<style scoped>
    h2 {
        text-align: center;
        padding: 30px;
        font-size: 18px;
    }

    #chartTree {
        width: 1500px;
        height: 900px;
        border: 1px solid #606266;
        background-color: #eee;
        margin: 0 auto;
    }
</style>
<template>
    <div class="pvhFactory">
        <basic-container>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane :label="$t('PRODUCT TRACING')" name="first"></el-tab-pane>
                <el-tab-pane :label="$t('PRODUCT TREE TRACING')" name="second"></el-tab-pane>
            </el-tabs>
            <div class="layTop">
                <div class="Title" style="margin-bottom:10px;">{{$t('PRODUCT TREE TRACING')}}</div>
            </div>
            <template>
                <div id="chartTree">

                </div>
            </template>
        </basic-container>
    </div>
</template>

<script>
    import { getTreePosition, getTreeLinks } from "@/api/orderFracing";
    export default {
        data() {
            return {
                loadId:"",
                activeName: 'second',
                positionList: [],
                linksList: [],
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.loadId = this.$route.query.ID;
                this.loadChartTree();
            },
            async loadChartTree() {
                var chartDom = document.getElementById('chartTree');
                var myChart = this.$echarts.init(chartDom);
                var option;
                //            background: url("/img/lic2.jpg") center center no-repeat;
                getTreePosition(this.loadId, 1400, 800).then(res => {
                    var listTree = res.data; //�ڵ�����
                    if (listTree != null) {
                        this.positionList = listTree.ListTreeTreeShapePosition;
                        this.linksList = listTree.ListTreeTreeShapeLink;
                        option = {
                            title: {
                                text: ''
                            }, //����
                            tooltip: {},     //��ʾ�����
                            animationDurationUpdate: 1500,//
                            animationEasingUpdate: 'quinticInOut',
                            series: [
                                {
                                    type: 'graph',
                                    layout: 'none',
                                    symbolSize: 50,//ԲȦ��С
                                    roam: false,
                                    label: {
                                        show: true,
                                        formatter: function (params) {
                                            return params.data.name
                                        }
                                    },
                                    edgeSymbol: ['circle', 'arrow'],
                                    edgeSymbolSize: [3, 10],
                                    edgeLabel: {
                                        fontSize: 15
                                    },
                                    data: this.positionList,
                                    // links: [],
                                    links: this.linksList,
                                    lineStyle: {
                                        opacity: 0.9,
                                        width: 2,
                                        curveness: 0
                                    }
                                }
                            ]
                        };
                        myChart.setOption(option);
                    }
                    });
                window.addEventListener("resize", () => { myChart.resize(); });
            },
            handleClick() {
                if (this.activeName == "first") {
                    this.$router.push({ path: '/pvh_operation/orderFracing', query: { ID: this.loadId } })
                }
            },
        },
    }
</script>
