import request from '@/router/axios';
import {businessUrl} from "@/config/env"
export const list = (pageNo,pageSize,nameOrCode,processId) => {
    return request({
        url : businessUrl + "specParam/selectPage",
        method: "post",
        data: {
            pageNo,
            pageSize,
            nameOrCode,
            processId
        }
    })
}
//标准工艺
export const baseList = (pageNo,pageSize,nameOrIdLike) => {
    return request({
        url : businessUrl + "specParam/selectPage",
        method: "post",
        data: {
            pageNo,
            pageSize,
            nameOrIdLike
        }
    })
}
//实际工艺
export const planList = (pageNo,pageSize,nameOrIdLike) => {
    return request({
        url : businessUrl + "specParam/selectPage",
        method: "post",
        data: {
            pageNo,
            pageSize,
            nameOrIdLike
        }
    })
}
//编辑
export const edit = (row) => {
    return request({
        url : businessUrl + "specParam/update",
        method: "put",
        data: {
            ...row
        }
    })
}
//删除
export const remove = (id) => {
    return request({
        url : businessUrl + "specParam/delete",
        method: "delete",
        params: {
            id
        }
    })
}
export const changeStatus = (row) => {
    return request({
        url : businessUrl + "specParam/updateStatus",
        method: "put",
        data : {
            id : row.id,
            status : Number(row.status)
        }
    })
}
export const add = (row) => {
    return request({
        url : businessUrl + "specParam/insert",
        method: "post",
        data : {
            type: 2,
            ...row,
            status: 1
        }
    })
}
//岗位分类
export const typeList = () => {
    return request({
        url : businessUrl + "dict/selectDictByCategoryId",
        method: "get",
        params: {
            categoryId : "05b4b2a3d01a470eb47eae52fd7395bf"
        }
    })
}
export const audit = (id,auditStatus) => {
    return request({
        url : businessUrl + "specParam/audit",
        method: "put",
        data: {
            id,
            auditStatus
        }
    })
}
//导出
export const exportExcel = () => request({
    url: businessUrl + "specParam/export",
    method: "get",
    responseType: 'blob'
})
//下载模版
export const downloadExecl = () => request({
    url: businessUrl + "specParam/importTemplate",
    method: "get",
    responseType: 'blob'
})
//导入
export const importExcel = (file) => request({
    url: businessUrl + "specParam/import",
    method: "post",
    headers :{"Content-Type":"multipart/form-data"},
    responseType: 'blob',
    data: file
})