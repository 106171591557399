import request from '@/router/axios';
import { pvhApiBase } from '@/config/env';



//�б�
export const SectionProcessList = (vendorSectionId) => {
    return request({
        url: pvhApiBase + "Tracing/GetSectionProcesssByVendorSectionID",
        method: "get",
        params: {
            "vendorSectionId": vendorSectionId
        }
    })
}

//��ȡ����������Ϣ
export const getVendorSectionInfo = (id) => {
    return request({
        url: pvhApiBase + "Tracing/GetVendorSection",
        method: "get",
        params: {
            "id": id
        }
    })
}

//��������һ������
//���� ��������
export const addVendorPro = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PostVendorSection",
        method: "post",
        data: {
            ...row
        }
    })
}

//�޸�������Ϣ
export const editVendorPro = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PutVendorSection",
        method: "put",
        data: {
            ...row
        }
    })
}

export const addPro = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PostSectionProcess",
        method: "post",
        data: {
            ...row
        }
    })
}
//�����޸�
export const editPro = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PutSectionProcess",
        method: "put",
        data: {
            ...row
        }
    })
}
//����ɾ��
export const delPro = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeleteSectionProcess",
        method: "delete",
        params: {
            id
        }
    })
}




