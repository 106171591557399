var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-menu",
                {
                  staticClass: "el-menu-vertical-demo",
                  attrs: { "default-active": "0" },
                  on: { open: _vm.handleOpen }
                },
                [
                  _vm._l(_vm.arrList, function(item, i) {
                    return [
                      _c(
                        "el-menu-item",
                        {
                          key: i,
                          attrs: { index: i.toString() },
                          on: {
                            click: function($event) {
                              return _vm.list(item)
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(item.name))]
                          )
                        ]
                      )
                    ]
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 20 } },
            [
              _c("avue-crud", {
                ref: "crud",
                attrs: { option: _vm.tableOption, data: _vm.tableData },
                on: {
                  "refresh-change": _vm.refreshChange,
                  "row-save": _vm.handleSave,
                  "row-update": _vm.handleUpdate,
                  "row-del": _vm.handleDel
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }