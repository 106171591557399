import request from '@/router/axios';
import { pvhApiBase } from '@/config/env';

// 添加用户权限
export const addUserPermission = (UserId, GrantPermission,GrantPermissions,GrantPermissionList) => {
	return request({
		url: pvhApiBase + "Cashier/PostUserPermission",
			method: "post",
			data: {
				UserId, GrantPermission,GrantPermissions,GrantPermissionList
			}
	})
}

// 获取用户权限
export const userJurisdiction = (id) => {
	return request({
			url: pvhApiBase + "Cashier/GetPermissionByUserId",
			method: "get",
			params: {
				userId: id
			}
	})
}