<template>
  <div>
		<basic-container>
			<el-col :span="4">
        <el-menu
            default-active="0"
            class="el-menu-vertical-demo"
            @open="handleOpen">
          <template v-for="(item,i) in arrList">
            <el-menu-item :index="i.toString()" @click="list(item)" v-bind:key="i">
              <span slot="title">{{ item.label }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </el-col>
			<el-col :span="20">
			<avue-crud :option="tableOption"
									:data="tableData"
									v-model="obj"
									ref="crud"
									@refresh-change="refreshChange"
									@row-update="handleUpdate"
									@row-del="handleDel"
									@row-save="handleSave">
        </avue-crud>
      </el-col>
		</basic-container>
	</div>
</template>
<script>
// import {list, treeList, add, remove, edit} from "@/api/dic"
import { downList,downSonList,addSonList,delSonList,editSonList,itemIsAgreement} from '@/api/enumeration'
import {TracingUnifiedIsBool } from "@/api/unified";

import importBtn from "@/components/importExport/importBtn";
export default {
  components: {
    importBtn
  },
  data() {
			var validateExistName = (rule, value, callback) => {
					if ((value != '' || value != null)) {
							var ids = this.obj.Id;
							if (ids == undefined) {
									ids = "";
							}
							TracingUnifiedIsBool("IsEnumItemExist",ids, value, this.item.value).then(resu => {
									var IsExist = resu.data;
									if (!IsExist) {
										// this.arrList.push(thihs.$t('Name') + ':'  +this.$t('NameIsExist'))
											callback(new Error(this.$t('NameIsExist')));
									} else { callback(); }
							}).catch(() => { callback(); });
					} else {
							callback();
					}
			};
			var validateValue = (rule, value, callback) => {
						if ((value != '' || value != null)) {
							var ids = this.obj.Id;
							if (ids == undefined) {
									ids = "";
							}
							TracingUnifiedIsBool("IsEnumItemValueExist",ids, value, this.item.value).then(resu => {
									var IsExist = resu.data;
									if (!IsExist) {
											callback(new Error(this.$t('NameIsExist')));
									} else { callback(); }
						}).catch(() => { callback(); });
					} else {
							callback();
					}
			};
	  return {
		  item: "",
		  arrList: [],
		  tableData: [],
		  obj: {},
		  itemID: {},
		  tableOption: {
			  delBtn: true,
			  menuWidth: 200,
			  columnBtn: false,
			  dialogFullscreen: true,
			  addBtnText: this.$t('ADD'),
			  delBtnText: this.$t('DELETE'),
			  editBtnText: this.$t('Edit'),
			  emptyText: this.$t('No Data'),
			  menuTitle: this.$t('OPERATION'),
			  column: [{
				  label: this.$t('NAME'),
				  prop: "Value",
				  rules: [{
					  required: true,
					  message: this.$t('PLEASE ENTER THE value'),
					  trigger: "blur"
				  }, {
					  validator: validateValue,
					  trigger: "blur"
				  }]
			  }, {
				  label: this.$t('value'),
				  prop: "Item",
				  rules: [{
					  required: true,
					  message: this.$t('PLEASE ENTER A NAME'),
					  trigger: "blur"
				  }, {
					  validator: validateExistName,
					  trigger: "blur"
				  }]
			  }]
		  },
		  categoryId: ''
	  }
  },
  created() {
		},
  mounted() {
		downList('EnumParentList').then(res => {
			this.arrList = res.data;
      this.item = res.data[0];
      this.list(this.item);
    })
  },
	methods: {
		handleOpen() {
      alert("loading");
    },
		//判断枚举item是否一致
    verification(row) {
			itemIsAgreement('IsEnumParentKeyExist',null,row,'ParentKey').then(res => {
				console.log('验证是否一致',res);
			})
		},
		//判断枚举value是否一致
    valueVerification(row) {
			itemIsAgreement('IsEnumItemValueExist',null,row,'ParentKey').then(res => {
				console.log('验证是否一致',res);
			})
		},
		// 点击主菜单
		list(item) {
			this.item = item
			downSonList(null,null,item.value).then(res => {
				this.tableData = res.data
			})
    },
		// 改变刷新
		refreshChange() {
			this.list(item)
		},
		// 新增子列表
		handleSave(parmas, done) {
			addSonList(parmas,this.item.value).then((res) => {
				console.log(res);
        this.list(this.item);
				res.data.statusCode=="200"?this.$message({
                showClose: true,
                message: "新增成功",
                type: "success"
              }):"";
        done();
				this.verification(parmas)
      })
		},
		// 编辑子列表
		handleUpdate(row,index,done) {
			editSonList(row).then(res => {
					this.list(this.item);
					res.data.code=="0000"?this.$message({
						showClose: true,
						message: res.data.msg,
						type: "success"
					}):this.$message({
						showClose: true,
						message: res.data.message,
						type: "success"
					});
					this.verification(row)
				})
			done();
		},
		// 删除子列表
		handleDel(row) {
			// console.log('删除的',row);
			this.$confirm(this.$t('IsDel') + row.Value, this.$t('Tips'), {
            confirmButtonText: this.$t('Yes'),
            cancelButtonText: this.$t('Cancel'),
            type: 'warning'
      }).then(() => {
            delSonList(row.Id).then(res => {
							// console.log('删除子列表',res);
              this.list(this.item);
              this.$message({
                showClose: true,
                message: "删除成功",
                type: "success"
              });
            });
          })
          .catch(() => {
          });
		}
	},
}
</script>