import request from '@/router/axios';
import { pvhApiBase } from '@/config/env'


//�ֶ��б���ʾ ��ѯ
export const fieldList = (page, pageSize, name, dataType, isNull, beginTime, endTime ) => {
    return request({
        url: pvhApiBase + "Tracing/GetSupportFields",
        method: "get",
        params: {
            "name": name,
            "dataType": dataType,
            "isNull": isNull,
            "beginOrderDate": beginTime,
            "endOrderDate": endTime,
            "page": page,
            "pageSize": pageSize
        }
    })
}

//�����ֶ���Ϣ
export const addField = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PostSupportField",
        method: "post",
        data: {
            ...row
        }
    })
}

//�����ֶ���Ϣ
export const editField = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PutSupportField",
        method: "put",
        data: {
            ...row
        }
    })
}

//ɾ���ֶ���Ϣ
export const delField = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeleteSupportField",
        method: "delete",
        params: {
            id
        }
    })
}