var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "el-dialog",
            {
              staticStyle: {
                marginTop: "20px",
                height: "1200px",
                overflow: "hidden"
              },
              attrs: {
                title: _vm.$t("IMPORT"),
                visible: _vm.dialogVisible,
                width: "60%",
                "append-to-body": true
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c(
                "el-upload",
                {
                  ref: "file",
                  staticClass: "upload-demo",
                  attrs: {
                    action: "http://192.168.2.110:8026/specParam/import",
                    "on-preview": _vm.handlePreview,
                    "on-success": _vm.uploadFileSuccess,
                    "auto-upload": false,
                    "on-change": _vm.addExcel,
                    "on-remove": _vm.handleRemove,
                    "before-remove": _vm.beforeRemove,
                    accept: ".xlsx,.xls",
                    multiple: "",
                    limit: 3,
                    "on-exceed": _vm.handleExceed,
                    "file-list": _vm.fileList
                  }
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v(_vm._s(_vm.$t("Upload")))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip"
                    },
                    [_vm._v("只能上传xls/xlsx文件，且不超过1MB")]
                  )
                ],
                1
              ),
              _c(
                "p",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "margin-top": "-30px" },
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.downloadExecl1 }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Download the template")))]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "success" }, on: { click: _vm.confirm } },
                    [_vm._v(_vm._s(_vm.$t("Yes")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                "table-loading": _vm.tableLoading,
                page: _vm.page
              },
              on: {
                "current-change": _vm.currentChange,
                "size-change": _vm.sizeChange,
                "selection-change": _vm.selectionChange,
                "refresh-change": _vm.refreshChange,
                "row-save": _vm.handleSave,
                "row-update": _vm.handleUpdate,
                "row-del": _vm.handleDel
              },
              scopedSlots: _vm._u([
                {
                  key: "status",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        attrs: { "inactive-value": 1, "active-value": 0 },
                        on: {
                          change: function($event) {
                            return _vm.change(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.status,
                          callback: function($$v) {
                            _vm.$set(scope.row, "status", $$v)
                          },
                          expression: "scope.row.status"
                        }
                      })
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "menuLeft" },
                [
                  _c("importBtn", {
                    attrs: {
                      outApi: this.proApis.SPECPARAMEXPORT,
                      innerAPI: this.proApis.SPECPARAMIMPORT,
                      API: this.proApis.SPECPARAMIMPORTTEMPLATE
                    },
                    on: { refresh: _vm.refreshData }
                  })
                ],
                1
              ),
              _c(
                "template",
                { slot: "menuRight" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "100px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: "工序",
                        size: "small"
                      },
                      model: {
                        value: _vm.status,
                        callback: function($$v) {
                          _vm.status = $$v
                        },
                        expression: "status"
                      }
                    },
                    _vm._l(_vm.wklnList, function(value) {
                      return _c("el-option", {
                        key: value.id,
                        attrs: { label: value.name, value: value.id }
                      })
                    }),
                    1
                  ),
                  _c("el-input", {
                    staticStyle: {
                      display: "inlie-block",
                      width: "200px",
                      marginRight: "5px"
                    },
                    attrs: { placeholder: "项目名称或编码", size: "small" },
                    model: {
                      value: _vm.nameOrCode,
                      callback: function($$v) {
                        _vm.nameOrCode = $$v
                      },
                      expression: "nameOrCode"
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }