import request from '@/router/axios';
import {businessUrl} from "@/config/env"
import { userUrl } from '../config/env';
export const getTree = () => request({
    url: businessUrl + "productCategory/select",
    method: "post",
    data: {
        
    }
})
//查询日
export const list = (year = "2021",mouth = "02",workShopId) => {
    return request({
        url: businessUrl + "schedule/selectScheduleData/" + year + "/" + mouth+"?workShopId="+workShopId,
        method: "post",
        data: {
            year,mouth
        }
    })
}
export const getDayList = (year = "2021",mouth = "03",day="26",workShopId) => {
    return request({
        url: businessUrl + "schedule/selectScheduleDay/" + year + "/" + mouth + "/" + day+"?workShopId="+workShopId,
        method: "post",
        data: {
            year,mouth,day
        }
    })
}
export const getWeekList = (year = "2021",mouth = "03",day="25",workShopId=4) => {
    return request({
        url: businessUrl + "schedule/selectScheduleWeek/" + year + "/" + mouth + "/" + day + "?workShopId=" + workShopId,
        method: "post",
        parmas: {
            
        }
    })
}
export const getWorkShop = () => request({
    url: userUrl + "department/selectWorkShopName?type=1",
    method: "get",
    data: {
        type: 1
    }
})
export const getListBool = (year = "2021",mouth="03",workShopId=4) => {
    return request({
        url: businessUrl + "schedule/selectFlag/" + year + "/" + mouth+"?workShopId="+workShopId,
        method: "post",
        data: {
            year,mouth
        }
    })
}
//获取部门
export const getSeverLetClass =  (workShopId) => request({
    url : userUrl + "department/selectGroupName?workShopId=" + workShopId,
    method: "post",
    parmas: {
        workShopId : 4
    }
})
//根据班制查询班次数量
export const getClassMath = (type,id) => {
    if(!type) {
        return request({
            url : businessUrl + "scheduleRules/selectTypeById?"+ "id=" + id+"&type=",
            method: "get",
            parmas: {
                type: String(type),
                id
            }
        })
    } else {
        return request({
            url : businessUrl + "scheduleRules/selectTypeById?"+ "id=" + id+"&type="+type,
            method: "get",
            parmas: {
                type: String(type),
                id
            }
        })
    }
}
//提交排班的班制度数据
/**
@param {string} workshop 车间
@param {string} goDate 时间
@param {string} radio 班制
@param {array} submit 三班
*/
export const submitData = (dto) => { 
    console.log(dto,'js')
    return request({
        url: businessUrl + "schedule/batchInsert",
        method: "post",
        data: dto
    })
    //workshop, goDate, radio, submit, radioTop, selectMultipleList
    //console.log(workshop, "workshop", goDate, "goDate", radio, "radio", submit, "submit", radioTop,"radioTop", selectMultipleList,"54785")
    //if (radioTop != "") {
    //    return request({
    //        url: businessUrl + "schedule/batchInsert",
    //        method: "post",
    //        data: {
    //            workShopId: workshop,allTimes: goDate.split(","),
    //            scheduleRulesId: radioTop,
    //            groupIds: selectMultipleList
    //        }
    //    })
    //} else {
    //    return request({
    //        url: businessUrl + "schedule/batchInsert",
    //        method: "post",
    //        data: {
    //            workShopId: workshop,allTimes: goDate.split(","),type: radio,shiftGroup: submit
    //        }
    //    })
    //}
    
}
//查询所有班制
export const getWorkList = () => request({
    url: businessUrl + "shift/selectType",
    method: "get",
    parmas: {
        
    }
})
//根据班制类型查询排班规则
export const getWorkTypeByWorkId = (type) => request({
    url: businessUrl + "scheduleRules/selectScheduleRulesByType?type=" + type,
    method: "get",
    parmas: {
        type
    }
})
//预览排班
export const getLookWorkList = () => (workshop,goDate,radio,submit,radioTop,selectMultipleList) => { 
    if (radioTop != "") {
        return request({
            url: businessUrl + "schedule/showSchedule",
            method: "post",
            data: {
                workShopId: workshop,allTimes: goDate.split(","),
                scheduleRulesId: radioTop,
                groupIds: selectMultipleList
            }
        })
    } else {
        return request({
            url: businessUrl + "schedule/showSchedule",
            method: "post",
            data: {
                workShopId: workshop,allTimes: goDate.split(","),type: radio,shiftGroup: submit
            }
        })
    }
}