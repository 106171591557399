<template>
    <div class="pvhFactory">
        <basic-container>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane :label="$t('PRODUCT TRACING')" name="first"></el-tab-pane>
                <el-tab-pane :label="$t('PRODUCT TREE TRACING')" name="second"></el-tab-pane>
            </el-tabs>
            <div class="layTop">
                <div class="Title" style="margin-bottom:10px;">{{$t('PRODUCT TRACING')}}</div>
            </div>
            <template>
                <div>
                    <el-row>
                        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" style="margin-bottom:20px;">
                            <el-autocomplete v-model="SeaCodeVal" :placeholder="$t('queryParam')"
                                             prefix-icon="el-icon-help"
                                             style="width:300px;" size="small" :fetch-suggestions="querySearchCodeAsync" @select="handleSelect" @change="handleChange">
                            </el-autocomplete>

                            <el-button icon="iconfont icon-dingwei1" type="text" size="medium" :title="$t('Tracing')" style="margin-left:10px;" @click="SelectData()"></el-button>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" style="margin-bottom:20px;text-align: right;">
                            <el-button icon="el-icon-thumb" type="primary" size="medium" v-if="false" round @click="getApprove()">{{$t('Approve')}}</el-button>
                            <el-button icon="el-icon-thumb" type="success" size="medium" v-if="isBrandBusiness" round @click="ApprovedsubmitForm">{{$t('APPROVED')}}</el-button>
                            <el-button icon="el-icon-download" type="warning" size="medium" v-if="isBrandBusiness" round @click="GetReview()">{{$t('DownloadPDF')}}</el-button>
                        </el-col>
                    </el-row>
                    <div class="clearfix" style="padding:20px 40px;background:#F5FAFF;border-radius:8px;display: flex;align-items: center;justify-content: space-between;margin-bottom:20px;">
                        <div class="clearfix" style="width:calc(100% - 120px);float:left;">
                            <el-form :model="topInfo">
                                <el-row>
                                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" style="margin-bottom:20px;">
                                        <span style="margin-right:10px;font-weight:700;font-size:14px;">{{$t('Division')}} :</span>
                                        <span class="fontCont">{{topInfo.departVal}}</span>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" style="margin-bottom:20px;">
                                        <span style="margin-right:10px;font-weight:700;font-size:14px;">{{$t('Brand')}} :</span>
                                        <span class="fontCont">{{topInfo.brandVal}}</span>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" style="margin-bottom:20px;">
                                        <span style="margin-right:10px;font-weight:700;font-size:14px;">{{$t('BATCH')}} :</span>
                                        <span class="fontCont">{{topInfo.batchVal}}</span>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" style="margin-bottom:20px;">
                                        <span style="margin-right:10px;font-weight:700;font-size:14px;">{{$t('PO')}} :</span>
                                        <span class="fontCont">{{topInfo.poVal}}</span>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" style="margin-bottom:20px;">
                                        <span style="margin-right:10px;font-weight:700;font-size:14px;">{{$t('ContractNo')}} :</span>
                                        <span class="fontCont">{{topInfo.contractNoVal}}</span>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" style="margin-bottom:20px;">
                                        <span style="margin-right:10px;font-weight:700;font-size:14px;">{{$t('ProductCode')}} :</span>
                                        <span class="fontCont">{{topInfo.codeVal}}</span>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" style="margin-bottom:20px;">
                                        <span style="margin-right:10px;font-weight:700;font-size:14px;">{{$t('Product Name')}} :</span>
                                        <span class="fontCont">{{topInfo.productVal}}</span>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" style="margin-bottom:20px;">
                                        <span style="margin-right:10px;font-weight:700;font-size:14px;">{{$t('COLOR')}} :</span>
                                        <span class="fontCont">{{topInfo.colorVal}}</span>
                                    </el-col>

                                </el-row>
                                <el-row>
                                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" style="margin-bottom:20px;">
                                        <span style="margin-right:10px;font-weight:700;font-size:14px;">{{$t('QUANTITY')}} :</span>
                                        <span class="fontCont">{{topInfo.numberVal}}/{{topInfo.unitVal}}</span>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" style="margin-bottom:20px;" v-if="topInfo.destinationCountryNameVal==''?false:true">
                                        <span style="margin-right:10px;font-weight:700;font-size:14px;">{{$t('MARKET')}} :</span>
                                        <span class="fontCont">{{topInfo.destinationCountryNameVal}}</span>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" style="margin-bottom:20px;">
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" style="margin-bottom:20px;">
                                    </el-col>
                                </el-row>
                            </el-form>
                        </div>
                        <div>
                            <div class="qrcode" id="qrCodeUrl" ref="qrCodeUrl" @click="onPrint">
                                <vue-qr :logoSrc="qrColorCode" :logoScale="20" :margin="2" colorDark='#000' :text="qrpocode" :size="120"></vue-qr>
                            </div>
                            <span style="font-size:14px;">Mobile  Traceability</span>
                        </div>

                    </div>
                    <div class="tracingList">
                        <div class="tli" v-for="(item,index) in InfoData.activities" v-bind:key="index">
                            <div class="topTitleCon"><i style="color:#4D8AF0" class="iconfont icon-yuan"></i>{{item.title}}</div>
                            <div v-for="(time, index) in item.children" :key="index" style="align-items: flex-start; justify-content: space-between;">
                                <el-row style="background-color: #dcdfe673;padding-top:5px;">
                                    <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" style="margin-bottom:20px;">
                                        <div class="topTitleCon2"><i style="color:#C2C7D2" class="iconfont icon-yuan"></i>{{time.date}}</div>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" style="justify-content: right; display: flex;">

                                        <div style="width:35px;justify-content: center;display: flex;" v-if="!time.isLastVar">
                                            <el-tooltip class="item" effect="light" placement="bottom-start">
                                                <div slot="content" v-for="(item,index) in time.Approvelist" v-bind:key="index">{{formatterApprove(item)}}</div>
                                                <img style="height: 1rem; width: 1.25rem;margin-top: 5px;" :title="$t(time.ApproveVar)" :src="time.ApproveImgVar" />
                                            </el-tooltip>
                                        </div>
                                        <div v-if="isBrandBusiness&&!time.isLastVar">
                                            <el-button type="danger" size="small" icon="el-icon-close" plain round :title="$t('Reject')" style="padding: 3px !important;" @click="RejectForm(time)">{{$t('Reject')}} </el-button>
                                            <el-button type="info" size="small" icon="el-icon-lock" plain round :title="$t('LOCK')" style="padding: 3px !important;" @click="submitLock(time)" v-if="time.LockedStatusVar<=0">{{$t('LOCK')}} </el-button>
                                            <el-button type="warning" size="small" icon="el-icon-unlock" plain round :title="$t('Unlock')" style="padding: 3px !important;" @click="submitUnlock(time)" v-if="time.LockedStatusVar>0">{{$t('Unlock')}} </el-button>
                                        </div>

                                        <div style="width:50px;justify-content: center;display: flex;">
                                            <el-tooltip class="item" effect="light" placement="bottom-start">
                                                <div slot="content" v-for="(item,index) in time.statuslist" v-bind:key="index">{{formatterColumn(item)}}</div>
                                                <el-link :type="time.statusColorVar" style="font-weight: bold;">{{time.statusVar}}</el-link>
                                            </el-tooltip>
                                        </div>
                                        <div style="width:60px;" v-if="time.processList.length==0">
                                            <el-button icon="iconfont icon-mingxi" disabled style="padding: 0 0 0 !important;"></el-button>
                                        </div>
                                        <div style="width:60px;justify-content: center;display: flex;" v-if="time.processList.length>0">
                                            <el-badge :value="time.processCount" class="item">
                                                <el-button icon="iconfont icon-mingxi" @click="showField(time.processList)" :title="$t('Declaration items uploaded / declaration items to be uploaded')" style="padding: 0 0 0 !important;"></el-button>
                                            </el-badge>
                                        </div>
                                        <div style="width:60px;" v-if="time.fileList.length==0">
                                            <el-button icon="iconfont icon-fujian" disabled style="padding: 0 0 0 !important;"></el-button>
                                        </div>
                                        <div style="width:60px;justify-content: center;display: flex;" v-if="time.fileList.length>0">
                                            <el-badge :value="time.fileCount" class="item">
                                                <el-button icon="iconfont icon-fujian" @click="showFileList(time.fileList)" :title="$t('Declaration items uploaded / declaration items to be uploaded')" style="padding: 0 0 0 !important;"></el-button>
                                            </el-badge>
                                        </div>

                                    </el-col>
                                </el-row>
                                <div style="padding-left: 35px;">
                                    <el-row>
                                        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            <span class="fontTitle" v-if="time.VendorSectionSortVar<2"  >{{$t('VENDOR')}} : </span>
                                            <span class="fontTitle" v-if="time.VendorSectionSortVar>1" >{{$t('SUPPLIER')}} : </span>
                                            <span class="fontCont">{{time.vendorVal}}</span>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            <span class="fontTitle">{{$t('PO')}} : </span>
                                            <span class="fontCont">{{time.POVal}}</span>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            <span class="fontTitle">{{$t('Division')}} : </span>
                                            <span class="fontCont">{{time.CdivisionVar}}</span>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            <span class="fontTitle">{{$t('Brand')}} : </span>
                                            <span class="fontCont">{{time.CbrandVar}}</span>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            <span class="fontTitle">{{$t('ProductCode')}} : </span>
                                            <span class="fontCont">{{time.codeVal}}</span>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            <span class="fontTitle">{{$t('BATCH')}} : </span>
                                            <span class="fontCont">{{time.title}}</span>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            <span class="fontTitle">{{$t('COLOR')}} : </span>
                                            <span class="fontCont">{{time.CcolorVar}}</span>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            <span class="fontTitle">{{$t('QUANTITY')}}: </span>
                                            <span class="fontCont">{{time.CnunberVar}}/{{time.CunitVar}}</span>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" v-if="time.isRawFiberTypeId">
                                            <span class="fontTitle">{{$t('FIBER TYPE')}} : </span>
                                            <span class="fontCont">{{time.RawFiberTypeIdVar}}</span>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" v-if="time.isVendorAddressId">
                                            <span class="fontTitle">{{$t('ORIGIN')}} :</span>
                                            <span class="fontCont">{{time.VendorAddressIdVar}}</span>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                                            <span class="fontTitle">{{$t('Product Name')}} : </span>
                                            <span class="fontCont">{{time.CproductVar}}</span>
                                        </el-col>
                                        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-if="time.compositionVar!= '' ? true : false">
                                            <span class="fontTitle">{{$t('COMPONENT')}} : </span>
                                            <span class="fontCont">{{time.compositionVar}}</span>
                                        </el-col>

                                    </el-row>
                                    <el-row>
                                        <el-col :xs="24" :sm="12" :md="16" :lg="12" :xl="12" v-if="time.isCollection">
                                            <span class="fontTitle">{{$t('CONTRACT SIGNING COMPANY')}} : </span>
                                            <span class="fontCont">{{time.AffiliatedCorpCollectionVar}}</span>
                                        </el-col>

                                        <el-col :xs="24" :sm="12" :md="16" :lg="12" :xl="12" v-if="time.isWorking">
                                            <span class="fontTitle">{{$t('ASSOCIATED PROCESSING PLANT')}} : </span>
                                            <span class="fontCont">{{time.AffiliatedCorpWorkingVar}}</span>
                                        </el-col>
                                    </el-row>

                                    <div v-for="(downs,index) in time.downlist" v-bind:key="index" v-if="time.downlist.length>0" class="downListCon">
                                        <el-row>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                                <span class="fontTitle">{{$t('PO')}} : </span>
                                                <span class="fontCont">{{downs.PoRowInfo.Po}}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="10" :xl="10">
                                                <span class="fontTitle">{{$t('PO COMPANY')}} : </span>
                                                <span class="fontCont">{{downs.PoRowInfo.VendorCorporationId}}</span>
                                            </el-col>

                                            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                                                <div style="display: flex;justify-content: right;">
                                                    <div style="width:35px;justify-content: center;display: flex;">
                                                        <el-tooltip class="item" effect="light" placement="bottom-start">
                                                            <div slot="content" v-for="(item,index) in downs.ApproveList" v-bind:key="index">{{formatterApprove(item)}}</div>
                                                            <img style="height: 1rem; width: 1.25rem;margin-top: 5px;" :title="$t(downs.Approve)" :src="downs.ApproveImg" />
                                                        </el-tooltip>
                                                    </div>
                                                    <div v-if="isBrandBusiness">
                                                        <el-button type="danger" size="small" icon="el-icon-close" plain round :title="$t('Reject')" style="padding: 3px !important;" @click="RejectDownForm(downs)">{{$t('Reject')}} </el-button>
                                                        <el-button type="info" size="small" icon="el-icon-lock" plain round :title="$t('LOCK')" style="padding: 3px !important;" @click="submitDownLock(downs)" v-if="downs.PoRowDetailInfo.LockedStatus<=0">{{$t('LOCK')}} </el-button>
                                                        <el-button type="warning" size="small" icon="el-icon-unlock" plain round :title="$t('Unlock')" style="padding: 3px !important;" @click="submitDownUnlock(downs)" v-if="downs.PoRowDetailInfo.LockedStatus>0">{{$t('Unlock')}} </el-button>
                                                    </div>
                                                    <div style="width: 50px; justify-content: center; display: flex;">
                                                        <el-tooltip class="item" effect="light" placement="bottom-start">
                                                            <div slot="content" v-for="(item,index) in downs.StatusList" v-bind:key="index">{{formatterColumn(item)}}</div>
                                                            <el-link :type="downs.StatusColor" style="font-weight: bold;">{{downs.StatusCompletion}}</el-link>
                                                        </el-tooltip>
                                                    </div>
                                                    <div style="width:60px;" v-if="downs.processList.length==0">
                                                        <el-button icon="iconfont icon-mingxi" disabled style="padding: 0 0 0 !important;"></el-button>
                                                    </div>
                                                    <div style="width:60px;justify-content: center;display: flex;" v-if="downs.processList.length>0">
                                                        <el-badge :value="downs.processCount" class="item">
                                                            <el-button icon="iconfont icon-mingxi" @click="showField(downs.processList)"  :title="$t('Declaration items uploaded / declaration items to be uploaded')" style="padding: 0 0 0 !important;"></el-button>
                                                        </el-badge>
                                                    </div>
                                                    <div style="width:60px;" v-if="downs.fileList.length==0">
                                                        <el-button icon="iconfont icon-fujian"  disabled style="padding: 0 0 0 !important;"></el-button>
                                                    </div>
                                                    <div style="width:60px;justify-content: center;display: flex;" v-if="downs.fileList.length>0">
                                                        <el-badge :value="downs.fileCount" class="item">
                                                            <el-button icon="iconfont icon-fujian" @click="showFileList(downs.fileList)"  :title="$t('Declaration items uploaded / declaration items to be uploaded')" style="padding: 0 0 0 !important;"></el-button>
                                                        </el-badge>
                                                    </div>
                                                </div>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" style="display: flex;">
                                                <span class="fontTitle">{{$t('PO CODE')}} : </span>
                                                <span class="fontCont">{{downs.PoRowDetailInfo.Code}}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                                <span class="fontTitle">{{$t('PO PRODUCT NAME')}} : </span>
                                                <span class="fontCont">{{downs.PoRowDetailInfo.ProductName}}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                                <span class="fontTitle">{{$t('LOSSRATE')}} : </span>
                                                <span class="fontCont">{{downs.LossRate}}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                                <span class="fontTitle">{{$t('UseQuantityPO')}} : </span>
                                                <span class="fontCont">{{downs.UsedNumber}}/{{downs.PoRowDetailInfo.UnitId}}</span>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" v-if="downs.PoRowDetailInfo.RawFiberTypeId!= '' ? true : false">
                                                <span class="fontTitle">{{$t('FIBER TYPE')}} : </span>
                                                <span class="fontCont">{{downs.PoRowDetailInfo.RawFiberTypeName}}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" v-if="downs.PoRowDetailInfo.VendorAddressId!= '' ? true : false">
                                                <span class="fontTitle">{{$t('ORIGIN')}} : </span>
                                                <span class="fontCont">{{downs.PoRowDetailInfo.VendorAddressId}}</span>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            </el-col>
                                        </el-row>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <el-dialog :title="$t('selectPO')" :visible.sync="dialogTableVisible" :modal-append-to-body="false" width="90%" top="2vh">
                        <avue-crud ref="crud" :option="tableOpt" :row-style="rowStyle" :row-key="GetRowKeys" :data="potable" :page.sync="page" @selection-change="selectionChange" @current-change="currentChange">
                            <template slot="menuLeft" style="width: calc(100% - 80px);">
                                <el-row :gutter="20" class="topFromSearch">
                                    <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            <span>{{$t('ProductCode')}}:</span>
                                            <el-input v-model="codeVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                        </el-col>
                                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            <span>{{$t('BATCH')}}:</span>
                                            <el-input v-model="batchVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                        </el-col>
                                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            <span>{{$t('COLOR')}}:</span>
                                            <el-input v-model="colorVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                        </el-col>
                                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            <span id="venSpan">{{$t('VENDOR')}}:</span>
                                            <el-select v-model="vendorVal.ids" @focus="focusDiv($event)" @blur="blurDiv($event)" multiple collapse-tags placeholder="" style="width:100%;" @change="venchang" clearable="true">
                                                <el-option v-for="item in this.vendorData"
                                                           :key="item.value"
                                                           :label="item.label"
                                                           :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </el-col>
                                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            <span>{{$t('BEINGTIME')}}:</span>
                                            <el-date-picker class="dataicon" v-model="beginTimeVal"
                                                            type="date" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                                            format="yyyy-MM-dd" value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                                            </el-date-picker>
                                        </el-col>
                                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            <span>{{$t('ENDTIME')}}:</span>
                                            <el-date-picker class="dataicon" v-model="endTimeVal"
                                                            type="date" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                                            format="yyyy-MM-dd" value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                                            </el-date-picker>
                                        </el-col>
                                    </el-col>
                                    <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top:10px;">
                                        <el-button class="moreBtn" size="small" icon="iconfont icon-24px" circle @click="moreFrom" style="margin-right:0;"></el-button>
                                        <el-button type="primary" size="small" plain @click="searchFrom">{{$t('SEARCH')}}</el-button>
                                    </el-col>
                                </el-row>
                            </template>
                            <template slot="header">
                                <el-button type="primary" @click="submitSelectData()">{{$t('Yes')}}</el-button>
                            </template>
                            <template slot="tip">
                            </template>
                        </avue-crud>
                    </el-dialog>
                    <el-dialog :title="$t('Attachment list')" :visible.sync="fileDialogTableVisible" :modal-append-to-body="false" width="90%" top="2vh">
                        <avue-crud ref="fileCrud" :option="filetableOpt" :row-style="rowStyle" :row-key="GetRowKeys" :data="fileList" :page.sync="page" @selection-change="selectionChange" @current-change="currentChange">
                            <template slot-scope="{row,index,size,type}" slot="menu">
                                <el-button icon="el-icon-download" type="text" size="medium" :title="$t('DOWNLOAD')" @click="downloadFile(row)"></el-button>
                            </template>
                            <template slot="tip">
                            </template>
                        </avue-crud>
                    </el-dialog>
                    <el-dialog :title="$t('Field list')" :visible.sync="fieldDialogTableVisible" :modal-append-to-body="false" width="90%" top="2vh" @close="fieldDialogTableVisible = false">
                        <div style="height: 500px;overflow-y: auto;">
                            <div v-for="(process,index) in fieldList " v-bind:key="index">
                                <el-row style="background:#ebeef5;padding-bottom: 7px;">
                                    <span style="float: left; font-weight: bold; font-size: 14px; color: rgb(44, 141, 244);">{{process.title}} : </span>
                                </el-row>
                                <div v-for="(subject,index) in process.subjectList " style="height:120px;border-bottom: 0.3rem solid #EBEEF5;" v-bind:key="index">
                                    <template slot="title">
                                        <span style="float:left;font-weight:bold;font-size:10px;color:#2C8DF4;">{{subject.title}}</span>
                                    </template>
                                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" style="margin-bottom:10px;" v-for="(field,index) in subject.fieldList" v-bind:key="index">
                                        <span class="fontTitle">{{field.name}} :</span>
                                        <span class="fontCont">{{field.value}}</span>
                                    </el-col>
                                </div>
                            </div>
                        </div>
                    </el-dialog>
                    <el-dialog :title="$t('REJECT')" :visible.sync="RejectdialogTableVisible" :modal-append-to-body="false" width="70%" top="1vh">
                        <el-form :model="RejectEdit" :rules="rules" label-position="top" label-width="150px">
                            <el-row>
                                <el-col :span="12" :sm="24" :md="12">
                                    <el-form-item :label="$t('REJECTED COMPANY')">
                                        <el-input v-model="RejectEdit.ApprovedObjectName" style="width: calc(100% - 115px);" v-bind:disabled="true"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" :sm="24" :md="12">
                                    <el-form-item :label="$t('REJECTION CATEGORY')" :required="true" prop="RejectCategory" :rules="rules.RejectCategory">
                                        <el-autocomplete style="width: calc(100% - 115px);"
                                                         v-model="RejectEdit.RejectCategory"
                                                         :fetch-suggestions="RejectCategorySearch"
                                                         :popper-append-to-body="false"
                                                         clearable></el-autocomplete>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" :sm="24" :md="24">
                                    <el-form-item :label="$t('REJECTION DESCRIPTION')" :required="true" prop="RejectRemark" :rules="rules.RejectRemark">
                                        <el-input v-model="RejectEdit.RejectRemark" style="width: calc(100% - 115px);" type="textarea" maxlength="300" show-word-limit :autosize="{ minRows: 4, maxRows:6}"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <div style="text-align:center;padding:20px 0;">
                                <el-button type="primary" @click="submitReject();">{{$t('Yes')}}</el-button>
                                <el-button @click="RejectdialogTableVisible = false">{{$t('Cancel')}}</el-button>
                            </div>
                        </el-form>
                        <avue-crud :option="tableOptRejectList" :data="tableDataRejectList" :row-style="rowStyle" :page.sync="pageRejectList" @current-change="currentRejectListChange">
                        </avue-crud>
                    </el-dialog>

                    <div style="text-align:center" v-if="IsApprove">
                        <el-button type="success" @click="ApprovedsubmitForm">{{$t('APPROVED')}}</el-button>
                        <el-button type="info" @click="getCancelApprove()">{{$t('CANCEL')}}</el-button>
                    </div>
                    <div style="text-align:center">
                        <el-row style="background-color:#cdebc7;">
                            <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                <span class="fontTitle">{{$t('Unapproved items')}} :</span>
                                <span class="fontSumCont">{{bottomInfo.TotalNoApprove}}</span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                <span class="fontTitle">{{$t('Total suppliers')}} :</span>
                                <span class="fontSumCont"> {{bottomInfo.TotalSuppliers}}</span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                <span class="fontTitle">{{$t('Total Po')}} :</span>
                                <span class="fontSumCont">{{bottomInfo.TotalPo}} </span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                <span class="fontTitle">{{$t('Total submission times')}} :</span>
                                <span class="fontSumCont">{{bottomInfo.TotalSubmissionTimes}} </span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                <span class="fontTitle">{{$t('Total documents')}} :</span>
                                <span class="fontSumCont">{{bottomInfo.TotalDocuments}}</span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                <!--<span class="fontCont">{{bottomInfo.CproductVar}}</span>-->
                            </el-col>
                        </el-row>
                    </div>

                    <div class="upTit">{{$t('LoadingComplete')}}{{$t('STIME')}}:{{ this.currentTime}}</div>
                </div>
            </template>
        </basic-container>
    </div>
</template>
<style scoped>
    .el-dialog__wrapper {
        transition-duration: 0.3s;
    }

    .tracingList .el-timeline-item {
        padding-left: 110px;
        position: relative;
    }

    .tracingList .el-timeline-item__timestamp {
        position: absolute;
        left: -110px;
        top: 0;
        margin-top: 2px;
    }

    .tracingList .el-timeline-item__tail {
        position: absolute;
        left: 114px !important;
        top: 2px !important;
    }

    .tracingList .el-timeline-item__node {
        position: absolute;
        left: 109px !important;
        top: 0 !important;
    }

    .topTitleCon {
        font-size: 16px;
        color: #333;
        box-sizing: border-box;
        font-weight: 700;
    }

        .topTitleCon i {
            vertical-align: 1px;
            font-size: 13px !important;
            margin-right: 15px;
            -webkit-font-smoothing: antialiased;
        }
    .topTitleCon2 {
        font-size: 12px;
        color: #333;
        padding-left: 3px;
        box-sizing: border-box;
        font-weight: 500;
    }

        .topTitleCon2 i {
            vertical-align: 1px;
            font-size: 13px !important;
            margin-right: 15px;
            -webkit-font-smoothing: antialiased;
        }

    .downIcon i {
        margin: 0 !important;
        margin-top: -2px !important;
    }

    .downIcon {
        color: #409EFF;
        width: 32px;
        height: 32px;
        text-align: center;
        line-height: 30px;
        border-color: #409EFF;
        padding: 0 !important;
    }

    .tli {
        border: 1px dashed #979797;
        border-radius: 10px;
        padding: 15px;
        margin-bottom: 20px;
    }

        .tli .el-collapse-item__header {
            height: 35px;
            background-color: #ebeef5;
        }

        .tli .el-timeline-item {
            padding-bottom: 1px;
        }

    .upTit {
        font-size: 8px;
        text-align: center;
        margin: 0 auto;
        width: 100%;
        padding: 15px 0;
        border-top: 1px solid #f5f7fa;
        font-weight: 700;
        color: #ccc;
    }

    .downListCon {
        border-bottom: 2px solid #EBEEF5;
        /*  border: .0425rem dashed #979797;*/
        background-color: rgb(253 249 242);
    }
    .fontTitle {
        font-size: 14px;
        font-weight: 500;
    }
    .fontCont {
        font-size: 0.65rem;
        color: #303133;
    }
    .fontSumCont {
        font-size: 1rem;
        FONT-WEIGHT: 800;
        color: #333;
    }

</style>
<script>
    import { AllPOList, getDate, getDateYYYMMDD, PoAndDetailList, DecCompleted } from "@/api/purchaseOrd";
    import { TracingUnifiedIsBool, TracingDropDownList, TracingStringList, TracingUnifiedHelpReturn } from "@/api/unified";
    import { add, GetOrderPackage } from "@/api/orderPackage";
    import { ListApproves, addApprovesSo, addUnlockSo, addLockSo, addApprovesPo, addUnlockPo, addLockPo } from "@/api/orderApprove";
    import { pvhApiBase } from '@/config/env'
    import VueQr from 'vue-qr'

    export default {
        data() {
            return {
                activeName: 'first',
                isBrandBusiness: false, IsApprove: false,
                codeVal: "", batchVal: "", colorVal: "", beginTimeVal: "", endTimeVal: "", vendorVal: { ids: [], }, vendorData: [], businessNoInVal: '', businessNoOutVal: '',
                loadId: '', qrpocode: '', qrColorCode: '', currentTime: "",
                Coderestaurants: [], Codestate: '', Codetimeout: null,
                potable: [], codeslist: [], selectedData: [],//采购商
                bottomInfo: {
                    TotalNoApprove: 0, TotalSuppliers: 0, TotalPo: 0, TotalSubmissionTimes: 0, TotalDocuments: 0,
                },
                topInfo: {
                    brandVal: '', departVal: '', productVal: '', supplierVal: '', codeVal: '',
                    poVal: '', batchVal: '', contractNoVal: '', IngredientVal: '', productNameVal: '', colorVal: '', numberVal: '', unitVal: '', destinationCountryNameVal: '', 
                },
                InfoData: {
                    activities: [{
                        title: '',
                        children: [{
                            date: '', vendorVal: '', codeVal: '', POVal: '',
                            processList: [], processCount: '', fileCount: '',
                            fileList: [{ name: '', fileURL: '' }],
                            downlist: [],
                            title: '', CbrandVar: '', CdivisionVar: '', CsupplierVar: '', CproductVar: '', CproductCodeVar: '', CbatchVar: '', CcolorVar: '', CnunberVar: '', CunitVar: ''
                            , compositionVar: '', isCollection: false, isWorking: false, AffiliatedCorpCollectionVar: "", AffiliatedCorpWorkingVar: "", BusinessNoInVar: "", BusinessNoOutVar: "", LossRateVar: 0,
                            isRawFiberTypeId: false, RawFiberTypeIdVar: "", isVendorAddressId: false, VendorAddressIdVar: "", LockedStatusVar: 0, isLastVar: false, VendorSectionSortVar: 0,
                            poDetailVar: '', statusColorVar: 'info', statusVar: '', statuslist: [], collapseName: [], ApproveImgVar: '' ,ApproveColorVar: 'info', ApproveVar: '', Approvelist: [],
                        }]
                    }]
                },
                no_SeaCodeVal: 'no', selectId: '', SeaCodeVal: '', dialogTableVisible: false, fileDialogTableVisible: false, fileList: [], //当前查询值
                fieldDialogTableVisible: false, fieldList: [],
                RejectdialogTableVisible: false, tableDataRejectList: [], RejectCategoryData: [],
                RejectEdit: {
                    DetailOrderId: '',
                    ApprovedObject: '',
                    ApprovedObjectName: '',
                    RejectCategory: '',
                    RejectRemark: '',
                    isPo: true,
                },
                rules: {
                    RejectCategory: [{ required: true, message: this.$t('Required'), trigger: 'blur' }],
                    RejectRemark: [{ required: true, message: this.$t('Required'), trigger: 'blur' }],
                },
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background: true,
                    pageSize: 10
                },
                pageRejectList: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background: true,
                    pageSize: 10
                },
                tableOpt: {
                    page: true,
                    refreshBtn: false,
                    columnBtn: false,
                    simplePage: true,
                    addBtn: false,
                    addRowBtn: false,
                    cellBtn: false,
                    index: true,
                    indexLabel: ' ',
                    border: true,
                    align: "center",
                    menu: false,
                    emptyText: this.$t('No Data'),
                    selection: true,
                    reserveSelection: true,
                    tip: false,
                    column: [
                        {
                            label: this.$t('DATE'),
                            prop: 'orderDate',
                            type: "date",
                            format: 'yyyy-MM-dd',
                            valueFormat: 'yyyy-MM-dd',
                        },
                        {
                            label: this.$t('VENDOR'),
                            prop: 'vendorCorporationId',
                        },
                        {
                            label: this.$t('ProductCode'),
                            prop: 'code',
                        },
                        {
                            label: this.$t('Product Name'),
                            prop: 'productName',
                        },
                        {
                            label: this.$t('BATCH'),
                            prop: 'batch',
                        },
                        {
                            label: this.$t('COLOR'),
                            prop: 'color',
                        },
                        {
                            label: this.$t('PO'),
                            prop: 'po',
                        },
                        {
                            label: this.$t('UNIT'),
                            prop: 'unitId',
                        },
                        {
                            label: this.$t('OrderNumber'),
                            prop: 'number',
                        }
                    ]
                },
                filetableOpt: {
                    page: false,
                    refreshBtn: false,
                    columnBtn: false,
                    simplePage: true,
                    addBtn: false,
                    addRowBtn: false,
                    editBtn: false,
                    delBtn: false,
                    cellBtn: false,
                    index: true,
                    indexLabel: ' ',
                    border: true,
                    align: "center",
                    menuTitle: this.$t('OPERATION'),
                    emptyText: this.$t('No Data'),
                    tip: false,
                    column: [
                        {
                            label: this.$t('PROCESS NAME'),
                            prop: 'ProcessName',
                        },
                        {
                            label: this.$t('FILE TYPE'),
                            prop: 'SupportFileName',
                        },
                        {
                            label: this.$t('FILE NAME'),
                            prop: 'Name',
                        },
                        {
                            label: this.$t('Index'),
                            prop: 'FileURL',
                            hide: true,
                        },
                    ]
                },
                tableOptRejectList: {
                    page: false,
                    refreshBtn: false,
                    columnBtn: false,
                    simplePage: true,
                    addBtn: false,
                    addRowBtn: false,
                    editBtn: false,
                    delBtn: false,
                    cellBtn: false,
                    index: true,
                    menu: false,
                    indexLabel: ' ',
                    border: true,
                    align: "center",
                    emptyText: this.$t('No Data'),
                    tip: false,
                    column: [
                        {
                            label: this.$t('DATE'),
                            prop: 'CreateTime',
                            type: "date",
                            format: 'yyyy-MM-dd',
                            valueFormat: 'yyyy-MM-dd',
                        },
                        {
                            label: this.$t('REJECTED COMPANY'),
                            prop: 'ApprovedObject',
                        },
                        {
                            label: this.$t('REJECTION CATEGORY'),
                            prop: 'RejectCategory',
                        },
                        {
                            label: this.$t('REJECTION DESCRIPTION'),
                            prop: 'RejectRemark',
                        },
                        {
                            label: this.$t('RESUBMISSION INSTRUCTIONS'),
                            prop: 'Remarks',
                        },
                    ]
                }
            }
        },
        mounted() {
            this.init();// 在加载的时候执行了这个方法，可以调用这个根据ID来插叙的方法
            this.Coderestaurants = this.getCodeList();//数据源
        },
        components: {
            VueQr
        },
        watch: {
            $route() {
                this.init()
            }
        },
        methods: {
            //设置表头行的样式
            tableHeaderColor() {
                return 'background-color:#838bb3!important;padding: 0!important;'

            },//表格行央视
            rowStyle({ rowIndex }) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
            },
            moreFrom() {
                let fromHei = document.querySelector(".fromOutDiv").clientHeight;
                if (fromHei > 60) {
                    document.querySelector(".fromOutDiv").style.cssText = "height:60px;";
                } else {
                    document.querySelector(".fromOutDiv").style.cssText = "height:auto;";
                }
            },  //点击更多按钮
            focusDiv(obj) {//搜索框获取焦点
                if (obj.currentTarget === undefined) {
                    obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                } else {
                    if (obj.currentTarget.parentElement.getAttribute('class').indexOf('suffix') !== -1) {
                        obj.currentTarget.parentElement.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                    } else {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                    }

                }
            },//搜索框失去焦点
            blurDiv(obj) {
                if (obj.currentTarget === undefined) {
                    if (obj.value === "") {
                        obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                } else if (obj.currentTarget === null) {
                    if (this.vendorVal.ids.length === 0) {
                        document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                } else {
                    if (obj.target.value == "") {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                }
            },
            venchang() {
                if (this.vendorVal.ids.length === 0) {
                    document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                } else {
                    document.querySelector("#venSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                }
            },//点击更多按钮
            searchFrom() {
                this.no_SeaCodeVal = "yes";
                this.getPurOrdList();
            },//查询
            getCodeList() {
                this.Codeslist = [];
                TracingStringList("ALLPurchaseOrderInfo").then(res => {
                    for (var i = 0; i < res.data.length; i++) {
                        this.Codeslist.push({
                            value: res.data[i]
                        })
                    }
                })
                return this.Codeslist;
            },
            init() {
                this.loadId = this.$route.query.ID;
                TracingDropDownList('VendorList').then(res => {
                    this.vendorData = res.data;
                });
                TracingUnifiedIsBool("OrderIsBrandBusiness", this.loadId).then(res => {
                    this.isBrandBusiness = res.data;
                });
                AllPOList(this.loadId).then(res => {
                    //底部
                    this.bottomInfo.TotalOrderProgress = res.data.TotalOrderProgress;
                    this.bottomInfo.TotalNoApprove = res.data.TotalNoApprove;
                    this.bottomInfo.TotalSuppliers = res.data.TotalSuppliers;
                    this.bottomInfo.TotalPo = res.data.TotalPo;
                    this.bottomInfo.TotalSubmissionTimes = res.data.TotalSubmissionTimes;
                    this.bottomInfo.TotalDocuments = res.data.TotalDocuments;
                    //头部
                    this.topInfo.supplierVal = res.data.TopPoInfo.VendorCorporationId;
                    this.topInfo.codeVal = res.data.TopPoDetailInfo.Code;
                    this.topInfo.brandVal = res.data.TopPoInfo.Brand;
                    this.topInfo.departVal = res.data.TopPoInfo.DepartmentId;
                    this.topInfo.productVal = res.data.TopPoDetailInfo.ProductName;
                    this.topInfo.poVal = res.data.TopPoInfo.Po;
                    this.topInfo.batchVal = res.data.TopPoInfo.Batch;
                    this.topInfo.contractNoVal = res.data.TopPoInfo.ContractId;
                    this.topInfo.IngredientVal = res.data.TopPoDetailInfo.Ingredient;
                    this.topInfo.productNameVal = res.data.TopPoDetailInfo.ProductName;
                    this.topInfo.colorVal = res.data.TopPoDetailInfo.Color;
                    this.topInfo.numberVal = res.data.TopPoDetailInfo.Number;
                    this.topInfo.destinationCountryNameVal = res.data.TopPoDetailInfo.DestinationCountryName;
                    this.topInfo.unitVal = res.data.TopPoDetailInfo.UnitId;
                    this.topInfo.businessNoInVal = res.data.TopPoDetailInfo.BusinessNoIn;
                    this.topInfo.businessNoOutVal = res.data.TopPoDetailInfo.BusinessNoOut;
                    var eachspo = [];
                    var forchildrn = [];
                    var eachspolist = [];
                    this.InfoData.activities = [];
                    eachspo = res.data.eachStagePOList;
                    for (var i = 0; i < eachspo.length; i++) {
                        forchildrn = eachspo[i].children;
                        var childrenlistVar = [];
                        for (var j = 0; j < forchildrn.length; j++) {
                            var downlistval = forchildrn[j].downInfos;
                            if (forchildrn[j].SoRowDetailInfo.Id != null) {
                                var childrenVal = {
                                    fileList: forchildrn[j].fileList,
                                    processList: forchildrn[j].processList,
                                    processCount: forchildrn[j].processCount,
                                    fileCount: forchildrn[j].fileCount,
                                    downlist: downlistval,
                                    date: getDateYYYMMDD(forchildrn[j].PoRowInfo.OrderDate),
                                    vendorVal: forchildrn[j].PoRowInfo.VendorCorporationId,
                                    codeVal: forchildrn[j].PoRowDetailInfo.Code,
                                    POVal: forchildrn[j].PoRowInfo.Po,
                                    CbrandVar: forchildrn[j].PoRowInfo.Brand,
                                    CdivisionVar: forchildrn[j].PoRowInfo.DepartmentId,
                                    CsupplierVar: forchildrn[j].PoRowInfo.VendorCorporationId,
                                    AffiliatedCorpCollectionVar: forchildrn[j].SoRowInfo.AffiliatedCorpCollection,
                                    AffiliatedCorpWorkingVar: forchildrn[j].SoRowInfo.AffiliatedCorpWorking,
                                    isCollection: forchildrn[j].SoRowInfo.AffiliatedCorpCollection != null ? true : false,
                                    isWorking: forchildrn[j].SoRowInfo.AffiliatedCorpWorking != null ? true : false,
                                    BusinessNoInVar: forchildrn[j].SoRowDetailInfo.BusinessNoIn,
                                    BusinessNoOutVar: forchildrn[j].SoRowDetailInfo.BusinessNoOut,
                                    title: forchildrn[j].PoRowDetailInfo.Batch,
                                    CproductVar: forchildrn[j].PoRowDetailInfo.ProductName,
                                    compositionVar: forchildrn[j].Component,
                                    CproductCodeVar: forchildrn[j].PoRowDetailInfo.Code,
                                    CbatchVar: forchildrn[j].PoRowDetailInfo.Batch,
                                    CcolorVar: forchildrn[j].PoRowDetailInfo.Color,
                                    CnunberVar: forchildrn[j].PoRowDetailInfo.Number,
                                    CunitVar: forchildrn[j].PoRowDetailInfo.UnitId,
                                    poDetailVar: forchildrn[j].SoRowDetailInfo.Id,
                                    collapseName: forchildrn[j].SoRowDetailInfo.Id,
                                    statusColorVar: forchildrn[j].StatusColor,
                                    statusVar: forchildrn[j].StatusCompletion,
                                    statuslist: forchildrn[j].StatusList,
                                    ApproveColorVar: forchildrn[j].ApproveColor,
                                    ApproveImgVar: forchildrn[j].ApproveImg,
                                    ApproveVar: forchildrn[j].ApproveInfo,
                                    Approvelist: forchildrn[j].ApproveList,
                                    isRawFiberTypeId: forchildrn[j].PoRowDetailInfo.RawFiberTypeName != "" ? true : false,
                                    RawFiberTypeIdVar: forchildrn[j].PoRowDetailInfo.RawFiberTypeName,
                                    isVendorAddressId: forchildrn[j].PoRowDetailInfo.VendorAddressId != "" ? true : false,
                                    VendorAddressIdVar: forchildrn[j].PoRowDetailInfo.VendorAddressId,
                                    LockedStatusVar: forchildrn[j].SoRowDetailInfo.LockedStatus,
                                    isLastVar: false,
                                    VendorSectionSortVar: forchildrn[j].VendorSectionSort,

                                }
                                childrenlistVar.push(childrenVal);
                            } else {
                                var childrVal = {
                                    fileList: forchildrn[j].fileList,
                                    processList: forchildrn[j].processList,
                                    downlist: downlistval,
                                    date: getDateYYYMMDD(forchildrn[j].PoRowInfo.OrderDate),
                                    vendorVal: forchildrn[j].PoRowInfo.VendorCorporationId,
                                    codeVal: forchildrn[j].PoRowDetailInfo.Code,
                                    CbrandVar: forchildrn[j].PoRowInfo.Brand,
                                    CdivisionVar: forchildrn[j].PoRowInfo.DepartmentId,
                                    CsupplierVar: forchildrn[j].PoRowInfo.VendorCorporationId,
                                    AffiliatedCorpCollectionVar: "",
                                    AffiliatedCorpWorkingVar: "",
                                    isCollection: "",
                                    isWorking: "",
                                    BusinessNoInVar: "",
                                    BusinessNoOutVar: "",
                                    title: forchildrn[j].PoRowDetailInfo.Batch,
                                    CproductVar: forchildrn[j].PoRowDetailInfo.ProductName,
                                    compositionVar: forchildrn[j].Component,
                                    CproductCodeVar: forchildrn[j].PoRowDetailInfo.Code,
                                    CbatchVar: forchildrn[j].PoRowDetailInfo.Batch,
                                    CcolorVar: forchildrn[j].PoRowDetailInfo.Color,
                                    CnunberVar: forchildrn[j].PoRowDetailInfo.Number,
                                    CunitVar: forchildrn[j].PoRowDetailInfo.UnitId,
                                    poDetailVar: forchildrn[j].PoRowDetailInfo.Id,
                                    collapseName: forchildrn[j].PoRowDetailInfo.Id,
                                    statusColorVar: forchildrn[j].StatusColor,
                                    statusVar: forchildrn[j].StatusCompletion,
                                    statuslist: forchildrn[j].StatusList,
                                    ApproveColorVar: forchildrn[j].ApproveColor,
                                    ApproveImgVar: forchildrn[j].ApproveImg,
                                    ApproveVar: forchildrn[j].ApproveInfo,
                                    Approvelist: forchildrn[j].ApproveList,
                                    isRawFiberTypeId: forchildrn[j].PoRowDetailInfo.RawFiberTypeName != "" ? true : false,
                                    RawFiberTypeIdVar: forchildrn[j].PoRowDetailInfo.RawFiberTypeName,
                                    isVendorAddressId: forchildrn[j].PoRowDetailInfo.VendorAddressId != "" ? true : false,
                                    VendorAddressIdVar: forchildrn[j].PoRowDetailInfo.VendorAddressId,
                                    LockedStatusVar: forchildrn[j].PoRowDetailInfo.LockedStatus,
                                    isLastVar: true,
                                    VendorSectionSortVar: forchildrn[j].VendorSectionSort,
                                }
                                childrenlistVar.push(childrVal);
                            }
                        }
                        var eachStagePOVal = { title: eachspo[i].title, children: childrenlistVar };
                        eachspolist.push(eachStagePOVal);
                        this.InfoData.activities.push(eachStagePOVal);
                    }
                    //生成二维码
                    this.qrpocode = res.data.PoQRCode;
                    this.qrColorCode = res.data.PoQRColor;
                }).catch((erro) => { window.console.log(erro) });
                this.currentTime = getDate(new Date());
            },
            querySearchCodeAsync(queryString, cb) {
                var Coderestaurants = this.Coderestaurants;
                var results = queryString ? Coderestaurants.filter(this.createStateFilterCode(queryString)) : Coderestaurants;
                clearTimeout(this.Codetimeout);
                this.Codetimeout = setTimeout(() => {
                    cb(results.slice(0, 10));
                }, 1000 * Math.random());
            },
            createStateFilterCode(queryString) {
                return (Codestate) => {
                    return (Codestate.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            handleChange() {
                this.getPurOrdList();
            },
            handleSelect() {
                this.getPurOrdList();
            },//选中批号
            async getPurOrdList() {
                await PoAndDetailList(this.page.currentPage, this.page.pageSize, this.vendorVal.ids.toString(), this.codeVal, this.batchVal, this.colorVal, this.no_SeaCodeVal, this.SeaCodeVal, this.beginTimeVal, this.endTimeVal,).then(res => {
                    var stockListInfo = res.data.OrderStocks;
                    if (stockListInfo != null) {
                        this.potable = [];
                        for (var i = 0; i < stockListInfo.length; i++) {
                            this.potable.push({
                                id: stockListInfo[i].POInfoDetail.Id,
                                poDetailId: stockListInfo[i].POInfoDetail.Id,
                                purchaseOrderId: stockListInfo[i].POInfoDetail.PurchaseOrderId,
                                productName: stockListInfo[i].POInfoDetail.ProductName,
                                orderDate: stockListInfo[i].POInfo.OrderDate == '' ? '' : stockListInfo[i].POInfo.OrderDate.substr(0, 10),
                                po: stockListInfo[i].POInfo.Po,
                                vendorCorporationId: stockListInfo[i].POInfo.VendorCorporationId,
                                batch: stockListInfo[i].POInfoDetail.Batch,
                                color: stockListInfo[i].POInfoDetail.Color,
                                code: stockListInfo[i].POInfoDetail.Code,
                                status: stockListInfo[i].POInfoDetail.Status,
                                unitId: stockListInfo[i].POInfoDetail.UnitName,
                                number: stockListInfo[i].POInfoDetail.Number,
                            })
                        }
                    }
                    this.page.total = res.data.TotalCount;
                    this.page.pageSize = res.data.PageSize;
                    this.currentTime = getDate(new Date());
                    //只有一条数据
                    if (this.potable.length == 1) {
                        this.selectId = this.potable[0].poDetailId;
                        this.$router.push({ query: { ID: this.selectId } })
                        location.reload();
                    }
                    else {
                        this.dialogTableVisible = true;
                    }

                }).catch(erro => {
                    console.log(erro)
                })
            },
            currentChange(val) {
                this.page.currentPage = val
                this.getPurOrdList();
            },
            GetRowKeys(row) {
                return row.Id;
            },
            selectionChange(list) {
                if (list.length > 1) {
                    this.$refs.crud.toggleSelection();
                    this.$message({
                        message: this.$t('OnlyCheckedOne'),
                        type: "warning"
                    });
                }
                else { this.selectedData = list; }
            },//改变项值
            submitSelectData() {
                if (this.selectedData.length > 0) {
                    for (var i = 0; i < this.selectedData.length; i++) {
                        this.selectId = this.selectedData[i].poDetailId;
                        this.$router.push({ query: { ID: this.selectId } })
                        location.reload();
                    }
                }
                this.dialogTableVisible = false;
            },
            SelectData() {
                this.getPurOrdList();
            },
            showFileList(e) {
                this.fileDialogTableVisible = true;
                this.fileList = e;

            },
            showField(e) {
                this.fieldDialogTableVisible = true;
                this.fieldList = e;
            },
            noline() {
                this.$message({ showClose: true, message: this.$t('online'), type: "warning" });
            },
            print() {
                //查询PDF包
                GetOrderPackage(this.loadId).then(res => {
                    var o = res.data;
                    if (o.status == 0) {
                        if (o.id != '') {
                            //新增操作记录
                            let paramrow = {
                                orderPackageId: o.id,
                                type: 4,
                                remark: "",
                                status: 0,
                                createTime: '2021-05-01T08:00',
                                createUserid: '',
                                corporationId: ''
                            };
                            add(paramrow).then(() => { }).catch((erro) => { console.log(erro) });
                            //进行打印
                            if (o.path != '') {
                                let page = window.open(o.path);
                                setTimeout(() => {
                                    page.print();
                                }, 500)
                            }
                        }
                    }
                    else {
                        this.$message({ showClose: true, message: this.$t('NoPreviewedPrinting'), type: "warning" });
                    }
                });

            },
            GetReview() {
                GetOrderPackage(this.loadId).then(res => {
                    var o = res.data;
                    if (o.status == 0) {
                        this.$router.push({ path: '/pvh_operation/review', query: { OrderId: this.loadId } })
                    }
                    else {

                        this.$router.push({ path: '/pvh_operation/loadSpeed', query: { OrderId: this.loadId } })
                    }
                });
            },
            downloadFile(e) {
                var downpath = pvhApiBase + "Tracing/GetFilePathStream?id=" + e.FileURL;
                this.downloadPDF(downpath, e.Name);
            },
            downloadPDF(url, fileName) {
                fetch(url).then(function (response) {
                    if (response.ok) {
                        return response.arrayBuffer()
                    }
                    throw new Error('Network response was not ok.')
                }).then(function (arraybuffer) {
                    let blob = new Blob([arraybuffer], {
                        type: `application/pdf;charset-UTF-8` // word文档为msword,pdf文档为pdf
                    })
                    let objectURL = URL.createObjectURL(blob)
                    let downEle = document.createElement('a')
                    let fname = fileName // 下载文件的名字
                    // let fname = `download` // 下载文件的名字
                    downEle.href = objectURL
                    downEle.setAttribute('download', fname)
                    document.body.appendChild(downEle)
                    downEle.click()
                }).catch(function (error) {
                    console.log('There has been a problem with your fetch operation: ', error.message)
                })
            },
            handleClick() {
                if (this.activeName == "second") {
                    this.$router.push({ path: '/pvh_operation/orderTree', query: { ID: this.loadId } })
                }
            },
            getApprove() {
                this.IsApprove = true;
                //获取审批分类
                this.RejectCategoryData = [];
                TracingStringList("RejectCategory").then(res => {
                    for (var i = 0; i < res.data.length; i++) {
                        this.RejectCategoryData.push({
                            value: res.data[i]
                        })
                    }
                })
            },
            getCancelApprove() {
                this.IsApprove = false;
            },
            ApprovedsubmitForm() {
                this.IsApprove = false;
                var isBodyType = "isbody";
                this.$confirm(this.$t('IsDeclarationcompleted'), this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                    DecCompleted(this.loadId, isBodyType).then(() => {
                        this.init();
                        this.$message({ showClose: true, message: this.$t('OperationSuccess'), type: "success" });
                    });
                })
            },
            submitDownUnlock(e) {
                let paramrow = {
                    topPoDetailOrderId: this.loadId,
                    detailOrderId: e.PoRowDetailInfo.Id,
                    approvedObject: '',
                    rejectCategory: "",
                    rejectRemark: "Operation Unlock",
                    remarks: '',
                    status: 0,
                };
                addUnlockPo(paramrow).then(() => {
                    this.$message({
                        message: this.$t('OperationSuccess'),
                        type: "success"
                    });
                    this.init();
                }).catch((erro) => { console.log(erro) });
            },
            submitDownLock(e) {
                let paramrow = {
                    topPoDetailOrderId: this.loadId,
                    detailOrderId: e.PoRowDetailInfo.Id,
                    approvedObject: '',
                    rejectCategory: "",
                    rejectRemark: "Operation Lock",
                    remarks: '',
                    status: 0,
                };
                addLockPo(paramrow).then(() => {
                    this.$message({
                        message: this.$t('OperationSuccess'),
                        type: "success"
                    });
                    this.init();
                }).catch((erro) => { console.log(erro) });
            },
            RejectDownForm(e) {
                var info = e;
                this.RejectdialogTableVisible = true;
                this.RejectEdit.DetailOrderId = info.PoRowDetailInfo.Id;
                this.RejectEdit.ApprovedObject = '';
                this.RejectEdit.ApprovedObjectName = info.PoRowInfo.VendorCorporationId;
                this.RejectEdit.RejectCategory = '';
                this.RejectEdit.RejectRemark = '';
                this.RejectEdit.isPo = true;
                this.getRejectList();
            },
            RejectForm(e) {
                var info = e;
                this.RejectdialogTableVisible = true;
                this.RejectEdit.DetailOrderId = info.poDetailVar;
                this.RejectEdit.ApprovedObject = '';
                this.RejectEdit.ApprovedObjectName = info.vendorVal;
                this.RejectEdit.RejectCategory = '';
                this.RejectEdit.RejectRemark = '';
                this.RejectEdit.isPo = false;
                this.getRejectList();
            },
            submitUnlock(e) {
                let paramrow = {
                    topPoDetailOrderId: this.loadId,
                    detailOrderId: e.poDetailVar,
                    approvedObject: '',
                    rejectCategory: "",
                    rejectRemark: "Operation Unlock",
                    remarks: '',
                    status: 0,
                };
                addUnlockSo(paramrow).then(() => {
                    this.$message({
                        message: this.$t('OperationSuccess'),
                        type: "success"
                    });
                    this.init();
                }).catch((erro) => { console.log(erro) });
            },
            submitLock(e) {
                let paramrow = {
                    topPoDetailOrderId: this.loadId,
                    detailOrderId: e.poDetailVar,
                    approvedObject: '',
                    rejectCategory: "",
                    rejectRemark: "Operation Lock",
                    remarks: '',
                    status: 0,
                };
                addLockSo(paramrow).then(() => {
                    this.$message({
                        message: this.$t('OperationSuccess'),
                        type: "success"
                    });
                    this.init();
                }).catch((erro) => { console.log(erro) });
            },
            submitReject() {
                let paramrow = {
                    topPoDetailOrderId: this.loadId,
                    detailOrderId: this.RejectEdit.DetailOrderId,
                    approvedObject: '',
                    rejectCategory: this.RejectEdit.RejectCategory,
                    rejectRemark: this.RejectEdit.RejectRemark,
                    remarks: '',
                    status: 0,
                };
                if (this.RejectEdit.isPo) {
                    addApprovesPo(paramrow).then(() => {
                        this.$message({
                            message: this.$t('OperationSuccess'),
                            type: "success"
                        });
                        this.RejectdialogTableVisible = false;
                        this.init();
                    }).catch((erro) => { console.log(erro) });}
                else {
                    addApprovesSo(paramrow).then(() => {
                        this.$message({
                            message: this.$t('OperationSuccess'),
                            type: "success"
                        });
                        this.RejectdialogTableVisible = false;
                        this.init();
                    }).catch((erro) => { console.log(erro) });
                }

            },
            async getRejectList() {
                await ListApproves(this.RejectEdit.DetailOrderId, this.pageRejectList.currentPage, this.pageRejectList.pageSize).then(res => {
                    var RejectInfo = res.data.OrderApproves;
                    this.tableDataRejectList = RejectInfo;
                    this.pageRejectList.total = res.data.TotalCount;
                    this.pageRejectList.pageSize = res.data.PageSize;
                }).catch(erro => {
                    console.log(erro)
                })
            },
            currentRejectListChange(val) {
                this.pageRejectList.currentPage = val
                this.getRejectList();
            },
            RejectCategorySearch(queryString, cb) {
                var restaurants = this.RejectCategoryData;
                var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results.slice(0, 10));
            },
            createFilter(queryString) {
                return (restaurant) => {
                    return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            formatterApprove(e) {
                var helpReturn = e;
                if (helpReturn.Param1 == "Reject") {
                    return "× " + this.$t('Reject') + " : " + helpReturn.Param2 + "-" + helpReturn.Param3;
                }
                else if (helpReturn.Param1 == "Resubmit") {
                    return "↑ " + this.$t('Resubmit') + " : " + helpReturn.Param2;
                }
                else {
                    return "";
                }
            },
            formatterColumn(e) {
                var helpReturn = e;
                if (helpReturn.Param1 == "HaveUpinfo") {
                    return "√ " + helpReturn.Param2 + " : " + this.$t('HaveUpinfo');
                }
                else if (helpReturn.Param1 == "NoNeedUpinfo") {
                    return "√" + this.$t('NoNeedUpinfo');
                }
                else if (helpReturn.Param1 == "NoUpinfo") {
                    return "× " + helpReturn.Param2 + " : " + this.$t('NoUpinfo');
                }
                else if (helpReturn.Param1 == "NoWriteSO") {
                    return "×" + this.$t('NoWriteSO');
                }
                else if (helpReturn.Param1 == "HaveWriteSO") {
                    return "√" + this.$t('HaveWriteSO');
                }
                else if (helpReturn.Param1 == "NoWriteComponent") {
                    return "×" + this.$t('NoWriteComponent');
                }
                else if (helpReturn.Param1 == "HaveWriteComponent") {
                    return "√" + this.$t('HaveWriteComponent');
                }
                else if (helpReturn.Param1 == "NoRelation") {
                    return "×" + this.$t('NoRelation');
                }
                else if (helpReturn.Param1 == "HaveRelation") {
                    return "√" + this.$t('HaveRelation');
                }
                else {
                    return "";
                }
            },
            onPrint() {
                this.$print(this.$refs.qrCodeUrl)
            },
        }
    }
</script>