<template>
  <div>
    <basic-container>
      <div>
        <span>tokenName：</span>
        <el-input v-model="tokenName" placeholder="请输入token名字" style="width: 200px;height:50px"></el-input>
      </div>
      <div style="marginTop: 10px">过期时间： 2021-10-31</div>
      <!-- 树结构 -->
      <div style="marginTop: 20px;display: flex;">
        权限：
      <el-tree
        :data="data"
        show-checkbox
        node-key="id"
        ref="tree"
        highlight-current
        :props="defaultProps">
      </el-tree>
      </div>
     <el-button type="info" @click="getToken"  style="marginTop: 20px;marginLeft:50px">生成token</el-button>
     <div>
        <span>token值：</span>
        <el-input v-model="tokenName"  style="marginTop: 20px;width: 200px;height:50px"></el-input>
      </div>
      <!-- 表结构 -->
        <el-table
          :data="tableData"
          border
          style="width: 65%">
          <el-table-column
            prop="date"
            label="tokenName"
            width="180">
          </el-table-column>
          <el-table-column
            prop="name"
            label="过期时间"
            width="180">
          </el-table-column>
          <el-table-column
            prop="address"
            label="创建时间"
            width="240">
      </el-table-column>
       <el-table-column label="操作">
      <template slot-scope="scope">
        <el-button
          size="mini"
          @click="handleEdit(scope.$index, scope.row)">移除</el-button>
      </template>
    </el-table-column>
        </el-table>
    </basic-container>
  </div>
</template>
<script>
import { tokenList} from '@/api/enumeration'
  export default {
    data() {
      return {
         tokenName: '', //tokenName 双向绑定的值
         // 树结构数据
         data: [{
          id: 1,
          label: '一级 1',
          children: [{
            id: 4,
            label: '二级 1-1',
            children: [{
              id: 9,
              label: '三级 1-1-1'
            }, {
              id: 10,
              label: '三级 1-1-2'
            }]
          }]
        }, {
          id: 2,
          label: '一级 2',
          children: [{
            id: 5,
            label: '二级 2-1'
          }, {
            id: 6,
            label: '二级 2-2'
          }]
        }, {
          id: 3,
          label: '一级 3',
          children: [{
            id: 7,
            label: '二级 3-1'
          }, {
            id: 8,
            label: '二级 3-2'
          }]
        }],
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        // 表结构数据
         tableData: [{
            date: '2016-05-02',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1518 弄'
          }, {
            date: '2016-05-04',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1517 弄'
          }, {
            date: '2016-05-01',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1519 弄'
          }, {
            date: '2016-05-03',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1516 弄'
          }]
      };
      },
    created() {
        tokenList('123').then(res => {
          console.log('token的数组',res);
        })
      },
    methods: {
      handleEdit(index, row) {
        console.log(index, row);
      },
      //获取token
      getToken() {
        console.log('获取token',this.tokenName);
      }
  }
  };
</script>
 