var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "10px" }
              },
              [_vm._v(_vm._s(_vm.$t("SALES ORDER")))]
            )
          ]),
          [
            _c(
              "el-row",
              { staticClass: "topFromSearch", attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  {
                    staticClass: "fromOutDiv",
                    attrs: { xs: 24, sm: 17, md: 17, lg: 19, xl: 20 }
                  },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("SO")) + ":")]),
                        _c("el-input", {
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.soVal,
                            callback: function($$v) {
                              _vm.soVal = $$v
                            },
                            expression: "soVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("BUSINESS SERIAL NUMBER INTERNAL")) +
                              ":"
                          )
                        ]),
                        _c("el-input", {
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.businessnoinVal,
                            callback: function($$v) {
                              _vm.businessnoinVal = $$v
                            },
                            expression: "businessnoinVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("BUSINESS SERIAL NUMBER EXTERNAL")) +
                              ":"
                          )
                        ]),
                        _c("el-input", {
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.businessnooutVal,
                            callback: function($$v) {
                              _vm.businessnooutVal = $$v
                            },
                            expression: "businessnooutVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("POProductCode")) + ":")
                        ]),
                        _c("el-input", {
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.codeVal,
                            callback: function($$v) {
                              _vm.codeVal = $$v
                            },
                            expression: "codeVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("POBATCH")) + ":")]),
                        _c("el-input", {
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.batchVal,
                            callback: function($$v) {
                              _vm.batchVal = $$v
                            },
                            expression: "batchVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", { attrs: { id: "venSpan" } }, [
                          _vm._v(_vm._s(_vm.$t("FROM")) + ":")
                        ]),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              multiple: "",
                              "collapse-tags": "",
                              placeholder: "",
                              clearable: "true"
                            },
                            on: {
                              focus: function($event) {
                                return _vm.focusDiv($event)
                              },
                              blur: function($event) {
                                return _vm.blurDiv($event)
                              },
                              change: _vm.venchang
                            },
                            model: {
                              value: _vm.fromVal.ids,
                              callback: function($$v) {
                                _vm.$set(_vm.fromVal, "ids", $$v)
                              },
                              expression: "fromVal.ids"
                            }
                          },
                          _vm._l(this.fromData, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("BEINGTIME")) + ":")]),
                        _c("el-date-picker", {
                          staticClass: "dataicon",
                          staticStyle: { width: "100%" },
                          attrs: { type: "date", "prefix-icon": "" },
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.beginTimeVal,
                            callback: function($$v) {
                              _vm.beginTimeVal =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "beginTimeVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("ENDTIME")) + ":")]),
                        _c("el-date-picker", {
                          staticClass: "dataicon",
                          staticStyle: { width: "100%" },
                          attrs: { type: "date", "prefix-icon": "" },
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.endTimeVal,
                            callback: function($$v) {
                              _vm.endTimeVal =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "endTimeVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("COLOR")) + ":")]),
                        _c("el-input", {
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.colorVal,
                            callback: function($$v) {
                              _vm.colorVal = $$v
                            },
                            expression: "colorVal"
                          }
                        })
                      ],
                      1
                    ),
                    !_vm.isCanAgent
                      ? _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                          },
                          [
                            _c("span", { attrs: { id: "otherStatusSpan" } }, [
                              _vm._v(_vm._s(_vm.$t("STATE")) + ":")
                            ]),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "collapse-tags": "",
                                  placeholder: "",
                                  clearable: "true"
                                },
                                on: {
                                  focus: function($event) {
                                    return _vm.focusDiv($event)
                                  },
                                  blur: function($event) {
                                    return _vm.blurDiv($event)
                                  },
                                  change: _vm.otherStatuschang
                                },
                                model: {
                                  value: _vm.otherStatusVal,
                                  callback: function($$v) {
                                    _vm.otherStatusVal = $$v
                                  },
                                  expression: "otherStatusVal"
                                }
                              },
                              _vm._l(this.otherStatusData, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isCanAgent
                      ? _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                          },
                          [
                            _c("span", { attrs: { id: "traingStatusSpan" } }, [
                              _vm._v(_vm._s(_vm.$t("STATE")) + ":")
                            ]),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "collapse-tags": "",
                                  placeholder: "",
                                  clearable: "true"
                                },
                                on: {
                                  focus: function($event) {
                                    return _vm.focusDiv($event)
                                  },
                                  blur: function($event) {
                                    return _vm.blurDiv($event)
                                  },
                                  change: _vm.traingStatuschang
                                },
                                model: {
                                  value: _vm.traingStatusVal,
                                  callback: function($$v) {
                                    _vm.traingStatusVal = $$v
                                  },
                                  expression: "traingStatusVal"
                                }
                              },
                              _vm._l(this.traingStatusData, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("UPDATE START DATE")) + ":")
                        ]),
                        _c("el-date-picker", {
                          staticClass: "dataicon",
                          staticStyle: { width: "100%" },
                          attrs: { type: "date", "prefix-icon": "" },
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.UTbeginTimeVal,
                            callback: function($$v) {
                              _vm.UTbeginTimeVal =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "UTbeginTimeVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("UPDATE END DATE")) + ":")
                        ]),
                        _c("el-date-picker", {
                          staticClass: "dataicon",
                          staticStyle: { width: "100%" },
                          attrs: { type: "date", "prefix-icon": "" },
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.UTendTimeVal,
                            callback: function($$v) {
                              _vm.UTendTimeVal =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "UTendTimeVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        staticStyle: {
                          "justify-content": "flex-start",
                          height: "38px",
                          "align-items": "center",
                          display: "flex"
                        },
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c(
                          "el-checkbox",
                          {
                            staticClass: "othChe",
                            model: {
                              value: _vm.StatusVal,
                              callback: function($$v) {
                                _vm.StatusVal = $$v
                              },
                              expression: "StatusVal"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("DeletedData")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    staticStyle: { "padding-top": "10px" },
                    attrs: { xs: 24, sm: 7, md: 7, lg: 5, xl: 4 }
                  },
                  [
                    _c("el-button", {
                      staticClass: "moreBtn",
                      attrs: {
                        size: "small",
                        icon: "iconfont icon-24px",
                        circle: ""
                      },
                      on: { click: _vm.moreFrom }
                    }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small", plain: "" },
                        on: { click: _vm.search }
                      },
                      [_vm._v(_vm._s(_vm.$t("SEARCH")))]
                    ),
                    _vm.isCanAgent
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              type: "primary",
                              size: "small",
                              icon: "el-icon-plus"
                            },
                            on: { click: _vm.addPurOrd }
                          },
                          [_vm._v(_vm._s(_vm.$t("PO AGENT")))]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c("avue-crud", {
              ref: "crud",
              staticClass: "pvhTable",
              attrs: {
                option: _vm.setData.tableOpt,
                data: _vm.tableData,
                "row-style": _vm.rowStyle,
                page: _vm.page
              },
              on: {
                "update:page": function($event) {
                  _vm.page = $event
                },
                "selection-change": _vm.selectionChange,
                "current-change": _vm.currentChange,
                "row-update": _vm.handleUpdate
              },
              scopedSlots: _vm._u([
                {
                  key: "Speed",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    var size = ref.size
                    var type = ref.type
                    return [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: { effect: "light", placement: "bottom-start" }
                        },
                        [
                          _vm._l(row.SpeedContent, function(item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                attrs: { slot: "content" },
                                slot: "content"
                              },
                              [_vm._v(_vm._s(_vm.formatColumn(item)))]
                            )
                          }),
                          _c("el-link", { attrs: { type: "warning" } }, [
                            _vm._v(_vm._s(row.Speed))
                          ])
                        ],
                        2
                      )
                    ]
                  }
                },
                {
                  key: "menu",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    var size = ref.size
                    var type = ref.type
                    return [
                      row.ApproveStatus != 100 && row.LockedStatus != 1
                        ? _c("el-button", {
                            attrs: {
                              icon: "el-icon-edit-outline",
                              type: "text",
                              size: "medium",
                              title: _vm.$t("Edit")
                            },
                            on: {
                              click: function($event) {
                                return _vm.$refs.crud.rowEdit(row, index)
                              }
                            }
                          })
                        : _vm._e(),
                      row.ApproveStatus == 100 || row.LockedStatus == 1
                        ? _c("el-button", {
                            attrs: {
                              icon: "el-icon-reading",
                              type: "text",
                              size: "medium",
                              title: _vm.$t("Look Over")
                            },
                            on: {
                              click: function($event) {
                                return _vm.$refs.crud.rowView(row, index)
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.isCanAgent
                        ? _c("el-button", {
                            attrs: {
                              icon: "el-icon-edit",
                              type: "text",
                              size: "medium",
                              title: _vm.$t("EDIT AGENT PO")
                            },
                            on: {
                              click: function($event) {
                                return _vm.editPur(row)
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.isCanAgent &&
                      row.ApproveStatus != 100 &&
                      row.LockedStatus != 1
                        ? _c("el-button", {
                            attrs: {
                              icon: "el-icon-delete-solid",
                              type: "text",
                              size: "medium",
                              title: _vm.$t("Delete")
                            },
                            on: {
                              click: function($event) {
                                return _vm.delPur(row)
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "DestinationCountryHeader",
                  fn: function(ref) {
                    var column = ref.column
                    return [
                      _c("label", [_vm._v(_vm._s(column.label))]),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: { effect: "dark", placement: "bottom" }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("MARKET EXPLAIN")) +
                                  "\n                        "
                              )
                            ]
                          ),
                          _c("i", {
                            staticClass: "el-icon-warning",
                            staticStyle: {
                              "margin-left": "3px",
                              "font-size": "14px"
                            }
                          })
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "expand",
                  fn: function(props) {
                    return [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: props.row.Details,
                            "header-cell-style": _vm.tableHeaderColor
                          }
                        },
                        [
                          _c("el-table-column", { attrs: { type: "index" } }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "Speed",
                              align: "center",
                              label: _vm.$t("DEGREE OF COMPLETION")
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    var index = ref.index
                                    var size = ref.size
                                    var type = ref.type
                                    return [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "light",
                                            placement: "bottom-start"
                                          }
                                        },
                                        [
                                          _vm._l(row.SpeedContent, function(
                                            item,
                                            index
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                attrs: { slot: "content" },
                                                slot: "content"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.formatColumn(item))
                                                )
                                              ]
                                            )
                                          }),
                                          _c(
                                            "el-link",
                                            { attrs: { type: "warning" } },
                                            [_vm._v(_vm._s(row.Speed))]
                                          )
                                        ],
                                        2
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "BusinessNoIn",
                              label: _vm.$t("BUSINESS SERIAL NUMBER INTERNAL")
                            }
                          }),
                          _vm.showOut
                            ? _c("el-table-column", {
                                attrs: {
                                  prop: "BusinessNoOut",
                                  label: _vm.$t(
                                    "BUSINESS SERIAL NUMBER EXTERNAL"
                                  )
                                }
                              })
                            : _vm._e(),
                          _c("el-table-column", {
                            attrs: {
                              prop: "Code",
                              label: _vm.$t("POProductCode"),
                              width: "120"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "OutProductName",
                              label: _vm.$t("POProductName")
                            }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "Batch", label: _vm.$t("POBATCH") }
                          }),
                          _vm.showOut
                            ? _c("el-table-column", {
                                attrs: {
                                  prop: "Number",
                                  label: _vm.$t("Number")
                                }
                              })
                            : _vm._e(),
                          _vm.showOut
                            ? _c("el-table-column", {
                                attrs: {
                                  prop: "UnitName",
                                  label: _vm.$t("UNIT")
                                }
                              })
                            : _vm._e(),
                          _c("el-table-column", {
                            attrs: { prop: "Color", label: _vm.$t("COLOR") }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "ProductName",
                              label: _vm.$t("InternalProductName")
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "DestinationCountryName",
                              label: _vm.$t("MARKET")
                            }
                          }),
                          !_vm.isCanAgent
                            ? _c("el-table-column", {
                                attrs: {
                                  prop: "Status",
                                  align: "center",
                                  label: _vm.$t("STATUS"),
                                  formatter: _vm.formatterColumn
                                }
                              })
                            : _vm._e(),
                          _vm.isCanAgent
                            ? _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "TrackingApproveStatus",
                                  label: _vm.$t("STATUS"),
                                  formatter: _vm.formatterTrackingStatus
                                }
                              })
                            : _vm._e(),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: _vm.$t("UPDATE TIME"),
                              prop: "LinkageUpdateDateTime",
                              formatter: _vm.formatDate
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "200px;",
                              align: "center",
                              label: _vm.$t("OPERATION")
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    var index = ref.index
                                    var size = ref.size
                                    var type = ref.type
                                    return [
                                      _c("el-button", {
                                        attrs: {
                                          icon: "el-icon-coin",
                                          type: "text",
                                          size: "medium",
                                          title: _vm.$t(
                                            "Proportion of raw materials"
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.fibred(
                                              row,
                                              index,
                                              size,
                                              type
                                            )
                                          }
                                        }
                                      }),
                                      _c("el-button", {
                                        attrs: {
                                          icon: "icon-shangchuan",
                                          type: "text",
                                          size: "medium",
                                          title: _vm.$t("Upload")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.upload(
                                              row,
                                              index,
                                              size,
                                              type
                                            )
                                          }
                                        }
                                      }),
                                      _c("el-button", {
                                        attrs: {
                                          icon: "el-icon-edit-outline",
                                          type: "text",
                                          size: "medium",
                                          title: _vm.$t("Edit")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.editSOinfo(
                                              row,
                                              index,
                                              size,
                                              type
                                            )
                                          }
                                        }
                                      }),
                                      row.ApproveStatus < 0
                                        ? _c("el-button", {
                                            attrs: {
                                              icon: "el-icon-thumb",
                                              type: "text",
                                              size: "medium",
                                              title: _vm.$t("VIEW AUDIT INFO")
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.ViewAuditInfo(
                                                  row,
                                                  index,
                                                  size,
                                                  type
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      _c("el-button", {
                                        attrs: {
                                          icon: "icon-guanlian",
                                          type: "text",
                                          size: "medium",
                                          title: _vm.$t("Relevance")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.rele(
                                              row,
                                              index,
                                              size,
                                              type
                                            )
                                          }
                                        }
                                      }),
                                      _c("el-button", {
                                        attrs: {
                                          icon: "icon-dingwei1",
                                          type: "text",
                                          size: "medium",
                                          title: _vm.$t("Tracing")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.tracingPur(row)
                                          }
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "menuForm",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    var type = ref.type
                    return [
                      type == "add"
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-circle-plus-outline",
                                size: "small"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.crud.rowSave()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Hold")))]
                          )
                        : _vm._e(),
                      type == "edit"
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-circle-check",
                                size: "small"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.crud.rowUpdate()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Hold")))]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-close",
                            size: "small"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$refs.crud.closeDialog()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Cancel")))]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.obj,
                callback: function($$v) {
                  _vm.obj = $$v
                },
                expression: "obj"
              }
            }),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.$t("ChooseUploadBrand"),
                  visible: _vm.BranddialogTableVisible,
                  "modal-append-to-body": false,
                  width: "30%",
                  top: "1vh"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.BranddialogTableVisible = $event
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("Brand"), "label-width": "120px" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "calc(100% - 115px)" },
                        attrs: { filterable: "", placeholder: "" },
                        model: {
                          value: _vm.brandChoose,
                          callback: function($$v) {
                            _vm.brandChoose = $$v
                          },
                          expression: "brandChoose"
                        }
                      },
                      _vm._l(_vm.brandList, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: { "text-align": "center", padding: "50px 0" }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            _vm.submitBrandChoose()
                            _vm.BranddialogTableVisible = false
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Yes")))]
                    ),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            _vm.BranddialogTableVisible = false
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Cancel")))]
                    )
                  ],
                  1
                ),
                _c("el-form-item")
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.$t("EditSOinformation"),
                  visible: _vm.SoDetaildialogTableVisible,
                  "modal-append-to-body": false,
                  width: "70%",
                  top: "1vh"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.SoDetaildialogTableVisible = $event
                  }
                }
              },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      model: _vm.SoDetailEdit,
                      rules: _vm.rules,
                      "label-position": "top",
                      "label-width": "150px"
                    }
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("POProductCode"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: _vm.$t("POProductCode") } },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: { disabled: true },
                                      model: {
                                        value: _vm.SoDetailEdit.Code,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.SoDetailEdit,
                                            "Code",
                                            $$v
                                          )
                                        },
                                        expression: "SoDetailEdit.Code"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("POProductName"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: _vm.$t("POProductName") } },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: { disabled: true },
                                      model: {
                                        value: _vm.SoDetailEdit.OutProductName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.SoDetailEdit,
                                            "OutProductName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "SoDetailEdit.OutProductName"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t(
                                    "BUSINESS SERIAL NUMBER INTERNAL"
                                  ),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "BUSINESS SERIAL NUMBER INTERNAL"
                                      )
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: { disabled: true },
                                      model: {
                                        value: _vm.SoDetailEdit.BusinessNoIn,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.SoDetailEdit,
                                            "BusinessNoIn",
                                            $$v
                                          )
                                        },
                                        expression: "SoDetailEdit.BusinessNoIn"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t(
                                    "BUSINESS SERIAL NUMBER EXTERNAL"
                                  ),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "BUSINESS SERIAL NUMBER EXTERNAL"
                                      ),
                                      prop: "BusinessNoOut"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      model: {
                                        value: _vm.SoDetailEdit.BusinessNoOut,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.SoDetailEdit,
                                            "BusinessNoOut",
                                            $$v
                                          )
                                        },
                                        expression: "SoDetailEdit.BusinessNoOut"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("POBATCH"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: _vm.$t("POBATCH") } },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: { disabled: true },
                                      model: {
                                        value: _vm.SoDetailEdit.Batch,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.SoDetailEdit,
                                            "Batch",
                                            $$v
                                          )
                                        },
                                        expression: "SoDetailEdit.Batch"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("InternalProductName"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("InternalProductName")
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: {
                                          width: "calc(100% - 115px)"
                                        },
                                        attrs: {
                                          filterable: "",
                                          "allow-create": "",
                                          "default-first-option": "",
                                          placeholder: ""
                                        },
                                        on: { change: _vm.selectChanged },
                                        model: {
                                          value: _vm.SoDetailEdit.ProductCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.SoDetailEdit,
                                              "ProductCode",
                                              $$v
                                            )
                                          },
                                          expression: "SoDetailEdit.ProductCode"
                                        }
                                      },
                                      _vm._l(_vm.productData, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: _vm.$t("Number") } },
                              [
                                _c("el-input-number", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.SoDetailEdit.Number,
                                    callback: function($$v) {
                                      _vm.$set(_vm.SoDetailEdit, "Number", $$v)
                                    },
                                    expression: "SoDetailEdit.Number"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: _vm.$t("UNIT") } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.SoDetailEdit.UnitName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.SoDetailEdit,
                                        "UnitName",
                                        $$v
                                      )
                                    },
                                    expression: "SoDetailEdit.UnitName"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: _vm.$t("COLOR") } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.SoDetailEdit.Color,
                                    callback: function($$v) {
                                      _vm.$set(_vm.SoDetailEdit, "Color", $$v)
                                    },
                                    expression: "SoDetailEdit.Color"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("el-col", { attrs: { span: 12, sm: 24, md: 12 } })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "center",
                          padding: "50px 0"
                        }
                      },
                      [
                        !_vm.isLocked
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    _vm.submitInfoSO()
                                    _vm.SoDetaildialogTableVisible = false
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Yes")))]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function($event) {
                                _vm.SoDetaildialogTableVisible = false
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("Cancel")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.$t("Proportion of raw materials"),
                  visible: _vm.fibredialogTableVisible,
                  "modal-append-to-body": false,
                  width: "70%",
                  top: "20vh"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.fibredialogTableVisible = $event
                  }
                }
              },
              [
                _c(
                  "avue-crud",
                  {
                    ref: "crudComponent",
                    attrs: {
                      option: _vm.setDataComponent.tableOpt,
                      data: _vm.tableDataComponent,
                      "row-style": _vm.rowStyle,
                      page: _vm.pageComponent
                    },
                    on: {
                      "update:page": function($event) {
                        _vm.pageComponent = $event
                      },
                      "current-change": _vm.currentComponentChange,
                      "row-update": _vm.handleComponentUpdate,
                      "row-del": _vm.handDel,
                      "row-save": _vm.handleSave
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "CategoryForm",
                        fn: function(scope) {
                          return [
                            _c("el-autocomplete", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "fetch-suggestions": _vm.SearchCategoryInfo
                              },
                              model: {
                                value: _vm.objComponent.Category,
                                callback: function($$v) {
                                  _vm.$set(_vm.objComponent, "Category", $$v)
                                },
                                expression: "objComponent.Category"
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "menu",
                        fn: function(ref) {
                          var row = ref.row
                          var index = ref.index
                          var size = ref.size
                          var type = ref.type
                          return [
                            row.ParentId != "0" && !_vm.isLocked
                              ? _c("el-button", {
                                  attrs: {
                                    icon: "el-icon-edit-outline",
                                    type: "text",
                                    size: "medium",
                                    title: _vm.$t("Edit")
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.$refs.crudComponent.rowEdit(
                                        row,
                                        index
                                      )
                                      _vm.isParent = false
                                    }
                                  }
                                })
                              : _vm._e(),
                            !_vm.isLocked
                              ? _c("el-button", {
                                  attrs: {
                                    icon: "el-icon-delete-solid",
                                    type: "text",
                                    size: "medium",
                                    title: _vm.$t("Delete")
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handDel(row, index)
                                    }
                                  }
                                })
                              : _vm._e(),
                            row.ParentId == "0" && !_vm.isLocked
                              ? _c("el-button", {
                                  attrs: {
                                    icon: "el-icon-plus",
                                    type: "text",
                                    size: "medium",
                                    title: _vm.$t("Add Details")
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.$refs.crudComponent.rowAdd()
                                      _vm.parentId = row.Id
                                      _vm.isParent = false
                                    }
                                  }
                                })
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "menuForm",
                        fn: function(ref) {
                          var row = ref.row
                          var index = ref.index
                          var type = ref.type
                          return [
                            type == "add"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-circle-plus-outline",
                                      size: "small"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.$refs.crudComponent.rowSave()
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Hold")))]
                                )
                              : _vm._e(),
                            type == "edit"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-circle-check",
                                      size: "small"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.$refs.crudComponent.rowUpdate()
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Hold")))]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-circle-close",
                                  size: "small"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$refs.crudComponent.closeDialog()
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Cancel")))]
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.objComponent,
                      callback: function($$v) {
                        _vm.objComponent = $$v
                      },
                      expression: "objComponent"
                    }
                  },
                  [
                    _c(
                      "template",
                      {
                        staticStyle: { width: "calc(100% - 80px)" },
                        slot: "menuLeft"
                      },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              {
                                attrs: {
                                  xs: 24,
                                  sm: 16,
                                  md: 16,
                                  lg: 16,
                                  xl: 16
                                }
                              },
                              [
                                !_vm.isLocked
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                          icon: "el-icon-plus"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.$refs.crudComponent.rowAdd()
                                            _vm.parentId = "0"
                                            _vm.isParent = true
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("ADD")))]
                                    )
                                  : _vm._e(),
                                _c("el-tag", { attrs: { size: "small" } }, [
                                  _vm._v(_vm._s(_vm.$t("component such as")))
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.$t("REJECT"),
                  visible: _vm.RejectdialogTableVisible,
                  "modal-append-to-body": false,
                  width: "70%",
                  top: "1vh"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.RejectdialogTableVisible = $event
                  }
                }
              },
              [
                !_vm.isViewAudit
                  ? _c(
                      "el-form",
                      {
                        ref: "Rejectrules",
                        attrs: {
                          model: _vm.RejectEdit,
                          rules: _vm.Rejectrules,
                          "label-position": "top",
                          "label-width": "150px"
                        }
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24, sm: 24, md: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("RESUBMIT DESCRIPTION"),
                                      required: true,
                                      prop: "Remarks",
                                      rules: _vm.Rejectrules.Remarks
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        type: "textarea",
                                        maxlength: "300",
                                        "show-word-limit": "",
                                        autosize: { minRows: 4, maxRows: 6 }
                                      },
                                      model: {
                                        value: _vm.RejectEdit.Remarks,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.RejectEdit,
                                            "Remarks",
                                            $$v
                                          )
                                        },
                                        expression: "RejectEdit.Remarks"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              padding: "20px 0"
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.submitReject("Rejectrules")
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Yes")))]
                            ),
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.RejectdialogTableVisible = false
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Cancel")))]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("avue-crud", {
                  attrs: {
                    option: _vm.tableOptRejectList,
                    data: _vm.tableDataRejectList,
                    "row-style": _vm.rowStyle,
                    page: _vm.pageRejectList
                  },
                  on: {
                    "update:page": function($event) {
                      _vm.pageRejectList = $event
                    },
                    "current-change": _vm.currentRejectListChange
                  }
                })
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }