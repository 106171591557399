var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            {
              staticStyle: { display: "flex", "margin-bottom": "20px" },
              attrs: { sm: 24, md: 12, lg: 12 }
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.timeStart()
                    }
                  }
                },
                [_vm._v("Start")]
              ),
              _c("el-button", { on: { click: _vm.stop } }, [_vm._v("stop")]),
              _c("el-button", { on: { click: _vm.reset } }, [_vm._v("reset")]),
              _c("el-button", { on: { click: _vm.timeStart } }, [
                _vm._v("timeStart")
              ]),
              _c("el-button", { on: { click: _vm.cellMap } }, [
                _vm._v("cellMap")
              ]),
              _c("el-button", { on: { click: _vm.getStart } }, [
                _vm._v("getStart")
              ])
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticStyle: { display: "flex", "margin-bottom": "20px" },
              attrs: { sm: 24, md: 12, lg: 12 }
            },
            [
              _c("span", { staticClass: "spanRight" }, [
                _c("i", { staticClass: "el-icon-brush" }),
                _vm._v(" " + _vm._s(_vm.str))
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            {
              staticStyle: { display: "flex", "margin-bottom": "20px" },
              attrs: { sm: 24, md: 12, lg: 8 }
            },
            [
              _c("div", { attrs: { id: "all" } }, [
                _c("div", { attrs: { id: "allmap" } })
              ])
            ]
          ),
          _c("el-col", {
            staticStyle: { display: "flex", "margin-bottom": "20px" },
            attrs: { sm: 24, md: 12, lg: 8 }
          })
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            {
              staticStyle: { display: "flex", "margin-bottom": "20px" },
              attrs: { sm: 24, md: 24, lg: 24 }
            },
            [
              _c("el-input", {
                staticStyle: { height: "800px", width: "1600px" },
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 2, maxRows: 50 },
                  placeholder: ""
                },
                model: {
                  value: _vm.textarea2,
                  callback: function($$v) {
                    _vm.textarea2 = $$v
                  },
                  expression: "textarea2"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }