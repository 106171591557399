<template>
  <div>
    <basic-container>
    <avue-crud :option="tableOption"
                 :data="tableData"
                 :page="page"
                 ref="crud"
                 @current-change="currentChange"
                 @size-change="sizeChange" v-loading="loading">
            <!--<template slot="status" slot-scope="scope">
                <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                @change="change(scope.row)">
                </el-switch>
            </template>-->
        <!--<template slot="menuRight">
                <span>{{$t('NAME')}}:</span>
            <el-input v-model="nameVal" :placeholder="$t('NAME OR NUMBER OF PERSONNEL')" style="display:inlie-block;width:200px;marginRight: 5px;" size="small" clearable></el-input>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="search">{{$t('SEARCH')}}</el-button>
        </template>-->
        <template slot="menu" slot-scope="{row,index,size,type}">
            <el-button icon="el-icon-data-analysis" :size="size" :type="type" @click="goPermission(row)">{{$t('Permissions to')}}</el-button>
            <el-button icon="el-icon-data-analysis" :size="size" :type="type" @click="goUser(row)">{{$t('Users to')}}</el-button>
        </template>
    </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {list} from "@/api/roleList"

export default {
    data() {
        return {
            loading: true,
            nameVal: "",
            page: {
                pageSize: 20,
                pagerCount: 5,
                total: 10,
                pageCurrent: 1
            },
            disabled: false,
            tableData: [],
            tableOption: {
                page: true,
                delBtn: false,
                addBtn: false,
                editBtn: false,
                menuWidth: 200,
                refreshBtn: false,
                tip: false,
                columnBtn: false,
                emptyText: this.$t('No Data'),
                menuTitle: this.$t('OPERATION'),
                column: [
                    {
                        label: this.$t('NAME'),
                        prop: "name",
                        rules: [{
                            required: true,
                            message: this.$t('PLEASE ENTER A NAME'),
                            trigger: "blur"
                        }]
                    }]
            }
        }
        },
        computed: {
            setData() {
                return {
                    tableOption: {
                        page: true,
                        delBtn: false,
                        menuWidth: 200,
                        refreshBtn: false,
                        tip: false,
                        columnBtn: false,
                        emptyText: this.$t('No Data'),
                        menuTitle: this.$t('OPERATION'),
                        column: [
                            {
                                label: this.$t('NAME'),
                                prop: "name",
                                rules: [{
                                    required: true,
                                    message: this.$t('PLEASE ENTER A NAME'),
                                    trigger: "blur"
                                }]
                            }]
                    }
                }
            }
        },
        created() {
            this.list();
        },
        methods: {
        //权限编辑
            goPermission(item) {
                console.log(item)
                this.$router.push({ path: '/peop/rolePermission', query: { roleName: item.name, roleId: item.id } })
            },
            goUser(item) {
                console.log(item)
                this.$router.push({ path: '/peop/userToRole', query: { roleName: item.name, roleId: item.id } })
            },
      currentChange(pageCurrent) {
        this.page.pageCurrent = pageCurrent;
        this.list();
      },
      sizeChange(pageSize) {
        this.page.pageCurrent = 1;
        this.page.pageSize = pageSize;
        this.list();
      },
      search() {
        this.list();
      },
      list() {
          list(this.page.pageCurrent, this.page.pageSize).then(res => {
              this.loading = false;
              this.tableData = res.data.roles;
              this.page.total = res.data.totalCount;
              this.page.pageSize = res.data.PageSize;
      })
     }
  }
}
</script>
<style scoped>
    .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        padding-left: 10px;
    }
</style>