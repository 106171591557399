<template>
    <div class="pvhFactory">
        <basic-container>

            </basic-container>
        </div>
</template>


<style>
 
</style>

<script>
    export default {
        data() {
            return {
     
            }
        },
        created() {
            this.init()
        },
        watch: {
            $route(to, from) {
                this.init()
            }
        },
        mounted() {
        },
        methods: {
            init() {
                
            },
        },
    }
</script>