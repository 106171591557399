var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c("avue-crud", {
            ref: "crud",
            attrs: {
              option: _vm.tableOption,
              data: _vm.tableData,
              "table-loading": _vm.tableLoading,
              page: _vm.page
            },
            on: {
              "current-change": _vm.currentChange,
              "size-change": _vm.sizeChange,
              "selection-change": _vm.selectionChange,
              "refresh-change": _vm.refreshChange,
              "row-save": _vm.handleSave,
              "row-update": _vm.handleUpdate,
              "row-del": _vm.handleDel
            },
            scopedSlots: _vm._u([
              {
                key: "status",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: { "inactive-value": 1, "active-value": 0 },
                      on: {
                        change: function($event) {
                          return _vm.change(scope.row)
                        }
                      },
                      model: {
                        value: scope.row.status,
                        callback: function($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status"
                      }
                    })
                  ]
                }
              },
              {
                key: "menuRight",
                fn: function(scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        staticStyle: {
                          width: "100px !important",
                          marginRight: "5px"
                        },
                        attrs: {
                          clearable: "",
                          placeholder: _vm.$t("STATE"),
                          size: "small"
                        },
                        model: {
                          value: _vm.status,
                          callback: function($$v) {
                            _vm.status = $$v
                          },
                          expression: "status"
                        }
                      },
                      [
                        _c(
                          "el-option",
                          { attrs: { label: _vm.$t("NORMAL"), value: "0" } },
                          [_vm._v(_vm._s(_vm.$t("NORMAL")))]
                        ),
                        _c(
                          "el-option",
                          { attrs: { label: _vm.$t("DISABLE"), value: "1" } },
                          [_vm._v(_vm._s(_vm.$t("DISABLE")))]
                        )
                      ],
                      1
                    ),
                    _c("el-input", {
                      staticStyle: {
                        display: "inlie-block",
                        width: "200px",
                        marginRight: "5px"
                      },
                      attrs: {
                        placeholder: _vm.$t("CLASSIFICATION NAME OR CODE"),
                        size: "small"
                      },
                      model: {
                        value: _vm.name,
                        callback: function($$v) {
                          _vm.name = $$v
                        },
                        expression: "name"
                      }
                    })
                  ]
                }
              },
              {
                key: "menuForm",
                fn: function(ref) {
                  var row = ref.row
                  var index = ref.index
                  var type = ref.type
                  return [
                    type == "add"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-circle-plus-outline",
                              size: "small"
                            },
                            on: {
                              click: function($event) {
                                return _vm.$refs.crud.rowSave()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("Hold")))]
                        )
                      : _vm._e(),
                    type == "edit"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-circle-check",
                              size: "small"
                            },
                            on: {
                              click: function($event) {
                                return _vm.$refs.crud.rowUpdate()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("Hold")))]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-circle-close", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.$refs.crud.closeDialog()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Cancel")))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }