<template>
  <div>
    <basic-container>
        <avue-crud :option="tableOption"
                   :data="tableData"
                   :table-loading="tableLoading"
                   :page="page"
                   ref="crud"
                   @current-change="currentChange"
                   @size-change="sizeChange"
                   @selection-change="selectionChange"
                   @refresh-change="refreshChange"
                   @row-save="handleSave"
                   @row-update="handleUpdate"
                   @row-del="handleDel">
            <template slot="status" slot-scope="scope">
                <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                           @change="change(scope.row)">
                </el-switch>
            </template>
            <template slot="menuRight" slot-scope="scope">
                <el-select clearable v-model="status" :placeholder="$t('STATE')" size="small" style="width:100px !important;marginRight: 5px;">
                    <el-option :label="$t('NORMAL')" value="0">{{$t('NORMAL')}}</el-option>
                    <el-option :label="$t('DISABLE')" value="1">{{$t('DISABLE')}}</el-option>
                </el-select>
                <el-input v-model="name" :placeholder="$t('CLASSIFICATION NAME OR CODE')" style="display:inlie-block;width:200px;marginRight: 5px;" size="small"></el-input>
            </template>
            <template slot-scope="{row,index,type}" slot="menuForm">
                <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" v-if="type=='add'" @click="$refs.crud.rowSave()">{{$t("Hold")}}</el-button>
                <el-button type="primary" icon="el-icon-circle-check" size="small" v-if="type=='edit'" @click="$refs.crud.rowUpdate()">{{$t("Hold")}}</el-button>
                <el-button icon="el-icon-circle-close" size="small" @click="$refs.crud.closeDialog()">{{$t("Cancel")}}</el-button>
            </template>
        </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {list,add,edit,remove,changeStatus} from "@/api/mtrList"
export default {
    watch:{
        mtrId: function(value) {
            console.log(value);
           this.tableOption.addBtn = value == 2 ? false: true;
        }
    },
    data() {
        return {
            tableLoading: false,
            mtrId: "",
            name: "",
            status: "0",
            page: {
                pageSize: 10,
                pagerCount: 5,
                total: 0,
                pageCurrent: 1
            },
            disabled: true,
            tableData: [],
            tableOption: {
                addBtn: false,
                delBtn: true,
                menuWidth: 200,
                dialogWidth: 500,
                labelWidth: 120,
                selection: true,
                tip: false,
                columnBtn: false,
                dialogFullscreen: true,
                addBtnText: this.$t('ADD'),
                delBtnText: this.$t('DELETE'),
                editBtnText: this.$t('Edit'),
                emptyText: this.$t('No Data'),
                menuTitle: this.$t('OPERATION'),
                editTitle: this.$t('Edit'),
                addTitle: this.$t('Add'),
                saveBtn: false,
                updateBtn: false,
                cancelBtn: false,
                column: [{
                    label: this.$t('CODE'),
                    prop: "code",
                    placeholder: this.$t('PLEASE ENTER THE CODE'),
                    span: 24,
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER THE CODE'),
                        trigger: "blur"
                    }]
                },{
                    label: this.$t('NAME'),
                    prop: "name",
                    placeholder: this.$t('PLEASE ENTER A NAME'),
                    span: 24,
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER A NAME'),
                        trigger: "blur"
                    }]
                }, {
                    label: this.$t('INVENTORY TYPE'),
                    type: "select",
                    placeholder: this.$t('PLEASE SELECT INVENTORY TYPE'),
                    hide: true,
                    dicUrl: this.businessUrl + "dict/selectDictByCategoryId?categoryId=2263ddddc4c74b639d40eac679b4fd41",
                    props: {
                        label: "name",
                        value: "id"
                    },
                    prop: "type",
                    span: 24,
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER INVENTORY TYPE'),
                        trigger: "blur"
                        }],
                },
                {
                    label: this.$t('INVENTORY TYPE'),
                    display: false,
                    type: "select",
                    prop: "dictName",
                    width: 120,
                },
                {
                    label: this.$t('CLASSIFICATION VALUE'),
                    prop: "scaleFactor",
                    placeholder: this.$t('PLEASE ENTER A CLASSIFICATION VALUE'),
                    span: 24,
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER A CLASSIFICATION VALUE'),
                        trigger: "blur"
                    }],
                    width:120,
                },
                {
                    label: this.$t('CREATE USER'),
                    editDisplay: false,
                    addDisplay: false,
                    prop: "userName"
                }, {
                    label: this.$t('ENABLE STATUS'),
                    slot: true,
                    editDisplay: false,
                    addDisplay: false,
                    prop: "status",
                    width: 120,
                }]
            }
        }
    },
    created() {
    },
    methods: {
        currentChange(pageCurrent) {
          this.page.pageCurrent = pageCurrent;
          this.list();
        },
        sizeChange(pageSize) {
          this.page.pageCurrent = 1;
          this.page.pageSize = pageSize;
          this.list();
        },
        clickList(mtrId) {
            this.mtrId = mtrId;
            list(this.status, this.name, this.page.pageCurrent,this.page.pageSize,this.mtrId).then(res => {
                this.page.total = 0;
                this.page.total = Number(res.data.data.total);
                this.tableData = res.data.data.items;
            })
        },
        handleDel(row) {
            this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('Cancel'),
                type: 'warning'
            }).then(() => { 
                remove(row.id).then((res) => {
                     if (res.data.code == "0000") {
                        this.$message({
                        type: "success",
                        message: this.$t('DeleteSuccess'),
                        });
                     }
                    this.list();
                })
            })
        },
        handleUpdate(row,index,done) {
            edit(row,this.mtrId).then((res) => {
                 if (res.data.code == "0000") {
                this.$message({
                  type: "success",
                    message: this.$t('EDIT SUCCEEDED')
                });
              }
                this.list();
                done();
            });
            this.$parent.list();
        },
        handleSave(row,done) {
            console.log(row);
            console.log('新增')
            if (!this.mtrId) {
                this.$message({
                    type: "error",
                    message: this.$t('PLEASE SELECT THE SUPERIOR CATEGORY ON THE LEFT')
                });
                done();
                return;
            }
            add(row,this.mtrId).then((res) => {
                 if(res.data.code == "0000"){
                    this.$message({
                      type: "success",
                      message: "新增成功",
                    }); 
                }
                this.list();
                done();
            });
            // this.$parent.list();
        },
        change(row) {
            changeStatus(row).then(() => {
                this.$message({
                    message: this.$t('UpdateSuccess'),
                    type: "success"
                })
            })
        },
        selectionChange(list) {
            console.log(list);
            list != "" ? this.disabled = false : this.disabled = true;
        },
        refreshChange() {
          this.list();
        },
        list(){
            list(this.status, this.name, this.page.pageCurrent,this.page.pageSize,this.mtrId).then(res => {
                this.tableData = res.data.data.items;
                this.page.total = res.data.data.total;
        })
        },
        envText: function() {
            return this.env
        }
  }
}
</script>
<style scoped>
    .el-select-dropdown__item {
        padding-left: 0px;
    }
</style>