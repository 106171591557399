var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "20px" }
              },
              [_vm._v(_vm._s(_vm.$t("DeclarationFile")))]
            )
          ]),
          [
            _c("avue-crud", {
              attrs: {
                option: _vm.setData.tableOpt,
                data: _vm.tableData,
                "row-style": _vm.rowStyle
              },
              scopedSlots: _vm._u([
                {
                  key: "expand",
                  fn: function(props) {
                    return [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: props.row.details,
                            "header-cell-style": _vm.tableHeaderColor
                          }
                        },
                        [
                          _c("el-table-column", { attrs: { type: "index" } }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "name",
                              label: _vm.$t("SUPPORT FILE NAME")
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "code",
                              label: _vm.$t("SUPPORT FILE Code")
                            }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            })
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }