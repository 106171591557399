
<template>
    <div class="pvhFactory">
        <basic-container>
            <el-container>
                <div class="layTop">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane :label="$t('DownloadPDF')" name="first"></el-tab-pane>
                        <el-tab-pane :label="$t('PDFLog')" name="second"></el-tab-pane>
                    </el-tabs>
                    <div class="Title">{{$t('PDFLog')}}</div>
                </div>
                <el-main style="overflow: hidden;">
                    <div  class="topInfoPdflpg">
                        <el-row>
                            <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                <span class="spanRight"><i class="el-icon-s-custom"></i>{{$t('Supplier')}} : {{formData.supplierVal}}</span>
                            </el-col>
                            <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                <span class="spanRight"><i class="iconfont icon-rili"></i>{{$t('At')}} : {{formData.OrderDateVal}}</span>
                            </el-col>
                            <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                <span><i class="el-icon-attract"></i>{{$t('BATCH')}} : {{formData.BatchVal}}</span>
                                <span class="spanLeft"></span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                <span class="spanRight"><i class="el-icon-document"></i>{{$t('PO')}} : {{formData.poVal}}</span>
                            </el-col>
                            <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                <span><i class="el-icon-document"></i>{{$t('BUSINESS SERIAL NUMBER INTERNAL')}} : {{formData.BusinessNoInVal}}</span>
                            </el-col>
                            <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                <span class="spanRight"><i class="el-icon-document"></i>{{$t('BUSINESS SERIAL NUMBER EXTERNAL')}} : {{formData.BusinessNoOutVal}}</span>
                                <span class="spanLeft"></span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                <span class="spanRight"><i class="el-icon-coin"></i>{{$t('Product Name')}} : {{formData.ProductNameVal}}</span>
                            </el-col>
                            <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                <span class="spanRight"><i class="el-icon-brush"></i>{{$t('COLOR')}} : {{formData.ColorVal}}</span>
                            </el-col>
                            <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                <span class="spanRight"><i class="el-icon-shopping-cart-full"></i>{{$t('QUANTITY')}} : {{formData.NumberVal}}/{{formData.UnitNameVal}}</span>
                                <span class="spanLeft"></span>
                            </el-col>
                        </el-row>
                    </div>
                </el-main>
                <el-footer style="height: 600px !important;padding: 20px 0px;">
                    <avue-crud ref="crud"
                               v-model="obj"
                               :option="setData.tableOpt"
                               :data="tableData"
                               :page.sync="page"
                               @current-change="currentChange"
                               style="margin-bottom: 20px !important;">
                    </avue-crud>
                </el-footer>
            </el-container>
        </basic-container>
    </div>
</template>
<style scoped>

    .dataicon i {
        display: none;
    }

    .othChe .el-checkbox__label {
        color: #409eff !important;
    }
    .topInfoPdflpg {
        background: linear-gradient(to right,#4A4A74,#212146);
        border-radius: 10px;
        padding: 30px 20px 10px;
        color: #fff;
        width: 100%;
        font-size: 14px;
        width: 100%;
        float: left;
    }
        .topInfoPdflpg i {
            color: #B2EBE9;
            margin-right: 10px;
            font-size: 20px;
        }

        .topInfoPdflpg p {
            width: calc(100% - 160px);
        }
</style>


<script>
    import { List } from "@/api/orderPackage";
    import { POInfo } from "@/api/purchaseOrd";

    export default {
        data() {
            return {
                formData: {
                    soVal: '', fromVal: '', POproductVal: '', batchVal: '', codeVal: '', colorVal: '', numberVal: '', unitVal: '', odateVal: '', productVal: '', remarkVal: '', departVal: '',
                    OrderDateVal: '', supplierVal: '', poVal: '', BusinessNoInVal: '', BusinessNoOutVal: '', ProductNameVal: '', BatchVal: '', ColorVal: '', NumberVal: '', UnitNameVal: '',
                },
                activeName: 'second',
                srcObject: '',
                numPages: null,
                operationType: 1,
                token: '',
                downUrl: '',
                obj: {},
                loadId: '',
                loadOrderId: '',
                tableData: [],
                sourceData: [],
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev,next",
                    background: true,
                    pageSize: 10
                },
                typeData: [
                    {
                        label: this.$t('OPERATIONTYPE1'),
                        value: 1
                    },
                    {
                        label: this.$t('OPERATIONTYPE2'),
                        value: 2
                    },
                    {
                        label: this.$t('OPERATIONTYPE3'),
                        value: 3
                    }
                    ,
                    {
                        label: this.$t('OPERATIONTYPE4'),
                        value: 4
                    }

                ]
            }
        },
        computed: {
            setData() {
                return {
                    tableOpt: {
                        page: true,
                        refreshBtn: false,
                        delBtn: false,
                        emptyText: this.$t('No Data'),
                        align: 'center',
                        columnBtn: false,
                        simplePage: false,
                        addBtn: false,
                        editBtn: false,
                        header: true,
                        menu: false,
                        labelWidth: "30%",
                        column: [
                            {
                                label: this.$t('OPERATION TIME'),
                                prop: 'CreateTime',
                                type: "date",
                                format: "yyyy-MM-dd hh:mm:ss",
                                valueFormat: "yyyy-MM-dd hh:mm:ss",
                                order: 1,
                            },
                            {
                                label: this.$t('OPERATOR'),
                                prop: 'CreateUserid',
                            },
                            {
                                label: this.$t('OPERATION TYPE'),
                                prop: 'Type',
                                type: "select",
                                filterable: true,
                                dicData: this.typeData,
                            },
                            {
                                label: this.$t('UNIQUE CODE'),
                                prop: 'UniqueCode',
                            }
                        ]
                    }
                }
            }
        },
        created() {
            this.init();
        },
        methods: {
            init() {
                this.loadId = this.$route.query.Id;
                this.loadOrderId = this.$route.query.OrderId;
                this.getList();
                this.topInit();
            },
            handleClick() {
                if (this.activeName == "first") {
                    this.$router.push({ path: '/pvh_operation/review', query: { OrderId: this.loadOrderId } })
                }
            },
            currentChange(val) {
                this.page.currentPage = val
                this.getList();
            },
            async getList() {
                await List(this.loadId, this.page.currentPage, this.page.pageSize).then(res => {
                    this.tableData = res.data.OrderPackageOperationLogs;
                    this.page.total = res.data.TotalCount;
                    this.page.pageSize = res.data.PageSize;
                }).catch(erro => {
                    console.log(erro);
                })
            },
            async topInit() {
                await POInfo(this.loadOrderId, this.loadOrderId, "", "").then(res => {
                    var o = res.data;
                    this.formData.supplierVal = o.POInfo.VendorCorporationId;
                    this.formData.batchVal = o.POInfoDetails.Batch;
                    this.formData.poVal = o.POInfoDetails.Po;
                    this.formData.BusinessNoInVal = o.POInfoDetails.BusinessNoIn;
                    this.formData.BusinessNoOutVal = o.POInfoDetails.BusinessNoOut;
                    this.formData.ProductNameVal = o.POInfoDetails.ProductName;
                    this.formData.BatchVal = o.POInfoDetails.Batch;
                    this.formData.ColorVal = o.POInfoDetails.Color;
                    this.formData.NumberVal = o.POInfoDetails.Number;
                    this.formData.UnitNameVal = o.POInfoDetails.UnitId;
                    this.formData.OrderDateVal = o.POInfo.OrderDate !== '' ? o.POInfo.OrderDate.substr(0, 10) : '';
                })
            },
        },
    }
</script>