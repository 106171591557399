import request from '@/router/axios';
import { pvhApiBase } from '@/config/env';

// 添加用户角色
export const addRoleUserNew = (UserId, RoleId) => {
	return request({
		url: pvhApiBase + "Cashier/NewPostUserRoles",
		method: "post",
		data: {
			UserId, RoleId
		}
	})
}

// 获取用户权限
export const userJurisdiction = (corpId) => {
	return request({
		url: pvhApiBase + "ProcessManagement/GetCorpUserssByCorpId",
		method: "get",
		params: {
			corpId: corpId
		}
	})
}