<template>
    <div class="additionToMachine" id="home_container"   style="background:transparent;position:relative;top:70px;left:0;">

        <volumeSet v-if="dialogVisible" :item="seletItem" :modelShow="dialogVisible" @closeModel="closeModel"></volumeSet>
        <found v-if="showFound" :seletItem="seletItem" @setFoundBySelf="setFoundBySelf" :modelShow="showFound" @setFound="setFound" @closeModel="closeModel"></found>
        <repair v-if="showRepair" :modelShow="showRepair" @closeModel="closeModel"></repair>
        <machine v-if="showMachine" :seletItem="seletItem" :modelShow="showMachine" @openFound="openFound" @closeModel="closeModel"></machine>
        <scene v-if="showScene" :seletItem="seletItem" :modelShow="showScene" @closeModel="closeModel"></scene>

        <div class="outFormDiv" style="display: flex; justify-content: space-between; padding: 15px; position: fixed; top: 100px; right: 0; width: calc(100% - 13.75rem); z-index: 1;box-sizing: border-box;">
            <div class="formList" v-if="setData.topIsMenu" >
                <el-form :inline="true" :model="formInline" class="demo-form-inline" labelWidth="80px" style="display:flex;">
                    <!-- <el-form-item  style="width:calc(33.33% - 23.33px);display:flex;">
                        <el-date-picker v-model="formInline.openTime"
                                        value-format="yyyy-MM-dd"
                                        type="date"
                                        placeholder="选择日期" style="width:100%;">
                        </el-date-picker>
                    </el-form-item> -->
                    <el-form-item  style="width:calc(33.33% - 23.33px);display:flex;">
                        <el-select filterable v-model="formInline.machineModelId" @change="getModelId" placeholder="请选择设备机型" clearable>
                            <el-option v-for="item in equipmentModel"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item  style="width:calc(33.33% - 23.33px);display:flex;">
                        <el-select filterable v-model="formInline.productId" @change="getBatchId" placeholder="请选择产品品种" clearable>
                            <el-option v-for="item in varietiesProducts"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item style="width:calc(33.33% - 23.33px);display:flex;">
                        <el-select filterable v-model="formInline.departmentId" placeholder="请选择车间" clearable>
                            <el-option v-for="item in workCenter"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item style="width:calc(33.33% - 23.33px);display:flex;">
                        <el-select filterable v-model="formInline.machineId" placeholder="请选择设备编号" clearable>
                            <el-option v-for="item in equipmentSerialNumber"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item  style="width:calc(33.33% - 23.33px);display:flex;">
                        <el-select filterable v-model="formInline.batchCode" placeholder="请选择产品批次" clearable>
                            <el-option v-for="item in productBatch"
                                       :key="item.id"
                                       :label="item.batchCode"
                                       :value="item.batchCode">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="btn" v-if="setData.topIsMenu" style="width:240px;text-align:right;display:flex;justify-content:flex-end;align-items: start;">
                <!-- <div style="width:50px;height:40px;overflow:hidden;text-align:center;line-height:40px;" @click="moreFrom">
                    <i class="iconfont icon-xuanxiang" style="font-size:30px !important;margin:0;color:#4D8AF0;"></i>
                </div> -->
                <el-button type="primary" @click="onSearch" style="margin-right:5px;">{{$t('SEARCH')}}</el-button>
                <!--        <el-button type="primary" @click="selectAll">反选</el-button>-->
                <!--<el-button type="primary" @click="reset">重置</el-button>-->
                <el-button type="primary" @click="getAll">{{$t('Batch set')}}</el-button>
            </div>
            <div v-if="setData.topIsCheMenu" style="width:100%;text-align:right;">
                <el-checkbox-button class="allBtn" :indeterminate="isIndeterminate" type="primary" @change="onAll">{{setData.allText}}</el-checkbox-button>
                <!--<el-button type="primary" @click="onAll" style="margin-right:5px;">{{setData.allText}}</el-button>-->
                <el-badge :value="checkboxList.length" class="item" style="margin-right:5px;">
                    <el-button type="primary" @click="onChange">{{$t('Batch set')}}</el-button>
                </el-badge>
                <el-button type="primary" @click="outAll">{{$t('Exit the batch')}}</el-button>
            </div>
        </div>
        
        <div class="content yzgl" style="display: flex; justify-content: center; width: 100%; position: relative; padding: 7.5px; box-sizing: border-box;" :style="outHei">
            <draggable style="position: absolute; top: 0; left: 0; width: 100%; margin-top: 7.5px; display: flex; flex-wrap: wrap; padding: 0 7.5px; box-sizing: border-box;" :style="scHei">
                <el-checkbox-group v-model="checkboxList" style="width: 100%;display: flex;flex-wrap: wrap;">
                    <el-checkbox-button v-for="(item,indexCod) in devList" style="padding:0;" :style="itemHeight" class="fontCss" :label="item.id" :key="item.id" @change="checkChange($event,item)" :disabled="setData.isCheck">
                        <div style="display:flex;justify-content:space-between;padding:0 7.5px;height:50px;line-height:50px;border-radius:8px 8px 0 0;width:100%;box-sizing:border-box;" :style="topTitle[item.openStatus]" class="name">
                            <div style="position:relative;padding-left:15px;" :style="textStatus[item.openStatus]">
                                <i class="iconfont icon-biaotibiao" :style="iconStatus[item.openStatus]"></i>{{ item.code }}
                            </div>
                            <div>
                                <div v-if="item.openStatus == 2" class="item_color" style="color:#42B983;font-size:14px;">
                                    <i class="iconfont icon-shengchandan" style="vertical-align: 0; font-size:15px !important;"></i>生产中
                                </div>
                                <div v-if="item.openStatus == 0" class="item_color" style="color:#fff;font-size:14px;">
                                    <i class="iconfont icon-guanji" style="vertical-align: 0; font-size:15px !important;"></i>待开台
                                </div>
                                <div v-else-if="item.faultStatus == 1" class="item_color" style="color:#fff;font-size:14px;">
                                    <i class="iconfont icon-jinggao" style="vertical-align: 0; font-size:15px !important;"></i>待维修
                                </div>
                                <!--<el-checkbox @change="checkChange($event,item)" v-model="item.select" style="float: right;"></el-checkbox>-->
                                </div>
                            </div>
                            <div style="padding: 0 7.5px;height:calc(100% - 128px);display:flex;flex-wrap:wrap;align-items:center;width:100%;box-sizing:border-box;text-align:left;">
                                <div style="width:100%;display:flex;align-items:center;"><i class="iconfont icon-pinzhong12" style="vertical-align: 0; font-size:16px !important;color:#CAD9EF;margin-right:10px;"></i>产品品种：<span style="font-size:14px;color:#aaa;display:inline-block;width:calc(100% - 115px);overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{item.fact?item.fact.productName:'-'}}</span></div>
                                <div style="width:100%;display:flex;align-items:center;"><i class="iconfont icon-mingxi" style="vertical-align: 0; font-size:16px !important;color:#CAD9EF;margin-right:10px;"></i>产品批次：<span style="font-size:14px;color:#aaa;display:inline-block;width:calc(100% - 115px);overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{item.fact?item.fact.batchName:'-'}}</span></div>
                            </div>
                            <div style="padding:0 7.5px;width:100%;height:50px;box-sizing:border-box;">
                                <div style="width:calc(51% - 23.75px); marginTop: 20px;">
                                    <el-button v-if="item.openStatus == 0" @click="changeStatus(3,item)" style="background: #f1fff6;width: 70%;border:1px solid #42B983;color:#42B983;border-radius: 8px;padding: 12px 0;" icon="iconfont icon-guanji" >
                                        确认开台
                                    </el-button>
                                <!-- </div> -->
                                <!-- <div style="width: calc(25% - 23.75px);"> -->
                                    <el-button v-if="item.openStatus == 2" @click="changeStatus(4,item)" style="background: #FFF2F2;width: 70%;border:1px solid #F56C6C;color:#F56C6C;border-radius: 8px;padding: 12px 0;" icon="iconfont icon-manglu">
                                        确认了机
                                    </el-button>
                                    <el-button @click="changeStatus(1,item)" style="background: #f5f5f5;width: 70%;border:1px solid #aaa;color:#aaa;border-radius: 8px;padding: 12px 0;" icon="iconfont icon-guanji" >
                                        现场管理
                                    </el-button>
                                    <el-button @click="changeStatus(2,item)" style="background: #fff9ef;width: 70%;border:1px solid #E6A23C;color:#E6A23C;border-radius: 8px;padding: 12px 0;" icon="iconfont icon-guanji" >
                                        呼叫维修
                                    </el-button>
                                </div>
                                <!-- <div style="width:30px;">
                                    <el-dropdown>
                                        <span class="el-dropdown-link" style="text-align:center;cursor:pointer;">
                                            <i class="iconfont icon-gengduo" style="margin:0;font-size:16px !important;"></i>
                                        </span>
                                        <el-dropdown-menu trigger="click" :append-to-body="false">
                                            <el-link style="padding:8px 12px;text-align:left;width:120px;font-size:12px;"  @click="changeStatus(1,item)">现场管理</el-link>
                                            <el-link style="padding:8px 12px;text-align:left;width:120px;font-size:12px;" @click="changeStatus(2,item)">呼叫维修</el-link>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div> -->
                            </div>
                        </el-checkbox-button>
                    </el-checkbox-group>
                <!--<div class="item" v-for="(item,indexCod) in devList" :key="item.id">
                    <div class="name">
                        {{ item.name }}
                        <div v-if="item.openStatus == 2" class="item_color green"></div>
                        <div v-if="item.openStatus == 0" class="item_color blue"></div>
                        <div v-if="item.faultStatus == 1" class="item_color red"></div>-->
                        <!--            <el-checkbox @change="checkChange($event,item)" v-model="item.select" style="float: right;"></el-checkbox>-->
                    <!--</div>
                    <div class="detail_info">
                        <span>产品品种：{{item.fact?item.fact.productName:'-'}}</span>
                        <span>产品批次：{{item.fact?item.fact.batchCode:'-'}}</span>
                    </div>
                    <div class="operate">
                        <el-row>
                            <el-col :span="5">
                                <span class="item_name">操作</span>
                            </el-col>
                            <el-col :span="19">
                                <el-button type="warning" :disabled="item.openStatus == 0" @click="changeStatus(1,item)">现场管理</el-button>
                                <el-button type="danger" @click="changeStatus(2,item)">呼叫维修</el-button>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="5">
                                <span class="item_name" style="opacity: 0;">操作</span>
                            </el-col>
                            <el-col :span="19">
                                <el-button @click="changeStatus(3,item)" type="success"
                                           :disabled="item.openStatus == 2">
                                    确认开台
                                </el-button>
                                <el-button @click="changeStatus(4,item)" type="primary"
                                           :disabled="item.openStatus == 0">
                                    确认了机
                                </el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>-->
            <div style="position:fixed;right:15px;bottom:70px;">
                <div v-if="isTop" @click="toTop" style="margin-bottom:10px;cursor:pointer;width:50px;border-radius:50%;height:50px;background:#4D8AF0;text-align:center;line-height:50px;">
                    <i class="iconfont icon-zhiding-59" style="font-size:30px !important;color:#fff;margin:0;width:50px;"></i>
                </div>
                <div v-if="isPre" @click="preList" style="margin-bottom:10px;cursor:pointer;width:50px;border-radius:50%;height:50px;background:#4D8AF0;text-align:center;line-height:50px;">
                    <i class="iconfont icon-shang25" style="font-size:30px !important;color:#fff;margin:-7px 0 0;width:50px;"></i>
                </div>
                <div v-if="isNex" @click="nextList" style="margin-bottom:10px;cursor:pointer;width:50px;border-radius:50%;height:50px;background:#4D8AF0;text-align:center;line-height:50px;">
                    <i class="iconfont icon-xia852" style="font-size:30px !important;color:#fff;margin:0;width:50px;"></i>
                </div>
            </div>
            </draggable>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapState } from "vuex";
    import { fullscreenToggel, listenfullscreen, getliaojiData } from "@/util/util";
import draggable from "vuedraggable";
import found from './components/found'
import repair from './components/repair'
import machine from './components/machine'
import scene from './components/scene'
import volumeSet from './components/volumeSet'

export default {
  components: {
    volumeSet,
    draggable,
    found,
    repair,
    machine,
    scene
  },
  data() {
      return {
          checkboxAll:[],
          
          scH: window.screen.height,
          isTop: false,
          isPre: false,
          isNex: true,
          currentId: 0,
          scHei: {
              height: "",
              top: 0,
          },
          outHei: {
              height: "",
              top:0,
          },
          ishasSeletNum:0,
          checkboxList:[],
          iconStatus: {
              2: { 'color': '#42B983' },
              1: { 'color': '#fff' },
              0: { 'color': '#fff' },
          },
          topTitle: {
              2: { 'background': '#fff' },
              1: { 'background': '#F56C6C' },
              0: { 'background': '#aaa' },
          },
          textStatus: {
              2: { 'color': '#333' },
              1: { 'color': '#fff' },
              0: { 'color': '#fff' },
          },
          itemHeight: {
              "height": "",
              "background": "#fff",
              "box-shadow": "0px 4px 12px 0px rgba(11, 51, 117, 0.1)",
              "margin": "0 7.5px 15px",
              "width": "calc(25% - 15px)",
              "box-sizing": "border-box",
              "border-radius": "8px",
              "display": "flex",
              "flex-wrap": "wrap"
          },
      dialogVisible: false,
      showScene:false,
      showRepair:false,
      showFound:false,
      showMachine:false,
      formInline: {
        batchCode: '',

        departmentId: "",
        machineId: "",
        machineModelId: "",
        openTime: "",
        productId: ""
      },
      equipmentModel: [],
      varietiesProducts: [],
      workCenter: [],
      equipmentSerialNumber: [],
      productBatch: [],
      devList: [],
      seletItem: {},
      machineModelId:null,
          productId: null,
          errorYield: "",
          secDa: {}
    }
  },
  created() {
    this.getList()
    this.selectAll()
    this.getWrokCenter()
    this.selectVarieties()
        },
        computed: {
            setData() {
                return {
                    allText: "全选",
                    isCheck: true,
                    topIsMenu: true,
                    topIsCheMenu: false
                }
            },
            ...mapState({
                showDebug: state => state.common.showDebug,
                showColor: state => state.common.showColor,
                showTheme: state => state.common.showTheme,
                showLock: state => state.common.showLock,
                showFullScren: state => state.common.showFullScren,
                showCollapse: state => state.common.showCollapse,
                showSearch: state => state.common.showSearch,
                showMenu: state => state.common.showMenu
            }),
            ...mapGetters([
                "userInfo",
                "isFullScren",
                "tagWel",
                "tagList",
                "isCollapse",
                "tag",
                "logsLen",
                "logsFlag"
            ])
        },
        mounted() {
            listenfullscreen(this.getHeight);
            this.getHeight();
        },
        methods: {
            nextList() {
                let setHei = Number(this.outHei.height.split("px")[0]);
                let othHei = -setHei;
                if (this.currentId >= parseInt(this.devList.length / 12)) {
                    this.$message({
                        type: "error",
                        message: "已经是最后一页啦~"
                    });
                    this.currentId = parseInt(this.devList.length / 12);
                } else {
                    this.currentId++;
                    this.isTop = true;
                    this.isPre = true;
                    this.scHei.top = (this.currentId * othHei) + 7.5 + "px";
                }
                console.log(this.currentId)
            },
            preList() {
                let setHei = Number(this.outHei.height.split("px")[0]);
                let othHei = -setHei;
                if (this.currentId <= 1) {
                    this.isTop = false;
                    this.isPre = false;
                    this.$message({
                        type: "error",
                        message: "已经是第一页啦~"
                    })
                    this.scHei.top = 0;
                    this.currentId = 0
                } else {
                    this.currentId--;
                    this.isTop = true;
                    this.isPre = true;
                    this.scHei.top = (this.currentId * othHei) + 7.5 + "px";
                }
                console.log(this.currentId)
            },
            toTop() {
                this.isTop = false;
                this.isPre = false;
                this.$message({
                    type: "success",
                    message: "已经到第一页啦~"
                })
                this.scHei.top = 0;
                this.currentId = 0
            },
    //   moreFrom() {
    //       let fromHei = document.querySelector(".formList").clientHeight;
    //       if (fromHei > 40) {
    //           document.querySelector(".formList").style.cssText = "width: calc(100% - 240px); height: 40px; overflow: hidden;";
    //           document.querySelector(".outFormDiv").style.cssText = "display: flex; justify-content: space-between; padding: 15px; background: #F2F2F2; position: fixed; top: 100px; right: 0; width: calc(100% - 13.75rem); z-index: 1;box-sizing: border-box;";
    //       } else {
    //           document.querySelector(".formList").style.cssText = "width: calc(100% - 240px);height:auto;";
    //           document.querySelector(".outFormDiv").style.cssText = "display: flex; justify-content: space-between; padding: 15px; background: #F2F2F2; position: fixed; top: 100px; right: 0; width: calc(100% - 13.75rem); z-index: 1;box-sizing: border-box;box-shadow:0px 15px 10px 0px rgba(0,0,0,.1);";
    //       }
    //   },
            getHeight(h) {
                //let hei;
                //let scH = window.screen.height;
                let docH = document.getElementById("leftHeight").clientHeight;
                console.log(this.scH, docH)
                if (this.isFullScren) {
                    let hei = this.scH - 70;
                    this.bigHeight = hei;
                    this.itemHeight.height = (hei / 3 - 17.5) + "px";
                    this.outHei.height = hei + "px";
                    this.outHei.top = "70px";
                } else {
                    let hei = docH - 70;
                    this.bigHeight = hei;
                    this.itemHeight.height = (hei / 3 - 27.5) + "px";
                    this.outHei.height = hei - 27.5 + "px";
                    this.outHei.top = "0";
                }
            },
    // 查询
    onSearch() {
      this.getList();
    },
    // 重置
    reset(){
      this.formInline = {
        batchCode: '',
        departmentId: "",
        machineId: "",
        machineModelId: "",
        openTime: "",
        productId: ""
      }
      this.getList();
    },
    // 获取全部list数据
    async getList() {
        const res = await this.request.post(this.proApis.QUERYMACHINE, this.formInline);
        console.log(res,'测试')
        this.devList = res.data.data;
        res.data.data.forEach((v, i) => {
            this.checkboxAll.push(v.id)
        })
    },
    // 获取机型
    async selectAll() {
      const res = await this.request.post(this.proApis.MACHINEMODELSELECTALL, {})
      console.log(res,'res')
      this.equipmentModel = res.data
    },
    // 获取编号
    async selectByMachineModelId() {
      const res = await this.request.get(`${this.proApis.SELECTBYMACHINEMODELID}?machineModelId=${this.machineModelId}`)
      console.log(res,'res')
      this.equipmentSerialNumber = res.data.data
    },
    // 获取品种
    async selectVarieties() {
      const res = await this.request.post(this.proApis.PRODUCTSELECT,{})
      console.log(res,'品种')
      this.varietiesProducts = res.data.data
    },
    // 获取车间
    async getWrokCenter() {
      const res = await this.request.get(`${this.proApis.SELECTWORKSHOPNAME}?type=1`)
      console.log(res,'获取工作重心')
      this.workCenter = res.data.data
    },
    // 获取工作批次
      async selectBatch() {
        console.log(1)
      const res = await this.request.post(this.proApis.SELECTBATCH,{productId: this.productId})
        console.log(res,'获取工作批次')
          this.productBatch = res.data.data;
          console.log(this.productBatch)
    },
      getBatchId(val) {
        console.log(val)
      this.productId = val
      this.formInline.batchCode = ''
      this.selectBatch()
    },

    getModelId(val) {
        console.log(val);
      this.machineModelId = val
        this.formInline.machineId = ''
      this.selectByMachineModelId()
    },
    // 多选
    checkChange(val, item) {
        item.select = val
        console.log(item)
      console.log(this.devList, 'devList')
    },
    // 改变操作状态
    async changeStatus(status,item) {
      console.log(status, 'status',item)
        this.seletItem = item
        if (status == 1 || status == 2) {
            this.$message.warning('此功能该本版未开通')
            return;
        }
        //this.errorYield = erroY.data.data.errorYield;
      switch (status) {
        case 1:
          this.showScene = true
          break;
        case 2:
          this.showRepair = true
          break;
        case 3:
          this.showFound = true
          break;
        case 4:
              const res = await this.request.get(this.proApis.SPECSHEETMACHINEFACTQUERYFACTYIELD + "?id=" + item.fact.id)
              console.log(res, 'as')
              let arr = res.data.data;
              //this.seletItem = ;
              let plan = 0;
              let fact = 0;
              if (res.data.data.fact.planYield == null) {
                  plan = 0;
              } else {
                  plan = res.data.data.fact.planYield;
              }
              if (res.data.data.fact.factYield == null) {
                  fact = 0;
              } else {
                  fact = res.data.data.fact.factYield;
              }
              this.secDa = {
                  planYield: plan,
                  factYield: fact
              };
              const erroY = await this.request.post(this.proApis.SELECTWUCHACHANLIANG, this.secDa)
              arr.errorYield = erroY.data.data.errorYield;
              this.seletItem = arr;
          this.showMachine = true
          break;
      }
    },
    // 打开开台
    async openFound(){
      this.showMachine = false
      this.showFound = true
      await this.getList()
    },
            //退出全选
            outAll(val) {
                this.setData.isCheck = true;
                this.setData.topIsMenu = true;
                this.setData.topIsCheMenu = false;
                this.checkboxList = [];
                this.setData.allText = "全选";
            },
            //进入全选
            getAll(val) {
                this.setData.isCheck = false;
                this.setData.topIsMenu = false;
                this.setData.topIsCheMenu = true;
                this.checkboxList = [];
            },
            //全选
            onAll(value) {
                value == false ? this.checkboxList = [] : this.checkboxList = this.checkboxAll;
                value == false ? this.setData.allText = "全选" : this.setData.allText = "取消";
                value == false ? this.devList.forEach((v, i) => {
                    v.select = false;
                }) : this.devList.forEach((v, i) => {
                    v.select = true;
                })
            },
    // 打开批量设置
    onChange() {
      let arr = []
      this.ishasSeletNum = 0
        this.devList.forEach(e => {
            console.log(e.select)
        if (e.select) {
          arr.push(e)
          this.ishasSeletNum++
        }
      })
      if (this.ishasSeletNum === 0) return this.$message.warning('请先选择设备')
      console.log(this.ishasSeletNum, 'ishasSeletNum')
      console.log(arr, 'arr')
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i].status !== arr[i + 1].status) {
          this.$message.warning('不可批量设置不同的状态')
          return
        }
      }
      this.dialogVisible = true;
      this.seletItem = arr[0]
    },
    // 关闭批量设置
    closeModel() {
      this.dialogVisible = false;
      this.showScene = false;
      this.showRepair = false;
      this.showFound = false;
        this.showMachine = false;
        this.setData.isCheck = true;
        this.setData.topIsMenu = true;
        this.setData.topIsCheMenu = false;
        this.checkboxList = [];
        this.setData.allText = "全选";
      this.getList()
    },
    async setFoundBySelf(val){
      console.log(val)
      const res = await this.request.post(this.proApis.INSERTCUSTOMIZEPLAN,val)

      if(res.data.code == 'BUSINESS.0002') {
        this.$message.error(res.data.msg)
      }else {
        this.$message.success('开台成功')
        this.getList();
        this.showFound = false;
      }
    },
    // 确认开台
    async setFound(obj){
      console.log(obj)
      const res = await this.request.post(this.proApis.SPECSHEETMACHINEFACT,obj)
      console.log(res,'resss')
      if(res.data.code == 'BUSINESS.0002') {
        this.$message.error(res.data.msg)
      }else {
        this.$message.success('开台成功')
        this.getList();
        this.showFound = false;
      }
    }
  }
}
</script>
<style>
    .yzgl .el-checkbox-button__inner {
        padding: 0 !important;
        width: 100%;
        border-radius: 8px 8px 8px 8px !important;
    }

    .yzgl .el-checkbox-button.is-checked .el-checkbox-button__inner {
        background: #fff;
        color: #333;
        /*box-shadow: 0 0 3px 3px rgba(77,138,240,.2) !important;*/
    }
</style>
<style lang="scss" scoped>
.additionToMachine {
  background-color: #fff;
  /*padding: 1.25rem;*/
  .item_color {
    float: left;
  }
  .green {
    background-color: #67C23A;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    position: relative;
    top: 0.5rem;
  }
  .blue {
    background-color: #409EFF;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    position: relative;
    top: 0.5rem;
  }
  .red {
    background-color: red;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    position: relative;
    top: 0.5rem;
  }
  .status_tips {
    width: 50%;
    height: 1.9375rem;
    line-height: 1.9375rem;
    margin-left: 1.25rem;

  }
  .container_title {
    font-size: 1.4375rem;
    font-weight: 700;
    margin-bottom: 1.25rem;
  }

  .content {
    display: flex;
    justify-content: center;

    .item {
      width: 25%;
      height: auto;
      background: rgba(242, 242, 242, 1);
      //float: left;
      display: inline-block;
      margin: 1.5625rem;
      padding: 0.75rem;
      .detail_info {
        width: 100%;
        background-color: #72767b;
        color: #fff;
        font-size: 1.125rem;
        display: flex;
        justify-content: space-around;
        height: 2.125rem;
        line-height: 2.125rem;
        margin-bottom: 1rem;
      }
      .name {
        font-weight: 700;
        font-size: 1.5rem;
        width: 100%;
        text-align: center;
        font-weight: 700;
        margin-bottom: 0.5rem;
      }

      .item_name {
        font-weight: 700;
        height: 2.5rem;
        line-height: 2.5rem;
      }

      /deep/ .el-radio-button {
        margin-right: 0.8125rem;
      }
      /deep/ .el-radio-button__inner {
        border-radius: 0.25rem;
        width: 6.125rem;
      }
    }
  }

}
    .yzgl .name .icon-biaotibiao {
        font-size: 41px !important;
        text-align: left !important;
        position: absolute;
        top: 0;
        left: -15px;
    }
</style>