var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "el-collapse",
            {
              staticStyle: { marginTop: "30px" },
              model: {
                value: _vm.activeNames,
                callback: function($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames"
              }
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { title: "基础信息", name: "1" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleform",
                      staticClass: "content",
                      attrs: {
                        align: "center",
                        model: _vm.form,
                        "label-width": "80px",
                        rules: _vm.rules
                      }
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "工艺员", prop: "workPerson" }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "formSelect",
                                      attrs: {
                                        placeholder: "请选择工艺员",
                                        disabled: ""
                                      },
                                      model: {
                                        value: _vm.form.person,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "person", $$v)
                                        },
                                        expression: "form.person"
                                      }
                                    },
                                    _vm._l(_vm.personOptions, function(item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.userId
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "纱管颜色",
                                    prop: "tubeColorId"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "formSelect",
                                      attrs: {
                                        placeholder: "请选择纱管颜色",
                                        disabled: ""
                                      },
                                      model: {
                                        value: _vm.form.tubeColorId,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "tubeColorId", $$v)
                                        },
                                        expression: "form.tubeColorId"
                                      }
                                    },
                                    _vm._l(_vm.tubeColorIdOptions, function(
                                      item
                                    ) {
                                      return _c("el-option", {
                                        key: item.code,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "计划产量",
                                    prop: "modelOutput"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入计划产量" },
                                    model: {
                                      value: _vm.form.modelOutput,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "modelOutput", $$v)
                                      },
                                      expression: "form.modelOutput"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "生产工序",
                                    prop: "processId"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "formSelect",
                                      attrs: {
                                        placeholder: "请选择生产工序",
                                        disabled: ""
                                      },
                                      on: { change: _vm.wklnChange },
                                      model: {
                                        value: _vm.form.processId,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "processId", $$v)
                                        },
                                        expression: "form.processId"
                                      }
                                    },
                                    _vm._l(_vm.wklnOption, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "产品名称",
                                    prop: "productId"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "formSelect",
                                      attrs: {
                                        placeholder: "请选择产品名称",
                                        disabled: ""
                                      },
                                      on: { change: _vm.chengeProduct },
                                      model: {
                                        value: _vm.form.productId,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "productId", $$v)
                                        },
                                        expression: "form.productId"
                                      }
                                    },
                                    _vm._l(_vm.wklnOptions, function(item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "产品批号",
                                    prop: "batchCode"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "formSelect",
                                      attrs: { placeholder: "请选择产品批号" },
                                      model: {
                                        value: _vm.form.batchId,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "batchId", $$v)
                                        },
                                        expression: "form.batchId"
                                      }
                                    },
                                    _vm._l(_vm.batchOptions, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.batchCode,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "10",
                                    label: "计划开台时间",
                                    prop: "planStartTime"
                                  }
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      type: "datetime",
                                      placeholder: "计划开台时间"
                                    },
                                    model: {
                                      value: _vm.form.planStartTime,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "planStartTime", $$v)
                                      },
                                      expression: "form.planStartTime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "10",
                                    label: "计划了机时间",
                                    prop: "planEndTime"
                                  }
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      type: "datetime",
                                      placeholder: "计划了机时间"
                                    },
                                    model: {
                                      value: _vm.form.planEndTime,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "planEndTime", $$v)
                                      },
                                      expression: "form.planEndTime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-collapse-item",
                { attrs: { title: "配置机台", name: "2" } },
                [
                  _c(
                    "el-form",
                    { attrs: { model: _vm.form, rules: _vm.rules } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "设备机型",
                                    prop: "machineType"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        disabled: ""
                                      },
                                      on: { change: _vm.proccessChange },
                                      model: {
                                        value: _vm.form.machineModelId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "machineModelId",
                                            $$v
                                          )
                                        },
                                        expression: "form.machineModelId"
                                      }
                                    },
                                    _vm._l(_vm.proccessOptions, function(item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "适合机台",
                                    prop: "checkArrayList"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "borderWoker" },
                                    [
                                      _c(
                                        "el-checkbox-group",
                                        {
                                          staticStyle: { float: "left" },
                                          on: { change: _vm.changeCheck },
                                          model: {
                                            value: _vm.checkArrayList,
                                            callback: function($$v) {
                                              _vm.checkArrayList = $$v
                                            },
                                            expression: "checkArrayList"
                                          }
                                        },
                                        _vm._l(_vm.checkboxList, function(
                                          value
                                        ) {
                                          return _c(
                                            "el-checkbox",
                                            {
                                              key: value.id,
                                              staticStyle: {
                                                float: "left",
                                                "margin-bottom": "5px"
                                              },
                                              attrs: {
                                                label: value.id,
                                                border: "",
                                                disabled: "",
                                                checked: ""
                                              }
                                            },
                                            [_vm._v(_vm._s(value.name))]
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-collapse-item",
                { attrs: { title: "工艺信息", name: "2" } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        model: _vm.form,
                        "label-width": "160px",
                        rules: _vm.rules
                      }
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "纱支定长(m)",
                                    prop: "meters"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: { min: 0, disabled: "" },
                                    on: { change: _vm.getTheoryDoffingTime },
                                    model: {
                                      value: _vm.form.meters,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "meters", $$v)
                                      },
                                      expression: "form.meters"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "平均前罗拉直径(mm)",
                                    prop: "avgFrontRollerDiameter"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: { min: 0, disabled: "" },
                                    on: { change: _vm.getTheoryDoffingTime },
                                    model: {
                                      value: _vm.form.avgFrontRollerDiameter,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "avgFrontRollerDiameter",
                                          $$v
                                        )
                                      },
                                      expression: "form.avgFrontRollerDiameter"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "前罗拉速度(转/分钟)",
                                    prop: "frontRollerSpeed"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: { min: 0, disabled: "" },
                                    on: { change: _vm.getTheoryDoffingTime },
                                    model: {
                                      value: _vm.form.frontRollerSpeed,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "frontRollerSpeed",
                                          $$v
                                        )
                                      },
                                      expression: "form.frontRollerSpeed"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "理论落纱时间(H)",
                                    prop: "theoryDoffingTime"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: { min: 0, disabled: "" },
                                    model: {
                                      value: _vm.form.theoryDoffingTime,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "theoryDoffingTime",
                                          $$v
                                        )
                                      },
                                      expression: "form.theoryDoffingTime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "定量克重(g/km)",
                                    prop: "gramWeight"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      min: 0,
                                      max: 10000,
                                      label: "描述文字",
                                      value: "1",
                                      disabled: ""
                                    },
                                    on: { change: _vm.getTheoryDoffingTime },
                                    model: {
                                      value: _vm.form.gramWeight,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "gramWeight", $$v)
                                      },
                                      expression: "form.gramWeight"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 14 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "台日产量(KG/天)",
                                    prop: "dayYield"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      min: 0,
                                      max: 10000,
                                      label: "描述文字",
                                      disabled: ""
                                    },
                                    model: {
                                      value: _vm.form.dayYield,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "dayYield", $$v)
                                      },
                                      expression: "form.dayYield"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "公定回潮率%" } },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      min: 1,
                                      max: 10000,
                                      label: "描述文字",
                                      disabled: ""
                                    },
                                    model: {
                                      value: _vm.form.moistureRegain,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "moistureRegain",
                                          $$v
                                        )
                                      },
                                      expression: "form.moistureRegain"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 14 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "运转效率%",
                                    prop: "efficiencyPercent"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      min: 1,
                                      max: 10000,
                                      label: "描述文字",
                                      disabled: ""
                                    },
                                    model: {
                                      value: _vm.form.efficiencyPercent,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "efficiencyPercent",
                                          $$v
                                        )
                                      },
                                      expression: "form.efficiencyPercent"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-collapse-item",
                { attrs: { title: "工艺参数", name: "3" } },
                [
                  _c(
                    "el-form",
                    { attrs: { model: _vm.form, rules: _vm.rules } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "标准工艺单选择",
                                    prop: "machineType"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        disabled: ""
                                      },
                                      on: { change: _vm.processSheetChange },
                                      model: {
                                        value: _vm.form.processSheetId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "processSheetId",
                                            $$v
                                          )
                                        },
                                        expression: "form.processSheetId"
                                      }
                                    },
                                    _vm._l(_vm.processSheetList, function(
                                      item
                                    ) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.code,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c(
                          "footer",
                          [
                            _c(
                              "h3",
                              {
                                staticStyle: {
                                  height: "40px",
                                  "line-height": "40px",
                                  "text-align": "center"
                                }
                              },
                              [_vm._v("标准参数")]
                            ),
                            _c("avue-form", {
                              ref: "nextRef",
                              staticStyle: {
                                marginTop: "20px",
                                width: "300px"
                              },
                              attrs: { option: _vm.optionBefore },
                              model: {
                                value: _vm.lastform,
                                callback: function($$v) {
                                  _vm.lastform = $$v
                                },
                                expression: "lastform"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c(
                          "footer",
                          [
                            _c(
                              "h3",
                              {
                                staticStyle: {
                                  height: "40px",
                                  "line-height": "40px",
                                  "text-align": "center"
                                }
                              },
                              [_vm._v("设定参数")]
                            ),
                            _c("avue-form", {
                              ref: "nextRef",
                              staticStyle: {
                                marginTop: "20px",
                                width: "300px"
                              },
                              attrs: { option: _vm.optionSetUp },
                              on: { change: _vm.changeDifference },
                              model: {
                                value: _vm.setUpform,
                                callback: function($$v) {
                                  _vm.setUpform = $$v
                                },
                                expression: "setUpform"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c(
                          "footer",
                          [
                            _c(
                              "h3",
                              {
                                staticStyle: {
                                  height: "40px",
                                  "line-height": "40px",
                                  "text-align": "center"
                                }
                              },
                              [_vm._v("对比差值")]
                            ),
                            _c("avue-form", {
                              ref: "nextRef",
                              staticStyle: {
                                marginTop: "20px",
                                width: "300px"
                              },
                              attrs: { option: _vm.optionDifference },
                              model: {
                                value: _vm.differenceform,
                                callback: function($$v) {
                                  _vm.differenceform = $$v
                                },
                                expression: "differenceform"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { textAlign: "right", marginTop: "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-check", type: "success" },
                  on: { click: _vm.save }
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-back", type: "primary" },
                  on: { click: _vm.commit }
                },
                [_vm._v("返回")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }