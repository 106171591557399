<template>
  <div class="volumeSet">
    <el-dialog
        style="marginTop: -60px;"
        title="创建物模型管理"
        :visible.sync="modelShow"
        width="900px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        :append-to-body="true">
      <div class="content">
        <el-form ref="form" :model="form" label-width="80px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="设备机型">
                <el-input v-model="form.name" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备类别">
                <el-input v-model="form.name" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="工序">
                <el-input v-model="form.name" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="功率">
                <el-input v-model="form.name" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="创建人">
                <el-input v-model="form.name" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="创建时间">
                <el-input v-model="form.name" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-button type="primary" @click="openInner"> + {{$t("Add functionality")}}</el-button>
        <div class="table_container">
          <template>
            <el-table
                :data="tableData"
                style="width: 100%">
              <el-table-column
                  prop="date"
                  label="功能名称">
              </el-table-column>
              <el-table-column
                  prop="功能类型"
                  label="姓名">
              </el-table-column>
              <el-table-column
                  prop="address"
                  label="标识符">
              </el-table-column>
              <el-table-column
                  prop="address"
                  label="数据类型">
              </el-table-column>
              <el-table-column
                  prop="address"
                  label="数据长度">
              </el-table-column>
              <el-table-column
                  prop="address"
                  label="取值范围">
                <template slot-scope="{row}">
                  <i class="el-icon-time"></i>
                  <span style="margin-left: 10px">{{ row.date }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  prop="address"
                  label="补步长">
              </el-table-column>
              <el-table-column
                  prop="address"
                  label="读写类型">
              </el-table-column>
            </el-table>
          </template>
        </div>
      </div>
<!--      内存dialog-->
      <el-dialog
          width="900px"
          title="添加功能"
          :visible.sync="innerVisible"
          append-to-body>
        <div class="inner_content">
          <div class="table_container">
            <template>
              <el-table
                  :data="tableData"
                  style="width: 100%">
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column
                    prop="date"
                    label="功能名称"
                    >
                </el-table-column>
                <el-table-column
                    prop="功能类型"
                    label="姓名">
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="标识符">
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="数据类型">
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="数据长度">
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="取值范围">
                  <template slot-scope="{row}">
                    <i class="el-icon-time"></i>
                    <span style="margin-left: 10px">{{ row.date }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="补步长">
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="读写类型">
                </el-table-column>
              </el-table>
            </template>
          </div>
        </div>
        <p slot="footer" class="dialog-footer" style="margin-top: -30px;">
          <el-button @click="innerVisible = false">{{$t("Cancel")}}</el-button>
          <el-button type="primary" @click="innerSure">{{$t("Yes")}}</el-button>
        </p>
      </el-dialog>

      <p slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button @click="closeModel">{{$t("Cancel")}}</el-button>
        <el-button type="primary" @click="makeSure">{{$t("Yes")}}</el-button>
      </p>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "edit",
  props: {
    modelShow: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      form:{
        name:'哈哈哈'
      },
      tableData:[],
      innerVisible:false
    }
  },
  created(){

  },
  methods: {
    openInner(){
      this.innerVisible = true
    },
    innerSure(){

    },
    closeModel() {
      this.$emit('closeModel', false)
    },
    makeSure() {
      this.$emit('closeModel', false)
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  width: 100%;
  //text-align: center;
}
</style>