<template>
  <div>
    <basic-container>
        <avue-crud :option="tableOption"
                   :data="tableData"
                   :table-loading="tableLoading"
                   :page="page"
                   ref="crud"
                   @size-change="sizeChange"
                   @current-change="currentChange"
                   @selection-change="selectionChange"
                   @refresh-change="refreshChange"
                   @before-open="beforeOpen"
                   @row-save="handleSave"
                   @row-update="handleUpdate"
                   @row-del="handleDel">
            <template slot="status" slot-scope="scope">
                <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                           @change="change(scope.row)">
                </el-switch>
            </template>
            <template slot="menuRight" slot-scope="scope">
                <el-input v-model="nameOrCode" :placeholder="$t('MATERIAL NAME OR CODE')" style="display:inlie-block;width:200px;marginRight: 5px;" size="small"></el-input>
            </template>
            <template slot="menu" scope="scope">
                <div v-if="scope.row.status==0" style="height:30px;" />
            </template>
            <template slot-scope="{row,index,type}" slot="menuForm">
                <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" v-if="type=='add'" @click="$refs.crud.rowSave()">{{$t("Hold")}}</el-button>
                <el-button type="primary" icon="el-icon-circle-check" size="small" v-if="type=='edit'" @click="$refs.crud.rowUpdate()">{{$t("Hold")}}</el-button>
                <el-button icon="el-icon-circle-close" size="small" @click="$refs.crud.closeDialog()">{{$t("Cancel")}}</el-button>
            </template>
        </avue-crud>
    </basic-container>
  </div>
</template>
<script>
    import { list, add, edit, remove, setStatus, audit, getType, getIdType} from "@/api/mtrD";
import {getMtrType} from "@/api/config";
export default {
    watch: {
        mtrId: function (value) {
            console.log(value)
            value ? this.tableOption.addBtn = true : "";
            this.tableOption.column[0].value = value;
      }
    },
    data() {
        return {
            //字典id
            dafV: "",
            typeId: '',
            tableLoading: false,
            mtrDIcId: "",
            selectionList: [],
            mtrId: 1,
            nameOrCode: "",
            status: "",
            page: {
                pageSize: 10,
                pagerCount: 5,
                total: 0,
                pageCurrent: 1
            },
            disabled: true,
            tableData: [],
            tableOption: {
                addBtn: true,
                delBtn: true,
                menuWidth: 200,
                selection: true,
                tip: false,
                columnBtn: false,
                labelWidth: 150,
                dialogFullscreen: true,
                addBtnText: this.$t('ADD'),
                delBtnText: this.$t('DELETE'),
                editBtnText: this.$t('Edit'),
                emptyText: this.$t('No Data'),
                menuTitle: this.$t('OPERATION'),
                editTitle: this.$t('Edit'),
                addTitle: this.$t('Add'),
                saveBtn: false,
                updateBtn: false,
                cancelBtn: false,
                column: [{
                    label: this.$t('MATERIAL CLASSIFICATION'),
                    editDisplay: false,
                    hide: true,
                    type: "tree",
                    value: "",
                    dicData: [],
                    //dicUrl: this.businessUrl + "productCategory/select",
                    // change: function ($value) {
                    //     console.log($value)
                    //     getIdType($value.value).then(res => {
                    //         console.log($value)
                    //         console.log(res, "256")
                    //         this.mtrDIcId = res.data.data.type;
                    //         //this.$refs.crud
                    //         this.$refs.crud.$refs.dialogForm.$refs.tableForm.form.type = res.data.data.type;
                    //     });
                    //     if ($value) {
                    //         //this.tableOption.column[8].addDisplay = false;
                    //         //this.tableOption.column[10].addDisplay = false;
                    //     } else {
                    //         //this.tableOption.column[8].addDisplay = true;
                    //         //this.tableOption.column[10].addDisplay = true;
                    //     }
                    // }.bind(this),
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE SELECT MATERIAL CLASSIFICATION'),
                        trigger: "blur"
                    }],
                    prop: "tree",
                    props: {
                        label: "name",
                        value: "id",
                        children: "ChildNodes"
                    },
                    row: true,
                    span: 24
                }, {
                    label: this.$t('MATERIAL NAME'),
                    placeholder: this.$t('PLEASE ENTER MATERIAL NAME'),
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE SELECT A MATERIAL NAME'),
                        trigger: "blur"
                    }],
                    prop: "name"
                }, {
                    label: this.$t('MATERIAL CODE'),
                    placeholder: this.$t('PLEASE ENTER THE MATERIAL CODE'),
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE SELECT MATERIAL CODE'),
                        trigger: "blur"
                    }],
                    prop: "code"
                }, {
                    label: this.$t('SPECIFICATION AND MODEL'),
                    placeholder: this.$t('PLEASE ENTER THE SPECIFICATION AND MODEL'),
                    prop: "models",
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE SELECT SPECIFICATION AND MODEL'),
                        trigger: "blur"
                    }]
                }, {
                    label: this.$t('UNIT OF MEASURE'),
                    placeholder: this.$t('PLEASE SELECT UOM'),
                    dicUrl: this.businessUrl + "unit/selectAllUnit",
                    type: "select",
                    props: {
                        label: "name",
                        value: "id"
                    },
                    prop: "unitId",
                    width: 80,
                }, {
                    label: this.$t('INVENTORY TYPE'),
                    placeholder: this.$t('PLEASE SELECT INVENTORY TYPE'),
                    disabled: false,
                    // hide: true,
                    type: "select",
                    prop: "type",
                    dicUrl: this.businessUrl + "dict/selectDictByCategoryId?categoryId=2263ddddc4c74b639d40eac679b4fd41",
                    // change: function (value) {
                    //   console.log(value.value);
                    // },
                    formatter: function (row, value) {
                        console.log(row, value);
                        return row.strType;
                    },
                    props: {
                        label: "name",
                        value: "id"
                    },
                    rules: [{
                        required: false,
                        message: this.$t('PLEASE SELECT INVENTORY TYPE'),
                        trigger: "blur"
                    }]
                },
                {
                    label: this.$t('BAR CODE'),
                    placeholder: this.$t('PLEASE ENTER BARCODE'),
                    prop: "barCode",
                    width: 80,
                },
                // {
                //     label: this.$t('RAW MATERIAL COMPOSITION'),
                //     placeholder: this.$t('PLEASE SELECT RAW MATERIAL COMPOSITION'),
                //     multiple: true,
                //     addDisplay: true,
                //     width: 120,
                //     type: "select",
                //     dicData: [],
                //     props: {
                //         label: "productPropertyValueName",
                //         value: "id"
                //     },
                //     prop: "productPropertyValueList1"
                // }, {
                //     label: this.$t('SPINNING PROCESS'),
                //     placeholder: this.$t('PLEASE SELECT SPINNING PROCESS'),
                //     type: "select",
                //     multiple: true,
                //     dicData: [],
                //     dicUrl: this.businessUrl + "productPropertyItem/queryProperty?name=纺纱工艺",
                //     props: {
                //         label: "productPropertyValueName",
                //         value: "id"
                //     },
                //     prop: "productPropertyValueList2"
                // }, {
                //     label: this.$t('YARN USE'),
                //     placeholder: this.$t('PLEASE SELECT YARN USAGE'),
                //     type: "select",
                //     multiple: true,
                //     dicData: [],
                //     props: {
                //         label: "productPropertyValueName",
                //         value: "id"
                //     },
                //     prop: "productPropertyValueList3"
                // }, 
                {
                    label: this.$t('NUMBER OF BRANCHES'),
                    value: 0,
                    type: "number",
                    prop: "yarnCount",
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER THE NUMBER OF PIECES'),
                        trigger: "blur"
                    }],
                    width:100,
                }, {
                    label: this.$t('ENABLE BATCH NO'),
                    width: 150,
                    value: 0,
                    prop: "enableBatch",
                    type: "radio",
                    dicData: [{
                        label: this.$t('True'),
                        value: 1
                    }, {
                        label: this.$t('False'),
                        value: 0
                    }],
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER THE ACTIVATION BATCH NUMBER'),
                        trigger: "blur"
                    }]
                }, {
                    label: this.$t('IS IT A STANDARD DISCOUNT VARIETY'),
                    width: 150,
                    value: 0,
                    prop: "isStandard",
                    type: "radio",
                    dicData: [{
                        label: this.$t('True'),
                        value: 1
                    }, {
                        label: this.$t('False'),
                        value: 0
                    }],
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER WHETHER IT IS A STANDARD DISCOUNT VARIETY'),
                        trigger: "blur"
                    }]
                }, {
                    label: this.$t('STATE'),
                    addDisplay: false,
                    prop: "status",
                    type: "select",
                    slot: true,
                    dicData: [{
                        label: this.$t('ENABLE'),
                        value: 0
                    }, {
                        label: this.$t('DISABLE'),
                        value: 1
                    }]
                }, {
                    label: this.$t('CREATTIME'),
                    width: 200,
                    editDisplay: false,
                    addDisplay: false,
                    prop: "createTime"
                }]
            }
        }
    },
    created() {
      // this.request.get(this.businessUrl + "productPropertyItem/queryProperty?name=原料成分").then(res => {
      //   console.log(res);
      //   this.tableOption.column[7].dicData = res.data.data;
      // });
      // this.request.get(this.businessUrl + "productPropertyItem/queryProperty?name=纺纱工艺").then(res => {
      //   this.tableOption.column[8].dicData = res.data.data;
      // });
      // this.request.get(this.businessUrl + "productPropertyItem/queryProperty?name=纱线用途").then(res => {
      //   this.tableOption.column[9].dicData = res.data.data;
      // });
        this.request.get(this.businessUrl + "productCategory/select").then(res => {
            this.tableOption.column[0].dicData = res.data.data;
        });
      list()
    },
    methods: {
        beforeOpen() {
          
        },
        setAudit(status) {
          audit(this.selectionList[0].id,status).then(() => {
            if (status == 0) {
                this.$message({ type: "success", message: this.$t('AUDIT SUCCEEDED')});
            } else {
                this.$message({ type: "success", message: this.$t('DE APPROVAL SUCCEEDED')});
            };
          })
        },
        sizeChange(pageSize) {
          this.page.pageSize = pageSize;
          this.list();
        },
        currentChange(pageCurrent) {
          this.page.pageCurrent = pageCurrent;
          this.list();
        },
        clickList(mtrId) {
            let str;
            if (mtrId == 2) {
                str = 'unclassified';
            }else {
                 str = mtrId;
            }
            this.mtrId = str;
            console.log(this.mtrId);
            list(this.status,this.nameOrCode,this.page.pageCurrent,this.page.pageSize,str).then(res => {
                res.data.data.items.forEach(v => {
                  v.productPropertyItemList ? v.productPropertyItemList.forEach(value => {
                    if(value.name == "纺纱工艺") {
                      v.productPropertyValueList2 = value.id;
                    } else if(value.name == "原料成分") {
                      v.productPropertyValueList1  = value.id;
                    } else if(value.name == "纱线用途") {
                      v.productPropertyValueList3  = value.id;
                    }
                  }):"";
                  v.productAddition ? v.yarnCount = v.productAddition.yarnCount : "";
                })
                this.tableData = res.data.data.items;
                this.page.total = res.data.data.total;
            })
        },
        handleDel(row) {
            this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('Cancel'),
                type: 'warning'
            }).then(() => {
                remove(row.id).then((res) => {
                   if(res.data.code == "0000"){
                    this.$message({
                         type: "success",
                         message: this.$t('DeleteSuccess'),
                        });
                    }
                    this.list();
                })
            })
        },
        handleUpdate(row,index,done) {
            //选择集合
            let saveList = [];
            console.log(row.productPropertyValueList1);
            // if (row.productPropertyValueList1 && row.productPropertyValueList2 && row.productPropertyValueList3) {
            //   saveList.push({
            //     productPropertyItemId : this.tableOption.column[8].dicData.find(value => {
            //     return value.id == row.productPropertyValueList1;
            //   }).productPropertyValueId,
            //     id : row.productPropertyValueList1});
            //     console.log(this.tableOption.column[9].dicData,row.productPropertyValueList2);
            //   saveList.push({
            //     productPropertyItemId : this.tableOption.column[9].dicData.find(value => {
            //     return value.id == row.productPropertyValueList2;
            //   }).productPropertyValueId,
            //     id : row.productPropertyValueList2});
            //     saveList.push({
            //     productPropertyItemId : this.tableOption.column[10].dicData.find(value => {
            //     return value.id == row.productPropertyValueList3;
            //   }).productPropertyValueId,
            //     id : row.productPropertyValueList3});
            //   console.log(saveList);
            //   row.productPropertyValueList = saveList;
            // }
            console.log(row)
            edit(row,this.mtrId).then((res) => {
              console.log(res);
              if (res.data.code == "0000") {
                this.$message({
                  type: "success",
                    message: this.$t('EDIT SUCCEEDED')
                });
              }
                this.list();
                done();
            })
        },
        handleSave(row,done) {
            console.log(row);
            //选择集合
            // let saveList = [];
            // if (row.productPropertyValueList1 && row.productPropertyValueList2 && row.productPropertyValueList3) {
            //   row.productPropertyValueList1.forEach((v,i)=>{
            //     saveList.push({
            //       productPropertyItemId : this.tableOption.column[8].dicData.find(value => {
            //         return value.id == row.productPropertyValueList1[0];
            //       }).productPropertyValueId,
            //         id : row.productPropertyValueList1[0]});
            //     })
            //   row.productPropertyValueList2.forEach((v,i)=>{
            //     saveList.push({
            //       productPropertyItemId : this.tableOption.column[9].dicData.find(value => {
            //       return value.id == row.productPropertyValueList2[i];
            //     }).productPropertyValueId,
            //       id : row.productPropertyValueList2[i]});
            //   })
            //   row.productPropertyValueList3.forEach((v,i)=>{
            //     saveList.push({
            //       productPropertyItemId : this.tableOption.column[10].dicData.find(value => {
            //       return value.id == row.productPropertyValueList3[i];
            //     }).productPropertyValueId,
            //       id : row.productPropertyValueList3[i]});
            //   })
            //   console.log(saveList);
            //   row.productPropertyValueList = saveList;
            // }
            // delete row.productPropertyValueList1;
            // delete row.productPropertyValueList2;
            // delete row.productPropertyValueList3;
            // delete row.productAdditionDto;
            // row.type = this.mtrDIcId;
            // console.log(this.mtrId)
            // console.log(row)
            let str
            if (this.mtrId == 2) {
                str = 'unclassified'
            }else {
                 str = this.mtrId
            }
            add(row,str).then((res) => {
                if(res.data.code == "0000"){
                    this.$message({
                         type: "success",
                          message: this.$t('AddSuccess')
                     });
                }
                this.list();
                done();
            });
        },
        change(row) {
            setStatus(row).then(() => {
              this.$message({
                type: "success",
                  message: this.$t('STATUS MODIFIED SUCCESSFULLY')
              });
              this.list();
            })
        },
        selectionChange(list) {
            this.selectionList = list;
            console.log(list);
            list != "" ? this.disabled = false : this.disabled = true;
        },
        refreshChange() {
          this.list();
        },
        list(){
          list(this.status,this.nameOrCode,this.page.pageCurrent,this.page.pageSize,this.mtrId).then(res => {
            console.log(res);
                // res.data.data.items.forEach(v => {
                //   v.productPropertyItemList.forEach(value => {
                //     if(value.name == "纺纱工艺") {
                //       v.productPropertyValueList2 = value.id;
                //     } else if(value.name == "原料成分") {
                //       v.productPropertyValueList1 = value.id;
                //     } else if(value.name == "纱线用途") {
                //       v.productPropertyValueList3 = value.id;
                //     }
                //   });
                //   v.productAddition ? v.yarnCount = v.productAddition.yarnCount : "";
                // })
                this.tableData = res.data.data.items;
                this.page.total = res.data.data.total;
        })
        },
        envText: function() {
            return this.env
        }
  }
}
</script>
<style scoped>
    .el-select-dropdown__item {
        padding-left: 0px;
    }
</style>