<template>
  <div>
    <el-dialog
      :title='$t("Add")'
    :visible.sync="addBool"
      width="80%"
      :append-to-body="true">
        <!-- <el-collapse v-model="activeNames" @change="handleChange"> -->
        <el-collapse-item title="基础信息" name="1">
            <el-form inline ref="form" :model="form" label-width="80px">
            <el-row :gutter="20">
                <el-form-item label="工艺单号">
                    <el-input placeholder="请输入工艺单号" v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="单据日期">
                    <el-date-picker
                        v-model="value1"
                        type="date"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="工艺员">
                    <el-select v-model="value" placeholder="请选择">
                        <el-option
                        v-for="item in wklnOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-row>
                <el-form-item label="产品名称">
                    <el-select v-model="value" placeholder="请选择">
                        <el-option
                        v-for="item in wklnOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="批号">
                    <el-select v-model="value" placeholder="请选择">
                        <el-option
                        v-for="item in wklnOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="生产工序">
                    <el-select v-model="value" placeholder="请选择">
                        <el-option
                        v-for="item in wklnOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                </el-form>
        </el-collapse-item>
        <el-collapse-item title="工艺信息" name="2">
            <div>控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；</div>
            <div>页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。</div>
        </el-collapse-item>
        <el-collapse-item title="工艺项目信息" name="3">
            <div>简化流程：设计简洁直观的操作流程；</div>
            <div>清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；</div>
            <div>帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。</div>
        </el-collapse-item>
        </el-collapse>
        <p slot="footer" class="dialog-footer" style="margin-top: -30px;">
          <el-button @click="addBool = false">{{$t("Cancel")}}</el-button>
          <el-button type="primary" @click="submitAdd">{{$t("Yes")}}</el-button>
        </p>
    </el-dialog>
    <basic-container>
    <avue-crud :option="tableOption"
                v-model="tableFrom"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :page="page"
                 ref="crud"
                 @current-change="currentChange"
                 @size-change="sizeChange"
                 :before-open="beforeOpen"
                 @selection-change="selectionChange"
                 @refresh-change="refreshChange"
                 @row-save="handleSave"
                 @row-update="handleUpdate"
                 @row-del="handleDel">
            <template slot="status" slot-scope="scope">
                <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                @change="change(scope.row)">
                </el-switch>
            </template>
        <template slot="name" slot-scope="scope">
            <el-link @click="linkToLook(scope.row)">{{scope.row.name}}</el-link>
        </template>
        <template slot="menuLeft">
            <!-- <el-button type="primary" size="small" icon="el-icon-plus" @click="addClick">{{$t("Add")}}</el-button> -->
            <el-dropdown>
                <!-- <el-button type="success" icon="el-icon-menu" size="small">
                    (全部状态)<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button> -->
                <!-- <el-dropdown-menu slot="dropdown"> -->
                    <!-- <el-dropdown-item>正常</el-dropdown-item>
                    <el-dropdown-item>开始</el-dropdown-item>
                    <el-dropdown-item>启动</el-dropdown-item>
                    <el-dropdown-item>禁用</el-dropdown-item>
                    <el-dropdown-item>🚀</el-dropdown-item> -->
                <!-- </el-dropdown-menu> -->
            </el-dropdown>
            <!-- <el-button type="primary" size="small" icon="el-icon-discover" v-bind:disabled="disabled">审核</el-button>
            <el-button type="warning" size="small" icon="el-icon-discover" v-bind:disabled="disabled">撤销审核</el-button> -->
        </template>
        <template slot="menuRight">
            <el-select clearable v-model="machineModel" placeholder="设备机型选择" @change="getMachineModelId" size="small" style="width:150px !important;marginRight: 5px;">
                <el-option v-for="item in machineModelList " :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-select clearable v-model="machine" placeholder="设备选择" size="small" style="width:150px !important;marginRight: 5px;">
                <el-option v-for="item in machineList " :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-select clearable v-model="wkln" placeholder="工序" size="small" style="width:110px !important;marginRight: 5px;">
                <el-option v-for="item in wklnList " :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-button type="primary" size="small" icon="el-icon-search" @click="search">{{$t("search")}}</el-button>
            <!-- <el-button type="primary" size="small" icon="el-icon-search">高级搜索</el-button> -->
        </template>
        <template slot="baseName" slot-scope="scope">
            <el-link @click="linkToTab(scope.row)">{{scope.row.baseName}}</el-link>
        </template>
        <!-- <template slot="menu">
            <el-button type="primary" round></el-button>
        </template> -->
    </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {planList,add,edit,remove,wklnList,machineModelList,getMachineByModeId} from "@/api/realityList"
export default {
    data() {
        return {
            tableLoading:false,
            tableFrom: {},
            value1: '',
            value: '',
            //工艺员
            wklnOptions: [],
            form: {},
            addBool: false,
            machineModel: "",
            machineModelList: [],
            machine: "",
            machineList: [],
            wkln: "",
            wklnList: [],
            nameOrCode: "",
            departmentId: "1",
            page: {
                pageSize: 20,
                pagerCount:5,
                total:'',
                pageCurrent: 1
            },
            disabled:true,
            tableData:[],
            tableOption:{
              addBtn: false,
              editBtn: false,
              delBtn: true,
              menuWidth:200,
              selection: true,
              tip: false,
              columnBtn : false,
              labelWidth: "120",
              dialogFullscreen:true,
              //addBtnText: this.$t('ADD'),
              delBtnText: this.$t('DELETE'),
              //editBtnText: this.$t('Edit'),
              emptyText: this.$t('No Data'),
              menuTitle: this.$t('OPERATION'),
              column:[{
                label: "工艺单号",
                prop: "specSheetCode",
                slot: true
              },{
                label: "设备机型",
                prop: "machineModelCode"
              },{
                label: "设备名称",
                prop: "machineName"
              },{
                label: "产品名称",
                prop: "productName"
              },{
                label: "规格",
                prop: "productModels"
              },{
                label: "生产工序",
                prop: "processName"
              },{
                label: "更新日期",
                prop: "createTime",
                dicData: []
              },{
                label: "创建人",
                prop: "userName"
              }]
            }
        }
    },
    created() {
      this.list();
      wklnList().then(res => {
          this.wklnList = res.data;
      });
      machineModelList().then(res => {
          this.machineModelList = res.data;
      });
    },
    methods: {
         getMachineModelId(vel){
          console.log('vel',vel);
          getMachineByModeId(vel).then(res => {
              console.log(res);
              this.machineList = res.data.data
          })
        },
        currentChange(pageCurrent) {
          this.page.pageCurrent = pageCurrent;
          this.list();
        },
        sizeChange(pageSize) {
          this.page.pageCurrent = 1;
          this.page.pageSize = pageSize;
          this.list();
        },
        linkToLook(row) {
            this.$router.push({path: "lookreality",query: {pushData: row}});
        },
        beforeOpen(done,type) {
            console.log(this.tableFrom);
            this.$router.push({path: "editreality",query: {pushData: this.tableFrom}});
        },
        search() {
            this.list();
        },
        linkToTab(row) {
            this.$router.push({path: "standardList",query: {pushData: row}})
        },
        submitAdd() {
            this.addBool = false;
        },
        addClick() {
            this.$router.push({path: "addreality"});
        },
        handleDel(row) {
            this.$confirm("确定要删除吗","提示",{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                remove(row.id).then(() => {
                    this.list();
                })
            })
        },
        handleUpdate(row,index,done) {
            edit(row).then(() => {
                this.list();
                done();
            })
        },
        handleSave(row,done) {
            add(row).then(() => {
                this.list();
                done();
            })
        },
        change(row) {
            changeStatus(row)
        },
        selectionChange(list) {
            console.log(list);
            list != "" ? this.disabled = false : this.disabled = true;
        },
        refreshChange() {
          this.list();
        },
        list(){
          planList(this.page.pageCurrent,this.page.pageSize,this.wkln,this.machineModel,this.machine,this.status).then(res => {
              console.log('res',res);
              this.page.total = res.data.data.total
            //   res.data.data.items.forEach(v => {
            //       v.machineCodes = v.machineCodes.join(",");
            //   });
              this.tableData = res.data.data.items;
              console.log('this.tab',this.tableData);

              this.tableData.forEach(v => {
                  v.status = v.status;
              })
          })
        },
        envText: function() {
            return this.env
        }
  }
}
</script>
<style scoped>
    .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        padding-left: 10px;
    }
</style>