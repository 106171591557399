<template>
  <div>
        <basic-container>
            <el-button icon="el-icon-back" type="success" @click="commitStandard">确认</el-button>
            <el-collapse v-model="activeNames" style="marginTop: 30px;">
                <el-collapse-item title="基础信息" name="1">
                    <el-form align="center" class="content" ref="ruleform" :model="form" label-width="80px" :rules="rules">
                        <el-row :gutter="20">
                            <el-col :span="6">
                                <el-form-item label="工艺单号">
                                    <el-input placeholder="工艺单号" v-model="form.name"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="工艺员" prop="workPerson">
                                    <el-select v-model="form.specUserid" placeholder="请选择工艺员"  class="formSelect">
                                        <el-option
                                        v-for="item in personOptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.userId">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="产品名称" prop="productId">
                                    <el-select v-model="form.productId" placeholder="请选择" @change="chengeProduct"  class="formSelect">
                                        <el-option
                                        v-for="item in wklnOptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <!-- <el-col :span="6">
                                <el-form-item label="批 号">
                                    <el-select v-model="form.batchCode" placeholder="请选择"  class="formSelect">
                                        <el-option
                                        v-for="item in batchOptions"
                                        :key="item.value"
                                        :label="item.batchCode"
                                        :value="item.batchCode">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col> -->
                            <el-col :span="6">
                                <el-form-item label="生产工序" prop="processId">
                                    <el-select v-model="form.processId" placeholder="请选择" @change="wklnChange" class="formSelect">
                                        <el-option
                                        v-for="item in wklnOption"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-collapse-item>
                <el-collapse-item title="工艺信息" name="2">
                    <el-form :model="form" label-width="160px" :rules="rules">
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="设备机型" prop="machineType">
                                    <el-select v-model="form.machineModelId" placeholder="请选择" @change="proccessChange">
                                        <el-option
                                        v-for="item in proccessOptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="18">
                                <el-form-item label="适合机台" prop="checkArrayList">
                                        <div class="borderWoker" label-width="160px">
                                            <el-checkbox-group v-model="checkArrayList" @change="changeCheck" style="float: left;">
                                                <el-checkbox v-for="value in checkboxList" :key="value.id" :label="value.id" border style="width:120px;margin-left: 10px;">{{value.name}}</el-checkbox>
                                            </el-checkbox-group>
                                        </div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="10">
                                <el-form-item label="纱支定长(m)">
                                    <el-input-number v-model="form.meters" :min="0" @change="getTheoryDoffingTime"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="平均前罗拉直径(mm)" prop="avgFrontRollerDiameter">
                                    <el-input-number v-model="form.avgFrontRollerDiameter" :min="0" @change="getTheoryDoffingTime"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="10">
                                <el-form-item label="前罗拉速度(转/分钟)" prop="frontRollerSpeed">
                                    <el-input-number v-model="form.frontRollerSpeed" :min="0" @change="getTheoryDoffingTime"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="理论落纱时间(H)" prop="theoryDoffingTime">
                                    <el-input-number v-model="form.theoryDoffingTime" :min="0"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="10">
                                <el-form-item label="定量克重(g/km)" prop="gramWeight">
                                    <el-input-number v-model="form.gramWeight" :min="0" :max="10000" label="描述文字" value="1" ></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="14">
                                <el-form-item label="台日产量(KG/天)" prop="dayYield">
                                    <el-input-number v-model="form.dayYield" :min="0" :max="10000" label="描述文字"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="10">
                                <el-form-item label="公定回潮率%" prop="moistureRegain">
                                    <el-input-number v-model="form.moistureRegain" :min="0" :max="10000" label="描述文字"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="14">
                                <el-form-item label="运转效率%" prop="efficiencyPercent">
                                    <el-input-number v-model="form.efficiencyPercent" :min="0" :max="10000" label="描述文字"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-collapse-item>
                <el-collapse-item title="工艺项目信息" name="3">
                    <el-table
        header-align="center"
      :data="tableData"
      style="width: 100%">
      <el-table-column
        type="index"
        label="序号"
        width="80">
      </el-table-column>
      <el-table-column
        prop="specParamName"
        label="工艺项目"
        width="100">
      </el-table-column>
      <el-table-column
        prop="value"
        header-align="center"
        width="200"
        label="工艺项目值">
        <template slot-scope="scope">
            <el-input-number v-if="scope.row.inputType == 1" v-model="scope.row.value" :min="1" :max="10000" label="描述文字"></el-input-number>
            <el-select v-model="scope.row.value" v-else>
                <el-option :label="scope.row.specParamValue" :value="scope.row.specParamValue">
                </el-option>
            </el-select>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        width="200"
        label="上限值">
        <template slot-scope="scope">
            <el-input-number v-if="scope.row.inputType == 1" v-model="scope.row.maxValue" :min="1" :max="10000" label="上限值"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        width="200"
        label="下限值">
        <template slot-scope="scope">
            <el-input-number v-if="scope.row.inputType == 1" v-model="scope.row.minValue" :min="1" :max="10000" label="下限值"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column
        prop="paramType"
        :formatter="formatParamType"
        label="项目类型">
        <template slot-scope="scope">
            <p v-if="scope.row.paramType == 1"> 设备工艺 </p>
            <p v-else> 运转工艺 </p>
        </template>
      </el-table-column>
      <el-table-column
        prop="isNull"
        label="是否允许为空">
        <template slot-scope="scope">
            <p v-if="scope.row.isNull == 1"> 是 </p>
            <p v-else> 否 </p>
        </template>
      </el-table-column>
      <el-table-column
        prop="dataType"
        label="数据类型">
        <template slot-scope="scope">
            <p v-if="scope.row.dataType == 1"> 数值型 </p>
            <p v-else> 字符型 </p>
        </template>
      </el-table-column>
    </el-table>
                </el-collapse-item>
            </el-collapse>
        </basic-container>
  </div>
</template>
<script>
import {add,edit,getTheory,getDayYield} from "@/api/standardList";
import {productWork,getpoccessByproduct,wklnList,machineModelList,getmachineByMachineModelId,getwklnWorkBywkln,getPerson} from "@/api/config";
export default {
    data() {
        return {
            form:{
                //编辑id
                name: "",
                editId: "",
                specUserid: "",
                hourYield: "",
                //定量克重
                gramWeight: "1",
                //单产分子
                hourYieldSon: 1,
                //定量
                machineModelId: "",
                //产品名称
                productId: "",
                //工序
                processId: "",
                //选择机台
                checkArrayList: [],
                //纱支定长
                meters: 0,
                //平均前罗拉直径
                avgFrontRollerDiameter: 0,
                //前罗拉速度
                frontRollerSpeed: 0,
                //理论落纱时间
                theoryDoffingTime: 0,
                //批号
                batchCode: "",
                 //日产
                dayYield:"",
                //公定回潮
                moistureRegain: 0,
                 //运转效率
                efficiencyPercent: 0,
            },
            person: "",
            //工艺员
            personOptions: [],
            //
            specParamList: [],
            //全选
            isIndeterminate: false,
            checkArrayList: [],
            checkboxList: [],
            //设备机型
            proccess: "",
            proccessOptions: [],
            wklnValue: "",
            //生产工序
            wklnOption: [],
            batchOptions: [],
            //产品名称
            product: [],
            activeNames: ["1","2","3"],
            form: {},
            wklnOptions: [],
            rules: {
                productId: [ {required: true, message: '请选择产品名称', trigger: 'blur'} ],
                processId: [ {required: true, message: '请选择生产工序', trigger: 'blur'} ],
                machineType: [ {required: true, message: '请选择设备机型', trigger: 'blur'} ],
                meters: [{ required: true, message: '请输入纱支定长', trigger: 'blur' }],
                avgFrontRollerDiameter: [{ required: true, message: '请输入前罗拉直径', trigger: 'blur' }],
                frontRollerSpeed: [{ required: true, message: '请输入前罗拉速度', trigger: 'blur' }],
                theoryDoffingTime: [{ required: true, message: '请输入理论落纱时间', trigger: 'blur' }],
                gramWeight: [{ required: true, message: '请选择定量克重(g/km)', trigger: 'blur'} ],
                dayYield: [{ required: true, message: '请选择台日产量', trigger: 'blur'} ],
                efficiencyPercent: [ {required: true, message: '请选择运转效率%', trigger: 'blur'} ],
                textCode: [ {required: true, message: '请输入活动名称', trigger: 'blur'} ],
            }
        }
    },
    created() {
        this.form = this.$route.query.pushData;
        //获取机台
        getmachineByMachineModelId(this.$route.query.pushData.machineModelId).then(res => {
            this.checkboxList = res.data.data;
        });
        console.log('=====',this.$route.query.pushData);
        this.checkArrayList = this.$route.query.pushData.machineCodes;
        this.tableData = this.$route.query.pushData.specSheetParamList;
        this.editId = this.$route.query.pushData.id;
        //工艺员
        getPerson().then(res => {
            this.personOptions = res.data.data;
        });
        machineModelList().then(res => {
            this.proccessOptions = res.data;
        });
        wklnList().then(res => {
            this.wklnOption = res.data;
        })
        productWork().then(res => {
            this.wklnOptions = res.data.data;
        })
    },
    methods: {
        getTheoryDoffingTime() {
            console.log(this.form.meters, this.form.frontRollerSpeed, this.checkArrayList);
            getTheory(this.form.meters, this.form.frontRollerSpeed, this.checkArrayList).then(res => {
                    console.log(res,'wai')
                    if (res.data.data != null) {
                        this.form.theoryDoffingTime = Number(res.data.data.theoryDoffingTime).toFixed(2);
                    } else {
                        this.form.theoryDoffingTime = 0;
                    }
                    console.log(this.proid, this.form.meters, this.form.frontRollerSpeed, this.checkArrayList, this.form.gramWeight, this.form.theoryDoffingTime)
                    getDayYield(this.proid, this.form.meters, this.form.frontRollerSpeed, this.checkArrayList, this.form.gramWeight, this.form.theoryDoffingTime).then(res => {
                        console.log(res,'nei')
                        if (res.data.data != null) {
                            this.form.dayYield = res.data.data.dayYield;
                        } else {
                            this.form.dayYield = 0;
                        }
                    })
                });
        },
        formatParamType(row) {
            let formatData = [{
                label: "是",
                value: 1
            },{
                label: "否",
                value: 0
            }];
            formatData.forEach(v => {
                if(row.id) {
                    
                }
            })
        },
        allClick() {
        },
        changeCheck(value) {
            if(value == "全选") {
                let checkList = [];
                this.checkboxList.forEach(v => {
                    checkList.push(v.id);
                });
                checkList.push("全选");
                this.$set(this.form,"checkArrayList",checkList);
                console.log(this.checkArrayList);
            }
        },
        proccessChange(value) {
            getmachineByMachineModelId(value).then(res => {
                this.checkboxList = res.data.data;
                this.$forceUpdate();
            });
        },
        wklnChange(value) {
            getwklnWorkBywkln(value).then(res => {
                res.data.data.forEach(v => {
                    if(v.inputType == 1) {
                        v.maxValue = 1;
                        v.minValue = 1;
                    }
                });
                this.tableData = res.data.data;
                this.$forceUpdate();
            })
        },
        chengeProduct(value) {
            getpoccessByproduct(value).then(res => {
                this.batchOptions = res.data.data;
            })
        },
        commitStandard() {
            let row = {
                //工艺员
                name: this.form.name,
                specUserid:this.form.specUserid,
                productId: this.form.productId,
                batchCode: this.form.batchCode,
                processId:this.form.processId,
                machineModelId:this.form.machineModelId,
                //台时单产
                dayYield: this.form.dayYield,
                moistureRegain: this.form.moistureRegain,
                //定量相除
                gramWeight: this.form.gramWeight,
                efficiencyPercent: this.form.efficiencyPercent,
                meters: this.form.meters,
                theoryDoffingTime: this.form.theoryDoffingTime,
                tubeColorId: this.form.tubeColorId,
                frontRollerSpeed: this.form.frontRollerSpeed
            };
            //选择机台
            let machineIds = this.checkArrayList;
            let specSheetParamDtoList = [];
            this.tableData ? this.tableData.forEach(v => {
                specSheetParamDtoList.push({maxValue: v.maxValue,minValue: v.minValue,specParamId: v.specParamId,value: v.value})
            }):"";
            let formName = "ruleform";
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    edit(row,machineIds,specSheetParamDtoList,this.editId).then(() => {
                        if(res.data.code == "0000") {
                            this.$message({
                                message: "保存成功",
                                type: "success"
                            });
                        }
                    }).then(() => {
                        this.$router.push({path: "standardList"});
                    });
                } else {
                    return false;
                }
            });
            this.$router.push({path: "standardList"});
        }
    }
}
</script>
<style lang="scss" scoped>
    .formSelect {
        width: 100%;
    }
</style>