var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-back", type: "success" },
              on: { click: _vm.commitStandard }
            },
            [_vm._v("确认")]
          ),
          _c(
            "el-collapse",
            {
              staticStyle: { marginTop: "30px" },
              on: { change: _vm.handleChange },
              model: {
                value: _vm.activeNames,
                callback: function($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames"
              }
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { title: "基础信息", name: "1" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleform",
                      staticClass: "content",
                      attrs: {
                        align: "center",
                        model: _vm.form,
                        "label-width": "80px",
                        rules: _vm.rules
                      }
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "工艺单号" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "工艺单号",
                                      disabled: ""
                                    },
                                    model: {
                                      value: _vm.form.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "name", $$v)
                                      },
                                      expression: "form.name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "工艺员", prop: "workPerson" }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "formSelect",
                                      attrs: { placeholder: "请选择工艺员" },
                                      model: {
                                        value: _vm.form.specUserid,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "specUserid", $$v)
                                        },
                                        expression: "form.specUserid"
                                      }
                                    },
                                    _vm._l(_vm.personOptions, function(item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.userId
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "产品名称",
                                    prop: "productId"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "formSelect",
                                      attrs: { placeholder: "请选择" },
                                      on: { change: _vm.chengeProduct },
                                      model: {
                                        value: _vm.form.productId,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "productId", $$v)
                                        },
                                        expression: "form.productId"
                                      }
                                    },
                                    _vm._l(_vm.wklnOptions, function(item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "批 号" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "formSelect",
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.form.batchCode,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "batchCode", $$v)
                                        },
                                        expression: "form.batchCode"
                                      }
                                    },
                                    _vm._l(_vm.batchOptions, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.batchCode,
                                          value: item.batchCode
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "生产工序",
                                    prop: "processId"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "formSelect",
                                      attrs: { placeholder: "请选择" },
                                      on: { change: _vm.wklnChange },
                                      model: {
                                        value: _vm.form.processId,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "processId", $$v)
                                        },
                                        expression: "form.processId"
                                      }
                                    },
                                    _vm._l(_vm.wklnOption, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-collapse-item",
                { attrs: { title: "工艺信息", name: "2" } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        model: _vm.form,
                        "label-width": "100px",
                        rules: _vm.rules
                      }
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 5 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "设备机型",
                                    prop: "machineType"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      on: { change: _vm.proccessChange },
                                      model: {
                                        value: _vm.form.machineModelId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "machineModelId",
                                            $$v
                                          )
                                        },
                                        expression: "form.machineModelId"
                                      }
                                    },
                                    _vm._l(_vm.proccessOptions, function(item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 19 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "适合机台",
                                    prop: "checkArrayList"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "borderWoker" },
                                    [
                                      _c(
                                        "el-checkbox-group",
                                        {
                                          staticStyle: { float: "left" },
                                          on: { change: _vm.changeCheck },
                                          model: {
                                            value: _vm.checkArrayList,
                                            callback: function($$v) {
                                              _vm.checkArrayList = $$v
                                            },
                                            expression: "checkArrayList"
                                          }
                                        },
                                        _vm._l(_vm.checkboxList, function(
                                          value
                                        ) {
                                          return _c(
                                            "el-checkbox",
                                            {
                                              staticStyle: { float: "left" },
                                              attrs: {
                                                label: value.id,
                                                border: ""
                                              }
                                            },
                                            [_vm._v(_vm._s(value.name))]
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "定量(g/km)",
                                    prop: "hourYieldPerson"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "41%" },
                                    attrs: {
                                      min: 1,
                                      max: 10000,
                                      label: "描述文字",
                                      value: "1"
                                    },
                                    on: { change: _vm.handleChange },
                                    model: {
                                      value: _vm.form.gramWeight,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "gramWeight", $$v)
                                      },
                                      expression: "form.gramWeight"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 14 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "台时单产",
                                    prop: "hourYield"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      min: 1,
                                      max: 10000,
                                      label: "描述文字"
                                    },
                                    on: { change: _vm.handleChange },
                                    model: {
                                      value: _vm.form.hourYield,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "hourYield", $$v)
                                      },
                                      expression: "form.hourYield"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "公定回潮率%", prop: "&" } },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      min: 1,
                                      max: 10000,
                                      label: "描述文字"
                                    },
                                    on: { change: _vm.handleChange },
                                    model: {
                                      value: _vm.form.moistureRegain,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "moistureRegain",
                                          $$v
                                        )
                                      },
                                      expression: "form.moistureRegain"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 14 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "运转效率%",
                                    prop: "efficiencyPercent"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      min: 1,
                                      max: 10000,
                                      label: "描述文字"
                                    },
                                    on: { change: _vm.handleChange },
                                    model: {
                                      value: _vm.form.efficiencyPercent,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "efficiencyPercent",
                                          $$v
                                        )
                                      },
                                      expression: "form.efficiencyPercent"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-collapse-item",
                { attrs: { title: "工艺项目信息", name: "3" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { "header-align": "center", data: _vm.tableData }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", label: "序号", width: "80" }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "specParamName",
                          label: "工艺项目",
                          width: "100"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "value",
                          "header-align": "center",
                          width: "200",
                          label: "工艺项目值"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.inputType == 1
                                  ? _c("el-input-number", {
                                      attrs: {
                                        min: 1,
                                        max: 10000,
                                        label: "描述文字"
                                      },
                                      model: {
                                        value: scope.row.value,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "value", $$v)
                                        },
                                        expression: "scope.row.value"
                                      }
                                    })
                                  : _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: scope.row.value,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "value", $$v)
                                          },
                                          expression: "scope.row.value"
                                        }
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: scope.row.specParamValue,
                                            value: scope.row.specParamValue
                                          }
                                        })
                                      ],
                                      1
                                    )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "address",
                          width: "200",
                          label: "上限值"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.inputType == 1
                                  ? _c("el-input-number", {
                                      attrs: {
                                        min: 1,
                                        max: 10000,
                                        label: "上限值"
                                      },
                                      on: { change: _vm.handleChange },
                                      model: {
                                        value: scope.row.maxValue,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "maxValue", $$v)
                                        },
                                        expression: "scope.row.maxValue"
                                      }
                                    })
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "address",
                          width: "200",
                          label: "下限值"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.inputType == 1
                                  ? _c("el-input-number", {
                                      attrs: {
                                        min: 1,
                                        max: 10000,
                                        label: "下限值"
                                      },
                                      on: { change: _vm.handleChange },
                                      model: {
                                        value: scope.row.minValue,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "minValue", $$v)
                                        },
                                        expression: "scope.row.minValue"
                                      }
                                    })
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "paramType",
                          formatter: _vm.formatParamType,
                          label: "项目类型"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.paramType == 1
                                  ? _c("p", [_vm._v(" 设备工艺 ")])
                                  : _c("p", [_vm._v(" 运转工艺 ")])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "isNull", label: "是否允许为空" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.isNull == 1
                                  ? _c("p", [_vm._v(" 是 ")])
                                  : _c("p", [_vm._v(" 否 ")])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "dataType", label: "数据类型" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.dataType == 1
                                  ? _c("p", [_vm._v(" 数值型 ")])
                                  : _c("p", [_vm._v(" 字符型 ")])
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }