<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{ this.formData.sign=='add'? $t('SupplierSectionAdd'):$t('SupplierSectionModify') }}</div>
            </div>
            <template>
                <div class="Info">
                    <el-form :model="formData" ref="from" label-position="right" v-bind:show-message="notip">
                        <el-row>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item :label="$t('Code')" prop="codeVal" label-width="140px" :required="true" :rules="formData.fromaDataRules.codeVal">
                                    <el-input v-model="formData.codeVal" style="width: calc(100% - 115px);" maxlength="64" clearable="true"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item :label="$t('Name')" prop="nameVal" label-width="140px" :required="true" :rules="formData.fromaDataRules.nameVal">
                                    <el-input v-model="formData.nameVal" style="width: calc(100% - 115px);" maxlength="64" clearable="true"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item :label="$t('LOSSRATEMIN')" prop="lossRateMinVal" label-width="140px" :required="true" :rules="formData.fromaDataRules.lossRateMinVal">
                                    <el-input-number v-model="formData.lossRateMinVal" style="width: calc(100% - 115px);" :controls="false" clearable :min="0"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item :label="$t('LOSSRATEMAX')" prop="lossRateMaxVal" label-width="140px" :required="true" :rules="formData.fromaDataRules.lossRateMaxVal">
                                    <el-input-number v-model="formData.lossRateMaxVal" style="width: calc(100% - 115px);" :controls="false" clearable :min="0"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item :label="$t('UNITGROUPS')" prop="unitGroupsVal" label-width="140px" :required="true" :rules="formData.fromaDataRules.unitGroupsVal">
                                    <el-select v-model="formData.unitGroupsVal" filterable placeholder="" style="width: calc(100% - 115px);" clearable="true">
                                        <el-option v-for="item in this.groupList"
                                                   :key="item"
                                                   :label="item"
                                                   :value="item">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item :label="$t('SORT')" prop="sortVal" label-width="140px">
                                    <el-input-number v-model="formData.sortVal" style="width: calc(100% - 115px);"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item :label="$t('isLastStep')" prop="isLastStepVal" label-width="140px" :rules="formData.fromaDataRules.isLastStepVal">
                                    <el-switch v-model="formData.isLastStepVal"></el-switch>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item :label="$t('Status')" prop="statusVal" label-width="140px">
                                    <el-switch v-model="formData.statusVal"></el-switch>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item :label="$t('VERSION NUMBER')" label-width="140px"  :required="true" :rules="formData.fromaDataRules.versionVal">
                                    <el-input v-model="formData.versionVal" style="width: calc(100% - 115px);" maxlength="36" clearable="true"></el-input>
                                    <el-button @click="randomGeneration()" :title="$t('Random Generation')"  type="primary" size="small" icon="el-icon-sunrise" circle></el-button>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-tooltip class="item" effect="dark" :content="$t('DEFAULT FIBER CLASSIFICATION')" placement="top-start">
                                    <el-form-item :label="$t('DEFAULT FIBER CLASSIFICATION')" label-width="140px">
                                        <el-input v-model="formData.classMainRawMaterialsVal" style="width: calc(100% - 115px);" maxlength="255" clearable="true"></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                        </el-row>
                        <div class="smallTit">{{$t('Process')}}</div>
                        <el-table :data="formData.tableData" :row-class-name="tableRowClassName"
                                  @current-change="handleCurrentChange" :header-cell-class-name="starAdd" @row-click="rowClick" row-key="getRowKey() ">
                            <el-table-column v-if="formData.show">
                                <span>{{scope.row.Id}}</span>
                                <span>{{scope.row.Sort}}</span>
                            </el-table-column>
                            <el-table-column :label="$t('Index')" type="index" width="50px">
                            </el-table-column>
                            <el-table-column :label="$t('SORT')" align='center'>
                                <template slot-scope="scope">
                                    <el-button icon="el-icon-top" type="text" size="medium" :title="$t('Moveup')" @click="Moveup(scope.$index)"></el-button>
                                    <el-button icon="el-icon-bottom" type="text" size="medium" :title="$t('Movedown')" @click="Movedown(scope.$index)"></el-button>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('CODE')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.Code}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.Code'" :rules="formData.fromaDataRules.Code">
                                        <el-input v-if="scope.row.isEgdit" v-model="scope.row.Code"  maxlength="64" clearable="true"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('NAME')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.Name}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.Name'" :rules="formData.fromaDataRules.Name">
                                        <el-input v-if="scope.row.isEgdit" v-model="scope.row.Name" clearable maxlength="64" clearable="true"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('DOCUMENT')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.Files.length}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.Files'">
                                        <span v-if="scope.row.isEgdit">{{scope.row.Files.length}}</span>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('NUMBER')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.Fields.length}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.Fields'">
                                        <span v-if="scope.row.isEgdit">{{scope.row.Fields.length}}</span>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('FIBER TYPE')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.RawFiberTypeName}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.RawFiberTypeId'">
                                        <el-select v-if="scope.row.isEgdit" value-key="id" v-model="scope.row.RawFiberTypeId" filterable placeholder=" " v-on:change="rawFiberchange(scope.row)">
                                            <el-option v-for="item in rawFiberData"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('DECLARE TYPE')" align='center'>
                                <template slot-scope="scope">
                                    <el-switch v-if="!scope.row.isEgdit" v-model="scope.row.DeclareType"></el-switch>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.DeclareType'">
                                        <el-switch v-if="scope.row.isEgdit" v-model="scope.row.DeclareType"></el-switch>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('DECLARE NOT REQUIRED')" align='center'>
                                <template slot-scope="scope">
                                    <el-switch v-if="!scope.row.isEgdit" v-model="scope.row.NotRequiredDeclare"></el-switch>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.NotRequiredDeclare'">
                                        <el-switch v-if="scope.row.isEgdit" v-model="scope.row.NotRequiredDeclare"></el-switch>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('CAN INSIDE CITE')" align='center'>
                                <template slot-scope="scope">
                                    <el-switch v-if="!scope.row.isEgdit" v-model="scope.row.InsideCanCite"></el-switch>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.InsideCanCite'">
                                        <el-switch v-if="scope.row.isEgdit" v-model="scope.row.InsideCanCite"></el-switch>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('IS OUTBOUND')" align='center'>
                                <template slot-scope="scope">
                                    <el-switch v-if="!scope.row.isEgdit" v-model="scope.row.IsCrossBorder"></el-switch>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.IsCrossBorder'">
                                        <el-switch v-if="scope.row.isEgdit" v-model="scope.row.IsCrossBorder"></el-switch>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('Enable')" align='center'>
                                <template slot-scope="scope">
                                    <el-switch v-if="!scope.row.isEgdit" v-model="scope.row.Status"></el-switch>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.Status'">
                                        <el-switch v-if="scope.row.isEgdit" v-model="scope.row.Status"></el-switch>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('ALLOW MULTIPLE')" align='center'>
                                <template slot-scope="scope">
                                    <el-switch v-if="!scope.row.isEgdit" v-model="scope.row.Allowmultiple"></el-switch>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.Allowmultiple'">
                                        <el-switch v-if="scope.row.isEgdit" v-model="scope.row.Allowmultiple"></el-switch>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('OPERATION')" align='center'>
                                <template slot-scope="scope">
                                    <el-button @click="setIpu(scope.row)" :title="$t('Set Data')" size="small" icon="el-icon-setting" circle></el-button>
                                    <el-button @click="deleteItem(scope.$index,scope.row)" :title="$t('Delete')" type="danger" size="small" icon="el-icon-delete" circle></el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="text-align:right;padding:50px 0;">
                            <el-button type="primary" @click="submitForm">{{$t('Save')}}</el-button>
                            <el-button @click="resetForm">{{$t('CANCEL')}}</el-button>
                        </div>
                    </el-form>
                </div>
            </template>
            <el-dialog class="addDia" :title="$t('Define process declaration data')"
                       :visible.sync="centerDialogVisible"
                       height="60%"
                       width="50%"
                       font-size="20px"
                       :show-close="false"
                       center>
                <el-divider></el-divider>
                <div class="formD upDiv">
                    <div class="smallTit">{{$t('Input Fields')}}</div>
                    <el-checkbox-group v-model="FieldList" style="margin:0;display:flex;justify-content:flex-start;flex-wrap:wrap;">
                        <el-checkbox v-for="(item) in this.SupportFieldData" :label="item.value" :key="item.value" style="width:50%;margin:0;display: flex;align-items: center;">{{item.label}}</el-checkbox>
                    </el-checkbox-group>
                </div>
                <el-divider></el-divider>
                <div class="formD upDiv">
                    <div class="smallTit">{{$t('Upload Files')}}</div>
                    <el-checkbox-group v-model="FileList" style="margin:0;display:flex;justify-content:flex-start;flex-wrap:wrap;">
                        <el-checkbox v-for="(item) in this.SupportFileData" :label="item.value" :key="item.value" style="width:50%;margin:0;display: flex;align-items: center;">{{item.label}}</el-checkbox>
                    </el-checkbox-group>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click.native="savaVal($event)">{{$t('Save')}}</el-button>
                    <el-button plain @click="centerDialogVisible = false">{{$t('Cancel')}}</el-button>
                </span>
            </el-dialog>
        </basic-container>
    </div>
</template>

<script>
    import { SectionProcessList, getVendorSectionInfo, addVendorPro, editVendorPro, addPro, editPro, delPro } from "@/api/supplierAdd";
    import { TracingDropDownList, TracingUnifiedIsBool, GetBusinessNoIn } from "@/api/unified";
    import {ShowGroupName } from "@/api/math"
    import Sortable from 'sortablejs';
    export default {
        name: 'Info',
        data() {
            var requiredcodeVal = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    this.notiplist.push(this.$t('CODE') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var requirednameVal = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    this.notiplist.push(this.$t('Name') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var requiredCode = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    let indexRule = rule.fullField.split(".")
                    this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('CODE') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var requiredName = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    let indexRule = rule.fullField.split(".")
                    this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('Name') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var validateNameExist = (rule, value, callback) => {
                if ((value != '' || value != null)) {
                    TracingUnifiedIsBool("SectionNameExist", this.formData.loadId, value).then(resu => {
                        var IsExist = resu.data;
                        if (!IsExist) {
                            this.notiplist.push(this.$t('Name') + ':' + this.$t('NameIsExist'));
                            callback(new Error(this.$t('NameIsExist')));
                        } else { callback(); }
                    }).catch(() => { callback(); });
                } else {
                    callback();
                }
            };
            var validateCodeExist = (rule, value, callback) => {
                if ((value != '' || value != null)) {
                    TracingUnifiedIsBool("SectionCodeExist", this.formData.loadId, value).then(resu => {
                        var IsExist = resu.data;
                        if (!IsExist) {
                            this.notiplist.push(this.$t('CODE') + ':' + this.$t('CodeIsExist'));
                            callback(new Error(this.$t('CodeIsExist')));
                        } else { callback(); }
                    }).catch(() => { callback(); });
                } else {
                    callback();
                }
            };
            var valiLossRate = (rule, value, callback) => {
                if (Number(this.formData.lossRateMaxVal) < Number(this.formData.lossRateMinVal)) {
                    this.notiplist.push(this.$t('valiLossRate'));
                    callback(new Error(this.$t('valiLossRate')));
                } else {
                    callback();
                }
            };
            var requiredLossRateMin = (rule, value, callback) => {
                if (Number(value) >= 0) {
                    callback();
                } else {
                    this.notiplist.push(this.$t('LOSSRATEMIN') + ':' + this.$t('NeedGreater0'));
                    callback(new Error(this.$t('NeedGreater0')));
                }
            };
            var requiredLossRateMax = (rule, value, callback) => {
                if (Number(value) > 0) {
                    callback();
                } else {
                    this.notiplist.push(this.$t('LOSSRATEMAX') + ':' + this.$t('NeedGreater0'));
                    callback(new Error(this.$t('NeedGreater0')));
                }
            };
            var requiredUnitGroupsVal = (rule, value, callback) => {
                if ((value == '' || value == undefined || value == null) && !this.formData.isLastStepVal) {
                    this.notiplist.push(this.$t('UNITGROUPS') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var requiredversionVal = (rule, value, callback) => {
                if ((value == '' || value == undefined || value == null) && !this.formData.isLastStepVal) {
                    this.notiplist.push(this.$t('VERSION NUMBER') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var validisLastStep = (rule, value, callback) => {
                if (value) {
                    var ids = this.formData.loadId;
                    if (ids == undefined) {
                        ids = "";
                    }
                    TracingUnifiedIsBool("ExistOnlyLastSection", ids, value).then(resu => {
                        var IsExist = resu.data;
                        if (!IsExist) {
                            this.notiplist.push(this.$t('isOnlyLastStep'));
                            callback(new Error(this.$t('isOnlyLastStep')));
                        } else { callback(); }
                    }).catch((erro) => { callback(); });
                } else {
                    callback();
                }
            };
            return {
                DeclareTypeData: [
                    {
                        label: this.$t('PO'),
                        value: 0
                    }, {
                        label: this.$t('SO'),
                        value: 1
                    }

                ],
                notip: true, notipInfo: "", notiplist: [], notificationPromise: Promise.resolve(),
                FieldList: [],
                FileList: [],
                groupList: [],
                rawFiberData: [],
                SupportFieldData: [],
                SupportFileData: [],
                centerDialogVisible: false,
                lineCur: 0,//当前选中行
                formData: {
                    show: false, sign: '', isCanKey: false,
                    loadId: '',
                    codeVal: '', nameVal: '', statusVal: true, isLastStepVal: false, sortVal: 0, versionVal: "", classMainRawMaterialsVal:"",
                    lossRateMinVal: '', lossRateMaxVal: 0, unitGroupsVal:"",
                    //隐藏id  
                    tableData: [{
                        Id: '', Code: '', Name: '', Files: [], Fields: [], Status: true, Allowmultiple: true, Remark: '', Sort: 1.1,
                        DeclareType: false, RawFiberTypeName: '', RawFiberTypeId: '', NotRequiredDeclare: false, InsideCanCite: false, IsCrossBorder:false, isEgdit: true }],
                    fromaDataRules: {
                        codeVal: [{ validator: requiredcodeVal, trigger: 'blur' },
                                 { validator: validateCodeExist, trigger: 'blur'}],
                        nameVal: [{ validator: requirednameVal, trigger: 'blur' },
                                  { validator: validateNameExist, trigger: 'blur' }],
                        lossRateMinVal: [{ type: "number", validator: requiredLossRateMin, trigger: 'blur' }],
                        lossRateMaxVal: [{ type: "number", validator: requiredLossRateMax, trigger: 'change' }, { type: "number", validator: valiLossRate, trigger: 'change' }],
                        unitGroupsVal: [{ validator: requiredUnitGroupsVal, trigger: 'blur' }],
                        versionVal: [{ validator: requiredversionVal, trigger: 'blur' }],
                        isLastStepVal: [{ validator: validisLastStep, trigger: 'change' }],
                        Code: [{ validator: requiredCode, trigger: 'blur' }],
                        Name: [{ validator: requiredName, trigger: 'blur' }],
                    }
                }
            }
        },
        watch: {
            $route() {
                this.init()
            }
        },// watch的作用可以监控一个值的变换,并调用因为变化需要执行的方法。 //监听路由是否发生变化
        created() {

            this.init()// 在加载的时候执行了这个方法，可以调用这个根据ID来插叙的方法
        },//加载
        methods: {//加载数据
            init() {
                this.formData.sign = this.$route.query.sign;
                this.formData.loadId = this.$route.query.ID;
                TracingDropDownList('SupportFieldList').then(res => {
                    this.SupportFieldData = res.data;
                });
                TracingDropDownList('SupportFileList').then(res => {
                    this.SupportFileData = res.data;
                });
                ShowGroupName().then(res => {
                    this.groupList = res.data.data;
                });
                TracingDropDownList('RawFiberType').then(res => {
                    this.rawFiberData = res.data;
                });
                if (this.formData.sign == 'edit') {
                    this.topInit();
                    this.getInfoList();
                }
            },
            topInit() {
                getVendorSectionInfo(this.formData.loadId).then(res => {
                    var o = res.data;
                    this.formData.codeVal = o.code;
                    this.formData.nameVal = o.name;
                    this.formData.statusVal = o.status;
                    this.formData.isLastStepVal = o.isLastStep;
                    this.formData.sortVal = o.sort;
                    this.formData.lossRateMinVal = o.lossRateMin;
                    this.formData.lossRateMaxVal = o.lossRateMax;
                    this.formData.unitGroupsVal = o.unitGroups;
                    this.formData.classMainRawMaterialsVal = o.classMainRawMaterials;
                })
            },//删除数据
            deleteItem(index, row) {
                this.$confirm(this.$t('IsDel') + row.Code, this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                    if (row.Id == '') {
                        this.formData.tableData.splice(index, 1);
                    }
                    else {
                        delPro(row.Id).then(() => {
                            //待删除
                            this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                            this.formData.tableData.splice(index, 1);
                        });
                    }
                })
            },//点击行变化
            handleCurrentChange(currentRow, oldCurrentRow) {
                if (currentRow.index == this.formData.tableData.length - 1) {
                    this.addrow();
                }
                this.$set(currentRow, 'isEgdit', true);
                this.$set(oldCurrentRow, 'isEgdit', false);
                this.lineCur = currentRow.index;
            },//提交数据
            rowClick() {
                if (this.formData.tableData.length == 1) {
                    this.addrow();
                }
            },//行点击
            submitForm() {
                this.$refs["from"].clearValidate() // 移除校验结果
                for (var j = this.formData.tableData.length - 1; j > -1; j--) {
                    if (this.formData.tableData[j].Code.trim() == '' && this.formData.tableData[j].Name.trim() == '' && this.formData.tableData[j].Remark.trim() == '' ) {
                        this.formData.tableData.splice(j, 1);
                    }
                }
                if (this.formData.tableData.length == 0) {
                    this.addrow();
                    this.$message({
                        message: this.$t('No Data Submitted'),
                        type: "error"
                    });//提示无数据
                    return false;
                } else {
                    this.$nextTick(() => {//避免点击两次
                        this.notip = false;
                        this.notiplist = [];
                        let formName = "from";
                        this.$refs[formName].validate((valid) => {
                            if (valid) {
                                let headrow = {
                                    id: this.formData.loadId,
                                    code: this.formData.codeVal.trim(),
                                    name: this.formData.nameVal.trim(),
                                    status: this.formData.statusVal,
                                    isLastStep: this.formData.isLastStepVal,
                                    sort: this.formData.sortVal,
                                    LossRateMin: this.formData.lossRateMinVal,
                                    LossRateMax: this.formData.lossRateMaxVal,
                                    UnitGroups: this.formData.unitGroupsVal,
                                    remark: '',
                                    brandBusinessId: '',
                                    corporationId: '',
                                    createTime: '2021-05-01T08:00',
                                    createUserid: '',
                                    updateTime: '2021-05-01T08:00',
                                    updateUserid: '',
                                    classMainRawMaterials: this.formData.classMainRawMaterialsVal,
                                };
                                if (this.formData.loadId != '') {
                                    editVendorPro(headrow).then(() => {
                                        this.formData.tableData.forEach(v => {
                                            let paramrow = {
                                                id: v.Id,
                                                code: v.Code.trim(),
                                                name: v.Name.trim(),
                                                status: v.Status,
                                                allowmultiple: v.Allowmultiple,
                                                remark: v.Remark,
                                                lastAlterTime: '2021-05-01T08:00',
                                                vendorSectionId: this.formData.loadId,
                                                createTime: '2021-05-01T08:00',
                                                createUserid: '',
                                                brandBusinessId: '',
                                                sort: v.index,
                                                fields: v.Fields,
                                                files: v.Files,
                                                declareType: v.DeclareType,
                                                rawFiberTypeId: v.RawFiberTypeId,
                                                notRequiredDeclare: v.NotRequiredDeclare,
                                                insideCanCite: v.InsideCanCite,
                                                isCrossBorder: v.IsCrossBorder,
                                                version: this.formData.versionVal,
                                             
                                            };
                                            if (v.Id != '') {
                                                editPro(paramrow).then().catch((erro) => { console.log(erro) });
                                            }
                                            if (v.Id == '') {
                                                addPro(paramrow).then().catch((erro) => { console.log(erro) });
                                            }
                                        })
                                        this.$message({
                                            message: this.$t('OperationSuccess'),
                                            type: "success"
                                        });

                                        //返回列表
                                        this.$router.push({ path: '/pvh_setting/supplierList' });
                                    }).catch((erro) => { console.log(erro) });
                                }
                                if (this.formData.loadId == '') {
                                    addVendorPro(headrow).then(res => {
                                        this.formData.tableData.forEach(v => {
                                            let paramrow = {
                                                id: v.Id,
                                                code: v.Code.trim(),
                                                name: v.Name.trim(),
                                                status: v.Status,
                                                allowmultiple: v.Allowmultiple,
                                                remark: v.Remark,
                                                lastAlterTime: '2021-05-01T08:00',
                                                vendorSectionId: res.data.result,
                                                createTime: '2021-05-01T08:00',
                                                createUserid: '',
                                                brandBusinessId: '',
                                                sort: v.index,
                                                fields: v.Fields,
                                                files: v.Files,
                                                declareType: v.DeclareType,
                                                rawFiberTypeId: v.RawFiberTypeId,
                                                notRequiredDeclare: v.NotRequiredDeclare,
                                                insideCanCite: v.InsideCanCite,
                                                isCrossBorder: v.IsCrossBorder,
                                                version: this.formData.versionVal,
                                            };
                                            if (v.Id != '') {
                                                editPro(paramrow).then().catch((erro) => { console.log(erro) });
                                            }
                                            if (v.Id == '') {
                                                addPro(paramrow).then().catch((erro) => { console.log(erro) });
                                            }
                                        })
                                        this.$message({
                                            message: this.$t('OperationSuccess'),
                                            type: "success"
                                        });
                                        //返回列表
                                        this.$router.push({ path: '/pvh_setting/supplierList' });
                                    }).catch((erro) => { console.log(erro) });
                                }
                            } else {
                                this.notificationPromise = this.notificationPromise.then(this.$nextTick).then(() => {
                                    let newDatas = [];
                                    const h = this.$createElement;
                                    for (let i in this.notiplist) {
                                        newDatas.push(h('p', null, this.notiplist[i]));
                                    }
                                    this.$notify.error({
                                        title: this.$t('OperationFailed'),
                                        offset: 100,
                                        showClose: true,
                                        duration: 0,
                                        message: h('div', null, newDatas),
                                        customClass: 'notifyStyle',
                                    });
                                });
                                return false;
                            }
                        });
                    });
                }
            },//保存按钮
            resetForm() {
                this.$router.push({ path: '/pvh_setting/supplierList' });
            },//取消按钮
            starAdd(obj) {
                if (obj.columnIndex === 2 || obj.columnIndex === 3) {
                    return 'star';
                }
            },//列头加星号
            tableRowClassName({ row, rowIndex }) {
                // 把每一行的索引放进row
                row.index = rowIndex
            },//加index
            addrow() {
                let item = {
                    Id: '', Code: '', Name: '', Files: [], Fields: [], Status: true, Allowmultiple: true, Remark: '', Sort: 1.1,
                    DeclareType: false, RawFiberTypeName: '', RawFiberTypeId: '', NotRequiredDeclare: false, InsideCanCite: false, IsCrossBorder: false, isEgdit: false
                }
                this.formData.tableData.push(item);      // 把每一行的索引放进row
            },//新增行
            getInfoList() {
                SectionProcessList(this.formData.loadId).then(res => {
                    this.formData.tableData = res.data;
                    if (this.formData.tableData.length == 0) { this.addrow(); }
                    else {
                        this.formData.versionVal = this.formData.tableData[0].Version;
                    }
                }).catch((erro) => { console.log(erro) });
            }, //加载数据
            setIpu(row) {
                this.centerDialogVisible = true;//弹框显示为true
                this.lineCur = row.index;
                if (row.Files.length > 0) {
                    this.FileList = row.Files;
                } else
                {
                    this.FileList = [];
                }
                if (row.Fields.length > 0) {
                    this.FieldList = row.Fields;
                } else {
                    this.FieldList = [];
                }
            },
            savaVal() {
                this.centerDialogVisible = false;
                if (this.FileList.length > 0) {
                    this.formData.tableData[this.lineCur].Files = this.FileList;
                } else {
                    this.formData.tableData[this.lineCur].Files = [];
                }
                if (this.FieldList.length > 0) {
                    this.formData.tableData[this.lineCur].Fields = this.FieldList;
                } else {
                    this.formData.tableData[this.lineCur].Fields = [];
                }
            },
            Moveup(index) {
                if (index > 0) {
                    const upDate = this.formData.tableData[index - 1]
                    this.formData.tableData.splice(index - 1, 1)
                    this.formData.tableData.splice(index, 0, upDate)
                } else {
                    this.$message({
                        message: this.$t('cantMoveup'),
                        type: "warning"
                    });//提示无数据
                }
            },//上移
            Movedown(index) {
                if ((index + 1) === this.formData.tableData.length) {
                    this.$message({
                        message: this.$t('cantMovedown'),
                        type: "warning"
                    });//提示无数据
                } else {
                    const downDate = this.formData.tableData[index + 1]
                    this.formData.tableData.splice(index + 1, 1)
                    this.formData.tableData.splice(index, 0, downDate)
                }
            },//下移
            //行拖拽
            rowDrop() {
                const tbody = document.querySelector('.el-table__body-wrapper tbody')
                const _this = this
                Sortable.create(tbody, {
                    onEnd({ newIndex, oldIndex }) {
                        const currRow = _this.formData.tableData.splice(oldIndex, 1)[0]
                        _this.formData.tableData.splice(newIndex, 0, currRow)
                    }
                })
            },
            getRowKey() {
                return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = Math.random() * 16 | 0,
                        v = c == 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
            },
            rawFiberchange(row) {
                var objrow1 = {};
                objrow1 = this.rawFiberData.find((i) => {//这里的List就是上面遍历的数据源
                    return i.value === row.RawFiberTypeId;//筛选出匹配数据
                });
                row.RawFiberTypeName = objrow1.label;
            },
            randomGeneration() {
                GetBusinessNoIn("PVH_Process", " ").then(res => {
                    this.formData.versionVal = res.data + "_" + this.formData.nameVal;
                })
            },

        },
        mounted() {
          //  this.rowDrop()  //20210714取消拖拽功能
        },
    }
</script>


<style scoped>
    .notifyStyle {
        width: 30% !important;
        background: #FFE4E1;
    }

    .el-dialog__wrapper {
        transition-duration: 0.3s;
    }
    /*弹出框上*/
    .addDia.el-dialog.el-dialog--center {
        margin-top: 9vh;
    }

    .ttt {
        width: 100px;
    }
    /*多选框*/
    el-checkbox__inner {
        border: .0625rem solid #409eff;
    }

    .searlable .el-checkbox__label {
        color: #8CA0B3;
        font-weight: 500;
    }

    .addDia .el-dialog__body {
        padding: 0
    }

    .addDia .smallTit {
        margin-top: 15px;
        font-size: 1.5rem;
    }

    .addDia .el-dialog__header {
        padding-bottom: 0;
    }

    .addDia.Title {
        font-size: 1.5rem;
    }

    .formD {
        padding: 0 40px;
    }

    .addDia .el-checkbox-group {
        height: 12.5rem;
        overflow-x: auto;
        vertical-align: text-bottom;
        margin-left: 6rem;
        margin-right: 4rem;
    }

    .addDia .el-checkbox__label {
        display: inline-block;
        padding-left: 2rem;
        line-height: 2.5rem;
        font-size: 1.2rem;
        padding-right: 3rem;
    }

    .upDiv .el-checkbox__label {
        padding: 0 20px 0 5px;
        width: calc(100% - 14px);
        box-sizing: border-box;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .notifyStyle {
        width: 30% !important;
        background: #FFE4E1;
    }

    table th.star div::before {
        content: ' * ';
        color: red;
    }

    .ttt {
        width: 100px;
    }

    .searlable .el-checkbox__label {
        color: #8CA0B3;
        font-weight: 500;
    }

    .addDia .el-dialog__body {
        padding: 0
    }

    .addDia .smallTit {
        margin-top: 15px;
    }

    .addDia .el-dialog__header {
        padding-bottom: 0;
    }

    .formD {
        padding: 0 40px;
    }
</style>