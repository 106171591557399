var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c("el-tab-pane", {
                attrs: { label: _vm.$t("PRODUCT TRACING"), name: "first" }
              }),
              _c("el-tab-pane", {
                attrs: { label: _vm.$t("PRODUCT TREE TRACING"), name: "second" }
              })
            ],
            1
          ),
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "10px" }
              },
              [_vm._v(_vm._s(_vm.$t("PRODUCT TREE TRACING")))]
            )
          ]),
          [_c("div", { attrs: { id: "chartTree" } })]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }