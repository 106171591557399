<template>
  <div>
    <basic-container>
    <avue-crud :option="tableOption"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :page="page"
                 ref="crud"
                 @current-change="currentChange"
                 @size-change="sizeChange"
                 @selection-change="selectionChange"
                 @refresh-change="refreshChange"
                 @row-save="handleSave"
                 @row-update="handleUpdate"
                 @row-del="handleDel">
            <template slot="status" slot-scope="scope">
                <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                @change="change(scope.row)">
                </el-switch>
            </template>
      <template slot="menuLeft">
        <importBtn
            :outApi="this.proApis.DEPARTMENTEXPORT"
            :innerAPI="this.proApis.DEPARTMENTIMPORT"
            :API="this.proApis.DEPARTMENTIMPORTTEMPLATE"
            @refresh="refreshData"
        ></importBtn>
      </template>
        <template slot="menuRight">
            <el-select v-model="status"  :placeholder="$t('STATE')"  size="small" style="width:100px !important;marginRight: 5px;">
                <el-option :label="$t('NORMAL')" value="0">{{$t('NORMAL')}}</el-option>
                <el-option :label="$t('DISABLE')" value="1">{{$t('DISABLE')}}</el-option>
            </el-select>
            <el-input v-model="nameOrIdLike" :placeholder="$t('WORKSHOP NAME OR CODE')" style="display:inlie-block;width:200px;marginRight: 5px;" size="small"></el-input>
        </template>
        <template slot="menu" scope="scope">
            <div v-if="scope.row.status==0" style="height:30px;"/>
            <!-- <el-button type="text" size="mini" icon="el-icon-discover" style="color:red;">禁用</el-button> -->
        </template>
    </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {list,changeStatus,add,edit,remove} from "@/api/workp"
    import importBtn from "@/components/importExport/importBtn";
    import { getPerson } from "@/api/config";
export default {
  components:{
    importBtn
  },
    data() {
        return {
            tableLoading: false,
            nameOrIdLike: "",
            status: "0",
            page: {
                pageSize: 20,
                pagerCount: 5,
                total: 0,
                pageCurrent: 1
            },
            disabled: true,
            tableData: [],
            tableOption: {
                delBtn: true,
                menuWidth: 200,
                selection: true,
                tip: false,
                columnBtn: false,
                dialogFullscreen: true,
                addBtnText: this.$t('ADD'),
                delBtnText: this.$t('DELETE'),
                editBtnText: this.$t('Edit'),
                emptyText: this.$t('No Data'),
                menuTitle: this.$t('OPERATION'),
                column: [{
                    label: this.$t('NAME'),
                    prop: "name",
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER A NAME'),
                        trigger: "blur"
                    }]
                }, {
                    label: this.$t('CODE'),
                    width: 230,
                    hide: true,
                    display: false,
                    editDisplay: false,
                    prop: "id",
                    rules: {
                        required: true,
                        message: this.$t('PLEASE ENTER THE CODE'),
                        trigger: "blur"
                    }
                }, {
                    label: this.$t('PERSON IN CHARGE'),
                    prop: "leaderUserName",
                    type: 'select',
                    filterable: true,
                    dicData: [],
                    props: {
                        label: "name",
                        value: "name"
                    },
                }, {
                    label: this.$t('STATUS'),
                    prop: "status",
                    type: "switch",
                    slot: true,
                    dicData: [{
                        label: this.$t('ENABLE'),
                        value: 0
                    }, {
                        label: this.$t('DISABLE'),
                        value: 1
                    }]
                }]
            }
        }
    },
        created() {
            getPerson().then(res => {
                this.tableOption.column[2].dicData = res.data.data;
            });
      this.list();
    },
    methods: {
        currentChange(pageCurrent) {
          this.page.pageCurrent = pageCurrent;
          this.list();
        },
        sizeChange(pageSize) {
          this.page.pageCurrent = 1;
          this.page.pageSize = pageSize;
          this.list();
        },
        refreshData(){
          this.list()
        },
        // 操作栏的显示隐藏
        // getPermission(key, row, index) {
        //   if (key==='delBtn' && row.status === 0 ) {
        //     return false;
        //   } else if (key==='editBtn' && row.status === 0 ){
        //     return false;
        //   }
        //   return true;
        // },
        handleDel(row) {
            this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('Cancel'),
                type: 'warning'
            }).then(() => {
                remove(row.id).then(res => {
                    // if(res.data.code == "BUSINESS.0005") {
                    //     this.$message({
                    //         type: "error",
                    //         message: this.$t('FAILED TO DELETE. PLEASE ENSURE THAT THE SUBORDINATE ORGANIZATION HAS BEEN DELETED FIRST')
                    //     });
                    // } else {
                    //     this.$message({
                    //         type: "success",
                    //         message: this.$t('DELETE SUCCEEDED, REFRESH DATA')
                    //     });
                    // }
                    this.list();
                })
            })
        },
        handleUpdate(row,index,done) {
            edit(row).then(() => {
                this.list();
                done();
            })
        },
        handleSave(row, done) {
            console.log(row)
            add(row).then(() => {
                this.list();
                done();
            })
        },
        change(row) {
            changeStatus(row).then(() => {
                this.$message({
                    message: this.$t('UpdateSuccess'),
                    type: "success"
                });
            });
        },
        selectionChange(list) {
            console.log(list);
            list != "" ? this.disabled = false : this.disabled = true;
        },
        refreshChange() {
          this.list();
        },
        list(){
          list(this.page.pageCurrent,this.page.pageSize,this.nameOrIdLike,this.status).then(res => {
              this.page.total = res.data.data.total;
              this.tableData = res.data.data.items;
              console.log('this.tableData',this.tableData);
              this.tableData.forEach(v => {
                  v.status = v.status;
              })
          })
        },
        envText: function() {
            return this.env
        }
  }
}
</script>
<style scoped>
    .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        padding-left: 10px;
    }
</style>