var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c(
            "el-container",
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: _vm.$t("DownloadPDF"), name: "first" }
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: _vm.$t("PDFLog"), name: "second" }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "layTop" }, [
                _c("div", { staticClass: "Title" }, [
                  _vm._v(_vm._s(_vm.$t("DownloadPDF")))
                ])
              ]),
              _c("el-main", { staticStyle: { overflow: "hidden" } }, [
                _c(
                  "div",
                  { staticClass: "topInfoReview" },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-bottom": "20px"
                            },
                            attrs: { sm: 24, md: 12, lg: 8 }
                          },
                          [
                            _c("span", { staticClass: "spanRight" }, [
                              _c("i", { staticClass: "el-icon-s-custom" }),
                              _vm._v(
                                _vm._s(_vm.$t("Supplier")) +
                                  " : " +
                                  _vm._s(_vm.formData.supplierVal)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-bottom": "20px"
                            },
                            attrs: { sm: 24, md: 12, lg: 8 }
                          },
                          [
                            _c("span", { staticClass: "spanRight" }, [
                              _c("i", { staticClass: "iconfont icon-rili" }),
                              _vm._v(
                                _vm._s(_vm.$t("At")) +
                                  " : " +
                                  _vm._s(_vm.formData.OrderDateVal)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-bottom": "20px"
                            },
                            attrs: { sm: 24, md: 12, lg: 8 }
                          },
                          [
                            _c("span", [
                              _c("i", { staticClass: "el-icon-attract" }),
                              _vm._v(
                                _vm._s(_vm.$t("BATCH")) +
                                  " : " +
                                  _vm._s(_vm.formData.BatchVal)
                              )
                            ]),
                            _c("span", { staticClass: "spanLeft" })
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-bottom": "20px"
                            },
                            attrs: { sm: 24, md: 12, lg: 8 }
                          },
                          [
                            _c("span", { staticClass: "spanRight" }, [
                              _c("i", { staticClass: "el-icon-document" }),
                              _vm._v(
                                _vm._s(_vm.$t("PO")) +
                                  " : " +
                                  _vm._s(_vm.formData.poVal)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-bottom": "20px"
                            },
                            attrs: { sm: 24, md: 12, lg: 8 }
                          },
                          [
                            _c("span", [
                              _c("i", { staticClass: "el-icon-document" }),
                              _vm._v(
                                _vm._s(
                                  _vm.$t("BUSINESS SERIAL NUMBER INTERNAL")
                                ) +
                                  " : " +
                                  _vm._s(_vm.formData.BusinessNoInVal)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-bottom": "20px"
                            },
                            attrs: { sm: 24, md: 12, lg: 8 }
                          },
                          [
                            _c("span", { staticClass: "spanRight" }, [
                              _c("i", { staticClass: "el-icon-document" }),
                              _vm._v(
                                _vm._s(
                                  _vm.$t("BUSINESS SERIAL NUMBER EXTERNAL")
                                ) +
                                  " : " +
                                  _vm._s(_vm.formData.BusinessNoOutVal)
                              )
                            ]),
                            _c("span", { staticClass: "spanLeft" })
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-bottom": "20px"
                            },
                            attrs: { sm: 24, md: 12, lg: 8 }
                          },
                          [
                            _c("span", { staticClass: "spanRight" }, [
                              _c("i", { staticClass: "el-icon-coin" }),
                              _vm._v(
                                _vm._s(_vm.$t("Product Name")) +
                                  " : " +
                                  _vm._s(_vm.formData.ProductNameVal)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-bottom": "20px"
                            },
                            attrs: { sm: 24, md: 12, lg: 8 }
                          },
                          [
                            _c("span", { staticClass: "spanRight" }, [
                              _c("i", { staticClass: "el-icon-brush" }),
                              _vm._v(
                                _vm._s(_vm.$t("COLOR")) +
                                  " : " +
                                  _vm._s(_vm.formData.ColorVal)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-bottom": "20px"
                            },
                            attrs: { sm: 24, md: 12, lg: 8 }
                          },
                          [
                            _c("span", { staticClass: "spanRight" }, [
                              _c("i", {
                                staticClass: "el-icon-shopping-cart-full"
                              }),
                              _vm._v(
                                _vm._s(_vm.$t("QUANTITY")) +
                                  " : " +
                                  _vm._s(_vm.formData.NumberVal) +
                                  "/" +
                                  _vm._s(_vm.formData.UnitNameVal)
                              )
                            ]),
                            _c("span", { staticClass: "spanLeft" })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "el-footer",
                {
                  staticStyle: {
                    height: "850px !important",
                    background: "#eee",
                    padding: "inherit",
                    "margin-top": "20px"
                  }
                },
                [
                  _c(
                    "el-row",
                    { staticStyle: { background: "#ffffff" } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            "margin-bottom": "0.7rem !important",
                            "text-align": "left"
                          },
                          attrs: { xs: 24, sm: 24, md: 24, lg: 18, xl: 18 }
                        },
                        [
                          _vm.isNeedRegenerate
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: { effect: "dark", placement: "bottom" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.$t("Need to regenerate pdf")
                                          ) +
                                          "\n                            "
                                      )
                                    ]
                                  ),
                                  _c("i", {
                                    staticClass: "el-icon-s-opportunity",
                                    staticStyle: {
                                      "margin-left": "3px",
                                      "font-size": "16px",
                                      COLOR: "RED!important"
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          _vm.isNeedRegenerate
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-left": "10px",
                                    COLOR: "RED!important"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "Please regenerate the PDF. The declaration data has been modified"
                                      )
                                    )
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "10px" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("Current PDF file generation time")
                                ) + " : "
                              )
                            ]
                          ),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "10px" } },
                            [_vm._v(_vm._s(_vm.transformDate(_vm.pdfDt)))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            "margin-bottom": "0.7rem !important",
                            "text-align": "right"
                          },
                          attrs: { xs: 24, sm: 24, md: 24, lg: 6, xl: 6 }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "warning", size: "small" },
                              on: { click: _vm.regenerate }
                            },
                            [_vm._v(_vm._s(_vm.$t("REGENERATE")))]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.downloadFile }
                            },
                            [_vm._v(_vm._s(_vm.$t("DOWNLOAD")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c("el-col", {
                        attrs: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }
                      }),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loadedRatio == 0,
                              expression: "loadedRatio == 0"
                            }
                          ],
                          attrs: { xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                height: "760px",
                                "overflow-y": "auto",
                                margin: "20px 10px"
                              }
                            },
                            _vm._l(_vm.pageNum, function(page) {
                              return _c("pdf", {
                                key: page,
                                staticStyle: { margin: "0 0 10px 0" },
                                attrs: { page: page, src: _vm.url },
                                on: {
                                  "page-loaded": function($event) {
                                    _vm.loadedRatio = $event
                                  }
                                }
                              })
                            }),
                            1
                          )
                        ]
                      ),
                      _c("el-col", {
                        attrs: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }