import request from '@/router/axios';
import {businessUrl} from "@/config/env"
export const list = (pageNo,pageSize,name,status) => {
    return request({
        url : businessUrl + "department/selectTreePageData",
        method: "post",
        data: {
            pageNo,
            pageSize,
            type: 0,
            name,
            status
        }
    })
}
//编辑
export const edit = (row) => {
    return request({
        url : businessUrl + "department/updateByPrimaryKeySelective",
        method: "put",
        data: {
            ...row
        }
    })
}
//删除
export const remove = (id) => {
    return request({
        url : businessUrl + "department/delete",
        method: "delete",
        params: {
            id
        }
    })
}
export const changeStatus = (row) => {
    return request({
        url : businessUrl + "department/updateStatus",
        method: "put",
        data : {
            id : row.id,
            status : Number(row.status)
        }
    })
}
export const add = (row) => {
    return request({
        url : businessUrl + "department/insert",
        method: "post",
        data : {
            type: 0,
            ...row
        }
    })
}
export const addnextClass = (row) => {
    return request({
        url : businessUrl + "department/insertJunior",
        method: "post",
        data : {
            type: 0,
            ...row
        }
    })
}