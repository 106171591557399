var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "volumeSet" },
    [
      _c(
        "el-dialog",
        {
          staticStyle: { marginTop: "-60px" },
          attrs: {
            visible: _vm.modelShow,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
            width: "800px"
          },
          on: {
            "update:visible": function($event) {
              _vm.modelShow = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.form, "label-width": "120px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "机台编号:" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.seletItem.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.seletItem, "name", $$v)
                                  },
                                  expression: "seletItem.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "品种工艺来源:" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { fill: "#67C23A" },
                                  model: {
                                    value: _vm.form.radio,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "radio", $$v)
                                    },
                                    expression: "form.radio"
                                  }
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "1" } },
                                    [_vm._v("工艺翻改单")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "2" } },
                                    [_vm._v("快捷创建")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.radio == 1,
                          expression: "form.radio == 1"
                        }
                      ]
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "table_content" },
                        [
                          _c(
                            "el-table",
                            {
                              ref: "multipleTable",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.tableData,
                                "tooltip-effect": "dark"
                              },
                              on: {
                                "selection-change": _vm.handleSelectionChange
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: { type: "selection", width: "55" }
                              }),
                              _c("el-table-column", {
                                attrs: { label: "创建时间", width: "220" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [_vm._v(_vm._s(row.createTime))]
                                    }
                                  }
                                ])
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "productName",
                                  label: "产品",
                                  width: "120"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "batchName",
                                  label: "批次",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "planYield", label: "产量" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(_vm._s(row.planYield) + "kg")
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "工艺翻改时间:" } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      disabled: "",
                                      type: "datetime",
                                      format: "yyyy-MM-dd HH:mm:ss",
                                      placeholder: "选择日期时间"
                                    },
                                    model: {
                                      value: _vm.form.createTime,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "createTime", $$v)
                                      },
                                      expression: "form.createTime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "预计生产品种:" } },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.form.productName,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "productName", $$v)
                                      },
                                      expression: "form.productName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "品种批次:" } },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.form.batchName,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "batchName", $$v)
                                      },
                                      expression: "form.batchName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "预计开台时间:" } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      disabled: "",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      type: "datetime",
                                      placeholder: "选择日期时间"
                                    },
                                    model: {
                                      value: _vm.form.planStartTime,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "planStartTime", $$v)
                                      },
                                      expression: "form.planStartTime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "预计了机时间:" } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      disabled: "",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      type: "datetime",
                                      placeholder: "选择日期时间"
                                    },
                                    model: {
                                      value: _vm.form.planEndTime,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "planEndTime", $$v)
                                      },
                                      expression: "form.planEndTime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "计划产量:" } },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: { placeholder: "", disabled: "" },
                                      model: {
                                        value: _vm.form.num,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "num", $$v)
                                        },
                                        expression: "form.num"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("kg")
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.radio == 2,
                          expression: "form.radio == 2"
                        }
                      ]
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "预计生产品种:" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      on: { change: _vm.getParam2 },
                                      model: {
                                        value: _vm.form.productId,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "productId", $$v)
                                        },
                                        expression: "form.productId"
                                      }
                                    },
                                    _vm._l(
                                      _vm.productionIsExpectedTo2,
                                      function(item) {
                                        return _c("el-option", {
                                          key: item.productId,
                                          attrs: {
                                            label: item.productName,
                                            value: item.productId
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.getSpec(item)
                                            }
                                          }
                                        })
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "品种批次:" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.form.batchCode,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "batchCode", $$v)
                                        },
                                        expression: "form.batchCode"
                                      }
                                    },
                                    _vm._l(_vm.options2, function(item) {
                                      return _c("el-option", {
                                        key: item.batchCode,
                                        attrs: {
                                          label: item.batchCode,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "计划产量:" } },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: { placeholder: "请输入内容" },
                                      model: {
                                        value: _vm.form.planYield,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "planYield", $$v)
                                        },
                                        expression: "form.planYield"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("kg")
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "p",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "-30px" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { type: "success" }, on: { click: _vm.makeSure } },
                [_vm._v("确认开台")]
              ),
              _c("el-button", { on: { click: _vm.closeModel } }, [
                _vm._v("取 消")
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }