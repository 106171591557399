<template>
    <div class="pvhFactory">
        <basic-container>
            <template>
                <div>
                    <el-form :rules="rules" :model="formData" ref="ruleform" class="demo-ruleForm" label-position="right" v-bind:show-message="notip">
                        <div class="layTop">
                            <div class="Title" style="margin-bottom:10px;">
                                {{$t('MATERIAL ORDER FOR PRODUCT')}}
                                <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('EditSOinformation')" @click="editSOinfo()"></el-button>
                            </div>
                        </div>
                        <div class="topInfo clearfix">
                            <el-row>
                                <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                    <span><i class="el-icon-s-custom"></i>{{$t('FROM')}} : {{formData.fromVal}}/{{formData.departVal}}</span>
                                </el-col>
                                <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                    <span class="spanRight"><i class="iconfont icon-rili"></i>{{$t('At')}} : {{formData.odateVal}}</span>
                                </el-col>
                                <el-col :sm="24" :md="12" :lg="4" style="display:flex;margin-bottom:20px;">
                                    <span class="spanRight"><i class="el-icon-brush"></i>{{$t('COLOR')}} : {{formData.colorVal}}</span>
                                </el-col>
                                <el-col :sm="24" :md="12" :lg="4" style="display:flex;margin-bottom:20px;">
                                    <span class="spanRight"><i class="el-icon-shopping-cart-full"></i>{{$t('Number')}} : {{formData.numberVal}}/{{formData.unitVal}}</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                    <span class="spanRight"><i class="el-icon-price-tag"></i>{{$t('POProductCode')}} : {{formData.codeVal}}</span>
                                </el-col>
                                <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                    <span class="spanRight"><i class="el-icon-coin"></i>{{$t('POProductName')}} : {{formData.outProductVal}}</span>
                                </el-col>

                                <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                    <span class="spanRight"><i class="el-icon-attract"></i>{{$t('POBATCH')}} : {{formData.batchVal}}</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                    <span class="spanRight"><i class="el-icon-document"></i>{{$t('SO')}} : {{formData.soVal}}</span>
                                </el-col>
                                <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                    <span class="spanRight"><i class="el-icon-coin"></i>{{$t('InternalProductName')}} : {{formData.productNameVal}}</span>
                                </el-col>
                                <el-col :sm="24" :md="12" :lg="4" style="display:flex;margin-bottom:20px;">
                                    <span class="spanRight"><i class="el-icon-map-location"></i>{{$t('MARKET')}} : {{formData.destinationCountryNameVal}}</span>
                                </el-col>
                                <el-col :sm="24" :md="12" :lg="4" style="display:flex;margin-bottom:20px;">
                                    <span class="spanRight"><i class="el-icon-chat-line-square"></i>{{$t('REMARK')}} : {{formData.remarkVal}}</span>
                                </el-col>

                            </el-row>
                            <el-row>
                                <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                    <span class="spanRight"><i class="el-icon-sort"></i>{{$t('CONTRACT SIGNING COMPANY')}} : {{formData.affCollNameVal}}</span>
                                </el-col>
                                <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                    <span class="spanRight"><i class="el-icon-s-ticket"></i>{{$t('ASSOCIATED PROCESSING PLANT')}} : {{formData.affWorkNameVal}}</span>
                                </el-col>
                                <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                    <span class="spanRight"><i class="el-icon-key"></i>{{$t('BUSINESS SERIAL NUMBER EXTERNAL')}} : {{formData.businessNoOutVal}}</span>
                                </el-col>
                            </el-row>
                        </div>
                        <div>
                            <div class="smallTit">{{$t('Material Information')}}</div>
                            <template>
                                <el-table :data="formData.tableData" :row-class-name="tableRowClassName"
                                          @current-change="handleCurrentChange" :header-cell-class-name="starAdd">
                                    <el-table-column :label="$t('Index')" type="index" width="50px">
                                    </el-table-column>
                                    <el-table-column v-if="formData.show">
                                        <span>{{scope.row.Id}}</span>
                                        <span>{{scope.row.PoDetailId}}</span>
                                        <span>{{scope.row.PoId}}</span>
                                    </el-table-column>
                                    <el-table-column :label="$t('ProductCode')" align='center'>
                                        <template slot-scope="scope">
                                            <span v-if="!scope.row.isEgdit">{{scope.row.Code}}</span>
                                            <el-form-item :prop="'tableData.' + scope.$index + '.Code'" :rules='rules.Code'>
                                                <el-autocomplete v-if="scope.row.isEgdit" v-model="scope.row.Code" :disabled="scope.row.isCanKey"  clearable="true" :fetch-suggestions="querySearchCodeAsync" @select="handleSelect" @change="handleChange"><i slot="suffix" class="el-icon-zoom-in" @click="selectCode(scope.$index,scope.row);"></i></el-autocomplete>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('Product Name')" align='center'>
                                        <template slot-scope="scope">
                                            <span v-if="!scope.row.isEgdit">{{scope.row.ProductName}}</span>
                                            <el-form-item>
                                                <span v-if="scope.row.isEgdit">{{scope.row.ProductName}}</span>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('BATCH')" align='center'>
                                        <template slot-scope="scope">
                                            <span v-if="!scope.row.isEgdit">{{scope.row.Batch}}</span>
                                            <el-form-item>
                                                <span v-if="scope.row.isEgdit">{{scope.row.Batch}}</span>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('COLOR')" align='center'>
                                        <template slot-scope="scope">
                                            <span v-if="!scope.row.isEgdit">{{scope.row.Color}}</span>
                                            <el-form-item>
                                                <span v-if="scope.row.isEgdit">{{scope.row.Color}}</span>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('At')" align='center'>
                                        <template slot-scope="scope">
                                            <span v-if="!scope.row.isEgdit">{{scope.row.OrderDate}}</span>
                                            <el-form-item>
                                                <span v-if="scope.row.isEgdit">{{scope.row.OrderDate}}</span>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('OrderNumber')" align='center'>
                                        <template slot-scope="scope">
                                            <span v-if="!scope.row.isEgdit">{{scope.row.OrderNumber}}</span>
                                            <el-form-item>
                                                <span v-if="scope.row.isEgdit">{{scope.row.OrderNumber}}</span>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('CanUserNumber')" align='center'>
                                        <template slot-scope="scope">
                                            <span v-if="!scope.row.isEgdit">{{scope.row.SurplusNumber}}</span>
                                            <el-form-item>
                                                <span v-if="scope.row.isEgdit">{{scope.row.SurplusNumber}}</span>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('UseNumberPO')" align='center'>
                                        <template slot-scope="scope">
                                            <span v-if="!scope.row.isEgdit">{{scope.row.Number}}</span>
                                            <el-form-item :prop="'tableData.' + scope.$index + '.Number'" :rules='rules.Number'>
                                                <el-input-number v-if="scope.row.isEgdit"   v-model="scope.row.Number" :controls="false" clearable :min="0" style="width: calc(100% - 10px);" ></el-input-number>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('LOSSRATE')" align='center'>
                                        <template slot-scope="scope">
                                            <span v-if="!scope.row.isEgdit">{{scope.row.LossRate}}</span>
                                            <el-form-item :prop="'tableData.' + scope.$index + '.LossRate'" :rules='rules.LossRate'>
                                                <el-input-number v-if="scope.row.isEgdit" v-model="scope.row.LossRate" :controls="false" clearable :min="0" style="width: calc(100% - 10px);" ></el-input-number>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('OPERATION')" align='center' v-if="!isLocked">
                                        <template slot-scope="scope">
                                            <el-button @click="deleteItem(scope.$index,scope.row)" :title="$t('Delete')" type="danger" size="small" icon="el-icon-delete" circle></el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div style="text-align:right;padding:50px 0;">
                                    <el-button type="primary" v-if="!isLocked" @click="submitForm">{{$t('Save')}}</el-button>
                                    <el-button @click="resetForm">{{$t('CANCEL')}}</el-button>
                                </div>
                            </template>
                        </div>
                        <el-dialog :title="$t('EditSOinformation')" :visible.sync="SOdialogTableVisible" :modal-append-to-body="false" width="50%" top="1vh">
                            <el-row>
                                <el-col :span="12" :sm="24" :md="12">
                                    <el-form-item :label="$t('SO')">
                                        <el-input v-model="formData.soVal" style="width: calc(100% - 5px);" clearable="true"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" :sm="24" :md="12">
                                    <el-form-item :label="$t('BUSINESS SERIAL NUMBER EXTERNAL')" prop="businessNoOutVal" >
                                        <el-input v-model="formData.businessNoOutVal" style="width: calc(100% - 5px);" clearable="true"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12" :sm="24" :md="12">
                                    <el-form-item :label="$t('Product Name')">
                                        <el-select v-model="formData.productCodeVal" filterable allow-create default-first-option placeholder="" style="width: calc(100% - 5px);" @change="selectChanged" clearable="true">
                                            <el-option v-for="item in productData"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" :sm="24" :md="12">
                                    <el-form-item :label="$t('REMARK')">
                                        <el-input v-model="formData.remarkVal" style="width: calc(100% - 5px);" clearable="true"></el-input>
                                    </el-form-item>

                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12" :sm="24" :md="12">
                                    <el-form-item :label="$t('CONTRACT SIGNING COMPANY')">
                                        <el-select v-model="formData.affCorpCollectionVal" filterable placeholder="" style="width: calc(100% - 5px);" @change="selectAffCollChanged" clearable="true">
                                            <el-option v-for="item in affCorpCollectionData"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" :sm="24" :md="12">
                                    <el-form-item :label="$t('ASSOCIATED PROCESSING PLANT')">
                                        <el-select v-model="formData.affCorpWorkingVal" filterable placeholder="" style="width: calc(100% - 5px);" @change="selectAffWorkChanged" clearable="true">
                                            <el-option v-for="item in affCorpWorkingData"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <div style="text-align:center;">
                                <el-button type="primary" v-if="!isLocked" @click="submittopInfoSO();">{{$t('Yes')}}</el-button>
                                <el-button @click="SOdialogTableVisible = false">{{$t('Cancel')}}</el-button>
                            </div>
                            <el-form-item>

                            </el-form-item>
                        </el-dialog>
                    </el-form>
                    <el-dialog :title="$t('selectPO')" :visible.sync="dialogTableVisible" :modal-append-to-body="false" width="90%" top="2vh">
                        <avue-crud ref="crud" :option="tableOpt" :row-style="rowStyle" :row-key="GetRowKeys" :data="potable" :page.sync="page" @selection-change="selectionChange" @current-change="currentChange">
                            <template slot="menuLeft" style="width: calc(100% - 80px);">
                                <el-row :gutter="20" class="topFromSearch">
                                    <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            <span>{{$t('ProductCode')}}:</span>
                                            <el-input v-model="codeVal" @focus="focusDiv($event)" @blur="blurDiv($event)" ></el-input>
                                        </el-col>
                                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            <span>{{$t('BATCH')}}:</span>
                                            <el-input v-model="batchVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                        </el-col>
                                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            <span>{{$t('COLOR')}}:</span>
                                            <el-input v-model="colorVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                        </el-col>
                                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            <span id="venSpan">{{$t('VENDOR')}}:</span>
                                            <el-select v-model="vendorVal.ids" @focus="focusDiv($event)" @blur="blurDiv($event)" multiple collapse-tags placeholder="" style="width:100%;" @change="venchang" clearable="true">
                                                <el-option v-for="item in this.vendorData"
                                                           :key="item.value"
                                                           :label="item.label"
                                                           :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </el-col>
                                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            <span>{{$t('BEINGTIME')}}:</span>
                                            <el-date-picker class="dataicon" v-model="beginTimeVal"
                                                            type="date" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                                            format="yyyy-MM-dd" value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                                            </el-date-picker>
                                        </el-col>
                                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                            <span>{{$t('ENDTIME')}}:</span>
                                            <el-date-picker class="dataicon" v-model="endTimeVal"
                                                            type="date" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                                            format="yyyy-MM-dd" value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                                            </el-date-picker>
                                        </el-col>
                                    </el-col>
                                    <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top:10px;">
                                        <el-button class="moreBtn" size="small" icon="iconfont icon-24px" circle @click="moreFrom" style="margin-right:0;"></el-button>
                                        <el-button type="primary" size="small" plain @click="searchFrom">{{$t('SEARCH')}}</el-button>
                                    </el-col>
                                </el-row>
                            </template>
                            <template slot="header">
                                <el-button type="primary" size="small" @click="submitSelectData()">{{$t('Yes')}}</el-button>
                            </template>
                            <template slot="tip">
                            </template>
                            <template slot="expand" slot-scope="props">
                                <el-table :data="props.row.Details" style="width: 100%" :header-cell-style="tableHeaderColor">
                                    <el-table-column type="index"></el-table-column>
                                    <el-table-column prop="So" :label="$t('SO')"></el-table-column>
                                    <el-table-column prop="PurchaseCorporationName" :label="$t('FROM')"></el-table-column>
                                    <el-table-column prop="Code" :label="$t('POProductCode')"></el-table-column>
                                    <el-table-column prop="MGName" :label="$t('POProductName')"></el-table-column>
                                    <el-table-column prop="Batch" :label="$t('POBATCH')"></el-table-column>
                                    <el-table-column prop="ProductName" :label="$t('InternalProductName')"></el-table-column>
                                    <el-table-column prop="Number" :label="$t('Number')"></el-table-column>
                                    <el-table-column prop="UnitName" :label="$t('UNIT')"></el-table-column>
                                    <el-table-column prop="Color" :label="$t('COLOR')"></el-table-column>
                                    <el-table-column prop="Ingredient" :label="$t('Ingredient')"></el-table-column>
                                    <el-table-column prop="UsedNumber" :label="$t('UseNumberPO')"></el-table-column>
                                </el-table>
                            </template>
                        </avue-crud>
                    </el-dialog>
                </div>
            </template>
        </basic-container>
    </div>
</template>
<style>
    .el-dialog__wrapper {
        transition-duration: 0.3s;
    }

    .notifyStyle {
        width: 30% !important;
        background: #FFE4E1;
    }

    .topInfo {
        background: linear-gradient(to right,#4A4A74,#212146);
        border-radius: 10px;
        padding: 30px 20px 10px;
        color: #fff;
        font-size: 14px;
    }

        .topInfo i {
            color: #B2EBE9;
            margin-right: 10px;
            font-size: 20px;
        }

        .topInfo p {
            width: calc(100% - 160px);
        }

    spanRight {
        width: 140px;
        text-align: right;
        padding-right: 20px;
        display: block;
    }

    spanLeft {
        width: 140px;
        text-align: left;
        padding-right: 20px;
        display: block;
    }

    table th.star div::before {
        content: ' * ';
        color: red;
    }

    .ttt {
        width: 100px;
    }

    .searlable .el-checkbox__label {
        color: #8CA0B3;
        font-weight: 500;
    }
</style>
<script>
    import { SaleInfo, editHeadSO, poCanUserStockList, stockNum, checkInventory, soUserStockList, addRalation, editRalation, delRalation, verificationInScope } from "@/api/salesOrd";
    import { ProcessDropDownList, TracingDropDownList, TracingStringList, TracingUnifiedIsBool, ProcessAddProduct, Getguid, corpinfo } from "@/api/unified";
    import { getVendorSectionInfo } from "@/api/supplierAdd";
    export default {
        data() {
            var requiredbrand = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    let indexRule = rule.fullField.split(".")
                    this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('POitem') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var checkKeepFourPlaces = (rule, value, callback) => {
                const FloatRegex = /^\d{1,14}(\.\d{1,4})?$/;
                if (!FloatRegex.test(value)) {
                    let indexRule = rule.fullField.split(".")
                    this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('NUMBER') + ':' + this.$t('KeepFourPlaces'));
                    callback(new Error(this.$t('KeepFourPlaces')));
                } else { callback(); }

            };
            var valiInventory = (rule, value, callback) => {
                let indexRule = rule.fullField.split(".")
                var ralationid = this.formData.tableData[indexRule[1]].Id;
                var Detailid = this.formData.tableData[indexRule[1]].PoDetailId;
                checkInventory(ralationid, Detailid, value, 'ralationEdit').then(res => {
                    var result = res.data;
                    if (result) {
                        stockNum(Detailid).then(resu => {
                            var resultnum = resu.data;
                            this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('NUMBER') + ':' + this.$t('noInventory')+resultnum);
                            callback(new Error(this.$t('noInventory') + resultnum));
                        });
                    } else { callback(); }
                }).catch((erro) => { console.log(erro) });
            };
            var valiNumbPass = (rule, value, callback) => {
                if (Number(value) > 0) {
                    callback();
                } else {
                    let indexRule = rule.fullField.split(".")
                    this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('NUMBER') + ':' + this.$t('NeedGreater0'));
                    callback(new Error(this.$t('NeedGreater0')));
                }
            };
            var valiLossRatePass = (rule, value, callback) => {
                if (Number(value) > 0) {
                    callback();
                } else {
                    let indexRule = rule.fullField.split(".")
                    this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('LOSSRATE') + ':' + this.$t('NeedGreater0'));
                    callback(new Error(this.$t('NeedGreater0')));
                }
            };
            var valiLossRate = (rule, value, callback) => {
                let indexRule = rule.fullField.split(".")
                getVendorSectionInfo(this.formData.VendorSectionIdVal).then(res => {
                    var result = res.data;
                    if (result != null) {
                        var min = result.lossRateMin;
                        var max = result.lossRateMax;
                        if (Number(value) > Number(max) || Number(value) < Number(min)) {
                            this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + ' ' + this.$t('LOSSRATE') + ':' + this.$t('Overstep') + min + "-" + max);
                            callback(new Error(this.$t('Overstep') + min + "-" + max));
                        } else { callback(); }
                    } else { callback(); }
                }).catch((erro) => { console.log(erro) });
            };
            var valiBusinessNoOut = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    callback();
                } else {
                    var Detailid = this.formData.loadId;
                    TracingUnifiedIsBool("SaleOrderDetail_BusinessNoOutExist", Detailid, value).then(res => {
                        var IsExist = res.data;
                        if (!IsExist) {
                            callback(new Error(this.$t('BUSINESS SERIAL NUMBER EXTERNAL') + ' ' + this.$t('Exist')));
                        } else {
                            callback();
                        }
                    }).catch((erro) => { console.log(erro) });
                }
            };
            return {
                codeVal: "", batchVal: "", colorVal: "", beginTimeVal: "", endTimeVal: "", vendorVal: { ids: [], }, vendorData: [],
                notip: true, notiplist: [], notificationPromise: Promise.resolve(), corpId: '',
                isLocked:false,
                formData: {
                    loadId: '', loadSOId: '', businessNoOutVal: '', businessNoInVal: '',
                    soVal: '', fromVal: '', outProductVal: '', batchVal: '', codeVal: '', colorVal: '', numberVal: '', unitVal: '', odateVal: '', remarkVal: '', departVal: '', destinationCountryNameVal: '',
                    productNameVal: '', productCodeVal: '', affCollNameVal: '', affCorpCollectionVal: '', affWorkNameVal: '', affCorpWorkingVal: '', VendorSectionIdVal:'',
                    show: false, sign: '',
                    tableData: [{ Id: '', PoDetailId: '', PoId: '', OriginalNumber: '', ProductName: '', Batch: '', Code: '', OrderDate: '', Color: '', OrderNumber: '', SurplusNumber: '', Number: '', LossRate:'', isEgdit: true, isCanKey: false }]
                },
                Coderestaurants: [], Codestate: '', Codetimeout: null,
                dialogTableVisible: false, SOdialogTableVisible: false, 
                potable: [], codeslist: [], fromData: [], selectedData: [], productData: [],//产品
                affCorpCollectionData: [],//关联合同签署公司 
                affCorpWorkingData: [],//关联加工工厂
                lineCur: 0,//当前选中行
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background: true,
                    pageSize: 10
                },
                rules: {
                    businessNoOutVal: [{ validator: valiBusinessNoOut, trigger: 'blur' }],
                    Code: [{ validator: requiredbrand, trigger: 'blur' }],
                    Number: [
                        { type: "number", validator: valiNumbPass, trigger: "blur" },
                        { type: "number", validator: checkKeepFourPlaces, trigger: 'blur' },
                        { type: "number", validator: valiInventory, trigger: "blur" }
                    ],
                    LossRate: [
                      /*  { type: "number", validator: valiLossRatePass, trigger: "blur" },*/
                        { type: "number", validator: valiLossRate, trigger: "blur" }
                    ],
                },
                tableOpt: {
                    page: true,
                    simplePage: true,
                    refreshBtn: false,
                    columnBtn: false,
                    addBtn: false,
                    addRowBtn: false,
                    cellBtn: false,
                    index: true,
                    indexLabel: ' ',
                    border: true,
                    align: "center",
                    menu: false,
                    emptyText: this.$t('No Data'),
                    selection: true,
                    selectable: (row) => {
                        return row.isCanCheck === 0;
                    },
                    reserveSelection: true,
                    tip: false,
                    expand: true,
                    rowKey: 'id',
                    expandRowKeys: [],
                    column: [
                        {
                            label: this.$t('DATE'),
                            prop: 'orderDate',
                            type: "date",
                            format: 'yyyy-MM-dd',
                            valueFormat: 'yyyy-MM-dd',
                        },
                        {
                            label: this.$t('VENDOR'),
                            prop: 'vendorCorporationId',
                        },
                        {
                            label: this.$t('ProductCode'),
                            prop: 'code',
                        },
                        {
                            label: this.$t('Product Name'),
                            prop: 'productName',
                        },
                        {
                            label: this.$t('BATCH'),
                            prop: 'batch',
                        },
                        {
                            label: this.$t('COLOR'),
                            prop: 'color',
                        },
                        {
                            label: this.$t('PO'),
                            prop: 'po',
                        },     
                        {
                            label: this.$t('UNIT'),
                            prop: 'unitId',
                        },
                        {
                            label: this.$t('OrderNumber'),
                            prop: 'number',
                        },
                        {
                            label: this.$t('CanUserNumber'),
                            prop: 'surplusNumber',
                        }
                    ]
                }

            }
        },
        watch: {
            $route() {
                this.Init();
            }
        },// watch的作用可以监控一个值的变换,并调用因为变化需要执行的方法。 //监听路由是否发生变化
        created() {
            this.Init();
        },
        methods: {
            //设置表头行的样式
            tableHeaderColor() {
                return 'background-color:#838bb3!important;padding: 0!important;'

            },
            moreFrom() {
                let fromHei = document.querySelector(".fromOutDiv").clientHeight;
                if (fromHei > 60) {
                    document.querySelector(".fromOutDiv").style.cssText = "height:60px;";
                } else {
                    document.querySelector(".fromOutDiv").style.cssText = "height:auto;";
                }
            },  //点击更多按钮
            focusDiv(obj) {//搜索框获取焦点
                if (obj.currentTarget === undefined) {
                    obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                } else {
                    if (obj.currentTarget.parentElement.getAttribute('class').indexOf('suffix') !== -1) {
                        obj.currentTarget.parentElement.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                    } else {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                    }

                }
                //console.log(obj.currentTarget.parentElement.getAttribute('class').indexOf('suffix') !== -1)

            },//搜索框失去焦点
            blurDiv(obj) {
                if (obj.currentTarget === undefined) {
                    if (obj.value === "") {
                        obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                } else if (obj.currentTarget === null) {
                    if (this.vendorVal.ids.length === 0) {
                        document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                } else {
                    if (obj.target.value == "") {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                }
            },
            venchang() {
                if (this.vendorVal.ids.length === 0) {
                    document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                } else {
                    document.querySelector("#venSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                }
            },//点击更多按钮
            searchFrom() {
                this.getPurOrdList();
            },//查询
            getCodeList() {
                this.Codeslist = [];
                TracingStringList("ProductCode").then(res => {
                    for (var i = 0; i < res.data.length; i++) {
                        this.Codeslist.push({
                            value: res.data[i]
                        })
                    }
                })
                return this.Codeslist;
            },
            Init() {
                TracingDropDownList('VendorList').then(res => {
                    this.vendorData = res.data;
                });
                ProcessDropDownList('ProductList').then(res => {
                    this.productData = res.data;
                });
                // 关联公司TYPE--0: 全部信息1：实际收款(关联开票), 2实际付款，3实际加工   
                TracingDropDownList('AffiliatedCorp', '', 1).then(res => {
                    this.affCorpCollectionData = res.data;
                });
                TracingDropDownList('AffiliatedCorp', '', 3).then(res => {
                    this.affCorpWorkingData = res.data;
                });
                corpinfo().then(res => {
                    this.corpId = res.data.id;
                })
                this.topInit();
                this.soUserStockInit();
            },
            resetForm() {
                this.$router.push({ path: '/pvh_factory/salesOrd' });
            },//取消按钮
            topInit() {
                this.formData.loadSOId = this.$route.query.soid;
                this.formData.loadId = this.$route.query.soDetailid;
                SaleInfo(this.formData.loadId).then(res => {
                    var o = res.data;
                    this.formData.soVal = o.SOInfo.So;

                    this.formData.fromVal = o.SOInfo.PurchaseCorporationId;
                    this.formData.batchVal = o.SOInfoDetails.Batch;
                    this.formData.outProductVal = o.SOInfoDetails.OutProductName;
                    this.formData.codeVal = o.SOInfoDetails.Code;
                    this.formData.colorVal = o.SOInfoDetails.Color;
                    this.formData.numberVal = o.SOInfoDetails.Number;
                    this.formData.destinationCountryNameVal = o.SOInfoDetails.DestinationCountryName;
                    this.formData.unitVal = o.SOInfoDetails.UnitId;
                    this.formData.odateVal = o.SOInfo.OrderDate !== '' ? o.SOInfo.OrderDate.substr(0, 10) : '';
                    this.formData.productNameVal = o.SOInfoDetails.ProductName;
                    this.formData.productCodeVal = o.SOInfoDetails.ProductCode;
                    this.formData.affCollNameVal = o.SOInfo.AffiliatedCorpCollectionName;
                    this.formData.affCorpCollectionVal = o.SOInfo.AffiliatedCorpCollection;
                    this.formData.affWorkNameVal = o.SOInfo.AffiliatedCorpWorkingName;
                    this.formData.affCorpWorkingVal = o.SOInfo.AffiliatedCorpWorking;
                    this.formData.VendorSectionIdVal = o.SOInfo.VendorSectionId;
                    this.formData.businessNoOutVal = o.SOInfoDetails.BusinessNoOut;
                    this.formData.businessNoInVal = o.SOInfoDetails.BusinessNoIn;
                    this.formData.remarkVal = o.SOInfoDetails.Remark;
                    this.formData.departVal = o.SOInfo.Division;
                    var ApproveStatus = o.SOInfoDetails.ApproveStatus;
                    var LockedStatus = o.SOInfoDetails.LockedStatus;
                    if (ApproveStatus == 100 || ApproveStatus == 50 || LockedStatus == 1)
                    {
                        this.isLocked = true;
                    }
                })
            },
            soUserStockInit() {
                //获取SO已使用的库存数据
                soUserStockList(1, 60, this.formData.loadId).then(res => {
                    var infodata = res.data.OrderRalations;
                    if (infodata.length > 0) {
                        this.formData.tableData = [];
                        for (var i = 0; i < infodata.length; i++) {
                            this.formData.tableData.push({
                                Id: infodata[i].ralationInfo.Id,
                                PoDetailId: infodata[i].POInfoDetail.Id,
                                PoId: infodata[i].POInfoDetail.PurchaseOrderId,
                                OriginalNumber: infodata[i].OriginalNumber,
                                Batch: infodata[i].POInfoDetail.Batch,
                                Code: infodata[i].POInfoDetail.Code,
                                ProductName: infodata[i].POInfoDetail.ProductName,
                                OrderDate: infodata[i].OrderDate,
                                Color: infodata[i].POInfoDetail.Color,
                                OrderNumber: infodata[i].POInfoDetail.Number,
                                SurplusNumber: infodata[i].SurplusNumber,
                                Number: infodata[i].ralationInfo.UsedNumber,
                                LossRate: infodata[i].ralationInfo.LossRate,
                                isEgdit: false,
                                isCanKey: true
                            })
                        }
                    }
                    if (this.formData.tableData.length == 0) { this.addrow(); }
                })
            },
            async getPurOrdList() {
                await poCanUserStockList(this.page.currentPage, this.page.pageSize, this.vendorVal.ids.toString(), this.codeVal, this.batchVal, this.colorVal, this.beginTimeVal, this.endTimeVal,).then(res => {
                    var stockListInfo = res.data.OrderStocks;
                    if (stockListInfo != null) {
                        this.potable = [];
                        for (var i = 0; i < stockListInfo.length; i++) {
                            var isCan = 0;
                            this.formData.tableData.forEach(v => {
                                if (stockListInfo[i].POInfoDetail.Id == v.PoDetailId) {
                                    isCan = 1;
                                }
                            });
                            this.potable.push({
                                id: stockListInfo[i].POInfoDetail.Id,
                                poDetailId: stockListInfo[i].POInfoDetail.Id,
                                purchaseOrderId: stockListInfo[i].POInfoDetail.PurchaseOrderId,
                                productName: stockListInfo[i].POInfoDetail.ProductName,
                                orderDate: stockListInfo[i].POInfo.OrderDate == '' ? '' : stockListInfo[i].POInfo.OrderDate.substr(0, 10),
                                po: stockListInfo[i].POInfo.Po,
                                vendorCorporationId: stockListInfo[i].POInfo.VendorCorporationId,
                                batch: stockListInfo[i].POInfoDetail.Batch,
                                color: stockListInfo[i].POInfoDetail.Color,
                                code: stockListInfo[i].POInfoDetail.Code,
                                status: stockListInfo[i].POInfoDetail.Status,
                                unitId: stockListInfo[i].POInfoDetail.UnitName,
                                number: stockListInfo[i].POInfoDetail.Number,
                                surplusNumber: stockListInfo[i].SurplusNumber,
                                isCanCheck: isCan,
                                Details: stockListInfo[i].Details
                            })
                        }
                    }
                    this.page.total = res.data.TotalCount;
                    this.page.pageSize = res.data.PageSize;
                    //只有一条数据
                    if (this.potable.length == 1) {
                        verificationInScope(this.potable[0].poDetailId, this.formData.loadId).then(res => {
                            var errinfo = res.data;
                            if (errinfo.length > 0) {
                                this.notiplist = [];
                                for (var i = 0; i < errinfo.length; i++) {
                                    if (errinfo[i].Param4 == "type1") {
                                        this.notiplist.push(this.$t('Index') + (errinfo[i].Param2) + '  ' + this.$t('verificationInScopeA') + errinfo[i].Param1 + this.$t('verificationInScopeB') + errinfo[i].Param3 + this.$t('verificationInScopeC'));
                                    }
                                    else {
                                        this.notiplist.push(this.$t('Index') + (errinfo[i].Param2) + '  ' + this.$t('verificationInScopeA') + errinfo[i].Param1 + this.$t('verificationInScopeB') + errinfo[i].Param3 + this.$t('verificationInScopeC') + '  ' + errinfo[i].Param5);
                                    }
                                }
                                this.gettip();
                                console.log('error submit!!');
                                return false;
                            }
                        }).catch((erro) => { console.log(erro) });

                        var isaddlien = true;
                        this.formData.tableData.forEach(v => {
                            if (this.potable[0].poDetailId == v.PoDetailId) {
                                isaddlien = false;
                            }
                        });
                        if (isaddlien) {
                            var numa = this.formData.tableData[this.lineCur].OrderNumber;//订单数量
                            if (numa == '')//判断是否为新录入行数
                            {
                                this.formData.tableData.splice(this.lineCur, 1);
                            }
                            let selectItem = {
                                Id: '', PoDetailId: this.potable[0].poDetailId, PoId: this.potable[0].purchaseOrderId, OriginalNumber: 0, Batch: this.potable[0].batch, ProductName: this.potable[0].productName, OrderDate: this.potable[0].orderDate, Code: this.potable[0].code, Color: this.potable[0].color, OrderNumber: this.potable[0].number, SurplusNumber: this.potable[0].surplusNumber, Number: '', isEgdit: true, isCanKey: true
                            }
                            this.formData.tableData.unshift(selectItem)
                        } else {
                            this.$message({
                                showClose: true, 
                                message: this.$t('DateExist'),
                                type: "warning"
                            })
                        }
                    }
                    else {
                        this.dialogTableVisible = true;
                    }
                    this.$refs["ruleform"].clearValidate(); // 移除校验结果
                }).catch(erro => {
                    console.log(erro)
                })
            },
            GetRowKeys(row) {
                return row.Id;
            },
            rowCell(row, index) {
                this.$refs.crud.rowCell(row, index)
            },
            rowStyle({ rowIndex }) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
            },//切换分页
            currentChange(val) {
                this.page.currentPage = val
                this.getPurOrdList();
            },
            starAdd(obj) {
                if (obj.columnIndex === 1 || obj.columnIndex === 8 || obj.columnIndex === 9) {
                    return 'star';
                }
            },//列头加星号
            tableRowClassName({ row, rowIndex }) {
                // 把每一行的索引放进row
                row.index = rowIndex
            },//加index
            addrow() {
                let item = { Id: '', PoDetailId: '', PoId: '', OriginalNumber: '', Batch: '', Code: '', ProductName: '', OrderDate: '', Color: '', OrderNumber: '', SurplusNumber: '', Number: '', LossRate:'', isEgdit: false, isCanKey: false }
                this.formData.tableData.push(item);      // 把每一行的索引放进row
            },//新增行
            deleteItem(index, row) {
                this.$confirm(this.$t('IsDel') + row.Code, this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                    if (row.Id == '') {
                        this.formData.tableData.splice(index, 1);
                    }
                    else {
                        delRalation(row.Id).then(() => {
                            //待删除
                            this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                            this.formData.tableData.splice(index, 1);
                        });
                    }
                })
            },//删除行
            handleCurrentChange(currentRow, oldCurrentRow) {
                if (currentRow.index == this.formData.tableData.length - 1) {
                    this.addrow();
                }
                this.$set(currentRow, 'isEgdit', true);
                if (oldCurrentRow != null && oldCurrentRow != undefined) {
                    this.$set(oldCurrentRow, 'isEgdit', false);
                }
                this.lineCur = currentRow.index;
            },//改变点击行
            querySearchCodeAsync(queryString, cb) {
                var Coderestaurants = this.Coderestaurants;
                var results = queryString ? Coderestaurants.filter(this.createStateFilterCode(queryString)) : Coderestaurants;
                clearTimeout(this.Codetimeout);
                this.Codetimeout = setTimeout(() => {
                    cb(results.slice(0,10));
                }, 1000 * Math.random());
            },
            createStateFilterCode(queryString) {
                return (Codestate) => {
                    return (Codestate.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            handleChange(val) {
                this.codeVal = val;
                this.getPurOrdList();
            },
            handleSelect(item) {
                this.codeVal = item.value;
                this.getPurOrdList();
            },//选中批号
            selectCode(index, row) {
                this.lineCurrent = row.index;
                this.codeVal = row.Code;
                this.getPurOrdList();
            },//点击选Batch图片
            selectionChange(list) {
                this.selectedData = list;
            },//改变项值
            submitSelectData() {
                if (this.selectedData.length > 0) {
                    var numa = this.formData.tableData[this.lineCur].OrderNumber;//订单数量
                    if (numa == '')//判断是否为新录入行数
                    {
                        this.formData.tableData.splice(this.lineCur, 1);
                    }
                    for (var i = 0; i < this.selectedData.length; i++) {
                        //判断是否再品牌授权内
                        verificationInScope(this.selectedData[i].PoDetailId, this.formData.loadId).then(res => {
                            var errinfo = res.data;
                            if (errinfo.length > 0)
                            {
                                this.notiplist = [];
                                for (var i = 0; i < errinfo.length; i++)
                                {
                                    if (errinfo[i].Param4 == "type1") {
                                        this.notiplist.push(this.$t('Index') + (errinfo[i].Param2) + '  ' + this.$t('verificationInScopeA') + errinfo[i].Param1 + this.$t('verificationInScopeB') + errinfo[i].Param3 + this.$t('verificationInScopeC'));
                                    }
                                    else
                                    {
                                        this.notiplist.push(this.$t('Index') + (errinfo[i].Param2) + '  ' + this.$t('verificationInScopeA') + errinfo[i].Param1 + this.$t('verificationInScopeB') + errinfo[i].Param3 + this.$t('verificationInScopeC') + '  ' +  errinfo[i].Param5 );
                                    }
                                }
                                this.gettip();
                                console.log('error submit!!');
                                return false;
                            }
                        }).catch((erro) => { console.log(erro) });

                        //编辑修改状态
                        var isaddlien = true;
                        this.formData.tableData.forEach(v => {
                            if (this.selectedData[i].poDetailId == v.PoDetailId) {
                                isaddlien = false;
                            }
                        });
                        if (isaddlien) {
                            //打开最后一行的编辑状态
                            var IsOpen = false;
                            if (i == this.selectedData.length - 1) {
                                IsOpen = true;
                            }
                            let selectItem = {
                                Id: '',
                                PoDetailId: this.selectedData[i].poDetailId,
                                PoId: this.selectedData[i].purchaseOrderId,
                                OriginalNumber: 0,
                                Batch: this.selectedData[i].batch,
                                ProductName: this.selectedData[i].productName,
                                OrderDate: this.selectedData[i].orderDate,
                                Code: this.selectedData[i].code,
                                Color: this.selectedData[i].color,
                                OrderNumber: this.selectedData[i].number,
                                SurplusNumber: this.selectedData[i].surplusNumber,
                                Number: '',
                                LossRate: '',
                                isEgdit: IsOpen,
                                isCanKey: true
                            }
                            this.formData.tableData.unshift(selectItem);
                        }
                    }
                    this.$refs["ruleform"].clearValidate() // 移除校验结果
                }
                this.dialogTableVisible = false;
            },
            submitForm() {
                this.$refs["ruleform"].clearValidate() // 移除校验结果
                for (var j = this.formData.tableData.length - 1; j > -1; j--) {
                    if (this.formData.tableData[j].Code.trim() == '' && this.formData.tableData[j].Color.trim() == ''
                        && (this.formData.tableData[j].Number == 0 || this.formData.tableData[j].Number == '') && (this.formData.tableData[j].LossRate == 0 || this.formData.tableData[j].LossRate == '')) {
                        this.formData.tableData.splice(j, 1);
                    }
                }
                if (this.formData.tableData.length == 0) {
                    this.addrow();
                    this.$message({
                        message: this.$t('No Data Submitted'),
                        type: "error"
                    });//提示无数据
                    return false;
                } else {
                    this.$nextTick(() => {//避免点击两次
                        this.notip = false;
                        this.notiplist = [];
                        let formName = "ruleform";
                        this.$refs[formName].validate((valid) => {
                            if (valid) {
                                this.formData.tableData.forEach(v => {
                                    let paramrow = {
                                        id: v.Id,
                                        indexNumber: v.index,
                                        purchaseOrderId: v.PoId,
                                        saleOrderId: this.formData.loadSOId,
                                        usedNumber: v.Number,
                                        lossRate: v.LossRate,
                                        purchaseOrderDetailId: v.PoDetailId,
                                        saleOrderDetailId: this.formData.loadId,
                                        remark: '',
                                        status: 0,
                                        createTime: '2021-05-01T08:00',
                                        createUserid: '',
                                        updateTime: '2021-05-01T08:00',
                                        updateUserid: '',
                                        departmentId: '',
                                        corporationId: ''
                                    };
                                    if (v.Id != '') {
                                        editRalation(paramrow).then().catch((erro) => { console.log(erro) });
                                    }
                                    if (v.Id == '') {
                                        addRalation(paramrow).then().catch((erro) => { console.log(erro) });
                                    }

                                })
                                this.$message({
                                    message: this.$t('OperationSuccess'),
                                    type: "success"
                                });
                                setTimeout(() => {
                                    this.$router.push({ path: '/pvh_factory/salesOrd' });
                                }, 1000 * Math.random());
                            } else {
                                this.gettip();
                                console.log('error submit!!');
                                return false;
                            }
                        });
                    });
                }
            },//保存按钮
            editSOinfo() {
                this.SOdialogTableVisible = true;
            },//弹出修改框
            submittopInfoSO() {
                if (this.formData.businessNoOutVal == '' || this.formData.businessNoOutVal == undefined || this.formData.businessNoOutVal == null) {
                    //this.$message({ showClose: true, message: this.$t('BUSINESS SERIAL NUMBER EXTERNAL') + ' ' + this.$t('Required'), type: "warning" })
                    //return false;
                } else {
                    var Detailid = this.formData.loadId;
                    TracingUnifiedIsBool("SaleOrderDetail_BusinessNoOutExist", Detailid, this.formData.businessNoOutVal).then(res => {
                        var IsExist = res.data;
                        if (!IsExist) {
                            //this.$message({ showClose: true, message: this.$t('BUSINESS SERIAL NUMBER EXTERNAL') + ' ' + this.$t('Exist'), type: "warning" })
                            //return false;
                        } else
                        {
                            let sorow = {
                                Id: this.formData.loadSOId,
                                DetailId: this.formData.loadId,
                                So: this.formData.soVal,
                                ProductName: this.formData.productNameVal,
                                ProductCode: this.formData.productCodeVal,
                                AffiliatedCorpCollection: this.formData.affCorpCollectionVal,
                                AffiliatedCorpWorking: this.formData.affCorpWorkingVal,
                                Remark: this.formData.remarkVal,
                                BusinessNoOut: this.formData.businessNoOutVal,
                            };
                            editHeadSO(sorow).then(() => {
                                this.$message({
                                    message: this.$t('OperationSuccess'),
                                    type: "success"
                                });
                                this.SOdialogTableVisible = false;
                            }).catch((erro) => { console.log(erro) });
                        }
                    }).catch((erro) => { });
                }
            },//提交修改
            selectChanged(value) {
                var objrow = {};
                objrow = this.productData.find((i) => {//这里的List就是上面遍历的数据源
                    return i.value === value;//筛选出匹配数据
                });
                if (objrow == undefined) {
                    var Productid = Getguid();
                    var Productcode = value;
                    var Productname = value;
                    //创建物料接口
                    let paramrow = {
                        id: Productid,
                        name: Productname,
                        code: Productcode,
                        status: 0,
                        enableBatch: 1,
                        corporationId: this.corpId,
                        auditStatus: 1,
                        isStandard: 0,
                        isAutomaticAdd: 1,
                    };
                    ProcessAddProduct(paramrow).then(() => {
                        let newItem = { value: Productid, label: Productname };
                        this.productData.unshift(newItem);
                        this.formData.productCodeVal = Productid;
                        this.formData.productNameVal = Productname;
                    }
                    ).catch((erro) => { console.log(erro) });
                } else {
                    this.formData.productNameVal = objrow.label;
                }
            },
            selectAffCollChanged(value) {
                var objrow = {};
                objrow = this.affCorpCollectionData.find((i) => {//这里的userList就是上面遍历的数据源
                    return i.value === value;//筛选出匹配数据
                });
                this.formData.affCollNameVal = objrow.label
            },
            selectAffWorkChanged(value) {
                var objrow = {};
                objrow = this.affCorpWorkingData.find((i) => {//这里的userList就是上面遍历的数据源
                    return i.value === value;//筛选出匹配数据
                });
                this.formData.affWorkNameVal = objrow.label
            },
            gettip(){
                this.notificationPromise = this.notificationPromise.then(this.$nextTick).then(() => {
                    let newDatas = [];
                    const h = this.$createElement;
                    for (let i in this.notiplist) {
                        newDatas.push(h('p', null, this.notiplist[i]));
                    }
                    this.$notify.error({
                        title: this.$t('OperationFailed'),
                        offset: 100,
                        showClose: true,
                        duration: 0,
                        message: h('div', null, newDatas),
                        customClass: 'notifyStyle',
                    });
                });
            }
        },
        mounted() {
            this.Coderestaurants = this.getCodeList();//数据源

        }
    }
</script>