<template>
  <div>
    <basic-container>
      <el-dialog
        :title='$t("Edit")'
        :visible.sync="dialogVisible"
        width="70%"
        :before-close="handleClose">
        <avue-crud :option="tableOptions"  :data="tableDatas" ref="crud">
<template slot-scope="scope" slot="menu">
  <div  style="height:30px;" v-if="scope.row.status == 0"/>
        <el-button type="text"
                   size="small"
                   plain
                   class="none-border"
                   @click.stop="del(scope.row,scope.index)">{{$t("DELETE")}}
        </el-button>
      </template>
        <!-- <template slot="machineCode" slot-scope="{row}">
          <el-link style="color: #409EFF;text-style: none;" @click="clickLink(row)">
          </el-link>
        </template> -->
        <template slot="menuRight">
          <el-form :inline="true" :model="formInlines" class="demo-form-inline" labelWidth="90px" style="display:flex;justify-content:space-between;flex-wrap:wrap;">
            <el-input v-model="equipments" :disabled='true' :placeholder="$t('Equipment Name')" style="display:inlie-block;width:150px;marginRight: 5px;" size="small"></el-input>
            <el-select filterable clearable v-model="proIds" @change="dpinzhong" placeholder="品种" size="small" style="width:100px !important;marginRight: 5px;">
                <el-option v-for="item in refineList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-select filterable clearable v-model="postIds" @change="dpihao" placeholder="批号" size="small" style="width:100px !important;marginRight: 5px;">
                <el-option v-for="item in workList" :key="item.id" :label="item.batchCode" :value="item.id"></el-option>
            </el-select>
                <el-date-picker clearable
                                v-model="startTimes"
                                 size="small"
                                value-format="yyyy-MM-dd"
                                type="datetime"
                                placeholder="开始日期" style="width:150px !important;marginRight: 5px;">
                </el-date-picker>
                <el-date-picker clearable
                                v-model="endTimes"
                                 size="small"
                                value-format="yyyy-MM-dd"
                                type="datetime"
                                placeholder="结束日期" style="width:150px !important;marginRight: 5px;">
                </el-date-picker>
            <el-input v-model="outputs" :placeholder="$t('Please Enter Output')" style="display:inlie-block;width:150px;marginRight: 5px;" size="small"></el-input>
            <el-input v-model="consumptions" :placeholder="$t('Please Enter Energy Consumption')" style="display:inlie-block;width:150px;marginRight: 5px;" size="small"></el-input>
          <el-button type="primary" @click="save" size="mini" style="height:32px;padding-top: 8px;">{{$t("save")}}</el-button>
        </el-form>
        </template>
      </avue-crud>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">{{$t("Cancel")}}</el-button>
          <el-button type="primary" @click="sub">{{$t("Save")}}</el-button>
        </span>
      </el-dialog>
      <avue-crud :option="tableOption"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :page="page"
                 ref="crud"
                 @current-change="currentChange"
                 @size-change="sizeChange"
                 @refresh-change="refreshChange"
                 @row-update="handleUpdate"
                 >
                  
<template slot-scope="scope" slot="menu">
  <!-- <div  style="height:30px;" v-if="scope.row.status == 0"/> -->
        <el-button type="text"
                   size="small"
                   plain
                   class="none-border"
                   @click.stop="handleView(scope.row,scope.index)">{{$t("Edit")}}
        </el-button>
      </template>
        <!-- <template slot="machineCode" slot-scope="{row}">
          <el-link style="color: #409EFF;text-style: none;" @click="clickLink(row)">
          </el-link>
        </template> -->
        
        <template slot="menuRight">
             <el-form :inline="true" :model="formInline" class="demo-form-inline" labelWidth="90px" style="display:flex;">
                <!-- <el-form-item label="开始时间:"  style="width:250px;display:flex;"> -->
                    <el-date-picker clearable
                                    v-model="startTime"
                                    value-format="yyyy-MM-dd"
                                    size="small"
                                    type="date"
                                    placeholder="开始日期"  style="width:150px !important;margin-right: 5px;">
                    </el-date-picker>
                <!-- </el-form-item>
                 <el-form-item label="结束时间:"  style="width:250px;display:flex;height:32px"> -->
                    <el-date-picker clearable
                                    v-model="endTime"
                                    value-format="yyyy-MM-dd"
                                      size="small"
                                    type="date"
                                    placeholder="结束日期" style="width:150px !important;margin-right: 5px;">
                    </el-date-picker>
                <!-- </el-form-item> -->
          <el-select filterable clearable v-model="page.processId" @change="pinzhong" placeholder="品种" size="small" style="width:100px !important;marginRight: 5px;">
            <el-option v-for="item in refineList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <!-- <el-select filterable clearable v-model="page.postId" placeholder="批号" size="small" style="width:100px !important;marginRight: 5px;">
            <el-option v-for="item in workLists" :key="item.id" :label="item.batchCode" :value="item.id"></el-option>
          </el-select> -->
          <el-autocomplete
              size="small" style="width:210px !important;marginRight: 5px;"
              v-model="page.postId"
              :fetch-suggestions="querySearchAsync"
              @input="changeInput"
              placeholder="请输入批号"
              @select="handleSelect">
            <template slot-scope="{item}">
                <div class="name">{{item.value=item.batchCode}}</div>
              </template>
           </el-autocomplete>
           <el-select filterable clearable v-model="page.workShopId" @change="chejian" placeholder="车间" size="small" style="width:100px !important;marginRight: 5px;">
            <el-option v-for="item in workshopList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
           <el-select filterable clearable v-model="page.groupId" placeholder="设备" size="small" style="width:100px !important;marginRight: 5px;">
            <el-option v-for="item in quipmentList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-button type="primary" @click="search" size="mini" style="height:32px;padding-top: 8px;">{{$t("search")}}</el-button>
        </el-form>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {searchVarieties,searchNumber,searcheQuipment,list,searchWorkshop,getCommmit,getvarietiesById,getBatchById,getIdByCode} from "@/api/refineStyle";
export default {
    data() {
     return {
       // 弹出层
       getRow: {},
       table:[],
       varieties:'',
       batch:'',
       batchCodeId: '',
       tableDatas:[],
       tableOptions:{
        editBtn: false,
        delBtn: false,
        refreshBtn: false,
        addBtn: false,
        menuWidth: 200,
        columnBtn: false,
        emptyText: this.$t('No Data'),
        menuTitle: this.$t('OPERATION'),
        column: [{
          label: "设备名称",
          prop: "machineName",
        
        }, {
          label: "品种名称",
          // slot: true,
          prop: "productName",
            id: 'ids'
        }, {
          label: "批号",
          prop: "batchCode"
        }, {
          label: "开始时间",
          prop: "startTime"
        }, {
          label: "结束时间",
          prop: "endTime"
        }, {
          label: "产量",
          prop: "yield"
        },{
          label: "能耗",
          prop: "energy"
        }]
       },
       formInline:{},
       formInlines:{},
       equipments: '', //设备名称
        startTimes: '',
        endTimes:'',
        outputs:'',
        consumptions: '',
        // pageSize: 10,
        // pagerCount: 50,
        // total: 10,
        // pageNo: 1,
        // workShopId:'',
        // groupId:'', // 设备
        proIds:'', //品种
        postIds:'', //批号
        // 其他------
       equipment: '', //设备名称
       output: '', //产量
       consumption: '', //能耗
        //  细纱
      refineList: [],//品种列表
      quipmentList:[],//设备列表
      formInline: {}, //
      startTime: null, //开始时间
      endTime: null, //结束时间
      pinzhongId: null, //品种Id
      // valueTransfer: [],
      tableLoading: false,
      workshopList: [], //车间列表
      // classData:null,
      // dataTransfer: [],
      // //添加机台
      // addVisible: false,
      // workValue: "",
      // wklnValue: "",
      // postName:'',
      // processName:'',
      // refrechValue: "",
      // calssValue: "",
      // //班组
      // refrechLists: [],
      // //工序
      // wklnLists: [],
      // //车间
      // calssLists: [],
      // //岗位
      workLists: [],
      workList: [],
      // //当前编辑的人员
      // userId: "",
      // //机台
      // nameorcode: "",
      // classAssign: "甲班",
      // wkshp: "主车间",
      // person: "人物",
      dialogVisible: false,
      page: {
        pageSize: 10,
        // pagerCount: 50,
        total:'',
        pageNo: 1,
        workShopId:'',
        groupId:'', // 设备
        processId:'', //品种
        postId:'', //批号
        userName:''
      },
      total: 0,
      input: "",
      tableData: [],
      tableOption: {
        editBtn: false,
        delBtn: false,
        refreshBtn: false,
        addBtn: false,
        menuWidth: 200,
        columnBtn: false,
        // dialogFullscreen: true,
        // addBtnText: this.$t('ADD'),
        // editBtnText: this.$t('Edit'),
        emptyText: this.$t('No Data'),
        menuTitle: this.$t('OPERATION'),
        column: [{
          label: "设备名称",
          prop: "machineName"
        }, {
          label: "品种名称",
        //   slot: true,
          prop: "productName"
        }, {
          label: "批号",
          prop: "batchCode"
        }, {
          label: "开始时间",
          prop: "startTime"
        }, {
          label: "结束时间",
          prop: "endTime"
        }, {
          label: "产量",
          prop: "yield"
        },{
          label: "能耗",
          prop: "energy"
        }]
      }
    }
    },
    created() {
        list().then(res => {
          console.log('哈哈哈',res);
            // this.tableData = res.data.data.data
        })
        // 查询品种
        searchVarieties().then(res => {
            console.log('细沙列表',res);
            this.refineList = res.data.data.items
        }),
        // 查询车间
        searchWorkshop(1).then(res => {
          console.log('车间',res.data);
          this.workshopList = res.data.data
        });
        this.getTimeFn();
        this.search()
    },
    mounted() {
        //列表
        // list(this.page.postId,this.startTime,this.endTime,this.page.processId,this.page.postId,this.page.pageNo,this.page.pageSize).then(res => {
        //      console.log('=======',res,this.page.postId);
        // })
        //查询批号
        // searchNumber(this.refineList[0].id).then(res => {
        //     console.log('批号',res);
        // })
    },
    methods: {
      changeInput() {
         searchNumber(this.page.processId,this.page.postId).then(res => {
                console.log('批号',res);
                this.workLists = res.data.data
                // this.workLists.slice(0,20)
              })
      },
       querySearchAsync(queryString, cb) {
         var restaurants = this.workLists;
         console.log(restaurants);
        var result = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
         cb(result);
      },
      createFilter(queryString) {
        return (restaurant) => {
              if (restaurant.batchCode !== null) {
                return (restaurant.batchCode.toLowerCase().indexOf(queryString.toLowerCase()) !== -1);
            }
          
        };
    },
      handleSelect(item) {
        console.log(item);
        this.batchCodeId = item.id
        console.log(item.id);

      },
      dpinzhong(e) {
         searchNumber(e).then(res => {
                console.log('dialog批号',res);
                this.workList = res.data.data
              })
        getvarietiesById(e).then(res => {
          console.log(res,'mingzi');
          this.varieties = res.data.data.name
        })
        console.log('val',e);
      },
      dpihao(val){
        getBatchById(val).then(res => {
          this.batch = res.data.data.batchCode
          console.log('id',res);
        })
        console.log('123',val);
      },
      handleClose(done) {
        this.$confirm(this.$t("IsClose"))
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      pinzhong(val) {
            if(this.page.processId == '') {
              return
            }else {
              // searchNumber(val,'11').then(res => {
              //   console.log('批号',res);
              //   this.workLists = res.data.data
              //   this.workLists.slice(0,20)
              // })
            }
          // this.pinzhongId = val;
      },
      addConfirm() {

      },
      cjChange() {
          
      },
      chejian(val) {
          console.log('val',val);
          if(this.page.workShopId == '') {
            return
          }else {
            // 查询设备
          searcheQuipment(val).then(res => {
            console.log('设备',res.data);
            this.quipmentList = res.data.data.items
            })
          }
      },

      async search() {
        if(this.page.postId != '') {
         await getIdByCode(this.page.postId).then(res => {
            console.log(res);
           this.batchCodeId = res.data.data.id
           console.log(res.data.data.id);
           console.log(this.batchCodeId);
          })
        }
          console.log(this.page.pageNo,this.page.pageSize,this.page.processId,this.batchCodeId,this.page.groupId,this.startTime+' 00:00:000',this.endTime + ' 00:00:000');
          if(this.startTime == '' || this.endTime == '') {
            console.log('输入时间');
          }else {
            await list(this.page.pageNo,this.page.pageSize,this.startTime+' 00:00:000',this.endTime + ' 00:00:000',this.page.groupId,this.page.processId,this.batchCodeId).then(res => {
             console.log('=======',res);
              this.tableData = res.data.data.data
              this.page.total = res.data.data.total
            })
          }
       },
      handleUpdate() {

      },
      refreshChange() {

      },
      //点击修改
      handleView(row) {
        this.getRow = row
        this.dialogVisible = true
        this.equipments = row.machineName
      },
      //弹出框的删除
      del(row) {
        console.log(row);
        this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
            confirmButtonText: this.$t('Yes'),
            cancelButtonText: this.$t('Cancel'),
            type: 'warning'
      }).then(() => {
        console.log('row',row);
        console.log('this.tableDatas',this.tableDatas);
        this.tableDatas.splice(row,1)
              // this.list(this.item);
              this.$message({
                showClose: true,
                message: "删除成功",
                type: "success"
              });
          })
          .catch(() => {
          });
      },
      // 弹出框的保存
      save() {
        console.log('弹出框的保存',this.equipments,this.varieties,this.batch,this.startTimes,this.endTimes,this.outputs,this.consumptions);
        let objSave = {
          machineName: this.equipments,
          productName:this.varieties,
          batchCode: this.batch,
          startTime: this.startTimes,
          endTime: this.endTimes,
          yield: this.outputs,
          energy: this.consumptions
        }
        this.tableDatas.push(objSave) 
      },
      // 弹出框的提交
      sub() {
        let subObj = {
          cutProductId:this.proIds, // 切点品种id
          cutBatchId: this.postIds,// 切点批号id
          cutStartTime:this.startTimes,// 切点时间
          cutEndTime:this.endTimes,// 切点结算时间
          cutYield:this.outputs,// 开始-切点品种 的产量
          cutEnergy:this.consumptions// 开始-切点品种 的能耗
        }
        this.table.push(subObj)
        getCommmit(this.getRow,this.table).then(res => {
          console.log('res',res);
           this.dialogVisible = false
           list(this.page.pageNo,this.page.pageSize,this.startTime+' 00:00:000',this.endTime + ' 00:00:000',this.page.groupId,this.page.processId,this.page.postId).then(res => {
              this.tableData = res.data.data.data
           })
        })
          console.log('弹出框的提交');
      },
      currentChange(pageCurrent) {
                this.page.pageNo = pageCurrent;
                this.search();
            },
            sizeChange(pageSize) {
                this.page.pageNo = 1;
                this.page.pageSize = pageSize;
                this.search();
            },
        getTimeFn() {
          console.log(1);
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
          this.startTime = this.formatDate(start);
          this.endTime = this.formatDate(end);
      },
        formatDate(date) {
          var myyear = date.getFullYear();
          var mymonth = date.getMonth() + 1;
          var myweekday = date.getDate();

          if (mymonth < 10) {
            mymonth = "0" + mymonth;
          }
          if (myweekday < 10) {
            myweekday = "0" + myweekday;
          }
          return myyear + "-" + mymonth + "-" + myweekday;
        }
    }
}
</script>
<style lang="scss" scoped>
.machineClass {
  /deep/.avue-crud__left {
    width: 100%;
  }
  /deep/.avue-crud__right {
    width: 100%;
  }
}
</style>