<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:10px;">{{$t('SALES ORDER')}}</div>
            </div>
            <template>
                <el-row :gutter="20" class="topFromSearch">
                    <el-col :xs="24" :sm="17" :md="17" :lg="19" :xl="20" class="fromOutDiv">
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('SO')}}:</span>
                            <el-input v-model="soVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('BUSINESS SERIAL NUMBER INTERNAL')}}:</span>
                            <el-input v-model="businessnoinVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('BUSINESS SERIAL NUMBER EXTERNAL')}}:</span>
                            <el-input v-model="businessnooutVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('POProductCode')}}:</span>
                            <el-input v-model="codeVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('POBATCH')}}:</span>
                            <el-input v-model="batchVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                        </el-col>

                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span id="venSpan">{{$t('FROM')}}:</span>
                            <el-select v-model="fromVal.ids" @focus="focusDiv($event)" @blur="blurDiv($event)" multiple collapse-tags placeholder="" style="width:100%;" @change="venchang" clearable="true">
                                <el-option v-for="item in this.fromData"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>

                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('BEINGTIME')}}:</span>
                            <el-date-picker class="dataicon" v-model.trim="beginTimeVal"
                                            type="date" @focus="focusDiv($event)" @blur="blurDiv($event)" prefix-icon="" style="width:100%;">
                            </el-date-picker>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('ENDTIME')}}:</span>
                            <el-date-picker class="dataicon" v-model.trim="endTimeVal"
                                            type="date" @focus="focusDiv($event)" @blur="blurDiv($event)" prefix-icon="" style="width:100%;">
                            </el-date-picker>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('COLOR')}}:</span>
                            <el-input v-model="colorVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6" v-if="!isCanAgent">
                            <span id="otherStatusSpan">{{$t('STATE')}}:</span>
                            <el-select v-model="otherStatusVal" @focus="focusDiv($event)" @blur="blurDiv($event)" collapse-tags placeholder="" style="width:100%;" @change="otherStatuschang" clearable="true">
                                <el-option v-for="item in this.otherStatusData"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6" v-if="isCanAgent">
                            <span id="traingStatusSpan">{{$t('STATE')}}:</span>
                            <el-select v-model="traingStatusVal" @focus="focusDiv($event)" @blur="blurDiv($event)" collapse-tags placeholder="" style="width:100%;" @change="traingStatuschang" clearable="true">
                                <el-option v-for="item in this.traingStatusData"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('UPDATE START DATE')}}:</span>
                            <el-date-picker class="dataicon" v-model.trim="UTbeginTimeVal"
                                            type="date" @focus="focusDiv($event)" @blur="blurDiv($event)" prefix-icon="" style="width:100%;">
                            </el-date-picker>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('UPDATE END DATE')}}:</span>
                            <el-date-picker class="dataicon" v-model.trim="UTendTimeVal"
                                            type="date" @focus="focusDiv($event)" @blur="blurDiv($event)" prefix-icon="" style="width:100%;">
                            </el-date-picker>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6" style="justify-content:flex-start;height:38px;align-items:center;display:flex;">
                            <el-checkbox v-model="StatusVal" class="othChe">{{$t('DeletedData')}}</el-checkbox>
                        </el-col>
                    </el-col>
                    <el-col :xs="24" :sm="7" :md="7" :lg="5" :xl="4" style="padding-top:10px;">
                        <el-button class="moreBtn" size="small" icon="iconfont icon-24px" circle @click="moreFrom"></el-button>
                        <el-button type="primary" size="small" plain  @click="search">{{$t('SEARCH')}}</el-button>
                        <el-button type="primary" size="small" icon="el-icon-plus" v-if="isCanAgent" style="margin-left:10px;" @click="addPurOrd">{{$t('PO AGENT')}}</el-button>
                    </el-col>
                </el-row>
                <avue-crud class="pvhTable"
                           ref="crud" v-model="obj"
                           :option="setData.tableOpt"
                           :data="tableData"
                           :row-style="rowStyle"
                           :page.sync="page"
                           @selection-change="selectionChange"
                           @current-change="currentChange"
                           @row-update="handleUpdate">
                    <template slot-scope="{row,index,size,type}" slot="Speed">
                        <el-tooltip class="item" effect="light" placement="bottom-start">
                            <div slot="content" v-for="(item,index) in row.SpeedContent" v-bind:key="index">{{formatColumn(item)}}</div>
                            <el-link type="warning">{{row.Speed}}</el-link>
                        </el-tooltip>
                    </template>
                    <template slot-scope="{row,index,size,type}" slot="menu">
                        <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')" v-if="row.ApproveStatus!=100&&row.LockedStatus!=1" @click="$refs.crud.rowEdit(row,index)"></el-button>
                        <el-button icon="el-icon-reading" type="text" size="medium" :title="$t('Look Over')" v-if="row.ApproveStatus==100||row.LockedStatus==1" @click="$refs.crud.rowView(row,index)"></el-button>
                        <el-button icon="el-icon-edit" type="text" size="medium" :title="$t('EDIT AGENT PO')" v-if="isCanAgent" @click="editPur(row)"></el-button>
                        <el-button icon="el-icon-delete-solid" type="text" size="medium" :title="$t('Delete')" v-if="isCanAgent&&row.ApproveStatus!=100&&row.LockedStatus!=1" @click="delPur(row)"></el-button>
                    </template>
                    <template slot="DestinationCountryHeader" slot-scope="{ column }">
                        <label>{{ column.label }}</label>
                        <el-tooltip class="item" effect="dark" placement="bottom">
                            <div slot="content">
                                {{ $t('MARKET EXPLAIN') }}
                            </div>
                            <i class="el-icon-warning"
                               style="margin-left: 3px; font-size: 14px"></i>
                        </el-tooltip>
                    </template>
                    <template slot="expand" slot-scope="props">
                        <el-table :data="props.row.Details" style="width: 100%" :header-cell-style="tableHeaderColor">
                            <el-table-column type="index"></el-table-column>
                            <el-table-column prop="Speed" align="center" :label="$t('DEGREE OF COMPLETION')">
                                <template slot-scope="{row,index,size,type}">
                                    <el-tooltip class="item" effect="light" placement="bottom-start">
                                        <div slot="content" v-for="(item,index) in row.SpeedContent" v-bind:key="index">{{formatColumn(item)}}</div>
                                        <el-link type="warning">{{row.Speed}}</el-link>
                                    </el-tooltip>
                                </template>
                            </el-table-column>
                            <el-table-column prop="BusinessNoIn" :label="$t('BUSINESS SERIAL NUMBER INTERNAL')"></el-table-column>
                            <el-table-column v-if="showOut" prop="BusinessNoOut" :label="$t('BUSINESS SERIAL NUMBER EXTERNAL')"></el-table-column>
                            <el-table-column prop="Code" :label="$t('POProductCode')" width="120"></el-table-column>
                            <el-table-column prop="OutProductName" :label="$t('POProductName')"></el-table-column>
                            <el-table-column prop="Batch" :label="$t('POBATCH')"></el-table-column>
                            <el-table-column v-if="showOut" prop="Number" :label="$t('Number')"></el-table-column>
                            <el-table-column v-if="showOut" prop="UnitName" :label="$t('UNIT')"></el-table-column>
                            <el-table-column prop="Color" :label="$t('COLOR')"></el-table-column>
                            <el-table-column prop="ProductName" :label="$t('InternalProductName')"></el-table-column>
                            <el-table-column prop="DestinationCountryName" :label="$t('MARKET')">
                            </el-table-column>
                            <el-table-column prop="Status" align="center" :label="$t('STATUS')" :formatter="formatterColumn"  v-if="!isCanAgent"></el-table-column>
                            <el-table-column align="center" prop="TrackingApproveStatus" :label="$t('STATUS')" :formatter="formatterTrackingStatus" v-if="isCanAgent"></el-table-column>
                            <el-table-column align="center" :label="$t('UPDATE TIME')" prop="LinkageUpdateDateTime" :formatter="formatDate"> </el-table-column>
                            <el-table-column width="200px;" align="center" :label="$t('OPERATION')">
                                <template slot-scope="{row,index,size,type}">
                                    <el-button icon="el-icon-coin" type="text" size="medium" :title="$t('Proportion of raw materials')" @click="fibred(row,index,size,type)"></el-button>
                                    <el-button icon="icon-shangchuan" type="text" size="medium" :title="$t('Upload')" @click="upload(row,index,size,type)"></el-button>
                                    <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')" @click="editSOinfo(row,index,size,type)"></el-button>
                                    <el-button icon="el-icon-thumb" type="text" size="medium" :title="$t('VIEW AUDIT INFO')" v-if="row.ApproveStatus<0" @click="ViewAuditInfo(row,index,size,type)"></el-button>
                                    <el-button icon="icon-guanlian" type="text" size="medium" :title="$t('Relevance')" @click="rele(row,index,size,type)"></el-button>
                                    <el-button icon="icon-dingwei1" type="text" size="medium" :title="$t('Tracing')" @click="tracingPur(row)"></el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                    <template slot-scope="{row,index,type}" slot="menuForm">
                        <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" v-if="type=='add'" @click="$refs.crud.rowSave()">{{$t("Hold")}}</el-button>
                        <el-button type="primary" icon="el-icon-circle-check" size="small" v-if="type=='edit'" @click="$refs.crud.rowUpdate()">{{$t("Hold")}}</el-button>
                        <el-button icon="el-icon-circle-close" size="small" @click="$refs.crud.closeDialog()">{{$t("Cancel")}}</el-button>
                    </template>
                </avue-crud>
                <el-dialog :title="$t('ChooseUploadBrand')" :visible.sync="BranddialogTableVisible" :modal-append-to-body="false" width="30%" top="1vh">
                    <el-form-item :label="$t('Brand')" label-width="120px">
                        <el-select v-model="brandChoose" filterable placeholder="" style="width: calc(100% - 115px);">
                            <el-option v-for="item in brandList"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <div style="text-align:center;padding:50px 0;">
                        <el-button type="primary" @click="submitBrandChoose();BranddialogTableVisible = false;">{{$t('Yes')}}</el-button>
                        <el-button @click="BranddialogTableVisible = false">{{$t('Cancel')}}</el-button>
                    </div>
                    <el-form-item>

                    </el-form-item>
                </el-dialog>
                <el-dialog :title="$t('EditSOinformation')" :visible.sync="SoDetaildialogTableVisible" :modal-append-to-body="false" width="70%" top="1vh">
                    <el-form :model="SoDetailEdit" :rules="rules" label-position="top" label-width="150px">
                        <el-row>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-tooltip class="item" effect="dark" :content="$t('POProductCode')" placement="top-start">
                                    <el-form-item :label="$t('POProductCode')">
                                        <el-input v-model="SoDetailEdit.Code" style="width: calc(100% - 115px);" v-bind:disabled="true"></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-tooltip class="item" effect="dark" :content="$t('POProductName')" placement="top-start">
                                    <el-form-item :label="$t('POProductName')">
                                        <el-input v-model="SoDetailEdit.OutProductName" style="width: calc(100% - 115px);" v-bind:disabled="true"></el-input>
                                    </el-form-item>
                                </el-tooltip>

                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-tooltip class="item" effect="dark" :content="$t('BUSINESS SERIAL NUMBER INTERNAL')" placement="top-start">
                                    <el-form-item :label="$t('BUSINESS SERIAL NUMBER INTERNAL')">
                                        <el-input v-model="SoDetailEdit.BusinessNoIn" style="width: calc(100% - 115px);" v-bind:disabled="true"></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-tooltip class="item" effect="dark" :content="$t('BUSINESS SERIAL NUMBER EXTERNAL')" placement="top-start">
                                    <el-form-item :label="$t('BUSINESS SERIAL NUMBER EXTERNAL')" prop="BusinessNoOut">
                                        <el-input v-model="SoDetailEdit.BusinessNoOut" style="width: calc(100% - 115px);"></el-input>
                                    </el-form-item>
                                </el-tooltip>

                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-tooltip class="item" effect="dark" :content="$t('POBATCH')" placement="top-start">
                                    <el-form-item :label="$t('POBATCH')">
                                        <el-input v-model="SoDetailEdit.Batch" style="width: calc(100% - 115px);" v-bind:disabled="true"></el-input>
                                    </el-form-item>
                                </el-tooltip>

                            </el-col>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-tooltip class="item" effect="dark" :content="$t('InternalProductName')" placement="top-start">
                                    <el-form-item :label="$t('InternalProductName')">
                                        <el-select v-model="SoDetailEdit.ProductCode" filterable allow-create default-first-option style="width: calc(100% - 115px);" placeholder="" @change="selectChanged">
                                            <el-option v-for="item in productData"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>

                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item :label="$t('Number')">
                                    <el-input-number v-model="SoDetailEdit.Number" style="width: calc(100% - 115px);" v-bind:disabled="true"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item :label="$t('UNIT')">
                                    <el-input v-model="SoDetailEdit.UnitName" style="width: calc(100% - 115px);" v-bind:disabled="true"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item :label="$t('COLOR')">
                                    <el-input v-model="SoDetailEdit.Color" style="width: calc(100% - 115px);" v-bind:disabled="true"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12">

                            </el-col>
                        </el-row>
                        <div style="text-align:center;padding:50px 0;">
                            <el-button type="primary" v-if="!isLocked" @click="submitInfoSO();SoDetaildialogTableVisible = false;">{{$t('Yes')}}</el-button>
                            <el-button @click="SoDetaildialogTableVisible = false">{{$t('Cancel')}}</el-button>
                        </div>
                    </el-form>
                </el-dialog>
                <el-dialog :title="$t('Proportion of raw materials')" :visible.sync="fibredialogTableVisible" :modal-append-to-body="false" width="70%" top="20vh">
                    <avue-crud ref="crudComponent" v-model="objComponent" :option="setDataComponent.tableOpt" :data="tableDataComponent" :row-style="rowStyle" :page.sync="pageComponent" @current-change="currentComponentChange" @row-update="handleComponentUpdate" @row-del="handDel" @row-save="handleSave">
                        <template slot="CategoryForm" scope="scope">
                            <el-autocomplete v-model="objComponent.Category"
                                             :fetch-suggestions="SearchCategoryInfo" style="width:100%;"></el-autocomplete>
                        </template>
                        <template slot="menuLeft" style="width: calc(100% - 80px);">
                            <el-row :gutter="20">
                                <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
                                    <el-button type="primary" size="small" icon="el-icon-plus" style="margin-left:10px;"  v-if="!isLocked" @click="$refs.crudComponent.rowAdd();parentId='0';isParent=true;">{{$t('ADD')}}</el-button>
                                    <el-tag size="small">{{$t('component such as')}}</el-tag>
                                </el-col>
                            </el-row>
                        </template>
                        <template slot-scope="{row,index,size,type}" slot="menu"  >
                            <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')" v-if="row.ParentId!='0'&&!isLocked" @click="$refs.crudComponent.rowEdit(row,index);isParent=false;"></el-button>
                            <el-button icon="el-icon-delete-solid" type="text" size="medium" :title="$t('Delete')" v-if="!isLocked" @click="handDel(row,index)"></el-button>
                            <el-button icon="el-icon-plus" type="text" size="medium" :title="$t('Add Details')" v-if="row.ParentId=='0'&&!isLocked" @click="$refs.crudComponent.rowAdd();parentId=row.Id;isParent=false;"></el-button>
                        </template>
                        <template slot-scope="{row,index,type}" slot="menuForm">
                            <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" v-if="type=='add'" @click="$refs.crudComponent.rowSave()">{{$t("Hold")}}</el-button>
                            <el-button type="primary" icon="el-icon-circle-check" size="small" v-if="type=='edit'" @click="$refs.crudComponent.rowUpdate()">{{$t("Hold")}}</el-button>
                            <el-button icon="el-icon-circle-close" size="small" @click="$refs.crudComponent.closeDialog()">{{$t("Cancel")}}</el-button>
                        </template>
                    </avue-crud>
                </el-dialog>
                <el-dialog :title="$t('REJECT')" :visible.sync="RejectdialogTableVisible" :modal-append-to-body="false" width="70%" top="1vh">
                    <el-form :model="RejectEdit" :rules="Rejectrules" ref="Rejectrules" label-position="top" label-width="150px" v-if="!isViewAudit">
                        <el-row>
                            <el-col :span="24" :sm="24" :md="24">
                                <el-form-item :label="$t('RESUBMIT DESCRIPTION')" :required="true" prop="Remarks" :rules="Rejectrules.Remarks">
                                    <el-input v-model="RejectEdit.Remarks" style="width: calc(100% - 115px);" type="textarea" maxlength="300" show-word-limit :autosize="{ minRows: 4, maxRows:6}"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div style="text-align:center;padding:20px 0;">
                            <el-button type="primary" @click="submitReject('Rejectrules');">{{$t('Yes')}}</el-button>
                            <el-button @click="RejectdialogTableVisible = false">{{$t('Cancel')}}</el-button>
                        </div>
                    </el-form>
                    <avue-crud :option="tableOptRejectList" :data="tableDataRejectList" :row-style="rowStyle" :page.sync="pageRejectList" @current-change="currentRejectListChange">
                    </avue-crud>
                </el-dialog>
            </template>
        </basic-container>
    </div>
</template>
<style>
    .el-dialog__wrapper {
        transition-duration: 0.3s;
    }

    .othChe .el-checkbox__label {
        color: #409eff !important;
    }
    
    .el-table tr .el-table__expanded-cell {
        padding: 0 0 0 60px !important;
    }

    .dataicon i {
        display: none;
    }
    .el-icon-arrow-right:before {
        content: "\E6E0";
        font-weight: bold;
        color: Tomato;
        font-size: 15px;
    }
</style>
<script>
    import { saleOrderList, editSale, editDetailSO, editHeadSO } from "@/api/salesOrd";
    import { delHead,  SoListByPoId } from "@/api/purchaseOrd";
    import { ProcessDropDownList, TracingDropDownList, TracingUnifiedIsBool, ProcessAddProduct, Getguid, corpinfo, TracingStringList} from "@/api/unified";
    import { List, add, edit, del } from "@/api/saleComponent";
    import { ListApproves, addResubmitsSo } from "@/api/orderApprove";
    export default {
        data() {
            var valiBusinessNoOut = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    callback();
                } else {
                    var Detailid = this.SoDetailEdit.SoDetailId;
                    TracingUnifiedIsBool("SaleOrderDetail_BusinessNoOutExist", Detailid, value).then(res => {
                        var IsExist = res.data;
                        if (!IsExist) {
                            callback(new Error(this.$t('BUSINESS SERIAL NUMBER EXTERNAL') + ' ' + this.$t('Exist')));
                        } else {
                            callback();
                        }
                    }).catch((erro) => { console.log(erro) });
                }
            };
            return {
                showOut : false,
                obj: {},
                isCanAgent: false, corpId: '',
                BranddialogTableVisible: false, SoDetaildialogTableVisible: false, fibredialogTableVisible: false, 
                RejectdialogTableVisible: false, tableDataRejectList: [], RejectCategoryData: [], isViewAudit: false, 
                RejectEdit: {
                    DetailOrderId: '',
                    Remarks: '',
                },
                Rejectrules: {
                    Remarks: [{ required: true, message: this.$t('Required'), trigger: 'blur' }],
                },
                SoDetailEdit: {
                    SoId: "",
                    SoDetailId: "",
                    Code: "",
                    OutProductName: "",
                    OutProductCode: "",
                    Batch: "",
                    ProductName: "",
                    ProductCode: "",
                    Number: 0,
                    UnitName: "",
                    Color: "",
                    Ingredient: "",
                    Remark: "",
                    BusinessNoIn: "",
                    BusinessNoOut: "",
                },
                rules: {
                    BusinessNoOut: [{ validator: valiBusinessNoOut, trigger: 'blur' }],
                },
                productData: [],//产品
                soVal: "",
                fromVal: {
                    ids: [],
                },
                otherStatusVal:"",
                otherStatusData: [
                    {
                        label: this.$t('declaration reject'),
                        value: "14"
                    },
                    {
                        label: this.$t('declaration resubmit'),
                        value: "18"
                    },
                    {
                        label: this.$t('Locked'),
                        value: "80"
                    },
                    {
                        label: this.$t('declaration completed'),
                        value: "90"
                    }],
                traingStatusVal: "",
                traingStatusData: [
                    {
                        label: this.$t('declaration reject'),
                        value: "-4"
                    },
                    {
                        label: this.$t('Pending'),
                        value: "1"
                    },
                    {
                        label: this.$t('declaration resubmit'),
                        value: "-1"
                    },
                    {
                        label: this.$t('declaration completed'),
                        value: "100"
                    }],
                traingData: [
                    {
                        label: this.$t('initial'),
                        value: 0
                    },
                    {
                        label: this.$t('Pending'),
                        value: 1
                    },
                    {
                        label: this.$t('declaration reject'),
                        value: -4
                    },
                    {
                        label: this.$t('declaration reject'),
                        value: -1
                    },
                    {
                        label: this.$t('declaration completed'),
                        value: 100
                    }],
                StatusVal: false,
                businessnoinVal: "",
                businessnooutVal: "",
                codeVal: "",
                colorVal: "",
                batchVal: "",
                comVal: "",
                beginTimeVal: "",
                endTimeVal: "",
                UTbeginTimeVal: "",
                UTendTimeVal: "",
                checked: true,
                isLocked: false,
                tableData: [],
                sectionData: [],
                fromData: [],//采购商
                destinationCountryData: [],//销售目的国
                CategoryData: [],
                affCorpCollectionData: [],//关联合同签署公司 
                affCorpWorkingData: [],//关联加工工厂
                brandList: [],
                brandChoose: "",
                soDetailId: "",
                vendorSectionId: "",
                stateData: [
                    {
                        label: this.$t('Delete'),
                        value: 1
                    },
                    {
                        label: this.$t('initial'),
                        value: 0
                    },
                    {
                        label: this.$t('under order'),
                        value: 5
                    }
                    ,
                    {
                        label: this.$t('under declaration'),
                        value: 10
                    },
                    {
                        label: this.$t('declaration reject'),
                        value: 14
                    },
                    {
                        label: this.$t('declaration resubmit'),
                        value: 18
                    },
                    {
                        label: this.$t('declaration locking'),
                        value: 20
                    },
                    {
                        label: this.$t('Locked'),
                        value: 80
                    },
                    {
                        label: this.$t('declaration completed'),
                        value: 90
                    }],
                 page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background: true,
                    pageSize: 10
                },
                parentId: "0",
                isParent: true,
                rawFiberData: [],
                tableDataComponent: [],
                objComponent: {},
                pageComponent: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background: true,
                    pageSize: 10
                },
                pageRejectList: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background: true,
                    pageSize: 10
                },
                tableOptRejectList: {
                    page: false,
                    refreshBtn: false,
                    columnBtn: false,
                    simplePage: true,
                    addBtn: false,
                    addRowBtn: false,
                    editBtn: false,
                    delBtn: false,
                    cellBtn: false,
                    index: true,
                    menu: false,
                    indexLabel: ' ',
                    border: true,
                    align: "center",
                    emptyText: this.$t('No Data'),
                    tip: false,
                    column: [
                        {
                            label: this.$t('DATE'),
                            prop: 'CreateTime',
                            type: "date",
                            format: 'yyyy-MM-dd',
                            valueFormat: 'yyyy-MM-dd',
                        },
                        {
                            label: this.$t('REJECTED COMPANY'),
                            prop: 'ApprovedObject',
                        },
                        {
                            label: this.$t('REJECTION CATEGORY'),
                            prop: 'RejectCategory',
                        },
                        {
                            label: this.$t('REJECTION DESCRIPTION'),
                            prop: 'RejectRemark',
                        },
                        {
                            label: this.$t('RESUBMISSION INSTRUCTIONS'),
                            prop: 'Remarks',
                        },
                    ]
                }
            }
        },
        computed: {
            setData() {
                return {
                    tableOpt: {
                        page: true,
                        addBtn: false,
                        refreshBtn: false,
                        emptyText: this.$t('No Data'),
                        menu: true,
                        menuTitle: this.$t('OPERATION'),
                        menuWidth: 200,
                        editBtn: false,
                        editBtnText: this.$t('Edit'),
                        editTitle: this.$t('Edit'),
                        delBtn: false,
                        align: 'center',
                        header: false,
                        labelWidth: "33%",
                        expand: true,
                        rowKey: 'Id',
                        expandRowKeys: [],
                        saveBtn: false,
                        updateBtn: false,
                        cancelBtn: false,
                        column: [
                            {
                                label: this.$t('DEGREE OF COMPLETION'),
                                prop: 'Speed',
                                slot: true
                            },
                            {
                                label: this.$t('SO'),
                                prop: 'So',
                                placeholder: ' ',
                            },
                            {
                                label: this.$t('VendorSection'),
                                prop: 'VendorSectionId',
                                type: "select",
                                filterable: true,
                                dicData: this.sectionData,
                                placeholder: ' ',
                                editDisabled: true
                            },
                            {
                                label: this.$t('FROM'),
                                prop: 'PurchaseCorporationId',
                                placeholder: ' ',
                                type: "select",
                                filterable: true,
                                dicData: this.fromData,
                                editDisabled: true

                            },
                            {
                                label: this.$t('MARKET'),
                                prop: 'DestinationCountry',
                                placeholder: ' ',
                                type: "select",
                                filterable: true,
                                multiple: true,
                                headerslot: true,
                                dicData: this.destinationCountryData,
                                editDisabled: true
                            },
                            {
                                label: this.$t('POBATCH'),
                                prop: 'Batch',
                                hide: true,
                                placeholder: ' ',
                                editDisabled: true
                            },
                            {
                                label: this.$t('CONTRACT SIGNING COMPANY'),
                                prop: 'AffiliatedCorpCollection',
                                type: "select",
                                placeholder: ' ',
                                hide: true,
                                filterable: true,
                                dicData: this.affCorpCollectionData,

                            },
                            {
                                label: this.$t('ASSOCIATED PROCESSING PLANT'),
                                prop: 'AffiliatedCorpWorking',
                                type: "select",
                                placeholder: ' ',
                                hide: true,
                                filterable: true,
                                dicData: this.affCorpWorkingData, 

                            },
                            {
                                label: this.$t('OUT PURCHASE ORDER NO'),
                                prop: 'PurchaserPo',
                                placeholder: ' ',
                                labelTip: this.$t('OUT PURCHASE ORDER NO'),
                                formslot: true,
                                filterable: true,
                                editDisabled: true
                            },
                            {
                                label: this.$t('ORDER DATE'),
                                prop: 'OrderDate',
                                type: "date",
                                placeholder: ' ',
                                format: 'yyyy-MM-dd',
                                valueFormat: 'yyyy-MM-dd',  
                                editDisabled: true
                            },
                            {
                                label: this.$t('STATE'),
                                prop: 'Status',
                                placeholder: ' ',
                                type: "select",
                                editDisplay: false,
                                hide:this.isCanAgent,
                                dicData: this.stateData,

                            },
                            {
                                label: this.$t('STATE'),
                                prop: 'TrackingApproveStatus',
                                placeholder: ' ',
                                type: "select",
                                hide: !this.isCanAgent,
                                editDisplay: false,
                                dicData: this.traingData,
                            },
                            {
                                label: this.$t('UPDATE TIME'),
                                prop: 'LinkageUpdateDateTime',
                                type: "date",
                                editDisplay: false,
                                format: "yyyy-MM-dd HH:mm:ss",
                                valueFormat: "yyyy-MM-dd HH:mm:ss",
                            },
                            {
                                label: this.$t('PODivision'),
                                placeholder: ' ',
                                prop: 'Division',
                                hide: true,
                                editDisabled: true
                            }
                        ]
                    }
                }
            },
            setDataComponent() {
                var valiCategoryExist = (rule, value, callback) => {
                    if ((value != '' || value != null)) {
                        var ids = this.objComponent.id;
                        if (this.objComponent.id == undefined) {
                            ids = "";
                        }
                        TracingUnifiedIsBool("SaleComponent_IsCategoryExist", ids, value, this.soDetailId).then(resu => {
                            var IsExist = resu.data;
                            if (!IsExist) {
                                callback(new Error(this.$t('CATEGORYS') + " " + this.$t('Exist')));
                            } else { callback(); }
                        }).catch(() => { callback(); });
                    } else {
                        callback();
                    }
                };
                var valiRawFiberTypeIdExist = (rule, value, callback) => {
                    if ((value != '' || value != null)) {
                        var ids = this.objComponent.id;
                        if (this.objComponent.id == undefined) {
                            ids = "";
                        }
                        TracingUnifiedIsBool("SaleComponent_IsRawFiberTypeIdExist", ids, value, this.parentId).then(resu => {
                            var IsExist = resu.data;
                            if (!IsExist) {
                                callback(new Error(this.$t('FIBER TYPE') + " " + this.$t('Exist')));
                            } else { callback(); }
                        }).catch(() => { callback(); });
                    } else {
                        callback();
                    }
                };
                var valiNumbPass = (rule, value, callback) => {
                    if (Number(value) > 0) {
                        callback();
                    } else {
                        callback(new Error(this.$t('NeedGreater0')));
                    }
                };
                var valiRatio = (rule, value, callback) => {
                    if (Number(value) >100) {
                        callback(new Error(this.$t('Cannot be greater than 100')));
                        } else { callback(); }
                };
                return {
                    tableOpt: {
                        page: true,
                        refreshBtn: false,
                        delBtn: false,
                        emptyText: this.$t('No Data'),
                        menuTitle: this.$t('OPERATION'),
                        menuWidth: 140,
                        align: 'center',
                        columnBtn: false,
                        editTitle: this.$t('Edit'),
                        addTitle: this.$t('Add'),
                        addBtn: false,
                        editBtn: false,
                        header: true,
                        labelWidth: "30%",
                        simplePage: true,
                        border: true,
                        index: true,
                        defaultExpandAll: true,
                        saveBtn: false,
                        updateBtn: false,
                        cancelBtn: false,
                        rowKey: 'Id',
                        column: [
                            {
                                label: this.$t('CATEGORYS'),
                                prop: 'Category',
                                formslot: true,
                                placeholder: ' ',
                                editDisplay: this.isParent,//页面 隐藏
                                addDisplay: this.isParent,
                                rules: [{ required: true, message: this.$t('Required'), trigger: 'blur' },
                                    { validator: valiCategoryExist, trigger: 'blur' } ]
                            },
                            {
                                label: this.$t('FIBER TYPE'),
                                prop: 'RawFiberTypeId',
                                placeholder: ' ',
                                editDisplay: !this.isParent,//页面 隐藏
                                addDisplay: !this.isParent,
                                type: "select",
                                filterable: true,
                                dicData: this.rawFiberData,
                                rules: [{ required: true, message: this.$t('FIBER TYPE'), trigger: 'blur' },
                                    { validator: valiRawFiberTypeIdExist, trigger: 'blur' }]
                            },
                            {
                                label: this.$t('RATIO'),
                                prop: 'Ratio',
                                placeholder: ' ',
                                type: 'number',
                                editDisplay: !this.isParent,//页面 隐藏
                                addDisplay: !this.isParent,
                                rules: [ 
                                    { type: "number", required: true,  validator: valiNumbPass, trigger: 'blur' },
                                    { type: "number", validator: valiRatio, trigger: 'blur' }]
                            },
                            {
                                label: this.$t('REMARK'),
                                placeholder: ' ',
                                prop: 'Remarks',
                            },
                            {
                                label: this.$t('STATE'),
                                prop: 'Status',
                                hide: true,
                                editDisplay: false,//页面 隐藏
                                addDisplay: false,
                            },
                            {
                                label: this.$t('STATE'),
                                prop: 'SoDetailId',
                                hide: true,
                                editDisplay: false,//页面 隐藏
                                addDisplay: false,
                            },
                            {
                                label: this.$t('STATE'),
                                prop: 'ParentId',
                                hide: true,
                                editDisplay: false,//页面 隐藏
                                addDisplay: false,
                            },
                        ]
                    },
                }
            }
        },
        created() {
            this.getSaleOrderList();
            TracingDropDownList('all_VendorSectionList').then(res => {
                this.sectionData = res.data;
            });
            TracingDropDownList('FromList').then(res => {
                this.fromData = res.data;
            });   
            ProcessDropDownList('ProductList').then(res => {
                this.productData = res.data;
            });
            // 关联公司TYPE--0: 全部信息1：实际收款, 2实际付款，3实际加工   
            TracingDropDownList('AffiliatedCorp','', 1).then(res => {
                this.affCorpCollectionData = res.data;
            });
            TracingDropDownList('AffiliatedCorp','', 3).then(res => {
                this.affCorpWorkingData = res.data;
            });
            TracingDropDownList('RawFiberType').then(res => {
                this.rawFiberData = res.data;
            });
            TracingUnifiedIsBool("BrandBusiness_AgenEntryPoExist").then(res => {
                this.isCanAgent = res.data;
            })
            TracingDropDownList('DestinationCountryData').then(res => {
                this.destinationCountryData = res.data;
            });

            corpinfo().then(res => {
                this.corpId = res.data.id;
            })
        },
        methods: {
            //设置表头行的样式
            tableHeaderColor() {
                return 'background-color:#838bb3!important;padding: 0!important;'

            }, //表格行央视
            rowStyle({ rowIndex }) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
            },
            focusDiv(obj) {//搜索框获取焦点
                if (obj.currentTarget === undefined) {
                    obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                } else {
                    if (obj.currentTarget.parentElement.getAttribute('class').indexOf('suffix') !== -1) {
                        obj.currentTarget.parentElement.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                    } else {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                    }

                }
            },//搜索框失去焦点
            blurDiv(obj) {
                if (obj.currentTarget === undefined) {
                    if (obj.value === "") {
                        obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                } else if (obj.currentTarget === null) {
                    if (this.fromVal.ids.length === 0) {
                        document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                    if (this.otherStatusVal === 0) {
                        document.querySelector("#otherStatusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                    if (this.traingStatusVal === 0) {
                        document.querySelector("#traingStatusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                } else {
                    if (obj.target.value == "") {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                }
            },
            formatterColumn(row) {
                var s_Status = "";
                switch (row.Status) {
                    case -1:
                        s_Status= this.$t('Delete');
                        break;
                    case 0:
                        s_Status = this.$t('initial');
                        break;
                    case 5:
                        s_Status = this.$t('under order');
                        break;
                    case 10:
                        s_Status = this.$t('under declaration');
                        break;
                    case 14:
                        s_Status = this.$t('declaration reject');
                        break;
                    case 18:
                        s_Status = this.$t('declaration resubmit');
                        break;
                    case 20:
                        s_Status = this.$t('declaration locking');
                        break;
                    case 80:
                        s_Status = this.$t('Locked');
                        break;
                    case 90:
                        s_Status = this.$t('declaration completed');
                        break;
                    default:
                        s_Status = this.$t('unknown');
                }
                return s_Status;
            },
            formatterTrackingStatus(row) {
                var s_Status = "";
                switch (row.TrackingApproveStatus) {
                    case -1:
                        s_Status = this.$t('declaration reject');
                        break;
                    case 0:
                        s_Status = this.$t('initial');
                        break;
                    case -4:
                        s_Status = this.$t('declaration reject');
                        break;
                    case 1:
                        s_Status = this.$t('Pending');
                        break;
                    case 100:
                        s_Status = this.$t('declaration completed');
                        break;
                    default:
                        s_Status = this.$t('unknown');
                }
                return s_Status;
            },
            venchang() {
                if (this.fromVal.ids.length === 0) {
                    document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                } else {
                    document.querySelector("#venSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                }
            },//点击更多按钮
            otherStatuschang() {
                if (this.otherStatusVal === 0) {
                    document.querySelector("#otherStatusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                } else {
                    document.querySelector("#otherStatusSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                }
            },//点击更多按钮
            traingStatuschang() {
                if (this.traingStatusVal === 0) {
                    document.querySelector("#traingStatusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                } else {
                    document.querySelector("#traingStatusSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                }
            },//点击更多按钮
            moreFrom() {
                let fromHei = document.querySelector(".fromOutDiv").clientHeight;
                if (fromHei > 60) {
                    document.querySelector(".fromOutDiv").style.cssText = "height:60px;";
                } else {
                    document.querySelector(".fromOutDiv").style.cssText = "height:auto;";
                }
            },
            //编辑数据
            handleUpdate(row, index, done) {
                editSale(row).then(() => {
                    this.$message({
                        showClose: true,
                        message: this.$t('UpdateSuccess'),
                        type: "success"
                    });
                    this.getSaleOrderList();
                    done();
                })
            },
            selectionChange(list) {
                list != "" ? this.disabled = false : this.disabled = true;
            },
            search() {
                this.getSaleOrderList();
            },
            currentChange(val) {
                this.page.currentPage = val
                this.getSaleOrderList();
            },
            async getSaleOrderList() {
                await saleOrderList(this.page.currentPage, this.page.pageSize, this.businessnoinVal, this.businessnooutVal, this.soVal, this.fromVal.ids.toString(), this.codeVal, this.batchVal, this.colorVal, this.comVal, this.beginTimeVal, this.endTimeVal, this.StatusVal, this.otherStatusVal, this.traingStatusVal, this.UTbeginTimeVal, this.UTendTimeVal).then(res => {
                    this.tableData = res.data.SaleOrders;
                    this.page.total = res.data.TotalCount;
                    this.page.pageSize = res.data.PageSize;
                }).catch(erro => {
                    console.log(erro)
                })
            },//列表
            rele(row) {
                if (row.Status == 1) { this.$message({ showClose: true, message: this.$t('NotCanDeleted'), type: "warning" }); return; }
                this.$router.push({ path: '/pvh_factory/materialOrd', query: { soDetailid: row.Id, soid: row.SaleOrderId } })
            },//上传
            upload(row) {
                this.vendorSectionId = row.VendorSectionId;
                //验证此销售单是否有SO及成分
                TracingUnifiedIsBool('IsWriteSOComponent', row.Id).then(res => {
                    var IsExist = res.data;
                    if (!IsExist) {
                        this.$message({
                            message: this.$t('Please SO filled in'),
                            type: "error"
                        });
                    }
                });
                //上传前判断是否有品牌商信息
                TracingDropDownList('OrderBrandTrackList', row.PurchaserOrderDetailId).then(res => {
                    this.brandList = res.data;
                    if (this.brandList.length > 0) {
                        if (this.brandList.length == 1) {
                            this.$router.push({ path: '/pvh_factory/declarationEntry', query: { orderId: row.Id, brandBusinessId: this.brandList[0].value, vendorSectionId: this.vendorSectionId, isPO: 'false' } })
                        }
                        else {
                            this.soDetailId = row.Id;
                            this.BranddialogTableVisible = true;
                        }
                    }
                    else {
                        this.$message({
                            message: this.$t('NoBrandInfo'),
                            type: "warning"
                        });
                        return;
                    }
                });
            },
            submitBrandChoose() {
                if (this.brandChoose == "") {
                    this.$message({
                        message: this.$t('PleaseChooseBrand'),
                        type: "warning"
                    });
                    return;
                }
                else
                {
                    this.$router.push({ path: '/pvh_factory/declarationEntry', query: { orderId: this.soDetailId, brandBusinessId: this.brandChoose, vendorSectionId: this.vendorSectionId, isPO: 'false'} })
                }

            },//提交修改项
            editSOinfo(row) {
                this.SoDetaildialogTableVisible = true;
                this.SoDetailEdit.SoId = row.SaleOrderId;
                this.SoDetailEdit.SoDetailId = row.Id;
                this.SoDetailEdit.OutProductName = row.OutProductName;
                this.SoDetailEdit.OutProductCode = row.OutProductCode;
                this.SoDetailEdit.ProductCode = row.ProductCode;
                this.SoDetailEdit.ProductName = row.ProductName;
                this.SoDetailEdit.Number = row.Number;
                this.SoDetailEdit.UnitName = row.UnitName;
                this.SoDetailEdit.Color = row.Color;
                this.SoDetailEdit.Ingredient = row.Ingredient;
                this.SoDetailEdit.Remark = row.Remark;
                this.SoDetailEdit.Code = row.Code;
                this.SoDetailEdit.Batch = row.Batch;
                this.SoDetailEdit.UnitName = row.UnitName;
                this.SoDetailEdit.BusinessNoIn = row.BusinessNoIn;
                this.SoDetailEdit.BusinessNoOut = row.BusinessNoOut;
                var ApproveStatus = row.ApproveStatus;
                var LockedStatus = row.LockedStatus;
                if (ApproveStatus == 100 || ApproveStatus == 50 || LockedStatus == 1) {
                    this.isLocked = true;
                }
            },//弹出修改框
            submitInfoSO() {
                let sorow = {
                    Id: this.SoDetailEdit.SoId,
                    DetailId: this.SoDetailEdit.SoDetailId,
                    So: "",
                    ProductCode: this.SoDetailEdit.ProductCode,
                    ProductName: this.SoDetailEdit.ProductName,
                    Remark: this.SoDetailEdit.Remark,
                    BusinessNoOut: this.SoDetailEdit.BusinessNoOut,
                };
                editDetailSO(sorow).then(() => {
                    this.$message({
                        message: this.$t('OperationSuccess'),
                        type: "success"
                    });
                    this.getSaleOrderList();
                }).catch((erro) => { console.log(erro) });

            },//提交修改项
            selectChanged(value) {
                var objrow = {};
                objrow = this.productData.find((i) => {//这里的List就是上面遍历的数据源
                    return i.value === value;//筛选出匹配数据
                });
                if (objrow == undefined) {
                    var Productid = Getguid();
                    var Productcode = value;
                    var Productname = value;
                    //创建物料接口
                    let paramrow = {
                        id: Productid,
                        name: Productname,
                        code: Productcode,
                        status: 0,
                        enableBatch: 1,
                        corporationId: this.corpId,
                        auditStatus: 1,
                        isStandard: 0,
                        isAutomaticAdd: 1,
                    };
                    ProcessAddProduct(paramrow).then(res => {
                        let newItem = { value: Productid, label: Productname };
                        this.productData.unshift(newItem);
                        this.SoDetailEdit.ProductCode = Productid;
                        this.SoDetailEdit.ProductName = Productname;
                    }
                    ).catch((erro) => { console.log(erro) });
                } else {
                    this.SoDetailEdit.ProductName = objrow.label;
                }
            }, 
            fibred(row) {
                var ApproveStatus = row.ApproveStatus;
                var LockedStatus = row.LockedStatus;
                if (ApproveStatus == 100 || ApproveStatus == 50 || LockedStatus == 1) {
                    this.isLocked = true;
                }
                this.fibredialogTableVisible = true;
                this.soDetailId = row.Id; 
                this.CategoryData = [];
                TracingStringList("ClassMainRawMaterials", row.VendorSectionId).then(res => {
                    for (var i = 0; i < res.data.length; i++) {
                        this.CategoryData.push({
                            value: res.data[i]
                        })
                    }
                });
                console.log("this.CategoryData", this.CategoryData);
                this.GetList();
            },
            currentComponentChange(val) {
                this.pageComponent.currentPage = val;
                this.GetList();
            },//搜索
            searchFrom() {
                this.GetList();
            },//编辑数据
            handleComponentUpdate(row, index, done) {
                edit(row).then(() => {
                    this.$message({ showClose: true, message: this.$t('UpdateSuccess'), type: "success" });
                    this.GetList();
                    this.objComponent = {};
                    this.getSaleOrderList();
                    done();
                })
            },//添加数据
            handleSave(row, done) {
                row.SoDetailId = this.soDetailId;
                row.Status = 0;
                row.ParentId = this.parentId
                add(row).then(() => {
                    this.$message({ showClose: true, message: this.$t('AddSuccess'), type: "success" });
                    this.GetList();
                    this.objComponent = {};
                    this.getSaleOrderList();
                    done();
            
      
                })
            },
            //删除数据
            handDel(row, done) {
                this.$confirm(this.$t('IsDel') , this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                    del(row.Id).then(() => {
                        this.GetList();
                        this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                        this.getSaleOrderList();
                    })
                    done();
                })
            },//获取列表显示出数据
            async GetList() {
                List(this.pageComponent.currentPage, this.pageComponent.pageSize, this.soDetailId).then(res => {
                    this.tableDataComponent = res.data.SaleComponents;
                    this.pageComponent.total = res.data.TotalCount;
                    this.pageComponent.pageSize = res.data.PageSize;
                }).catch(erro => {
                    console.log(erro);
                })
            },
            addPurOrd() {
                this.$router.push({ path: '/pvh_factory/purchaseAdd_AgenEntryPo', query: { sign: 'add', ID: '', corpid: '', systemBatchNo: '' } })
            },//新增数据
            editPur(row) {
                this.$router.push({ path: '/pvh_factory/purchaseAdd_AgenEntryPo', query: { sign: 'edit', ID: row.PurchaserOrderId, corpid: row.PurchaseCorporationId, systemBatchNo:'' } })
            },
            ViewAuditInfo(row) {
                var info = row;
                this.RejectdialogTableVisible = true;
                this.RejectEdit.DetailOrderId = info.Id;
                this.RejectEdit.Remarks = '';
                this.getRejectList();
                if (info.ApproveStatus <0) {
                    this.isViewAudit = false;
                }
                else {
                    this.isViewAudit = true;
                }
            },
            submitReject(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let paramrow = {
                            detailOrderId: this.RejectEdit.DetailOrderId,
                            remarks: this.RejectEdit.Remarks,
                            status: 0,
                        };
                        addResubmitsSo(paramrow).then(() => {
                            this.$message({
                                message: this.$t('OperationSuccess'),
                                type: "success"
                            });
                            this.RejectdialogTableVisible = false;
                            this.GetList();
                        }).catch((erro) => { console.log(erro) });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async getRejectList() {
                await ListApproves(this.RejectEdit.DetailOrderId, this.pageRejectList.currentPage, this.pageRejectList.pageSize).then(res => {
                    var RejectInfo = res.data.OrderApproves;
                    this.tableDataRejectList = RejectInfo;
                    this.pageRejectList.total = res.data.TotalCount;
                    this.pageRejectList.pageSize = res.data.PageSize;
                }).catch(erro => {
                    console.log(erro)
                })
            },
            currentRejectListChange(val) {
                this.pageRejectList.currentPage = val
                this.getRejectList();
            },
            //追踪
            tracingPur(row) {
                this.$router.push({ path: '/pvh_operation/orderFracing', query: { ID: row.PurchaserOrderDetailId } })
            },
            formatColumn(e) {
                var helpReturn = e;
                if (helpReturn.Param1 == "Completed") {
                    return "√" + this.$t('No.') + helpReturn.Param3 + " : " + this.$t('Completed');
                }
                else if (helpReturn.Param1 == "HaveUpinfo") {
                    return "√" + this.$t('No.') + helpReturn.Param3 + " : " + helpReturn.Param2 + "-" + this.$t('HaveUpinfo');
                }
                else if (helpReturn.Param1 == "NoUpinfo") {
                    return "×" + this.$t('No.') + helpReturn.Param3 + " : " + helpReturn.Param2 + "-" + this.$t('NoUpinfo');
                }
                else if (helpReturn.Param1 == "HavePartUpinfo") {
                    return "×" + this.$t('No.') + helpReturn.Param3 + " : " + helpReturn.Param2 + "-" + this.$t('HavePartUpinfo');
                }
                else if (helpReturn.Param1 == "NoWriteSO") {
                    return "×" + this.$t('No.') + helpReturn.Param3 + " : " + this.$t('NoWriteSO');
                }
                else if (helpReturn.Param1 == "HaveWriteSO") {
                    return "√" + this.$t('No.') + helpReturn.Param3 + " : " + this.$t('HaveWriteSO');
                }
                else if (helpReturn.Param1 == "NoWriteComponent") {
                    return "×" + this.$t('No.') + helpReturn.Param3 + " : " + this.$t('NoWriteComponent');
                }
                else if (helpReturn.Param1 == "HaveWriteComponent") {
                    return "√" + this.$t('No.') + helpReturn.Param3 + " : " + this.$t('HaveWriteComponent');
                }
                else if (helpReturn.Param1 == "NoRelation") {
                    return "×" + this.$t('No.') + helpReturn.Param3 + " : " + this.$t('NoRelation');
                }
                else if (helpReturn.Param1 == "HaveRelation") {
                    return "√" + this.$t('No.') + helpReturn.Param3 + " : " + this.$t('HaveRelation');
                }
                else {
                    return "";
                }
            },
            delPur(row) {
                console.log("row", row);
                //如果使用的数据不能删除
                SoListByPoId(row.PurchaserOrderId).then(res => {
                    var list = res.data;
                    if (list.length > 0) { this.$message({ showClose: true, message: this.$t('NotCanDeletedPO'), type: "warning" }); return; }
                    this.$confirm(this.$t('IsDel') + row.PurchaserPo, this.$t('Tips'), {
                        confirmButtonText: this.$t('Yes'),
                        cancelButtonText: this.$t('Cancel'),
                        type: "warning"
                    }).then(() => {
                        delHead(row.PurchaserOrderId).then(() => {
                            this.getSaleOrderList();
                            this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                        });
                    });
                })
            },//恢复数据
            SearchCategoryInfo(queryString, cb) {
                var restaurants = this.CategoryData;
                var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results.slice(0, 10));
            },
            createFilter(queryString) {
                return (restaurant) => {
                    return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            //替换表格时间格式
            formatDate(row, column) {
                // 获取单元格数据
                let date = row[column.property];
                if (date) {
                    return this.transformDate(date)
                } else {
                    return ''
                }
            },
            //标准时间格式转yyyy-MM-dd HH:mm:ss
            transformDate(date) {
                if (date) {
                    let dt = new Date(date);
                    return dt.getFullYear() + '-' +
                        ((dt.getMonth() + 1) < 10 ? ('0' + (dt.getMonth() + 1)) : (dt.getMonth() + 1)) + '-' +
                        (dt.getDate() < 10 ? ('0' + dt.getDate()) : dt.getDate()) + ' ' +
                        (dt.getHours() < 10 ? ('0' + dt.getHours()) : dt.getHours()) + ':' +
                        (dt.getMinutes() < 10 ? ('0' + dt.getMinutes()) : dt.getMinutes()) + ':' +
                        (dt.getSeconds() < 10 ? ('0' + dt.getSeconds()) : dt.getSeconds())
                } else {
                    return ''
                }
            },
        }
    }
</script>