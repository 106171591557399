<template>
  <div id="home_container" style="background: transparent; position: relative; top: 70px; left: 0" >
    <el-dialog
      :title="title + '--' + codesTitle"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      width="60%"
    >
      <el-form ref="form" :model="goForm" style="margin-top: -20px" label-width="120px">
        <el-collapse v-model="activeNames">
          <el-collapse-item title="带替换人员信息" name="1">
            <el-row>
              <el-col :span="24"><el-form-item label="时间选择">
                <el-date-picker
                    @change="dateChange"
                    v-model="formInline.time2"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item></el-col>
            </el-row>
            <el-row>
              <el-col :span="24"><el-form-item label="班次:">
                <div class="block">
                  <el-cascader
                      v-model="shiftAndGroup"
                      :options="options"
                      :props="{ expandTrigger: 'hover' }"
                      @change="shiftAndGroupChange"></el-cascader>
                </div>
                <!--          <el-radio-group
                            v-for="(value1,index) in setData.changeData2.班次"
                            :key="index"
                            v-model="changeValue.班次"
                            @change="clickBC(value1)"
                          >
                            <el-radio-button
                              :label="value1.id"
                              style="marginleft: 10px; margintop: 5px"
                            >
                              {{ value1.name }}
                            </el-radio-button>
                          </el-radio-group>-->
              </el-form-item></el-col>
            </el-row>
            <el-row>
              <el-col :span="24"><el-form-item label="工种:">
                <el-radio-group
                    v-model="forPost"
                    v-for="(value3,index) in forPostItem"
                    :key="index"
                    @change="clickGZ(value3)"
                >
                  <el-radio-button
                      :label="value3.name"
                      style="marginleft: 10px; margintop: 5px"
                      :value="value3.id"
                  ></el-radio-button>
                </el-radio-group>
              </el-form-item></el-col>
            </el-row>
            <el-row>
              <el-col :span="24"><el-form-item label="员工:">
                <el-radio-group
                    v-for="(value2,index) in setData.changeData2.员工"
                    :key="index"
                    v-model="changeValue.员工"
                    @change="personnelChange(value2)"
                >
                  <el-radio-button
                      :label="value2.name"
                      style="marginleft: 10px; margintop: 5px"
                  >
                    {{ value2.name }}
                  </el-radio-button>
                </el-radio-group>
              </el-form-item></el-col>
            </el-row>
          </el-collapse-item>
          <el-collapse-item title="替岗人员" name="2">
            <avue-form
                v-model="form"
                :option="option"
                v-if="dialogVisible"
                @submit="submit"
            ></avue-form>
          </el-collapse-item>
          <el-collapse-item title="产品切分" name="3">
            <el-form-item v-for="(item,index) in timeYieldData" :label="item.timeInterval">
              <template >
                <el-input-number  v-model="timeYieldData[index].yield"></el-input-number>
              </template>
            </el-form-item>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <el-dialog width="30%" :visible.sync="innerVisible" append-to-body>
        <div class="inner">
          <el-radio-group v-model="nameRadio">
            <el-radio-button
              v-for="item in nameList"
              :key="item.id"
              :label="item.name"
            >
            </el-radio-button>
          </el-radio-group>
        </div>
        <p slot="footer" class="dialog-footer" style="margin-top: -30px">
          <el-button @click="innerVisible = false">{{$t("Cancel")}}</el-button>
          <el-button type="primary" @click="selectName">{{$t("Yes")}}</el-button>
        </p>
      </el-dialog>
      <el-dialog width="30%" :visible.sync="showAddUser" append-to-body>
        <div class="inner">
          <el-form ref="form" :model="form" label-width="110px">
            <el-form-item label="姓名">
              <el-input v-model.trim="newUserForm.name"></el-input>
            </el-form-item>
            <el-form-item label="性别">
              <el-radio-group v-model="newUserForm.gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model.trim="newUserForm.phoneNumber"></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-radio-group v-model="newUserForm.status">
                <el-radio :label="0">在职</el-radio>
                <el-radio :label="1">离职</el-radio>
                <el-radio :label="-1">待确认</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <p slot="footer" class="dialog-footer" style="margin-top: -30px">
          <el-button @click="showAddUser = false">{{$t("Cancel")}}</el-button>
          <el-button type="primary" @click="addUser">{{$t("Yes")}}</el-button>
        </p>
      </el-dialog>
      <el-dialog width="30%" :visible.sync="innerSave" append-to-body>
        <div class="inner">
          <h4 style="margin-bottom: 0.8125rem">以下为可修改机器</h4>
          <div class="content">
            <div
              class="item"
              style="margin-bottom: 0.8125rem"
              v-for="item in codeList"
              :key="item.id"
            >
              {{ item.code }}
            </div>
          </div>
        </div>
        <p slot="footer" class="dialog-footer" style="margin-top: -30px">
          <el-button @click="innerSave = false">{{$t("Cancel")}}</el-button>
          <el-button type="primary" @click="saveForPostRlus">{{$t("Yes")}}</el-button>
        </p>
      </el-dialog>
      <p slot="footer" class="dialog-footer" style="margin-top: -30px">
        <el-button @click="closedialogVisible">{{$t("Cancel")}}</el-button>
        <el-button type="primary" @click="confirm">{{$t("Yes")}}</el-button>
      </p>
    </el-dialog>
    <div
      class="outFormDiv"
      style="
        display: flex;
        padding: 15px;
        background: #f2f2f2;
        position: fixed;
        top: 100px;
        right: 0;
        width: calc(100% - 13.75rem);
        z-index: 1;
        box-sizing: border-box;
      "
    >
      <div
        class="formList"
        v-if="setData.topIsMenu"
       
      >
          <el-form :inline="true"
                   :model="formInline"
                   class="demo-form-inline"
                   labelWidth="100px"
                   style="display: flex;">
              <el-form-item label="日期选择"
                            style="width: calc(50% - 23.33px); display: flex">
                  <el-date-picker v-model="formInline.time"
                                  value-format="yyyy-MM-dd"
                                  type="date"
                                  placeholder="选择日期"
                                  style="width: 100%">
                  </el-date-picker>
              </el-form-item>
              <el-form-item label="车间选择"
                            style="width: calc(50% - 23.33px); display: flex">
                  <el-select filterable
                             v-model="formInline.workShopId"
                             placeholder="请选择车间">
                      <el-option v-for="item in workCenter"
                                 :key="item.id"
                                 :label="item.name"
                                 :value="item.id"></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="人员姓名"
                            style="width: calc(50% - 23.33px); display: flex">
                  <el-input v-model="formInline.userName"></el-input>
              </el-form-item>
              <el-form-item label="设备编号"
                            style="width: calc(50% - 23.33px); display: flex">
                  <el-input v-model="formInline.machineCode"></el-input>
              </el-form-item>
          </el-form>
      </div>
      <div
        class="btn"
        v-if="setData.topIsMenu"
        style="width: 550px; text-align: right; display: flex; justify-content: flex-end; align-items: start;">
        <el-button type="primary" @click="onSearch">{{$t("search")}}</el-button>
        <!--<el-checkbox-button class="allBtn" :indeterminate="isIndeterminate" type="primary" @change="onAll">{{setData.allText}}</el-checkbox-button>-->
<!--        <el-button type="primary" @click="getAll">{{$t("Batch for duty")}}</el-button>-->
        <!-- <el-button type="primary" @click="daochu">{{$t("EXPORT")}}</el-button> -->
      </div>
      <div v-if="setData.topIsCheMenu" style="width: 100%; text-align: right">
        <el-checkbox-button
          class="allBtn"
          :indeterminate="isIndeterminate"
          type="primary"
          @change="onAll"
          >{{ setData.allText }}</el-checkbox-button
        >
        <!--<el-button type="primary" @click="onAll" style="margin-right:5px;">{{setData.allText}}</el-button>-->
        <el-badge
          :value="checkboxYes.length"
          class="item"
          style="margin-right: 5px"
        >
          <el-button type="primary" @click="onSubmit">{{$t("Batch for duty")}}</el-button>
        </el-badge>
        <el-button type="primary" @click="outAll">{{$t("Exit the batch")}}</el-button>
      </div>
    </div>

    <div
      class="rygl"
      style="
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;
        padding: 7.5px;
        box-sizing: border-box;
      "
      :style="outHei"
    >
      <draggable
        style="
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          margin-top: 7.5px;
          display: flex;
          flex-wrap: wrap;
          padding: 0 7.5px;
          box-sizing: border-box;
        "
        :style="scHei"
      >
        <el-checkbox-group
          v-model="checkboxYes"
          style="width: 100%; display: flex; flex-wrap: wrap"
        >
          <el-checkbox-button
            v-for="(item, index) in list"
            :label="item.machineCode"
            :key="item.machineCode"
            style="padding: 0"
            :style="itemHeight"
            class="fontCss"
            @change="isIndeterminateChange"
            :disabled="setData.isCheck"
          >
            <div
              style="
                display: flex;
                justify-content: space-between;
                padding: 0 7.5px;
                height: 50px;
                line-height: 50px;
                border-radius: 8px 8px 0 0;
                width: 100%;
                box-sizing: border-box;
              "
              class="name"
            >
              <div style="position: relative">{{ item.machineCode }}</div>
            </div>
            <div
              style="
                padding: 0 7.5px;
                height: calc(100% - 128px);
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                width: 100%;
                box-sizing: border-box;
                text-align: left;
              "
            >
              <div style="width: 100%;display:flex;align-items:center;">
                <i
                  class="iconfont icon-pinzhong12"
                  style="
                    vertical-align: 0;
                    font-size: 16px !important;
                    color: #cad9ef;
                    margin-right: 10px;
                  "
                ></i
                >人员姓名：
                <div style="width:calc(100% - 115px);text-overflow:ellipsis;overflow:hidden;white-space:nowrap;">
                    <span style="font-size: 14px; color: #aaa; padding-right: 5px" v-for="val in item.machineUserPostList">{{ val.userName }}</span>
                </div>
              </div>
              <div style="width: 100%;display:flex;align-items:center;">
                <i
                  class="iconfont icon-mingxi"
                  style="
                    vertical-align: 0;
                    font-size: 16px !important;
                    color: #cad9ef;
                    margin-right: 10px;
                  "
                ></i
                >工&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;种：
                  <div style="width:calc(100% - 115px);text-overflow:ellipsis;overflow:hidden;white-space:nowrap;">
                      <span style="font-size: 14px; color: #aaa; padding-right: 5px" v-for="val in item.machineUserPostList">{{ val.postName }}</span>
                  </div>
              </div>
            </div>
            <div
              style="
                display: flex;
                padding: 0 7.5px;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 50px;
                box-sizing: border-box;
              "
            >
              <div style="width: calc(50% - 23.75px)">
                <el-button
                  style="
                    background: #f0f7ff;
                    width: 100%;
                    border: 1px solid #4d8af0;
                    color: #4d8af0;
                    border-radius: 8px;
                    padding: 12px 0;
                  "
                  icon="iconfont icon-duoren"
                  :type="boxStyle(index)"
                  @click="
                    openForPost(
                      item.machineCode,
                      item.machineId,
                      item.machineUserPostList,
                      item
                    )
                  "
                >
                  替岗
                </el-button>
              </div>
            </div>
          </el-checkbox-button>
        </el-checkbox-group>
        <!--<div v-for="(item,indexCod,i) in list" :key="indexCod"
                     style="width: 20%;height: 163px;background: rgba(242, 242, 242, 1);float: left;margin: 25px;">
                    <p style="fontSize: 35px;textAlign: center;">
                        {{ indexCod }}
                        <el-checkbox @change="isIndeterminateChange" v-model="checkboxYes" style="float: left;" :label="indexCod">
                            {{ "" }}
                        </el-checkbox>
                    </p>

                    <footer>-->
        <!-- 小方块 -->

        <!--<div v-for="(value,index) in item"
                             style="width: 30%;height: 50px;margin: 4px;float:left;color:white;border-radius: 10px;">
                            <el-button :type="boxStyle(index)" @click="openForPost(indexCod,value.id,value,item)">{{value.name}}</el-button>
                        </div>
                    </footer>

                </div>-->
        <div style="position: fixed; right: 15px; bottom: 70px">
          <div
            v-if="isTop"
            @click="toTop"
            style="
              margin-bottom: 10px;
              cursor: pointer;
              width: 50px;
              border-radius: 50%;
              height: 50px;
              background: #4d8af0;
              text-align: center;
              line-height: 50px;
            "
          >
            <i
              class="iconfont icon-zhiding-59"
              style="
                font-size: 30px !important;
                color: #fff;
                margin: 0;
                width: 50px;
              "
            ></i>
          </div>
          <div
            v-if="isPre"
            @click="preList"
            style="
              margin-bottom: 10px;
              cursor: pointer;
              width: 50px;
              border-radius: 50%;
              height: 50px;
              background: #4d8af0;
              text-align: center;
              line-height: 50px;
            "
          >
            <i
              class="iconfont icon-shang25"
              style="
                font-size: 30px !important;
                color: #fff;
                margin: -7px 0 0;
                width: 50px;
              "
            ></i>
          </div>
          <div
            v-if="isNex"
            @click="nextList"
            style="
              margin-bottom: 10px;
              cursor: pointer;
              width: 50px;
              border-radius: 50%;
              height: 50px;
              background: #4d8af0;
              text-align: center;
              line-height: 50px;
            "
          >
            <i
              class="iconfont icon-xia852"
              style="
                font-size: 30px !important;
                color: #fff;
                margin: 0;
                width: 50px;
              "
            ></i>
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { fullscreenToggel, listenfullscreen } from "@/util/util";
import draggable from "vuedraggable";
import {
  getNameByLike,
  list,
  batchForCheck,
  getpepoelChange,
  getClassAndPepole,
  batchForDuty,
  selectWorkShopName,
  selectAllByUserChange,
  timeYield,
  getUser
} from "@/api/pepoleChange";
import { dateFormat } from "@/util/date";
import { getGrop } from "@/util/auth";
import {getGroupUser} from "@/api/config";
export default {
  data() {
    return {
      activeNames: ['1','2','3'],
      timeYieldData: [],
      shiftAndGroup: [],
      options: [],
      scH: window.screen.height,
      isTop: false,
      isPre: false,
      isNex: true,
      currentId: 0,
      ishasSeletNum: 0,
      checkboxList: [],
      scHei: {
        height: "",
        top: 0,
      },
      outHei: {
        height: "",
        top: 0,
      },
      itemHeight: {
        height: "",
        background: "#fff",
        "box-shadow": "0px 4px 12px 0px rgba(11, 51, 117, 0.1)",
        margin: "0 7.5px 15px",
        width: "calc(25% - 15px)",
        "box-sizing": "border-box",
        "border-radius": "8px",
        display: "flex",
        "flex-wrap": "wrap",
      },
      checkboxYes: [],
      checkboxAll: [],
      codesTitle: "",
      isIndeterminate: false,
      innerVisible: false,
      indexCod: "",
      form: {},
      option: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 110,
        column: [
          {
            label: "替岗人员姓名",
            type: "select",
            prop: "changeUserId",
            filterable: true,
            span: 24,
            row: true,
            dicData: [],
            props: {
              label: "name",
              value: "userId"
            },

          },
          {
            label: "替岗时间开始",
            type: "time",
            prop: "changeStartDate",
            span: 24,
            row: true,
            format: "HH:mm",
            valueFormat: "HH:mm",
            startPlaceholder: "时间日期开始范围自定义",
            endPlaceholder: "时间日期结束范围自定义",
            change: ()=>{
              this.timeYieldChange();
            }
          },
          {
            label: "替岗时间结束",
            type: "time",
            prop: "changeEndDate",
            span: 24,
            format: "HH:mm",
            valueFormat: "HH:mm",
            row: true,
            startPlaceholder: "时间日期开始范围自定义",
            endPlaceholder: "时间日期结束范围自定义",
            change: ()=>{
              this.timeYieldChange();
            }
          },
        ],
      },
      goForm: { class: "", res: "", change: "" },
      //批量替岗
      dialogVisible: false,
      changeValue: { 班次: "", 班组: "", 员工: "" },
      //机器人员数据
      changeData: {},
      formInline: {
        time: dateFormat(new Date()),
        time2: dateFormat(new Date()),
        userName: "",
        workShopId: "",
        machineCode: "",
      },
      list: [],
      workCenter: [],
      title: "",
      forPost: "",
      forPostItem: [],
      paramsList: {},
      selectItem: {},
      forPostId: "",
      nameList: [],
      nameRadio: "",
      innerSave: false,
      codeList: [],
      showAddUser: false,
      newUserForm: {
        gender: 1,
        name: "",
        phoneNumber: "",
        status: 0,
      },
      // forPostObj:{}
    };
  },
  computed: {
    setData() {
      return {
        allText: "全选",
        isCheck: true,
        topIsMenu: true,
        topIsCheMenu: false,
        changeData2: {},
      };
    },
    ...mapState({
      showDebug: (state) => state.common.showDebug,
      showColor: (state) => state.common.showColor,
      showTheme: (state) => state.common.showTheme,
      showLock: (state) => state.common.showLock,
      showFullScren: (state) => state.common.showFullScren,
      showCollapse: (state) => state.common.showCollapse,
      showSearch: (state) => state.common.showSearch,
      showMenu: (state) => state.common.showMenu,
    }),
    ...mapGetters([
      "userInfo",
      "isFullScren",
      "tagWel",
      "tagList",
      "isCollapse",
      "tag",
      "logsLen",
      "logsFlag",
    ]),
  },
  created() {
    console.log(this.formInline,'line');
    list(this.formInline).then((res) => {
      console.log(res, "list");
      this.list = res.data.data;
      this.checkboxAll = [];
      res.data.data.forEach((v, i) => {
        this.checkboxAll.push(v.machineCode);
      });
      console.log(this.list);
    });
    this.getCenter();
    getUser().then(res => {
      console.log(res);
      this.option.column[0].dicData = res.data.data;
    });
  },
  components: { draggable },
  mounted() {
    listenfullscreen(this.getHeight);
    this.getHeight();
  },
  methods: {
    timeYieldChange(){
      if (this.paramsList.员工&&this.form.changeStartDate&&this.form.changeEndDate){
        timeYield(
            this.formInline.time2,
            this.indexCod,
            this.shiftAndGroup[0],
            this.forPostId,
            this.paramsList.员工,
            this.shiftAndGroup[1],
            this.formInline.time2 + " " +this.form.changeStartDate+ ":00",
            this.formInline.time2 + " " +this.form.changeEndDate+ ":00",
        ).then(res =>{
          console.log("qqqqqqqqqqqq",res)
          this.timeYieldData = [];
          res.data.data.forEach(item =>{
            if (item.timeInterval != null &&item.yield != null &&item.energy != null ){
              this.timeYieldData.push(item);
            }
          })
        })
      }
    },
    async shiftAndGroupChange(){
      let res = await getClassAndPepole(
          this.shiftAndGroup[0],
          this.indexCod,
          null,
          this.formInline.time,
          this.shiftAndGroup[1],
          this.formInline.workShopId
      );
      this.paramsList.班次 = this.shiftAndGroup[0];
      this.changeValue.班次 = this.shiftAndGroup[0];
      this.paramsList.班组 = this.shiftAndGroup[1];
      this.changeValue.班组 = this.shiftAndGroup[1];
      this.forPostItem = JSON.parse(JSON.stringify(res.data.data.工种));
      this.setData.changeData2 = res.data.data;
      this.forPost = "";
      this.forPostId = "";
      this.$forceUpdate();
    },
    nextList() {
      let setHei = Number(this.outHei.height.split("px")[0]);
      let othHei = -setHei;
      if (this.currentId >= parseInt(this.list.length / 12)) {
        this.$message({
          type: "error",
          message: "已经是最后一页啦~",
        });
        this.currentId = parseInt(this.list.length / 12);
      } else {
        this.currentId++;
        this.isTop = true;
        this.isPre = true;
        this.scHei.top = this.currentId * othHei + 7.5 + "px";
      }
      console.log(this.currentId);
    },
    preList() {
      let setHei = Number(this.outHei.height.split("px")[0]);
      let othHei = -setHei;
      if (this.currentId <= 1) {
        this.isTop = false;
        this.isPre = false;
        this.$message({
          type: "error",
          message: "已经是第一页啦~",
        });
        this.scHei.top = 0;
        this.currentId = 0;
      } else {
        this.currentId--;
        this.isTop = true;
        this.isPre = true;
        this.scHei.top = this.currentId * othHei + 7.5 + "px";
      }
      console.log(this.currentId);
    },
    toTop() {
      this.isTop = false;
      this.isPre = false;
      this.$message({
        type: "success",
        message: "已经到第一页啦~",
      });
      this.scHei.top = 0;
      this.currentId = 0;
    },
    // moreFrom() {
    //   let fromHei = document.querySelector(".formList").clientHeight;
    //   if (fromHei > 40) {
    //     document.querySelector(".formList").style.cssText =
    //       "width: calc(100% - 240px); height: 40px; overflow: hidden;";
    //     document.querySelector(".outFormDiv").style.cssText =
    //       "display: flex; justify-content: space-between; padding: 15px; background: #F2F2F2; position: fixed; top: 100px; right: 0; width: calc(100% - 13.75rem); z-index: 1;box-sizing: border-box;";
    //   } else {
    //     document.querySelector(".formList").style.cssText =
    //       "width: calc(100% - 240px);height:auto;";
    //     document.querySelector(".outFormDiv").style.cssText =
    //       "display: flex; justify-content: space-between; padding: 15px; background: #F2F2F2; position: fixed; top: 100px; right: 0; width: calc(100% - 13.75rem); z-index: 1;box-sizing: border-box;box-shadow:0px 15px 10px 0px rgba(0,0,0,.1);";
    //   }
    // },
    getHeight(h) {
      //let hei;
      //let scH = window.screen.height;
      let docH = document.getElementById("leftHeight").clientHeight;
      console.log(this.scH, docH);
      if (this.isFullScren) {
        let hei = this.scH - 70;
        this.bigHeight = hei;
        this.itemHeight.height = hei / 3 - 17.5 + "px";
        this.outHei.height = hei + "px";
        this.outHei.top = "70px";
      } else {
        let hei = docH - 70;
        this.bigHeight = hei;
        this.itemHeight.height = hei / 3 - 27.5 + "px";
        this.outHei.height = hei - 27.5 + "px";
        this.outHei.top = "0";
      }
    },
    daochu() {
      window.location.href =
        this.proApis.SCHEDULEUSEREXPORT + "?corpId=" + getGrop();
    },
    // 新增员工
    async addUser() {
      const res = await this.request.post(
        this.proApis.CORPUSERSINSERT,
        this.newUserForm
      );
      console.log(res, "ress");
      if (res.data.code === "0000") {
        this.$message.success("添加成功");
        this.form.changeUserName = this.newUserForm.name;
        this.showAddUser = false;
      }
    },
    async searchName() {
      if (!this.form.changeUserName)
        return this.$message.warning("请输入搜索内容");
      console.log(this.form.changeUserName, "this.form.changeUserName");
      const res = await getNameByLike(this.form.changeUserName);
      if (res.data.data.length === 0) {
        await this.$confirm("未查到用户,是否新增?", "提示");
        this.newUserForm.name = this.form.changeUserName;
        this.showAddUser = true;
      } else {
        this.nameList = res.data.data;
        this.innerVisible = true;
        console.log(res);
      }
    },
    selectName() {
      console.log(this.nameRadio);
      this.form.changeUserName = this.nameRadio;
      this.innerVisible = false;
    },
    async dateChange(val) {
      console.log(val);
      let res;
      if (this.title == "替岗") {
        res = await getpepoelChange(this.indexCod, "", this.formInline.time2,this.formInline.workShopId);
      } else {
        res = await selectAllByUserChange({
          codes: this.checkboxYes,
          postId: "",
          time: this.formInline.time2,
        });
      }
      this.options = [];
      this.shiftAndGroup = [];
      res.data.data.班次.forEach(item =>{
        this.options.push({
          value: item.shiftId,
          label: item.shiftName,
          children: [{
            value: item.groupId,
            label: item.groupName
          }]
        })
      })
      console.log(res);
      this.$nextTick(() => {
        this.setData.changeData2 = JSON.parse(JSON.stringify(res.data.data));
        this.forPostItem = JSON.parse(JSON.stringify(res.data.data.工种));
      });
      this.changeValue.班次 = "";
      this.changeValue.班组 = "";
      this.changeValue.员工 = "";
      this.forPost = "";
      this.forPostId = "";
    },
    // 点击工种打开替岗
    async openForPost(indexCod, id, value, item) {
      console.log("1",indexCod);
      console.log("2",id);
      console.log("3",value);
      console.log("4",item);
      // await this.getUser(indexCod,'')
      this.codesTitle = indexCod;
      this.indexCod = indexCod;
      //this.forPostItem = item;
      // this.setData.changeData2 = res
      this.forPost = value[0].postName;
      this.forPostId = value[0].postId;
      this.formInline.time2 = this.formInline.time;
      const res = await getpepoelChange(
          indexCod,
          null,
          this.formInline.time,
          this.formInline.workShopId
      );
      const resPost = await getClassAndPepole(
          item.shiftId,
          indexCod,
          null,
          this.formInline.time,
          item.groupId,
          this.formInline.workShopId
      );
      const resUser = await getClassAndPepole(
          item.shiftId,
          indexCod,
          value[0].postId,
          this.formInline.time,
          item.groupId,
          this.formInline.workShopId
      );
      this.$nextTick(() => {
          console.log(res, "res");
        this.options = [];
        res.data.data.班次.forEach(item =>{
          this.options.push({
            value: item.shiftId,
            label: item.shiftName,
            children: [{
              value: item.groupId,
              label: item.groupName
            }]
          })
        })
        this.shiftAndGroup[0] = item.shiftId;
        this.shiftAndGroup[1] = item.groupId;
        this.setData.changeData2.班次 = JSON.parse(JSON.stringify(res.data.data.班次));
        this.forPostItem = JSON.parse(JSON.stringify(resPost.data.data.工种));
        this.setData.changeData2.员工 = JSON.parse(JSON.stringify(resUser.data.data.员工));
      });
      this.changeValue.员工="";
      this.timeYieldData = [];
      this.form.changeStartDate = "";
      this.form.changeEndDate = "";
      console.log(this.setData.changeData2, "data");
      this.dialogVisible = true;
      this.title = "替岗";
    },
    // 点击工种
    async clickGZ(value3) {
      console.log(value3);
      this.forPostId = value3.id;
      let codes = this.title == "替岗" ? this.indexCod : this.checkboxYes;
      let res;
      if (this.title == "替岗") {
        res = await getClassAndPepole(this.shiftAndGroup[0],codes, value3.id, this.formInline.time,this.shiftAndGroup[1],this.formInline.workShopId);
        console.log(codes, value3.postId, this.formInline.time);
      } else {
        res = await selectAllByUserChange({
          codes,
          postId: value3.postId,
          time: this.formInline.time,
        });
        console.log(2);
      }

      console.log(res, "res");
      this.$nextTick(() => {
        this.setData.changeData2.员工 = JSON.parse(JSON.stringify(res.data.data.员工));
        console.log('this.setData.changeData2',this.setData.changeData2);
        this.$forceUpdate();
      });
      this.changeValue.班次 = "";
      this.changeValue.班组 = "";
      this.changeValue.员工 = "";
    },
/*    // 点击班次
    async clickBC(value1) {
      this.paramsList.班次 = value1.id;
      this.changeValue.班次 = String(value1.id);
      let res;
      if (this.title == "替岗") {
        res = await getClassAndPepole(
          value1.id,
          this.indexCod,
          this.forPostId,
          this.formInline.time
        );
      } else {
        res = await selectAllByUserChange({
          suShiftId: value1.id,
          codes: this.checkboxYes,
          postId: this.forPostId,
          time: this.formInline.time,
        });
      }
      console.log(res, "res");
      this.setData.changeData2.班组 = res.data.data.班组;
      this.setData.changeData2.员工 = res.data.data.员工;
    },*/
    // 点击班组
/*    async groupChage(value2) {
      console.log(value2);
      this.paramsList.班组 = value2.id;
      // this.changeValue.班组 = value2.name
      this.changeValue.班组 = value2.id;
      let res;
      if (this.title == "替岗") {
        res = await getClassAndPepole(
          this.paramsList.班次,
          this.indexCod,
          this.forPostId,
          this.formInline.time,
          value2.id
        );
      } else {
        res = await selectAllByUserChange({
          suShiftId: this.paramsList.班次,
          codes: this.checkboxYes,
          postId: this.forPostId,
          time: this.formInline.time,
          suGroupId: value2.id,
        });
      }
      console.log(res, "res");
      this.setData.changeData2.员工 = res.data.data.员工;
    },*/
    // 点击员工
    personnelChange(val) {
      console.log(this.changeValue.员工,val);
      this.paramsList.员工 = val.userId;
      this.changeValue.员工 = val.name;
      this.timeYieldChange();
    },
    submit() {},
    isIndeterminateChange(value) {
      this.checkboxYes.length > 0 &&
      this.checkboxYes.length < this.checkboxAll.length
        ? (this.isIndeterminate = true)
        : (this.isIndeterminate = false);
      //this.checkboxYes.length > 0 && this.checkboxYes.length < this.checkboxAll.length ? this.isIndeterminate = true : this.isIndeterminate = false;
    },
    onSearch() {
      this.form = {};
      this.setData.changeData2 = {};
      this.changeValue.班次 = "";
      this.changeValue.班组 = "";
      this.changeValue.员工 = "";
      this.forPost = "";
        this.checkboxYes = [];
        console.log(this.formInline.workShopId)
        list(this.formInline).then((res) => {
            console.log(res, 'searlisy')
            this.list = res.data.data;
            this.checkboxAll = [];
            for (let key in res.data.data) {
                this.checkboxAll.push(key);
            } 
      });
    },
    //退出全选
    outAll(val) {
      this.setData.isCheck = true;
      this.setData.topIsMenu = true;
      this.setData.topIsCheMenu = false;
      this.checkboxYes = [];
      this.setData.allText = "全选";
    },
    //进入全选
    getAll(val) {
      console.log(this.setData.isCheck);
      this.setData.isCheck = false;
      this.setData.topIsMenu = false;
      this.setData.topIsCheMenu = true;
      this.checkboxYes = [];
    },
    onAll(value) {
      value == false
        ? (this.checkboxYes = [])
        : (this.checkboxYes = this.checkboxAll);
      value == false
        ? (this.setData.allText = "全选")
        : (this.setData.allText = "取消");
    },
    async confirm() {
      console.log( 
          // this.paramsList.员工 ,
          // this.paramsList.班次 ,
          // this.paramsList.班组 ,
          this.forPostId ,
          // this.form.changeUserName ,
          // this.form.changeStartDate ,
          // this.form.changeEndDate ,
          // this.indexCod ,
          // this.formInline.time
          );
      let arr = Object.keys(this.list)[this.selectItem];
      if (this.title == "替岗") {
        if (
          !this.paramsList.员工 ||
          !this.shiftAndGroup[0] ||
          !this.shiftAndGroup[1] ||
          !this.forPostId ||
          !this.form.changeUserId ||
          !this.form.changeStartDate ||
          !this.form.changeEndDate ||
          !this.indexCod ||
          !this.formInline.time
        ){
          let params = {
            corpUsersId: this.paramsList.员工,
            suShiftId: this.shiftAndGroup[0],
            suGroupId: this.shiftAndGroup[1],
            postId: this.forPostId,
            changeUserId: this.form.changeUserId,
            changeStartDate: this.form.changeStartDate,
            changeEndDate: this.form.changeEndDate,
            codes: [this.indexCod],
            time: this.formInline.time
          };
          console.log(params, "params");
          return this.$message.warning("请完善表单");
          }
        let yieldList = [];
        let energyList = [];
        this.timeYieldData.forEach(tmp =>{
          yieldList.push(tmp.yield);
          energyList.push(tmp.energy);
        });
        let params = {
          corpUsersId: this.paramsList.员工,
          suShiftId: this.shiftAndGroup[0],
          suGroupId: this.shiftAndGroup[1],
          postId: this.forPostId,
          changeUserId: this.form.changeUserId,
          changeStartDate: this.formInline.time2 + " " +this.form.changeStartDate+ ":00",
          changeEndDate: this.formInline.time2 + " " +this.form.changeEndDate+ ":00",
          codes: [this.indexCod],
          time: this.formInline.time2,
          yieldList: yieldList,
          energyList: energyList
        };
        console.log(params, "params");
        const res = await batchForDuty(params);
        console.log(res, "ijn");
        this.dialogVisible = false;
        this.onSearch();
        this.form = {};
        this.setData.changeData2 = {};
        this.changeValue.班次 = "";
        this.changeValue.班组 = "";
        this.changeValue.员工 = "";
        this.forPost = "";
        this.setData.isCheck = true;
        this.setData.topIsMenu = true;
        this.setData.topIsCheMenu = false;
        this.checkboxYes = [];
        this.setData.allText = "全选";
      } else {
        if (
          !this.paramsList.员工 ||
          !this.paramsList.班次 ||
          !this.paramsList.班组 ||
          !this.forPostId ||
          !this.form.changeUserName ||
          !this.form.changeStartDate ||
          !this.form.changeEndDate ||
          this.checkboxYes.length == 0 ||
          !this.formInline.time
        ){
          return this.$message.warning("请完善表单");
          }
        let params = {
          corpUsersId: this.paramsList.员工,
          suShiftId: this.paramsList.班次,
          suGroupId: this.paramsList.班组,
          postId: this.forPostId,
          changeUserName: this.form.changeUserName,
          changeStartDate: this.form.changeStartDate,
          changeEndDate: this.form.changeEndDate,
          codes: this.checkboxYes,
          time: this.formInline.time,
        };
        const res = await batchForCheck(params);
        if (res.data.code == "0000"){
          this.$message.success("替岗成功");
        }else {
          this.$message.success(res.data.msg);
        }
        this.codeList = res.data.data;
        this.innerSave = true;
        console.log(res, "resresres");
        this.setData.isCheck = true;
        this.setData.topIsMenu = true;
        this.setData.topIsCheMenu = false;
        this.checkboxYes = [];
        this.setData.allText = "全选";
      }
    },
    // 全部替岗保存前的校验
    async saveForPostRlus() {
      let params = {
        corpUsersId: this.paramsList.员工,
        suShiftId: this.paramsList.班次,
        suGroupId: this.paramsList.班组,
        postId: this.forPostId,
        changeUserName: this.form.changeUserName,
        changeStartDate: this.form.changeStartDate,
        changeEndDate: this.form.changeEndDate,
        codes: this.checkboxYes,
        time: this.formInline.time,
      };
      await batchForDuty(params);
      this.innerSave = false;
      this.dialogVisible = false;
      this.onSearch();
      this.form = {};
      this.setData.changeData2 = {};
      this.changeValue.班次 = "";
      this.changeValue.班组 = "";
      this.changeValue.员工 = "";
      this.forPost = "";
    },
    closedialogVisible() {
      this.dialogVisible = false;
      this.form = {};
      this.setData.changeData2 = {};
      this.changeValue.班次 = "";
      this.changeValue.班组 = "";
      this.changeValue.员工 = "";
      this.forPost = "";
    },
    async getCenter() {
      const res = await selectWorkShopName();
        this.workCenter = res.data.data;
        this.formInline.workShopId = res.data.data[0].id;
      console.log(res,'852');
    },
    // 查询人员替岗多台机器信息
    async selectAllByUser() {
      const res = await selectAllByUserChange({
        time: this.formInline.time,
        codes: this.checkboxYes,
      });
      return res.data.data;
    },
    async onSubmit() {
      console.log(this.checkboxYes);
      if (this.checkboxYes.length > 0) {
        this.formInline.time2 = this.formInline.time;
        const res = await this.selectAllByUser();
        this.dialogVisible = true;
        this.title = "批量替岗";
        this.codesTitle = this.checkboxYes;
        this.setData.changeData2 = res;
        // console.log( this.list[this.checkboxYes[0]],'this.checkboxYes.length',this.setData.changeData2)
        this.forPostItem = res.工种;
      } else {
        this.$message.warning("请选择替岗机型");
      }
    },
    getClassAndPepoleMethod(code, postId, item) {
      getClassAndPepole(
        this.changeValue.班次,
        code,
        postId,
        this.formInline.time
      ).then((res) => {
        this.changeData.班组 = res.data.data.班组;
        this.changeData.员工 = res.data.data.员工;
        this.paramsList.班次 = item.id;
      });
    },
    getClassAndPepoleMethod2(code, postId, suGroupId, item) {
      getClassAndPepole(
        this.changeValue.班次,
        code,
        postId,
        this.formInline.time,
        suGroupId
      ).then((res) => {
        this.changeData.员工 = res.data.data.员工;
        this.paramsList.班组 = item.id;
      });
    },
    async getClassAndPepoleMethod3(value, item, indexCod, value3) {
      // getClassAndPepole(this.changeValue.班次, code, postId,this.formInline.time,suGroupId).then(res => {
      //   console.log(res,'ress')
      //   this.changeData.员工 = res.data.data.员工
      // });
      // console.log(value3,'value3')
      this.forPostItem = item;
      this.selectItem = indexCod;
      this.forPost = value.name;
      this.paramsList.员工 = value3.userId;
      this.forPostId = value.id;
      await this.$confirm("是否替岗?", "提示");
      if (!this.changeValue.班次 || !this.changeValue.班组) return;
      this.dialogVisible = true;
      this.title = "替岗";
    },
    //获取人员
    getUser(mechineIndexId, userId) {
      getpepoelChange(mechineIndexId, userId, this.formInline.time,this.formInline.workShopId).then(
        (res) => {
          this.changeValue = {};
          this.changeData = {};
          this.$nextTick(() => {
            this.setData.changeData2 = JSON.parse(
              JSON.stringify(res.data.data)
            );
          });
          this.changeData = res.data.data;
        }
      );
    },
    boxStyle(index) {
      let style = "";
      switch (index) {
        case 0:
          style = "primary";
          break;
        case 1:
          style = "success";
          break;
        case 2:
          style = "info";
          break;
        case 3:
          style = "warning";
          break;
        case 4:
          style = "danger";
          break;
        case 5:
          style = "";
          break;
      }
      return style;
    },
  },
};
</script>
<style>
.allBtn .el-checkbox-button__inner {
  padding: 12px 20px !important;
  border-radius: 3px !important;
  margin: 0 5px;
  background: #409eff;
  color: #fff;
}
.rygl .el-checkbox-button__inner {
  padding: 0 !important;
  width: 100%;
  border-radius: 8px 8px 8px 8px !important;
}
.el-checkbox-button.is-disabled .el-checkbox-button__inner {
  cursor: default;
}
.rygl .el-checkbox-button.is-checked .el-checkbox-button__inner {
  background: #fff;
  color: #333;
  /*box-shadow: 0 0 3px 3px rgba(77,138,240,.2) !important;*/
}
</style>
<style lang="scss" scoped>
.container_title {
  font-weight: 700;
  font-size: 20px;
  padding-left: 20px;
  padding-top: 20px;
}
.inner {
  /deep/.el-radio-button {
    margin: 7px;
  }
}
/deep/.el-radio-button__inner {
  border-left: 0.0625rem solid #dcdfe6;
}
/deep/.el-collapse-item__header {
  font-size: 18px;
  font-weight: 550;
}
</style>