var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "volumeSet" },
    [
      _c(
        "el-dialog",
        {
          staticStyle: { marginTop: "-60px" },
          attrs: {
            title: _vm.$t("Edit"),
            visible: _vm.modelShow,
            width: "500px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
            "append-to-body": true
          },
          on: {
            "update:visible": function($event) {
              _vm.modelShow = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-width": "80px",
                    rules: _vm.rules
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "编码", prop: "code" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.code,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "code", $$v)
                          },
                          expression: "form.code"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "功率", prop: "power" } },
                    [
                      _c("el-input", {
                        attrs: { type: "number" },
                        model: {
                          value: _vm.form.power,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "power", $$v)
                          },
                          expression: "form.power"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "设备类型", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.form.type,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type"
                          }
                        },
                        _vm._l(_vm.options, function(item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.name, value: item.code },
                            nativeOn: {
                              click: function($event) {
                                return _vm.getDTO(item)
                              }
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "工序", prop: "processId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.form.processId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "processId", $$v)
                            },
                            expression: "form.processId"
                          }
                        },
                        _vm._l(_vm.process, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "p",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "-30px" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeModel } }, [
                _vm._v(_vm._s(_vm.$t("Cancel")))
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.makeSure } },
                [_vm._v(_vm._s(_vm.$t("Yes")))]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }