<template>
    <div id="loadbgcontent">
        <el-form>
            <el-row>
                <el-col style="width: 240px; justify-content: center; display: flex;">
                    <div id="loadbg"></div>
                </el-col>
            </el-row>
            <el-row>
                <el-col style="width:260px">
                    <el-progress :stroke-width="26" :percentage="percentage" :color="colors" ></el-progress>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <span style="font-size:18px;">{{$t('Please be patient')}}</span>  
                </el-col>
            </el-row>
            <!--<el-row>
                <el-col>
                <el-button type="primary" @click="start">开始</el-button> <el-button type="danger" @click="over">结束</el-button>
                </el-col>
            </el-row>-->
        </el-form>
    </div>
</template>


<style>
    #loadbg {
        width: 90px;
        height: 90px;
        background: url("/img/loadBG03.gif") center center no-repeat;
        background-size: cover;
        position: relative;
    }
    #loadbgcontent {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: #fff;
    }

</style>

<script>
    import { packageProgress, addPackage, IsHavePackage, add, } from "@/api/orderPackage";
    export default {
        data() {
            return {
                OrderId:"",
                packageId: "",
                timer: "",
                value: 0,
                percentage: 0,
                RedisPercentage: 0,
                url:"",
                colors: [
                    { color: '#000066', percentage: 20 },
                    { color: '#000066', percentage: 40 },
                    { color: '#000066', percentage: 60 },
                    { color: '#000066', percentage: 80 },
                    { color: '#000066', percentage: 100 }
                ]
            }
        },
        created() {
            this.init()
        },
        watch: {
            $route(to, from) {
                this.init()
            }
        },
        mounted() { },
        beforeDestroy() {
            clearInterval(this.timer);
        },
        methods: {
            init() {
            //新增文件包
             this.OrderId = this.$route.query.OrderId;
             this.add();
            },
            add() {
                let paramrow = {
                    id: "",
                    orderId: this.OrderId,
                    path: "Pending",
                    size: 0,
                    remark: "",
                    status: 0,
                    createTime: '2021-05-01T08:00',
                    createUserid: '',
                    corporationId: ''
                };
                addPackage(paramrow).then(res =>  {
                    this.packageId = res.data.result
                 
                }).catch((erro) => { console.log(erro) }); 
                 //读取进度
                this.startProgress();
            },
            startProgress() {
                this.timer = setInterval(this.valChangeProgress, 1000); // 注意: 第一个参数为方法名的时候不要加括号;
            },
            valChangeProgress() {
                if (this.packageId != "")
                {
                    if (this.percentage == 100) {
                        //获取是否有保存路径
                        IsHavePackage(this.packageId).then(res => {
                            var s_url = res.data.result;
                            if (s_url != "") {
                                this.over();
                                //新增操作记录
                                this.addLog(1);
                                //关闭当前子页
                                this.$router.push({ path: '/pvh_operation/review', query: { OrderId: this.OrderId } })
                            }
                            //读取进度
                        }).catch((erro) => { console.log(erro) });

                    }
                    else if (this.percentage < 0) {
                        this.over();
                        this.$message({ showClose: true, message: this.$t('File generation failed, please contact the background administrator'), type: "warning" });
                        return false;
                    }
                    else {
                        packageProgress(this.packageId).then(res => {
                            var progress = parseInt(res.data.result)
                            this.RedisPercentage = progress;
                            if (this.RedisPercentage != 0 && this.RedisPercentage >= this.percentage) {
                                this.percentage = this.RedisPercentage;
                            } else
                            {
                                this.percentage++;
                            }
                            //读取进度
                        }).catch((erro) => { console.log(erro) });
                    }
                }
                else
                {
                    this.percentage++;
                }
            },
            start() {
                this.timer = setInterval(this.valChange, 50000); // 注意: 第一个参数为方法名的时候不要加括号;
            },
            valChange() {
                if (this.percentage != 100) {
                    this.percentage += 10;
                    console.log(this.percentage);
                } else
                {
                    this.percentage -= 10;
                    console.log(this.percentage);
                }
  
            },
            over() {
                clearInterval(this.timer);
            },
            increase() {
                this.percentage += 10;
                if (this.percentage > 100) {
                    this.percentage = 100;
                }
            },
            decrease() {
                this.percentage -= 10;
                if (this.percentage < 0) {
                    this.percentage = 0;
                }
            },
            addLog(type) {
                let paramrow = {
                    orderPackageId: this.packageId,
                    type: type,
                    remark: "",
                    status: 0,
                    createTime: '2021-05-01T08:00',
                    createUserid: '',
                    corporationId: ''
                };
                add(paramrow).then(() => {
                }).catch((erro) => { console.log(erro) });
            },
        },
    }
</script>