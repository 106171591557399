import request from '@/router/axios';
import {businessUrl} from "@/config/env"
export const getTree = () => request({
    url: businessUrl + "productCategory/select",
    method: "get",
    data: {
        
    }
})
export const list = (status,nameOrCode,pageNo,pageSize,parentId) => request({
    url: businessUrl + "productCategory/selectPage",
    method: "post",
    data: {
        status,nameOrCode,pageNo,pageSize,parentId
    }
})
export const add = (row,parentId) => request({
    url: businessUrl + "productCategory/insert",
    method: "post",
    data: {
        ...row,
        parentId
    }
})
export const remove = (id) => {
    return request({
    url: businessUrl + "productCategory/delete",
    method: "delete",
    params: {
        id
    }
    })
}
export const edit = (row) => request({
    url: businessUrl + "productCategory/update",
    method: "put",
    data: {
        ...row
    }
})
export const changeStatus = (row) => request({
    url: businessUrl + "productCategory/updateStatus",
    method: "put",
    data: {
        ...row
    }
})