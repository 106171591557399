var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "div",
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("RoleName")) + "：")]),
              _c("el-input", {
                staticStyle: { width: "200px", height: "50px" },
                attrs: { placeholder: "", readonly: "" },
                model: {
                  value: _vm.roleName,
                  callback: function($$v) {
                    _vm.roleName = $$v
                  },
                  expression: "roleName"
                }
              }),
              _c("el-input", {
                staticStyle: {
                  width: "200px",
                  height: "50px",
                  display: "none"
                },
                attrs: { placeholder: "" },
                model: {
                  value: _vm.roleId,
                  callback: function($$v) {
                    _vm.roleId = $$v
                  },
                  expression: "roleId"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { marginTop: "20px", display: "flex" } },
            [
              _vm._v("\n\t\t\t" + _vm._s(_vm.$t("PERMISSION")) + "：\n\t\t\t"),
              _c("el-tree", {
                ref: "tree",
                attrs: {
                  slot: "reference",
                  data: _vm.data,
                  "show-checkbox": "",
                  "node-key": "id",
                  "highlight-current": "",
                  props: _vm.defaultProps
                },
                on: {
                  check: _vm.clickCheck,
                  setCheckedKeys: _vm.setCheckedKeys,
                  "node-collapse": _vm.nodeCollapse
                },
                slot: "reference"
              }),
              _c("div", { staticStyle: { marginLeft: "500px" } }, [
                _vm._v(_vm._s(_vm.check.label))
              ]),
              _c(
                "el-popover",
                {
                  staticStyle: { marginTop: "40px", marginLeft: "-120px" },
                  attrs: {
                    placement: "right",
                    width: "100",
                    title: _vm.$t("DATA PERMISSIONS")
                  },
                  model: {
                    value: _vm.visity,
                    callback: function($$v) {
                      _vm.visity = $$v
                    },
                    expression: "visity"
                  }
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      on: { change: _vm.checkRow },
                      model: {
                        value: _vm.checkedCities,
                        callback: function($$v) {
                          _vm.checkedCities = $$v
                        },
                        expression: "checkedCities"
                      }
                    },
                    _vm._l(_vm.cities, function(city) {
                      return _c(
                        "el-checkbox",
                        { key: city.id, attrs: { label: city.id } },
                        [_vm._v(_vm._s(city.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-popover",
                {
                  staticStyle: { marginTop: "180px" },
                  attrs: {
                    placement: "right",
                    width: "100",
                    title: _vm.$t("PERMISSION LEVEL")
                  },
                  model: {
                    value: _vm.visity,
                    callback: function($$v) {
                      _vm.visity = $$v
                    },
                    expression: "visity"
                  }
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      on: {
                        change: function(e) {
                          return _vm.changeCheckbox(e, _vm.ckList.radios)
                        }
                      },
                      model: {
                        value: _vm.ckList.checkList,
                        callback: function($$v) {
                          _vm.$set(_vm.ckList, "checkList", $$v)
                        },
                        expression: "ckList.checkList"
                      }
                    },
                    [
                      _vm._l(_vm.ckList.radios, function(value) {
                        return _c(
                          "el-checkbox",
                          {
                            key: value.id,
                            attrs: {
                              value: value.id,
                              label: value.id,
                              disabled:
                                _vm.ckList.checkList.includes(
                                  _vm.ckList.radios[
                                    _vm.ckList.radios.length - 1
                                  ].id
                                ) &&
                                value.id !==
                                  _vm.ckList.radios[
                                    _vm.ckList.radios.length - 1
                                  ].id
                            }
                          },
                          [_vm._v(_vm._s(value.name))]
                        )
                      }),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.save }
                        },
                        [_vm._v(_vm._s(this.$t("SAVE")))]
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { marginTop: "20px", marginLeft: "50px" },
              attrs: { type: "info" },
              on: { click: _vm.updateRolePermission }
            },
            [_vm._v(_vm._s(_vm.$t("save")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }