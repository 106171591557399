<template>
  <div>
    <basic-container>
        <avue-crud :option="tableOption"
                   :data="tableData"
                   :page="page"
                   ref="crud"
                   v-model="obj"
                   @current-change="currentChange"
                   @size-change="sizeChange"
                   @selection-change="selectionChange"
                   @refresh-change="refreshChange"
                   @row-save="handleSave"
                   @row-update="handleUpdate"
                   @row-del="handleDel">
            <template slot="status" slot-scope="scope">
                <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                           @change="change(scope.row)">
                </el-switch>
            </template>
            <template slot="menuRight">
                <el-select clearable v-model="modelType" placeholder="请选择机型名称" size="small" style="width:200px !important;marginRight: 5px;">
                    <el-option v-for="value in deviceTypeList" :key="value.id" :label="value.name" :value="value.id"></el-option>
                </el-select>
                <el-select clearable v-model="processId" placeholder="请选择所属工序" size="small" style="width:200px !important;marginRight: 5px;">
                    <el-option v-for="item in workingList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
                <el-input v-model="nameOrCode" placeholder="设备名称或编码" style="display:inlie-block;width:200px;marginRight: 5px;" size="small"></el-input>
            </template>
            <!--@select="handleSelect" 下面三个都有这个-->
            <template slot="manufacturerForm" scope="scope">
                <el-autocomplete class="inline-input"
                                 v-model="obj.manufacturer"
                                 :fetch-suggestions="manufacturerFormquerySearch"
                                 placeholder="请输入内容"
                                 style="width:100%;"></el-autocomplete>
            </template>
            <template slot="hmiOwnerForm" scope="scope">
                <el-autocomplete class="inline-input"
                                 v-model="obj.hmiOwner"
                                 :fetch-suggestions="querySearchHMI"
                                 placeholder="请输入内容"
                                 style="width:100%;"></el-autocomplete>
            </template>
            <template slot="plcOwnerForm" scope="scope">
                <el-autocomplete class="inline-input"
                                 v-model="obj.plcOwner"
                                 :fetch-suggestions="querySearchplcOwner"
                                 placeholder="请输入内容"
                                 style="width:100%;"></el-autocomplete>
            </template>
            <template slot="menu" slot-scope="{row,index,size,type}">
                <el-button icon="el-icon-data-analysis" :size="size" :type="type" @click="goReport(row)">{{$t("Check the report")}}</el-button>
            </template>
        </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {list,add,edit,remove,selectByplcOwner,selectByhmiOwner,selectBymanufacturer,isWorking} from "@/api/device"
    import { getworkAll, getdeviceType, getCJAndGZ} from "@/api/config";
export default {
    data() {
        return {
            obj: {},
            plcOwnerList: [],
            hmiOwnerList: [],
            manufacturerList: [],
            deviceTypeList: [],
            workingList: [],
            nameOrCode: "",
            processName: "",
            processId: "",
            modelType: "",
            form:'',
            page: {
                pageSize: 20,
                pagerCount:5,
                total: 10,
                pageCurrent: 1
            },
            disabled:true,
            tableData:[],
            tableOption:{
              delBtn: true,
              menuWidth:200,
              selection: true,
              text: "center",
              tip: false,
              columnBtn : false,
              labelWidth: 120,
              dialogFullscreen:true,
              addBtnText: this.$t('ADD'),
              delBtnText: this.$t('DELETE'),
              editBtnText: this.$t('Edit'),
              emptyText: this.$t('No Data'),
              menuTitle: this.$t('OPERATION'),
                column: [
                    {
                        label: "设备编号",
                        display: false,
                        prop: "id",
                        hide: true,
                        rules: [{
                          required: true,
                          message: "请输入编号",
                          trigger: "blur"
                        }]
                    },
                    {
                        label: "设备编码",
                        value: 0,
                        prop: "code"
                    },
                    {
                        label: "设备名称",
                        prop: "name",
                        rules: [{
                          required: true,
                          message: "请输入设备名称",
                          trigger: "blur"
                        }]
                    },
                    {
                        label: "机型名称",
                        dicData: [],
                        rules: [{
                          required: true,
                          message: "请输入机型名称",
                          trigger: "blur"
                        }],
                        type: "select",
                        props:{
                          label: "name",
                          value: "id"
                        },
                        width: "100",
                        prop: "machineModelId"
                    },
                    {
                        label: "生产厂商",
                        formslot: true,
                        prop: "manufacturer"
                    },
                    {
                        label: "出厂日期",
                        valueFormat: "yyyy-MM-dd",
                        width: "150",
                        type: "date",
                        format: 'yyyy-MM-dd',
                        prop: "prodTime"
                    },
                    {
                        label: "PLC厂商",
                        formslot: true,
                        prop: "plcOwner"
                    },
                    {
                        label: "PLC型号",
                        prop: "plcModel"
                    },
                    {
                        label: "HMI厂商",
                        formslot: true,
                        prop: "hmiOwner"
                    },
                    {
                        label: "设备总锭数",
                        width: "100",
                        type: "number",
                        prop: "spinCount"
                    },
                    {
                        label: "车间",
                        dicData: [],
                        type: "select",
                        rules: [{
                          required: true,
                            message: "请输入车间",
                          trigger: "blur"
                        }],
                        props:{
                          label:"name",
                          value:"id"
                        },
                        prop: "departmentId"
                    },
                    {
                        label: "所属工序",
                        dicData: [],
                        type: "select",
                        prop:"processId",
                        rules: [{
                          required: true,
                            message: "请输入所属工序",
                          trigger: "blur"
                        }],
                        props:{
                          label:"name",
                          value:"id"
                        },
                    },
                    {
                        label: "使用锭数",
                        type: "number",
                        prop: "spinUsed"
                    },
                    {
                        label: "iot编号",
                        prop: "iotId"
                    },
                    {
                        label: "iot状态",
                        display: false,
                        editDisabled: true,
                        prop: "iotStatus",
                        dicData: [{
                          label: "离线",
                          value: 0
                        },{
                          label: "开启",
                          value: 1
                        },{
                          label: "关闭",
                          value: 2
                        }]
                    },
                    {
                        label: "创建时间",
                        valueFormat: "yyyy-MM-dd hh:mm:ss",
                        width: "150",
                        disabled: true,
                        addDisplay: false,
                        editDispaly: false,
                        prop: "createTime"
                    },
                    {
                        label: "创建人",
                        disabled: true,
                        addDisplay: false,
                        editDispaly: false,
                        prop: "createUserName"
                    },
                ],
                group: [
                    {
                        label: '能耗属性',
                        prop: 'machineEnergyDto',
                        column: [
                            {
                                label: "额定电压(V)",
                                hide: true,
                                prop: "ratedVoltage",
                                bind: "machineEnergyResponse.ratedVoltage",
                                type:"number",
                                rules: [{
                                    required: true,
                                    message: "请输入额定电压",
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: "额定电流(A)",
                                hide: true,
                                prop: "ratedCurrent",
                                bind: "machineEnergyResponse.ratedCurrent",
                                type: "number",
                                rules: [{
                                    required: true,
                                    message: "请输入额定电流",
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: "额定功率(P)",
                                hide: true,
                                prop: "ratedPower",
                                bind: "machineEnergyResponse.ratedPower",
                                type: "number",
                                rules: [{
                                    required: true,
                                    message: "请输入额定功率",
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: "额定功率因素",
                                hide: true,
                                prop: "ratedPowerFactor",
                                bind: "machineEnergyResponse.ratedPowerFactor",
                                type: "number",
                                rules: [{
                                    required: true,
                                    message: "请输入额定功率因素",
                                    trigger: "blur"
                                }]
                            }
                        ]
                    },
                    {
                        label: '工艺属性',
                        prop: 'machineSpecParamResponse',
                        column: [
                            {
                                label: "理论效率(%)",
                                hide: true,
                                prop: "theoryEfficiency",
                                bind: "machineSpecParamResponse.theoryEfficiency",
                                type: "number",
                                rules: [{
                                    required: true,
                                    message: "请输入理论效率",
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: "卷绕直径(mm)",
                                hide: true,
                                prop: "windingDiameter",
                                bind: "machineSpecParamResponse.windingDiameter",
                                type: "number",
                                rules: [{
                                    required: true,
                                    message: "请输入卷绕直径",
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: "前罗拉直径(mm)",
                                hide: true,
                                prop: "frontRollerDiameter",
                                bind: "machineSpecParamResponse.frontRollerDiameter",
                                type: "number",
                                rules: [{
                                    required: true,
                                    message: "请输入前罗拉直径",
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: "锭盘直径(mm)",
                                hide: true,
                                prop: "wharveDiameter",
                                bind: "machineSpecParamResponse.wharveDiameter",
                                type: "number",
                                rules: [{
                                    required: true,
                                    message: "请输入锭盘直径",
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: "滚盘直径(mm)",
                                hide: true,
                                prop: "rolledPlateDiameter",
                                bind: "machineSpecParamResponse.rolledPlateDiameter",
                                type: "number",
                                rules: [{
                                    required: true,
                                    message: "请输入滚盘直径",
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: "纱管直径(mm)",
                                hide: true,
                                prop: "bobbinDiameter",
                                bind: "machineSpecParamResponse.bobbinDiameter",
                                type: "number",
                                rules: [{
                                    required: true,
                                    message: "请输入纱管直径",
                                    trigger: "blur"
                                }]
                            }
                        ]
                    },
                    {
                        label: '预警属性',
                        prop: 'machineWarningResponse',
                        column: [
                            {
                                label: "电流下限(A)",
                                hide: true,
                                prop: "yjsxcurrentMin",
                                bind: "machineWarningResponse.currentMin",
                                type: "number",
                                rules: [{
                                    required: true,
                                    message: "请输入电流下限",
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: "电流上限(A)",
                                hide: true,
                                prop: "yjsxcurrentMax",
                                bind: "machineWarningResponse.currentMax",
                                type: "number",
                                rules: [{
                                    required: true,
                                    message: "请输入电流上限",
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: "电压下限(V)",
                                hide: true,
                                prop: "yjsxvoltageMin",
                                bind: "machineWarningResponse.voltageMin",
                                type: "number",
                                rules: [{
                                    required: true,
                                    message: "请输入电压下限",
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: "电压上限(V)",
                                hide: true,
                                prop: "yjsxvoltageMax",
                                bind: "machineWarningResponse.voltageMax",
                                type: "number",
                                rules: [{
                                    required: true,
                                    message: "请输入电压上限",
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: "功率下限(P)",
                                hide: true,
                                prop: "yjsxpowerMin",
                                bind: "machineWarningResponse.powerMin",
                                type: "number",
                                rules: [{
                                    required: true,
                                    message: "请输入功率下限",
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: "功率上限(P)",
                                hide: true,
                                prop: "yjsxpowerMax",
                                bind: "machineWarningResponse.powerMax",
                                type: "number",
                                rules: [{
                                    required: true,
                                    message: "请输入功率上限",
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: "功率因素下限",
                                hide: true,
                                prop: "yjsxpowerFactorMin",
                                bind: "machineWarningResponse.powerFactorMin",
                                type: "number",
                                rules: [{
                                    required: true,
                                    message: "请输入功率因素下限",
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: "功率因素上限",
                                hide: true,
                                prop: "yjsxpowerFactorMax",
                                bind: "machineWarningResponse.powerFactorMax",
                                type: "number",
                                rules: [{
                                    required: true,
                                    message: "请输入功率因素上限",
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: "数据传输状态",
                                hide: true,
                                prop: "yjsxiotStatus",
                                bind: "machineWarningResponse.iotStatus",
                                type: "number",
                                rules: [{
                                    required: true,
                                    message: "请输入数据传输状态",
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: "停机时间上限值(H)",
                                hide: true,
                                prop: "yjsxdownTimeMax",
                                bind: "machineWarningResponse.downTimeMax",
                                type: "number",
                                rules: [{
                                    required: true,
                                    message: "请输入停机时间上限值",
                                    trigger: "blur"
                                }]
                            }
                        ]
                    }
                ]
            }
        }
    },
    created() {
      this.modelType = this.$route.query.pushData;
      console.log(this.modelType);
             console.log(123);
      selectByhmiOwner().then(res => {
        this.hmiOwnerList= res.data;
      });
      selectByplcOwner().then(res => {
        this.plcOwnerList= res.data;
      });
      selectBymanufacturer().then(res => {
        this.manufacturerList= res.data;
      });
      getdeviceType().then(res => {
        this.tableOption.column[3].dicData=res.data;
        this.deviceTypeList = res.data;
      });
      isWorking().then(res => {
          console.log('所属工序',res.data);
          this.tableOption.column[11].dicData=res.data;
          this.workingList = res.data;
      }),
      getCJAndGZ(1).then(res => {
        this.tableOption.column[10].dicData=res.data.data;
      });
      this.listReast();
    },
        methods: {
            goReport(item) {
                console.log(item)
                this.$router.push({ path: '/searchDashboard/reportStyle', query: { type: 'device', id: item.id, departmentId: item.departmentId } })
            },
        querySearchHMI(query,cb) {
          console.log(query);
          let list = query ? this.hmiOwnerList.filter(v => {
            if(v) {
              return v.indexOf(query) != -1;
            }
          }):this.hmiOwnerList;
          let randerList = [];
          list?list.forEach(v => {
            randerList.push({value:v});
          }):"";
          if(!query) {
            randerList = [];
            this.hmiOwnerList.forEach(v => {
              randerList.push({value:v});
            });
          }
          cb(randerList);
        },
        manufacturerFormquerySearch(query,cb) {
          let list = query ? this.manufacturerList.filter(v => {
            if(v) {
              return v.indexOf(query) != -1;
            }
          }):this.manufacturerList;
          let randerList = [];
          list?list.forEach(v => {
            randerList.push({value:v});
          }):"";
          console.log(list,query);
          if(!query) {
            randerList = [];
            this.manufacturerList.forEach(v => {
              randerList.push({value:v});
            });
          }
          cb(randerList);
        },
        querySearchplcOwner(query,cb) {
          let list = query ? this.plcOwnerList.filter(v => {
            if(v) {
              return v.indexOf(query) != -1;
            }
          }):this.plcOwnerList;
          let randerList = [];
          list?list.forEach(v => {
            randerList.push({value:v});
          }):"";
          console.log(list,query);
          if(!query) {
            randerList = [];
            this.plcOwnerList.forEach(v => {
              randerList.push({value:v});
            });
          }
          cb(randerList);
        },
        currentChange(pageCurrent) {
          this.page.pageCurrent = pageCurrent;
          this.listReast();
        },
        sizeChange(pageSize) {
          this.page.pageCurrent = 1;
          this.page.pageSize = pageSize;
          this.listReast();
        },
        handleDel(row) {
            this.$confirm("确定要删除吗","提示",{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                remove(row.id).then((res) => {
                if(res.data.code == "0000"){
                    this.$message({
                      type: "success",
                      message: "删除成功",
                    });
                  }
                    this.listReast();
                })
            })
        },
        //编辑
        handleUpdate(row,index,done) {
          console.log(row);
           if(row.prodTime!=""){
              row.prodTime+= " 00:00:000"
            }
            edit(row).then((res) => {
                 if(res.data.code == "0000"){
                    this.$message({
                      type: "success",
                      message: "编辑数据成功",
                    });
                  }
                console.log(res)
                this.listReast();
                done();
            })
        },
        //新增
        handleSave(row, done) {
            console.log(row)
            if(row.prodTime!=""){
              row.prodTime+= " 00:00:000"
            }
            add(row).then((res) => {
                 if(res.data.code == "0000"){
                    this.$message({
                      type: "success",
                      message: "新增数据成功",
                    });
                  }
                console.log(res)
                this.listReast();
                done();
            })
        },
        change(row) {
            changeStatus(row)
        },
        selectionChange(list) {
            console.log(list);
            list != "" ? this.disabled = false : this.disabled = true;
        },
        refreshChange() {
          this.listReast();
        },
        listReast(){
          //debugger
            list(this.page.pageCurrent, this.page.pageSize, this.modelType,this.processId, this.nameOrCode).then(res => {
              console.log(res)
              this.page.total = res.data.data.total;
              this.tableData = JSON.parse(JSON.stringify(res.data.data.items));
              this.$forceUpdate();
          });
        },
        envText: function() {
            return this.env
        }
  }
}
</script>
<style scoped>
    .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        padding-left: 10px;
    }
</style>