var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "10px" }
              },
              [_vm._v(_vm._s(_vm.$t("PURCHASE ORDER")))]
            )
          ]),
          [
            _c(
              "el-row",
              { staticClass: "topFromSearch", attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  {
                    staticClass: "fromOutDiv",
                    attrs: { xs: 24, sm: 16, md: 16, lg: 19, xl: 20 }
                  },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("PO")) + ":")]),
                        _c("el-input", {
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.poVal,
                            callback: function($$v) {
                              _vm.poVal = $$v
                            },
                            expression: "poVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("BUSINESS SERIAL NUMBER INTERNAL")) +
                              ":"
                          )
                        ]),
                        _c("el-input", {
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.businessnoinVal,
                            callback: function($$v) {
                              _vm.businessnoinVal = $$v
                            },
                            expression: "businessnoinVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("BUSINESS SERIAL NUMBER EXTERNAL")) +
                              ":"
                          )
                        ]),
                        _c("el-input", {
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.businessnooutVal,
                            callback: function($$v) {
                              _vm.businessnooutVal = $$v
                            },
                            expression: "businessnooutVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("ProductCode")) + ":")
                        ]),
                        _c("el-input", {
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.codeVal,
                            callback: function($$v) {
                              _vm.codeVal = $$v
                            },
                            expression: "codeVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("BATCH")) + ":")]),
                        _c("el-input", {
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.batchVal,
                            callback: function($$v) {
                              _vm.batchVal = $$v
                            },
                            expression: "batchVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", { attrs: { id: "venSpan" } }, [
                          _vm._v(_vm._s(_vm.$t("VENDOR")) + ":")
                        ]),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              multiple: "",
                              "collapse-tags": "",
                              placeholder: "",
                              clearable: "true"
                            },
                            on: {
                              focus: function($event) {
                                return _vm.focusDiv($event)
                              },
                              blur: function($event) {
                                return _vm.blurDiv($event)
                              },
                              change: _vm.venchang
                            },
                            model: {
                              value: _vm.vendorVal.ids,
                              callback: function($$v) {
                                _vm.$set(_vm.vendorVal, "ids", $$v)
                              },
                              expression: "vendorVal.ids"
                            }
                          },
                          _vm._l(this.vendorData, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("BEINGTIME")) + ":")]),
                        _c("el-date-picker", {
                          staticClass: "dataicon",
                          staticStyle: { width: "100%" },
                          attrs: {
                            type: "date",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            "prefix-icon": ""
                          },
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.beginTimeVal,
                            callback: function($$v) {
                              _vm.beginTimeVal = $$v
                            },
                            expression: "beginTimeVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("ENDTIME")) + ":")]),
                        _c("el-date-picker", {
                          staticClass: "dataicon",
                          staticStyle: { width: "100%" },
                          attrs: {
                            type: "date",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            "prefix-icon": ""
                          },
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.endTimeVal,
                            callback: function($$v) {
                              _vm.endTimeVal = $$v
                            },
                            expression: "endTimeVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("COLOR")) + ":")]),
                        _c("el-input", {
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.colorVal,
                            callback: function($$v) {
                              _vm.colorVal = $$v
                            },
                            expression: "colorVal"
                          }
                        })
                      ],
                      1
                    ),
                    !_vm.isBrandBusiness
                      ? _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                          },
                          [
                            _c("span", { attrs: { id: "otherStatusSpan" } }, [
                              _vm._v(_vm._s(_vm.$t("STATE")) + ":")
                            ]),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "collapse-tags": "",
                                  placeholder: "",
                                  clearable: "true"
                                },
                                on: {
                                  focus: function($event) {
                                    return _vm.focusDiv($event)
                                  },
                                  blur: function($event) {
                                    return _vm.blurDiv($event)
                                  },
                                  change: _vm.otherStatuschang
                                },
                                model: {
                                  value: _vm.otherStatusVal,
                                  callback: function($$v) {
                                    _vm.otherStatusVal = $$v
                                  },
                                  expression: "otherStatusVal"
                                }
                              },
                              _vm._l(this.otherStatusData, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isBrandBusiness
                      ? _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                          },
                          [
                            _c("span", { attrs: { id: "traingStatusSpan" } }, [
                              _vm._v(_vm._s(_vm.$t("STATE")) + ":")
                            ]),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "collapse-tags": "",
                                  placeholder: "",
                                  clearable: "true"
                                },
                                on: {
                                  focus: function($event) {
                                    return _vm.focusDiv($event)
                                  },
                                  blur: function($event) {
                                    return _vm.blurDiv($event)
                                  },
                                  change: _vm.traingStatuschang
                                },
                                model: {
                                  value: _vm.traingStatusVal,
                                  callback: function($$v) {
                                    _vm.traingStatusVal = $$v
                                  },
                                  expression: "traingStatusVal"
                                }
                              },
                              _vm._l(this.traingStatusData, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("UPDATE START DATE")) + ":")
                        ]),
                        _c("el-date-picker", {
                          staticClass: "dataicon",
                          staticStyle: { width: "100%" },
                          attrs: { type: "date", "prefix-icon": "" },
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.UTbeginTimeVal,
                            callback: function($$v) {
                              _vm.UTbeginTimeVal =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "UTbeginTimeVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("UPDATE END DATE")) + ":")
                        ]),
                        _c("el-date-picker", {
                          staticClass: "dataicon",
                          staticStyle: { width: "100%" },
                          attrs: { type: "date", "prefix-icon": "" },
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.UTendTimeVal,
                            callback: function($$v) {
                              _vm.UTendTimeVal =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "UTendTimeVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        staticStyle: {
                          "justify-content": "flex-start",
                          height: "38px",
                          "align-items": "center",
                          display: "flex"
                        },
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c(
                          "el-checkbox",
                          {
                            staticClass: "othChe",
                            model: {
                              value: _vm.StatusVal,
                              callback: function($$v) {
                                _vm.StatusVal = $$v
                              },
                              expression: "StatusVal"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("DeletedData")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    staticStyle: { "padding-top": "10px" },
                    attrs: { xs: 24, sm: 8, md: 8, lg: 5, xl: 4 }
                  },
                  [
                    _c("el-button", {
                      staticClass: "moreBtn",
                      staticStyle: { "margin-right": "0" },
                      attrs: {
                        size: "small",
                        icon: "iconfont icon-24px",
                        circle: ""
                      },
                      on: { click: _vm.moreFrom }
                    }),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "0px !important" },
                        attrs: { type: "primary", size: "small", plain: "" },
                        on: { click: _vm.searchFrom }
                      },
                      [_vm._v(_vm._s(_vm.$t("SEARCH")))]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          "margin-left": "10px",
                          "margin-right": "0px!important"
                        },
                        attrs: {
                          type: "primary",
                          size: "small",
                          icon: "el-icon-plus"
                        },
                        on: { click: _vm.addPurOrd }
                      },
                      [_vm._v(_vm._s(_vm.$t("ADD")))]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("avue-crud", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              attrs: {
                option: _vm.setData.tableOpt,
                data: _vm.tableData,
                "row-style": _vm.rowStyle,
                page: _vm.page
              },
              on: {
                "update:page": function($event) {
                  _vm.page = $event
                },
                "current-change": _vm.currentChange
              },
              scopedSlots: _vm._u([
                {
                  key: "Speed",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    var size = ref.size
                    var type = ref.type
                    return [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: { effect: "light", placement: "bottom-start" }
                        },
                        [
                          _vm._l(row.SpeedContent, function(item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                attrs: { slot: "content" },
                                slot: "content"
                              },
                              [_vm._v(_vm._s(_vm.formatterColumn(item)))]
                            )
                          }),
                          _c("el-link", { attrs: { type: "warning" } }, [
                            _vm._v(_vm._s(row.Speed))
                          ])
                        ],
                        2
                      )
                    ]
                  }
                },
                {
                  key: "menu",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    var size = ref.size
                    var type = ref.type
                    return [
                      row.Status >= 0
                        ? _c("el-button", {
                            attrs: {
                              icon: "el-icon-edit-outline",
                              type: "text",
                              size: "medium",
                              title: _vm.$t("Edit")
                            },
                            on: {
                              click: function($event) {
                                return _vm.editPur(row)
                              }
                            }
                          })
                        : _vm._e(),
                      row.Status == 0 &&
                      row.ApproveStatus != 100 &&
                      row.LockedStatus != 1
                        ? _c("el-button", {
                            attrs: {
                              icon: "el-icon-delete-solid",
                              type: "text",
                              size: "medium",
                              title: _vm.$t("Delete")
                            },
                            on: {
                              click: function($event) {
                                return _vm.delPur(row)
                              }
                            }
                          })
                        : _vm._e(),
                      row.Status == 1
                        ? _c("el-button", {
                            attrs: {
                              icon: "el-icon-refresh-left",
                              type: "text",
                              size: "medium",
                              title: _vm.$t("RecoverData")
                            },
                            on: {
                              click: function($event) {
                                return _vm.recover(row)
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "expand",
                  fn: function(props) {
                    return [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: props.row.Details,
                            "header-cell-style": _vm.tableHeaderColor
                          }
                        },
                        [
                          _c("el-table-column", { attrs: { type: "index" } }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "Speed",
                              align: "center",
                              label: _vm.$t("DEGREE OF COMPLETION")
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    var index = ref.index
                                    var size = ref.size
                                    var type = ref.type
                                    return [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "light",
                                            placement: "bottom-start"
                                          }
                                        },
                                        [
                                          _vm._l(row.SpeedContent, function(
                                            item,
                                            index
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                attrs: { slot: "content" },
                                                slot: "content"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatterColumn(item)
                                                  )
                                                )
                                              ]
                                            )
                                          }),
                                          _c(
                                            "el-link",
                                            { attrs: { type: "warning" } },
                                            [_vm._v(_vm._s(row.Speed))]
                                          )
                                        ],
                                        2
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "BusinessNoIn",
                              label: _vm.$t("BUSINESS SERIAL NUMBER INTERNAL")
                            }
                          }),
                          _vm.showOut
                            ? _c("el-table-column", {
                                attrs: {
                                  prop: "BusinessNoOut",
                                  label: _vm.$t(
                                    "BUSINESS SERIAL NUMBER EXTERNAL"
                                  )
                                }
                              })
                            : _vm._e(),
                          _c("el-table-column", {
                            attrs: {
                              prop: "Code",
                              label: _vm.$t("ProductCode")
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "ProductName",
                              label: _vm.$t("Product Name")
                            }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "Batch", label: _vm.$t("BATCH") }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "Color", label: _vm.$t("COLOR") }
                          }),
                          !_vm.isBrandBusiness
                            ? _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "Status",
                                  label: _vm.$t("STATUS"),
                                  formatter: _vm.formatterStatus
                                }
                              })
                            : _vm._e(),
                          _vm.isBrandBusiness
                            ? _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "TrackingApproveStatus",
                                  label: _vm.$t("STATUS"),
                                  formatter: _vm.formatterTrackingStatus
                                }
                              })
                            : _vm._e(),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: _vm.$t("UPDATE TIME")
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      scope.row.IsApproveAfChange
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                placement: "bottom"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "content" },
                                                  slot: "content"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "Modify again after approval"
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  )
                                                ]
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                                staticStyle: {
                                                  "margin-left": "3px",
                                                  "font-size": "14px"
                                                }
                                              })
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "margin-left": "10px" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.transformDate(
                                                scope.row.LinkageUpdateDateTime
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "200px;",
                              align: "center",
                              label: _vm.$t("OPERATION")
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    var index = ref.index
                                    var size = ref.size
                                    var type = ref.type
                                    return [
                                      _vm.LastSectionlist.indexOf(
                                        row.VendorSectionId
                                      ) == -1
                                        ? _c("el-button", {
                                            attrs: {
                                              icon: "icon-dingwei1",
                                              type: "text",
                                              size: "medium",
                                              title: _vm.$t("Tracing")
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.tracingPur(row)
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      !_vm.isBrandBusiness
                                        ? _c("el-button", {
                                            attrs: {
                                              icon: "icon-shangchuan",
                                              type: "text",
                                              size: "medium",
                                              title: _vm.$t("Upload")
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.upload(
                                                  row,
                                                  index,
                                                  size,
                                                  type
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      row.ApproveStatus < 0
                                        ? _c("el-button", {
                                            attrs: {
                                              icon: "el-icon-thumb",
                                              type: "text",
                                              size: "medium",
                                              title: _vm.$t("VIEW AUDIT INFO")
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.ViewAuditInfo(
                                                  row,
                                                  index,
                                                  size,
                                                  type
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            }),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.$t("ChooseUploadBrand"),
                  visible: _vm.BranddialogTableVisible,
                  "modal-append-to-body": false,
                  width: "30%",
                  top: "1vh"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.BranddialogTableVisible = $event
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("Brand"), "label-width": "120px" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "calc(100% - 115px)" },
                        attrs: { filterable: "", placeholder: "" },
                        model: {
                          value: _vm.brandChoose,
                          callback: function($$v) {
                            _vm.brandChoose = $$v
                          },
                          expression: "brandChoose"
                        }
                      },
                      _vm._l(_vm.brandList, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: { "text-align": "center", padding: "50px 0" }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            _vm.submitBrandChoose()
                            _vm.BranddialogTableVisible = false
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Yes")))]
                    ),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            _vm.BranddialogTableVisible = false
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Cancel")))]
                    )
                  ],
                  1
                ),
                _c("el-form-item")
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.$t("REJECT"),
                  visible: _vm.RejectdialogTableVisible,
                  "modal-append-to-body": false,
                  width: "70%",
                  top: "1vh"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.RejectdialogTableVisible = $event
                  }
                }
              },
              [
                !_vm.isViewAudit
                  ? _c(
                      "el-form",
                      {
                        ref: "Rejectrules",
                        attrs: {
                          model: _vm.RejectEdit,
                          rules: _vm.Rejectrules,
                          "label-position": "top",
                          "label-width": "150px"
                        }
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24, sm: 24, md: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("RESUBMIT DESCRIPTION"),
                                      required: true,
                                      prop: "Remarks",
                                      rules: _vm.Rejectrules.Remarks
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        type: "textarea",
                                        maxlength: "300",
                                        "show-word-limit": "",
                                        autosize: { minRows: 4, maxRows: 6 }
                                      },
                                      model: {
                                        value: _vm.RejectEdit.Remarks,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.RejectEdit,
                                            "Remarks",
                                            $$v
                                          )
                                        },
                                        expression: "RejectEdit.Remarks"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              padding: "20px 0"
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.submitReject("Rejectrules")
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Yes")))]
                            ),
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.RejectdialogTableVisible = false
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Cancel")))]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("avue-crud", {
                  attrs: {
                    option: _vm.tableOptRejectList,
                    data: _vm.tableDataRejectList,
                    "row-style": _vm.rowStyle,
                    page: _vm.pageRejectList
                  },
                  on: {
                    "update:page": function($event) {
                      _vm.pageRejectList = $event
                    },
                    "current-change": _vm.currentRejectListChange
                  }
                })
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }