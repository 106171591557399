<template>
    <div class="pvhFactory">
        <basic-container>
            <el-container>
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane :label="$t('DownloadPDF')" name="first"></el-tab-pane>
                    <el-tab-pane :label="$t('PDFLog')" name="second"></el-tab-pane>
                </el-tabs>
                <div class="layTop">
                    <div class="Title">{{$t('DownloadPDF')}}</div>
                </div>
                <el-main style="overflow: hidden;">
                    <div  class="topInfoReview">
                        <el-row>
                            <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                <span class="spanRight"><i class="el-icon-s-custom"></i>{{$t('Supplier')}} : {{formData.supplierVal}}</span>
                            </el-col>
                            <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                <span class="spanRight"><i class="iconfont icon-rili"></i>{{$t('At')}} : {{formData.OrderDateVal}}</span>
                            </el-col>
                            <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                <span><i class="el-icon-attract"></i>{{$t('BATCH')}} : {{formData.BatchVal}}</span>
                                <span class="spanLeft"></span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                <span class="spanRight"><i class="el-icon-document"></i>{{$t('PO')}} : {{formData.poVal}}</span>
                            </el-col>
                            <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                <span><i class="el-icon-document"></i>{{$t('BUSINESS SERIAL NUMBER INTERNAL')}} : {{formData.BusinessNoInVal}}</span>
                            </el-col>
                            <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                <span class="spanRight"><i class="el-icon-document"></i>{{$t('BUSINESS SERIAL NUMBER EXTERNAL')}} : {{formData.BusinessNoOutVal}}</span>
                                <span class="spanLeft"></span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                <span class="spanRight"><i class="el-icon-coin"></i>{{$t('Product Name')}} : {{formData.ProductNameVal}}</span>
                            </el-col>
                            <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                <span class="spanRight"><i class="el-icon-brush"></i>{{$t('COLOR')}} : {{formData.ColorVal}}</span>
                            </el-col>
                            <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                <span class="spanRight"><i class="el-icon-shopping-cart-full"></i>{{$t('QUANTITY')}} : {{formData.NumberVal}}/{{formData.UnitNameVal}}</span>
                                <span class="spanLeft"></span>
                            </el-col>
                        </el-row>
                    </div>
                </el-main>
                <el-footer style="height:850px !important;background: #eee;padding: inherit;margin-top: 20px;">
                    <el-row style="background: #ffffff;">
                        <el-col style="margin-bottom: 0.7rem !important;text-align:left;" :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
                            <el-tooltip class="item" effect="dark" placement="bottom" v-if="isNeedRegenerate">
                                <div slot="content">
                                    {{ $t('Need to regenerate pdf') }}
                                </div>
                                <i class="el-icon-s-opportunity"
                                   style="margin-left: 3px; font-size: 16px;COLOR: RED!important;"></i>
                            </el-tooltip>
                            <span style="margin-left: 10px;COLOR: RED!important;" v-if="isNeedRegenerate">{{$t('Please regenerate the PDF. The declaration data has been modified')}}</span>
                            <span style="margin-left: 10px">{{$t('Current PDF file generation time')}} : </span>
                            <span style="margin-left: 10px">{{transformDate(pdfDt) }}</span>
                        </el-col>
                        <el-col style="margin-bottom: 0.7rem !important;text-align:right;" :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                            <el-button type="warning" size="small" @click="regenerate">{{$t('REGENERATE')}}</el-button>
                            <el-button type="primary" size="small" @click="downloadFile">{{$t('DOWNLOAD')}}</el-button>
                        </el-col>
                        <!--<el-col style="margin-bottom: 0rem !important;" :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
        <el-radio-group v-model="operationType">
            <el-radio :label="1">{{$t('READ-ONLY RIGHTS')}}</el-radio>
            <el-radio :label="2">{{$t('READ-WRITE PERMISSION')}}</el-radio>

        </el-radio-group>

    </el-col>
    <el-col style="margin-bottom: 0rem !important;" :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
        <span style=" font-size: 0.875rem;">{{$t('Download file operation password')}}:</span> <el-input v-model="token"  size="small" style="width: calc(100% - 115px);"></el-input>
    </el-col>-->
                    </el-row>
                    <el-row style="text-align: center;">
                        <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
                        </el-col>
                        <el-col v-loading="loadedRatio == 0" :xs="16" :sm="16" :md="16" :lg="16" :xl="16">
                           <div style="height: 760px;overflow-y: auto; margin: 20px 10px;">                                
                                <pdf
                                    @page-loaded="loadedRatio = $event"
                                    v-for="page in pageNum"
                                    :key="page"
                                    :page="page"
                                    :src="url"
                                    style="margin:0 0 10px 0;">
                                </pdf>                                
                            </div>

                        </el-col>
                        <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
                        </el-col>
                    </el-row>
                </el-footer>
            </el-container>
        </basic-container>
    </div>
</template>
<style scoped>
    .dataicon i {
        display: none;
    }

    .topInfoReview i {
        color: #B2EBE9;
        margin-right: 10px;
        font-size: 20px;
    }

    .topInfoReview p {
        width: calc(100% - 160px);
    }


    .othChe .el-checkbox__label {
        color: #409eff !important;
    }
    .topInfoReview {
        background: linear-gradient(to right,#4A4A74,#212146);
        border-radius: 10px;
        padding: 30px 20px 10px;
        color: #fff;
        width: 100%;
        font-size: 14px;
        float: left;
    }
</style>


<script>
    import { add, GetOrderPackage } from "@/api/orderPackage";
    import { TracingUnifiedIsBool } from "@/api/unified";
    import pdf from 'vue-pdf'
    import { pvhApiBase } from "@/config/env";
    import { POInfo } from "@/api/purchaseOrd";
    import { getToken, getGrop } from '@/util/auth'

    export default {
        components: { pdf },
        data() {
            return {
                formData: {
                    soVal: '', fromVal: '', POproductVal: '', batchVal: '', codeVal: '', colorVal: '', numberVal: '', unitVal: '', odateVal: '', productVal: '', remarkVal: '', departVal: '',
                    OrderDateVal: '', supplierVal: '', poVal: '', BusinessNoInVal: '', BusinessNoOutVal: '', ProductNameVal: '', BatchVal: '', ColorVal: '', NumberVal: '', UnitNameVal: '',
                    IndexNumberVal: 0,
                },
                pdfDt: "",
                isNeedRegenerate:false,
                activeName: 'first',                
                srcObject: '',
                operationType: 1,
                token: '',
                downUrl: '',
                obj: {},
                loadId: '',
                loadOrderId: '',
                tableData: [],
                sourceData: [],
                pdfBase64: '',
                filePath: '',
                pdf: '',
                url: '',
                //懒加载页数
                pageNum: 1,
                //总页数
                numPages: '',
                height: '',
                loadedRatio: 0,
                loading: true
            }
        },
        activated () {
            this.getScroll()
        },
        mounted() {//实时监听滚动条的变化
            this.init();
            window.addEventListener('scroll', this.getScroll,true)
        },
        destroyed() {//注意：监听的函数要在关闭页面时销毁掉,不然会影响到其它页面
            window.removeEventListener('scroll', this.getScroll)
        },
        methods: {
            init() {
                //  this.token = this.S4();
                this.loadOrderId = this.$route.query.OrderId;
                this.topInit();
                TracingUnifiedIsBool("PdfIsNeedRegenerate", this.loadOrderId).then(res => {
                    this.isNeedRegenerate = res.data;
                });
                GetOrderPackage(this.loadOrderId).then(res => {
                    var o = res.data;
                    this.pdfDt = o.createTime;
                    if (o.status == 0) {
                        if (o.id != '') {
                            this.loadId = o.id;
                            console.log(this.loadId);
                            this.url = pvhApiBase + "Tracing/GetPdfPreviewFileStream?packageId=" + this.loadId;

                            const headers = {
                                Authorization: 'Bearer ' + getToken(),
                                'corpId': getGrop()
                            }

                            this.url = pdf.createLoadingTask({
                                url: this.url,
                                httpHeaders: headers
                            });

                            this.url.promise.then(pdf => {
                                this.pdf = pdf
                                this.numPages = pdf.numPages;
                                //滚动加载
                                this.getScroll();
                            });
                            //新增操作记录
                            this.addLog(2);
                        }
                    }
                    else {
                        //生成文件
                        this.$message({ showClose: true, message: this.$t('NoPreviewedPrinting'), type: "warning" });
                    }
                })
            },
            handleClick() {
                if (this.activeName == "second") {
                    this.$router.push({ path: '/pvh_operation/pdfLog', query: { ID: this.loadId, OrderId: this.loadOrderId } })
                }
            },
            getScroll(e) {
                console.log(e.target.scrollTop ,'滚动高度')
                const scrollTop = e.target.scrollTop
                this.height = 500

                console.log(this.pageNum, this.height*(this.pageNum-1),scrollTop,this.height*(this.pageNum+1))
                if ((scrollTop > this.height*(this.pageNum-1))&&(scrollTop < this.height*(this.pageNum+1))) { //懒加载条件限制判断
                    if (this.pageNum < this.numPages) {//做一次判断
                        //达到条件后执行这个函数
                        this.pageNum += 1
                        // this.pdf.getPage(this.pageNum).then(page => {
                        //   console.log(page,'page')
                        // })
                        console.log('达到加载条件，执行加载');
                    }
                }
            },
            // 获取pdf页数
            getNumPages () {
                console.log(this.url,'page')
                let loadingTask = pdf.createLoadingTask(this.url)
                loadingTask.promise.then(pdf => {
                    this.numPages = pdf.numPages
                }).catch(err => {
                    this.numPages = 0
                    console.error('pdf 加载失败', err)
                })
            },
            addLog(type) {
                let paramrow = {
                    orderPackageId: this.loadId,
                    type: type,
                    remark: "",
                    status: 0,
                    createTime: '2021-05-01T08:00',
                    createUserid: '',
                    corporationId: ''
                };
                add(paramrow).then(() => {
                }).catch((erro) => { console.log(erro) });
            },
            regenerate() {
                TracingUnifiedIsBool("PdfIsJustCreateExist", this.loadOrderId).then(res => {
                    var IsExist = res.data;
                    this.loadingTask = null;
                    if (!IsExist) {
                        this.$confirm(this.$t('Fifteen minutes ago, the file was generated. Continue build'), this.$t('Tips'), {
                            confirmButtonText: this.$t('Yes'),
                            cancelButtonText: this.$t('Cancel'),
                            type: "warning"
                        }).then(() => {
                            this.$router.push({ path: '/pvh_operation/loadSpeed', query: { OrderId: this.loadOrderId } });
                        })
                    } else {
                        this.$router.push({ path: '/pvh_operation/loadSpeed', query: { OrderId: this.loadOrderId } });
                    }
                })
            },
            downloadFile() {
                // 调用子组件的下载方法
                console.log(this.formData.IndexNumberVal);
                console.log(this.formData.poVal);
                var po_new = this.formData.poVal;
                if (po_new != undefined) {
                    var pattern = new RegExp("[`~!@#$%^&-+=\\?\"|,;'\\[\\]·~！@#￥%……&*（）+=\\{\\}\\|《》？：“”【】、；‘'，。\\、\\-<>]"); //[]内输入你要过滤的字符
                    var rs = "";
                    for (var i = 0; i < po_new.length; i++) {
                        rs += po_new.substr(i, 1).replace(pattern, '');
                    }
                    po_new = rs;

                }
                else
                {
                    po_new = "";
                }

                console.log(po_new);
                var fileName = "GI_TR_" + po_new + "_" + this.formData.IndexNumberVal;
                //var eType = "";
                //if (this.operationType == 1) {
                //    eType = "readOnly";
                //    fileName = this.$t('READ-ONLY RIGHTS');
                //} else {
                //    eType = "print";
                //    fileName = this.$t('READ-WRITE PERMISSION');
                //}
                //新增操作记录
                this.addLog(4);
                this.downUrl = pvhApiBase + "Tracing/GetPdfPreviewFileStream?packageId=" + this.loadId;
                this.downloadPDF(this.downUrl, fileName+ ".pdf");
            },
            S4() {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            },
            guid() {
                // return (this.S4() + this.S4() + "-" + this.S4() + "-" + this.S4() + "-" + this.S4() + "-" + this.S4() + this.S4() + this.S4());
                return ("_" + this.S4() + this.S4() + this.S4());
            },
            downloadPDF(url, fileName) {
                fetch(url).then(function (response) {
                    if (response.ok) {
                        return response.arrayBuffer()
                    }
                    throw new Error('Network response was not ok.')
                }).then(function (arraybuffer) {
                    let blob = new Blob([arraybuffer], {
                        type: `application/pdf;charset-UTF-8` // word文档为msword,pdf文档为pdf
                    })

                    let objectURL = URL.createObjectURL(blob)

                    let downEle = document.createElement('a')
                    let fname = fileName // 下载文件的名字
                    // let fname = `download` // 下载文件的名字
                    downEle.href = objectURL
                    downEle.setAttribute('download', fname)
                    document.body.appendChild(downEle)
                    downEle.click()
                }).catch(function (error) {
                    console.log('There has been a problem with your fetch operation: ', error.message)
                })
            },
            async topInit() {
                await POInfo(this.loadOrderId, this.loadOrderId, "", "").then(res => {
                    var o = res.data;
                    this.formData.supplierVal = o.POInfo.VendorCorporationId;
                    this.formData.batchVal = o.POInfoDetails.Batch;
                    this.formData.poVal = o.POInfo.Po;
                    this.formData.BusinessNoInVal = o.POInfoDetails.BusinessNoIn;
                    this.formData.BusinessNoOutVal = o.POInfoDetails.BusinessNoOut;
                    this.formData.ProductNameVal = o.POInfoDetails.ProductName;
                    this.formData.BatchVal = o.POInfoDetails.Batch;
                    this.formData.ColorVal = o.POInfoDetails.Color;
                    this.formData.NumberVal = o.POInfoDetails.Number;
                    this.formData.UnitNameVal = o.POInfoDetails.UnitId;
                    this.formData.IndexNumberVal = o.POInfoDetails.IndexNumber+1;
                    this.formData.OrderDateVal = o.POInfo.OrderDate !== '' ? o.POInfo.OrderDate.substr(0, 10) : '';
                })
            },
            //标准时间格式转yyyy-MM-dd HH:mm:ss
            transformDate(date) {
                if (date) {
                    let dt = new Date(date);
                    return dt.getFullYear() + '-' +
                        ((dt.getMonth() + 1) < 10 ? ('0' + (dt.getMonth() + 1)) : (dt.getMonth() + 1)) + '-' +
                        (dt.getDate() < 10 ? ('0' + dt.getDate()) : dt.getDate()) + ' ' +
                        (dt.getHours() < 10 ? ('0' + dt.getHours()) : dt.getHours()) + ':' +
                        (dt.getMinutes() < 10 ? ('0' + dt.getMinutes()) : dt.getMinutes()) + ':' +
                        (dt.getSeconds() < 10 ? ('0' + dt.getSeconds()) : dt.getSeconds())
                } else {
                    return ''
                }
            },
        },
    }
</script>