var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wklnChange",
      staticStyle: {
        background: "transparent",
        position: "relative",
        top: "70px",
        left: "0"
      },
      attrs: { id: "home_container" }
    },
    [
      _c(
        "el-dialog",
        {
          staticStyle: { marginTop: "-100px", height: "1400px" },
          attrs: {
            title: "机台翻改",
            visible: _vm.dialogVisible,
            width: "70%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "header",
            { staticStyle: { height: "100%", overflow: "auto" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.goForm, "label-width": "120px" }
                },
                [
                  _c("avue-crud", {
                    staticStyle: {
                      marginTop: "-50px",
                      display: "block",
                      overflow: "scroll !important",
                      height: "270px"
                    },
                    attrs: { option: _vm.tableOption, data: _vm.planData },
                    on: { "current-row-change": _vm.handleCurrentRowChange },
                    model: {
                      value: _vm.planModel,
                      callback: function($$v) {
                        _vm.planModel = $$v
                      },
                      expression: "planModel"
                    }
                  }),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" }, attrs: { id: "yunz" } },
                    [
                      _c(
                        "header",
                        { staticStyle: { width: "25%", fontWeight: "700" } },
                        [
                          _c(
                            "h3",
                            {
                              staticStyle: {
                                height: "40px",
                                "background-color": "#ecf5ff",
                                "line-height": "40px",
                                "text-align": "center"
                              }
                            },
                            [_vm._v("翻改前")]
                          )
                        ]
                      ),
                      _c(
                        "header",
                        { staticStyle: { width: "25%", fontWeight: "700" } },
                        [
                          _c(
                            "h3",
                            {
                              staticStyle: {
                                height: "40px",
                                "background-color": "#B1D6FF",
                                "line-height": "40px",
                                "text-align": "center"
                              }
                            },
                            [_vm._v("翻改工艺")]
                          )
                        ]
                      ),
                      _c(
                        "header",
                        { staticStyle: { width: "25%", fontWeight: "700" } },
                        [
                          _c(
                            "h3",
                            {
                              staticStyle: {
                                height: "40px",
                                "background-color": "#B1D6FF",
                                "line-height": "40px",
                                "text-align": "center"
                              }
                            },
                            [_vm._v("翻改后")]
                          )
                        ]
                      ),
                      _c(
                        "header",
                        { staticStyle: { width: "25%", fontWeight: "700" } },
                        [
                          _c(
                            "h3",
                            {
                              staticStyle: {
                                height: "40px",
                                "background-color": "#B1D6FF",
                                "line-height": "40px",
                                "text-align": "center"
                              }
                            },
                            [_vm._v("对比差值")]
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        overflow: "scroll !important",
                        height: "310px"
                      },
                      attrs: { id: "yunz" }
                    },
                    [
                      _c(
                        "header",
                        { staticStyle: { width: "25%", fontWeight: "700" } },
                        [
                          _c("avue-form", {
                            staticStyle: { marginTop: "20px" },
                            attrs: { option: _vm.optionBefore },
                            model: {
                              value: _vm.lastform,
                              callback: function($$v) {
                                _vm.lastform = $$v
                              },
                              expression: "lastform"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "header",
                        { staticStyle: { width: "25%", fontWeight: "700" } },
                        [
                          _c("avue-form", {
                            staticStyle: { marginTop: "20px" },
                            attrs: { option: _vm.optionNo },
                            model: {
                              value: _vm.nextform,
                              callback: function($$v) {
                                _vm.nextform = $$v
                              },
                              expression: "nextform"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "header",
                        { staticStyle: { width: "25%", fontWeight: "700" } },
                        [
                          _c("avue-form", {
                            staticStyle: { marginTop: "20px" },
                            attrs: { option: _vm.optionAfter },
                            model: {
                              value: _vm.afterform,
                              callback: function($$v) {
                                _vm.afterform = $$v
                              },
                              expression: "afterform"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "header",
                        { staticStyle: { width: "25%", fontWeight: "700" } },
                        [
                          _c("avue-form", {
                            staticClass: "difference",
                            staticStyle: { marginTop: "20px" },
                            attrs: { option: _vm.optionDifference },
                            model: {
                              value: _vm.differenceform,
                              callback: function($$v) {
                                _vm.differenceform = $$v
                              },
                              expression: "differenceform"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "p",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "-30px" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "outFormDiv",
          staticStyle: {
            display: "flex",
            padding: "15px",
            background: "#F2F2F2",
            position: "fixed",
            top: "100px",
            right: "0",
            width: "calc(100% - 13.75rem)",
            "z-index": "1",
            "box-sizing": "border-box"
          }
        },
        [
          _vm.topIsMenu
            ? _c(
                "div",
                { staticClass: "formList" },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "demo-form-inline",
                      staticStyle: { display: "flex" },
                      attrs: {
                        inline: true,
                        model: _vm.formInline,
                        labelWidth: "80px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "calc(33.33% - 23.33px)",
                            display: "flex"
                          }
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              clearable: "",
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: "开台时间"
                            },
                            model: {
                              value: _vm.timeSelect,
                              callback: function($$v) {
                                _vm.timeSelect = $$v
                              },
                              expression: "timeSelect"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "calc(33.33% - 23.33px)",
                            display: "flex"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "设备机型" },
                              on: { change: _vm.workChange },
                              model: {
                                value: _vm.machineModelId,
                                callback: function($$v) {
                                  _vm.machineModelId = $$v
                                },
                                expression: "machineModelId"
                              }
                            },
                            _vm._l(_vm.machineoptions, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "calc(33.33% - 23.33px)",
                            display: "flex"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "产品品种" },
                              on: { change: _vm.productChange },
                              model: {
                                value: _vm.productId,
                                callback: function($$v) {
                                  _vm.productId = $$v
                                },
                                expression: "productId"
                              }
                            },
                            _vm._l(_vm.productoptions, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "calc(33.33% - 23.33px)",
                            display: "flex"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "车间选择" },
                              on: { change: _vm.getChenJianId },
                              model: {
                                value: _vm.workshopId,
                                callback: function($$v) {
                                  _vm.workshopId = $$v
                                },
                                expression: "workshopId"
                              }
                            },
                            _vm._l(_vm.chejianList, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "calc(33.33% - 23.33px)",
                            display: "flex"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "设备编号" },
                              model: {
                                value: _vm.machineId,
                                callback: function($$v) {
                                  _vm.machineId = $$v
                                },
                                expression: "machineId"
                              }
                            },
                            _vm._l(_vm.machineCodeoptions, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.code, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "calc(33.33% - 23.33px)",
                            display: "flex"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "产品批次" },
                              model: {
                                value: _vm.batchCode,
                                callback: function($$v) {
                                  _vm.batchCode = $$v
                                },
                                expression: "batchCode"
                              }
                            },
                            _vm._l(_vm.productBatchOptions, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.batchCode,
                                  value: item.batchCode
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.topIsMenu
            ? _c(
                "div",
                {
                  staticClass: "btn",
                  staticStyle: {
                    width: "240px",
                    "text-align": "right",
                    display: "flex",
                    "align-items": "start"
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "margin-right": "5px",
                        "margin-top": "2px"
                      },
                      attrs: { type: "primary", size: "medium" },
                      on: { click: _vm.onSearch }
                    },
                    [_vm._v(_vm._s(_vm.$t("search")))]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.topIsCheMenu
            ? _c("div", {
                staticStyle: { width: "100%", "text-align": "right" }
              })
            : _vm._e()
        ]
      ),
      _c(
        "div",
        {
          staticClass: "gyfg",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            width: "100%",
            position: "relative",
            padding: "7.5px",
            "box-sizing": "border-box"
          },
          style: _vm.outHei
        },
        [
          _c(
            "draggable",
            {
              staticStyle: {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                "margin-top": "7.5px",
                display: "flex",
                "flex-wrap": "wrap",
                padding: "0 7.5px",
                "box-sizing": "border-box"
              },
              style: _vm.scHei
            },
            [
              _c(
                "el-checkbox-group",
                {
                  staticStyle: {
                    width: "100%",
                    display: "flex",
                    "flex-wrap": "wrap"
                  },
                  model: {
                    value: _vm.checkboxList,
                    callback: function($$v) {
                      _vm.checkboxList = $$v
                    },
                    expression: "checkboxList"
                  }
                },
                _vm._l(_vm.list, function(item, indexCod) {
                  return _c(
                    "el-checkbox-button",
                    {
                      key: item.id,
                      staticClass: "fontCss",
                      staticStyle: { padding: "0" },
                      style: _vm.itemHeight,
                      attrs: { label: item.id, disabled: _vm.isCheck }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "name",
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            padding: "0 7.5px",
                            height: "50px",
                            "line-height": "50px",
                            "border-radius": "8px 8px 0 0",
                            width: "100%",
                            "box-sizing": "border-box"
                          },
                          style: _vm.topTitle[item.openStatus]
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                position: "relative",
                                "padding-left": "15px"
                              },
                              style: _vm.textStatus[item.openStatus]
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-biaotibiao",
                                style: _vm.iconStatus[item.openStatus]
                              }),
                              _vm._v(
                                _vm._s(item.code) +
                                  "\n\n                            "
                              )
                            ]
                          ),
                          _c("div", [
                            item.openStatus == 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "item_color",
                                    staticStyle: {
                                      color: "#42B983",
                                      "font-size": "14px"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-shengchandan",
                                      staticStyle: {
                                        "vertical-align": "0",
                                        "font-size": "15px !important"
                                      }
                                    }),
                                    _vm._v(
                                      "生产中\n                                "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            item.openStatus == 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "item_color",
                                    staticStyle: {
                                      color: "#fff",
                                      "font-size": "14px"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-guanji",
                                      staticStyle: {
                                        "vertical-align": "0",
                                        "font-size": "15px !important"
                                      }
                                    }),
                                    _vm._v(
                                      "待开始\n                                "
                                    )
                                  ]
                                )
                              : item.faultStatus == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "item_color",
                                    staticStyle: {
                                      color: "#fff",
                                      "font-size": "14px"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-jinggao",
                                      staticStyle: {
                                        "vertical-align": "0",
                                        "font-size": "15px !important"
                                      }
                                    }),
                                    _vm._v(
                                      "待维修\n                                "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "0 7.5px",
                            height: "calc(100% - 128px)",
                            display: "flex",
                            "flex-wrap": "wrap",
                            "align-items": "center",
                            width: "100%",
                            "box-sizing": "border-box",
                            "text-align": "left"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-pinzhong12",
                                staticStyle: {
                                  "vertical-align": "0",
                                  "font-size": "16px !important",
                                  color: "#CAD9EF",
                                  "margin-right": "10px"
                                }
                              }),
                              _vm._v("产品品种："),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    color: "#aaa",
                                    display: "inline-block",
                                    width: "calc(100% - 115px)",
                                    overflow: "hidden",
                                    "text-overflow": "ellipsis",
                                    "white-space": "nowrap"
                                  }
                                },
                                [_vm._v(_vm._s(item.fact.productName))]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-mingxi",
                                staticStyle: {
                                  "vertical-align": "0",
                                  "font-size": "16px !important",
                                  color: "#CAD9EF",
                                  "margin-right": "10px"
                                }
                              }),
                              _vm._v("产品批次："),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    color: "#aaa",
                                    display: "inline-block",
                                    width: "calc(100% - 115px)",
                                    overflow: "hidden",
                                    "text-overflow": "ellipsis",
                                    "white-space": "nowrap"
                                  }
                                },
                                [_vm._v(_vm._s(item.fact.batchName))]
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            padding: "0 7.5px",
                            "justify-content": "space-between",
                            "align-items": "center",
                            width: "100%",
                            height: "50px",
                            "box-sizing": "border-box"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { width: "calc(33% - 23.75px)" } },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "bottom",
                                    title: "参数详情",
                                    width: "400",
                                    trigger: "click",
                                    content: "暂无数据",
                                    "append-to-body": false
                                  }
                                },
                                [
                                  _vm._l(_vm.formData, function(val) {
                                    return _c(
                                      "header",
                                      {
                                        staticStyle: {
                                          dispaly: "block",
                                          lineHeight: "50px"
                                        }
                                      },
                                      [
                                        _vm._v(_vm._s(val.name) + ": "),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              width: "70%",
                                              float: "right"
                                            }
                                          },
                                          [_vm._v(_vm._s(val.specParamValue))]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        background: "#F0F7FF",
                                        width: "100%",
                                        border: "1px solid #4D8AF0",
                                        color: "#4D8AF0",
                                        "border-radius": "8px",
                                        padding: "12px 0"
                                      },
                                      attrs: {
                                        slot: "reference",
                                        value: 1,
                                        icon: "iconfont icon-ico-gongyishuxing"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.getpoccessClick(
                                            item.fact.specSheetId
                                          )
                                        }
                                      },
                                      slot: "reference"
                                    },
                                    [_vm._v("运转工艺")]
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { width: "calc(33% - 23.75px)" } },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "bottom",
                                    title: "参数详情",
                                    width: "400",
                                    trigger: "click",
                                    content: "暂无数据",
                                    "append-to-body": false
                                  }
                                },
                                [
                                  _vm._l(_vm.sbgy, function(val) {
                                    return _c(
                                      "header",
                                      {
                                        staticStyle: {
                                          dispaly: "block",
                                          lineHeight: "50px"
                                        }
                                      },
                                      [
                                        _vm._v(_vm._s(val.name) + ": "),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              width: "70%",
                                              float: "right"
                                            }
                                          },
                                          [_vm._v(_vm._s(val.specParamValue))]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        background: "#F0F7FF",
                                        width: "100%",
                                        border: "1px solid #4D8AF0",
                                        color: "#4D8AF0",
                                        "border-radius": "8px",
                                        padding: "12px 0"
                                      },
                                      attrs: {
                                        slot: "reference",
                                        value: 2,
                                        icon: "iconfont icon-nenghaocaiji"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.getsbgyClick(
                                            item.fact.specSheetId
                                          )
                                        }
                                      },
                                      slot: "reference"
                                    },
                                    [_vm._v("设备工艺")]
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { width: "calc(33% - 23.75px)" } },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    background: "#FFF9EF",
                                    width: "100%",
                                    border: "1px solid #E6A23C",
                                    color: "#E6A23C",
                                    "border-radius": "8px",
                                    padding: "12px 0"
                                  },
                                  attrs: {
                                    slot: "reference",
                                    value: 2,
                                    icon: "iconfont icon-nenghaocaiji"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.fangai(item)
                                    }
                                  },
                                  slot: "reference"
                                },
                                [_vm._v("机台翻改")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                }),
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "fixed",
                    right: "15px",
                    bottom: "70px"
                  }
                },
                [
                  _vm.isTop
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-bottom": "10px",
                            cursor: "pointer",
                            width: "50px",
                            "border-radius": "50%",
                            height: "50px",
                            background: "#4D8AF0",
                            "text-align": "center",
                            "line-height": "50px"
                          },
                          on: { click: _vm.toTop }
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont icon-zhiding-59",
                            staticStyle: {
                              "font-size": "30px !important",
                              color: "#fff",
                              margin: "0",
                              width: "50px"
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm.isPre
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-bottom": "10px",
                            cursor: "pointer",
                            width: "50px",
                            "border-radius": "50%",
                            height: "50px",
                            background: "#4D8AF0",
                            "text-align": "center",
                            "line-height": "50px"
                          },
                          on: { click: _vm.preList }
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont icon-shang25",
                            staticStyle: {
                              "font-size": "30px !important",
                              color: "#fff",
                              margin: "-7px 0 0",
                              width: "50px"
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm.isNex
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-bottom": "10px",
                            cursor: "pointer",
                            width: "50px",
                            "border-radius": "50%",
                            height: "50px",
                            background: "#4D8AF0",
                            "text-align": "center",
                            "line-height": "50px"
                          },
                          on: { click: _vm.nextList }
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont icon-xia852",
                            staticStyle: {
                              "font-size": "30px !important",
                              color: "#fff",
                              margin: "0",
                              width: "50px"
                            }
                          })
                        ]
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }