<template>
  <div>
    <basic-container>
      <div class="header">
        <el-button  @click="addShow"  type="primary" size="small" icon="el-icon-plus">{{$t('ADD')}}</el-button>
        <div class="search">
          <el-select v-model="departmentStatus"  :placeholder="$t('STATE')"  size="small" style="width:200px; marginRight: 5px;">
            <el-option :label="$t('NORMAL')" value=0>{{$t('NORMAL')}}</el-option>
            <el-option :label="$t('DISABLE')" value=1>{{$t('DISABLE')}}</el-option>
          </el-select>
          <el-input  v-model="departmentName" size="small" :placeholder="$t('Please enter')"></el-input>
          <el-button  @click="refreshChange"  type="primary" size="small" icon="el-icon-search">{{$t('SEARCH')}}</el-button>
        </div>
      </div>
      <el-table
          :data="tableData"
          style="width: 100%;margin-bottom: 20px; margin-top: 10px;"
          row-key="id"
          :indent="20"
          size = "medium"
          default-expand-all
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column
            prop="name"
            :label="this.$t('DEPARTMENT NAME')"
            >
        </el-table-column>
        <el-table-column
            prop="leaderUserName"
            :label="this.$t('PERSON IN CHARGE')"
            >
        </el-table-column>
        <el-table-column
            prop="sort"
            :label="this.$t('SORT')"
            sortable
            >
        </el-table-column>
        <el-table-column
            prop="number"
            :label="this.$t('NUMBER OF DEPARTMENTS')"
            >
          <template scope="scope">
            <el-link style="color: #409EFF;text-style: none;" @click="clickNumber(scope.row)">
              {{ scope.row.number}}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
            :label="this.$t('STATE')"
            >
          <template scope="scope">
            <el-switch
                v-model="scope.row.status"
                :active-value=0
                :inactive-value=1
                @change="statusChange(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
            :label="this.$t('STATE')"
            >
          <template slot-scope="scope">
              <el-button @click="updShow(scope.row)" type="text" size="small">{{$t('Edit')}}</el-button>
              <el-button @click="addChildrenShow(scope.row)" type="text" size="small">{{$t('ADD SUB DEPARTMENT')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" style="float: right; margin: 30px;">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-sizes="[10, 20, 50, 40,50,100]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total">
        </el-pagination>
      </div>
      <el-dialog :title="$t('Edit')"
                 :visible.sync="editFormVisible"
                 width="50%"
                 :append-to-body="true">
        <el-form :model="updForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item :label="$t('DEPARTMENT NAME')" prop="name">
            <el-input v-model="updForm.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('HEAD USER')" >
            <el-select v-model="updForm.leaderUserName">
              <el-option v-for="item in leaderList" :key="item.id" :label="item.name" :value="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item  :label="$t('SORT')" prop="region">
              <el-input-number v-model="updForm.sort" controls-position="right"></el-input-number>
          </el-form-item>
          <el-form-item  :label="$t('STATUS')" prop="region">
            <el-switch
                v-model="updForm.status"
                :active-value=0
                :inactive-value=1
            >
            </el-switch>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="editFormVisible = false">{{$t('CANCEL')}}</el-button>
            <el-button type="primary" @click="handleUpdate">{{$t('Hold')}}</el-button>
        </div>
      </el-dialog>
      <el-dialog :title="$t('ADD')"
                 :visible.sync="addChildrenVisible"
                 width="50%"
                 :append-to-body="true">
        <el-form :model="addChildrenForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item :label="$t('DEPARTMENT NAME')" prop="name">
            <el-input v-model="addChildrenForm.name"></el-input>
          </el-form-item>
          <el-form-item  :label="$t('HEAD USER')" >
            <el-select v-model="addChildrenForm.leaderUserName">
              <el-option v-for="item in leaderList" :key="item.id" :label="item.name" :value="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item  :label="$t('SORT')" prop="region">
            <el-input-number v-model="addChildrenForm.sort" controls-position="right"></el-input-number>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="addChildrenVisible = false">{{$t('CANCEL')}}</el-button>
            <el-button type="primary" @click="addChildrenSub">{{$t('Hold')}}</el-button>
        </div>
      </el-dialog>
      <el-dialog :title="$t('ADD')"
                 :visible.sync="addFormVisible"
                 width="50%"
                 :append-to-body="true">
        <el-form :model="addForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item :label="$t('DEPARTMENT NAME')" prop="name">
            <el-input v-model="addForm.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('HEAD USER')" >
            <el-select v-model="addForm.leaderUserName">
              <el-option v-for="item in leaderList" :key="item.id" :label="item.name" :value="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item  :label="$t('SORT')" prop="region">
            <el-input-number v-model="addForm.sort" controls-position="right"></el-input-number>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="addFormVisible = false">{{$t('CANCEL')}}</el-button>
            <el-button type="primary" @click="addFormSub">{{$t('Hold')}}</el-button>
        </div>
      </el-dialog>
    </basic-container>
  </div>
</template>
<script>
    import { list, add, addnextClass, remove, edit } from "@/api/classpepole";
    import { getPerson } from "@/api/config";
export default {
    data() {
        return {
          departmentName: '',
          departmentStatus: '',
          addForm:{
            name: null,
            leaderUserName: null,
            sort: null
          },
          addChildrenForm:{
            parentId: null,
            name: null,
            leaderUserName: null,
            sort: null,
          },
          updForm:{
            id: null,
            name: null,
            leaderUserName: null,
            sort: null,
            status: null
          },
          addChildrenVisible: false,
          addFormVisible: false,
          editFormVisible: false,
          rules: {
            name: [
              { required: true, message: this.$t('PLEASE ENTER A NAME'), trigger: 'blur' },
            ]
          },
          tableLoading: false,
            addTabel: { slot: 0 },
            //新增上级时修改
            addTopId: "",
            page: {
                pageSize: 20,
                currentPage: 1,
                total: 0
            },
            slot: 1,
            dialogVisible: false,
            input: "0012",
            tableData: [],
            leaderList: []
        }
    },
        created() {
            getPerson().then(res => {
                this.leaderList = res.data.data;
              console.log(res);
            });
      this.refreshChange();
    },
    methods: {
      clickNumber(row){
        this.$router.push({path: "pepoleOrder",query:{data: row}});
      },
      addFormSub(){
        add(this.addForm).then((res) => {
          if(res.data.code == "0000"){
            this.$message({
              type: "success",
              message: this.$t('AddSuccess')
            });
          }
          this.addFormVisible = false;
          this.refreshChange();
        });
      },
      addShow(){
        this.addForm.name =null;
        this.addForm.leaderUserName =null;
        this.addForm.sort =null;
        this.addFormVisible = true;
      },
      addChildrenSub(){
        addnextClass(this.addChildrenForm).then((res) => {
          console.log(res);
          if(res.data.code == "0000"){
            this.$message({
              type: "success",
                message: this.$t('OperationSuccess') 
            });
          }
          this.refreshChange();
          this.addChildrenVisible = false;
        });
      },
      addChildrenShow(row){
        this.addChildrenForm.parentId = row.id;
        this.addChildrenForm.name =null;
        this.addChildrenForm.leaderUserName =null;
        this.addChildrenForm.sort =null;
        this.addChildrenVisible = true;
      },
      handleSizeChange(size){
        this.page.pageSize = size;
        this.refreshChange();
      },
      handleCurrentChange(page){
        this.page.currentPage = page;
        this.refreshChange();
      },
      statusChange(row){
        console.log(row)
        edit(row).then((res) => {
          if(res.data.code == "0000"){
            this.$message({
              type: "success",
              message: this.$t('Status modified successfully') 
            });
          }
          done();
          this.refreshChange();
        });
      },
      updShow(row){
        this.updForm = row;
        console.log(this.updForm)
        console.log(row)
        this.editFormVisible = true
      },
      getPermission(key, row, index){
          if(key==='delBtn' && row.status == 0){
              return false;
          }else if(key==='delBtn' && index==-1){
            return false;
          }
          return true;
        },
      handleUpdate() {
           edit(this.updForm).then((res) => {
             if(res.data.code == "0000"){
                    this.$message({
                      type: "success",
                       message: this.$t('EDIT SUCCEEDED')
                    });
            }
             this.editFormVisible = false;
             this.refreshChange();
          });
        },
      refreshChange() {
          list(this.page.currentPage,this.page.pageSize,this.departmentName,this.departmentStatus).then(res => {
            this.page.total = res.data.data.total;
            this.tableData = res.data.data.data;
          })
        }
  }
}
</script>
<style scoped>
    .plus {
        width : 3px;
        height: 3px;
    }
    /* /deep/ .plus i::before {
        width : 1px;
    } */
</style>
<style lang="scss" scoped>
.el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  padding-left: 10px;
}
.header {
  display: flex;
  justify-content: space-between;
  .search {
    display: flex;
    .el-input {
      margin-right: 10px;
    }
  }
}
</style>