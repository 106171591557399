import request from '@/router/axios';
import { Row } from 'element-ui';
import {businessUrl} from "@/config/env"
export const list = (pageNo,pageSize,nameOrIdLike,status) => {
    return request({
        url : businessUrl + "department/selectPage",
        method: "post",
        data: {
            type: 1,
            pageNo,
            pageSize,
            nameOrIdLike,
            status : Number(status)
        }
    })
}
//编辑
export const edit = (row) => {
    return request({
        url : businessUrl + "department/updateByPrimaryKeySelective",
        method: "put",
        data: {
            ...row,
            type: 1,
        }
    })
}
//删除
export const remove = (id) => {
    return request({
        url : businessUrl + "department/delete",
        method: "delete",
        params: {
            id,
            type: 1,
        }
    })
}
export const changeStatus = (row) => {
    return request({
        url : businessUrl + "department/updateStatus",
        method: "put",
        data : {
            id : row.id,
            status : Number(row.status),
        }
    })
}
export const add = (row) => {
    return request({
        url : businessUrl + "department/insert",
        method: "post",
        data : {
            type: 2,
            ...row,
            type: 1,
        }
    })
}