<template>
  <div>
        <basic-container>
            <el-collapse v-model="activeNames" style="marginTop: 30px;">
							<!-- 基础信息 -->
                <el-collapse-item title="基础信息" name="1">
                    <el-form align="center" class="content" ref="ruleform" :model="form" label-width="80px" :rules="rules">
                        <el-row :gutter="24">
                            <!-- <el-col :span="6">
                                <el-form-item label="工艺单号">
                                    <el-input placeholder="工艺单号" v-model="form.name" disabled></el-input>
                                </el-form-item>
                            </el-col> -->
                            <el-col :span="6">
                                <el-form-item label="工艺员" prop="workPerson">
                                    <el-select v-model="form.person" placeholder="请选择工艺员" class="formSelect">
                                        <el-option v-for="item in personOptions"
                                                   :key="item.id"
                                                   :label="item.name"
                                                   :value="item.userId">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="纱管颜色" prop="tubeColorId"><!--tubeColorName-->
                                    <el-select v-model="form.tubeColorId" placeholder="请选择纱管颜色" class="formSelect">
                                        <el-option v-for="item in tubeColorIdOptions"
                                                   :key="item.code"
                                                   :label="item.name"
                                                   :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
							<el-col :span="6">
                                <el-form-item label="计划产量" prop="modelOutput">
									<el-input placeholder="请输入计划产量" v-model="form.modelOutput"></el-input>
                                </el-form-item>
                            </el-col>
							<el-col :span="6">
                                <el-form-item label="生产工序" prop="processId">
                                    <el-select v-model="form.processId" placeholder="请选择生产工序" @change="wklnChange" class="formSelect">
                                        <el-option
                                        v-for="item in wklnOption"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="产品名称" prop="productId">
                                    <el-select v-model="form.productId" placeholder="请选择产品名称" @change="chengeProduct"  class="formSelect">
                                        <el-option
                                        v-for="item in wklnOptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="产品批号" prop="batchCode">
                                    <el-select v-model="form.batchId" placeholder="请选择产品批号"  class="formSelect">
                                        <el-option
                                        v-for="item in batchOptions"
                                        :key="item.value"
                                        :label="item.batchCode"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                     <!-- <el-autocomplete
                                        size="small" style="width:100% !important;marginRight: 5px;"
                                        v-model="form.batchId" placeholder="请选择产品批号"  class="formSelect"
                                        :fetch-suggestions="querySearchAsync"
                                        @input="changeInput"
                                        @select="handleSelect">
                                        <template slot-scope="{item}">
                                            <div class="name">{{item.value=item.batchCode}}</div>
                                        </template>
                                    </el-autocomplete> -->
                                </el-form-item>
                            </el-col>
							<el-col :span="6">
                                <el-form-item label-width="10" label="计划开台时间" prop="planStartTime">
                                     <el-date-picker v-model="form.planStartTime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    type="datetime"
                                    placeholder="计划开台时间">
                    							</el-date-picker>
                                </el-form-item>
                            </el-col>
							<el-col :span="6">
                                <el-form-item label-width="10"  label="计划了机时间" prop="planEndTime">
                                   <el-date-picker v-model="form.planEndTime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    type="datetime"
                                    placeholder="计划了机时间">
                    							</el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-collapse-item>
								<!-- 中间配置机台 -->
							<el-collapse-item title="配置机台" name="2">
                    <el-form :model="form" :rules="rules">
                        <el-row :gutter="24">
                            <el-col >
                                <el-form-item label="设备机型" prop="machineType">
                                    <el-select v-model="form.machineModelId" placeholder="请选择" @change="proccessChange">
                                        <el-option v-for="item in proccessOptions"
                                                   :key="item.id"
                                                   :label="item.name"
                                                   :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
												<el-row :gutter="24">
													 <el-col :span="24">
                                <el-form-item label="适合机台" prop="checkArrayList">
                                    <div class="borderWoker">
                                        <el-checkbox-group v-model="checkArrayList" @change="changeCheck" style="float: left;">
                                             <el-checkbox v-for="value in checkboxList" :key="value.id" :label="value.id" border style="width:120px;margin-left: 10px;">{{value.name}}</el-checkbox>
                                        </el-checkbox-group>
                                    </div>
                                </el-form-item>
                            </el-col>
												</el-row>
                    </el-form>
                </el-collapse-item>

								<!-- 工艺信息 -->
                <el-collapse-item title="工艺信息" name="2">
                    <el-form :model="form" label-width="160px" :rules="rules">
                        <el-row :gutter="24">
                            <el-col :span="10">
                                <el-form-item label="纱支定长(m)" prop="meters">
                                    <el-input-number v-model="form.meters" @change="getTheoryDoffingTime" :min="0"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="平均前罗拉直径(mm)" prop="avgFrontRollerDiameter">
                                    <el-input-number v-model="form.avgFrontRollerDiameter" :min="0" @change="getTheoryDoffingTime"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="10">
                                <el-form-item label="前罗拉速度(转/分钟)" prop="frontRollerSpeed">
                                    <el-input-number v-model="form.frontRollerSpeed" @change="getTheoryDoffingTime" :min="0"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="理论落纱时间(H)" prop="theoryDoffingTime">
                                    <el-input-number v-model="form.theoryDoffingTime" :min="0"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="10">
                                <el-form-item label="定量克重(g/km)" prop="gramWeight">
                                    <el-input-number v-model="form.gramWeight" :min="0" :max="10000" label="描述文字" value="1" @change="getTheoryDoffingTime"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="14">
                                <el-form-item label="台日产量(KG/天)" prop="dayYield">
                                    <el-input-number v-model="form.dayYield" :min="0" :max="10000" label="描述文字"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="10">
                                <el-form-item label="公定回潮率%" >
                                    <el-input-number v-model="form.moistureRegain" :min="1" :max="10000" label="描述文字"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="14">
                                <el-form-item label="运转效率%" prop="efficiencyPercent">
                                    <el-input-number v-model="form.efficiencyPercent" :min="1" :max="10000" label="描述文字"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-collapse-item>
                <el-collapse-item title="工艺参数" name="3">
									

									 <el-form :model="form" :rules="rules">
                        <el-row :gutter="24">
                            <el-col >
                                <el-form-item label="标准工艺单选择" prop="machineType" >
                                    <el-select v-model="form.processSheetId" placeholder="请选择" @change="processSheetChange">
                                        <el-option v-for="item in processSheetList"
                                                   :key="item.id"
                                                   :label="item.code"
                                                   :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>

                    <el-row :gutter="24">
											 
                        <el-col :span="8">
                          <footer><h3 style="height: 40px;line-height: 40px;text-align: center;">标准参数</h3>
                            <avue-form style="marginTop:20px;width:300px" ref="nextRef" v-model="lastform" :option="optionBefore"></avue-form>
                        </footer>
                        </el-col>
                        <el-col :span="8">
                            <footer><h3 style="height: 40px;line-height: 40px;text-align: center;">设定参数</h3>
                                <avue-form style="marginTop:20px;width:300px" ref="nextRef" v-model="setUpform" :option="optionSetUp" @change="changeDifference"></avue-form>
                            </footer>
                        </el-col>
                        <el-col :span="8">
                            <footer><h3 style="height: 40px;line-height: 40px;text-align: center;">对比差值</h3>
                                <avue-form style="marginTop:20px;width:300px" class="diff" ref="nextRef" v-model="differenceform" :option="optionDifference"></avue-form>
                            </footer>
                            
                        </el-col>
                    </el-row>
                </el-collapse-item>
            </el-collapse>
            <div style="textAlign: right;marginTop: 20px;">

             <el-button icon="el-icon-check" type="success" @click="save">保存</el-button>
            <el-button icon="el-icon-back" type="primary" @click="commit">返回</el-button>
            </div>
        </basic-container>
  </div>
</template>
<script>
    import { getProcessSheet,getParameterByStandard,getParameterByStatus,getAvgVal,getTheory,getDayYield,getGram,insertSheetPlan,searchNumber} from "@/api/planList.js";
    import { productWork, getpoccessByproduct, wklnList, machineModelList, getmachineByMachineModelId, getwklnWorkBywkln, getPerson, getTubeColorId} from "@/api/config";
export default {
    data() {
        return {
						lastform: {}, //标准参数
						setUpform:{}, //设定参数
						differenceform: {}, // 对比差值
						processSheetList:[], // 标准工艺单数组
						optionSetUp: {
							submitBtn: false,
							emptyBtn: false,
							labelWidth:110,
							column: []
						},
						optionBefore: {
							submitBtn: false,
							emptyBtn: false,
							labelWidth:110,
							column: []
						},
						optionDifference: {
							submitBtn: false,
							emptyBtn: false,
							labelWidth:110,
							column: []
						},
            form:{
                person: "", //工艺员
                tubeColorId: "", //纱管颜色
                modelOutput: "", //计划产量
                processId: "",	// 生产工序
                productId: "",	//产品名称
                batchId: "",//批号
                planStartTime: "", //计划开始时间
                planEndTime: "", //计划结束时间
                processSheetId: "", //标准工艺单
                hourYield: "",
                //单产分母
                gramWeight: "1",
                //单产分子
                hourYieldSon: 1,
                //运转效率
                efficiencyPercent: 1,
                //公定回潮
                moistureRegain: 1,
                //定量
                moistureRegain: 1,
                machineModelId: "",
                //选择机台
                checkArrayList: [],
                //纱支定长
                meters: 0,
                //平均前罗拉直径
                avgFrontRollerDiameter: 0,
                //理论落纱时间
                theoryDoffingTime: 0,
                //前罗拉速度
                frontRollerSpeed: 0,
                //日产
                dayYield:""
            },
            proid:"",
            person: "",
            //工艺员
            personOptions: [],
            //纱管颜色
            tubeColorIdOptions:[],
            //
            specParamList: [],
            //全选
            isIndeterminate: false,
            checkArrayList: [],
            checkboxList: [],
            //设备机型
            proccess: "",
            proccessOptions: [],
            wklnValue: "",
            //生产工序
            wklnOption: [],
            batchOptions: [],
            //产品名称
            product: [],
            activeNames: ["1","2","3"],
            form: {},
            wklnOptions: [],
            rules: {
				workPerson: [ {required: true, message: '请选择工艺员', trigger: 'blur'} ],
                tubeColorId: [{ required: true, message: '请选择纱管颜色', trigger: 'blur' }],
				modelOutput: [{ required: true, message: '请输入计划产量', trigger: 'blur' }],
                processId: [ {required: true, message: '请选择生产工序', trigger: 'blur'} ],
                productId: [ {required: true, message: '请选择产品名称', trigger: 'blur'} ],
				batchCode:[ {required: true, message: '请选择产品批号', trigger: 'blur'} ],
				planStartTime:[ {required: true, message: '请选择计划开台时间', trigger: 'blur'} ],
				planEndTime:[ {required: true, message: '请选择计划了机时间', trigger: 'blur'} ],
                machineType: [ {required: true, message: '请选择设备机型', trigger: 'blur'} ],


                dayYield: [{ required: true, message: '请选择台日产量', trigger: 'blur'} ],
                efficiencyPercent: [ {required: true, message: '请选择运转效率%', trigger: 'blur'} ],
                gramWeight: [{ required: true, message: '请选择定量克重(g/km)', trigger: 'blur'} ],
                textCode: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
                meters: [{ required: true, message: '请输入纱支定长', trigger: 'blur' }],
                avgFrontRollerDiameter: [{ required: true, message: '请输入前罗拉直径', trigger: 'blur' }],
                frontRollerSpeed: [{ required: true, message: '请输入前罗拉速度', trigger: 'blur' }],
                theoryDoffingTime: [{ required: true, message: '请输入理论落纱时间', trigger: 'blur' }],
            }
        }
    },
    created() {
        //工艺员
        getPerson().then(res => {
            this.personOptions = res.data.data;
        });
        getTubeColorId().then(res => {
            console.log(res, "颜色")
            this.tubeColorIdOptions = res.data.data.items;
        })
        machineModelList().then(res => {
            this.proccessOptions = res.data;
        });
        wklnList().then(res => {
            this.wklnOption = res.data;
        });
        productWork().then(res => {
            this.wklnOptions = res.data.data;
        });
        getProcessSheet(0).then(res => {
					this.processSheetList = res.data.data.items
				});
        getParameterByStatus(0).then(res => {
				    let that = this;
					console.log('123123',res);
                    that.specParamList = [];
                    res.data.data.forEach(v => {
                        let tmp = {
                            specParamId: v.id,
                            specParamName: v.name
                        }
                        that.specParamList.push(tmp);
                    });
                    that.optionSetUp.column = [];
                    that.optionDifference.column = [];
                    res.data.data.forEach(v => {
                        that.optionDifference.column.push({
                            disabled: true,
                            label: v.name,
                            prop: v.name,
                            span: 24,
                            row: true
                        });
                        that.$set(that.differenceform,v.name,'');
                        that.differenceform[v.name] = '';
                        console.log(that.differenceform);
                    });
						res.data.data.forEach(v => {
								that.optionSetUp.column.push({
										label: v.name,
										prop: v.name,
										span: 24,
										// row: true
                                        blur: ()=> {
                                            console.log('标准',that.lastform[v.name]);
                                            console.log('填写',that.setUpform[v.name]);
                                            //that.differenceform[v.name] = that.lastform[v.name] - that.setUpform[v.name];
                                            let num = that.lastform[v.name]-0>that.setUpform[v.name]-0?(that.lastform[v.name] - that.setUpform[v.name]):(that.setUpform[v.name] - that.lastform[v.name]);
                                            if (!num){
                                                num = 0
                                            }
                                            this.$set(that.differenceform,v.name,num);
                                            this.$forceUpdate();
                                            console.log('差值',that.differenceform[v.name]);
                                            console.log(that.differenceform);
                                        }
								});
								//翻改前工艺单的值
                                that.$set(that.setUpform,v.name,v.value);
								//that.setUpform[v.name] = v.value;
								// console.log(that.setUpform[v.specParamName]);
						});
				});
    },
        methods: {
            processSheetChange(row) {
                getParameterByStandard(row).then(res => {
                    console.log(res);
                        this.optionBefore.column = [];
                res.data.data.forEach(v => {

                    this.optionBefore.column.push({
                        disabled: true,
                        label: v.specParamName,
                        prop: v.specParamName,
                        span: 24,
                        row: true
                    });
                    this.$set(this.lastform,v.specParamName,v.value);
                    this.$set(this.setUpform,v.specParamName,v.value);

                    // console.log(this.lastform[v.value]);
                    //this.lastform[v.specParamName] = v.value;
                });
                })
            },
            changeInput() {
                searchNumber(this.form.productId,this.form.batchId).then(res => {
                        console.log('批号',res);
                        this.batchOptions = res.data.data
                        // this.workLists.slice(0,20)
                    })
            },
            querySearchAsync(queryString, cb) {
                var restaurants = this.batchOptions;
                console.log(restaurants);
                var result = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                cb(result);
            },
            createFilter(queryString) {
                return (restaurant) => {
                    if (restaurant.batchCode !== null) {
                        return (restaurant.batchCode.toLowerCase().indexOf(queryString.toLowerCase()) !== -1);
                    }
                
                };
            },
            handleSelect(item) {
                console.log(item);
                this.batchCodeId = item.id
                console.log(item.id);

            },
            changeDifference(row) {
                console.log(row);
            },
        //计算落纱时间
            getTheoryDoffingTime() {
                getTheory(this.form.meters, this.form.frontRollerSpeed, this.checkArrayList).then(res => {
                    console.log(res,'wai')
                    if (res.data.data != null) {
                        this.form.theoryDoffingTime = Number(res.data.data.theoryDoffingTime).toFixed(2);
                    } else {
                        this.form.theoryDoffingTime = 0;
                    }
                    console.log(this.proid, this.form.meters, this.form.frontRollerSpeed, this.checkArrayList, this.form.gramWeight, this.form.theoryDoffingTime)
                    getDayYield(this.proid, this.form.meters, this.form.frontRollerSpeed, this.checkArrayList, this.form.gramWeight, this.form.theoryDoffingTime).then(res => {
                        console.log(res,'nei')
                        if (res.data.data != null) {
                            this.form.dayYield = res.data.data.dayYield;
                        } else {
                            this.form.dayYield = 0;
                        }
                    })
                });
            },
        commit() {
            this.$router.push({path: "planList"});
        },
        formatParamType(row) {
            let formatData = [{
                label: "是",
                value: 1
            },{
                label: "否",
                value: 0
            }];
            formatData.forEach(v => {
                if(row.id) {
                    
                }
            })
        },
        allClick() {
        },
        changeCheck(value) {
            console.log("value",value);
            getAvgVal(value).then(res => {
                this.form.avgFrontRollerDiameter = Number(res.data.data.avgFrontRollerDiameter).toFixed(2);
            })
            getTheory(this.form.meters, this.form.frontRollerSpeed, value).then(res => {
                console.log(res)
                if (res.data.data != null) {
                    this.form.theoryDoffingTime = Number(res.data.data.theoryDoffingTime).toFixed(2);
                } else {
                    this.form.theoryDoffingTime = 0;
                }
                getDayYield(this.proid, this.form.meters, this.form.frontRollerSpeed, value, this.form.gramWeight, this.form.theoryDoffingTime).then(res => {
                    if (res.data.data != null) {
                        this.form.dayYield = res.data.data.dayYield;
                    } else {
                        this.form.dayYield = 0;
                    }
                })
            });
            if(value == "全选") {
                let checkList = [];
                this.checkboxList.forEach(v => {
                    checkList.push(v.id);
                });
                checkList.push("全选");
                this.$set(this.form,"checkArrayList",checkList);
                console.log(this.checkArrayList,"list");
            }
        },
        proccessChange(value) {
            getmachineByMachineModelId(value).then(res => {
                console.log(res);
                this.checkboxList = res.data.data;
                this.$forceUpdate();
                this.checkArrayList = [];
                //
                this.form.meters = 0;
                this.form.avgFrontRollerDiameter = 0;
                this.form.frontRollerSpeed = 0;
                this.form.theoryDoffingTime = 0;
                //this.form.gramWeight = 0;
                this.form.dayYield = 0;
            });
        },
        wklnChange(value) {
            getwklnWorkBywkln(value).then(res => {
                console.log('tabd',res)
                res.data.data.forEach(v => {
                    if(v.inputType == 1) {
                        v.maxValue = 1;
                        v.minValue = 1;
                    }
                });
                this.tableData = res.data.data;
                this.tableData2 = res.data.data;
                this.tableData3 = res.data.data;
                this.$forceUpdate();
            })
        },
        chengeProduct(value) {
            this.proid = "";
            this.proid = value;
            searchNumber(this.form.productId).then(res => {
                        console.log('批号',res);
                        this.batchOptions = res.data.data
                        // this.workLists.slice(0,20)
                    })
            getGram(value).then(res => {
                this.form.gramWeight = res.data.data.gramWeight;
                getDayYield(value, this.form.meters, this.form.frontRollerSpeed, this.checkArrayList, this.form.gramWeight, this.form.theoryDoffingTime).then(res => {
                    if (res.data.data != null) {
                        this.form.dayYield = res.data.data.dayYield;
                    } else {
                        this.form.dayYield = 0;
                    }
                })
            })
            
        },
        save() {
            console.log(this.optionSetUp.column);
            console.log(this.differenceform);
            console.log(this.specParamList);
            let specSheetParamDtoList = [];
            this.optionSetUp.column.forEach(item => {
                let id = null;
                this.specParamList.forEach(i =>{
                    if (i.specParamName == item.prop){
                        id = i.specParamId;
                    }
                });
                let tmp = {
                    specParamId : id,
                    value : this.setUpform[item.prop]
                }
                specSheetParamDtoList.push(tmp);
            });
                let obj = {
                    processId: this.form.processId,
                    productId: this.form.productId,
                    specUserid: this.form.person,
                    baseSpecSheetId: this.form.processSheetId,
                    gramWeight: this.form.gramWeight,
                    dayYield: this.form.dayYield,
                    meters: this.form.meters,
                    numbers: this.form.gramWeight,
                    moistureRegain: this.form.moistureRegain,
                    efficiencyPercent: this.form.efficiencyPercent,
                    machineModelId: this.form.machineModelId,
                    theoryDoffingTime: this.form.theoryDoffingTime,
                    tubeColorId:  this.form.tubeColorId,
                    specSheetParamDtoList: specSheetParamDtoList,
                    machineIds: this.checkArrayList,
                    planStartTime: this.form.planStartTime,
                    planEndTime: this.form.planEndTime,
                    batchCode:  this.form.batchId,
                    planYield: this.form.modelOutput
            }
            insertSheetPlan(obj).then(res => {
                if(res.data.code == '0000'){
                    this.$message({
                        message: res.data.msg,
                        type: "success"
                    });
                }
            });
            this.$router.push({path: "planList"});
        }
    }
}
</script>
<style>
    .diff .el-input.is-disabled .el-input__inner {
                 color: #5a9cf8;
            }

</style>
<style lang="scss" scoped>
    .formSelect {
        width: 100%;
    }
</style>