<template>
  <div>
      <basic-container>
          <el-dialog :title="$t('IMPORT')"
                     style="marginTop: 20px;height: 1200px;overflow: hidden;"
                     :visible.sync="dialogVisible"
                     width="60%"
                     :append-to-body="true">
              <el-upload class="upload-demo"
                         ref="file"
                         action="http://192.168.2.110:8026/specParam/import"
                         :on-preview="handlePreview"
                         :on-success="uploadFileSuccess"
                         :auto-upload="false"
                         :on-change="addExcel"
                         :on-remove="handleRemove"
                         :before-remove="beforeRemove"
                         accept=".xlsx,.xls"
                         multiple
                         :limit="3"
                         :on-exceed="handleExceed"
                         :file-list="fileList">
                  <el-button size="small" type="primary">{{$t("Upload")}}</el-button>
                  <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件，且不超过1MB</div>
              </el-upload>
              <p slot="footer" class="dialog-footer" style="margin-top: -30px;">
                  <el-button type="primary" @click="downloadExecl1"> {{$t("Download the template")}}</el-button>
                  <el-button @click="dialogVisible = false">{{$t("Cancel")}}</el-button>
                  <el-button type="success" @click="confirm">{{$t("Yes")}}</el-button>
              </p>
          </el-dialog>

          <!--:permission="getPermission"-->
          <avue-crud :option="tableOption"
                     :data="tableData"
                     :table-loading="tableLoading"
                     :page="page"
                     ref="crud"
                     @current-change="currentChange"
                     @size-change="sizeChange"
                     @selection-change="selectionChange"
                     @refresh-change="refreshChange"
                     @row-save="handleSave"
                     @row-update="handleUpdate"
                     @row-del="handleDel">
              <template slot="status" slot-scope="scope">
                  <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                             @change="change(scope.row)">
                  </el-switch>
              </template>
              <template slot="menuLeft">
                  <importBtn :outApi="this.proApis.SPECPARAMEXPORT"
                             :innerAPI="this.proApis.SPECPARAMIMPORT"
                             :API="this.proApis.SPECPARAMIMPORTTEMPLATE"
                             @refresh="refreshData"></importBtn>
              </template>
              <template slot="menuRight">
                  <el-select clearable v-model="status" placeholder="工序" size="small" style="width:100px !important;marginRight: 5px;">
                      <el-option v-for="value in wklnList" :label="value.name" :value="value.id" :key="value.id"></el-option>
                  </el-select>
                  <el-input v-model="nameOrCode" placeholder="项目名称或编码" style="display:inlie-block;width:200px;marginRight: 5px;" size="small"></el-input>
              </template>
              <!--<template slot="menu" scope="scope">
                  <div v-if="scope.row.status==0" style="height:30px;" />-->
                  <!-- <el-button type="text" size="mini" icon="el-icon-discover" style="color:red;">禁用</el-button> -->
              <!--</template>-->
          </avue-crud>
      </basic-container>
  </div>
</template>
<script>
import importBtn from "@/components/importExport/importBtn";
import {list,add,edit,remove,changeStatus,exportExcel,downloadExecl,importExcel} from "@/api/wklnObject";
import {wklnList} from "@/api/config";
export default {
  components:{
    importBtn
  },
    data() {
        return {
          wklnList:[],
          status: "",
          //文件
          file: "",
          dialogVisible: false,
            nameOrCode: "",
            departmentId: "1",
            page: {
                pageSize: 20,
                pagerCount:5,
                total: '',
                pageCurrent: 1
            },
            disabled:true,
            tableData:[],
            tableLoading: false,
            tableOption:{
              delBtn: true,
              menuWidth:200,
              selection: true,
              tip: false,
              columnBtn : false,
              labelWidth: "120",
              dialogFullscreen:true,
               addBtnText: this.$t('ADD'),
                delBtnText: this.$t('DELETE'),
                editBtnText: this.$t('Edit'),
                emptyText: this.$t('No Data'),
                menuTitle: this.$t('OPERATION'),
              column:[
                {
                label: "项目编码",
                prop: "code"
              },{
                label: "项目名称",
                prop: "name"
              },{
                label: "所属工序",
                type: "select",
                dicUrl: this.businessUrl + "process/selectAll",
                props: {
                  label: "name",
                  value: "id"
                },
                prop: "processId"
              },{
                label: "项目类型",
                type: 'select',
                prop: "paramType",
                dicData: [{
                  label: "设备工艺",
                  value: 1
                },{
                  label: "运转工艺",
                  value: 2
                }]
              },{
                label: "项目排序",
                type: "number",
                prop: "sort"
              },{
                label: "数据类型",
                value: 2,
                span: 24,
                row: true,
                type: "radio",
                prop: "dataType",
                dicData: [{
                  label: "数值型",
                  value: 1
                },{
                  label: "字符型",
                  value: 2
                }]
              },{
                label: "允许为空",
                value: 1,
                row: true,
                type: "switch",
                prop: "isNull",
                dicData: [{
                  label: "是",
                  value: 1
                },{
                  label: "否",
                  value: 0
                }]
                  },
                //  {
                //label: "数据状态",
                //value: 1,
                //addDisplay: false,
                //editDisplay: false,
                //type: "select",
                //hide: true,
                //prop: "status",
                //dicData: [{
                //  label: "启用",
                //  value: 0
                //},{
                //  label: "禁用",
                //  value: 1
                //}]
                //  },
                  {
                label: "输入方式",
                value: 1,
                type: "radio",
                prop: "inputType",
                span: 24,
                row: false,
                dicData: [{
                  label: "input输入框",
                  value: 1
                },{
                  label: "select选择框",
                  value: 2
                }],
                change: ({value,column}) => {
                  if(value == 2) {
                    this.tableOption.column[8].display = true
                  }else {
                    this.tableOption.column[8].display = false
                  }
                  console.log('值改变',value,column)
                  },
              },
              {
                label: "枚举输入框",
                placeholder:'注意:枚举输入框的枚举值以“ ，”分割',
                hide:true,
                value: '',
                prop: 'specParamValue',
                type: "textarea",
                span: 8,
                row: false,
                display:false
              },
              {
                label: "状态",
                addDisplay: false,
                editDisplay: false,
                prop: "status",
                type: "select",
                slot: true,
                dicData: [{
                    label: "启用",
                    value: 0
                },{
                    label: "禁用",
                    value: 1
                }]
              }]
            }
        }
    },
    created() {
      this.list();
      wklnList().then(res => {
        this.wklnList = res.data;
        console.log(this.wklnList);
      });
    },
    methods: {
        currentChange(pageCurrent) {
          this.page.pageCurrent = pageCurrent;
          this.list();
        },
        sizeChange(pageSize) {
          this.page.pageCurrent = 1;
          this.page.pageSize = pageSize;
          this.list();
        },
        refreshData() {
          this.list();
        },
        confirm() {
          let fileFormData = new FormData();
          fileFormData.append("file",this.file);
          this.$refs.file.clearFiles();
          importExcel(fileFormData).then((res) => {
              if (res.data.code == "0000") {
                this.$message({
                type: "success",
                message: "导入成功"
                });
              }
            this.dialogVisible = false;
          });
        },
        addExcel(formData) {
          this.file = formData.raw;
        },
        uploadFileSuccess(resp) {
            this.$message.warning(resp.message);
        },
        downloadExecl() {
          downloadExecl().then(res => {
            this.downloadExecl(res.data,"工艺项目模板");
          })
        },
        importExcl() {
          this.dialogVisible = true;
        },
        exportExcl() {
          exportExcel().then(res => {
            this.downloadExecl(res.data,"工艺项目档案");
          })
        },
        // 下载execl文件
        downloadExecl(execlBuffer, fileName) {          const blob = new Blob([execlBuffer], {type: 'application/vnd.ms-excel'})
          if ('download' in document.createElement('a')) {
            const link = document.createElement('a')//创建a标签
            link.download = fileName//a标签添加属性
            link.style.display = 'none'
            link.href = URL.createObjectURL(blob)
            document.body.appendChild(link)
            link.click()//执行下载
            URL.revokeObjectURL(link.href) //释放url
          }
        },
        getPermission(key, row, index) {
          if (key==='delBtn' && row.status === 0 ) {
            return false;
          } else if (key==='editBtn' && row.status === 0 ){
            return false;
          }
          return true;
        },
        handleDel(row) {
            this.$confirm("确定要删除吗","提示",{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                remove(row.id).then((res) => {
                  if (res.data.code == "0000") {
                    this.$message({
                    type: "success",
                    message: "删除成功"
                    });
                  }
                    this.list();
                })
            })
        },
        handleUpdate(row,index,done) {
            edit(row).then((res) => {
              if (res.data.code == "0000") {
                    this.$message({
                    type: "success",
                    message: "编辑成功"
                    });
              }
              this.list();
              done();
            })
        },
        handleSave(row,done) {
            add(row).then(() => {
                this.list();
                done();
            })
        },
        change(row) {
            changeStatus(row).then(() => {
              this.$message({
                type: "success",
                message: "修改状态成功"
              });
              this.list();
            });
        },
        selectionChange(list) {
            console.log(list);
            list != "" ? this.disabled = false : this.disabled = true;
        },
        refreshChange() {
          this.list();
        },
        list(){
          list(this.page.pageCurrent,this.page.pageSize,this.nameOrCode,this.status).then(res => {
             this.page.total = res.data.data.total
              this.tableData = res.data.data.items;
              this.tableData.forEach(v => {
                  v.status = v.status;
              })
          })
        },
        envText: function() {
            return this.env
        },
        handlePreview() {

        },
        handleRemove() {

        },
        beforeRemove() {

        },
        handleExceed() {

        },
        fileList() {

        },
        downloadExecl1() {

        }
  }
}
</script>
<style scoped>
    .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        padding-left: 10px;
    }
</style>