<template>
	<div>
		<basic-container>
			<div>
				<span>{{$t('RoleName')}}：</span>
				<el-input v-model="roleName" placeholder="" readonly style="width: 200px;height:50px;"></el-input>
				<el-input v-model="roleId" placeholder="" style="width: 200px;height:50px;display:none"></el-input>
			</div>

			<!-- 树结构 -->
			<div style="marginTop: 20px;display: flex;">
				{{$t('User')}}：
				<el-tree :data="data"
						 show-checkbox
						 node-key="id"
						 ref="tree"
						 highlight-current
						 @check="clickCheck"
						 @setCheckedKeys="setCheckedKeys"
						 @node-collapse="nodeCollapse"
						 slot="reference"
						 :props="defaultProps">
				</el-tree>
			</div>
			<el-button type="info" @click="saveRoleUser" style="marginTop: 20px;marginLeft:50px">{{$t('save')}}</el-button>

		</basic-container>
	</div>
</template>
<script>
	import { addRoleUserNew, userJurisdiction } from '@/api/userToRole'
	export default {
		data() {
			return {
				roleName: '', //tokenName 双向绑定的值
				roleId: '',
				tableData: [], //表结构
				check: {},
				checkedKeys: [],
				// 树结构数据
				data: [],
				defaultProps: {
					children: 'children',
					label: 'label'
				},
				// 表结构数据
			};
		},
		created() {
			//权限
			this.getUserJurisdiction();
			console.log("参数name=", this.$route.query.roleName);
			this.roleName = this.$route.query.roleName;
			this.roleId = this.$route.query.roleId;
		},
		methods: {
			//保存角色用户
			saveRoleUser() {
				console.log('更新角色用户');
				let flag;
				flag = false;
				this.checkedKeys.forEach(item => {
					console.log('item', item);
					console.log('roleId', this.roleId);
					addRoleUserNew(item, this.roleId).then(res => {
						console.log("返回res=", res);
						if (res.data != null && res.data != "") {
							flag = true;
						}
					})
				})
				if (flag) {
					this.$message({
						message: this.$t('OperationSuccess'),
						type: "success"
					});
				}
			},
			// 获取权限
			getUserJurisdiction() {
				userJurisdiction("").then(res => {
					this.data = res.data
				})
			},
			// 选中的节点
			clickCheck(currentObj, treeStatus) {
				this.str = currentObj.id
				console.log(currentObj, treeStatus.checkedKeys);
				this.check = currentObj
				this.checkedKeys = treeStatus.checkedKeys
			},
			checkRow(checked, item) {
				console.log(checked, item);
				this.checkId = eval(checked.join("+"))
				console.log(this.checkId);
			},
			radioRow(checked, item) {
				console.log('单选', checked, item);
				this.radioId = checked
				console.log(this.radioId);
			},
			setCheckedKeys(keys, leafOnly) {
				console.log('111');
				console.log(keys.checkedNodes.label, leafOnly);
			},
			nodeCollapse(key, value) {
				console.log(key, value);
			}
		}
	};
</script>
