<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{$t('AFFILIATED CORPORATION')}}</div>
            </div>
            <template>
                <avue-crud ref="crud" v-model="obj" :option="setData.tableOpt" :data="tableData" :row-style="rowStyle" :page.sync="page" @current-change="currentChange" @row-update="editInfo" @row-del="delInfo" @row-save="handleSave">
                    <template slot="menuRight" style="width: calc(100% - 80px);">
                        <el-row :gutter="20" class="topFromSearch">
                            <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('CORPORATE NAME')}}:</span>
                                    <el-input v-model="nameVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('CORPORATE ABBREVIATION')}}:</span>
                                    <el-input v-model="shortNameVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('CORPORATE CODE')}}:</span>
                                    <el-input v-model="codeVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('LOCATION')}}:</span>
                                    <el-input v-model="addressVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                            </el-col>
                            <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top:10px;">
                                <el-button type="primary" size="small" plain @click="searchFrom">{{$t('SEARCH')}}</el-button>
                                <!--<el-button type="primary" size="small" icon="el-icon-plus" style="margin-left:10px;" @click="$refs.crud.rowAdd()">{{$t('ADD')}}</el-button>-->
                            </el-col>
                        </el-row>
                    </template>
                    <template slot-scope="{row,index,size,type}" slot="menu">
                        <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')" @click="$refs.crud.rowEdit(row,index)"></el-button>
                        <el-button icon="el-icon-delete-solid" type="text" size="medium" :title="$t('Delete')" @click="delInfo(row,index)"></el-button>
                    </template>
                    <template slot-scope="{row,index,type}" slot="menuForm">
                        <el-button type="primary" icon="el-icon-circle-plus-outline"  size="small" v-if="type=='add'" @click="$refs.crud.rowSave()">{{$t("Hold")}}</el-button>
                        <el-button type="primary" icon="el-icon-circle-check"  size="small" v-if="type=='edit'" @click="$refs.crud.rowUpdate()">{{$t("Hold")}}</el-button>
                        <el-button icon="el-icon-circle-close"  size="small" @click="$refs.crud.closeDialog()">{{$t("Cancel")}}</el-button>
                    </template>
                </avue-crud>
            </template>
        </basic-container>
    </div>
</template>
<style scoped>
    .dataicon i {
        display: none;
    }
</style>
<script>
    import { List, add, edit, del } from "@/api/affiliatedCorp";
    import { ProcessDropDownList, TracingDropDownList, TracingUnifiedIsBool } from "@/api/unified";
    export default {
        data() {
            return {
                obj: {},
                nameVal: "",
                shortNameVal: "",
                codeVal: "",
                addressVal: "",
                tableData: [],
                YesNoData: [
                    {
                        label: this.$t('False'),
                        value: false
                    }, {
                        label: this.$t('True'),
                        value: true
                    }

                ],
                TypeData: [
                    {
                        label: this.$t('CONTRACT SIGNING COMPANY'),
                        value: 1
                    }, {
                        label: this.$t('ASSOCIATED PROCESSING PLANT'),
                        value: 3
                    }

                ],
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev,next",
                    background: true,
                    pageSize: 10
                }
            }
        },
        computed: {
            setData() {
                var validateExistName = (rule, value, callback) => {
                    if ((value != '' || value != null)) {
                        var ids = this.obj.Id;
                        if (this.obj.Id == undefined) {
                            ids = "";
                        }
                        TracingUnifiedIsBool("affiliatedCorpNameExist", ids, value).then(resu => {
                            var IsExist = resu.data;
                            if (!IsExist) {
                                callback(new Error(this.$t('NameIsExist')));
                            } else { callback(); }
                        }).catch((erro) => { callback(); });
                    } else {
                        callback();
                    }
                };
                var validateExistShortName = (rule, value, callback) => {
                    if ((value != '' || value != null)) {
                        var ids = this.obj.Id;
                        if (this.obj.Id == undefined) {
                            ids = "";
                        }
                        TracingUnifiedIsBool("affiliatedCorpShortNameExist", ids, value).then(resu => {
                            var IsExist = resu.data;
                            if (!IsExist) {
                                callback(new Error(this.$t('NameIsExist')));
                            } else { callback(); }
                        }).catch((erro) => { callback(); });
                    } else {
                        callback();
                    }
                };
                var validateExistCode = (rule, value, callback) => {
                    if ((value != '' || value != null)) {
                        var ids = this.obj.Id;
                        if (this.obj.Id == undefined) {
                            ids = "";
                        }
                        TracingUnifiedIsBool("affiliatedCorpCodeExist", ids, value).then(resu => {
                            var IsExist = resu.data;
                            if (!IsExist) {
                                callback(new Error(this.$t('CodeIsExist')));
                            } else { callback(); }
                        }).catch((erro) => { callback(); });
                    } else {
                        callback();
                    }
                };
                var validateExistOnly = (rule, value, callback) => {
                    if (value) {
                        var ids = this.obj.Id;
                        if (this.obj.Id == undefined) {
                            ids = "";
                        }
                        TracingUnifiedIsBool("affiliatedCorpExistOnlyIsDefault", ids, value).then(resu => {
                            var IsExist = resu.data;
                            if (!IsExist) {
                                callback(new Error(this.$t('ISDEFAULTNOTONLY')));
                            } else { callback(); }
                        }).catch((erro) => { callback(); });
                    } else {
                        callback();
                    }
                };
                return {
                    tableOpt: {
                        page: true,
                        refreshBtn: false,
                        emptyText: this.$t('No Data'),
                        menuTitle: this.$t('OPERATION'),
                        menuWidth: 140,
                        align: 'center',
                        columnBtn: false,
                        simplePage: false,
                        editTitle: this.$t('Edit'),
                        addTitle: this.$t('Add'),
                        menu: false,
                        addBtn: false,
                        editBtn: false,
                        delBtn: false,
                        header: true,
                        saveBtn: false,
                        updateBtn: false,
                        cancelBtn: false,
                        column: [
                            {
                                label: this.$t('CORPORATE NAME'),
                                prop: 'Name',
                                placeholder: ' ',
                                rules: [{ required: true, message: this.$t('Required'), trigger: "blur" },
                                    { required: true, validator: validateExistName, trigger: "blur" }]
                            },
                            {
                                label: this.$t('TYPE'),
                                prop: 'Type',
                                type: "select",
                                placeholder: ' ',
                                filterable: true,
                                dicData: this.TypeData,
                                rules: [{ required: true, message: this.$t('Required'), trigger: "blur" }]
                            },
                            {
                                label: this.$t('CORPORATE ABBREVIATION'),
                                prop: 'ShortName',
                                placeholder: ' ',
                                rules: [{ required: true, message: this.$t('Required'), trigger: "blur" },
                                    { required: true, validator: validateExistShortName, trigger: "blur" }]
                            },
                            {
                                label: this.$t('CORPORATE CODE'),
                                prop: 'Code',
                                placeholder: ' ',
                                rules: [{ required: true, message: this.$t('Required'), trigger: "blur" },
                                    { required: true, validator: validateExistCode, trigger: "blur" }]
                            },
                            {
                                label: this.$t('LOCATION'),
                                prop: 'Address',
                                placeholder: ' ',
                            },
                            {
                                label: this.$t('IS DEFAULT'),
                                prop: 'IsDefault',
                                type: "radio",
                                placeholder: ' ',
                                value:false,
                                dicData: this.YesNoData,
                                rules: [{ required: true, message: this.$t('Required'), trigger: "blur" },
                                { validator: validateExistOnly, trigger: "blur" }]
                            },
                            {
                                label: this.$t('REMARK'),
                                prop: 'Remark',
                                placeholder: ' ',
                            },

                        ]
                    }
                }
            }
        },
        created() {
            this.getList();
        },
        methods: {
            focusDiv(obj) {//搜索框获取焦点
                if (obj.currentTarget === undefined) {
                    obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                } else {
                    obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                }
            },//搜索框失去焦点
            blurDiv(obj) {
                if (obj.currentTarget === undefined) {
                    if (obj.value === "") {
                        obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                } else {
                    if (obj.target.value == "") {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                }

            },//表格行样式
            rowStyle({ rowIndex }) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
            },//切换分页
            currentChange(val) {
                this.page.currentPage = val;
                this.getList();
            },//搜索
            searchFrom() {
                this.getList();
            },
            //显示数据
            getList() {
                List(this.nameVal, this.shortNameVal, this.codeVal, this.addressVal, this.page.currentPage, this.page.pageSize).then(res => {
                    this.tableData = res.data.AffiliatedCorporations;
                    this.page.total = res.data.TotalCount;
                    this.page.pageSize = res.data.PageSize;
                }).catch(erro => {
                    console.log(erro);
                })
            },
            //编辑数据
            editInfo(row, index, done) {
                edit(row).then(() => {
                    this.$message({ showClose: true, message: this.$t('UpdateSuccess'), type: "success" });
                    this.getList();
                    this.obj = {};
                    done();
                })
            },
            //删除数据
            delInfo(row, done) {
                this.$confirm(
                    this.$t('IsDel') + row.Name, this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"

                }).then(() => {
                    del(row.Id).then(res => {
                        this.getList();
                    })
                    this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                })
            },
            //新增数据 按照认证信息添加
            handleSave(row, done) {
                add(row).then(() => {
                    this.$message({ showClose: true, message: this.$t('AddSuccess'), type: "success" });
                    this.getList();
                    this.obj = {};
                    done();
                }).catch(() => { });
            },
        }
    }
</script>