<template>
  <div>
        <basic-container>
            <el-button icon="el-icon-back" type="success" @click="returnLink">返回</el-button>
            <el-collapse v-model="activeNames" @change="handleChange" style="marginTop: 30px;">
                <el-collapse-item title="基础信息" name="1">
                    <el-form align="center" class="content" ref="ruleform" :model="form" label-width="80px" :rules="rules">
                        <el-row :gutter="20">
                            <el-col :span="6">
                                <el-form-item label="工艺单号">
                                    <el-input placeholder="工艺单号" v-model="form.name" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="工艺员" prop="workPerson">
                                    <el-select v-model="form.person" placeholder="请选择工艺员"  class="formSelect" disabled>
                                        <el-option
                                        v-for="item in personOptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.userId">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="产品名称" prop="productId">
                                    <el-select v-model="form.productId" placeholder="请选择" @change="chengeProduct"  class="formSelect" disabled>
                                        <el-option
                                        v-for="item in wklnOptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="批 号">
                                    <el-select v-model="form.batchCode" placeholder="请选择"  class="formSelect" disabled>
                                        <el-option
                                        v-for="item in batchOptions"
                                        :key="item.value"
                                        :label="item.batchCode"
                                        :value="item.batchCode">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="生产工序" prop="processId">
                                    <el-select v-model="form.processId" placeholder="请选择" @change="wklnChange" class="formSelect" disabled>
                                        <el-option
                                        v-for="item in wklnOption"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-collapse-item>
                <el-collapse-item title="工艺信息" name="2">
                    <el-form :model="form" label-width="100px" :rules="rules">
                        <el-row :gutter="24">
                            <el-col :span="5">
                                <el-form-item label="设备机型" prop="machineType">
                                    <el-select v-model="form.machineModelId" placeholder="请选择" @change="proccessChange">
                                        <el-option
                                        v-for="item in proccessOptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="19">
                                <el-form-item label="适合机台" prop="checkArrayList">
                                        <div class="borderWoker">
                                            <el-checkbox-group v-model="checkArrayList" @change="changeCheck" style="float: left;">
                                                <el-checkbox v-for="value in checkboxList" :label="value.id" border style="float: left;">{{value.name}}</el-checkbox>
                                            </el-checkbox-group>
                                        </div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="10">
                                <el-form-item label="定量(g/km)" prop="hourYieldPerson">
                                        <el-input-number v-model="form.hourYieldPerson" @change="handleChange" :min="1" :max="10000" label="描述文字" style="width: 41%;" value="1"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="14">
                                <el-form-item label="台时单产" prop="hourYield">
                                    <el-input-number v-model="form.hourYield" @change="handleChange" :min="1" :max="10000" label="描述文字"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="10">
                                <el-form-item label="公定回潮率%" prop="&">
                                    <el-input-number v-model="form.moistureRegain" @change="handleChange" :min="1" :max="10000" label="描述文字"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="14">
                                <el-form-item label="运转效率%" prop="efficiencyPercent">
                                    <el-input-number v-model="form.efficiencyPercent" @change="handleChange" :min="1" :max="10000" label="描述文字"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-collapse-item>
                <el-collapse-item title="工艺项目信息" name="3">
                    <el-table
        header-align="center"
      :data="tableData"
      style="width: 100%">
      <el-table-column
        type="index"
        label="序号"
        width="80">
      </el-table-column>
      <el-table-column
        prop="name"
        label="工艺项目"
        width="100">
      </el-table-column>
      <el-table-column
        prop="specParamValue"
        header-align="center"
        width="200"
        label="工艺项目值">
        <template slot-scope="scope">
            <el-input-number v-if="scope.row.inputType == 1" v-model="scope.row.specParamValue" :min="1" :max="10000" label="描述文字"></el-input-number>
            <el-select v-model="scope.row.specParamValue" v-else>
                <el-option :label="scope.row.specParamValue" :value="scope.row.specParamValue">
                </el-option>
            </el-select>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        width="200"
        label="上限值">
        <template slot-scope="scope">
            <el-input-number v-if="scope.row.inputType == 1" v-model="scope.row.maxValue" @change="handleChange" :min="1" :max="10000" label="上限值"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        width="200"
        label="下限值">
        <template slot-scope="scope">
            <el-input-number v-if="scope.row.inputType == 1" v-model="scope.row.minValue" @change="handleChange" :min="1" :max="10000" label="下限值"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column
        prop="paramType"
        :formatter="formatParamType"
        label="项目类型">
        <template slot-scope="scope">
            <p v-if="scope.row.paramType == 1"> 设备工艺 </p>
            <p v-else> 运转工艺 </p>
        </template>
      </el-table-column>
      <el-table-column
        prop="isNull"
        label="是否允许为空">
        <template slot-scope="scope">
            <p v-if="scope.row.isNull == 1"> 是 </p>
            <p v-else> 否 </p>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        label="是否翻改项目">
      </el-table-column>
      <el-table-column
        prop="dataType"
        label="数据类型">
        <template slot-scope="scope">
            <p v-if="scope.row.dataType == 1"> 数值型 </p>
            <p v-else> 字符型 </p>
        </template>
      </el-table-column>
    </el-table>
                </el-collapse-item>
            </el-collapse>
        </basic-container>
  </div>
</template>
<script>
import {add} from "@/api/standardList";
import {productWork,getpoccessByproduct,wklnList,machineModelList,getmachineByMachineModelId,getwklnWorkBywkln,getPerson} from "@/api/config";
export default {
    data() {
        return {
            form:{
                person: "",
                hourYield: "",
                //单产分母
                hourYieldPerson: "1",
                //单产分子
                hourYieldSon: 1,
                //运转效率
                efficiencyPercent: 1,
                //公定回潮
                moistureRegain: 1,
                //定量
                moistureRegain: 1,
                machineModelId: "",
                //产品名称
                productId: "",
                //工序
                processId: "",
                //选择机台
                checkArrayList: [],
                //批号
                batchCode: ""
            },
            person: "",
            //工艺员
            personOptions: [],
            //
            specParamList: [],
            //全选
            isIndeterminate: false,
            checkArrayList: [],
            checkboxList: [],
            //设备机型
            proccess: "",
            proccessOptions: [],
            wklnValue: "",
            //生产工序
            wklnOption: [],
            batchOptions: [],
            //产品名称
            product: [],
            activeNames: ["1","2","3"],
            form: {},
            wklnOptions: [],
            rules: {
                productId: [ {required: true, message: '请选择产品名称', trigger: 'blur'} ],
                processId: [ {required: true, message: '请选择生产工序', trigger: 'blur'} ],
                machineType: [ {required: true, message: '请选择设备机型', trigger: 'blur'} ],
                hourYield: [ {required: true, message: '请选择台时单产', trigger: 'blur'} ],
                efficiencyPercent: [ {required: true, message: '请选择运转效率%', trigger: 'blur'} ],
                hourYieldPerson: [ {required: true, message: '请选择定量(g/km)', trigger: 'blur'} ],
                textCode: [ {required: true, message: '请输入活动名称', trigger: 'blur'} ]
            }
        }
    },
    created() {
        console.log(this.$route.query.pushData);
        this.form = this.$route.query.pushData;
        //工艺员
        getPerson().then(res => {
            this.personOptions = res.data.data;
        });
        machineModelList().then(res => {
            this.proccessOptions = res.data;
        });
        wklnList().then(res => {
            this.wklnOption = res.data;
        })
        productWork().then(res => {
            this.wklnOptions = res.data.data;
        })
    },
    methods: {
        returnLink() {
            this.$router.push({path: "realityList"});
        },
        formatParamType(row) {
            let formatData = [{
                label: "是",
                value: 1
            },{
                label: "否",
                value: 0
            }];
            formatData.forEach(v => {
                if(row.id) {
                    
                }
            })
        },
        allClick() {
        },
        changeCheck(value) {
            if(value == "全选") {
                let checkList = [];
                this.checkboxList.forEach(v => {
                    checkList.push(v.id);
                });
                checkList.push("全选");
                this.$set(this.form,"checkArrayList",checkList);
                console.log(this.checkArrayList);
            }
        },
        proccessChange(value) {
            getmachineByMachineModelId(value).then(res => {
                this.checkboxList = res.data.data;
                this.$forceUpdate();
            });
        },
        wklnChange(value) {
            getwklnWorkBywkln(value).then(res => {
                res.data.data.forEach(v => {
                    if(v.inputType == 1) {
                        v.maxValue = 1;
                        v.minValue = 1;
                    }
                });
                this.tableData = res.data.data;
                this.$forceUpdate();
            })
        },
        chengeProduct(value) {
            getpoccessByproduct(value).then(res => {
                this.batchOptions = res.data.data;
            })
        },
        save() {
            let row = {
                //工艺员
                specUserid:this.form.person,
                productId: this.form.productId,
                batchCode: this.form.batchCode,
                processId:this.form.processId,
                machineModelId:this.form.machineModelId,
                //台时单产
                hourYield:this.form.hourYield / 24,
                moistureRegain: this.form.moistureRegain,
                //定量相除
                gramWeight:this.form.hourYieldPerson / this.form.hourYieldSon,
                efficiencyPercent: this.form.efficiencyPercent
            };
            //选择机台
            let machineIds = this.checkArrayList;
            let specSheetParamDtoList = [];
            this.tableData.forEach(v => {
                specSheetParamDtoList.push({maxValue: v.maxValue,minValue: v.minValue,specParamId: v.id,value: v.specParamValue})
            });
            let formName = "ruleform";
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    add(row,machineIds,specSheetParamDtoList).then(() => {
                        this.$message({
                            message: "保存成功",
                            type: "success"
                        });
                    }).then(() => {
                        this.$router.push({path: "standardList"});
                    });
                } else {
                    return false;
                }
            });
            
        }
    }
}
</script>
<style lang="scss" scoped>
    .formSelect {
        width: 100%;
    }
</style>