import request from '@/router/axios';
import {businessUrl} from "@/config/env"
export const list = (pageNo,pageSize,modelType,processId,nameOrCode) => {
    return request({
        url : businessUrl + "machine/selectPage",
        method: "post",
        data: {
            pageNo,
            pageSize,
            modelType,
            nameOrCode,
            processId
        }
    })
}
//编辑
export const edit = (row) => {
    return request({
        url : businessUrl + "machine/update",
        method: "put",
        data: {
            ...row
        }
    })
}
//删除
export const remove = (id) => {
    return request({
        url : businessUrl + "machine/delete",
        method: "delete",
        params: {
            id
        }
    })
}
export const add = (row) => {
    return request({
        url : businessUrl + "machine/insert",
        method: "post",
        data : {
            type: 2,
            ...row,
            status: 1
        }
    })
}
export const  selectByPromayKey = (id,auditStatus) => {
    return request({
        url : businessUrl + "machine/selectByPromayKey",
        method: "put",
        data: {
            id,
            auditStatus
        }
    })
}
export const  selectByplcOwner = (id,auditStatus) => {
    return request({
        url : businessUrl + "machine/plcOwner",
        method: "get"
    })
}
export const  selectByhmiOwner = (id,auditStatus) => {
    return request({
        url : businessUrl + "machine/hmiOwner",
        method: "get"
    })
}
export const  selectBymanufacturer = (id,auditStatus) => {
    return request({
        url : businessUrl + "machine/manufacturer",
        method: "get"
    })
}
// 所属工序
export const isWorking = () => {
    return request({
        url : businessUrl + "process/selectAll",
        method: "get",
        params: {
           
        }
    })
}