var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("Add"),
            visible: _vm.addBool,
            width: "80%",
            "append-to-body": true
          },
          on: {
            "update:visible": function($event) {
              _vm.addBool = $event
            }
          }
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { title: "基础信息", name: "1" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { inline: "", model: _vm.form, "label-width": "80px" }
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "工艺单号" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入工艺单号" },
                            model: {
                              value: _vm.form.name,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "单据日期" } },
                        [
                          _c("el-date-picker", {
                            attrs: { type: "date", placeholder: "选择日期" },
                            model: {
                              value: _vm.value1,
                              callback: function($$v) {
                                _vm.value1 = $$v
                              },
                              expression: "value1"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "工艺员" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.value,
                                callback: function($$v) {
                                  _vm.value = $$v
                                },
                                expression: "value"
                              }
                            },
                            _vm._l(_vm.wklnOptions, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品名称" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.value,
                            callback: function($$v) {
                              _vm.value = $$v
                            },
                            expression: "value"
                          }
                        },
                        _vm._l(_vm.wklnOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "批号" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.value,
                            callback: function($$v) {
                              _vm.value = $$v
                            },
                            expression: "value"
                          }
                        },
                        _vm._l(_vm.wklnOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "生产工序" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.value,
                            callback: function($$v) {
                              _vm.value = $$v
                            },
                            expression: "value"
                          }
                        },
                        _vm._l(_vm.wklnOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("el-collapse-item", { attrs: { title: "工艺信息", name: "2" } }, [
            _c("div", [
              _vm._v(
                "控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；"
              )
            ]),
            _c("div", [
              _vm._v("页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。")
            ])
          ]),
          _c(
            "el-collapse-item",
            { attrs: { title: "工艺项目信息", name: "3" } },
            [
              _c("div", [_vm._v("简化流程：设计简洁直观的操作流程；")]),
              _c("div", [
                _vm._v(
                  "清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；"
                )
              ]),
              _c("div", [
                _vm._v(
                  "帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。"
                )
              ])
            ]
          ),
          _c(
            "p",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "-30px" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.addBool = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitAdd } },
                [_vm._v(_vm._s(_vm.$t("Yes")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "basic-container",
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                "table-loading": _vm.tableLoading,
                page: _vm.page,
                "before-open": _vm.beforeOpen
              },
              on: {
                "current-change": _vm.currentChange,
                "size-change": _vm.sizeChange,
                "selection-change": _vm.selectionChange,
                "refresh-change": _vm.refreshChange,
                "row-save": _vm.handleSave,
                "row-update": _vm.handleUpdate,
                "row-del": _vm.handleDel
              },
              scopedSlots: _vm._u([
                {
                  key: "status",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        attrs: { "inactive-value": 1, "active-value": 0 },
                        on: {
                          change: function($event) {
                            return _vm.change(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.status,
                          callback: function($$v) {
                            _vm.$set(scope.row, "status", $$v)
                          },
                          expression: "scope.row.status"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "name",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-link",
                        {
                          on: {
                            click: function($event) {
                              return _vm.linkToLook(scope.row)
                            }
                          }
                        },
                        [_vm._v(_vm._s(scope.row.name))]
                      )
                    ]
                  }
                },
                {
                  key: "baseName",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-link",
                        {
                          on: {
                            click: function($event) {
                              return _vm.linkToTab(scope.row)
                            }
                          }
                        },
                        [_vm._v(_vm._s(scope.row.baseName))]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.tableFrom,
                callback: function($$v) {
                  _vm.tableFrom = $$v
                },
                expression: "tableFrom"
              }
            },
            [
              _c("template", { slot: "menuLeft" }, [_c("el-dropdown")], 1),
              _c(
                "template",
                { slot: "menuRight" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "150px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: "设备机型选择",
                        size: "small"
                      },
                      on: { change: _vm.getMachineModelId },
                      model: {
                        value: _vm.machineModel,
                        callback: function($$v) {
                          _vm.machineModel = $$v
                        },
                        expression: "machineModel"
                      }
                    },
                    _vm._l(_vm.machineModelList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "150px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: "设备选择",
                        size: "small"
                      },
                      model: {
                        value: _vm.machine,
                        callback: function($$v) {
                          _vm.machine = $$v
                        },
                        expression: "machine"
                      }
                    },
                    _vm._l(_vm.machineList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "110px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: "工序",
                        size: "small"
                      },
                      model: {
                        value: _vm.wkln,
                        callback: function($$v) {
                          _vm.wkln = $$v
                        },
                        expression: "wkln"
                      }
                    },
                    _vm._l(_vm.wklnList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-search"
                      },
                      on: { click: _vm.search }
                    },
                    [_vm._v(_vm._s(_vm.$t("search")))]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }