var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "volumeSet" },
    [
      _c(
        "el-dialog",
        {
          staticStyle: { marginTop: "-60px" },
          attrs: {
            visible: _vm.modelShow,
            width: "35%",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.modelShow = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.form, "label-width": "120px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "机台编号:" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.form.code,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "code", $$v)
                                  },
                                  expression: "form.code"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "维修人员:" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.form.code,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "code", $$v)
                                  },
                                  expression: "form.code"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "故障大类:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.form.code,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "code", $$v)
                                    },
                                    expression: "form.code"
                                  }
                                },
                                _vm._l(_vm.options, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "故障小类:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.form.code,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "code", $$v)
                                    },
                                    expression: "form.code"
                                  }
                                },
                                _vm._l(_vm.options, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注:" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  resize: "none",
                                  type: "textarea",
                                  rows: 6
                                },
                                model: {
                                  value: _vm.form.code,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "code", $$v)
                                  },
                                  expression: "form.code"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "p",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "-30px" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.makeSure } },
                [_vm._v("呼叫维修")]
              ),
              _c("el-button", { on: { click: _vm.closeModel } }, [
                _vm._v("取 消")
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }