<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:10px;">{{$t('INFORMATION DECLARATION')}}</div>
            </div>
            <template>
                <div style="display:flex;">
                    <div style="width:200px;">
                        <span style="font-size: 16px;font-weight:600;"> {{$t('Process')}}</span> 
                        <el-steps direction="vertical" space="40px" :active="active" style="padding-top:20px">
                            <el-step v-for="(item,index) in stepData" :key="index" :code="item.Id"  :description="item.Name" :icon="item.Status?'iconfont icon-xuanzhongzhuangtai':'iconfont icon-huiseweixuanzhong'" @click.native="getActVal(index,item)">
                            </el-step>
                        </el-steps>
                    </div>
                    <div style="width: calc(100% - 200px); padding-left: 30px; box-sizing: border-box;" class="upd">
                        <div class="mainBox" v-if="!isPO">
                            <div style="margin-bottom:30px;" class="topInfo">
                                <el-row>
                                    <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                        <span><i class="el-icon-s-custom"></i>{{$t('FROM')}} : {{formData.fromVal}}/{{formData.departVal}}</span>
                                    </el-col>

                                    <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                        <span class="spanRight"><i class="el-icon-brush"></i>{{$t('COLOR')}} : {{formData.colorVal}}</span>
                                    </el-col>
                                    <el-col :sm="24" :md="12" :lg="4" style="display:flex;margin-bottom:20px;">
                                        <span class="spanRight"><i class="el-icon-shopping-cart-full"></i>{{$t('QUANTITY')}} : {{formData.numberVal}}/{{formData.unitVal}}</span>
                                        <span class="spanLeft"></span>
                                    </el-col>
                                    <el-col :sm="24" :md="12" :lg="4" style="display:flex;margin-bottom:20px;">
                                        <span class="spanRight"><i class="el-icon-map-location"></i>{{$t('MARKET')}} : {{formData.destinationCountryNameVal}}</span>
                                    </el-col>
                                </el-row>
                                <el-row>

                                    <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                        <span class="spanRight"><i class="el-icon-attract"></i>{{$t('POBATCH')}} : {{formData.batchVal}}</span>
                                    </el-col>
                                    <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                        <span class="spanRight"><i class="el-icon-price-tag"></i>{{$t('POProductCode')}} : {{formData.codeVal}}</span>
                                    </el-col>
                                    <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                        <span class="spanRight"><i class="el-icon-coin"></i>{{$t('POProductName')}} : {{formData.POproductVal}}</span>
                                    </el-col>

                                </el-row>
                                <el-row>
                                    <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                        <span class="spanRight"><i class="el-icon-document"></i>{{$t('SO')}} : {{formData.soVal}}</span>
                                    </el-col>
                                    <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                        <span class="spanRight"><i class="el-icon-coin"></i>{{$t('InternalProductName')}} : {{formData.productVal}}</span>
                                    </el-col>
                                    <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                        <span class="spanRight"><i class="iconfont icon-rili"></i>{{$t('At')}} : {{formData.odateVal}}</span>
                                    </el-col>
                          
                                </el-row>
                            </div>
                                <div>
                                    <div class="qrcode" id="qrCodeUrl" ref="qrCodeUrl" @click="onPrint">
                                        <vue-qr :logoSrc="declaraQrcodeColor" :logoScale="20" :margin="2" colorDark='#000' :text="declaraQrcode" :size="120"></vue-qr>
                                    </div>
                                    <span style="color: white; font-size: 14px; ">Mobile  Traceability</span>
                                </div>
                        </div>
                        <div class="mainBox" v-if="isPO">
                            <div style="margin-bottom:30px;" class="topInfo">
                                <el-row>
                                    <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                        <span class="spanRight"><i class="el-icon-s-custom"></i>{{$t('Supplier')}} : {{formData.supplierVal}}</span>
                                    </el-col>
                                    <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                        <span class="spanRight"><i class="iconfont icon-rili"></i>{{$t('At')}} : {{formData.OrderDateVal}}</span>
                                    </el-col>
                                    <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                        <span><i class="el-icon-attract"></i>{{$t('BATCH')}} : {{formData.BatchVal}}</span>
                                        <span class="spanLeft"></span>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                        <span class="spanRight"><i class="el-icon-document"></i>{{$t('PO')}} : {{formData.poVal}}</span>
                                    </el-col>
                                    <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                        <span><i class="el-icon-document"></i>{{$t('BUSINESS SERIAL NUMBER INTERNAL')}} : {{formData.BusinessNoInVal}}</span>
                                    </el-col>
                                    <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                        <span class="spanRight"><i class="el-icon-document"></i>{{$t('BUSINESS SERIAL NUMBER EXTERNAL')}} : {{formData.BusinessNoOutVal}}</span>
                                        <span class="spanLeft"></span>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                        <span class="spanRight"><i class="el-icon-coin"></i>{{$t('Product Name')}} : {{formData.ProductNameVal}}</span>
                                  
                                    </el-col>

                                    <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                        <span class="spanRight"><i class="el-icon-brush"></i>{{$t('COLOR')}} : {{formData.ColorVal}}</span>
                                    </el-col>
                                    <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                                        <span class="spanRight"><i class="el-icon-shopping-cart-full"></i>{{$t('QUANTITY')}} : {{formData.NumberVal}}/{{formData.UnitNameVal}}</span>
                                        <span class="spanLeft"></span>
                                    </el-col>
                                </el-row>
                            </div>
                            <div>
                                <div class="qrcode" id="qrCodeUrl" ref="qrCodeUrl" @click="onPrint">
                                    <vue-qr :logoSrc="declaraQrcodeColor" :logoScale="20" :margin="2" colorDark='#000' :text="declaraQrcode" :size="120"></vue-qr>
                                </div>
                                <span style="color: white; font-size: 14px; ">Mobile  Traceability</span>
                            </div>
                        </div>
                        <avue-crud :data="topTable" :option="setData.tableOpt" @row-click="handleRowClick">
                            <template slot="header">
                                <span style="font-size:16px;font-weight:700"> {{$t('Batchdetails')}}</span>
                                <el-tag size="small">{{$t('BatchdetailsExplain')}}</el-tag>
                                <span style="font-size:12px;font-weight:500;" v-if="allowmultiple"> {{$t('GetMore')}}</span>
                                <span style="font-size:12px;font-weight:500;" v-if="!allowmultiple"> {{$t('GetNoMore')}}</span>
                            </template>
                            <template slot-scope="{row,index,size,type}" slot="menu">
                                <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')" @click="handleRowClick"></el-button>
                                <el-button icon="el-icon-delete-solid" type="text" size="medium" :title="$t('Delete')" @click="delSubject(row,index)" v-if="!isLocked"></el-button>
                            </template>
                        </avue-crud>
                        <div class="formTit">{{subjectId==''? $t('New')+' '+$t('DeclarationInformation'):$t('Edit')+$t('Index')+" ："+lineCur+' '+$t('DeclarationInformation')}}</div>
                        <div id="app3" style="width: calc(100% - 225px);display:flex; align-items: start; padding-top: 10px;">
                            <form-create v-model="fApi" :rule="rule" :option="option" />
                        </div>
                        <div class="uptTit">{{subjectId==''? $t('New')+' '+$t('Upload Fields'):$t('Edit')+$t('Index')+" ："+lineCur +' '+$t('Upload Fields')}}</div>
                        <div class="uploadF">
                            <div class="demo-input-suffix" style="display:flex;align-items: start;" v-for="(item,index) in formAt" :key="index">
                                <div class="ttt">
                                    <span style="color: #F56C6C; font-size: 15px" v-if="item.mustMpload">*</span>
                                    <el-tooltip class="item" effect="dark" :content="item.label" placement="top-end">
                                        <span style="font-size: .875rem;color: #333; width: 130px;">{{item.label}}:</span>
                                    </el-tooltip>
                                </div>
                                <div style="width: calc(100% - 225px);display:flex; align-items: start;">
                                    <el-upload class="upload-demo"
                                               :ref="item.name"
                                               style="width:calc(100% - 40px);"
                                               :on-change="beforeUpload"
                                               :auto-upload="false"
                                               multiple
                                               :on-preview="handlePreview"
                                               :on-remove="handleRemove"
                                               :before-remove="beforeRemove"
                                               :accept="item.type"
                                               :data="item"
                                               :action="targetAction">
                                        <el-input suffix-icon="iconfont icon-wenjianjiaFolders5" style="width:100%;" size="medium" @click.native="uploadClick(item)" :placeholder="$t('UploadRestrictionsA')+item.type+$t('UploadRestrictionsB')">
                                        </el-input>
                                        <!--<div class="el-upload__tip" slot="tip">{{$t('UploadRestrictionsA')+item.type+$t('UploadRestrictionsB')}}</div>-->
                                    </el-upload>
                                </div>
                            </div>
                        </div>
                        <div style="text-align:center;padding:50px 0;">
                            <el-button type="info" @click="add('tepm')" v-if="!isLocked">{{$t('TEMPORARY STORAGE')}}</el-button>
                            <el-button type="primary" @click="add('save')" v-if="!isLocked">{{$t('Save')}}</el-button>
                            <el-button plain @click="revert">{{$t('CANCEL')}}</el-button>
                        </div>
                    </div>
                    </div>
            </template>
        </basic-container>

    </div>
</template>
<style scoped>
    .purK {
        width: 12px;
        height: 12px;
        display: inline-block;
        background: #D8D8D8;
        margin-right: 8px;
        border-radius: 2px;
    }

    .el-tabs__item.is-active .purK {
        background: #3953FE;
    }

    .purTopForm {
        display: flex;
        flex-wrap: wrap;
    }

        .purTopForm > div {
            width: 50%;
            margin-bottom: 20px;
        }

    .ttt {
        width: 9rem !important;
        text-align: right;
        display: inline-block;
        padding-right: 15px;
    }

    .uptTit {
        font-size: 16px;
        width: 100%;
        padding: 7px 0;
        border-bottom: 1px solid #EBEEF5;
        font-weight: 700;
        color: #ffffff;
        background: linear-gradient( 90deg, rgb(74, 74, 116), rgb(33, 33, 70));
    }

    .formTit {
        font-size: 16px;
        width: 100%;
        padding: 7px 0;
        border-bottom: 1px solid #EBEEF5;
        font-weight: 700;
        color: #ffffff;
        background: linear-gradient( 90deg, rgb(74, 74, 116), rgb(33, 33, 70));
    }

    .uploadF {
        margin-top: 30px;
    }

        .uploadF > div {
            margin-bottom: 20px;
        }

    .topInfo {
        background: linear-gradient(to right,#4A4A74,#212146);
        border-radius: 10px;
        padding: 30px 20px 10px;
        color: #fff;
        font-size: 14px;
        width: calc(100% - 120px);
        float: left;
    }

    .mainBox {
        padding: 20px 40px;
        background: linear-gradient(to right,#4A4A74,#212146);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
</style>
<script>
    import { pvhApiBase } from '@/config/env'
    import { orderProList, orderFileList, orderSubject, orderFieldList, addSubject, updateSubject, addFieldValue, delfile, delSubjectInfo, delFieldInfo } from "@/api/declaEntry";
    import { SaleInfo } from "@/api/salesOrd";
    import { POInfo } from "@/api/purchaseOrd";
    
    import { TracingUnifiedIsBool } from "@/api/unified";
    import axios from 'axios';
    import VueQr from 'vue-qr'

    export default {
        data() {
            return {
                declaraQrcode: "",
                declaraQrcodeColor:"",
                active: 0,
                orderId: "",
                subjectId: "",
                lineCur: 0,//当前选中行
                brandBusinessId: "",
                vendorSectionId: "",
                processId: "",
                loadNew: true,
                allowmultiple: true,
                isLocked: false,
                isPO: false,
                formData: {
                    soVal: '', fromVal: '', POproductVal: '', batchVal: '', codeVal: '', colorVal: '', numberVal: '', unitVal: '', odateVal: '', productVal: '', remarkVal: '', departVal: '', 
                    OrderDateVal: '', supplierVal: '', poVal: '', BusinessNoInVal: '', BusinessNoOutVal: '', ProductNameVal: '', BatchVal: '', ColorVal: '', NumberVal: '', UnitNameVal: '', destinationCountryNameVal: '',
                },
                stateData: [
                    {
                        label: this.$t('normal'),
                        value: 0
                    },
                    {
                        label: this.$t('temp'),
                        value: -1
                    }
                  ],
                targetAction: pvhApiBase + "Tracing/FormSingleFileUpload",
                fApi: {},
                rule: [],//存储表单json数据
                option: {
                    //显示表单重置按钮
                    submitBtn: false,
                    resetBtn: false,
                    "form": {
                        "labelPosition": "top",
                    },
                },
                upType: "",
                upName: "",
                fileBase64: "",
                stepData: [],//左侧流程
                topTable: [],//顶部 报关列表
                formAt: [],//流程对应的文件名称显示
                currItem: "",
           
            };
        },
        components: {
            VueQr
        },
        computed: {
            setData() {
                return {
                    tableOpt: {
                        index: true,
                        indexLabel: this.$t('Index'),
                        page: false,
                        align: 'center',
                        addBtn: false,
                        editBtn: false,
                        delBtn: false,
                        refreshBtn: false,
                        columnBtn: false,
                        emptyText: this.$t('No Data'),
                        menuTitle: this.$t('OPERATION'),
                        menuWidth: 180,
                        column: [
                            {
                                label: this.$t('FieldRatio'),
                                prop: 'FieldRatio',
                            },
                            {
                                label: this.$t('DocumentRatio'),
                                prop: 'DocumentRatio',
                            },
                            {
                                label: this.$t('STATE'),
                                prop: 'Status',
                                type: "select",
                                dicData: this.stateData,
                            },
                            {
                                label: this.$t('CREATTIME'),
                                prop: 'CreateTime',
                                type: "date",
                                format: 'yyyy-MM-dd HH:MM:ss',
                                valueFormat: 'yyyy-MM-dd HH:MM:ss',
                            },

                        ]
                    },
                }
            }
        },
        watch: {
            $route() {
                this.init()
            }
        },// watch的作用可以监控一个值的变换,并调用因为变化需要执行的方法。 //监听路由是否发生变化
        created() {
            this.init()// 在加载的时候执行了这个方法，可以调用这个根据ID来插叙的方法
        },//加载
        methods: {//加载数据
            init() {
                this.orderId = this.$route.query.orderId;
                this.brandBusinessId = this.$route.query.brandBusinessId;
                this.vendorSectionId = this.$route.query.vendorSectionId;
                this.isPO = this.$route.query.isPO == 'true'? true : false;
                this.getProList();//绑定流程项
                this.topInit();//绑定流程项

            },
            async topInit() {
                if (!this.isPO) {
                    await SaleInfo(this.orderId, this.orderId, this.brandBusinessId, this.vendorSectionId).then(res => {
                        var o = res.data;
                        this.formData.soVal = o.SOInfo.So;
                        this.formData.fromVal = o.SOInfo.PurchaseCorporationId;
                        this.formData.batchVal = o.SOInfoDetails.Batch;
                        this.formData.POproductVal = o.SOInfoDetails.OutProductName;
                        this.formData.codeVal = o.SOInfoDetails.Code;
                        this.formData.colorVal = o.SOInfoDetails.Color;
                        this.formData.numberVal = o.SOInfoDetails.Number;
                        this.formData.unitVal = o.SOInfoDetails.UnitId;
                        this.formData.odateVal = o.SOInfo.OrderDate !== '' ? o.SOInfo.OrderDate.substr(0, 10) : '';
                        this.formData.productVal = o.SOInfoDetails.ProductName;
                        this.formData.remarkVal = o.SOInfoDetails.Remark;
                        this.formData.destinationCountryNameVal = o.SOInfoDetails.DestinationCountryName;
                        this.formData.departVal = o.SOInfo.Division;
                        var ApproveStatus = o.SOInfoDetails.ApproveStatus;
                        var LockedStatus = o.SOInfoDetails.LockedStatus;
                        if (ApproveStatus == 100 || ApproveStatus == 50 || LockedStatus == 1) {
                            this.isLocked = true;
                        }
                        this.declaraQrcode = o.SOInfo.QrCodeUrl;
                        this.declaraQrcodeColor = o.SOInfo.QRColor;
                    })
                } else {
                    await POInfo(this.orderId, this.orderId, this.brandBusinessId, this.vendorSectionId).then(res => {
                        var o = res.data;
                        this.formData.supplierVal = o.POInfo.VendorCorporationId;
                        this.formData.batchVal = o.POInfoDetails.Batch;
                        this.formData.poVal = o.POInfoDetails.Po;
                        this.formData.BusinessNoInVal = o.POInfoDetails.BusinessNoIn;
                        this.formData.BusinessNoOutVal = o.POInfoDetails.BusinessNoOut;
                        this.formData.ProductNameVal = o.POInfoDetails.ProductName;
                        this.formData.BatchVal = o.POInfoDetails.Batch;
                        this.formData.ColorVal = o.POInfoDetails.Color;
                        this.formData.NumberVal = o.POInfoDetails.Number;
                        this.formData.UnitNameVal = o.POInfoDetails.UnitId;
                        this.formData.OrderDateVal = o.POInfo.OrderDate !== '' ? o.POInfo.OrderDate.substr(0, 10) : '';
                        var ApproveStatus = o.POInfoDetails.ApproveStatus;
                        var LockedStatus = o.POInfoDetails.LockedStatus;
                        if (ApproveStatus == 100 || ApproveStatus == 50 || LockedStatus == 1) {
                            this.isLocked = true;
                        }
                        this.declaraQrcode = o.POInfo.QrCodeUrl;
                        this.declaraQrcodeColor = o.POInfo.QRColor;
                    })
            }
            },
            async  getProList() {
                this.clearInfo();
                await orderProList(this.orderId, this.brandBusinessId, this.vendorSectionId, this.isPO).then(res => {
                    this.stepData = res.data;
                    if (this.processId=="")
                    {
                        this.processId = this.stepData[0].Id;
                        this.allowmultiple = this.stepData[0].Allowmultiple;
                    }
                    this.loadSbjInfo();
             
                })
            },
            //点击流程页面数据变换
            async  getActVal(index, item) {
                this.active = index;
                this.clearInfo();
                await orderProList(this.orderId, this.brandBusinessId, this.vendorSectionId, this.isPO).then(res => {
                    this.stepData = res.data;
                    this.processId = item.Id;
                    this.allowmultiple = item.Allowmultiple;
                    this.loadSbjInfo();
                })
            },
            async loadSbjInfo() {
                await orderSubject(this.orderId, this.processId, this.brandBusinessId).then(res1 => {
                    this.topTable = res1.data;
                    this.subjectId = "";
                    if (!this.allowmultiple) {
                        if (this.topTable.length > 0) {
                            this.subjectId = this.topTable[0].Id;
                        }
                    }
                    this.loadInfo();
                })
            },
            loadInfo() {
                orderFieldList(this.orderId, this.processId, this.subjectId, this.brandBusinessId, this.loadNew).then(res2 => {
                    this.rule = res2.data;
                       orderFileList(this.orderId, this.processId, this.subjectId, this.brandBusinessId, this.loadNew).then(res3 => {
                        this.formAt = res3.data;
                           for (var i = 0; i < this.formAt.length; i++) {
                               var formName = this.formAt[i].name;
                            if (this.formAt[i].listUrl != null) {
                                this.$refs[formName][0].uploadFiles = this.formAt[i].listUrl;
                            }
                        }
                    })
                })
            },
            handleRowClick(row) {
                this.clearInfo();
                this.subjectId = row.Id;
                this.lineCur = row.$index + 1;
              //  this.qrpocode = this.brandBusinessId+this.orderId + this.processId + this.subjectId;
                TracingUnifiedIsBool("SetIsSameDeclaration", this.subjectId).then(resu => {
                    var IsSame = resu.data;
                    if (IsSame) {
                        this.$confirm(this.$t('SetIsNotSame'), this.$t('Tips'), { confirmButtonText: this.$t('Yes'), cancelButtonText: this.$t('Cancel'), type: "warning" })
                            .then((re) => {
                                if (re == 'confirm') {
                                    //加载最新
                                    this.loadNew = true;
                                    //加载数据
                                    this.loadInfo();
                                } 
                            }).catch(action => {
                                //判断是 cancel (自定义的取消) 还是 close （关闭弹窗）
                                if (action === 'cancel') {
                                    //加载最新
                                    this.loadNew = false;
                                    //加载数据
                                    this.loadInfo();
                                } 
                            })
                    }
                    else {
                        this.loadNew = true;
                        //加载数据
                        this.loadInfo();
                    }
                }).catch(() => { });
            },
            add(e) {
                var is_temp = e;
                var s_state = 0;
                if (is_temp == 'tepm') { s_state = -1 }
                else {
                    var einfo = "";
                    //判断必传文件是否上传
                    for (var i = 0; i < this.formAt.length; i++) {
                        var mustMpload = this.formAt[i].mustMpload;
                        var formName = this.formAt[i].name;
                        if (mustMpload == 1) {
                            if (this.$refs[formName][0].uploadFiles.length == 0) {
                                einfo += "[" + this.formAt[i].label + "]" + this.$t('MustUpload') + ",";
                            }
                        }
                    }
                    if (einfo != "") {
                        this.$message({ message: einfo, type: "error" });
                        return false;
                    }
                }
                //判断暂存是否有多条
                TracingUnifiedIsBool("IsProcessTempExist", this.orderId, this.processId, this.subjectId).then(resu => {
                    var IsExist = resu.data;
                    if (!IsExist && is_temp == 'tepm') {
                        this.$message({ message: this.$t('temp error'), type: "error" });
                        return false;
                    }
                    else {
                        if (is_temp == 'tepm') {
                            this.saveDate(s_state);
                        }
                        else {
                            this.fApi.validate((valid) => {
                                if (valid) {
                                    this.saveDate(s_state);
                                } else {
                                    return false;
                                }
                            });
                        }
                    }
                }).catch((erro) => { console.log(erro) });
            },
            saveDate(e) {
                let headrow = {
                    id: this.subjectId,
                    corporationId: '',
                    departmentId: '',
                    status: e,
                    createTime: '2021-05-01T08:00',
                    createUserid: '',
                    sectionProcessId: this.processId,
                    orderId: this.orderId,
                    updateTime: '2021-05-01T08:00',
                    updateUserid: '',
                    orderSource: this.isPO
                };
                if (this.subjectId != '') {
                    updateSubject(headrow).then(() => {
                        //如果是确认使用新配置，删除之前得保存得所有数据
                        //删除字段
                        delFieldInfo(this.subjectId).then(() => {
                            for (var i = 0; i < this.fApi.rule.length; i++) {
                                if (this.fApi.rule[i].value.toString() != "") {
                                    let paramrow = {
                                        id: '',
                                        subjectId: this.subjectId,
                                        sectionProcessId: this.processId,
                                        orderId: this.orderId,
                                        SupportFieldCode: this.fApi.rule[i].prop,
                                        corporationId: '',
                                        value: this.fApi.rule[i].value.toString(),
                                    };
                                    addFieldValue(paramrow).then(() => { }).catch((erro) => { console.log(erro) });
                                }
                            }
                        });
                        this.updatefile();//绑定流程项
                        this.$message({
                            message: this.$t('OperationSuccess'),
                            type: "success"
                        });
                    }).catch((erro) => { console.log(erro) });
                }
                if (this.subjectId == '') {
                    addSubject(headrow).then(res => {
                        this.subjectId = res.data.result
                        for (var i = 0; i < this.fApi.rule.length; i++) {
                            if (this.fApi.rule[i].value.toString() != "") {
                                let paramrow = {
                                    id: '',
                                    subjectId: this.subjectId,
                                    sectionProcessId: this.processId,
                                    orderId: this.orderId,
                                    SupportFieldCode: this.fApi.rule[i].prop,
                                    corporationId: '',
                                    value: this.fApi.rule[i].value.toString(),
                                };
                                addFieldValue(paramrow).then(() => { }).catch((erro) => { console.log(erro) });
                            }
                        }
                        this.updatefile();//绑定流程项
                        this.$message({
                            message: this.$t('OperationSuccess'),
                            type: "success"
                        });
                    }).catch((erro) => { console.log(erro) });
                }
            },
            //返回上一页
            revert() {
                if (this.isPO) {
                    this.$router.push({ path: '/pvh_factory/purchaseOrd' });
                }
                else {
                    this.$router.push({ path: '/pvh_factory/salesOrd' });
                }

            },
            // 选择的文件超出限制的文件总数量时触发
            limitCheck() {
                this.$message.warning(this.$t('OnlyOneUploaded'))
            },
            uploadClick(item) {
                this.currItem = item;
            },
            beforeUpload(file) {
                var currtypelist = this.currItem.type.split("/");
                var fileType = file.name.split('.').pop().toLowerCase();
                const istype = currtypelist.indexOf(fileType) !== -1;
                if (!istype) {
                    setTimeout(() => {
                        this.$message.error(this.$t('UploadFailed') + ":[" + file.name + "]," + this.$t('UploadRestrictionsA') + this.currItem.type + this.$t('UploadRestrictionsD'));
                    }, 1000);
                }
                // 文件大小限制为10M
                const fileLimit = file.size / 1024 / 1024 > 10;
                if (fileLimit) {
                    setTimeout(() => {
                        this.$message.error(this.$t('UploadFailed') + ":[" + file.name + "]," + this.$t('UploadRestrictionsC'));
                    }, 1000);
                }
                if (!((!fileLimit) && istype))
                {
                    var formName = this.currItem.name;
                    if (this.$refs[formName] !== undefined) {
                        if (this.$refs[formName][0] !== undefined) {
                            if (this.$refs[formName][0].uploadFiles != null)
                            {
                                let num = 0;
                                this.$refs[formName][0].uploadFiles.map(item => 
                                {
                                    if (file.uid == item.uid)
                                    {
                                        this.$refs[formName][0].uploadFiles.splice(num,1);
                                    }
                                    num++;
                                });
                            }
                        }
                    }
                }
                return (fileLimit) && istype;
            },
            handleRemove(file) {
                if (this.isLocked) {
                    this.$message({ message: this.$t('cantdelete'), type: "error" });
                    return false;
                }
                if (file.status == "success") {
                    delfile(file.uid).then(() => {
                        //待删除
                        this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                    });
                }
            },
            beforeRemove(file) {
                if (this.isLocked)
                {
                    this.$message({ message: this.$t('cantdelete'), type: "error" });
                    return false;
                }
                return this.$confirm(this.$t('IsDel') + file.name, this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                })
            },
            handlePreview(file) {
                var downpath = pvhApiBase + "Tracing/GetFilePathStream?id=" + file.uid;
                this.downloadPDF(downpath, file.name );
            },
            downloadPDF(url, fileName) {
                fetch(url).then(function (response) {
                    if (response.ok) {
                        return response.arrayBuffer()
                    }
                    throw new Error('Network response was not ok.')
                }).then(function (arraybuffer) {
                    let blob = new Blob([arraybuffer])

                    let objectURL = URL.createObjectURL(blob)

                    let downEle = document.createElement('a')
                    let fname = fileName // 下载文件的名字
                    // let fname = `download` // 下载文件的名字
                    downEle.href = objectURL
                    downEle.setAttribute('download', fname)
                    document.body.appendChild(downEle)
                    downEle.click()
                }).catch(function (error) {
                    console.log('There has been a problem with your fetch operation: ', error.message)
                })
            },
            updatefile() {
                var num_t=0
                for (var i = 0; i < this.formAt.length; i++) {
                    var formName = this.formAt[i].name;
                    num_t++;
                    if (this.$refs[formName][0].uploadFiles != null) {
                        this.$refs[formName][0].uploadFiles.forEach(file => {
                            if (file.status == "ready") {
                                let formData = new FormData(); //  用FormData存放上传文件
                                formData.append("file", file.raw); //文件
                                formData.append("orderId", this.orderId);
                                formData.append("processId", this.processId);
                                formData.append("subjectId", this.subjectId);
                                formData.append("brandBusinessId", this.brandBusinessId);
                                formData.append("fileCode", this.formAt[i].prop);
                                axios.post(this.targetAction, formData, {
                                    headers: { "Content-Type": "multipart/form-data" } //设置请求头请求格式为JSON
                                     })
                                    .then(() => {
                                        if (i == this.formAt.length)
                                        {
                                            setTimeout(() => {
                                                this.getProList();
                                            }, 2000 * Math.random());
                                        }
                                    })
                                    .catch(err => {
                                        console.log(err);
                                    })
                            }
                        });
                    }
                    this.$refs[formName][0].clearFiles();
                }
                console.log("3232", num_t, this.formAt.length);
                if (this.formAt.length == num_t)
                {
                    this.getProList();
                }

            },
            delSubject(row) {
                this.$confirm(this.$t('IsDel') , this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                    delSubjectInfo(row.Id).then(() => {
                        this.getProList();
                        this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                    });
                });
            },//恢复数据
            clearInfo() {
                for (var i = 0; i < this.formAt.length; i++) {
                    var formName = this.formAt[i].name;
                    if (this.$refs[formName] !== undefined) {
                        if (this.$refs[formName][0] !== undefined) {
                            if (this.$refs[formName][0].uploadFiles != null) {
                                this.$refs[formName][0].clearFiles();
                            }
                        }
                    }
                }
            },
            onPrint() {
                this.$print(this.$refs.qrCodeUrl)
            },
        },

    }
</script>