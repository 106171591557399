var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          [
            _c(
              "div",
              [
                _c(
                  "el-form",
                  {
                    ref: "ruleform",
                    staticClass: "demo-ruleForm",
                    attrs: {
                      rules: _vm.rules,
                      model: _vm.formData,
                      "label-position": "right",
                      "show-message": _vm.notip
                    }
                  },
                  [
                    _c("div", { staticClass: "layTop" }, [
                      _c(
                        "div",
                        {
                          staticClass: "Title",
                          staticStyle: { "margin-bottom": "10px" }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("MATERIAL ORDER FOR PRODUCT")) +
                              "\n                            "
                          ),
                          _c("el-button", {
                            attrs: {
                              icon: "el-icon-edit-outline",
                              type: "text",
                              size: "medium",
                              title: _vm.$t("EditSOinformation")
                            },
                            on: {
                              click: function($event) {
                                return _vm.editSOinfo()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "topInfo clearfix" },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "margin-bottom": "20px"
                                },
                                attrs: { sm: 24, md: 12, lg: 8 }
                              },
                              [
                                _c("span", [
                                  _c("i", { staticClass: "el-icon-s-custom" }),
                                  _vm._v(
                                    _vm._s(_vm.$t("FROM")) +
                                      " : " +
                                      _vm._s(_vm.formData.fromVal) +
                                      "/" +
                                      _vm._s(_vm.formData.departVal)
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "margin-bottom": "20px"
                                },
                                attrs: { sm: 24, md: 12, lg: 8 }
                              },
                              [
                                _c("span", { staticClass: "spanRight" }, [
                                  _c("i", {
                                    staticClass: "iconfont icon-rili"
                                  }),
                                  _vm._v(
                                    _vm._s(_vm.$t("At")) +
                                      " : " +
                                      _vm._s(_vm.formData.odateVal)
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "margin-bottom": "20px"
                                },
                                attrs: { sm: 24, md: 12, lg: 4 }
                              },
                              [
                                _c("span", { staticClass: "spanRight" }, [
                                  _c("i", { staticClass: "el-icon-brush" }),
                                  _vm._v(
                                    _vm._s(_vm.$t("COLOR")) +
                                      " : " +
                                      _vm._s(_vm.formData.colorVal)
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "margin-bottom": "20px"
                                },
                                attrs: { sm: 24, md: 12, lg: 4 }
                              },
                              [
                                _c("span", { staticClass: "spanRight" }, [
                                  _c("i", {
                                    staticClass: "el-icon-shopping-cart-full"
                                  }),
                                  _vm._v(
                                    _vm._s(_vm.$t("Number")) +
                                      " : " +
                                      _vm._s(_vm.formData.numberVal) +
                                      "/" +
                                      _vm._s(_vm.formData.unitVal)
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "margin-bottom": "20px"
                                },
                                attrs: { sm: 24, md: 12, lg: 8 }
                              },
                              [
                                _c("span", { staticClass: "spanRight" }, [
                                  _c("i", { staticClass: "el-icon-price-tag" }),
                                  _vm._v(
                                    _vm._s(_vm.$t("POProductCode")) +
                                      " : " +
                                      _vm._s(_vm.formData.codeVal)
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "margin-bottom": "20px"
                                },
                                attrs: { sm: 24, md: 12, lg: 8 }
                              },
                              [
                                _c("span", { staticClass: "spanRight" }, [
                                  _c("i", { staticClass: "el-icon-coin" }),
                                  _vm._v(
                                    _vm._s(_vm.$t("POProductName")) +
                                      " : " +
                                      _vm._s(_vm.formData.outProductVal)
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "margin-bottom": "20px"
                                },
                                attrs: { sm: 24, md: 12, lg: 8 }
                              },
                              [
                                _c("span", { staticClass: "spanRight" }, [
                                  _c("i", { staticClass: "el-icon-attract" }),
                                  _vm._v(
                                    _vm._s(_vm.$t("POBATCH")) +
                                      " : " +
                                      _vm._s(_vm.formData.batchVal)
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "margin-bottom": "20px"
                                },
                                attrs: { sm: 24, md: 12, lg: 8 }
                              },
                              [
                                _c("span", { staticClass: "spanRight" }, [
                                  _c("i", { staticClass: "el-icon-document" }),
                                  _vm._v(
                                    _vm._s(_vm.$t("SO")) +
                                      " : " +
                                      _vm._s(_vm.formData.soVal)
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "margin-bottom": "20px"
                                },
                                attrs: { sm: 24, md: 12, lg: 8 }
                              },
                              [
                                _c("span", { staticClass: "spanRight" }, [
                                  _c("i", { staticClass: "el-icon-coin" }),
                                  _vm._v(
                                    _vm._s(_vm.$t("InternalProductName")) +
                                      " : " +
                                      _vm._s(_vm.formData.productNameVal)
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "margin-bottom": "20px"
                                },
                                attrs: { sm: 24, md: 12, lg: 4 }
                              },
                              [
                                _c("span", { staticClass: "spanRight" }, [
                                  _c("i", {
                                    staticClass: "el-icon-map-location"
                                  }),
                                  _vm._v(
                                    _vm._s(_vm.$t("MARKET")) +
                                      " : " +
                                      _vm._s(
                                        _vm.formData.destinationCountryNameVal
                                      )
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "margin-bottom": "20px"
                                },
                                attrs: { sm: 24, md: 12, lg: 4 }
                              },
                              [
                                _c("span", { staticClass: "spanRight" }, [
                                  _c("i", {
                                    staticClass: "el-icon-chat-line-square"
                                  }),
                                  _vm._v(
                                    _vm._s(_vm.$t("REMARK")) +
                                      " : " +
                                      _vm._s(_vm.formData.remarkVal)
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "margin-bottom": "20px"
                                },
                                attrs: { sm: 24, md: 12, lg: 8 }
                              },
                              [
                                _c("span", { staticClass: "spanRight" }, [
                                  _c("i", { staticClass: "el-icon-sort" }),
                                  _vm._v(
                                    _vm._s(_vm.$t("CONTRACT SIGNING COMPANY")) +
                                      " : " +
                                      _vm._s(_vm.formData.affCollNameVal)
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "margin-bottom": "20px"
                                },
                                attrs: { sm: 24, md: 12, lg: 8 }
                              },
                              [
                                _c("span", { staticClass: "spanRight" }, [
                                  _c("i", { staticClass: "el-icon-s-ticket" }),
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("ASSOCIATED PROCESSING PLANT")
                                    ) +
                                      " : " +
                                      _vm._s(_vm.formData.affWorkNameVal)
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "margin-bottom": "20px"
                                },
                                attrs: { sm: 24, md: 12, lg: 8 }
                              },
                              [
                                _c("span", { staticClass: "spanRight" }, [
                                  _c("i", { staticClass: "el-icon-key" }),
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("BUSINESS SERIAL NUMBER EXTERNAL")
                                    ) +
                                      " : " +
                                      _vm._s(_vm.formData.businessNoOutVal)
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("div", { staticClass: "smallTit" }, [
                          _vm._v(_vm._s(_vm.$t("Material Information")))
                        ]),
                        [
                          _c(
                            "el-table",
                            {
                              attrs: {
                                data: _vm.formData.tableData,
                                "row-class-name": _vm.tableRowClassName,
                                "header-cell-class-name": _vm.starAdd
                              },
                              on: { "current-change": _vm.handleCurrentChange }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("Index"),
                                  type: "index",
                                  width: "50px"
                                }
                              }),
                              _vm.formData.show
                                ? _c("el-table-column", [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.scope.row.Id))
                                    ]),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.scope.row.PoDetailId))
                                    ]),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.scope.row.PoId))
                                    ])
                                  ])
                                : _vm._e(),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("ProductCode"),
                                  align: "center"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        !scope.row.isEgdit
                                          ? _c("span", [
                                              _vm._v(_vm._s(scope.row.Code))
                                            ])
                                          : _vm._e(),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "tableData." +
                                                scope.$index +
                                                ".Code",
                                              rules: _vm.rules.Code
                                            }
                                          },
                                          [
                                            scope.row.isEgdit
                                              ? _c(
                                                  "el-autocomplete",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        scope.row.isCanKey,
                                                      clearable: "true",
                                                      "fetch-suggestions":
                                                        _vm.querySearchCodeAsync
                                                    },
                                                    on: {
                                                      select: _vm.handleSelect,
                                                      change: _vm.handleChange
                                                    },
                                                    model: {
                                                      value: scope.row.Code,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "Code",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.Code"
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-zoom-in",
                                                      attrs: { slot: "suffix" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.selectCode(
                                                            scope.$index,
                                                            scope.row
                                                          )
                                                        }
                                                      },
                                                      slot: "suffix"
                                                    })
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("Product Name"),
                                  align: "center"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        !scope.row.isEgdit
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.ProductName)
                                              )
                                            ])
                                          : _vm._e(),
                                        _c("el-form-item", [
                                          scope.row.isEgdit
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row.ProductName)
                                                )
                                              ])
                                            : _vm._e()
                                        ])
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("BATCH"),
                                  align: "center"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        !scope.row.isEgdit
                                          ? _c("span", [
                                              _vm._v(_vm._s(scope.row.Batch))
                                            ])
                                          : _vm._e(),
                                        _c("el-form-item", [
                                          scope.row.isEgdit
                                            ? _c("span", [
                                                _vm._v(_vm._s(scope.row.Batch))
                                              ])
                                            : _vm._e()
                                        ])
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("COLOR"),
                                  align: "center"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        !scope.row.isEgdit
                                          ? _c("span", [
                                              _vm._v(_vm._s(scope.row.Color))
                                            ])
                                          : _vm._e(),
                                        _c("el-form-item", [
                                          scope.row.isEgdit
                                            ? _c("span", [
                                                _vm._v(_vm._s(scope.row.Color))
                                              ])
                                            : _vm._e()
                                        ])
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _c("el-table-column", {
                                attrs: { label: _vm.$t("At"), align: "center" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        !scope.row.isEgdit
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.OrderDate)
                                              )
                                            ])
                                          : _vm._e(),
                                        _c("el-form-item", [
                                          scope.row.isEgdit
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row.OrderDate)
                                                )
                                              ])
                                            : _vm._e()
                                        ])
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("OrderNumber"),
                                  align: "center"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        !scope.row.isEgdit
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.OrderNumber)
                                              )
                                            ])
                                          : _vm._e(),
                                        _c("el-form-item", [
                                          scope.row.isEgdit
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row.OrderNumber)
                                                )
                                              ])
                                            : _vm._e()
                                        ])
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("CanUserNumber"),
                                  align: "center"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        !scope.row.isEgdit
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.SurplusNumber)
                                              )
                                            ])
                                          : _vm._e(),
                                        _c("el-form-item", [
                                          scope.row.isEgdit
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.SurplusNumber
                                                  )
                                                )
                                              ])
                                            : _vm._e()
                                        ])
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("UseNumberPO"),
                                  align: "center"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        !scope.row.isEgdit
                                          ? _c("span", [
                                              _vm._v(_vm._s(scope.row.Number))
                                            ])
                                          : _vm._e(),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "tableData." +
                                                scope.$index +
                                                ".Number",
                                              rules: _vm.rules.Number
                                            }
                                          },
                                          [
                                            scope.row.isEgdit
                                              ? _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "calc(100% - 10px)"
                                                  },
                                                  attrs: {
                                                    controls: false,
                                                    clearable: "",
                                                    min: 0
                                                  },
                                                  model: {
                                                    value: scope.row.Number,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "Number",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.Number"
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("LOSSRATE"),
                                  align: "center"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        !scope.row.isEgdit
                                          ? _c("span", [
                                              _vm._v(_vm._s(scope.row.LossRate))
                                            ])
                                          : _vm._e(),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "tableData." +
                                                scope.$index +
                                                ".LossRate",
                                              rules: _vm.rules.LossRate
                                            }
                                          },
                                          [
                                            scope.row.isEgdit
                                              ? _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "calc(100% - 10px)"
                                                  },
                                                  attrs: {
                                                    controls: false,
                                                    clearable: "",
                                                    min: 0
                                                  },
                                                  model: {
                                                    value: scope.row.LossRate,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "LossRate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.LossRate"
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ])
                              }),
                              !_vm.isLocked
                                ? _c("el-table-column", {
                                    attrs: {
                                      label: _vm.$t("OPERATION"),
                                      align: "center"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c("el-button", {
                                                attrs: {
                                                  title: _vm.$t("Delete"),
                                                  type: "danger",
                                                  size: "small",
                                                  icon: "el-icon-delete",
                                                  circle: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteItem(
                                                      scope.$index,
                                                      scope.row
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3242673147
                                    )
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "right",
                                padding: "50px 0"
                              }
                            },
                            [
                              !_vm.isLocked
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.submitForm }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Save")))]
                                  )
                                : _vm._e(),
                              _c(
                                "el-button",
                                { on: { click: _vm.resetForm } },
                                [_vm._v(_vm._s(_vm.$t("CANCEL")))]
                              )
                            ],
                            1
                          )
                        ]
                      ],
                      2
                    ),
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          title: _vm.$t("EditSOinformation"),
                          visible: _vm.SOdialogTableVisible,
                          "modal-append-to-body": false,
                          width: "50%",
                          top: "1vh"
                        },
                        on: {
                          "update:visible": function($event) {
                            _vm.SOdialogTableVisible = $event
                          }
                        }
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12, sm: 24, md: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: _vm.$t("SO") } },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 5px)"
                                      },
                                      attrs: { clearable: "true" },
                                      model: {
                                        value: _vm.formData.soVal,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formData, "soVal", $$v)
                                        },
                                        expression: "formData.soVal"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12, sm: 24, md: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "BUSINESS SERIAL NUMBER EXTERNAL"
                                      ),
                                      prop: "businessNoOutVal"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 5px)"
                                      },
                                      attrs: { clearable: "true" },
                                      model: {
                                        value: _vm.formData.businessNoOutVal,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "businessNoOutVal",
                                            $$v
                                          )
                                        },
                                        expression: "formData.businessNoOutVal"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12, sm: 24, md: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: _vm.$t("Product Name") } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: {
                                          width: "calc(100% - 5px)"
                                        },
                                        attrs: {
                                          filterable: "",
                                          "allow-create": "",
                                          "default-first-option": "",
                                          placeholder: "",
                                          clearable: "true"
                                        },
                                        on: { change: _vm.selectChanged },
                                        model: {
                                          value: _vm.formData.productCodeVal,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "productCodeVal",
                                              $$v
                                            )
                                          },
                                          expression: "formData.productCodeVal"
                                        }
                                      },
                                      _vm._l(_vm.productData, function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12, sm: 24, md: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: _vm.$t("REMARK") } },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 5px)"
                                      },
                                      attrs: { clearable: "true" },
                                      model: {
                                        value: _vm.formData.remarkVal,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "remarkVal",
                                            $$v
                                          )
                                        },
                                        expression: "formData.remarkVal"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12, sm: 24, md: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("CONTRACT SIGNING COMPANY")
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: {
                                          width: "calc(100% - 5px)"
                                        },
                                        attrs: {
                                          filterable: "",
                                          placeholder: "",
                                          clearable: "true"
                                        },
                                        on: {
                                          change: _vm.selectAffCollChanged
                                        },
                                        model: {
                                          value:
                                            _vm.formData.affCorpCollectionVal,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "affCorpCollectionVal",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.affCorpCollectionVal"
                                        }
                                      },
                                      _vm._l(
                                        _vm.affCorpCollectionData,
                                        function(item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value
                                            }
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12, sm: 24, md: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "ASSOCIATED PROCESSING PLANT"
                                      )
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: {
                                          width: "calc(100% - 5px)"
                                        },
                                        attrs: {
                                          filterable: "",
                                          placeholder: "",
                                          clearable: "true"
                                        },
                                        on: {
                                          change: _vm.selectAffWorkChanged
                                        },
                                        model: {
                                          value: _vm.formData.affCorpWorkingVal,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "affCorpWorkingVal",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.affCorpWorkingVal"
                                        }
                                      },
                                      _vm._l(_vm.affCorpWorkingData, function(
                                        item
                                      ) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { "text-align": "center" } },
                          [
                            !_vm.isLocked
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.submittopInfoSO()
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Yes")))]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.SOdialogTableVisible = false
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Cancel")))]
                            )
                          ],
                          1
                        ),
                        _c("el-form-item")
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: _vm.$t("selectPO"),
                      visible: _vm.dialogTableVisible,
                      "modal-append-to-body": false,
                      width: "90%",
                      top: "2vh"
                    },
                    on: {
                      "update:visible": function($event) {
                        _vm.dialogTableVisible = $event
                      }
                    }
                  },
                  [
                    _c(
                      "avue-crud",
                      {
                        ref: "crud",
                        attrs: {
                          option: _vm.tableOpt,
                          "row-style": _vm.rowStyle,
                          "row-key": _vm.GetRowKeys,
                          data: _vm.potable,
                          page: _vm.page
                        },
                        on: {
                          "update:page": function($event) {
                            _vm.page = $event
                          },
                          "selection-change": _vm.selectionChange,
                          "current-change": _vm.currentChange
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "expand",
                            fn: function(props) {
                              return [
                                _c(
                                  "el-table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: props.row.Details,
                                      "header-cell-style": _vm.tableHeaderColor
                                    }
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: { type: "index" }
                                    }),
                                    _c("el-table-column", {
                                      attrs: { prop: "So", label: _vm.$t("SO") }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "PurchaseCorporationName",
                                        label: _vm.$t("FROM")
                                      }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "Code",
                                        label: _vm.$t("POProductCode")
                                      }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "MGName",
                                        label: _vm.$t("POProductName")
                                      }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "Batch",
                                        label: _vm.$t("POBATCH")
                                      }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "ProductName",
                                        label: _vm.$t("InternalProductName")
                                      }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "Number",
                                        label: _vm.$t("Number")
                                      }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "UnitName",
                                        label: _vm.$t("UNIT")
                                      }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "Color",
                                        label: _vm.$t("COLOR")
                                      }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "Ingredient",
                                        label: _vm.$t("Ingredient")
                                      }
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "UsedNumber",
                                        label: _vm.$t("UseNumberPO")
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c(
                          "template",
                          {
                            staticStyle: { width: "calc(100% - 80px)" },
                            slot: "menuLeft"
                          },
                          [
                            _c(
                              "el-row",
                              {
                                staticClass: "topFromSearch",
                                attrs: { gutter: 20 }
                              },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "fromOutDiv",
                                    attrs: {
                                      xs: 24,
                                      sm: 16,
                                      md: 16,
                                      lg: 19,
                                      xl: 20
                                    }
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "formDiv",
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 6,
                                          xl: 6
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("ProductCode")) + ":"
                                          )
                                        ]),
                                        _c("el-input", {
                                          on: {
                                            focus: function($event) {
                                              return _vm.focusDiv($event)
                                            },
                                            blur: function($event) {
                                              return _vm.blurDiv($event)
                                            }
                                          },
                                          model: {
                                            value: _vm.codeVal,
                                            callback: function($$v) {
                                              _vm.codeVal = $$v
                                            },
                                            expression: "codeVal"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "formDiv",
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 6,
                                          xl: 6
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.$t("BATCH")) + ":")
                                        ]),
                                        _c("el-input", {
                                          on: {
                                            focus: function($event) {
                                              return _vm.focusDiv($event)
                                            },
                                            blur: function($event) {
                                              return _vm.blurDiv($event)
                                            }
                                          },
                                          model: {
                                            value: _vm.batchVal,
                                            callback: function($$v) {
                                              _vm.batchVal = $$v
                                            },
                                            expression: "batchVal"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "formDiv",
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 6,
                                          xl: 6
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.$t("COLOR")) + ":")
                                        ]),
                                        _c("el-input", {
                                          on: {
                                            focus: function($event) {
                                              return _vm.focusDiv($event)
                                            },
                                            blur: function($event) {
                                              return _vm.blurDiv($event)
                                            }
                                          },
                                          model: {
                                            value: _vm.colorVal,
                                            callback: function($$v) {
                                              _vm.colorVal = $$v
                                            },
                                            expression: "colorVal"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "formDiv",
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 6,
                                          xl: 6
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { attrs: { id: "venSpan" } },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("VENDOR")) + ":"
                                            )
                                          ]
                                        ),
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              multiple: "",
                                              "collapse-tags": "",
                                              placeholder: "",
                                              clearable: "true"
                                            },
                                            on: {
                                              focus: function($event) {
                                                return _vm.focusDiv($event)
                                              },
                                              blur: function($event) {
                                                return _vm.blurDiv($event)
                                              },
                                              change: _vm.venchang
                                            },
                                            model: {
                                              value: _vm.vendorVal.ids,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.vendorVal,
                                                  "ids",
                                                  $$v
                                                )
                                              },
                                              expression: "vendorVal.ids"
                                            }
                                          },
                                          _vm._l(this.vendorData, function(
                                            item
                                          ) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "formDiv",
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 6,
                                          xl: 6
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("BEINGTIME")) + ":"
                                          )
                                        ]),
                                        _c("el-date-picker", {
                                          staticClass: "dataicon",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            type: "date",
                                            format: "yyyy-MM-dd",
                                            "value-format": "yyyy-MM-dd",
                                            "prefix-icon": ""
                                          },
                                          on: {
                                            focus: function($event) {
                                              return _vm.focusDiv($event)
                                            },
                                            blur: function($event) {
                                              return _vm.blurDiv($event)
                                            }
                                          },
                                          model: {
                                            value: _vm.beginTimeVal,
                                            callback: function($$v) {
                                              _vm.beginTimeVal = $$v
                                            },
                                            expression: "beginTimeVal"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "formDiv",
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 6,
                                          xl: 6
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("ENDTIME")) + ":"
                                          )
                                        ]),
                                        _c("el-date-picker", {
                                          staticClass: "dataicon",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            type: "date",
                                            format: "yyyy-MM-dd",
                                            "value-format": "yyyy-MM-dd",
                                            "prefix-icon": ""
                                          },
                                          on: {
                                            focus: function($event) {
                                              return _vm.focusDiv($event)
                                            },
                                            blur: function($event) {
                                              return _vm.blurDiv($event)
                                            }
                                          },
                                          model: {
                                            value: _vm.endTimeVal,
                                            callback: function($$v) {
                                              _vm.endTimeVal = $$v
                                            },
                                            expression: "endTimeVal"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "padding-top": "10px" },
                                    attrs: {
                                      xs: 24,
                                      sm: 8,
                                      md: 8,
                                      lg: 5,
                                      xl: 4
                                    }
                                  },
                                  [
                                    _c("el-button", {
                                      staticClass: "moreBtn",
                                      staticStyle: { "margin-right": "0" },
                                      attrs: {
                                        size: "small",
                                        icon: "iconfont icon-24px",
                                        circle: ""
                                      },
                                      on: { click: _vm.moreFrom }
                                    }),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                          plain: ""
                                        },
                                        on: { click: _vm.searchFrom }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("SEARCH")))]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "template",
                          { slot: "header" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.submitSelectData()
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Yes")))]
                            )
                          ],
                          1
                        ),
                        _c("template", { slot: "tip" })
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }