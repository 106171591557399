
<template>
    <div>
        <el-row>
            <el-col :sm="24" :md="12" :lg="12" style="display:flex;margin-bottom:20px;">
                <el-button @click="timeStart()">Start</el-button>
                <el-button @click="stop">stop</el-button>
                <el-button @click="reset">reset</el-button>
                <el-button @click="timeStart">timeStart</el-button>
                <el-button @click="cellMap">cellMap</el-button>
                <el-button @click="getStart">getStart</el-button>
            </el-col>
            <el-col :sm="24" :md="12" :lg="12" style="display:flex;margin-bottom:20px;">
                <span class="spanRight"><i class="el-icon-brush"></i> {{str}}</span>
            </el-col>
        </el-row>
        <el-row>
            <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
                <div id="all">
                    <div id="allmap"></div>
                </div>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8" style="display:flex;margin-bottom:20px;">
            </el-col>
        </el-row>
        <el-row>
            <el-col :sm="24" :md="24" :lg="24" style="display:flex;margin-bottom:20px;">
                <el-input type="textarea"
                          :autosize="{ minRows: 2, maxRows: 50}"
                          placeholder=""
                          v-model="textarea2" style="height:800px;width:1600px;">
                </el-input>
            </el-col>

        </el-row>
    </div>
</template>
<style>
    #allmap {
        width: 800px;
        height: 400px;
        font-family: "΢���ź�";
        border: 1px solid green;
    }
</style>
<script>
    export default {
        data() {
            return {
                textarea2: '',
                textarea1: '33',
                Addadddata: [],
                hour: 0,//����ʱ���֣��룬���벢��ʼ��Ϊ0��
                minute: 0,
                ms: 0,
                second: 0,//��
                time: '',
                str: '00:00:00',
                zb:[],
            };
        },
        created() {
        },//����
        methods: {
            //��ʼ��ʱ
            timeStart() {
                this.time = setInterval(this.timer, 50)
            },
            //
            timer() {//�����ʱ����
                this.ms = this.ms + 50        //����
                if (this.ms >= 1000) {
                    this.ms = 0
                    this.second = this.second + 1        //��
                }
                if (this.second >= 60) {
                    this.second = 0
                    this.minute = this.minute + 1        //����
                }
                if (this.minute >= 60) {
                    this.minute = 0
                    this.hour = this.hour + 1        //Сʱ
                }
                //
                this.str = this.toDub(this.hour) + ':' + this.toDub(this.minute) + ':' + this.toDub(this.second)/*+""+this.toDubms(this.ms)+"����"*/
                // document.getElementById('mytime').innerHTML=h+"ʱ"+m+"��"+s+"��"+ms+"����";
            },
            //�������
            toDub(n) {//��0����
                if (n < 10) {
                    return '0' + n
                } else {
                    return '' + n
                }
            },
            //��ͣ
            stop() {
                clearInterval(this.time)
            },
            //����
            reset() {//����
                clearInterval(this.time)
                // this.hour =0
                this.minute = 0
                this.ms = 0
                this.second = 0
                this.str = '00:00:00'
            },
            getStart() {
                this.Addadddata.forEach(v => {
                    this.textarea2 += "\r\n" + v.guid + "|" + v.id + "|" + v.pid + "|" + v.path + "|" + v.name + "|" + v.name_en + "|" + v.name_pinyin + "|" + v.code + "|" + v.lat + "|" + v.lng + "|" + v.all_name;
                })
            },
            cellMap() {
                this.$nextTick(function () {
                    this.initMapinfo()
                })

            },
            initMapinfo() {
                let map;
                let marker;
                let geocoder;
                let responseDiv;
                let response;
                    map = new google.maps.Map(document.getElementById("allmap"), {
                        zoom: 8,
                        center: { lat: -34.397, lng: 150.644 },
                        mapTypeControl: false,
                    });
                    geocoder = new google.maps.Geocoder();

                    const inputText = document.createElement("input");

                    inputText.type = "text";
                    inputText.placeholder = "Enter a location";

                    const submitButton = document.createElement("input");

                    submitButton.type = "button";
                    submitButton.value = "Geocode";
                    submitButton.classList.add("button", "button-primary");

                    const clearButton = document.createElement("input");

                    clearButton.type = "button";
                    clearButton.value = "Clear";
                    clearButton.classList.add("button", "button-secondary");
                    response = document.createElement("pre");
                    response.id = "response";
                    response.innerText = "";
                    responseDiv = document.createElement("div");
                    responseDiv.id = "response-container";
                    responseDiv.appendChild(response);

                    const instructionsElement = document.createElement("p");

                    instructionsElement.id = "instructions";
                    instructionsElement.innerHTML =
                        "<strong>Instructions</strong>: Enter an address in the textbox to geocode or click on the map to reverse geocode.";
                    map.controls[google.maps.ControlPosition.TOP_LEFT].push(inputText);
                    map.controls[google.maps.ControlPosition.TOP_LEFT].push(submitButton);
                    map.controls[google.maps.ControlPosition.TOP_LEFT].push(clearButton);
                    map.controls[google.maps.ControlPosition.LEFT_TOP].push(instructionsElement);
                    map.controls[google.maps.ControlPosition.LEFT_TOP].push(responseDiv);
                    marker = new google.maps.Marker({
                        map,
                    });
                    map.addListener("click", (e) => {
                        geocode({ location: e.latLng });
                    });
                    submitButton.addEventListener("click", () =>
                        geocode({ address: inputText.value })
                    );
                    clearButton.addEventListener("click", () => {
                        clear();
                    });
                for (var i = 0; i < 15; i++) {
                    geocoder
                        .geocode({ address: this.Addadddata[i].all_name })
                        .then((result) => {
                            const { results } = result;
                            var lats = results[0].geometry.location.lat();
                            var lngs = results[0].geometry.location.lng();
                           // this.textarea2 += "\r\n" + this.Addadddata[i].guid + "|" + this.Addadddata[i].id + "|" + this.Addadddata[i].pid + "|" + this.Addadddata[i].path + "|" + this.Addadddata[i].name + "|" + this.Addadddata[i].name_en + "|" + this.Addadddata[i].name_pinyin + "|" + this.Addadddata[i].code + "|" + lats + "|" + lngs + "|" + this.Addadddata[i].all_name;
                         this.textarea2 += "\r\n" +  lats + "|" + lngs;

                        })
                        .catch((e) => {
                            alert("Geocode was not successful for the following reason: " + e);
                        });
                    this.sleep(800);
                  }
                    clear();
                function clear() {
                    marker.setMap(null);
                    responseDiv.style.display = "none";
                }
                function geocode(request) {
                    clear();
                    geocoder
                        .geocode(request)
                        .then((result) => {
                            const { results } = result;

                            map.setCenter(results[0].geometry.location);
                            marker.setPosition(results[0].geometry.location);
                            marker.setMap(map);
                            responseDiv.style.display = "block";
                            response.innerText = JSON.stringify(result, null, 2);
                            return results;
                        })
                        .catch((e) => {
                            alert("Geocode was not successful for the following reason: " + e);
                        });
                }
            },
             async sleep(n) {
                var start = new Date().getTime();
                while (true) {
                    if (new Date().getTime() - start > n) {
                        break;
                    }
                }
            },
        }


    }
</script>

