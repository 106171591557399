var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "additionToMachine",
      staticStyle: {
        background: "transparent",
        position: "relative",
        top: "70px",
        left: "0"
      },
      attrs: { id: "home_container" }
    },
    [
      _vm.dialogVisible
        ? _c("volumeSet", {
            attrs: { item: _vm.seletItem, modelShow: _vm.dialogVisible },
            on: { closeModel: _vm.closeModel }
          })
        : _vm._e(),
      _vm.showFound
        ? _c("found", {
            attrs: { seletItem: _vm.seletItem, modelShow: _vm.showFound },
            on: {
              setFoundBySelf: _vm.setFoundBySelf,
              setFound: _vm.setFound,
              closeModel: _vm.closeModel
            }
          })
        : _vm._e(),
      _vm.showRepair
        ? _c("repair", {
            attrs: { modelShow: _vm.showRepair },
            on: { closeModel: _vm.closeModel }
          })
        : _vm._e(),
      _vm.showMachine
        ? _c("machine", {
            attrs: { seletItem: _vm.seletItem, modelShow: _vm.showMachine },
            on: { openFound: _vm.openFound, closeModel: _vm.closeModel }
          })
        : _vm._e(),
      _vm.showScene
        ? _c("scene", {
            attrs: { seletItem: _vm.seletItem, modelShow: _vm.showScene },
            on: { closeModel: _vm.closeModel }
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "outFormDiv",
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            padding: "15px",
            position: "fixed",
            top: "100px",
            right: "0",
            width: "calc(100% - 13.75rem)",
            "z-index": "1",
            "box-sizing": "border-box"
          }
        },
        [
          _vm.setData.topIsMenu
            ? _c(
                "div",
                { staticClass: "formList" },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "demo-form-inline",
                      staticStyle: { display: "flex" },
                      attrs: {
                        inline: true,
                        model: _vm.formInline,
                        labelWidth: "80px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "calc(33.33% - 23.33px)",
                            display: "flex"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: "请选择设备机型",
                                clearable: ""
                              },
                              on: { change: _vm.getModelId },
                              model: {
                                value: _vm.formInline.machineModelId,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.formInline,
                                    "machineModelId",
                                    $$v
                                  )
                                },
                                expression: "formInline.machineModelId"
                              }
                            },
                            _vm._l(_vm.equipmentModel, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "calc(33.33% - 23.33px)",
                            display: "flex"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: "请选择产品品种",
                                clearable: ""
                              },
                              on: { change: _vm.getBatchId },
                              model: {
                                value: _vm.formInline.productId,
                                callback: function($$v) {
                                  _vm.$set(_vm.formInline, "productId", $$v)
                                },
                                expression: "formInline.productId"
                              }
                            },
                            _vm._l(_vm.varietiesProducts, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "calc(33.33% - 23.33px)",
                            display: "flex"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: "请选择车间",
                                clearable: ""
                              },
                              model: {
                                value: _vm.formInline.departmentId,
                                callback: function($$v) {
                                  _vm.$set(_vm.formInline, "departmentId", $$v)
                                },
                                expression: "formInline.departmentId"
                              }
                            },
                            _vm._l(_vm.workCenter, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "calc(33.33% - 23.33px)",
                            display: "flex"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: "请选择设备编号",
                                clearable: ""
                              },
                              model: {
                                value: _vm.formInline.machineId,
                                callback: function($$v) {
                                  _vm.$set(_vm.formInline, "machineId", $$v)
                                },
                                expression: "formInline.machineId"
                              }
                            },
                            _vm._l(_vm.equipmentSerialNumber, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "calc(33.33% - 23.33px)",
                            display: "flex"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: "请选择产品批次",
                                clearable: ""
                              },
                              model: {
                                value: _vm.formInline.batchCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.formInline, "batchCode", $$v)
                                },
                                expression: "formInline.batchCode"
                              }
                            },
                            _vm._l(_vm.productBatch, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.batchCode,
                                  value: item.batchCode
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.setData.topIsMenu
            ? _c(
                "div",
                {
                  staticClass: "btn",
                  staticStyle: {
                    width: "240px",
                    "text-align": "right",
                    display: "flex",
                    "justify-content": "flex-end",
                    "align-items": "start"
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "5px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.onSearch }
                    },
                    [_vm._v(_vm._s(_vm.$t("SEARCH")))]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.getAll } },
                    [_vm._v(_vm._s(_vm.$t("Batch set")))]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.setData.topIsCheMenu
            ? _c(
                "div",
                { staticStyle: { width: "100%", "text-align": "right" } },
                [
                  _c(
                    "el-checkbox-button",
                    {
                      staticClass: "allBtn",
                      attrs: {
                        indeterminate: _vm.isIndeterminate,
                        type: "primary"
                      },
                      on: { change: _vm.onAll }
                    },
                    [_vm._v(_vm._s(_vm.setData.allText))]
                  ),
                  _c(
                    "el-badge",
                    {
                      staticClass: "item",
                      staticStyle: { "margin-right": "5px" },
                      attrs: { value: _vm.checkboxList.length }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onChange }
                        },
                        [_vm._v(_vm._s(_vm.$t("Batch set")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.outAll } },
                    [_vm._v(_vm._s(_vm.$t("Exit the batch")))]
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "div",
        {
          staticClass: "content yzgl",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            width: "100%",
            position: "relative",
            padding: "7.5px",
            "box-sizing": "border-box"
          },
          style: _vm.outHei
        },
        [
          _c(
            "draggable",
            {
              staticStyle: {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                "margin-top": "7.5px",
                display: "flex",
                "flex-wrap": "wrap",
                padding: "0 7.5px",
                "box-sizing": "border-box"
              },
              style: _vm.scHei
            },
            [
              _c(
                "el-checkbox-group",
                {
                  staticStyle: {
                    width: "100%",
                    display: "flex",
                    "flex-wrap": "wrap"
                  },
                  model: {
                    value: _vm.checkboxList,
                    callback: function($$v) {
                      _vm.checkboxList = $$v
                    },
                    expression: "checkboxList"
                  }
                },
                _vm._l(_vm.devList, function(item, indexCod) {
                  return _c(
                    "el-checkbox-button",
                    {
                      key: item.id,
                      staticClass: "fontCss",
                      staticStyle: { padding: "0" },
                      style: _vm.itemHeight,
                      attrs: { label: item.id, disabled: _vm.setData.isCheck },
                      on: {
                        change: function($event) {
                          return _vm.checkChange($event, item)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "name",
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            padding: "0 7.5px",
                            height: "50px",
                            "line-height": "50px",
                            "border-radius": "8px 8px 0 0",
                            width: "100%",
                            "box-sizing": "border-box"
                          },
                          style: _vm.topTitle[item.openStatus]
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                position: "relative",
                                "padding-left": "15px"
                              },
                              style: _vm.textStatus[item.openStatus]
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-biaotibiao",
                                style: _vm.iconStatus[item.openStatus]
                              }),
                              _vm._v(
                                _vm._s(item.code) + "\n                        "
                              )
                            ]
                          ),
                          _c("div", [
                            item.openStatus == 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "item_color",
                                    staticStyle: {
                                      color: "#42B983",
                                      "font-size": "14px"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-shengchandan",
                                      staticStyle: {
                                        "vertical-align": "0",
                                        "font-size": "15px !important"
                                      }
                                    }),
                                    _vm._v(
                                      "生产中\n                            "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            item.openStatus == 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "item_color",
                                    staticStyle: {
                                      color: "#fff",
                                      "font-size": "14px"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-guanji",
                                      staticStyle: {
                                        "vertical-align": "0",
                                        "font-size": "15px !important"
                                      }
                                    }),
                                    _vm._v(
                                      "待开台\n                            "
                                    )
                                  ]
                                )
                              : item.faultStatus == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "item_color",
                                    staticStyle: {
                                      color: "#fff",
                                      "font-size": "14px"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-jinggao",
                                      staticStyle: {
                                        "vertical-align": "0",
                                        "font-size": "15px !important"
                                      }
                                    }),
                                    _vm._v(
                                      "待维修\n                            "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "0 7.5px",
                            height: "calc(100% - 128px)",
                            display: "flex",
                            "flex-wrap": "wrap",
                            "align-items": "center",
                            width: "100%",
                            "box-sizing": "border-box",
                            "text-align": "left"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-pinzhong12",
                                staticStyle: {
                                  "vertical-align": "0",
                                  "font-size": "16px !important",
                                  color: "#CAD9EF",
                                  "margin-right": "10px"
                                }
                              }),
                              _vm._v("产品品种："),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    color: "#aaa",
                                    display: "inline-block",
                                    width: "calc(100% - 115px)",
                                    overflow: "hidden",
                                    "text-overflow": "ellipsis",
                                    "white-space": "nowrap"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      item.fact ? item.fact.productName : "-"
                                    )
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-mingxi",
                                staticStyle: {
                                  "vertical-align": "0",
                                  "font-size": "16px !important",
                                  color: "#CAD9EF",
                                  "margin-right": "10px"
                                }
                              }),
                              _vm._v("产品批次："),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    color: "#aaa",
                                    display: "inline-block",
                                    width: "calc(100% - 115px)",
                                    overflow: "hidden",
                                    "text-overflow": "ellipsis",
                                    "white-space": "nowrap"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      item.fact ? item.fact.batchName : "-"
                                    )
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "0 7.5px",
                            width: "100%",
                            height: "50px",
                            "box-sizing": "border-box"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "calc(51% - 23.75px)",
                                marginTop: "20px"
                              }
                            },
                            [
                              item.openStatus == 0
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        background: "#f1fff6",
                                        width: "70%",
                                        border: "1px solid #42B983",
                                        color: "#42B983",
                                        "border-radius": "8px",
                                        padding: "12px 0"
                                      },
                                      attrs: { icon: "iconfont icon-guanji" },
                                      on: {
                                        click: function($event) {
                                          return _vm.changeStatus(3, item)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    确认开台\n                                "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.openStatus == 2
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        background: "#FFF2F2",
                                        width: "70%",
                                        border: "1px solid #F56C6C",
                                        color: "#F56C6C",
                                        "border-radius": "8px",
                                        padding: "12px 0"
                                      },
                                      attrs: { icon: "iconfont icon-manglu" },
                                      on: {
                                        click: function($event) {
                                          return _vm.changeStatus(4, item)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    确认了机\n                                "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    background: "#f5f5f5",
                                    width: "70%",
                                    border: "1px solid #aaa",
                                    color: "#aaa",
                                    "border-radius": "8px",
                                    padding: "12px 0"
                                  },
                                  attrs: { icon: "iconfont icon-guanji" },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeStatus(1, item)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                    现场管理\n                                "
                                  )
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    background: "#fff9ef",
                                    width: "70%",
                                    border: "1px solid #E6A23C",
                                    color: "#E6A23C",
                                    "border-radius": "8px",
                                    padding: "12px 0"
                                  },
                                  attrs: { icon: "iconfont icon-guanji" },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeStatus(2, item)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                    呼叫维修\n                                "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                }),
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "fixed",
                    right: "15px",
                    bottom: "70px"
                  }
                },
                [
                  _vm.isTop
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-bottom": "10px",
                            cursor: "pointer",
                            width: "50px",
                            "border-radius": "50%",
                            height: "50px",
                            background: "#4D8AF0",
                            "text-align": "center",
                            "line-height": "50px"
                          },
                          on: { click: _vm.toTop }
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont icon-zhiding-59",
                            staticStyle: {
                              "font-size": "30px !important",
                              color: "#fff",
                              margin: "0",
                              width: "50px"
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm.isPre
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-bottom": "10px",
                            cursor: "pointer",
                            width: "50px",
                            "border-radius": "50%",
                            height: "50px",
                            background: "#4D8AF0",
                            "text-align": "center",
                            "line-height": "50px"
                          },
                          on: { click: _vm.preList }
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont icon-shang25",
                            staticStyle: {
                              "font-size": "30px !important",
                              color: "#fff",
                              margin: "-7px 0 0",
                              width: "50px"
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm.isNex
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-bottom": "10px",
                            cursor: "pointer",
                            width: "50px",
                            "border-radius": "50%",
                            height: "50px",
                            background: "#4D8AF0",
                            "text-align": "center",
                            "line-height": "50px"
                          },
                          on: { click: _vm.nextList }
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont icon-xia852",
                            staticStyle: {
                              "font-size": "30px !important",
                              color: "#fff",
                              margin: "0",
                              width: "50px"
                            }
                          })
                        ]
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }