import request from '@/router/axios';
import { pvhApiBase } from '@/config/env';


//��ȡ������־�б� 
export const operateLogList = (page, pageSize, module, beginTime, endTime) => {
    return request({
        url: pvhApiBase + "Cashier/OperateLog",
        method: "get",
        params: {
            "module": module,
            "beginTime": beginTime,
            "endTime": endTime,
            "page": page,
            "pageSize": pageSize
        }
    })
}

//��ȡ�ӿ���־�б� 
export const apiLogList = (page, pageSize, module,apiName, beginTime, endTime) => {
    return request({
        url: pvhApiBase + "Cashier/ApiLog",
        method: "get",
        params: {
            "module": module,
            "apiName": apiName,
            "beginTime": beginTime,
            "endTime": endTime,
            "page": page,
            "pageSize": pageSize
        }
    })
}


//��ȡ�����־�б� 
export const auditLogList = (page, pageSize, module,user, beginTime, endTime) => {
    return request({
        url: pvhApiBase + "Cashier/AuditLog",
        method: "get",
        params: {
            "module": module,
            "user": user,
            "beginTime": beginTime,
            "endTime": endTime,
            "page": page,
            "pageSize": pageSize
        }
    })
}



//���ݵ�ǰ��¼�˹�˾��ѯ�û�
export const userList = () => {
    return request({
        url: pvhApiBase + "ProcessManagement/GetCorpUserssByCorpId ",
        method: "get"
    })
}
