<template>
  <div class="volumeSet">
    <el-dialog
        style="marginTop: -60px;"
        :visible.sync="modelShow"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        width="800px">
      <div class="content">
          <el-form :model="form" label-width="120px">
              <el-row>
                  <el-col :span="12">
                      <el-form-item label="机台编号:">
                          <el-input v-model="seletItem.name" disabled></el-input>
                      </el-form-item>
                  </el-col>
              </el-row>
              <el-row>
                  <el-col :span="12">
                      <el-form-item label="现有品种:">
                          <!--                <el-select v-model="form.code" placeholder="请选择">-->
                          <!--                  <el-option-->
                          <!--                      v-for="item in options"-->
                          <!--                      :key="item.value"-->
                          <!--                      :label="item.label"-->
                          <!--                      :value="item.value">-->
                          <!--                  </el-option>-->
                          <!--                </el-select>-->
                          <el-input v-model="seletItem.fact.productName" disabled></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="现品种批次:">
                          <!--                <el-select v-model="form.code" placeholder="请选择">-->
                          <!--                  <el-option-->
                          <!--                      v-for="item in options"-->
                          <!--                      :key="item.value"-->
                          <!--                      :label="item.label"-->
                          <!--                      :value="item.value">-->
                          <!--                  </el-option>-->
                          <!--                </el-select>-->
                          <el-input v-model="seletItem.fact.batchName" disabled></el-input>
                      </el-form-item>
                  </el-col>
              </el-row>
              <el-row>
                  <el-col :span="12">
                      <el-form-item label="计划开机时间:">
                          <el-date-picker v-model="seletItem.fact.factStartTime"
                                          disabled
                                          type="datetime"
                                          placeholder="选择日期时间">
                          </el-date-picker>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="计划了机时间:">
                          <el-date-picker v-model="seletItem.fact.factEndTime"
                                          disabled
                                          type="datetime"
                                          placeholder="选择日期时间">
                          </el-date-picker>

                      </el-form-item>
                  </el-col>
              </el-row>

              <el-row>
                  <el-col :span="12">
                      <el-form-item label="实际开机时间:">
                          <el-date-picker v-model="seletItem.fact.openTime"
                                          disabled
                                          type="datetime"
                                          placeholder="选择日期时间">
                          </el-date-picker>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="实际了机时间:">
                          <el-date-picker v-model="form.closeTime"
                                          value-format="yyyy-MM-dd HH:mm:ss"
                                          type="datetime"
                                          placeholder="选择日期时间">
                          </el-date-picker>
                      </el-form-item>
                  </el-col>
              </el-row>
              <el-row>
                  <el-col :span="12">
                      <el-form-item label="计划产量:">
                          <el-input placeholder="请输入内容" v-model="form.planYield" disabled>
                              <template slot="append">
                                  kg
                              </template>
                          </el-input>
                      </el-form-item>
                  </el-col>
              </el-row>
              <el-row>
                  <el-col :span="12">
                      <el-form-item label="空锭数:">
                          <el-input placeholder="请输入内容" v-model="form.spinEmpty" disabled>
                              <template slot="append">
                                  个
                              </template>
                          </el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="设备停机时间:">
                          <el-input placeholder="请输入内容" v-model="form.num" disabled>
                              <template slot="append">
                                  小时
                              </template>
                          </el-input>
                      </el-form-item>
                  </el-col>
              </el-row>
              <el-row>
                  <el-col :span="12">
                      <el-form-item label="开台产量:">
                          <el-input v-model="seletItem.fact.openYield" disabled>
                              <template slot="append">
                                  kg
                              </template>
                          </el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="了机产量:">
                          <el-input v-model="seletItem.fact.closeYield" disabled>
                              <template slot="append">
                                  kg
                              </template>
                          </el-input>
                      </el-form-item>
                  </el-col>
              </el-row>
              <el-row>
                  <el-col :span="12">
                      <el-form-item label="实际产量:">
                          <el-input placeholder="请输入内容" v-model="form.factYield" @change="erroFi">
                              <template slot="append">
                                  kg
                              </template>
                          </el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="产量误差:">
                          <el-input placeholder="请输入内容" v-model="errorYield" disabled>
                              <span slot="prepend"> + </span>
                              <template slot="append">
                                  %
                              </template>
                          </el-input>
                      </el-form-item>
                  </el-col>
              </el-row>
          </el-form>
      </div>
      <p slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="primary" @click="makeSure">确认了机</el-button>
        <el-button @click="closeModel">取 消</el-button>
      </p>
    </el-dialog>
  </div>
</template>

<script>
import { dateFormat } from '@/filters/'
export default {
  name: "volumeSet",
  props: {
    modelShow: {
      type: Boolean,
      default: false
      },
      seletItem: Object
  },
        data() {
            //console.log(this.errorYield)
            console.log(this.seletItem,"586")
    return {
      form:{
        num:'',
            productId: this.seletItem.fact.productId,
            batchCode: this.seletItem.fact.batchCode,
        machineId:this.seletItem.id,
            spinEmpty: this.seletItem.fact.spinEmpty || 0, // 空定数
            factYield: this.seletItem.fact.factYield || 0, // 实际产量
            planYield: this.seletItem.fact.planYield || 0,//计划产量
        id:this.seletItem.fact.id,
        closeTime: '',//了机时间
      },
      options:[],
        productionObj: {},
        errorYield: this.seletItem.errorYield
    }
  },
        created() {
            console.log(this.seletItem, "123")
            console.log(this.errorYield,'err')
     this.getDownTime()
    //this.getPlanProduction()
    //this.getEmptyNumber()
    //this.getActualProduction()
    console.log( dateFormat(new Date()))
    this.form.closeTime = dateFormat(new Date())
  },
  computed:{
    errorBand(){
      let num = 0
      num = (this.form.factYield / this.productionObj.planYield - 1) * 100
      return num
    }
  },
  methods: {
    closeModel() {
      this.$emit('closeModel', false)
    },
    // 计划产量
    async getPlanProduction(){
      const res = await this.request.get(this.proApis.QUERYPLANBYMACHINEID + "?machineId=" + this.seletItem.id)
      console.log(res,'chanl')
      this.productionObj = res.data.data
    },
    // 实际产量
    async getActualProduction(){
        //this.form.factYield = 25;
      const res = await this.request.get(this.proApis.SPECSHEETMACHINEFACTQUERYFACTYIELD + "?id=" + this.seletItem.fact.id)
        console.log(res, '369')
        this.form.factYield = res.data.data.fact.factYield
        console.log(this.form.factYield)
    },
    // 空定数
    async getEmptyNumber(){
      const res = await this.request.get(this.proApis.SPINEMPTYYIELDQUERYTOTALEMPTY + "?machineId=" + this.seletItem.id + '&openTime=' + this.seletItem.fact.openTime)
      console.log(res,'chanl')
      this.form.spinEmpty = res.data.data
    },
    // 停机时间
    async getDownTime(){
      const res = await this.request.get(this.proApis.SELECTBYMACHINEID + "?machineId=" + this.seletItem.id + '&openTime=' + this.seletItem.fact.openTime)
      console.log(res,'chanl')
      this.form.num = res.data.data
      },
      async erroFi() {
          let plan = 0;
          let fact = 0;
          if (this.seletItem.fact.planYield == null) {
              plan = 0;
          } else {
              plan = this.seletItem.fact.planYield;
          }
          if (this.seletItem.fact.factYield == null) {
              fact = 0;
          } else {
              fact = this.seletItem.fact.factYield;
          }
          this.secDa = {
              planYield: plan,
              factYield: this.form.factYield
          };
          const erroY = await this.request.post(this.proApis.SELECTWUCHACHANLIANG, this.secDa)
          console.log(this.secDa)
          this.errorYield = erroY.data.data.errorYield
      },
      async makeSure() {
          console.log(this.form)
      const res = await this.request.post(this.proApis.CLOSEMACHINE,this.form)
      console.log(res,'lj')
      if(res.data.code !="0000") {
        //this.$message.error(res.data.msg)
      }else {
        //this.$message.success('了机成功')
        this.$confirm('是否开台?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.$emit('openFound', true)
        }).catch(() => {
          this.$emit('closeModel', false)
        })
      }

    },
  }
}
</script>

<style lang="scss" scoped>
.item_name {
  font-weight: 700;
  height: 40px;
  line-height: 40px;
}
.dialog-footer {
  width: 100%;
  text-align: center;
}
</style>