<template>
  <div>
    <basic-container style="height:100%">
      <div style="">
        <div style="marginBottom: 20px;">操作日志</div>
         <el-date-picker clearable
            style="width:250px;height:32px;marginRight: 10px"
            v-model="startTime"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="开始日期">
          </el-date-picker>
          <el-date-picker clearable
            style="width:250px;height:32px;marginRight: 10px"
            v-model="endTime"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="结束日期">
          </el-date-picker>
         <el-input v-model="caozuoModel" :placeholder="$t('NAME OR NUMBER OF PERSONNEL')" style="width:200px;height:30px;marginRight: 10px;" size="medium" clearable></el-input>
         <el-button type="primary" icon="el-icon-search" @click="search">{{$t('SEARCH')}}</el-button>
          <el-table
          :data="tableData"
          border
          style="width: 65%;marginTop:10px">
          <el-table-column
            prop="date"
            label="tokenName"
            width="180">
          </el-table-column>
          <el-table-column
            prop="name"
            label="过期时间"
            width="180">
          </el-table-column>
          <el-table-column
            prop="address"
            label="创建时间"
            width="240">
      </el-table-column>
       <el-table-column label="操作">
      <template slot-scope="scope">
        <el-button
          size="mini"
          @click="handleEdit(scope.$index, scope.row)">移除</el-button>
      </template>
    </el-table-column>
        </el-table>
      </div>
    </basic-container>
    <basic-container style="height： 200px">
      <div style="">
        <span>操作日志</span>
        <div style="marginTop: 20px;display: flex;">时间：2021-10-01 —— 2021-10-30 </div> <div style="display: flex;">模块： </div> <button>搜索</button>
      </div>
    </basic-container>
  </div>
</template>
<script>
export default {
    data() {
      return {
        caozuoModel: '',
        startTime: '',
        endTime: '',
        tableData: [{
            date: '2016-05-02',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1518 弄'
          }, {
            date: '2016-05-04',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1517 弄'
          }, {
            date: '2016-05-01',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1519 弄'
          }, {
            date: '2016-05-03',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1516 弄'
          }]
      }
    },
    methods: {
      search() {
        console.log('操作搜索');
      },
       handleEdit(index, row) {
        console.log(index, row);
      },
      //获取token
      getToken() {
        console.log('获取token',this.tokenName);
      }
    }
}
</script>