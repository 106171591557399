import request from '@/router/axios';
import { pvhApiBase } from '@/config/env'


//��Ӧ������б�
export const vendorList = (code, name, page, pageSize) => {
    return request({
        url: pvhApiBase + "Tracing/GetVendorSections",
        method: "get",
        params: {
            "code": code,
            "name": name,
            "page": page,
            "pageSize": pageSize
        }
    })
}


//��Ӧ�������Ϣ�޸�
export const editVendor = (params) => {
    return request({
        url: pvhApiBase + "Tracing/PutVendorSection",
        method: "put",
        data: {
            ...params
        }
    })
}



//��Ӧ��������Ϣ�޸�
//export const editProcess = (params) => {
//    console.log(params);
//    return request({
//        url: pvhApiBase + "Tracing/PutSectionProcess",
//        method: "post",
//        data: {
//            ...params
//        }
//    })
//}

//��Ӧ����Ϣɾ��
export const delVendor = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeleteVendorSection",
        method: "delete",
        params: {
            id
        }
    })
}

//��Ӧ��������Ϣɾ��
export const delProcess = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeleteSectionProcess",
        method: "delete",
        params: {
            id
        }
    })
}