import request from '@/router/axios';
import { pvhApiBase } from '@/config/env';

//��ȡ��Ϣ
export const getTreePosition = (id, width, height) => {
    return request({
        url: pvhApiBase + 'Tracing/GetTreeShapePosition',
        method: "get",
        params: {
            "purchaseId": id,
            "width": width,
            "height": height
        }
    })
}

//��ȡ��Ϣ
export const getTreeLinks = (id) => {
    return request({
        url: pvhApiBase + 'Tracing/GetTreeShapeLinks',
        method: "get",
        params: {
            "purchaseId": id,
        }
    })
}