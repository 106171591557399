<template>
    <div>
        <basic-container>
            <avue-crud :option="tableOption"
                       :data="tableData"
                       :table-loading="tableLoading"
                       :page.sync="page"
                       @size-change="sizeChange"
                       @current-change="currentChange">
                <template slot="menuRight" slot-scop="scope">
                    <el-select v-model="type" placeholder="报表类型" size="small" style="width:110px !important;margin-right: 5px;" @change="selType" clearable>
                        <el-option v-for="item in typeList" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <el-select v-if="timeType" v-model="searchVal.time" placeholder="事件类型" size="small" style="width:110px !important;margin-right: 5px;" clearable>
                        <el-option v-for="item in timeList" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <el-date-picker v-model="searchVal.strTime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    type="datetime"
                                    size="small"
                                    style="width:150px !important;margin-right: 5px;"
                                    placeholder="选择开始时间">
                    </el-date-picker>
                    <el-date-picker v-model="searchVal.endTime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    type="datetime"
                                    size="small"
                                    style="width:150px !important;margin-right: 5px;"
                                    placeholder="选择结束时间">
                    </el-date-picker>
                    <el-select v-if="userType || timpType" v-model="searchVal.groupId" placeholder="请选择班组" size="small" style="width: 110px !important; margin-right: 5px;" clearable>
                        <el-option v-for="item in groupList"
                                   :key="item.name"
                                   :label="item.name"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                    <el-select v-if="userType" v-model="searchVal.userId" placeholder="请选择人员" size="small" style="width: 110px !important; margin-right: 5px;" clearable>
                        <el-option v-for="item in userList"
                                   :key="item.name"
                                   :label="item.name"
                                   :value="item.userId">
                        </el-option>
                    </el-select>
                    <el-select v-if="timeType || timpType" v-model="searchVal.workShopId" placeholder="请选择车间" size="small" style="width: 110px !important; margin-right: 5px;" clearable>
                        <el-option v-for="item in cjlist"
                                   :key="item.name"
                                   :label="item.name"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                    <el-select v-if="timeType" v-model="searchVal.machineId" placeholder="请选择设备" size="small" style="width: 110px !important; margin-right: 5px;" clearable>
                        <el-option v-for="item in mechinaList"
                                   :key="item.code"
                                   :label="item.code"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                    <el-select v-if="timpType" v-model="searchVal.shiftId" placeholder="请选择班制" size="small" style="width: 110px !important; margin-right: 5px;" clearable>
                        <el-option v-for="item in bzList"
                                   :key="item.name"
                                   :label="item.name"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜 索</el-button>
                </template>
            </avue-crud>
        </basic-container>
    </div>
</template>
<script>
    import Sortable from "sortablejs";
    import { getMechinaAll, getBZAll, getPerson, getTimp} from "@/api/config"
    import { calssList, getList} from "@/api/formStyle"
export default {
    data() {
        return {
            tableLoading: false,
            type: "1",
            typeList: [
                {
                    label: "设备报表",
                    value: "1"
                },
                {
                    label: "人员报表",
                    value: "2"
                },
                {
                    label: "班组报表",
                    value: "3"
                }
            ],
            timeType: true,
            userType: true,
            deviceType: true,
            timpType:true,
            timeList: [
                {
                    label: "天级",
                    value:"D"
                },
                {
                    label: "时级",
                    value: "H"
                }
            ],
            bzList:[],
            cjlist: [],
            mechinaList: [],
            groupList: [],
            userList:[],
            searchVal: {
                data:"",
                time: "",
                strTime: this.setDate(new Date(new Date - 7 * 24 * 3600 * 1000)),
                endTime: this.setDate(new Date()),
                workShopId: "",
                machineId: "",
                groupId: "",
                userId: "",
                shiftId:""
            },
            page: {
                currentPage: 1,
                total: 0,
                background: false,
                pageSize: 10
            },
            tableData: [],
            tableOption: {
                align: "center",
                cellBtn: false,
                refreshBtn: false,
                columnBtn: false,
                addBtn: false,
                menu:false,
                column: [],
            },
        };
    },
    watch: {
        $route: function () {
            
        }
    },
    created() {
        if (this.$route.query.type === 'day') {
            this.type = "1";
            this.searchVal.time = "D";
            this.searchVal.data = 0;
            this.timeType = true;
            this.userType = false;
            this.deviceType = false;
            this.timpType = false;
            this.searchVal.groupId = "";
            this.searchVal.userId = "";
            this.getCJ();
            this.getMec();
            this.tableOption.column = [
                {
                    label: '车间',
                    prop: 'workShopName'
                },
                {
                    label: '设备',
                    prop: 'machineName'
                },
                {
                    label: '产量',
                    prop: 'yield'
                },
                {
                    label: '能耗',
                    prop: 'energy'
                },
                {
                    label: '时间',
                    prop: 'occurDate'
                }
            ];
        } else if (this.$route.query.type === 'hours') {
            this.type = "1";
            this.searchVal.time = "H";
            this.searchVal.data = 0;
            this.timeType = true;
            this.userType = false;
            this.deviceType = false;
            this.timpType = false;
            this.searchVal.groupId = "";
            this.searchVal.userId = "";
            this.getCJ();
            this.getMec();
            this.tableOption.column = [
                {
                    label: '车间',
                    prop: 'workShopName'
                },
                {
                    label: '设备',
                    prop: 'machineName'
                },
                {
                    label: '产量',
                    prop: 'yield'
                },
                {
                    label: '能耗',
                    prop: 'energy'
                },
                {
                    label: '时间',
                    prop: 'occurDate'
                }
            ];
        } else if (this.$route.query.type === 'user') {
            this.type = "2";
            this.searchVal.time = "D";
            this.searchVal.data = 3;
            this.timeType = false;
            this.userType = true;
            this.deviceType = false;
            this.timpType = false;
            this.searchVal.groupId = this.$route.query.groupId;
            this.searchVal.userId = this.$route.query.userId;
            this.getGroup();
            this.getUser();
            this.tableOption.column = [
                {
                    label: '班组',
                    prop: 'groupName'
                },
                {
                    label: '班制',
                    prop: 'shiftName'
                },
                {
                    label: '员工',
                    prop: 'corpUsersName'
                },
                {
                    label: '产量',
                    prop: 'yield'
                },
                {
                    label: '时间',
                    prop: 'occurDate'
                }
            ];
        } else if (this.$route.query.type === 'device') {
            this.type = "1";
            this.searchVal.time = "D";
            this.searchVal.data = 0;
            this.timeType = true;
            this.userType = false;
            this.deviceType = true;
            this.timpType = false;
            this.searchVal.workShopId = this.$route.query.departmentId;
            this.searchVal.machineId = this.$route.query.id;
            this.getCJ();
            this.getMec();
            this.tableOption.column = [
                {
                    label: '车间',
                    prop: 'workShopName'
                },
                {
                    label: '设备',
                    prop: 'machineName'
                },
                {
                    label: '产量',
                    prop: 'yield'
                },
                {
                    label: '能耗',
                    prop: 'energy'
                },
                {
                    label: '时间',
                    prop: 'occurDate'
                }
            ];
        } else if (this.$route.query.type === 'timp') {
            this.type = "3";
            this.searchVal.time = "D";
            this.searchVal.data = 1;
            this.timeType = false;
            this.userType = false;
            this.timpType = true;
            this.searchVal.groupId = this.$route.query.id;
            this.searchVal.workShopId = this.$route.query.parentId;
            this.getGroup();
            this.getCJ();
            this.getbz();
            this.tableOption.column = [
                {
                    label: '车间',
                    prop: 'workShopName'
                },
                {
                    label: '班制',
                    prop: 'shiftName'
                },
                {
                    label: '班组',
                    prop: 'groupName'
                },
                {
                    label: '产量',
                    prop: 'yield'
                },
                {
                    label: '能耗',
                    prop: 'energy'
                },
                {
                    label: '时间',
                    prop: 'occurDate'
                }
            ];
        } else {
            this.searchVal.time = "D";
            this.searchVal.data = 0;
            this.timeType = true;
            this.userType = false;
            this.deviceType = true;
            this.timpType = false;
            this.searchVal.workShopId = "";
            this.searchVal.machineId = "";
            this.getCJ();
            this.getMec();
            this.tableOption.column = [
                {
                    label: '车间',
                    prop: 'workShopName'
                },
                {
                    label: '设备',
                    prop: 'machineName'
                },
                {
                    label: '产量',
                    prop: 'yield'
                },
                {
                    label: '能耗',
                    prop: 'energy'
                },
                {
                    label: '时间',
                    prop: 'occurDate'
                }
            ];
        }
        this.list();
    },
    methods: {
        //获取车间
        getCJ() {
            calssList().then(res => {
                this.cjlist = res.data.data;
                this.searchVal.workShopId = this.cjlist[0].id;
            })
        },
        //获取设备
        getMec() {
            getMechinaAll().then(res => {
                this.mechinaList = res.data.data;
            })
        },
        //获取班组
        getGroup() {
            getBZAll().then(res => {
                this.groupList = res.data.data;
            })
        },
        //获取人员
        getUser() {
            getPerson().then(res => {
                this.userList = res.data.data;
            })
        },
        //获取班制
        getbz() {
            getTimp().then(res => {
                this.bzList = res.data.data;
            })
        },
        //选择报表类型
        selType(v) {
            console.log(v)
            if (v == 1) {
                this.searchVal.time = "D";
                this.searchVal.data = 0;
                this.timeType = true;
                this.userType = false;
                this.timpType = false;
                this.searchVal.workShopId = "";
                this.searchVal.machineId = "";
                this.getCJ();
                this.getMec();
                this.tableOption.column = [
                    {
                        label: '车间',
                        prop: 'workShopName'
                    },
                    {
                        label: '设备',
                        prop: 'machineName'
                    },
                    {
                        label: '产量',
                        prop: 'yield'
                    },
                    {
                        label: '能耗',
                        prop: 'energy'
                    },
                    {
                        label: '时间',
                        prop: 'occurDate'
                    }
                ];
            } else if (v == 2) {
                this.searchVal.time = "D";
                this.searchVal.data = 3;
                this.timeType = false;
                this.userType = true;
                this.timpType = false;
                this.searchVal.groupId = "";
                this.searchVal.userId = "";
                this.getGroup();
                this.getUser();
                this.tableOption.column = [
                    {
                        label: '班组',
                        prop: 'groupName'
                    },
                    {
                        label: '班制',
                        prop: 'shiftName'
                    },
                    {
                        label: '员工',
                        prop: 'corpUsersName'
                    },
                    {
                        label: '产量',
                        prop: 'yield'
                    },
                    {
                        label: '时间',
                        prop: 'occurDate'
                    }
                ];
            } else if (v == 3) {
                this.searchVal.time = "D";
                this.searchVal.data = 1;
                this.timeType = false;
                this.userType = false;
                this.timpType = true;
                this.searchVal.groupId = "";
                this.searchVal.workShopId = "";
                this.getGroup();
                this.getCJ();
                this.getbz();
                this.tableOption.column = [
                    {
                        label: '车间',
                        prop: 'workShopName'
                    },
                    {
                        label: '班制',
                        prop: 'shiftName'
                    },
                    {
                        label: '班组',
                        prop: 'groupName'
                    },
                    {
                        label: '产量',
                        prop: 'yield'
                    },
                    {
                        label: '能耗',
                        prop: 'energy'
                    },
                    {
                        label: '时间',
                        prop: 'occurDate'
                    }
                ];
            }
            this.list();
        },
        //搜索
        search() {
            this.list();
        },
        list() {
            console.log(this.page.currentPage, this.page.pageSize, this.searchVal)
            getList(this.page.currentPage, this.page.pageSize,this.searchVal).then(res => {
                console.log(res);
                if (res.data.data !== null) {
                    this.tableData = res.data.data.data;
                    this.page.total = res.data.data.total;
                } else {
                    this.tableData = [];
                }
            })
        },
        //每个多少条
        sizeChange(v) {
            console.log(v)
            this.page.pageSize = v;
            this.list();
        },
        //当前第几页
        currentChange(v) {
            console.log(v)
            this.page.currentPage = v;
            this.list();
        },
        setDate(d) {
            var date = new Date(d); //日期对象
            var yTime = date.getFullYear();
            var MTime = date.getMonth() + 1;
            var dTime = date.getDate();
            var hTime = date.getHours();
            var Ftime = date.getMinutes();
            var sTime = date.getSeconds();
            if (MTime < 10) {
                MTime = "0" + MTime;
            }
            if (dTime < 10) {
                dTime = "0" + dTime;
            }
            if (hTime < 10) {
                hTime = "0" + hTime;
            }
            if (Ftime < 10) {
                Ftime = "0" + Ftime;
            }
            if (sTime < 10) {
                sTime = "0" + sTime;
            }
            return yTime + "-" + MTime + "-" + dTime + " " + hTime + ":" + Ftime + ":" + sTime;
        }
    },
};
</script>
