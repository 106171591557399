import request from '@/router/axios';
import {businessUrl} from "@/config/env"
import { updateLocale } from 'moment';
// 查询工艺单code的接口 
export const getPlanCode = () => {
	return request({
			url: businessUrl + "specSheet/selectAllSpecSheet",
			method: "get"
	})
}

export const list = (specSheetId,machineModelId,machineId,productId,status,pageNo,pageSize) => {
    return request({
        url : businessUrl + "specSheetMachinePlan/selectPage",
        method: "post",
        data: {
					specSheetId,
					machineModelId,
					machineId,
					productId,
					status,
					pageNo,
					pageSize,
        }
    })
}
//编辑
// export const edit = (row,machineIds,specSheetParamDtoList,id) => {
//     return request({
//         url : businessUrl + "specSheet/update",
//         method: "put",
//         data : {
//             ...row,
//             machineIds,specSheetParamDtoList,id
//         }
//     })
// }
// 查询标准工艺单
export const getProcessSheet = (status) => {
    return request({
        url : businessUrl + "specSheet/selectBaseByData",
        method: "post",
        data: {
           status
        }
    })
}
// 根据标准工艺单获取参数
export const getParameterByStandard = (id) => {
	return request({
			url: businessUrl + "specSheetParam/selectParamBySheetId?sheetId="+id,
			method: "get"
	})
}
// 根据标准工艺单获取设定参数
export const getParameterByStatus = (id) => {
	return request({
			url: businessUrl + "specParam/selectByStatus?status="+id,
			method: "get"
	})
}
//实际工艺
export const planList = (pageNo,pageSize,nameOrCode) => {
    return request({
        url : businessUrl + "specSheet/selectPageByPlan",
        method: "post",
        data: {
            pageNo,
            pageSize,
            nameOrCode
        }
    })
}
//删除
export const remove = (id) => {
    return request({
        url : businessUrl + "specSheet/delete",
        method: "delete",
        params: {
            id
        }
    })
}
export const changeStatus = (row) => {
    return request({
        url : businessUrl + "specSheet/updateStatus",
        method: "put",
        data : {
            id : row.id,
            status : Number(row.status)
        }
    })
}
/**
 * 
 * @param {} row 
 * {spec_userid@工艺员
 *  productId@产品名称
 * batchCode@批号
 * processId@工序
 * machineModelId@机型
 * hourYield@台日单产
 * moistureRegain@公定回潮率
 * gramWeight@定量
 * efficiencyPercent@运转效率
 * }
 * @param [] wkln
 * 工艺项目信息
 */
//新增标准工艺单
export const add = (row,machineIds,specSheetParamDtoList) => {
    return request({
        url : businessUrl + "specSheet/insertSheet",
        method: "post",
        data : {
            ...row,
            machineIds,specSheetParamDtoList
        }
    })
}
export const  selectByPromayKey = (id,auditStatus) => {
    return request({
        url : businessUrl + "maintenanceOrder/selectByPromayKey",
        method: "put",
        data: {
            id,
            auditStatus
        }
    })
}

//获取平均前罗拉直径
export const getAvgVal = (arr) => {
    return request({
        url: businessUrl + "calculation/avgfrontRollerDiameter",
        method: "post",
        data: {
            //calculationRequest: {
                machineIds:arr
            //}
        }
    })
}

//计算落纱时间
export const getTheory = (meters, frontRollerSpeed, arrID) => {
    return request({
        url: businessUrl + "calculation/doffingTime",
        method: "post",
        data: {
            meters: meters,
            averageSpeed: frontRollerSpeed,
            machineIds: arrID
        }
    })
}

//获取定量克重   //calculation/gramWeight
export const getGram = (id) => {
    return request({
        url: businessUrl + "productaddition/selectByProductId?productId="+id,
        method: "get"
    })
}

//新增计划工艺单
export const insertSheetPlan = (obj) => {
    return request({
        url: businessUrl + "specSheetMachinePlan/insertSheetPlan",
        method: "post",
        data: {
            ...obj
        }
    })
}

//新增计划工艺单
export const updateSheetPlan = (obj) => {
    return request({
        url: businessUrl + "specSheetMachinePlan/updateSheetPlan",
        method: "post",
        data: {
            ...obj
        }
    })
}

//编辑计划工艺单回显
export const selectByPlanSheetId = (planSheetId) => {
    return request({
        url: businessUrl + "specSheetMachinePlan/selectByPlanSheetId?planSheetId="+planSheetId,
        method: "get"
    })
}

//计算台日产量
export const getDayYield = (productid, meters, frontRollerSpeed, arrID, gram,theo) => {
    return request({
        url: businessUrl + "calculation/dayYield",
        method: "post",
        data: {
            productId: productid,
            meters: meters,
            averageSpeed: frontRollerSpeed,
            machineIds: arrID,
            gramWeight: gram,
            theoryDoffingTime: theo
        }
    })
}
// 查询批号
export const searchNumber = (id,code) => {
	return request({
			url : businessUrl + "productBatch/selectBatch",
			method: "post",
			data: {
				productId: id,
				batchCode:code
			}
	})
}