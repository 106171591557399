export const calcDate = (date1, date2) => {
    var date3 = date2 - date1;

    var days = Math.floor(date3 / (24 * 3600 * 1000))

    var leave1 = date3 % (24 * 3600 * 1000) //计算天数后剩余的毫秒数  
    var hours = Math.floor(leave1 / (3600 * 1000))

    var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数  
    var minutes = Math.floor(leave2 / (60 * 1000))

    var leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数  
    var seconds = Math.round(date3 / 1000)
    return {
        leave1,
        leave2,
        leave3,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
    }
}

/**
 * 日期格式化
 */
export function dateFormat(date,format="yyyy-MM-dd") {
    if (date != 'Invalid Date') {
        var o = {
            "M+": date.getMonth() + 1, //month
            "d+": date.getDate(), //day
            "h+": date.getHours(), //hour
            "m+": date.getMinutes(), //minute
            "s+": date.getSeconds(), //second
            "q+": Math.floor((date.getMonth() + 3) / 3), //quarter
            "S": date.getMilliseconds() //millisecond
        }
        if (/(y+)/.test(format)) format = format.replace(RegExp.$1,
            (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
            if (new RegExp("(" + k + ")").test(format))
                format = format.replace(RegExp.$1,
                    RegExp.$1.length == 1 ? o[k] :
                        ("00" + o[k]).substr(("" + o[k]).length));
        return format;
    }
    return '';

}

export function getDate(d) {
    var date = new Date(d); //日期对象
    var yTime = date.getFullYear();
    var MTime = date.getMonth() + 1;
    var dTime = date.getDate();
    var hTime = date.getHours();
    var Ftime = date.getMinutes();
    var sTime = date.getSeconds();
    if (MTime < 10) {
        MTime = "0" + MTime;
    }
    if (dTime < 10) {
        dTime = "0" + dTime;
    }
    if (hTime < 10) {
        hTime = "0" + hTime;
    }
    if (Ftime < 10) {
        Ftime = "0" + Ftime;
    }
    if (sTime < 10) {
        sTime = "0" + sTime;
    }
    return yTime + "-" + MTime + "-" + dTime + " " + hTime + ":" + Ftime + ":" + sTime;
}