<template>
  <div class="volumeSet">
    <el-dialog
        style="marginTop: -60px;"
        :visible.sync="modelShow"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="35%"
        :show-close="false">
      <div class="content">
        <div class="table">
          <div class="title">空锭管理</div>
          <el-table  :data="tableData1"
                     align="center"
                     border
                     style="width: 100%">
            <el-table-column prop="startTime" label="开始时间" ></el-table-column>
            <el-table-column prop="inputTime" label="结束时间" ></el-table-column>
            <el-table-column prop="spinEmpty" label="空锭数" ></el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="150">
              <template slot-scope="{row}">
                <el-button @click="edit('edit',row)" type="primary" size="small">编辑</el-button>
                <el-button @click="del(1,row)" type="primary" size="small">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="add_btn">
            <el-button  @click="edit('add',row)"  type="primary" size="small">新增</el-button>
          </div>
        </div>
        <div class="table">
          <div class="title">故障时间管理</div>
          <el-table
              :data="tableData2"
              border
              align="center"
              style="width: 100%">
            <el-table-column prop="beginTime" label="开始时间" ></el-table-column>
            <el-table-column prop="endTime" label="结束时间" ></el-table-column>
            <el-table-column prop="hours" label="故障小时数" ></el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="150">
              <template slot-scope="{row}">
                <el-button @click="edit('faultEdit',row)" type="primary" size="small">编辑</el-button>
                <el-button @click="del(2,row)" type="primary" size="small">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="add_btn">
            <el-button @click="edit('faultAdd',row)"  type="primary" size="small">新增</el-button>
          </div>
        </div>
      </div>
<!--      空锭管理-->
      <el-dialog
          width="30%"
          :title="title"
          :visible.sync="innerVisible"
          append-to-body>
        <div class="add_edit">
          <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="开始时间">
              <el-date-picker
                  v-model="form.startTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间">
              <el-date-picker
                  v-model="form.inputTime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetime"
                  placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="空锭数">
              <el-input placeholder="请输入内容" v-model="form.spinEmpty">
                <template slot="append">个</template>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <p slot="footer" class="dialog-footer" style="margin-top: -30px;">
          <el-button type="warning" @click="save">确 认</el-button>
          <el-button @click="innerVisible = false">取 消</el-button>
        </p>
      </el-dialog>
<!--      故障管理-->
      <el-dialog
          width="30%"
          :title="title"
          :visible.sync="showFault"
          append-to-body>
        <div class="add_edit">
          <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="开始时间">
              <el-date-picker
                  v-model="form.beginTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间">
              <el-date-picker
                  v-model="form.endTime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetime"
                  placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <p slot="footer" class="dialog-footer" style="margin-top: -30px;">
          <el-button type="warning" @click="saveHours">确 认</el-button>
          <el-button @click="showFault = false">取 消</el-button>
        </p>
      </el-dialog>
      <p slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="warning" @click="makeSure">确 认</el-button>
        <el-button @click="closeModel">取 消</el-button>
      </p>
    </el-dialog>
  </div>
</template>

<script>
import { dateFormat } from '@/filters/'
export default {
  name: "scene",
  props: {
    modelShow: {
      type: Boolean,
      default: false
    },
    seletItem:Object
  },
  data() {
    return {
      innerVisible:false,
      showFault:false,
      title:'',
      tableData1:[
        {
          date:1
        }
      ],
      tableData2:[
        {
          date:1
        }
      ],
      form:{
        date:''
      }
    }
  },
  mounted() {
    this.getSpinEmptyYield()
    this.getSelectFaultByMachineId()
  },
  methods: {
    // 保存
    async save(){
      if(this.title == '添加') {

        await this.request.post(this.proApis.SPINEMPTYYIELDINSERTSPIN,{
          startTime:this.form.startTime,
          inputTime:this.form.inputTime,
          spinEmpty:this.form.spinEmpty,
          machineId:this.seletItem.fact.machineId,
        })
        //this.$message.success('保存成功')
        await this.getSpinEmptyYield()
        this.innerVisible = false
      }else {
        await this.request.post(this.proApis.SPINEMPTYYIELDUPDATE,{
          startTime:this.form.startTime,
          inputTime:this.form.inputTime,
          spinEmpty:this.form.spinEmpty,
          machineId:this.seletItem.fact.machineId,
          id:this.form.id
        })
        //this.$message.success('保存成功')
        await this.getSpinEmptyYield()
        this.innerVisible = false
      }
    },
    async edit(type,row){
      console.log(row)
      if(type == 'edit'){
        this.form = row
        this.title = '编辑'
        this.innerVisible = true
      }else if(type == 'add'){
        const res = await this.request.get(`${this.proApis.SPINEMPTYYIELDSELECTLASTTIME}?machineId=${this.seletItem.fact.machineId}`)
        console.log(res,'rere')

        this.form = {
          startTime :res.data.data?res.data.data.inputTime:'',
          inputTime : dateFormat(new Date()),
          spinEmpty :'',
        }
        this.title = '添加'
        this.innerVisible = true
      }else if(type == 'faultEdit'){
        this.form = row
        this.title = '编辑'
        this.showFault = true
      }else if(type == 'faultAdd'){
        this.title = '添加'
        this.showFault = true
      }

    },
    del(type,row){
      this.$confirm('确定删除此项吗?','提示').then(res=>{
        console.log(row)
        if(type == 1){
          console.log('删除空锭')
          this.request.delete(this.proApis.SPINEMPTYYIELDDELETE+'?id='+row.id).then(res=>{
            this.$message.success(res.data.msg)
            this.getSpinEmptyYield()
          })
        }else {
          console.log('删除故障')
          this.request.delete(this.proApis.FAULTDELETE+'?id='+row.id).then(res=>{
            this.$message.success(res.data.msg)
            this.getSelectFaultByMachineId()
          })
        }

      }).catch(err=>{
        console.log(err)
      })
    },
    // 查询空锭数
    async getSpinEmptyYield(){
      let machineId = this.seletItem.fact.machineId
      let openTime = this.seletItem.fact.openTime
      const res = await this.request.get(`${this.proApis.SPINEMPTYYIELDSELECTALL}?machineId=${machineId}&openTime=${openTime}`)
      console.log(res)
      this.tableData1 = res.data.data
    },
    // 查询故障
    async getSelectFaultByMachineId(){
      let machineId = this.seletItem.fact.machineId
      let openTime = this.seletItem.fact.openTime
      const res = await this.request.get(`${this.proApis.FAULTSELECTFAULTBYMACHINEID}?machineId=${machineId}&openTime=${openTime}`)
      res.data.data.forEach(item=>{
        if(item.endTime){
          let end_str = (item.endTime).replace(/-/g,"/")
          let sta_str = (item.beginTime).replace(/-/g,"/")
          let end_date = new Date(end_str).getTime()
          let sta_date = new Date(sta_str).getTime()
          let num =  ((end_date-sta_date) / (1000*3600)).toFixed(1)
          item.hours = num
        }else {
          item.hours = ''
        }

      })
      this.tableData2 = res.data.data
      console.log(this.tableData2,'this.tableData2')
    },
    async saveHours(){
      if(this.title == '添加') {
        await this.request.post(this.proApis.FAULTINSERTFAULT,{
          beginTime:this.form.beginTime,
          endTime:this.form.endTime,
          machineId:this.seletItem.fact.machineId,
        })
        //this.$message.success('保存成功')
        await this.getSelectFaultByMachineId()
        this.showFault = false
      }else {
        await this.request.put(`${this.proApis.FAULTUPDATE}`
            ,{
          beginTime:this.form.beginTime,
          endTime:this.form.endTime,
          machineId:this.seletItem.fact.machineId,
          id:this.form.id
        })
        //this.$message.success('保存成功')
        await this.getSelectFaultByMachineId()
        this.showFault = false
      }
    },
    closeModel() {
      this.$emit('closeModel', false)
    },
    makeSure() {
      this.$emit('closeModel', false)
    },
  }
}
</script>

<style lang="scss" scoped>
.content {
  .table {
    margin-bottom: 30px;
    .title {
      font-weight: 700;
      margin-bottom: 10px;
    }
    .add_btn {
      margin-top: 10px;
    }
  }
}
.dialog-footer {
  width: 100%;
  text-align: center;
}
</style>