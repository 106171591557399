<template>
  <div>
    <basic-container>
      <avue-crud :option="tableOption"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :page="page"
                 ref="crud"
                 @row-update="handleUpdate"
                 @selection-change="selectionChange"
                 @refresh-change="refreshChange"
                 @row-save="handleSave"
                 @size-change="sizeChange"
                 @current-change="currentChange"
      >
        <template slot-scope="scope" slot="menu">
<!--            <el-button type="text" size="small" >停用</el-button>-->
            <el-button  size="text" @click.native="handleUpdate1(scope.row,scope.index)">{{$t("Edit")}}</el-button>
            <el-button  size="text" @click.native="handleDel(scope.row)">{{$t("Delete")}}</el-button>
        </template>
<!--        <template slot="menuLeft">-->
<!--          <el-button type="primary" size="small" icon="el-icon-discover" v-bind:disabled="disabled">审核</el-button>-->
<!--          <el-button type="warning" size="small" icon="el-icon-discover" v-bind:disabled="disabled">撤销审核</el-button>-->
<!--        </template>-->
        <template slot="status" slot-scope="scope" >
          <el-switch
              v-model="scope.row.status"
              :active-value="0"
              :inactive-value="1"
              @change='statusChange($event,scope.row.id)'>
          </el-switch>
        </template>
        <template slot="menuRight">
          <div style="display: inline-block;width:200px;marginRight: 5px;">
            <el-select clearable  v-model="page.status" placeholder="请选择状态">
              <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div style="display: inline-block;width:200px;marginRight: 5px;">
            <el-select clearable  v-model="page.dataType" placeholder="请选择字段类型">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <el-input v-model.trim="page.nameOrCode"
                    placeholder="请输入字段名称或字段KEY"
                    style="display:'inlie-block';width:200px;marginRight: 5px;"
                    size="small"></el-input>
          <el-button size="small" type="primary" @click="search">{{$t("search")}}</el-button>
        </template>

      </avue-crud>
    </basic-container>
  </div>
</template>
<script>

export default {
  data() {
    return {
      nameOrCode: "",
      processName: "",
      modelType: "",
      departmentName: "",
      tableLoading:false,
      page: {
        pageSize: 10,
        pageNo:1,
        pagerCount: 5,
        total: 1,
        pageCurrent: 1,
        nameOrCode:'',
        dataType:'',
        status:''
      },
      disabled: true,
      tableData: [],
      tableOption: {
        delBtn: false,
        editBtn:false,
        menuWidth: 400,
        selection: false,
        tip: false,
        columnBtn: false,
        dialogFullscreen: true,
        addBtnText: this.$t('ADD'),
        delBtnText: this.$t('DELETE'),
        editBtnText: this.$t('Edit'),
        emptyText: this.$t('No Data'),
        menuTitle: this.$t('OPERATION'),
        column: [
          // {
          //   label: "设备编号",
          //   prop: "id",
          //   hide: true,
          //   rules: [{
          //     required: true,
          //     message: "请输入编号",
          //     trigger: "blur"
          //   }]
          // },
          // {
          //   label: "创建时间",
          //   addDisplay: false,
          //   prop: "createTime"
          // },
          {
            label: "id",
            prop: "id",
            hide: true,
            addDisplay: false,
            editDisplay: false,
          },
          {
            label: "字段名称",
            prop: "name"
          },{
            label: "字段KEY",
            prop: "code"
          }, {
            label: "字段类型",
            prop: "dataType",
            type: "select",
            dicData:[{
              value:'byte',
              label:'byte'
            },
              {
                value:'short',
                label:'short'
              },
              {
                value:'int',
                label:'int'
              },
              {
                value:'long',
                label:'long'
              },
              {
                value:'float',
                label:'float'
              },
              {
                value:'double',
                label:'double'
              },
              {
                value:'boolean',
                label:'boolean'
              },
              {
                value:'char',
                label:'char'
              }]
          },{
            label: "数据最小值",
            prop: "minValue"
          }, {
            label: "数据最大值",
            prop: "maxValue"
          },
          {
            label: "状态",
            prop: "status",
            slot: true,
            dicData: [{
              label: "正常",
              value: 0
            }, {
              label: "无效",
              value: 1
            }]
          },
            // {
            //   label: "公司编号",
            //   prop: "corporationId",
            //   hide: true,
            // }
          ]
      },
      options:[
        {
          value:'byte',
          label:'byte'
        },
        {
          value:'short',
          label:'short'
        },
        {
          value:'int',
          label:'int'
        },
        {
          value:'long',
          label:'long'
        },
        {
          value:'float',
          label:'float'
        },
        {
          value:'double',
          label:'double'
        },
        {
          value:'boolean',
          label:'boolean'
        },
        {
          value:'char',
          label:'char'
        },
      ],
      statusList:[
        {
          value: 0,
          label:'正常'
        },{
          value: 1,
          label:'无效'
        }
      ]
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async statusChange(val,id){
      console.log(val,'val')
      const res = await this.request.get(this.proApis.IOTCOLUMNSUPDATESTATUS + '?status=' + val + '&id=' + id)
      console.log(res)
      if(res.data.code == '0000') {
        this.$message.success('修改成功')
        this.getList()
      }
    },
    // 删除
    handleDel(row) {
      this.$confirm("确定要删除吗", "提示", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.request.delete(this.proApis.IOTCOLUMNSDELETE + '?id=' + row.id).then(()=>{
          this.getList()
          this.$message.success('操作成功')
        })
      })
    },
    // 编辑
    handleUpdate1(row, index ) {
      this.$refs.crud.rowEdit(row, index)
      console.log(index,row)
    },
    //编辑
    async handleUpdate(row, index ,done){
      await this.request.put(this.proApis.UPDATE,row)
      await this.getList()
      this.$message.success('操作成功')
      done()
    },
    // 添加保存
    async handleSave(row, done) {
      console.log(row)
      const  res = await this.request.post(this.proApis.INSERT,row)
      if(res.data.code == '0000'){
        await this.getList()
        this.$message.success('操作成功')
        done()
      }else {
        this.$message.error(res.data.msg)
        done()
      }
      console.log(res,'res')

    },
    change(row) {
      console.log(row,'ffow')
    },
    selectionChange(list) {
      console.log(list);
      list != "" ? this.disabled = false : this.disabled = true;
    },
    refreshChange() {
      this.page= {
        pageSize: 10,
        pagerCount: 5,
        total: 1,
        pageCurrent: 1,
        nameOrCode:'',
        dataType:'',
      }
      this.getList();
    },
    // 查询
    search(){
      this.getList()
    },
    async getList() {
      const  res = await this.request.post(this.proApis.SELECTPAGE,this.page)
      this.page.total = res.data.data.total
      this.tableData = res.data.data.items
      console.log(res,'res')
    },
    sizeChange(val){
      this.page.pageNo = 1
      this.page.pageSize = val
      this.getList()
    },
    currentChange(val){
      this.page.pageNo = val
      this.getList()
    }
  }
}
</script>
<style scoped>
.el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  padding-left: 10px;
}
</style>