var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c("el-tag", [
            _vm._v("菜单中还是父类高亮，参考mock/menu.js里的group配置")
          ]),
          _c("el-tag", { attrs: { type: "success" } }, [
            _vm._v("动态路由接受参数" + _vm._s(_vm.$route.params))
          ])
        ],
        1
      ),
      _c(
        "basic-container",
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.breaks } },
            [_vm._v("关闭返回列表")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }