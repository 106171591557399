<template>
  <div>
    <basic-container>
      <avue-crud :option="tableOption"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :page="page"
                 ref="crud"
                  @refresh-change="refreshChange"
                 @selection-change="selectionChange"
                 @size-change="sizeChange"
                 @current-change="currentChange"
      >
      <template slot="machineNumber" slot-scope="{row}">
          <el-link style="color: #409EFF;text-style: none;" @click="clickMachine(row)">
            {{ row.machineNumber || '无'}}
          </el-link>
        </template>
         <template slot="menuLeft" >
            <el-button type="primary" @click="openAdd" size="small" icon="el-icon-plus">{{$t('Add')}}</el-button>
        </template>
        <template slot="menuRight">
          <el-select  v-model="page.processName" placeholder="请选择工序" clearable size="small" style="width:200px !important;marginRight: 5px;">
            <el-option
                v-for="item in process"
                :key="item.name"
                :label="item.name"
                :value="item.name">
            </el-option>
          </el-select>
          <el-select filterable v-model.trim="page.typeName" placeholder="请输入设备类别" clearable size="small" style="width:200px !important;marginRight: 5px;">
            <el-option v-for="value in typeList" :key="value.id" :value="value.code" :label="value.name"></el-option>
          </el-select>
          <el-input v-model.trim="page.nameOrCode" placeholder="请输入名称或编码" clearable size="small" style="width:200px !important;marginRight: 5px;"></el-input>
        </template>
        <template slot-scope="scope" slot="menu">
          <el-button  size="text" @click.native="handleUpdate(scope.row)">{{$t('Edit')}}</el-button>
          <el-button  size="text"  @click.native="handleDel(scope.row)">{{$t('Delete')}}</el-button>
<!--          <el-button  size="text"  @click.native="goModel(scope.row)">{{$t('Management model')}}</el-button>-->
        </template>
      </avue-crud>
      <!--      编辑弹窗-->
      <edit v-if="showEdit"  :modelShow="showEdit" @closeModel="closeModel"></edit>
      <look v-if="showLook"  :modelShow="showLook" @closeModel="closeModel"></look>
      <addDev @addDev="addDev" v-if="showAddDev"  :modelShow="showAddDev" @closeModel="closeModel"></addDev>
      <editDev @editDev="editDev" v-if="showEditDev"  :selectItem="selectItem" :modelShow="showEditDev" @closeModel="closeModel"></editDev>
    </basic-container>
  </div>
</template>
<script>
import edit from './components/edit'
import addDev from './components/addDev'
import editDev from "./components/editDev";
import look from "./components/look";
export default {
  components: {
    edit,
    addDev,
    editDev,
    look
  },
  data() {
    return {
      typeList: [],
      nameOrCode: "",
      processName: "",
      modelType: "",
      departmentName: "",
      tableLoading:false,
      showEdit:false,
      process:[],
      page: {
        nameOrCode:'',
        processName:'',
        typeName:'',
        pageNo: 1,
        pageSize: 10,
        total:0,
        categoryId:'6966062987724dc7ae03c8d7bade4b1c'
      },
      disabled: true,
      tableData: [
      ],
      tableOption: {
        delBtn: false,
        editBtn:false,
        menuWidth: 200,
        selection: false,
        tip: false,
        columnBtn: false,
        addBtn:false,
        column: [
          {
            label: "编码",
            prop: "code"
          },{
            label: "名称",
            prop: "name",
          }, {
            label: "工序",
            prop: "processName"
          },{
            label: "设备类别",
            prop: "typeName"
          }, {
            label: "功率(kw/h)",
            prop: "power"
          },{
            label: "关联设备数(台)",
            slot: true,
            prop: "machineNumber"
          }
          // {
          //   label: "创建人",
          //   prop: "createUserName",
          //   // hide: true,
          // },
          // {
          //   label: "创建时间",
          //   prop: "createTime",
          //   // hide: true,
          // }
        ]
      },
      showAddDev:false,
      showEditDev:false,
      showLook:false,
      selectItem:{}
    }
  },
  async created() {
    const res = await this.request.get(this.proApis.SELECTDICTBYCATEGORYID + "?categoryId=6966062987724dc7ae03c8d7bade4b1c");
    this.typeList = res.data.data;
    this.getList()
    this.getProse()
  },
  methods: {
    //查看具体的设备数
    clickMachine(item) {
      this.$router.push({ path: '/device/index', query: { pushData: item.id} })
    },
    async getProse(){
      const  res = await this.request.get(this.proApis.PROCESSSELECTALL)
      console.log(res,'工序')
      this.process = res.data

    },
    showCreat(row){
      this.showEdit = true
    },
    openAdd(){
      this.showAddDev = true
    },
    goModel(row){
      console.log('row',row);
      this.$router.push({
        name:'Object Model Manage',
        path: '/device/thingsModel'
      })
    },
    refreshChange() {
       this.getList()
    },
    // 保存添加
      async addDev(val) {
        console.log(val)
      const  res = await this.request.post(this.proApis.MACHINEMODELINSERT,val)
      console.log(res)
      if(res.data.code == '0000'){
        this.$message.success('新增成功')
      }
      this.showAddDev = false
      this.getList()
    },
    // 编辑保存
    async editDev(val){
      const  res = await this.request.post(this.proApis.MACHINEMODELUPDATE,val)
      console.log(res)
      if(res.data.code == '0000'){
        this.$message.success('编辑成功')
      }
      this.showEditDev = false
      this.getList()
    },
    showLookModel(){
      this.showLook = true
    },
    // 删除
    handleDel(row) {
      this.$confirm("确定要删除吗", "提示", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.request.delete(this.proApis.MACHINEMODEDELETE + '?id=' + row.id).then(()=>{
          this.getList()
          this.$message.success('删除成功')
        })
      })
    },
    // 编辑
    handleUpdate(row ) {
      console.log(row)
      this.selectItem = row
      this.showEditDev = true
    },
    selectionChange(list) {
      console.log(list);
      list != "" ? this.disabled = false : this.disabled = true;
    },
    async getList() {
      const  res = await this.request.post(this.proApis.MACHINEMODELSELECTPAGE,this.page)
      this.page.total = res.data.data.total
      this.tableData = res.data.data.items
      console.log(res,'res')
    },
    sizeChange(val){
      this.page.pageNo = 1
      this.page.pageSize = val
      this.getList()
    },
    currentChange(val){
      this.page.pageNo = val
      this.getList()
    },
    closeModel(){
      this.showEdit = false
      this.showAddDev = false
      this.showEditDev = false
      this.showLook = false
    }
  }
}
</script>
<style lang="scss" scoped>
.el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  padding-left: 10px;
}
.header {
  display: flex;
  justify-content: space-between;
  .search {
    display: flex;
    .el-input {
      margin-right: 10px;
    }
    .el-select {
      margin-right: 10px;
      width: 100%;
    }
  }
}
</style>