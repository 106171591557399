var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "el-aside",
            {
              staticStyle: { height: "80vh", float: "left" },
              attrs: { width: "20%" }
            },
            [
              _c("el-tree", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                attrs: {
                  data: _vm.data,
                  props: _vm.defaultProps,
                  "element-loading-text": _vm.$t(
                    "Loading material classification"
                  ),
                  "default-expand-all": ""
                },
                on: { "node-click": _vm.handleNodeClick }
              })
            ],
            1
          ),
          _c("timp", {
            ref: "timp",
            staticStyle: { width: "80%", float: "left" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }