import request from '@/router/axios';
import { pvhApiBase } from '@/config/env';


export const list = () => request({
    url: pvhApiBase + "Cashier/GetUserRolesByUserId",
    method: "get",
    data: {
        
    }
})


