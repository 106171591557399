<template>
    <div class="wklnChange" id="home_container" style="background:transparent;position:relative;top:70px;left:0;">
        <el-dialog :visible.sync="dialogTableVisible" width="70%">
            <span style="font-size:22px;">{{codeModel}} - {{nameModel}}</span>
            <el-divider style="background:#4D8AF0;height:1.5px;"></el-divider>
            <div style="display:flex;align-items:center;flex-wrap:wrap;margin-bottom:24px;">
                <div style="display:flex;align-items:center;margin-bottom:15px;margin-right:40px;">
                    <span style="font-size:14px;color:#333;margin-right:10px;">设备机型</span>
                    <div>
                        <el-input placeholder="请输入内容"
                                  v-model="codeName.modelName"
                                  size="small"
                                  :disabled="true">
                        </el-input>
                    </div>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;margin-right:40px;">
                    <span style="font-size:14px;color:#333;margin-right:10px;">所属工序</span>
                    <div>
                        <el-input placeholder="请输入内容"
                                  v-model="codeName.code"
                                  size="small"
                                  :disabled="true">
                        </el-input>
                    </div>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;margin-right:40px;">
                    <span style="font-size:14px;color:#333;margin-right:10px;">设备锭数</span>
                    <div>
                        <el-input placeholder="请输入内容"
                                  v-model="codeName.spinCount"
                                  size="small"
                                  :disabled="true">
                        </el-input>
                    </div>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;margin-right:40px;">
                    <span style="font-size:14px;color:#333;margin-right:10px;">使用锭数</span>
                    <div>
                        <el-input placeholder="请输入内容"
                                  v-model="codeName.spinUsed"
                                  size="small"
                                  :disabled="true">
                        </el-input>
                    </div>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;margin-right:40px;">
                    <span style="font-size:14px;color:#333;margin-right:10px;">在产品种</span>
                    <div>
                        <el-input placeholder="请输入内容"
                                  v-model="codeName.fact.productName"
                                  size="small"
                                  :disabled="true">
                        </el-input>
                    </div>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;margin-right:40px;">
                    <span style="font-size:14px;color:#333;margin-right:10px;">产品批号</span>
                    <div>
                        <el-input placeholder="请输入内容"
                                  v-model="codeName.fact.batchName"
                                  size="small"
                                  :disabled="true">
                        </el-input>
                    </div>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;margin-right:40px;">
                    <span style="font-size:14px;color:#333;margin-right:10px;">工艺单号</span>
                    <div>
                        <el-input placeholder="请输入内容"
                                  v-model="codeName.fact.specSheetCode"
                                  size="small"
                                  :disabled="true">
                        </el-input>
                    </div>
                </div>
            </div>
            <span style="font-size:22px;">锭速曲线</span>
            <el-divider style="background: #4D8AF0; height: 1.5px;"></el-divider>
            <div>
                <div class="charts container" style="width:100%;">
                    <div id="dsqx" style="width:100%;height:18.75rem;"></div>
                </div>
            </div>
            <span style="font-size:22px;">设备参数</span>
            <el-divider style="background: #4D8AF0; height: 1.5px;"></el-divider>
            <div>
                <div style="margin-top: 20px;margin-bottom:15px;">
                    <el-radio-group v-model="activeName" size="small" @change="sbcs">
                        <el-radio-button label="工艺参数"></el-radio-button>
                        <el-radio-button label="能耗参数"></el-radio-button>
                    </el-radio-group>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:24px;" id="gycsDiv">
                    <div style="display:flex;align-items:center;flex-wrap:wrap;">
                        <div v-for="item in totVal" style="display:flex;align-items:center;margin-bottom:15px;margin-right:40px;">
                            <span style="font-size:14px;color:#333;margin-right:10px;">{{item.highParam.specParamName}}</span>
                            <div>
                                <el-input placeholder="请输入内容"
                                          v-model="item.highParam.value"
                                          size="small"
                                          :disabled="true">
                                </el-input>
                            </div>
                        </div>
                    </div>
                    <div style="display:flex;align-items:center;flex-wrap:wrap;">
                        <div v-for="item in totVal" style="display:flex;align-items:center;margin-bottom:15px;margin-right:40px;">
                            <span style="font-size:14px;color:#333;margin-right:10px;">{{item.spindleSpeedParam.specParamName}}</span>
                            <div>
                                <el-input placeholder="请输入内容"
                                          v-model="item.spindleSpeedParam.value"
                                          size="small"
                                          :disabled="true">
                                </el-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="display:none;align-items:center;flex-wrap:wrap;margin-bottom:24px;" id="nhcsDiv">
                    <div style="display:flex;align-items:center;margin-bottom:15px;margin-right:40px;">
                        <span style="font-size:14px;color:#333;margin-right:10px;">电压</span>
                        <div>
                            <el-input placeholder="请输入内容"
                                      v-model="energyVal.voltage"
                                      size="small"
                                      :disabled="true">
                            </el-input>
                        </div>
                    </div>
                    <div style="display:flex;align-items:center;margin-bottom:15px;margin-right:40px;">
                        <span style="font-size:14px;color:#333;margin-right:10px;">电流</span>
                        <div>
                            <el-input placeholder="请输入内容"
                                      v-model="energyVal.eleCurr"
                                      size="small"
                                      :disabled="true">
                            </el-input>
                        </div>
                    </div>
                    <div style="display:flex;align-items:center;margin-bottom:15px;margin-right:40px;">
                        <span style="font-size:14px;color:#333;margin-right:10px;">总功率因数</span>
                        <div>
                            <el-input placeholder="请输入内容"
                                      v-model="energyVal.total_power_factor"
                                      size="small"
                                      :disabled="true">
                            </el-input>
                        </div>
                    </div>
                </div>
            </div>
            <span style="font-size:22px;">相关报表</span>
            <el-divider style="background: #4D8AF0; height: 1.5px;"></el-divider>
            <div>
                <el-button type="primary" @click="goHoursR()">设备时级报表</el-button>
                <el-button type="primary" @click="goDayR()">设备天级报表</el-button>
            </div>
        </el-dialog>
        <el-form :inline="true" :model="formInline" class="demo-form-inline outFormDiv" labelWidth="6.25rem" style="display:flex;justify-content:space-between;padding:15px;background:#F2F2F2;position:fixed;top:100px;right:0;width:calc(100% - 13.75rem);z-index:1;box-sizing: border-box;">
            <el-form-item label="所属车间" style="width:calc(33.33% - 23.33px);display:flex;margin-bottom:0;">
                <el-select v-model="formInline.departmentId" clearable filterable placeholder="车间选择">
                    <el-option v-for="item in departmentoptions"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="设备机型" style="width:calc(33.33% - 23.33px);display:flex;margin-bottom:0;">
                <el-select v-model="formInline.machineModelId" clearable filterable placeholder="设备机型">
                    <el-option v-for="item in machineoptions"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="设备编号" style="width:calc(33.33% - 23.33px);display:flex;margin-bottom:0;">
                <el-select v-model="formInline.code" clearable filterable placeholder="设备编号">
                    <el-option v-for="item in machineCodeoptions"
                               :key="item.code"
                               :label="item.code"
                               :value="item.code">
                    </el-option>
                </el-select>
            </el-form-item>
            <div style="width:70px;">
                <el-button type="primary" @click="onSearch">{{$t("search")}}</el-button>
            </div>
        </el-form>
        <div class="content sbCon" style="padding:7.5px;position:relative;" :style="outHei">
            <draggable class="scDiv" style="position:absolute;top:0;left:0;width:100%;margin-top:7.5px;padding:0 7.5px;box-sizing: border-box;" :style="scHei">
                <div class="item shebei" v-for="(item,indexCod) in list" :key="indexCod" :style="itemHeight">
                    <div class="name" style="display:flex;justify-content:space-between;padding:0 7.5px;height:50px;line-height:50px;border-radius:8px 8px 0 0;" :style="topTitle[item.iotStatus]">
                        <div style="position:relative;padding-left:15px;" :style="textStatus[item.iotStatus]"><i class="iconfont icon-biaotibiao" :style="iconStatus[item.iotStatus]"></i>{{item.code}}</div>
                        <div>
                            <div class="item_color" v-if="item.iotStatus==1" style="color:#4D8AF0;font-size:14px;">
                                <i class="iconfont icon-you" style="vertical-align: 0; font-size:15px !important;"></i>进行中
                            </div>
                            <div class="item_color" v-if="item.iotStatus==2" style="color:#fff;font-size:14px;">
                                <i class="iconfont icon-tingzhi" style="vertical-align:0; font-size:15px !important;"></i>停机中
                            </div>
                            <div class="item_color" v-if="item.iotStatus==0" style="color:#fff;font-size:14px;">
                                <i class="iconfont icon-lixian" style="vertical-align: 0; font-size:15px !important;"></i>离线中
                            </div>
                        </div>
                    </div>
                    <div class="detail_info" style="padding: 0 7.5px;height:calc(100% - 128px);display:flex;flex-wrap:wrap;align-items:center;">
                        <div style="width:100%;display:flex;align-items:center;">
                            <i class="iconfont icon-pinzhong12" style="vertical-align: 0; font-size:16px !important;color:#CAD9EF;margin-right:10px;"></i>
                            产品品种：<span style="font-size: 14px;color: #aaa;width: calc(100% - 115px);text-overflow: ellipsis;overflow: hidden;white-space: nowrap;display: inline-block;">{{item.fact?item.fact.productName:'-'}}</span>
                        </div>
                        <div style="width:100%;display:flex;align-items:center;">
                            <i class="iconfont icon-mingxi" style="vertical-align: 0; font-size:16px !important;color:#CAD9EF;margin-right:10px;"></i>
                            产品批次：<span style="font-size:14px;color:#aaa;width: calc(100% - 115px);text-overflow: ellipsis;overflow: hidden;white-space: nowrap;display: inline-block;">{{item.fact?item.fact.batchName:'-'}}</span>
                        </div>
                        <!-- <div style="width:100%;display:flex;align-items:center;">
                            <i class="iconfont icon-mingxi" style="vertical-align: 0; font-size:16px !important;color:#CAD9EF;margin-right:10px;"></i>
                            工艺单号<span style="font-size:14px;color:#aaa;width: calc(100% - 115px);text-overflow: ellipsis;overflow: hidden;white-space: nowrap;display: inline-block;">{{item.fact?item.fact.specSheetCode:'-'}}</span>
                        </div> -->
                    </div>
                    <div class="operate" style="width:100%;padding:0 7.5px;height:50px;">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <div>
                                    <el-button @click="showModel(item.iotStatus,item.id,2)" style="background: #F0F7FF;width: 100%;border:1px solid #4D8AF0;color:#4D8AF0;border-radius: 8px;padding: 12px 0;" icon="iconfont icon-ico-gongyishuxing">工艺参数</el-button>
                                    <!--<el-popover
                                    :disabled="item.iotStatus == 1?false:true"
                                    placement="bottom"
                                    title="工艺参数"
                                    @show="getProcessParameters(item,false)"
                                    width="800"
                                    trigger="click">
                                  <div class="content_form">
                                    <el-form ref="form" :model="form" label-width="6.25rem">
                                      <el-row :gutter="4">
                                        <el-col :span="8">
                                          <el-form-item label="定长">
                                            <el-input v-model="form.fixed_length" disabled></el-input>
                                          </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                          <el-form-item label="捻度">
                                            <el-input v-model="form.twist_number" disabled></el-input>
                                          </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                          <el-form-item label="牵引(伸)倍数">
                                            <el-input v-model="form.drafting_multiple" disabled></el-input>
                                          </el-form-item>
                                        </el-col>
                                      </el-row>
                                      <el-row :gutter="4">
                                        <el-col :span="8">
                                          <el-form-item label="前罗拉直径">
                                            <el-input v-model="form.front_roller_diameter" disabled></el-input>
                                          </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                          <el-form-item label="前罗拉转速">
                                            <el-input v-model="form.front_roller_speed" disabled></el-input>
                                          </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                          <el-form-item label="前罗拉线速">
                                            <el-input v-model="form.front_roller_line_speed" disabled></el-input>
                                          </el-form-item>
                                        </el-col>
                                      </el-row>
                                      <el-row :gutter="4">
                                        <el-col :span="8">
                                          <el-form-item label="锭数">
                                            <el-input v-model="form.machine_spindle_number" disabled></el-input>
                                          </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                          <el-form-item label="锭速">
                                            <el-input v-model="form.spindle_speed" disabled></el-input>
                                          </el-form-item>
                                        </el-col>
                                      </el-row>

                                    </el-form>
                                  </div>
                                  <el-button slot="reference" style="background: #F0F7FF;width: 100%;border:1px solid #4D8AF0;color:#4D8AF0;border-radius: 8px;padding: 12px 0;" icon="iconfont icon-ico-gongyishuxing">工艺参数</el-button>
                                </el-popover>-->
                                </div>

                            </el-col>
                            <el-col :span="12">
                                <div>
                                    <el-button @click="showModel(item.iotStatus,item.id,1)" style="background: #FFF9EF;width: 100%;border:1px solid #E6A23C;color:#E6A23C;border-radius: 8px;padding: 12px 0;" icon="iconfont icon-nenghaocaiji">能耗参数</el-button>
                                    <!--<el-popover placement="bottom"
                                            :disabled="item.iotStatus == 1?false:true"
                                            title="能耗参数"
                                            width="800"
                                            @show="getEnergyForm(item,false)"
                                            trigger="click">
                                    <div class="content_form">
                                        <el-form ref="form" :model="energyForm" label-width="6.25rem">
                                            <el-row :gutter="4">
                                                <el-col :span="8">
                                                    <el-form-item label="累计运行时间">
                                                        <el-input v-model="energyForm.cumulative_running_time" disabled></el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="累计上电时间">
                                                        <el-input v-model="energyForm.accumulated_on_time" disabled></el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="输出频率">
                                                        <el-input v-model="energyForm.output_frequency" disabled></el-input>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                            <el-row :gutter="4">
                                                <el-col :span="8">
                                                    <el-form-item label="输出电流">
                                                        <el-input v-model="energyForm.output_current" disabled></el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="母线电压">
                                                        <el-input v-model="energyForm.bus_voltage" disabled></el-input>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                        </el-form>
                                    </div>
                                    <el-button slot="reference" style="background: #FFF9EF;width: 100%;border:1px solid #E6A23C;color:#E6A23C;border-radius: 8px;padding: 12px 0;" icon="iconfont icon-nenghaocaiji">能耗参数</el-button>
                                </el-popover>-->
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div style="position:fixed;right:15px;bottom:70px;">
                    <div v-if="isTop" @click="toTop" style="margin-bottom:10px;cursor:pointer;width:50px;border-radius:50%;height:50px;background:#4D8AF0;text-align:center;line-height:50px;">
                        <i class="iconfont icon-zhiding-59" style="font-size:30px !important;color:#fff;margin:0;width:50px;"></i>
                    </div>
                    <div v-if="isPre" @click="preList" style="margin-bottom:10px;cursor:pointer;width:50px;border-radius:50%;height:50px;background:#4D8AF0;text-align:center;line-height:50px;">
                        <i class="iconfont icon-shang25" style="font-size:30px !important;color:#fff;margin:-7px 0 0;width:50px;"></i>
                    </div>
                    <div v-if="isNex" @click="nextList" style="margin-bottom:10px;cursor:pointer;width:50px;border-radius:50%;height:50px;background:#4D8AF0;text-align:center;line-height:50px;">
                        <i class="iconfont icon-xia852" style="font-size:30px !important;color:#fff;margin:0;width:50px;"></i>
                    </div>
                </div>
            </draggable>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapState } from "vuex";
    import { fullscreenToggel, listenfullscreen } from "@/util/util";
    import { getModel} from "@/api/devFile"
import draggable from "vuedraggable";

export default {
  data() {
        return {
            activeName: "工艺参数",
            codeName: {
                modelName: "",
                code: "",
                spinCount: "",
                spinUsed: "",
                fact: {
                    batchName: "",
                    productName:"",
                    specSheetCode:""
                }
            },
            highList: [],
            energyList:[],
            nhcsList: [],
            totVal: "",
            energyVal: {},
            spindleSpeedVal:"",
            codeModel: "",
            nameModel:"",
            dialogTableVisible: false,
            dialogFormVisible:false,
            scH: window.screen.height,
            isTop: false,
            isPre: false,
            isNex: true,
            currentId: 0,
            bigHeight: "",
            gdgd:"",
            scHei: {
                height: "",
                top:0,
            },
            outHei: {
                height: "",
                top:0,
            },
            itemHeight: {
                "height": "",
                "background": "#fff",
                "box-shadow": "0px 4px 12px 0px rgba(11, 51, 117, 0.1)",
                "margin": "0 7.5px 15px",
                "width": "calc(25% - 15px)",
                "box-sizing": "border-box",
                "border-radius": "8px",
                "display": "flex",
                "flex-wrap": "wrap"
            },
            iconStatus: {
                1: {'color': '#4D8AF0'},
                2: {'color': '#fff'},
                0: {'color': '#fff'},
            },
            textStatus: {
                1: { 'color': '#333' },
                2: { 'color': '#fff' },
                0: { 'color': '#fff' },
            },
            topTitle: {
                1: { 'background': '#fff' },
                2: { 'background': '#FF5A52' },
                0: { 'background': '#FFA600' },
            },
      //编号
      machineCodeoptions: [],
      //车间
      departmentoptions: [],
      //机型
      machineoptions: [],
      formInline: {
        departmentId:'',
        machineModelId:'',
        code:''
      },
      list: [],
      form:{
        name:'换卡率',
        fixed_length:'',
        twist_number:'',
        drafting_multiple:'',
        front_roller_diameter:'',
        front_roller_speed:'',
        front_roller_line_speed:'',
        spindle_speed:'',
        machine_spindle_number:'',
      },
      energyForm:{
        cumulative_running_time:'',
        accumulated_on_time:'',
        output_frequency:'',
        output_current:'',
        bus_voltage:'',
      }
    }
  },
  created() {
    this.getEquipmentModel()
    this.getWrokCenterCenter()
    this.getCode()
      this.getList();
      
        },
        computed: {
            ...mapState({
                showDebug: state => state.common.showDebug,
                showColor: state => state.common.showColor,
                showTheme: state => state.common.showTheme,
                showLock: state => state.common.showLock,
                showFullScren: state => state.common.showFullScren,
                showCollapse: state => state.common.showCollapse,
                showSearch: state => state.common.showSearch,
                showMenu: state => state.common.showMenu
            }),
            ...mapGetters([
                "userInfo",
                "isFullScren",
                "tagWel",
                "tagList",
                "isCollapse",
                "tag",
                "logsLen",
                "logsFlag"
            ])
        },
        mounted() {
            let scH = window.screen.height;
            listenfullscreen(this.getHeight);
            this.getHeight();
            document.querySelector(".scDiv").addEventListener('scroll', this.debounce(this.handleScroll, 300), true) || document.querySelector(".scDiv").addEventListener("DOMMouseScroll", this.debounce(this.handleScroll, 300), false);
        },
  components: {draggable},
        methods: {
            //时级报表
            goHoursR() {
                this.$router.push({ path: '/searchDashboard/formStyle', query: { type: 'hours' } })
            },
            //天级报表
            goDayR() {
                this.$router.push({ path: '/searchDashboard/formStyle', query: { type: 'day' } })
            },
            getHeight(h) {
                //let hei;
                //let scH = window.screen.height;
                let docH = document.getElementById("leftHeight").clientHeight;
                console.log(this.scH, docH)
                if (this.isFullScren) {
                    let hei = this.scH - 70;
                    this.bigHeight = hei;
                    this.itemHeight.height = (hei / 3 - 17.5) + "px";
                    this.outHei.height = hei + "px";
                    this.outHei.top = "70px";
                } else {
                    let hei = docH - 70;
                    this.bigHeight = hei;
                    this.itemHeight.height = (hei / 3 - 27.5) + "px";
                    this.outHei.height = hei - 27.5 + "px";
                    this.outHei.top = "0";
                }
            },
            sbcs(e) {
                if (e === "工艺参数") {
                    document.querySelector("#gycsDiv").style.display = "flex";
                    document.querySelector("#nhcsDiv").style.display = "none";
                } else {
                    document.querySelector("#gycsDiv").style.display = "none";
                    document.querySelector("#nhcsDiv").style.display = "flex";
                }
            },
            showModel(iotStatus,id,i) {
                if (iotStatus != 1) {
                    this.$message.warning('暂无参数');
                    return false;
                } else {
                    this.dialogTableVisible = true;
                    if (i === 2) {
                        this.activeName = "工艺参数";
                        setTimeout(function () {
                            document.querySelector("#gycsDiv").style.display = "flex";
                            document.querySelector("#nhcsDiv").style.display = "none";
                        }, 200)
                    } else {
                        this.activeName = "能耗参数";
                        setTimeout(function () {
                            document.querySelector("#gycsDiv").style.display = "none";
                            document.querySelector("#nhcsDiv").style.display = "flex";
                        }, 200)
                    }
                    getModel(id).then(res => {
                        console.log(res, 'model')
                        this.nameModel = res.data.data.name;
                        this.codeModel = res.data.data.code;
                        this.codeName = res.data.data;
                        this.codeName.fact.specSheetCode = res.data.data.fact.specSheetCode;
                        this.totVal = res.data.data.machineParamResponse.param;
                        this.energyVal = res.data.data.machineParamResponse.energyResponse;
                        //this.spindleSpeedVal = res.data.data.machineParamResponse.spindleSpeedList;
                        this.getdiansu();
                    })
                }
            },
            async getdiansu() {
                var chartDom = document.getElementById('dsqx');
                var myChart = this.$echarts.init(chartDom);
                var option;
                var that = this;
                console.log(this.highVal, this.energyVal,'ok')
                option = {
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        show: false,
                        data: ['高度/车速'],
                    },
                    grid: {
                        left: '3%',
                        right: '6%',
                        bottom: '3%',
                        top: '13%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            name:"高度",
                            type: 'category',
                            data: this.totVal.map(function (item) {
                                return item.highParam.value;
                            }),
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: '#ACACAC',
                                }
                            },
                        }
                    ],
                    yAxis: [
                        {
                            name:"车速",
                            type: 'value',
                            //data: this.totVal.map(function (item) {
                            //    return item.spindleSpeedParam.value;
                            //}),
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: '#ACACAC',
                                }
                            },
                            min: 0,
                            max: 100,
                            maxInterval: 10
                        }
                    ],
                    series: [
                        {
                            name: '车速',
                            type: 'line',
                            stack: '总量',
                            data: this.totVal.map(function (item) {
                                return Number(item.spindleSpeedParam.value).toFixed(1);
                            })
                        }
                    ]
                };
                myChart.setOption(option);
                window.addEventListener("resize", () => { myChart.resize(); });
            },
    onSearch() {
      this.getList();
    },
    async getList() {
      const  res = await this.request.post(this.proApis.QUERYMACHINE,this.formInline)
      // this.machineoptions = res.data.data
        this.list = res.data.data;
        let pageSize = Math.ceil(this.list.length / 12);
        this.scHei.height = (this.bigHeight - 27.5) * pageSize + "px";
        this.gdgd = this.bigHeight - 27.5;
    },
    // 车间选择
    async getWrokCenterCenter() {
      const res = await this.request.post(this.proApis.DEPARTMENT,{
        type:1
      })
      this.departmentoptions = res.data.data.items
      console.log(res)
    },
    // 编号
    async getCode() {
      const  res = await this.request.post(this.proApis.SELECTMACHINEBYGORP,{})
      this.machineCodeoptions = res.data.data.items
      console.log(res)
    },
    //  设备机型
    async getEquipmentModel() {
      const  res = await this.request.post(this.proApis.MACHINEMODELSELECTALL)
      this.machineoptions = res.data
      console.log(res)
    },
    // 获取工艺参数
    async getProcessParameters(item,flag){
      console.log(item,flag,'item,flag')
      if(item.iotStatus == 1){
        console.log(item,'item')
        const  res = await this.request.get(this.proApis.MACHINECOLLECTDATASELECTBYMACHINEID+'?machineId='+item.id)
        console.log(res)
        this.form = res.data
      } else  {
        this.$message.warning('暂无参数')
      }

    },
    prompt(iotStatus){
      if(iotStatus != 1) {
        this.$message.warning('暂无参数')
      }
    },
    // 获取能耗参数
    async getEnergyForm(item){
        if(item.iotStatus == 1 ){
            console.log(item,'item')
            const res = await this.request.get(this.proApis.MACHINEENERGYSELECTBYMACHINEID+'?machineId='+item.id)
            console.log(res,'2')
            this.energyForm = res.data
        }else {
            this.$message.warning('暂无参数')
        }

    },
            nextList() {
                let setHei = Number(this.outHei.height.split("px")[0]);
                let othHei = -setHei;
                if (this.currentId >= parseInt(this.list.length / 12)) {
                    this.$message({
                        type: "error",
                        message: "已经是最后一页啦~"
                    });
                    this.currentId = parseInt(this.list.length / 12);
                } else {
                    this.currentId++;
                    this.isTop = true;
                    this.isPre = true;
                    this.scHei.top = (this.currentId * othHei) + 7.5 + "px";
                }
                console.log(this.currentId)
            },
            preList() {
                let setHei = Number(this.outHei.height.split("px")[0]);
                let othHei = -setHei;
                if (this.currentId <=1) {
                    this.isTop = false;
                    this.isPre = false;
                    this.$message({
                        type: "error",
                        message: "已经是第一页啦~"
                    })
                    this.scHei.top = 0;
                    this.currentId = 0
                } else {
                    this.currentId--;
                    this.isTop = true;
                    this.isPre = true;
                    this.scHei.top = (this.currentId * othHei) + 7.5 + "px";
                }
                console.log(this.currentId)
            },
            toTop() {
                this.isTop = false;
                this.isPre = false;
                this.$message({
                    type: "success",
                    message: "已经到第一页啦~"
                })
                this.scHei.top = 0;
                this.currentId = 0
            },
    //防抖
            debounce(func, wait) {
                let timeout;
                return function () {
                    let context = this;
                    let args = arguments;
                    if (timeout) clearTimeout(timeout);
                    timeout = setTimeout(() => {
                        func.apply(context, args)
                    }, wait);
                }
            },
            //判断滚动方向
            handleScroll(e) {
                console.log(this.$refs)
                let direction = e.deltaY > 0 ? 'down' : 'up';
                let setHei = Number(this.outHei.height.split("px")[0])-200;
                let othHei = -setHei;
                console.log(this.currentId)
                if (this.currentId <= 0) {
                    if (direction == 'down') {
                        this.currentId++;
                        this.isTop = true;
                        this.isPre = true;
                        this.scHei.top = (this.currentId * othHei) + "px";
                    } else {
                        this.isTop = false;
                        this.isPre = false;
                        this.$message({
                            type: "error",
                            message: "已经是第一页啦~"
                        })
                        this.scHei.top = 0;
                        this.currentId = 0
                    }
                } else if (this.currentId >= parseInt(this.list.length / 12)) {
                    if (direction == 'down') {
                        this.$message({
                            type: "error",
                            message: "已经是最后一页啦~"
                        });
                        this.currentId = parseInt(this.list.length / 12);
                    } else {
                        this.currentId--
                        //this.isTop = true;
                        //this.isPre = true;
                        this.scHei.top = (this.currentId * setHei) + "px";
                    }
                } else {
                    if (direction == 'down') {
                        this.currentId++;
                        this.scHei.top = (this.currentId * othHei) + "px";
                    } else {
                        this.currentId--;
                        this.scHei.top = (this.currentId * setHei) + "px";
                    }
                }
                //if (direction == 'down') {
                    
                //    this.currentId = this.currentId + 1;
                //} else if (direction == 'up') {

                //}
                //业务操作
                //this.changeSel(this.currentId);
            }
  }
}
</script>
<style lang="scss">
.wklnChange {
  background-color: #fff;

  .header_con {
    display: flex;
    padding: 0.625rem 1.25rem;
    margin-bottom: 0.625rem;
  }

  .container_title {
    font-size: 1.4375rem;
    font-weight: 700;
  }

  .item_color {
    float: left;
  }

  .status_tips {
    width: 50%;
    height: 1.9375rem;
    line-height: 1.9375rem;
    margin-left: 2.5rem;

  }

  .content {
    display: flex;
    justify-content: center;

    .item {
      width: 25%;
      height: auto;
      background: rgba(242, 242, 242, 1);
      float: left;
      margin: 1.5625rem;

      .detail_info {
        width: 100%;
      }

      .name {
        font-size: 20px;
        width: 100%;
        text-align: center;
        font-weight: 500;
        margin-bottom: 0.5rem;
        line-height:30px;
      }

      .item_name {
        font-weight: 700;
        height: 2.5rem;
        line-height: 2.5rem;
      }

      /deep/ .el-radio-button {
        margin-right: 0.8125rem;
      }

      /deep/ .el-radio-button__inner {
        border-radius: 0.25rem;
        width: 6.125rem;
      }
    }

    .operate {
      .el-button {
        width: 100%;
      }
    }
  }
  .content_form {

  }
}
    .sbCon .name .icon-biaotibiao {
        font-size: 41px !important;
        text-align: left !important;
        position:absolute;
        top:0;
        left:-15px;
    }
</style>