import request from '@/router/axios';
import {businessUrl} from "@/config/env"
export const getTree = () => request({
    url: businessUrl + "",
    method: "post",
    data: {
        baseSpecSheetId: ""
    }
})
export const list = () => request({
    url: businessUrl + "machine/queryMachine",
    method: "post",
    data: {
    }
})
export const add = (row,parentId) => request({
    url: businessUrl + "productCategory/insert",
    method: "post",
    data: {
        ...row,
        parentId
    }
})
export const remove = (id) => {
    return request({
    url: businessUrl + "specSheetMachinePlan/delPlan",
    method: "delete",
    params: {
        id
    }
    })
}
export const edit = (row) => request({
    url: businessUrl + "productCategory/update",
    method: "put",
    data: {
        ...row
    }
})
//根据岗位/机器编码获取人员
export const getpepoelChange = (cod,postId) => request({
    url: businessUrl + "machine/selectNameByUserChange",
    method: "post",
    data: {
        cod,postId,time: "2021-1-24"
    }
})
//根据班次获取班组和员工
export const getClassAndPepole = (suShiftId,code,postId) => request({
    url: businessUrl + "machine/selectOneByUserChange",
    method: "post",
    data: {
        suShiftId,code,postId,time: "2021-1-24"
    }
})
//条件查询机台
export const getSearchList = (openTime,machineModelId,productId,departmentId,machineId,batchCode) => request({
    url: businessUrl + "machine/queryMachine",
    method: "post",
    data: {
        openTime,machineModelId,productId,departmentId,machineId,batchCode
    }
})
//产品品种查询批次
// export const getBatchByproduct = (id) => request({
//     url: businessUrl + "productBatch/selectBatch?productId=" + id,
//     method: "get"
// })
//运转工艺参数
export const getpoccess = (id) => request({
    url: businessUrl + "specSheet/selectById?id=" + id,
    method: "get"
})
//点击设备工艺
export const getsbgy = (id) => request({
    url: businessUrl + "machineSpecParam/selectByMachineId?machineId=" + id,
    method: "get"
})
//设备工艺参数
export const get = (id) => request({
    url: businessUrl + "specSheet/selectById?id=" + id,
    method: "get"
})
//获取翻改记录
export const getLookFackAndPlan = (machineId) => request({
    url: businessUrl + "specSheetMachinePlan/selectPlanSheet?machineId=" + machineId,
    method: "get"
})
//现有品种
export const getNowProduct = (id) => request({
    url: businessUrl + "specSheetMachineBase/selectBaseSheet?machineId=" + id,
    method: "get"
})
//查询实际工艺参数
export const getNowWork = (id) => request({
    url: businessUrl + "specSheetParam/queryParam?sheetId=" + id,
    method: "get"
})
//保存机台翻改
// export const saveWorkNo = (data,nextform,endform,id,nextformFalse,endformFalse) => {
//     console.log(nextform,endform,nextformFalse,endformFalse);
//     let nextformAssign = Object.assign(nextform,nextformFalse);
//     let endformAssign = Object.assign(endform,endformFalse);
//     for (let index = 0; index < nextform.length; index++) {

//     }
//     let specSheetParamDtoList = [];
//     delete nextformAssign.datetimerange;
//     for(let value in nextformAssign) {
//         specSheetParamDtoList.push({
//             "specParamId": value,
//             "value": nextformAssign[value]
//         })
//     }
//     return request({
//         url: businessUrl + "specSheet/insertPlanSheet",
//         method: "post",
//         data: {
//             id:data.productNameId,//品种工艺单id
//             batchCode:data.batchCode,//批次
//             machineId:id,//机台
//             planStartTime:data.planStartTime,//开始时间
//             planEndTime:data.planEndTime,//结束时间
//             planYield:data.planYield,//产量
//             specSheetParamDtoList//工艺单
//         }
//     })
// }
//保存工艺计划翻改（未修改工艺参数）
export const saveWorkNext = (data) => request({
    url: businessUrl + "specSheetMachinePlan/insert",
    method: "post",
    data: {
        id:data.productName,//品种工艺单id
        batchCode:data.batchCode,//批次
        machineId:id,//机台
        planStartTime:data.planStartTime,//开始时间
        planEndTime:data.planEndTime,//结束时间
        planYield:data.planYield,//产量
        processId:data.processId,//工序
        productId:data.productId//物料
    }
})
//批量翻改
export const saveWorkNoAll = (data,arrayList,processId,productId,workId) => request({
    url: businessUrl + "specSheetMachinePlan/batchInsertPlan",
    method: "post",
    data: {
        specSheetId:workId,//品种工艺单id
        batchCode:data.batchCode,//批次
        machineIds:arrayList,//机台
        planStartTime:data.planStartTime,//开始时间
        planEndTime:data.planEndTime,//结束时间
        planYield:data.planYield,//产量
        processId:processId,//工序
        productId:productId//物料
    }
})
//获取多个品种
export const getAllquerySheetByIds = (machineIds) => request({
    url: businessUrl + "specSheetMachineBase/queryBaseByMachineIds",
    headers: {
        "Content-Type" : "application/json"
    },
    method: "post",
    data: JSON.stringify(machineIds) 
})
//通过品种id和当前机台id获取工艺参数
export const getWorkIdAndProductId = (productId,machineId) => request({
    url: businessUrl + "specSheet/querySheet",
    method: "get",
    params: {
        productId,machineId
    }
})
export const sumbitChangeFirst =(row,machineIds,specSheetParamDtoList,id) => {
    return request({
        url : businessUrl + "specSheet/update",
        method: "put",
        data : {
            ...row,
            machineIds,specSheetParamDtoList,id
        }
    })
}
// 获取车间
export const getChejian = (type) => request({
    url: businessUrl + "department/selectWorkShopName",
    method: "get",
    params: {
        type: type
    }
})
export const getBatchNumber = (id) => request({
    url: businessUrl + "productBatch/selectBatch",
    method: "post",
    data: {
       productId: id
    }
})
// 获取计划工艺单
export const getPlanSheet = (id) => request({
    url: businessUrl + "specSheetMachinePlan/selectPlan",
    method: "get",
    params: {
        machineId: id
    }
})
// 计划工艺单的参数
export const getPlanSheetNumber = (id) => request({
    url: businessUrl + "specSheetParam/selectParamBySheetId",
    method: "get",
    params: {
        sheetId: id
    }
})
//翻改后的
export const getPlanSheetStatus = (status) => request({
    url: businessUrl + "specParam/selectByStatus",
    method: "get",
    params: {
        status:status
    }
})
//保存机台翻改
export const saveWorkNo = (obj) => request({
        url: businessUrl + "specSheet/insertPlanSheet",
        method: "post",
        data: {
           ...obj
        }
    })