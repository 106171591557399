<template>
    <div id="home_container"   style="background:transparent;position:relative;top:70px;left:0;"  class="wklnChange">
    <!--  新的翻改 -->
    <el-dialog
      title="机台翻改"
      style="marginTop: -100px;height:1400px;"
      :visible.sync="dialogVisible"
      width="70%">
        <header style="height: 100%;overflow: auto;">
        <el-form ref="form" :model="goForm" label-width="120px">
            <avue-crud :option="tableOption"
                 style="marginTop: -50px;display: block;overflow: hidden;height: 270px;overflow: scroll !important;"
                 @current-row-change="handleCurrentRowChange"
                 :data="planData"
                 v-model="planModel"
                 >
      </avue-crud>
      <div id="yunz" style="display:flex;">
                <header style="width:25%;fontWeight: 700;">
                    <h3 style="height: 40px;background-color: #ecf5ff;line-height: 40px;text-align: center;">翻改前</h3>
                </header>
                 <header style="width:25%;fontWeight: 700;">
                     <h3 style="height: 40px;background-color: #B1D6FF;line-height: 40px;text-align: center;">翻改工艺</h3>
                </header>
                 <header style="width:25%;fontWeight: 700;">
                     <h3 style="height: 40px;background-color: #B1D6FF;line-height: 40px;text-align: center;">翻改后</h3>
                </header>
                 <header style="width:25%;fontWeight: 700;">
                     <h3 style="height: 40px;background-color: #B1D6FF;line-height: 40px;text-align: center;">对比差值</h3>
                </header>
            </div>
            <div id="yunz" style="display:flex; overflow: hidden;height: 310px;overflow: scroll !important;">
                <header style="width:25%;fontWeight: 700;">
                    <avue-form style="marginTop:20px;" v-model="lastform" :option="optionBefore"></avue-form>
                </header>
                 <header style="width:25%;fontWeight: 700;">
                    <avue-form style="marginTop:20px;" v-model="nextform" :option="optionNo"></avue-form>
                </header>
                 <header style="width:25%;fontWeight: 700;">
                    <avue-form style="marginTop:20px;" v-model="afterform" :option="optionAfter"></avue-form>
                </header>
                 <header style="width:25%;fontWeight: 700;">
                    <avue-form style="marginTop:20px;" class="difference" v-model="differenceform" :option="optionDifference"></avue-form>
                </header>
            </div>
        </el-form>
        </header>
        <p slot="footer" class="dialog-footer" style="margin-top: -30px;">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirm">确 定</el-button>
        </p>
    </el-dialog>
        <div class="outFormDiv" style="display: flex; padding: 15px; background: #F2F2F2; position: fixed; top: 100px; right: 0; width: calc(100% - 13.75rem); z-index: 1;box-sizing: border-box;">
            <div class="formList" v-if="topIsMenu">
                <el-form :inline="true" :model="formInline" class="demo-form-inline" labelWidth="80px" style="display:flex;">
                    <el-form-item  style="width:calc(33.33% - 23.33px);display:flex;">
                        <el-date-picker clearable
                                        v-model="timeSelect"
                                        value-format="yyyy-MM-dd"
                                        type="date"
                                        placeholder="开台时间" style="width:100%;">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item  style="width:calc(33.33% - 23.33px);display:flex;">
                        <el-select clearable @change="workChange" v-model="machineModelId" placeholder="设备机型">
                            <el-option v-for="item in machineoptions"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item  style="width:calc(33.33% - 23.33px);display:flex;">
                        <el-select clearable v-model="productId" placeholder="产品品种" @change="productChange">
                            <el-option v-for="item in productoptions"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item  style="width:calc(33.33% - 23.33px);display:flex;">
                        <el-select clearable v-model="workshopId" placeholder="车间选择" @change="getChenJianId">
                            <el-option v-for="item in chejianList"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item  style="width:calc(33.33% - 23.33px);display:flex;">
                        <el-select clearable v-model="machineId" placeholder="设备编号">
                            <el-option v-for="item in machineCodeoptions"
                                       :key="item.id"
                                       :label="item.code"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item  style="width:calc(33.33% - 23.33px);display:flex;">
                        <el-select  clearable v-model="batchCode" placeholder="产品批次">
                            <el-option v-for="item in productBatchOptions"
                                       :key="item.id"
                                       :label="item.batchCode"
                                       :value="item.batchCode">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="btn" v-if="topIsMenu" style="width:240px;text-align:right;display:flex;align-items: start;">
                <el-button type="primary" size="medium" @click="onSearch" style="margin-right:5px;margin-top:2px;">{{$t("search")}}</el-button>
            </div>
            <div v-if="topIsCheMenu" style="width:100%;text-align:right;">
            </div>
        </div>
        <div class="gyfg" style="display: flex;justify-content: center;width: 100%;position:relative;padding:7.5px;box-sizing:border-box;" :style="outHei">
            <draggable style="position: absolute; top: 0; left: 0; width: 100%; margin-top: 7.5px; display: flex; flex-wrap: wrap; padding:0 7.5px;box-sizing:border-box;" :style="scHei">
                <!-- <div style="display: block;height: 19px;"><el-checkbox type="primary" @change="onAll" style="float: left;marginLeft: 25px;">全选</el-checkbox></div> -->
                <el-checkbox-group v-model="checkboxList" style="width: 100%;display: flex;flex-wrap: wrap;">
                    <el-checkbox-button v-for="(item,indexCod) in list" style="padding:0;" :style="itemHeight" class="fontCss" :label="item.id" :key="item.id" :disabled="isCheck">
                            <div style="display:flex;justify-content:space-between;padding:0 7.5px;height:50px;line-height:50px;border-radius:8px 8px 0 0;width:100%;box-sizing:border-box;" :style="topTitle[item.openStatus]" class="name">
                                <div style="position:relative;padding-left:15px;" :style="textStatus[item.openStatus]">
                                    <i class="iconfont icon-biaotibiao" :style="iconStatus[item.openStatus]"></i>{{ item.code }}

                                </div>
                                <div>
                                    <div v-if="item.openStatus == 2" class="item_color" style="color:#42B983;font-size:14px;">
                                        <i class="iconfont icon-shengchandan" style="vertical-align: 0; font-size:15px !important;"></i>生产中
                                    </div>
                                    <div v-if="item.openStatus == 0" class="item_color" style="color:#fff;font-size:14px;">
                                        <i class="iconfont icon-guanji" style="vertical-align: 0; font-size:15px !important;"></i>待开始
                                    </div>
                                    <div v-else-if="item.faultStatus == 1" class="item_color" style="color:#fff;font-size:14px;">
                                        <i class="iconfont icon-jinggao" style="vertical-align: 0; font-size:15px !important;"></i>待维修
                                    </div>
                                    <!--<el-checkbox @change="isIndeterminateChange" class="boxStyle" style="float: right;" v-model="checkboxList" :label="item.id" size="medium">{{""}}</el-checkbox>-->
                                </div>
                            </div>
                            <div style="padding: 0 7.5px;height:calc(100% - 128px);display:flex;flex-wrap:wrap;align-items:center;width:100%;box-sizing:border-box;text-align:left;">
                                <div style="width:100%;display:flex;align-items:center;"><i class="iconfont icon-pinzhong12" style="vertical-align: 0; font-size:16px !important;color:#CAD9EF;margin-right:10px;"></i>产品品种：<span style="font-size:14px;color:#aaa;display:inline-block;width:calc(100% - 115px);overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{item.fact.productName}}</span></div>
                                <div style="width:100%;display:flex;align-items:center;"><i class="iconfont icon-mingxi" style="vertical-align: 0; font-size:16px !important;color:#CAD9EF;margin-right:10px;"></i>产品批次：<span style="font-size:14px;color:#aaa;display:inline-block;width:calc(100% - 115px);overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{item.fact.batchName}}</span></div>
                            </div>
                            <div  style="display:flex;padding:0 7.5px;justify-content:space-between;align-items:center;width:100%;height:50px;box-sizing:border-box;">
                                <div style="width:calc(33% - 23.75px);">
                                    <el-popover placement="bottom"
                                                title="参数详情"
                                                width="400"
                                                trigger="click"
                                                content="暂无数据" :append-to-body="false">
                                        <header style="dispaly: block;lineHeight: 50px;" v-for="val in formData">{{val.name}}: <el-button style="width: 70%;float: right;">{{val.specParamValue}}</el-button></header>
                                        <el-button slot="reference" :value="1" style="background: #F0F7FF;width: 100%;border:1px solid #4D8AF0;color:#4D8AF0;border-radius: 8px;padding: 12px 0;" icon="iconfont icon-ico-gongyishuxing" @click="getpoccessClick(item.fact.specSheetId)">运转工艺</el-button>
                                    </el-popover>
                                </div>
                                <div style="width: calc(33% - 23.75px);">
                                    <el-popover placement="bottom"
                                                title="参数详情"
                                                width="400"
                                                trigger="click"
                                                content="暂无数据" :append-to-body="false">
                                        <header style="dispaly: block;lineHeight: 50px;" v-for="val in sbgy">{{val.name}}: <el-button style="width: 70%;float: right;">{{val.specParamValue}}</el-button></header>
                                        <el-button slot="reference" :value="2" style="background: #F0F7FF;width: 100%;border:1px solid #4D8AF0;color:#4D8AF0;border-radius: 8px;padding: 12px 0;" icon="iconfont icon-nenghaocaiji" @click="getsbgyClick(item.fact.specSheetId)">设备工艺</el-button>
                                    </el-popover>
                                </div>
                                 <div style="width: calc(33% - 23.75px);">
                                        <el-button slot="reference" :value="2" style="background: #FFF9EF;width: 100%;border:1px solid #E6A23C;color:#E6A23C;border-radius: 8px;padding: 12px 0;" icon="iconfont icon-nenghaocaiji" @click="fangai(item)">机台翻改</el-button>
                                </div>
                            </div>
                    </el-checkbox-button>
                </el-checkbox-group>
                <div style="position:fixed;right:15px;bottom:70px;">
                    <div v-if="isTop" @click="toTop" style="margin-bottom:10px;cursor:pointer;width:50px;border-radius:50%;height:50px;background:#4D8AF0;text-align:center;line-height:50px;">
                        <i class="iconfont icon-zhiding-59" style="font-size:30px !important;color:#fff;margin:0;width:50px;"></i>
                    </div>
                    <div v-if="isPre" @click="preList" style="margin-bottom:10px;cursor:pointer;width:50px;border-radius:50%;height:50px;background:#4D8AF0;text-align:center;line-height:50px;">
                        <i class="iconfont icon-shang25" style="font-size:30px !important;color:#fff;margin:-7px 0 0;width:50px;"></i>
                    </div>
                    <div v-if="isNex" @click="nextList" style="margin-bottom:10px;cursor:pointer;width:50px;border-radius:50%;height:50px;background:#4D8AF0;text-align:center;line-height:50px;">
                        <i class="iconfont icon-xia852" style="font-size:30px !important;color:#fff;margin:0;width:50px;"></i>
                    </div>
                </div>
            </draggable>
        </div>
    </div>
</template> 
<script>
    import { mapGetters, mapState } from "vuex";
    import { fullscreenToggel, listenfullscreen } from "@/util/util";
    import draggable from "vuedraggable";
    import { list, getpepoelChange, getClassAndPepole, getSearchList, getBatchByproduct, getpoccess, getLookFackAndPlan, remove, getNowProduct, getNowWork, saveWorkNo, saveWorkNoAll, getAllquerySheetByIds, getWorkIdAndProductId, sumbitChangeFirst, getsbgy,getChejian,getBatchNumber,getPlanSheet,getPlanSheetNumber,getPlanSheetStatus} from "@/api/wklnChange";
    import {machineModelList,productList,departmentList,machineList,productBatchList,getpoccessByproduct,getMtrType,getPerson,wklnList,getwklnWorkBywkln,getmachineByMachineModelId} from "@/api/config";
    export default {
        data() {
            var validatePass = (rule, value, callback)=>{
                    if (value === '') {
                        callback(new Error('请输入翻改后产品产量'));
                    } else {
                        callback();
                    }
                };
                var validatePass2 = (rule, value, callback)=>  {
                    if (value === '') {
                        callback(new Error('请输入预计开台时间'));
                    } else {
                        callback();
                    }
                };
                var validatePass3 = (rule, value, callback)=>  {
                    if (value === '') {
                        callback(new Error('请输入预计了机时间'));
                    } else {
                        callback();
                    }
                };
            return {
                //  新的翻改配置
                getMachineId:'', //传参时机台Id
                getProductId: '', //传参时产品编号Id
                fromSpecSheetId:'',//来源工艺单编号
                batchCodeId: '',//传参时批号Id
                planYieldId: '',//传参时产量
                getPlanStartTime: '', //传参时开始时间
                getPlanEndTime: '',//传参时开始时间
                specParamList:[], // 传参数组
                planId:"", //工艺单号ID
                planData: [],
                planModel: {},
                tableOption:{
                    delBtn: true,
                    addBtn:false,
                    refreshBtn:false,
                    highlightCurrentRow:true,
                    menuWidth:200,
                    menu:false,
                    selection: false,
                    tip: false,
                    columnBtn : false,
                    labelWidth: "120",
                    emptyText: this.$t('No Data'),
                    menuTitle: this.$t('OPERATION'),
                column:[{
                    label: "工艺单号",
                    prop: "specSheetCode"
                },{
                    label: "状态",
                    prop: "runningStatus",
                    slot: true,
                    dicData: [{
                    label: "运行中",
                    value: 1
                    }]
                },{
                    label: "品种",
                    prop: "productName"
                },{
                    label: "批次号",
                    prop: "batchName"
                },{
                    label: "计划产量(kg)",
                    prop: "planYield"
                },{
                    label: "计划开台时间",
                    prop: "planStartTime"
                },{
                    label: "计划了机时间",
                    prop: "planEndTime"
                },{
                    label: "实际开台时间",
                    prop: "factStartTime",
                }]
                },
                isIndeterminate: true,
                tableDataChange:[],
                formatParamType: [],
                isCheck: true,
                topIsMenu: true,
                topIsCheMenu:false,
                scH: window.screen.height,
                isTop: false,
                isPre: false,
                isNex: true,
                currentId: 0,
                scHei: {
                    height: "",
                    top: 0,
                },
                outHei: {
                    height: "",
                    top:0,
                },
                iconStatus: {
                    2: { 'color': '#42B983' },
                    1: { 'color': '#fff' },
                    0: { 'color': '#fff' },
                },
                topTitle: {
                    2: { 'background': '#fff' },
                    1: { 'background': '#F56C6C' },
                    0: { 'background': '#4D8AF0' },
                },
                textStatus: {
                    2: { 'color': '#333' },
                    1: { 'color': '#fff' },
                    0: { 'color': '#fff' },
                },
                itemHeight: {
                    "height": "",
                    "background": "#fff",
                    "box-shadow": "0px 4px 12px 0px rgba(11, 51, 117, 0.1)",
                    "margin": "0 7.5px 15px",
                    "width": "calc(25% - 15px)",
                    "box-sizing": "border-box",
                    "border-radius": "8px",
                    "display": "flex",
                    "flex-wrap": "wrap"
                },
                dialogFull: true,
                onOpenVistable: false,
                //待展开的翻改后参数偏离值
                leftListChangeFlase: [],
                //待展开的翻改后参数
                leftListChange: [],
                //待展开的翻改直径
                openMathList: [],
                //待展开的参数
                openList: [],
                //是否展开
                isOpen: false,
                nextformFalse: {},
                endformFalse: {},
                isLoading: true,
                //是否篡改
                isBooler: false,
                //翻改后的锭数old值(运转工艺参数)
                oldOptionValue: {},
                //翻改后的锭数old值(设备工艺参数)
                oldDeviceValue: {},
                //工艺单id
                workId: "",
                //极速翻改
                openForm: {code:"",batchCode:"",nowBatchCode:"",planYield:"",planEndTime:"",planStartTime:"",planYield:""},
                //批量翻改数据
                allForm: {code:"",batchCode:"",nowBatchCode:"",planYield:"",planEndTime:"",planStartTime:"",planYield:""},
                oldValue: {},
                //当前修改机台id
                id: "",
                //当前物料id
                productId: "",
                //当前工序id
                processId: "",
                Noform: {},
                endform: {},
                //查看的机台翻改记录
                lookId: "",
                //翻改记录
                tableData: [],
                formData: {},
                sbgy: {},
                machineModeId: "",productId: "",departmentId: "",machineId: "",batchCode: "",machineModelId: "",workshopId:'',
                radio1: 1,
                everydialogVisible: false,
                //批次
                productBatchOptions: [],
                //编号
                machineCodeoptions: [],
                //工作中心
                departmentoptions: [],
                // 车间选择
                chejianList:[],
                //品种
                productoptions: [],
                //机型
                machineoptions: [],
                checkboxAll: [],
                //选择
                checkboxList: [],
                //翻改前
                lastform: {},
                // 翻改工艺
                nextform: {productName:"",batchCode:"",planYield:"",planStartTime:"",planEndTime:""},
                //翻改后
                afterform: {},
                //差值
                differenceform: {},
                //翻改机台
                form: {},
                optionNo: {
                    submitBtn: false,
                    emptyBtn: false,
                    labelWidth:130,
                    inline: true,
                    column: [
                    {
                        label: "品种",
                        type: 'select',
                        prop:'productName',
                        dicData: [],
                        rules: [{
                            required: true,
                            message: "请输入翻改后品种",
                            trigger: "blur"
                        }],
                        change: (value)=> {
                            this.getProductId = value.value
                            if(this.getProductId) {
                              getBatchNumber(this.getProductId).then(res => {
                                this.optionNo.column[1].dicData= res.data.data
                              })
                            }
                        },
                        props: {
                            label: "name",
                            value: "id"
                        },
                        span:24,
                        row:false,
                    },
                    {
                        label: "批次号",
                        type: 'select',
                        prop:'batchCode',
                        dicData: [],
                        rules: [{
                            required: true,
                            message: "请输入翻改后品种",
                            trigger: "blur"
                        }],
                         change: async ($event)=> {
                             this.batchCodeId = $event.value;
                        },
                        props: {
                            label: "batchCode",
                            value: "id"
                        },
                        span:24,
                        row:false,
                    },
                    {
                        label: "产品产量(kg)",
                        type: "number",
                        prop:'planYield',
                        rules: [{
                            required: true,
                            message: "请输入翻改后产品产量",
                            trigger: "change"
                        }],
                       change: async ($event)=> {
                             this.planYieldId = $event.value;
                        },
                        span:24,
                        row:false,
                    },
                    {
                        label: "预计开台时间",
                        type: 'datetime',
                        valueFormat: "yyyy-MM-dd HH:mm",
                        format:"yyyy-MM-dd HH:mm",
                        prop:'planStartTime',
                        rules: [{
                            required: true,
                            message: "请输入翻改后品种",
                            trigger: "blur"
                        }],
                        change: async ($event)=> {
                             this.getPlanStartTime = $event.value;
                        },
                        span:24,
                        row:false,
                        startPlaceholder: '时间日期开始范围自定义',
                        endPlaceholder: '时间日期结束范围自定义',
                    },
                    {
                        label: "预计了机时间",
                        valueFormat: "yyyy-MM-dd HH:mm",
                        format:"yyyy-MM-dd HH:mm",
                        type: 'datetime',
                        prop:'planEndTime',
                        rules: [{
                            required: true,
                            message: "请输入预计了机时间",
                            trigger: "blur"
                        }],
                        change: async ($event)=> {
                             this.getPlanEndTime = $event.value;
                        },
                        span:24,
                        row:false,
                        startPlaceholder: '时间日期开始范围自定义',
                        endPlaceholder: '时间日期结束范围自定义',
                    }]
                },
                optionBefore: {
                    submitBtn: false,
                    emptyBtn: false,
                    labelWidth:110,
                    column: []
                },
                optionDifference: {
                    submitBtn: false,
                    emptyBtn: false,
                    labelWidth:110,
                    column: []
                },
                optionAfter: {
                    submitBtn: false,
                    emptyBtn: false,
                    labelWidth:110,
                    column: []
                },
                //翻改data
                goForm: {},
                dialogVisible: false,
                timeSelect: "",
                changeValue: {班次: "",班组: "",人员: ""},
                //机器人员数据
                changeData: {},
                formInline: {},
                list: []
            }
        },
        created() {
            getPlanSheetStatus(0).then(res => {
                this.specParamList = [];
                res.data.data.forEach(item => {
                    let obj = {
                        specParamId: item.id,
                        specParamName:item.name
                    };
                    this.specParamList.push(obj)
                    console.log(this.specParamList);
                })
                console.log('getPlanSheetStatus',res);
                  this.optionAfter.column = [];
                        res.data.data.forEach(v => {
                            this.optionAfter.column.push({
                                label: v.name,
                                prop: v.name,
                                row:true,
                                span: 24,
                                 blur: ()=> {
                                    console.log('标准',this.lastform[v.name]);
                                    console.log('填写',this.afterform[v.name]);
                                    //that.differenceform[v.name] = that.lastform[v.name] - that.setUpform[v.name];
                                    let num = this.lastform[v.name]-0>this.afterform[v.name]-0?(this.lastform[v.name] - this.afterform[v.name]):(this.afterform[v.name] - this.lastform[v.name]);
                                    if (!num){
                                        num = 0
                                    }
                                    this.$set(this.differenceform,v.name,num);
                                    this.$forceUpdate();
                                    console.log('差值',this.differenceform[v.name]);
                                    let element = document.querySelector('.difference')
                                     if(this.differenceform[v.name]) {
                                         element.classList.add('flag')
                                         console.log(1111,element);
                                     }
                                    console.log(this.differenceform);
                                }

                            });
                            //翻改前工艺单的值
                            this.endform[v.name] = v.value;
                            this.$set(this.afterform,v.name,v.value);
                            //that.setUpform[v.name] = v.value;
                            console.log(this.afterform[v.specParamName]);
                        });
                         this.optionDifference.column = [];
                        res.data.data.forEach(v => {
                            this.optionDifference.column.push({
                                disabled: true,
                                label: '',
                                prop: v.name,
                                row:true,
                                span: 24,
                            });
                            this.$set(this.differenceform,v.name,'');
                            this.differenceform[v.name] = '';
                            console.log(this.differenceform);
                            //翻改前工艺单的值
                            // this.differenceform[v.name] = v.value;
                        });
            })
            // 获取车间Id
            getChejian(1).then(res => {
                this.chejianList = res.data.data
            })
            machineModelList().then(res=>{
                console.log('设备机型',res);
                this.machineoptions = res.data
                // this.optionOpen.column[6].dicData=res.data;
            });
            // wklnList().then(res=>{
            //     this.optionOpen.column[5].dicData=res.data;
            // });
            // getPerson().then(res=>{
            //     this.optionOpen.column[4].dicData=res.data.data;
            // });
            // getMtrType().then(res=>{
            //     this.optionOpen.column[0].dicData=res.data.data[0].ChildNodes;
            // });
            productBatchList().then(res => {
                console.log(res);
                this.productBatchOptions = res.data.data.items;
            });
            departmentList().then(res => {
                this.departmentoptions = res.data.data.items;
                console.log('车间',this.departmentoptions);
            });
            productList().then(res => {
                console.log(res);
                this.optionNo.column[0].dicData = res.data.data.items;
                this.productoptions = res.data.data.items;
            });
            list(this.timeSelect).then(res => {
                this.list = res.data.data;
                this.checkboxAll = [];
                res.data.data.forEach((v, i) => {
                    v.fact == null ? v.fact = {productName : ""}:"";
                    this.checkboxAll.push(v.id);
                })
            });  
        },
        components: { draggable },
        computed: {
            ...mapState({
                showDebug: state => state.common.showDebug,
                showColor: state => state.common.showColor,
                showTheme: state => state.common.showTheme,
                showLock: state => state.common.showLock,
                showFullScren: state => state.common.showFullScren,
                showCollapse: state => state.common.showCollapse,
                showSearch: state => state.common.showSearch,
                showMenu: state => state.common.showMenu
            }),
            ...mapGetters([
                "userInfo",
                "isFullScren",
                "tagWel",
                "tagList",
                "isCollapse",
                "tag",
                "logsLen",
                "logsFlag"
            ])
        },
        mounted() {
            listenfullscreen(this.getHeight);
            this.getHeight();
        },
        methods: {
            handleCurrentRowChange(val) {
                console.log(val,'产品');
                // this.getProductId = val.productId
                getPlanSheetNumber(val.specSheetId).then(res => {
                    console.log('参数',res);
                      this.optionBefore.column = [];
                        res.data.data.forEach(v => {
                            this.optionBefore.column.push({
                                disabled: true,
                                label: v.specParamName,
                                prop: v.specParamName,
                                span: 24,
                                row: true
                            });
                    this.$set(this.lastform,v.specParamName,v.value);

                            //翻改前工艺单的值
                            this.lastform[v.specParamName] = v.value;
                        });
                })
            },
            pcChange(val){
                console.log('val',val);
            },
            getChenJianId(val){
               this.departmentId=val
            },
            // 下一页
            nextList() {
                let setHei = Number(this.outHei.height.split("px")[0]);
                let othHei = -setHei;
                if (this.currentId >= parseInt(this.list.length / 12)) {
                    this.$message({
                        type: "error",
                        message: "已经是最后一页啦~"
                    });
                    this.currentId = parseInt(this.list.length / 12);
                } else {
                    this.currentId++;
                    this.isTop = true;
                    this.isPre = true;
                    this.scHei.top = (this.currentId * othHei) + 7.5 + "px";
                }
                console.log(this.currentId)
            },
            //上一页
            preList() {
                let setHei = Number(this.outHei.height.split("px")[0]);
                let othHei = -setHei;
                if (this.currentId <= 1) {
                    this.isTop = false;
                    this.isPre = false;
                    this.$message({
                        type: "error",
                        message: "已经是第一页啦~"
                    })
                    this.scHei.top = 0;
                    this.currentId = 0
                } else {
                    this.currentId--;
                    this.isTop = true;
                    this.isPre = true;
                    this.scHei.top = (this.currentId * othHei) + 7.5 + "px";
                }
                console.log(this.currentId)
            },
            // 到顶部
            toTop() {
                this.isTop = false;
                this.isPre = false;
                this.$message({
                    type: "success",
                    message: "已经到第一页啦~"
                })
                this.scHei.top = 0;
                this.currentId = 0
            },
            // 高度
            getHeight(h) {
                //let hei;
                //let scH = window.screen.height;
                let docH = document.getElementById("leftHeight").clientHeight;
                console.log(this.scH, docH)
                if (this.isFullScren) {
                    let hei = this.scH - 70;
                    this.bigHeight = hei;
                    this.itemHeight.height = (hei / 3 - 17.5) + "px";
                    this.outHei.height = hei + "px";
                    this.outHei.top = "70px";
                } else {
                    let hei = docH - 70;
                    this.bigHeight = hei;
                    this.itemHeight.height = (hei / 3 - 27.5) + "px";
                    this.outHei.height = hei - 27.5 + "px";
                    this.outHei.top = "0";
                }
            },
            //一键翻改
            onOpen() {
              this.onOpenVistable = true;  
            },
            // 去标准工艺单
            looklook() {
                this.$router.push({path: "standardList"});
            },
            // 删除标准工艺单
            removeClick(id) {
                this.$confirm('此操作将删除翻改记录, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.isLoading = true;
                    remove(id).then(() => {
                        this.isLoading = false;
                        this.$message({
                            type: "success",
                            message: "删除成功!"
                        });
                    }).then(() => {
                        this.lookFackAndPlan(this.lookId);
                    });
                }).catch(() => {         
                });   
            },
            //查看机台获取翻改记录
            lookFackAndPlan(id) {
                //先置空翻改记录
                this.tableData = [];
                this.isLoading = true;
                this.lookId = id;
                getLookFackAndPlan(id).then(res => {
                    this.tableData = res.data.data;
                    this.isLoading = false;
                });
            },
            //运转工艺参数
            getpoccessClick(specShheetId) {
                this.formData = [];
                getpoccess(specShheetId).then(res => {
                    console.log(res,"运转工艺参数")
                    this.formData = res.data.data.runSpecSheetParamList;
;
                });
            },
            //点击设备工艺
            getsbgyClick(specShheetId) {
                this.sbgy = [];
                getpoccess(specShheetId).then(res => {
                    console.log(res, "设备工艺参数")
                    this.sbgy = res.data.data.machineSpecSheetParamList ;
                });
            },
            // 产品品种改变
            productChange(value) {
                // 品种改变获取批号
                console.log(value);
            //      getBatchNumber(value).then(res => {
            //          console.log('res',res);
            //     this.productBatchOptions = res.data.data;
            // });
            },
            // 设备机型改变
            workChange(value) {
                //根据机型获取编号
                machineList(value).then(res => {
                    console.log('机型1',res);
                    this.machineCodeoptions = res.data.data;
                });
            },
            //退出机台翻改
            outAll(val) {
                this.isCheck = true;
                this.topIsMenu = true;
                this.topIsCheMenu = false;
                this.checkboxList = [];
            },
            // 搜索
            onSearch() {
                this.getList();
            },
            // 获取数据
            getList() {
                console.log(this.formInline);
                console.log('1车间ID',this.workshopId);
                console.log(this.timeSelect, this.machineModelId, this.productId, this.departmentId, this.machineId, this.batchCode)
                getSearchList(this.timeSelect, this.machineModelId, this.productId, this.departmentId, this.machineId, this.batchCode,this.workshopId).then(res => {
                    this.list = res.data.data;
                    res.data.data.forEach(v => {
                        v.fact == null ? v.fact = {productName : ""}:"";
                    });
                    console.log(this.list);
                });
            },
            async fangai(item) {
                console.log('点击翻改');
                console.log(item);
               this.dialogVisible = true;
               this.getMachineId = item.id
               this.fromSpecSheetId = item.fact.specSheetId
               console.log(this.getMachineId);
                  //当前机器的翻改前数据
                let formList = this.list.find(v => {
                    return v.id == item.id;
                });
                getPlanSheet(item.id).then(res => {
                        this.planData = res.data.data
                        this.$set(item.fact,"runningStatus",1)
                        this.planData.unshift(item.fact)
                        console.log(this.planData);
                        this.planId = this.planData[0].specSheetId
                        let val = {specSheetId:this.planId}
                        this.handleCurrentRowChange(val)
                    })
            },
            confirm() {
                console.log(this.specParamList);
                let specSheetParamDtoList = []
                this.optionAfter.column.forEach(v => {
                    let id = '';
                    this.specParamList.forEach(i => {
                        console.log(i.specParamName,v.prop);
                        if (i.specParamName == v.prop){
                            id = i.specParamId;
                        }
                    })
                            console.log('id',id);
                    let tmp = {
                        specParamId: id,
                        value: this.afterform[v.prop]
                    }
                 specSheetParamDtoList.push(tmp);
                })
                console.log(this.getPlanStartTime,this.getPlanEndTime,this.planYieldId,this.batchCodeId);
                let obj =  {
                    productId:this.getProductId,
                    fromSpecSheetId:this.fromSpecSheetId,
                    specSheetParamDtoList:specSheetParamDtoList,
                    machineId:this.getMachineId,
                    planStartTime:this.getPlanStartTime + ':00',
                    planEndTime:this.getPlanEndTime + ':00',
                    batchCode:this.batchCodeId,
                    planYield:this.planYieldId,
                }
               saveWorkNo(obj).then(res => {
                   if(res.data.code == '0000') {
                    this.$message({
                        type: "success",
                        message: "翻改成功"
                        });
                        this.dialogVisible = false
                   }
                   console.log(res);
               })
               this.getList()
            },
            boxStyle(index) {
                let style = "";
                switch(index) {
                    case 0: style = "background: rgba(94, 94, 94, 1)";break;
                    case 1: style = "background: pink";break;
                    case 2: style = "background: rgba(238, 231, 61, 1)";break;
                    case 3: style = "background: rgba(0, 152, 217, 1)";break;
                    case 4: style = "background: red";break;
                    case 5: style = "background: rgba(0, 192, 111, 1)";break;
                }
                return style;
            }
        }
    }
</script>
<style>
    /* span.el-checkbox__inner {
            visibility: hidden;
        } */
       .difference .el-input.is-disabled .el-input__inner {
             color: #5a9cf8;
        }
        .el-checkbox-button.is-disabled .el-checkbox-button__inner {
            cursor: default;
        }
    .allBtn .el-checkbox-button__inner {
        padding: 12px 20px !important;
        border-radius: 3px !important;
        margin: 0 5px;
        background: #409EFF;
        color: #fff;
    }
    .gyfg .el-checkbox-button__inner {
        padding: 0 !important;
        width: 100%;
        border-radius: 8px 8px 8px 8px !important;
    }
    .gyfg .el-checkbox-button.is-checked .el-checkbox-button__inner {
        background: #fff;
        color: #333;
        /*box-shadow: 0 0 3px 3px rgba(77,138,240,.2) !important;*/
    }
    /*.el-checkbox-button.is-focus .el-checkbox-button__inner {
        background: #fff;
        color: #333;
        border-color:#fff !important;
    }*/
</style>
<style lang="scss" scoped>
    .wklnChange {
        .fontCss {
            * {
                /*font-size: 1vw;*/
            }
        }
        /deep/ .el-dialog {
            height: 730px !important;
        }
        .flag{
            color:red
        }
        .item_color {
                float: left;
            }
            .green {
            background-color: #67C23A;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            position: relative;
            top: 8px;
        }
        .blue {
            background-color: #409EFF;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            position: relative;
            top: 8px;
        }
        .red {
            background-color: red;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            position: relative;
            top: 8px;
        }
        .status_tips {
            width: 30%;
            height: 31px;
            margin-top: 10px;
            line-height: 31px;
            margin-left: 40px;

        }
        .boxStyle {
            float: right;
            position: absolute;
            right: -15px;
            top: 3px;
            transform: scale(1.5);
        }
    .gyfg .name .icon-biaotibiao {
        font-size: 41px !important;
        text-align: left !important;
        position: absolute;
        top: 0;
        left: -15px;
    }
    
    }
    
</style>