var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                "table-loading": _vm.tableLoading,
                page: _vm.page
              },
              on: {
                "refresh-change": _vm.refreshChange,
                "selection-change": _vm.selectionChange,
                "size-change": _vm.sizeChange,
                "current-change": _vm.currentChange
              },
              scopedSlots: _vm._u([
                {
                  key: "machineNumber",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c(
                        "el-link",
                        {
                          staticStyle: {
                            color: "#409EFF",
                            "text-style": "none"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickMachine(row)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(row.machineNumber || "无") +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "menu",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "text" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.handleUpdate(scope.row)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Edit")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "text" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.handleDel(scope.row)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Delete")))]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "menuLeft" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-plus"
                      },
                      on: { click: _vm.openAdd }
                    },
                    [_vm._v(_vm._s(_vm.$t("Add")))]
                  )
                ],
                1
              ),
              _c(
                "template",
                { slot: "menuRight" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "200px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        placeholder: "请选择工序",
                        clearable: "",
                        size: "small"
                      },
                      model: {
                        value: _vm.page.processName,
                        callback: function($$v) {
                          _vm.$set(_vm.page, "processName", $$v)
                        },
                        expression: "page.processName"
                      }
                    },
                    _vm._l(_vm.process, function(item) {
                      return _c("el-option", {
                        key: item.name,
                        attrs: { label: item.name, value: item.name }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "200px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        filterable: "",
                        placeholder: "请输入设备类别",
                        clearable: "",
                        size: "small"
                      },
                      model: {
                        value: _vm.page.typeName,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.page,
                            "typeName",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "page.typeName"
                      }
                    },
                    _vm._l(_vm.typeList, function(value) {
                      return _c("el-option", {
                        key: value.id,
                        attrs: { value: value.code, label: value.name }
                      })
                    }),
                    1
                  ),
                  _c("el-input", {
                    staticStyle: {
                      width: "200px !important",
                      marginRight: "5px"
                    },
                    attrs: {
                      placeholder: "请输入名称或编码",
                      clearable: "",
                      size: "small"
                    },
                    model: {
                      value: _vm.page.nameOrCode,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.page,
                          "nameOrCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "page.nameOrCode"
                    }
                  })
                ],
                1
              )
            ],
            2
          ),
          _vm.showEdit
            ? _c("edit", {
                attrs: { modelShow: _vm.showEdit },
                on: { closeModel: _vm.closeModel }
              })
            : _vm._e(),
          _vm.showLook
            ? _c("look", {
                attrs: { modelShow: _vm.showLook },
                on: { closeModel: _vm.closeModel }
              })
            : _vm._e(),
          _vm.showAddDev
            ? _c("addDev", {
                attrs: { modelShow: _vm.showAddDev },
                on: { addDev: _vm.addDev, closeModel: _vm.closeModel }
              })
            : _vm._e(),
          _vm.showEditDev
            ? _c("editDev", {
                attrs: {
                  selectItem: _vm.selectItem,
                  modelShow: _vm.showEditDev
                },
                on: { editDev: _vm.editDev, closeModel: _vm.closeModel }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }