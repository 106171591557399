import { pvhApiBase } from "@/config/env";
import request from '@/router/axios';


//�ļ���ʾ�б� ��ѯ
export const List = (page, pageSize) => {
    return request({
        url: pvhApiBase + "Tracing/GetVendorAddresss",
        method: "get",
        params: {
            "page": page,
            "pageSize": pageSize
        }
    })
}


//�����ļ���Ϣ�б�
export const add = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PostVendorAddress",
        method: "post",
        data: {
            ...row
        }
    })
}


//�ļ���Ϣ����
export const edit = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PutVendorAddress",
        method: "put",
        data: {
            ...row
        }
    })
}


//ɾ���ļ���Ϣ
export const del = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeleteVendorAddress",
        method: "delete",
        params: {
            id
        }
    })
}


//�ļ���ʾ�б� ��ѯ
export const AddresssInfoByPath = (path) => {
    return request({
        url: pvhApiBase + "Tracing/GetAddressDataByPath",
        method: "get",
        params: {
            "path": path,
        }
    })
}

//�ļ���ʾ�б� ��ѯ
export const TreeList = () => {
    return request({
        url: pvhApiBase + "Tracing/GetTreeAddresss",
        method: "get",
        params: {
        }
    })
}