import request from '@/router/axios';
import { pvhApiBase } from "@/config/env";

//��ѯ
export const ListApproves = (id, page, pageSize) => {
    return request({
        url: pvhApiBase + "Tracing/GetOrderApproves",
        method: "get",
        params: {
            "id": id,
            "page": page,
            "pageSize": pageSize
        }
    })
}

//
export const addApprovesSo = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PostOrderApproveSo",
        method: "post",
        data: {
            ...row
        }
    })
}

export const addResubmitsSo = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PostOrderApproveResubmitSo",
        method: "post",
        data: {
            ...row
        }
    })
}

export const addUnlockSo = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PostOrderApproveUnlockSo",
        method: "post",
        data: {
            ...row
        }
    })
}

export const addLockSo = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PostOrderApproveLockSo",
        method: "post",
        data: {
            ...row
        }
    })
}


//
export const addApprovesPo = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PostOrderApprovePo",
        method: "post",
        data: {
            ...row
        }
    })
}

export const addResubmitsPo = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PostOrderApproveResubmitPo",
        method: "post",
        data: {
            ...row
        }
    })
}

export const addUnlockPo = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PostOrderApproveUnlockPo",
        method: "post",
        data: {
            ...row
        }
    })
}

export const addLockPo = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PostOrderApproveLockPo",
        method: "post",
        data: {
            ...row
        }
    })
}
//
export const editApproves = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PutOrderApprove",
        method: "put",
        data: {
            ...row
        }
    })
}

//
export const delApproves = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeleteOrderApprove",
        method: "delete",
        params: {
            id
        }
    })
}
