var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                "table-loading": _vm.tableLoading,
                page: _vm.page
              },
              on: {
                "row-update": _vm.handleUpdate,
                "selection-change": _vm.selectionChange,
                "refresh-change": _vm.refreshChange,
                "row-save": _vm.handleSave,
                "size-change": _vm.sizeChange,
                "current-change": _vm.currentChange
              },
              scopedSlots: _vm._u([
                {
                  key: "menu",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "text" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.handleUpdate1(scope.row, scope.index)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Edit")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "text" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.handleDel(scope.row)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Delete")))]
                      )
                    ]
                  }
                },
                {
                  key: "status",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        attrs: { "active-value": 0, "inactive-value": 1 },
                        on: {
                          change: function($event) {
                            return _vm.statusChange($event, scope.row.id)
                          }
                        },
                        model: {
                          value: scope.row.status,
                          callback: function($$v) {
                            _vm.$set(scope.row, "status", $$v)
                          },
                          expression: "scope.row.status"
                        }
                      })
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "menuRight" },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "inline-block",
                        width: "200px",
                        marginRight: "5px"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择状态" },
                          model: {
                            value: _vm.page.status,
                            callback: function($$v) {
                              _vm.$set(_vm.page, "status", $$v)
                            },
                            expression: "page.status"
                          }
                        },
                        _vm._l(_vm.statusList, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "inline-block",
                        width: "200px",
                        marginRight: "5px"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择字段类型"
                          },
                          model: {
                            value: _vm.page.dataType,
                            callback: function($$v) {
                              _vm.$set(_vm.page, "dataType", $$v)
                            },
                            expression: "page.dataType"
                          }
                        },
                        _vm._l(_vm.options, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-input", {
                    staticStyle: {
                      display: "'inlie-block'",
                      width: "200px",
                      marginRight: "5px"
                    },
                    attrs: {
                      placeholder: "请输入字段名称或字段KEY",
                      size: "small"
                    },
                    model: {
                      value: _vm.page.nameOrCode,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.page,
                          "nameOrCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "page.nameOrCode"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.search }
                    },
                    [_vm._v(_vm._s(_vm.$t("search")))]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }