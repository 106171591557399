import request from '@/router/axios';
import { pvhApiBase } from '@/config/env';


export const list = (userName, page, pageSize) => request({
    url: pvhApiBase + "ProcessManagement/GetCorpUsersByName",
    method: "get",
    params: {
        userName, page, pageSize
    }
})


