import { pvhApiBase } from "@/config/env";
import request from '@/router/axios';


//�ļ���ʾ�б� ��ѯ
export const fileList = (page, pageSize, name, type, beginTime, endTime,  ) => {
    return request({
        url: pvhApiBase + "Tracing/GetSupportFiles",
        method: "get",
        params: {
            "name": name,
            "type": type,
            "beginOrderDate": beginTime,
            "endOrderDate": endTime,
            "page": page,
            "pageSize": pageSize
        }
    })
}


//�����ļ���Ϣ�б�
export const addFile = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PostSupportFile",
        method: "post",
        data: {
            ...row
        }
    })
}


//�ļ���Ϣ����
export const editFile = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PutSupportFile",
        method: "put",
        data: {
            ...row
        }
    })
}


//ɾ���ļ���Ϣ
export const delFile = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeleteSupportFile",
        method: "delete",
        params: {
            id
        }
    })
}