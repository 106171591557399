import request from '@/router/axios';
import { pvhApiBase } from '@/config/env'

//�걨�ֶ��б�
export const supportFieldList = () => {
    return request({
        url: pvhApiBase + "Tracing/GetCurrentSupportFields",
        method: "get",
        params: {
        }
    })
}

//�걨�ϴ��ļ������б�
export const supportFileList = () => {
    return request({
        url: pvhApiBase + "Tracing/GetCurrentSupportFiles",
        method: "get",
        params: {
        }
    })
}

