var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c(
            "el-container",
            [
              _c(
                "div",
                { staticClass: "layTop" },
                [
                  _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.handleClick },
                      model: {
                        value: _vm.activeName,
                        callback: function($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName"
                      }
                    },
                    [
                      _c("el-tab-pane", {
                        attrs: { label: _vm.$t("DownloadPDF"), name: "first" }
                      }),
                      _c("el-tab-pane", {
                        attrs: { label: _vm.$t("PDFLog"), name: "second" }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "Title" }, [
                    _vm._v(_vm._s(_vm.$t("PDFLog")))
                  ])
                ],
                1
              ),
              _c("el-main", { staticStyle: { overflow: "hidden" } }, [
                _c(
                  "div",
                  { staticClass: "topInfoPdflpg" },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-bottom": "20px"
                            },
                            attrs: { sm: 24, md: 12, lg: 8 }
                          },
                          [
                            _c("span", { staticClass: "spanRight" }, [
                              _c("i", { staticClass: "el-icon-s-custom" }),
                              _vm._v(
                                _vm._s(_vm.$t("Supplier")) +
                                  " : " +
                                  _vm._s(_vm.formData.supplierVal)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-bottom": "20px"
                            },
                            attrs: { sm: 24, md: 12, lg: 8 }
                          },
                          [
                            _c("span", { staticClass: "spanRight" }, [
                              _c("i", { staticClass: "iconfont icon-rili" }),
                              _vm._v(
                                _vm._s(_vm.$t("At")) +
                                  " : " +
                                  _vm._s(_vm.formData.OrderDateVal)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-bottom": "20px"
                            },
                            attrs: { sm: 24, md: 12, lg: 8 }
                          },
                          [
                            _c("span", [
                              _c("i", { staticClass: "el-icon-attract" }),
                              _vm._v(
                                _vm._s(_vm.$t("BATCH")) +
                                  " : " +
                                  _vm._s(_vm.formData.BatchVal)
                              )
                            ]),
                            _c("span", { staticClass: "spanLeft" })
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-bottom": "20px"
                            },
                            attrs: { sm: 24, md: 12, lg: 8 }
                          },
                          [
                            _c("span", { staticClass: "spanRight" }, [
                              _c("i", { staticClass: "el-icon-document" }),
                              _vm._v(
                                _vm._s(_vm.$t("PO")) +
                                  " : " +
                                  _vm._s(_vm.formData.poVal)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-bottom": "20px"
                            },
                            attrs: { sm: 24, md: 12, lg: 8 }
                          },
                          [
                            _c("span", [
                              _c("i", { staticClass: "el-icon-document" }),
                              _vm._v(
                                _vm._s(
                                  _vm.$t("BUSINESS SERIAL NUMBER INTERNAL")
                                ) +
                                  " : " +
                                  _vm._s(_vm.formData.BusinessNoInVal)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-bottom": "20px"
                            },
                            attrs: { sm: 24, md: 12, lg: 8 }
                          },
                          [
                            _c("span", { staticClass: "spanRight" }, [
                              _c("i", { staticClass: "el-icon-document" }),
                              _vm._v(
                                _vm._s(
                                  _vm.$t("BUSINESS SERIAL NUMBER EXTERNAL")
                                ) +
                                  " : " +
                                  _vm._s(_vm.formData.BusinessNoOutVal)
                              )
                            ]),
                            _c("span", { staticClass: "spanLeft" })
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-bottom": "20px"
                            },
                            attrs: { sm: 24, md: 12, lg: 8 }
                          },
                          [
                            _c("span", { staticClass: "spanRight" }, [
                              _c("i", { staticClass: "el-icon-coin" }),
                              _vm._v(
                                _vm._s(_vm.$t("Product Name")) +
                                  " : " +
                                  _vm._s(_vm.formData.ProductNameVal)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-bottom": "20px"
                            },
                            attrs: { sm: 24, md: 12, lg: 8 }
                          },
                          [
                            _c("span", { staticClass: "spanRight" }, [
                              _c("i", { staticClass: "el-icon-brush" }),
                              _vm._v(
                                _vm._s(_vm.$t("COLOR")) +
                                  " : " +
                                  _vm._s(_vm.formData.ColorVal)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-bottom": "20px"
                            },
                            attrs: { sm: 24, md: 12, lg: 8 }
                          },
                          [
                            _c("span", { staticClass: "spanRight" }, [
                              _c("i", {
                                staticClass: "el-icon-shopping-cart-full"
                              }),
                              _vm._v(
                                _vm._s(_vm.$t("QUANTITY")) +
                                  " : " +
                                  _vm._s(_vm.formData.NumberVal) +
                                  "/" +
                                  _vm._s(_vm.formData.UnitNameVal)
                              )
                            ]),
                            _c("span", { staticClass: "spanLeft" })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "el-footer",
                {
                  staticStyle: {
                    height: "600px !important",
                    padding: "20px 0px"
                  }
                },
                [
                  _c("avue-crud", {
                    ref: "crud",
                    staticStyle: { "margin-bottom": "20px !important" },
                    attrs: {
                      option: _vm.setData.tableOpt,
                      data: _vm.tableData,
                      page: _vm.page
                    },
                    on: {
                      "update:page": function($event) {
                        _vm.page = $event
                      },
                      "current-change": _vm.currentChange
                    },
                    model: {
                      value: _vm.obj,
                      callback: function($$v) {
                        _vm.obj = $$v
                      },
                      expression: "obj"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }