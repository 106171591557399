<template>
  <div>
    <basic-container>
    <avue-crud :option="tableOption"
                 :data="tableData"
                 v-model="tableFrom"
                 :table-loading="tableLoading"
                 :page="page"
                 ref="crud"
                 @current-change="currentChange"
                 @size-change="sizeChange">
            <!-- <template slot="name" slot-scope="scope">
                <el-link @click="linkToLook(scope.row)">{{scope.row.name}}</el-link>
            </template> -->
            <!-- <template slot="status" slot-scope="scope">
                <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                @change="change(scope.row)">
                </el-switch>
            </template> -->
        <template slot-scope="scope" slot="menu">
            <el-button type="text"
                       size="small"
                       icon="el-icon-edit"
                       class="none-border"
                       @click.stop="beforeOpen(scope.row)">编辑
            </el-button>
        </template>
        <template slot="menuLeft">
            <el-dropdown>
                <el-button type="primary" size="small" icon="el-icon-plus" @click="addClick">{{$t("Add")}}</el-button>
            </el-dropdown>
        </template>
        <template slot="menuRight">
            <el-select clearable v-model="work" placeholder="工艺单号" size="small" style="width:150px !important;marginRight: 5px;">
                <el-option v-for="item in planCode" :key="item.id" :label="item.code" :value="item.id">{{item.name}}</el-option>
            </el-select>
            <el-select clearable v-model="porccess" @change="changeProcess" placeholder="设备机型" size="small" style="width:120px !important;marginRight: 5px;">
                <el-option v-for="(item,index) in listComputer" :key="index" :label="item.name" :value="item.id">{{item.name}}</el-option>
            </el-select>
            <el-select clearable v-model="wklnNumber" placeholder="设备编号" size="small" style="width:120px !important;marginRight: 5px;">
                <el-option v-for="(item,index) in listWkln" :key="index" :label="item.code" :value="item.id">{{item.code}}</el-option>
            </el-select>
						<el-select clearable v-model="varieties" placeholder="品种" size="small" style="width:120px !important;marginRight: 5px;">
                <el-option v-for="(item,index) in listworkStyle" :key="index" :label="item.name" :value="item.id">{{item.name}}</el-option>
            </el-select>
            <el-select clearable v-model="status" placeholder="状态" size="small" style="width:100px !important;marginRight: 5px;">
                <el-option label="待执行" value="0">待执行</el-option>
                <el-option label="执行中" value="1">执行中</el-option>
                <el-option label="执行完毕" value="2">执行完毕</el-option>
            </el-select>
            <el-button type="primary" size="small" icon="el-icon-search" @click="search">{{$t("search")}}</el-button>
        </template>
    </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {getPlanCode,list,baseList} from "@/api/planList";
import {machineList,productList,machineModelList} from "@/api/config";
export default {
    data() {
        return {
            tableLoading:false,
            tableFrom: {},
            work: "", // 工艺单号
            porccess: "", //设备机型
            wklnNumber: "", //设备编号
						varieties: '', //品种
            status: "", //状态
						planCode: [], // 工艺单号数组
            listworkStyle: [], //品种数组
            listComputer: [], //设备机型数组
            wklnValue: "",
            listWkln: [],
            nameOrCode: "",
            departmentId: "1",
            page: {
                pageSize: 20,
                pagerCount:5,
                total: '',
                pageCurrent: 1
            },
            disabled:true,
            tableData:[],
            tableOption:{
              refreshBtn: false,
                editBtn: false,
              delBtn: false,
                addBtn:false,
              menuWidth:100,
              tip: false,
              columnBtn : false,
              labelWidth: "120",
              dialogFullscreen:true,
              addBtnText: this.$t('ADD'),
              editBtnText: this.$t('Edit'),
              emptyText: this.$t('No Data'),
              menuTitle: this.$t('OPERATION'),
              column:[{
                label: "工艺单号",
                prop: "specSheetCode",
                slot: true
              },{
                label: "设备机型",
                prop: "machineModelName"
              },{
                label: "设备",
                prop: "machineCode"
              },{
                label: "品种",
                prop: "productName"
              },{
                label: "批号",
                prop: "productBatchCode"
              },{
                label: "工序",
                prop: "processName"
              },{
                label: "计划开始时间",
                prop: "planStartTime",
                dicData: []
              },{
                label: "计划结束时间",
                prop: "planEndTime",
                dicData: []
              },{
                label: "计划产量",
                prop: "planYield"
              },{
                label: "状态",
                // slot: true,
                prop: "status",
								// fomatter(row) {
								// 	console.log(row);
								// }
                dicData: [{
                    label: "待执行",
                    value: 0
                },{
                    label: "执行中",
                    value: 1
                },{
                    label: "执行完成",
                    value: 2
                }]
              },{
                label: "创建日期",
                prop: "createTime"
              },{
                label: "创建人",
                prop: "corpUsersName"
              }]
            }
        }
    },
    created() {
      this.list();
			 productList().then(res => {
					console.log(res);
						this.listworkStyle = res.data.data.items;
      });
      machineModelList().then(res => {
          this.listComputer = res.data;
      });
			getPlanCode().then(res => {
				console.log(res);
				this.planCode = res.data.data
			})
    },
    methods: {
        currentChange(pageCurrent) {
          this.page.pageCurrent = pageCurrent;
          this.list();
        },
        sizeChange(pageSize) {
          this.page.pageCurrent = 1;
          this.page.pageSize = pageSize;
          this.list();
        },
				changeProcess(row) {
					 machineList(row).then(res => {
						 console.log(res);
            this.listWkln = res.data.data;
      });
				},
        // linkToLook(row) {
        //     this.$router.push({path: "lookstandrd",query: {pushData: row}});
        // },
        beforeOpen(row) {
            console.log('编辑');
            console.log('row',row);
            this.$router.push({path: "editPlanList",query: {pushData: row}});
        },
        search() {
            this.list();
        },
        addClick() {
					console.log('总增页面');
            this.$router.push({path: "addPlanList"});
        },
        change(row) {
            changeStatus(row).then(() => {
                this.$message({
                    message: "修改成功",
                    type: "success"
                });
            })
        },
        // selectionChange(list) {
        //     console.log(list);
        //     list != "" ? this.disabled = false : this.disabled = true;
        // },
        list(){
					console.log('========',this.work,this.porccess,this.wklnNumber,this.varieties,this.status,this.page.pageCurrent,this.page.pageSize);
            list(this.work,this.porccess,this.wklnNumber,this.varieties,this.status,this.page.pageCurrent,this.page.pageSize).then(res => {
              console.log('123',res);
               this.page.total = res.data.data.total
                this.tableData = res.data.data.items;
            //   this.tableData.forEach(v => {
            //       v.status = v.status;
            //   })
          })
        },
        envText: function() {
            return this.env
        }
  }
}
</script>
<style scoped>
    .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        padding-left: 10px;
    }
</style>