<template>
  <div class="volumeSet">
    <el-dialog
        style="marginTop: -60px;"
        :visible.sync="modelShow"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        width="800px">
      <div class="content">
        <el-form :model="form" label-width="120px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="机台编号:">
                <el-input v-model="seletItem.name" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="品种工艺来源:">
                <el-radio-group v-model="form.radio" fill="#67C23A">
                  <el-radio-button label="1">工艺翻改单</el-radio-button>
                  <el-radio-button label="2">快捷创建</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <div v-show="form.radio == 1">
            <div class="table_content">
              <el-table
                  ref="multipleTable"
                  :data="tableData"
                  tooltip-effect="dark"
                  style="width: 100%"
                  @selection-change="handleSelectionChange">
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column
                    label="创建时间"
                    width="220">
                  <template slot-scope="{row}">{{ row.createTime }}</template>
                </el-table-column>
                <el-table-column
                    prop="productName"
                    label="产品"
                    width="120">
                </el-table-column>
                <el-table-column
                    prop="batchName"
                    label="批次"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                    prop="planYield"
                    label="产量">
                  <template slot-scope="{row}">{{ row.planYield }}kg</template>
                </el-table-column>
              </el-table>
            </div>

            <el-row>
              <el-col :span="12">
                <el-form-item label="工艺翻改时间:">
                  <el-date-picker
                      v-model="form.createTime"
                      disabled
                      type="datetime"
                      format="yyyy-MM-dd HH:mm:ss"
                      placeholder="选择日期时间">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="预计生产品种:">
<!--                  <el-select v-model="form.productionId" @change="getParam" disabled placeholder="请选择">-->
<!--                    <el-option-->
<!--                        v-for="item in productionIsExpectedTo"-->
<!--                        @click.native="getP(item)"-->
<!--                        :key="item.productId"-->
<!--                        :label="item.productName"-->
<!--                        :value="item.productId">-->
<!--                    </el-option>-->
<!--                  </el-select>-->
                  <el-input v-model="form.productName" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="品种批次:">
<!--                  <el-select v-model="form.batchId" disabled placeholder="请选择">-->
<!--                    <el-option-->
<!--                        v-for="item in options"-->
<!--                        :key="item.id"-->
<!--                        :label="item.batchCode"-->
<!--                        :value="item.id">-->
<!--                    </el-option>-->
<!--                  </el-select>-->
                  <el-input v-model="form.batchName" disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="预计开台时间:">
                  <el-date-picker
                      v-model="form.planStartTime"
                      disabled
                      value-format="yyyy-MM-dd HH:mm:ss"
                      type="datetime"
                      placeholder="选择日期时间">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="预计了机时间:">
                  <el-date-picker
                      disabled
                      v-model="form.planEndTime"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      type="datetime"
                      placeholder="选择日期时间">
                  </el-date-picker>

                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="计划产量:">
                  <el-input placeholder="" disabled v-model="form.num">
                    <template slot="append">kg</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
<!--`````````````-->
          <div v-show="form.radio == 2">
            <el-row>
              <el-col :span="12">
                <el-form-item label="预计生产品种:">
                  <el-select v-model="form.productId" @change="getParam2" placeholder="请选择">
                    <el-option
                        v-for="item in productionIsExpectedTo2"
                        :key="item.productId"
                        @click.native="getSpec(item)"
                        :label="item.productName"
                        :value="item.productId">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="品种批次:">
                  <el-select v-model="form.batchCode" placeholder="请选择">
                    <el-option
                        v-for="item in options2"
                        :key="item.batchCode"
                        :label="item.batchCode"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="计划产量:">
                  <el-input placeholder="请输入内容" v-model="form.planYield">
                    <template slot="append">kg</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
      <p slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="success" @click="makeSure">确认开台</el-button>
        <el-button @click="closeModel">取 消</el-button>
      </p>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "volumeSet",
  props: {
    modelShow: {
      type: Boolean,
      default: false
    },
    seletItem:Object
  },
  data() {
    return {
      form:{
        planStartTime:'',
        data:'',
        factStartTime:'',
        factEndTime:'',
        factYield:'',
        machineId:this.seletItem.id,
        openYield:'',
        openTime:'',
        planId:'',
        processId:'',
        productId:'',
        specSheetId:'',
        num:0,
        num2:0,
        radio:'1',
        productionId:'',
        batchId:'',
        productionId2:'',
        productBatch2:'',
        batchCode:'',
        productName:'',
        createTime:'',
        planEndTime:'',
      },
      tableData:[],
      options:[],
      productionIsExpectedTo:[],
      options2:[],
      productionIsExpectedTo2:[],
      multipleSelection:[]
    }
  },
  created(){
    this.getInfo()
    this.getInfo2()
  },
  methods: {
    handleSelectionChange(val){

      if(val.length>1){
        this.$refs.multipleTable.clearSelection()
        this.$refs.multipleTable.toggleRowSelection(val.pop())
      }else {
        this.multipleSelection = val.pop()
        console.log(this.multipleSelection,'val')
        if(this.multipleSelection && this.multipleSelection.productName){
          setTimeout(()=>{
            this.form.productName = this.multipleSelection.productName
              this.form.batchName = this.multipleSelection.batchName
              this.form.batchCode = this.multipleSelection.batchCode
            this.form.num = this.multipleSelection.planYield
            this.form.planStartTime = this.multipleSelection.planStartTime
            this.form.planEndTime = this.multipleSelection.planEndTime
            this.form.factStartTime = this.multipleSelection.planStartTime
            this.form.factEndTime = this.multipleSelection.planEndTime
            this.form.createTime = this.multipleSelection.createTime
            this.form.processId = this.multipleSelection.processId
            this.form.productId = this.multipleSelection.productId
            this.form.specSheetId = this.multipleSelection.specSheetId
            this.form.planId = this.multipleSelection.id
          },500)
        }else {
          this.form.productName = ''
          this.form.batchCode =  ''
          this.form.num = ''
          this.form.planStartTime = ''
          this.form.planEndTime = ''
          this.form.createTime = ''
          this.form.processId = ''
          this.form.productId = ''
          this.form.specSheetId = ''
          this.form.planId = ''
          this.form.factStartTime = ''
          this.form.factEndTime = ''
        }

      }


    },
    // 获取工作批次
    async selectBatch(id) {
      const res = await this.request.get(`${this.proApis.SELECTBATCH}?productId=${id}`)
      console.log(res,'获取工作重心')
      this.options = res.data.data
    },
    // 获取工作批次
    async selectBatch2(id) {
      const res = await this.request.get(`${this.proApis.SELECTBATCH}?productId=${id}`)
      console.log(res,'品种批次')
      this.options2 = res.data.data
    },
    // 获取信息
    async getInfo(){
      const  res = await this.request.get(`${this.proApis.SELECTPLANSHEET}?machineId=${this.seletItem.id}`)
      console.log(res,'开台')
      this.tableData = res.data.data

    },
    getParam(val){
      console.log(val)
      this.form.batchId = ''
      this.form.planId = ''
      this.form.processId = ''
      this.form.productId = ''
      this.selectBatch(val)
    },
    getP(item){
      console.log(item,'item')
      this.form.planStartTime = item.planStartTime
      this.form.num = item.planYield
      this.form.processId = item.processId
      this.form.productId = item.productId
      this.form.specSheetId = item.specSheetId
      this.form.planId = item.id
    },
    getParam2(val){
      console.log(val)
      this.form.productBatch2 = ''
      this.selectBatch2(val)
    },
    getSpec(val){
      this.form.specSheetId = val.specSheetId

    },
    // 获取信息
    async getInfo2(){
      const  res = await this.request.get(`${this.proApis.SELECTBASESHEET}?machineId=${this.seletItem.id}`)
      console.log(res,'da')
      this.productionIsExpectedTo2 = res.data.data

    },
    closeModel() {
      this.$emit('closeModel', false)
    },
    makeSure() {
      if(this.form.radio == 1) {
        this.$emit('setFound', this.form)
      } else {
        this.$emit('setFoundBySelf', this.form)
      }

    },
  }
}
</script>

<style lang="scss" scoped>
.item_name {
  font-weight: 700;
  height: 40px;
  line-height: 40px;
}
.dialog-footer {
  width: 100%;
  text-align: center;
}
.table_content {
  margin: 20px;
  margin-top: 0px;
}

/deep/.el-table__header-wrapper  .el-checkbox{
  display:none
}

</style>