var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c("avue-crud", {
            ref: "crud",
            attrs: {
              option: _vm.tableOption,
              data: _vm.tableData,
              "table-loading": _vm.tableLoading,
              page: _vm.page
            },
            on: {
              "selection-change": _vm.selectionChange,
              "size-change": _vm.sizeChange,
              "current-change": _vm.currentChange
            },
            scopedSlots: _vm._u([
              {
                key: "menu",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "text" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.handleUpdate(scope.row, scope.index)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Edit")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "text" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.handleDel(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Delete")))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm.showEdit
            ? _c("edit", {
                attrs: { selectItem: _vm.selectItem, modelShow: _vm.showEdit },
                on: { add: _vm.add, closeModel: _vm.closeModel }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }