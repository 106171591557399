var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c("el-tab-pane", {
                attrs: { label: _vm.$t("PRODUCT TRACING"), name: "first" }
              }),
              _c("el-tab-pane", {
                attrs: { label: _vm.$t("PRODUCT TREE TRACING"), name: "second" }
              })
            ],
            1
          ),
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "10px" }
              },
              [_vm._v(_vm._s(_vm.$t("PRODUCT TRACING")))]
            )
          ]),
          [
            _c(
              "div",
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 }
                      },
                      [
                        _c("el-autocomplete", {
                          staticStyle: { width: "300px" },
                          attrs: {
                            placeholder: _vm.$t("queryParam"),
                            "prefix-icon": "el-icon-help",
                            size: "small",
                            "fetch-suggestions": _vm.querySearchCodeAsync
                          },
                          on: {
                            select: _vm.handleSelect,
                            change: _vm.handleChange
                          },
                          model: {
                            value: _vm.SeaCodeVal,
                            callback: function($$v) {
                              _vm.SeaCodeVal = $$v
                            },
                            expression: "SeaCodeVal"
                          }
                        }),
                        _c("el-button", {
                          staticStyle: { "margin-left": "10px" },
                          attrs: {
                            icon: "iconfont icon-dingwei1",
                            type: "text",
                            size: "medium",
                            title: _vm.$t("Tracing")
                          },
                          on: {
                            click: function($event) {
                              return _vm.SelectData()
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "margin-bottom": "20px",
                          "text-align": "right"
                        },
                        attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 }
                      },
                      [
                        false
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-thumb",
                                  type: "primary",
                                  size: "medium",
                                  round: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.getApprove()
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Approve")))]
                            )
                          : _vm._e(),
                        _vm.isBrandBusiness
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-thumb",
                                  type: "success",
                                  size: "medium",
                                  round: ""
                                },
                                on: { click: _vm.ApprovedsubmitForm }
                              },
                              [_vm._v(_vm._s(_vm.$t("APPROVED")))]
                            )
                          : _vm._e(),
                        _vm.isBrandBusiness
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-download",
                                  type: "warning",
                                  size: "medium",
                                  round: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.GetReview()
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("DownloadPDF")))]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    staticStyle: {
                      padding: "20px 40px",
                      background: "#F5FAFF",
                      "border-radius": "8px",
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "space-between",
                      "margin-bottom": "20px"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        staticStyle: {
                          width: "calc(100% - 120px)",
                          float: "left"
                        }
                      },
                      [
                        _c(
                          "el-form",
                          { attrs: { model: _vm.topInfo } },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "margin-bottom": "20px" },
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 6,
                                      xl: 6
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-right": "10px",
                                          "font-weight": "700",
                                          "font-size": "14px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("Division")) + " :"
                                        )
                                      ]
                                    ),
                                    _c("span", { staticClass: "fontCont" }, [
                                      _vm._v(_vm._s(_vm.topInfo.departVal))
                                    ])
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "margin-bottom": "20px" },
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 6,
                                      xl: 6
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-right": "10px",
                                          "font-weight": "700",
                                          "font-size": "14px"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Brand")) + " :")]
                                    ),
                                    _c("span", { staticClass: "fontCont" }, [
                                      _vm._v(_vm._s(_vm.topInfo.brandVal))
                                    ])
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "margin-bottom": "20px" },
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 6,
                                      xl: 6
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-right": "10px",
                                          "font-weight": "700",
                                          "font-size": "14px"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("BATCH")) + " :")]
                                    ),
                                    _c("span", { staticClass: "fontCont" }, [
                                      _vm._v(_vm._s(_vm.topInfo.batchVal))
                                    ])
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "margin-bottom": "20px" },
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 6,
                                      xl: 6
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-right": "10px",
                                          "font-weight": "700",
                                          "font-size": "14px"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("PO")) + " :")]
                                    ),
                                    _c("span", { staticClass: "fontCont" }, [
                                      _vm._v(_vm._s(_vm.topInfo.poVal))
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "margin-bottom": "20px" },
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 6,
                                      xl: 6
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-right": "10px",
                                          "font-weight": "700",
                                          "font-size": "14px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("ContractNo")) + " :"
                                        )
                                      ]
                                    ),
                                    _c("span", { staticClass: "fontCont" }, [
                                      _vm._v(_vm._s(_vm.topInfo.contractNoVal))
                                    ])
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "margin-bottom": "20px" },
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 6,
                                      xl: 6
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-right": "10px",
                                          "font-weight": "700",
                                          "font-size": "14px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("ProductCode")) + " :"
                                        )
                                      ]
                                    ),
                                    _c("span", { staticClass: "fontCont" }, [
                                      _vm._v(_vm._s(_vm.topInfo.codeVal))
                                    ])
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "margin-bottom": "20px" },
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 6,
                                      xl: 6
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-right": "10px",
                                          "font-weight": "700",
                                          "font-size": "14px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("Product Name")) + " :"
                                        )
                                      ]
                                    ),
                                    _c("span", { staticClass: "fontCont" }, [
                                      _vm._v(_vm._s(_vm.topInfo.productVal))
                                    ])
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "margin-bottom": "20px" },
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 6,
                                      xl: 6
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-right": "10px",
                                          "font-weight": "700",
                                          "font-size": "14px"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("COLOR")) + " :")]
                                    ),
                                    _c("span", { staticClass: "fontCont" }, [
                                      _vm._v(_vm._s(_vm.topInfo.colorVal))
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "margin-bottom": "20px" },
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 6,
                                      xl: 6
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-right": "10px",
                                          "font-weight": "700",
                                          "font-size": "14px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("QUANTITY")) + " :"
                                        )
                                      ]
                                    ),
                                    _c("span", { staticClass: "fontCont" }, [
                                      _vm._v(
                                        _vm._s(_vm.topInfo.numberVal) +
                                          "/" +
                                          _vm._s(_vm.topInfo.unitVal)
                                      )
                                    ])
                                  ]
                                ),
                                (_vm.topInfo.destinationCountryNameVal == ""
                                ? false
                                : true)
                                  ? _c(
                                      "el-col",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "20px"
                                        },
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 6,
                                          xl: 6
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-right": "10px",
                                              "font-weight": "700",
                                              "font-size": "14px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("MARKET")) + " :"
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "fontCont" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.topInfo
                                                  .destinationCountryNameVal
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c("el-col", {
                                  staticStyle: { "margin-bottom": "20px" },
                                  attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                                }),
                                _c("el-col", {
                                  staticStyle: { "margin-bottom": "20px" },
                                  attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", [
                      _c(
                        "div",
                        {
                          ref: "qrCodeUrl",
                          staticClass: "qrcode",
                          attrs: { id: "qrCodeUrl" },
                          on: { click: _vm.onPrint }
                        },
                        [
                          _c("vue-qr", {
                            attrs: {
                              logoSrc: _vm.qrColorCode,
                              logoScale: 20,
                              margin: 2,
                              colorDark: "#000",
                              text: _vm.qrpocode,
                              size: 120
                            }
                          })
                        ],
                        1
                      ),
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v("Mobile  Traceability")
                      ])
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "tracingList" },
                  _vm._l(_vm.InfoData.activities, function(item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "tli" },
                      [
                        _c("div", { staticClass: "topTitleCon" }, [
                          _c("i", {
                            staticClass: "iconfont icon-yuan",
                            staticStyle: { color: "#4D8AF0" }
                          }),
                          _vm._v(_vm._s(item.title))
                        ]),
                        _vm._l(item.children, function(time, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticStyle: {
                                "align-items": "flex-start",
                                "justify-content": "space-between"
                              }
                            },
                            [
                              _c(
                                "el-row",
                                {
                                  staticStyle: {
                                    "background-color": "#dcdfe673",
                                    "padding-top": "5px"
                                  }
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { "margin-bottom": "20px" },
                                      attrs: {
                                        xs: 24,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                        xl: 12
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "topTitleCon2" },
                                        [
                                          _c("i", {
                                            staticClass: "iconfont icon-yuan",
                                            staticStyle: { color: "#C2C7D2" }
                                          }),
                                          _vm._v(_vm._s(time.date))
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: {
                                        "justify-content": "right",
                                        display: "flex"
                                      },
                                      attrs: {
                                        xs: 24,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                        xl: 12
                                      }
                                    },
                                    [
                                      !time.isLastVar
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "35px",
                                                "justify-content": "center",
                                                display: "flex"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "light",
                                                    placement: "bottom-start"
                                                  }
                                                },
                                                [
                                                  _vm._l(
                                                    time.Approvelist,
                                                    function(item, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: index,
                                                          attrs: {
                                                            slot: "content"
                                                          },
                                                          slot: "content"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatterApprove(
                                                                item
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  _c("img", {
                                                    staticStyle: {
                                                      height: "1rem",
                                                      width: "1.25rem",
                                                      "margin-top": "5px"
                                                    },
                                                    attrs: {
                                                      title: _vm.$t(
                                                        time.ApproveVar
                                                      ),
                                                      src: time.ApproveImgVar
                                                    }
                                                  })
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isBrandBusiness && !time.isLastVar
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    padding: "3px !important"
                                                  },
                                                  attrs: {
                                                    type: "danger",
                                                    size: "small",
                                                    icon: "el-icon-close",
                                                    plain: "",
                                                    round: "",
                                                    title: _vm.$t("Reject")
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.RejectForm(
                                                        time
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("Reject")) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              time.LockedStatusVar <= 0
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      staticStyle: {
                                                        padding:
                                                          "3px !important"
                                                      },
                                                      attrs: {
                                                        type: "info",
                                                        size: "small",
                                                        icon: "el-icon-lock",
                                                        plain: "",
                                                        round: "",
                                                        title: _vm.$t("LOCK")
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.submitLock(
                                                            time
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.$t("LOCK")) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              time.LockedStatusVar > 0
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      staticStyle: {
                                                        padding:
                                                          "3px !important"
                                                      },
                                                      attrs: {
                                                        type: "warning",
                                                        size: "small",
                                                        icon: "el-icon-unlock",
                                                        plain: "",
                                                        round: "",
                                                        title: _vm.$t("Unlock")
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.submitUnlock(
                                                            time
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Unlock")
                                                        ) + " "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50px",
                                            "justify-content": "center",
                                            display: "flex"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "light",
                                                placement: "bottom-start"
                                              }
                                            },
                                            [
                                              _vm._l(time.statuslist, function(
                                                item,
                                                index
                                              ) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    attrs: { slot: "content" },
                                                    slot: "content"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatterColumn(
                                                          item
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              }),
                                              _c(
                                                "el-link",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "bold"
                                                  },
                                                  attrs: {
                                                    type: time.statusColorVar
                                                  }
                                                },
                                                [_vm._v(_vm._s(time.statusVar))]
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      ),
                                      time.processList.length == 0
                                        ? _c(
                                            "div",
                                            { staticStyle: { width: "60px" } },
                                            [
                                              _c("el-button", {
                                                staticStyle: {
                                                  padding: "0 0 0 !important"
                                                },
                                                attrs: {
                                                  icon: "iconfont icon-mingxi",
                                                  disabled: ""
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      time.processList.length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "60px",
                                                "justify-content": "center",
                                                display: "flex"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-badge",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    value: time.processCount
                                                  }
                                                },
                                                [
                                                  _c("el-button", {
                                                    staticStyle: {
                                                      padding:
                                                        "0 0 0 !important"
                                                    },
                                                    attrs: {
                                                      icon:
                                                        "iconfont icon-mingxi",
                                                      title: _vm.$t(
                                                        "Declaration items uploaded / declaration items to be uploaded"
                                                      )
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.showField(
                                                          time.processList
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      time.fileList.length == 0
                                        ? _c(
                                            "div",
                                            { staticStyle: { width: "60px" } },
                                            [
                                              _c("el-button", {
                                                staticStyle: {
                                                  padding: "0 0 0 !important"
                                                },
                                                attrs: {
                                                  icon: "iconfont icon-fujian",
                                                  disabled: ""
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      time.fileList.length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "60px",
                                                "justify-content": "center",
                                                display: "flex"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-badge",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    value: time.fileCount
                                                  }
                                                },
                                                [
                                                  _c("el-button", {
                                                    staticStyle: {
                                                      padding:
                                                        "0 0 0 !important"
                                                    },
                                                    attrs: {
                                                      icon:
                                                        "iconfont icon-fujian",
                                                      title: _vm.$t(
                                                        "Declaration items uploaded / declaration items to be uploaded"
                                                      )
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.showFileList(
                                                          time.fileList
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { "padding-left": "35px" } },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          time.VendorSectionSortVar < 2
                                            ? _c(
                                                "span",
                                                { staticClass: "fontTitle" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("VENDOR")) +
                                                      " : "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          time.VendorSectionSortVar > 1
                                            ? _c(
                                                "span",
                                                { staticClass: "fontTitle" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("SUPPLIER")) +
                                                      " : "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [_vm._v(_vm._s(time.vendorVal))]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "fontTitle" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("PO")) + " : "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [_vm._v(_vm._s(time.POVal))]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "fontTitle" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("Division")) +
                                                  " : "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [_vm._v(_vm._s(time.CdivisionVar))]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "fontTitle" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("Brand")) + " : "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [_vm._v(_vm._s(time.CbrandVar))]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "fontTitle" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("ProductCode")) +
                                                  " : "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [_vm._v(_vm._s(time.codeVal))]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "fontTitle" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("BATCH")) + " : "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [_vm._v(_vm._s(time.title))]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "fontTitle" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("COLOR")) + " : "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [_vm._v(_vm._s(time.CcolorVar))]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 8,
                                            lg: 6,
                                            xl: 6
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "fontTitle" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("QUANTITY")) +
                                                  ": "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [
                                              _vm._v(
                                                _vm._s(time.CnunberVar) +
                                                  "/" +
                                                  _vm._s(time.CunitVar)
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      time.isRawFiberTypeId
                                        ? _c(
                                            "el-col",
                                            {
                                              attrs: {
                                                xs: 24,
                                                sm: 12,
                                                md: 6,
                                                lg: 6,
                                                xl: 6
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "fontTitle" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("FIBER TYPE")
                                                    ) + " : "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "fontCont" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      time.RawFiberTypeIdVar
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      time.isVendorAddressId
                                        ? _c(
                                            "el-col",
                                            {
                                              attrs: {
                                                xs: 24,
                                                sm: 12,
                                                md: 6,
                                                lg: 6,
                                                xl: 6
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "fontTitle" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("ORIGIN")) +
                                                      " :"
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "fontCont" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      time.VendorAddressIdVar
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 12,
                                            lg: 12,
                                            xl: 12
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "fontTitle" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("Product Name")) +
                                                  " : "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fontCont" },
                                            [_vm._v(_vm._s(time.CproductVar))]
                                          )
                                        ]
                                      ),
                                      (time.compositionVar != ""
                                      ? true
                                      : false)
                                        ? _c(
                                            "el-col",
                                            {
                                              attrs: {
                                                xs: 24,
                                                sm: 12,
                                                md: 12,
                                                lg: 12,
                                                xl: 12
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "fontTitle" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("COMPONENT")
                                                    ) + " : "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "fontCont" },
                                                [
                                                  _vm._v(
                                                    _vm._s(time.compositionVar)
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      time.isCollection
                                        ? _c(
                                            "el-col",
                                            {
                                              attrs: {
                                                xs: 24,
                                                sm: 12,
                                                md: 16,
                                                lg: 12,
                                                xl: 12
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "fontTitle" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "CONTRACT SIGNING COMPANY"
                                                      )
                                                    ) + " : "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "fontCont" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      time.AffiliatedCorpCollectionVar
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      time.isWorking
                                        ? _c(
                                            "el-col",
                                            {
                                              attrs: {
                                                xs: 24,
                                                sm: 12,
                                                md: 16,
                                                lg: 12,
                                                xl: 12
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "fontTitle" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "ASSOCIATED PROCESSING PLANT"
                                                      )
                                                    ) + " : "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "fontCont" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      time.AffiliatedCorpWorkingVar
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._l(time.downlist, function(downs, index) {
                                    return time.downlist.length > 0
                                      ? _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "downListCon"
                                          },
                                          [
                                            _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: {
                                                      xs: 24,
                                                      sm: 12,
                                                      md: 8,
                                                      lg: 6,
                                                      xl: 6
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "fontTitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(_vm.$t("PO")) +
                                                            " : "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "fontCont"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            downs.PoRowInfo.Po
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: {
                                                      xs: 24,
                                                      sm: 12,
                                                      md: 8,
                                                      lg: 10,
                                                      xl: 10
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "fontTitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("PO COMPANY")
                                                          ) + " : "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "fontCont"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            downs.PoRowInfo
                                                              .VendorCorporationId
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: {
                                                      xs: 24,
                                                      sm: 24,
                                                      md: 8,
                                                      lg: 8,
                                                      xl: 8
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "justify-content":
                                                            "right"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              width: "35px",
                                                              "justify-content":
                                                                "center",
                                                              display: "flex"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "el-tooltip",
                                                              {
                                                                staticClass:
                                                                  "item",
                                                                attrs: {
                                                                  effect:
                                                                    "light",
                                                                  placement:
                                                                    "bottom-start"
                                                                }
                                                              },
                                                              [
                                                                _vm._l(
                                                                  downs.ApproveList,
                                                                  function(
                                                                    item,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key: index,
                                                                        attrs: {
                                                                          slot:
                                                                            "content"
                                                                        },
                                                                        slot:
                                                                          "content"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.formatterApprove(
                                                                              item
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                _c("img", {
                                                                  staticStyle: {
                                                                    height:
                                                                      "1rem",
                                                                    width:
                                                                      "1.25rem",
                                                                    "margin-top":
                                                                      "5px"
                                                                  },
                                                                  attrs: {
                                                                    title: _vm.$t(
                                                                      downs.Approve
                                                                    ),
                                                                    src:
                                                                      downs.ApproveImg
                                                                  }
                                                                })
                                                              ],
                                                              2
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm.isBrandBusiness
                                                          ? _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    staticStyle: {
                                                                      padding:
                                                                        "3px !important"
                                                                    },
                                                                    attrs: {
                                                                      type:
                                                                        "danger",
                                                                      size:
                                                                        "small",
                                                                      icon:
                                                                        "el-icon-close",
                                                                      plain: "",
                                                                      round: "",
                                                                      title: _vm.$t(
                                                                        "Reject"
                                                                      )
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.RejectDownForm(
                                                                          downs
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "Reject"
                                                                        )
                                                                      ) + " "
                                                                    )
                                                                  ]
                                                                ),
                                                                downs
                                                                  .PoRowDetailInfo
                                                                  .LockedStatus <=
                                                                0
                                                                  ? _c(
                                                                      "el-button",
                                                                      {
                                                                        staticStyle: {
                                                                          padding:
                                                                            "3px !important"
                                                                        },
                                                                        attrs: {
                                                                          type:
                                                                            "info",
                                                                          size:
                                                                            "small",
                                                                          icon:
                                                                            "el-icon-lock",
                                                                          plain:
                                                                            "",
                                                                          round:
                                                                            "",
                                                                          title: _vm.$t(
                                                                            "LOCK"
                                                                          )
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.submitDownLock(
                                                                              downs
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "LOCK"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                downs
                                                                  .PoRowDetailInfo
                                                                  .LockedStatus >
                                                                0
                                                                  ? _c(
                                                                      "el-button",
                                                                      {
                                                                        staticStyle: {
                                                                          padding:
                                                                            "3px !important"
                                                                        },
                                                                        attrs: {
                                                                          type:
                                                                            "warning",
                                                                          size:
                                                                            "small",
                                                                          icon:
                                                                            "el-icon-unlock",
                                                                          plain:
                                                                            "",
                                                                          round:
                                                                            "",
                                                                          title: _vm.$t(
                                                                            "Unlock"
                                                                          )
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.submitDownUnlock(
                                                                              downs
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "Unlock"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              width: "50px",
                                                              "justify-content":
                                                                "center",
                                                              display: "flex"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "el-tooltip",
                                                              {
                                                                staticClass:
                                                                  "item",
                                                                attrs: {
                                                                  effect:
                                                                    "light",
                                                                  placement:
                                                                    "bottom-start"
                                                                }
                                                              },
                                                              [
                                                                _vm._l(
                                                                  downs.StatusList,
                                                                  function(
                                                                    item,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key: index,
                                                                        attrs: {
                                                                          slot:
                                                                            "content"
                                                                        },
                                                                        slot:
                                                                          "content"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.formatterColumn(
                                                                              item
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                _c(
                                                                  "el-link",
                                                                  {
                                                                    staticStyle: {
                                                                      "font-weight":
                                                                        "bold"
                                                                    },
                                                                    attrs: {
                                                                      type:
                                                                        downs.StatusColor
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        downs.StatusCompletion
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              2
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        downs.processList
                                                          .length == 0
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  width: "60px"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    staticStyle: {
                                                                      padding:
                                                                        "0 0 0 !important"
                                                                    },
                                                                    attrs: {
                                                                      icon:
                                                                        "iconfont icon-mingxi",
                                                                      disabled:
                                                                        ""
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        downs.processList
                                                          .length > 0
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  width: "60px",
                                                                  "justify-content":
                                                                    "center",
                                                                  display:
                                                                    "flex"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "el-badge",
                                                                  {
                                                                    staticClass:
                                                                      "item",
                                                                    attrs: {
                                                                      value:
                                                                        downs.processCount
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        staticStyle: {
                                                                          padding:
                                                                            "0 0 0 !important"
                                                                        },
                                                                        attrs: {
                                                                          icon:
                                                                            "iconfont icon-mingxi",
                                                                          title: _vm.$t(
                                                                            "Declaration items uploaded / declaration items to be uploaded"
                                                                          )
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.showField(
                                                                              downs.processList
                                                                            )
                                                                          }
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        downs.fileList.length ==
                                                        0
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  width: "60px"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    staticStyle: {
                                                                      padding:
                                                                        "0 0 0 !important"
                                                                    },
                                                                    attrs: {
                                                                      icon:
                                                                        "iconfont icon-fujian",
                                                                      disabled:
                                                                        ""
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        downs.fileList.length >
                                                        0
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  width: "60px",
                                                                  "justify-content":
                                                                    "center",
                                                                  display:
                                                                    "flex"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "el-badge",
                                                                  {
                                                                    staticClass:
                                                                      "item",
                                                                    attrs: {
                                                                      value:
                                                                        downs.fileCount
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        staticStyle: {
                                                                          padding:
                                                                            "0 0 0 !important"
                                                                        },
                                                                        attrs: {
                                                                          icon:
                                                                            "iconfont icon-fujian",
                                                                          title: _vm.$t(
                                                                            "Declaration items uploaded / declaration items to be uploaded"
                                                                          )
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.showFileList(
                                                                              downs.fileList
                                                                            )
                                                                          }
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      display: "flex"
                                                    },
                                                    attrs: {
                                                      xs: 24,
                                                      sm: 12,
                                                      md: 8,
                                                      lg: 6,
                                                      xl: 6
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "fontTitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("PO CODE")
                                                          ) + " : "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "fontCont"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            downs
                                                              .PoRowDetailInfo
                                                              .Code
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: {
                                                      xs: 24,
                                                      sm: 12,
                                                      md: 8,
                                                      lg: 6,
                                                      xl: 6
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "fontTitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "PO PRODUCT NAME"
                                                            )
                                                          ) + " : "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "fontCont"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            downs
                                                              .PoRowDetailInfo
                                                              .ProductName
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: {
                                                      xs: 24,
                                                      sm: 12,
                                                      md: 8,
                                                      lg: 6,
                                                      xl: 6
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "fontTitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("LOSSRATE")
                                                          ) + " : "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "fontCont"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(downs.LossRate)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: {
                                                      xs: 24,
                                                      sm: 12,
                                                      md: 8,
                                                      lg: 6,
                                                      xl: 6
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "fontTitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "UseQuantityPO"
                                                            )
                                                          ) + " : "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "fontCont"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            downs.UsedNumber
                                                          ) +
                                                            "/" +
                                                            _vm._s(
                                                              downs
                                                                .PoRowDetailInfo
                                                                .UnitId
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-row",
                                              [
                                                (downs.PoRowDetailInfo
                                                  .RawFiberTypeId != ""
                                                ? true
                                                : false)
                                                  ? _c(
                                                      "el-col",
                                                      {
                                                        attrs: {
                                                          xs: 24,
                                                          sm: 12,
                                                          md: 8,
                                                          lg: 6,
                                                          xl: 6
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "fontTitle"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "FIBER TYPE"
                                                                )
                                                              ) + " : "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "fontCont"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                downs
                                                                  .PoRowDetailInfo
                                                                  .RawFiberTypeName
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                (downs.PoRowDetailInfo
                                                  .VendorAddressId != ""
                                                ? true
                                                : false)
                                                  ? _c(
                                                      "el-col",
                                                      {
                                                        attrs: {
                                                          xs: 24,
                                                          sm: 12,
                                                          md: 8,
                                                          lg: 6,
                                                          xl: 6
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "fontTitle"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t("ORIGIN")
                                                              ) + " : "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "fontCont"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                downs
                                                                  .PoRowDetailInfo
                                                                  .VendorAddressId
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c("el-col", {
                                                  attrs: {
                                                    xs: 24,
                                                    sm: 12,
                                                    md: 8,
                                                    lg: 6,
                                                    xl: 6
                                                  }
                                                }),
                                                _c("el-col", {
                                                  attrs: {
                                                    xs: 24,
                                                    sm: 12,
                                                    md: 8,
                                                    lg: 6,
                                                    xl: 6
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  }),
                  0
                ),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: _vm.$t("selectPO"),
                      visible: _vm.dialogTableVisible,
                      "modal-append-to-body": false,
                      width: "90%",
                      top: "2vh"
                    },
                    on: {
                      "update:visible": function($event) {
                        _vm.dialogTableVisible = $event
                      }
                    }
                  },
                  [
                    _c(
                      "avue-crud",
                      {
                        ref: "crud",
                        attrs: {
                          option: _vm.tableOpt,
                          "row-style": _vm.rowStyle,
                          "row-key": _vm.GetRowKeys,
                          data: _vm.potable,
                          page: _vm.page
                        },
                        on: {
                          "update:page": function($event) {
                            _vm.page = $event
                          },
                          "selection-change": _vm.selectionChange,
                          "current-change": _vm.currentChange
                        }
                      },
                      [
                        _c(
                          "template",
                          {
                            staticStyle: { width: "calc(100% - 80px)" },
                            slot: "menuLeft"
                          },
                          [
                            _c(
                              "el-row",
                              {
                                staticClass: "topFromSearch",
                                attrs: { gutter: 20 }
                              },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "fromOutDiv",
                                    attrs: {
                                      xs: 24,
                                      sm: 16,
                                      md: 16,
                                      lg: 19,
                                      xl: 20
                                    }
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "formDiv",
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 6,
                                          xl: 6
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("ProductCode")) + ":"
                                          )
                                        ]),
                                        _c("el-input", {
                                          on: {
                                            focus: function($event) {
                                              return _vm.focusDiv($event)
                                            },
                                            blur: function($event) {
                                              return _vm.blurDiv($event)
                                            }
                                          },
                                          model: {
                                            value: _vm.codeVal,
                                            callback: function($$v) {
                                              _vm.codeVal = $$v
                                            },
                                            expression: "codeVal"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "formDiv",
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 6,
                                          xl: 6
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.$t("BATCH")) + ":")
                                        ]),
                                        _c("el-input", {
                                          on: {
                                            focus: function($event) {
                                              return _vm.focusDiv($event)
                                            },
                                            blur: function($event) {
                                              return _vm.blurDiv($event)
                                            }
                                          },
                                          model: {
                                            value: _vm.batchVal,
                                            callback: function($$v) {
                                              _vm.batchVal = $$v
                                            },
                                            expression: "batchVal"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "formDiv",
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 6,
                                          xl: 6
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.$t("COLOR")) + ":")
                                        ]),
                                        _c("el-input", {
                                          on: {
                                            focus: function($event) {
                                              return _vm.focusDiv($event)
                                            },
                                            blur: function($event) {
                                              return _vm.blurDiv($event)
                                            }
                                          },
                                          model: {
                                            value: _vm.colorVal,
                                            callback: function($$v) {
                                              _vm.colorVal = $$v
                                            },
                                            expression: "colorVal"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "formDiv",
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 6,
                                          xl: 6
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { attrs: { id: "venSpan" } },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("VENDOR")) + ":"
                                            )
                                          ]
                                        ),
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              multiple: "",
                                              "collapse-tags": "",
                                              placeholder: "",
                                              clearable: "true"
                                            },
                                            on: {
                                              focus: function($event) {
                                                return _vm.focusDiv($event)
                                              },
                                              blur: function($event) {
                                                return _vm.blurDiv($event)
                                              },
                                              change: _vm.venchang
                                            },
                                            model: {
                                              value: _vm.vendorVal.ids,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.vendorVal,
                                                  "ids",
                                                  $$v
                                                )
                                              },
                                              expression: "vendorVal.ids"
                                            }
                                          },
                                          _vm._l(this.vendorData, function(
                                            item
                                          ) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "formDiv",
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 6,
                                          xl: 6
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("BEINGTIME")) + ":"
                                          )
                                        ]),
                                        _c("el-date-picker", {
                                          staticClass: "dataicon",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            type: "date",
                                            format: "yyyy-MM-dd",
                                            "value-format": "yyyy-MM-dd",
                                            "prefix-icon": ""
                                          },
                                          on: {
                                            focus: function($event) {
                                              return _vm.focusDiv($event)
                                            },
                                            blur: function($event) {
                                              return _vm.blurDiv($event)
                                            }
                                          },
                                          model: {
                                            value: _vm.beginTimeVal,
                                            callback: function($$v) {
                                              _vm.beginTimeVal = $$v
                                            },
                                            expression: "beginTimeVal"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "formDiv",
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 6,
                                          xl: 6
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("ENDTIME")) + ":"
                                          )
                                        ]),
                                        _c("el-date-picker", {
                                          staticClass: "dataicon",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            type: "date",
                                            format: "yyyy-MM-dd",
                                            "value-format": "yyyy-MM-dd",
                                            "prefix-icon": ""
                                          },
                                          on: {
                                            focus: function($event) {
                                              return _vm.focusDiv($event)
                                            },
                                            blur: function($event) {
                                              return _vm.blurDiv($event)
                                            }
                                          },
                                          model: {
                                            value: _vm.endTimeVal,
                                            callback: function($$v) {
                                              _vm.endTimeVal = $$v
                                            },
                                            expression: "endTimeVal"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "padding-top": "10px" },
                                    attrs: {
                                      xs: 24,
                                      sm: 8,
                                      md: 8,
                                      lg: 5,
                                      xl: 4
                                    }
                                  },
                                  [
                                    _c("el-button", {
                                      staticClass: "moreBtn",
                                      staticStyle: { "margin-right": "0" },
                                      attrs: {
                                        size: "small",
                                        icon: "iconfont icon-24px",
                                        circle: ""
                                      },
                                      on: { click: _vm.moreFrom }
                                    }),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                          plain: ""
                                        },
                                        on: { click: _vm.searchFrom }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("SEARCH")))]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "template",
                          { slot: "header" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.submitSelectData()
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Yes")))]
                            )
                          ],
                          1
                        ),
                        _c("template", { slot: "tip" })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: _vm.$t("Attachment list"),
                      visible: _vm.fileDialogTableVisible,
                      "modal-append-to-body": false,
                      width: "90%",
                      top: "2vh"
                    },
                    on: {
                      "update:visible": function($event) {
                        _vm.fileDialogTableVisible = $event
                      }
                    }
                  },
                  [
                    _c(
                      "avue-crud",
                      {
                        ref: "fileCrud",
                        attrs: {
                          option: _vm.filetableOpt,
                          "row-style": _vm.rowStyle,
                          "row-key": _vm.GetRowKeys,
                          data: _vm.fileList,
                          page: _vm.page
                        },
                        on: {
                          "update:page": function($event) {
                            _vm.page = $event
                          },
                          "selection-change": _vm.selectionChange,
                          "current-change": _vm.currentChange
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "menu",
                            fn: function(ref) {
                              var row = ref.row
                              var index = ref.index
                              var size = ref.size
                              var type = ref.type
                              return [
                                _c("el-button", {
                                  attrs: {
                                    icon: "el-icon-download",
                                    type: "text",
                                    size: "medium",
                                    title: _vm.$t("DOWNLOAD")
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.downloadFile(row)
                                    }
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      },
                      [_c("template", { slot: "tip" })],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: _vm.$t("Field list"),
                      visible: _vm.fieldDialogTableVisible,
                      "modal-append-to-body": false,
                      width: "90%",
                      top: "2vh"
                    },
                    on: {
                      "update:visible": function($event) {
                        _vm.fieldDialogTableVisible = $event
                      },
                      close: function($event) {
                        _vm.fieldDialogTableVisible = false
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: { height: "500px", "overflow-y": "auto" }
                      },
                      _vm._l(_vm.fieldList, function(process, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c(
                              "el-row",
                              {
                                staticStyle: {
                                  background: "#ebeef5",
                                  "padding-bottom": "7px"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      float: "left",
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      color: "rgb(44, 141, 244)"
                                    }
                                  },
                                  [_vm._v(_vm._s(process.title) + " : ")]
                                )
                              ]
                            ),
                            _vm._l(process.subjectList, function(
                              subject,
                              index
                            ) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: {
                                    height: "120px",
                                    "border-bottom": "0.3rem solid #EBEEF5"
                                  }
                                },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          float: "left",
                                          "font-weight": "bold",
                                          "font-size": "10px",
                                          color: "#2C8DF4"
                                        }
                                      },
                                      [_vm._v(_vm._s(subject.title))]
                                    )
                                  ]),
                                  _vm._l(subject.fieldList, function(
                                    field,
                                    index
                                  ) {
                                    return _c(
                                      "el-col",
                                      {
                                        key: index,
                                        staticStyle: {
                                          "margin-bottom": "10px"
                                        },
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 6,
                                          xl: 6
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "fontTitle" },
                                          [_vm._v(_vm._s(field.name) + " :")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "fontCont" },
                                          [_vm._v(_vm._s(field.value))]
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              )
                            })
                          ],
                          2
                        )
                      }),
                      0
                    )
                  ]
                ),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: _vm.$t("REJECT"),
                      visible: _vm.RejectdialogTableVisible,
                      "modal-append-to-body": false,
                      width: "70%",
                      top: "1vh"
                    },
                    on: {
                      "update:visible": function($event) {
                        _vm.RejectdialogTableVisible = $event
                      }
                    }
                  },
                  [
                    _c(
                      "el-form",
                      {
                        attrs: {
                          model: _vm.RejectEdit,
                          rules: _vm.rules,
                          "label-position": "top",
                          "label-width": "150px"
                        }
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12, sm: 24, md: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: _vm.$t("REJECTED COMPANY") }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: { disabled: true },
                                      model: {
                                        value:
                                          _vm.RejectEdit.ApprovedObjectName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.RejectEdit,
                                            "ApprovedObjectName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "RejectEdit.ApprovedObjectName"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12, sm: 24, md: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("REJECTION CATEGORY"),
                                      required: true,
                                      prop: "RejectCategory",
                                      rules: _vm.rules.RejectCategory
                                    }
                                  },
                                  [
                                    _c("el-autocomplete", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        "fetch-suggestions":
                                          _vm.RejectCategorySearch,
                                        "popper-append-to-body": false,
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.RejectEdit.RejectCategory,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.RejectEdit,
                                            "RejectCategory",
                                            $$v
                                          )
                                        },
                                        expression: "RejectEdit.RejectCategory"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24, sm: 24, md: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("REJECTION DESCRIPTION"),
                                      required: true,
                                      prop: "RejectRemark",
                                      rules: _vm.rules.RejectRemark
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        type: "textarea",
                                        maxlength: "300",
                                        "show-word-limit": "",
                                        autosize: { minRows: 4, maxRows: 6 }
                                      },
                                      model: {
                                        value: _vm.RejectEdit.RejectRemark,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.RejectEdit,
                                            "RejectRemark",
                                            $$v
                                          )
                                        },
                                        expression: "RejectEdit.RejectRemark"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              padding: "20px 0"
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.submitReject()
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Yes")))]
                            ),
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.RejectdialogTableVisible = false
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Cancel")))]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("avue-crud", {
                      attrs: {
                        option: _vm.tableOptRejectList,
                        data: _vm.tableDataRejectList,
                        "row-style": _vm.rowStyle,
                        page: _vm.pageRejectList
                      },
                      on: {
                        "update:page": function($event) {
                          _vm.pageRejectList = $event
                        },
                        "current-change": _vm.currentRejectListChange
                      }
                    })
                  ],
                  1
                ),
                _vm.IsApprove
                  ? _c(
                      "div",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "success" },
                            on: { click: _vm.ApprovedsubmitForm }
                          },
                          [_vm._v(_vm._s(_vm.$t("APPROVED")))]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "info" },
                            on: {
                              click: function($event) {
                                return _vm.getCancelApprove()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("CANCEL")))]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _c(
                      "el-row",
                      { staticStyle: { "background-color": "#cdebc7" } },
                      [
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 } },
                          [
                            _c("span", { staticClass: "fontTitle" }, [
                              _vm._v(_vm._s(_vm.$t("Unapproved items")) + " :")
                            ]),
                            _c("span", { staticClass: "fontSumCont" }, [
                              _vm._v(_vm._s(_vm.bottomInfo.TotalNoApprove))
                            ])
                          ]
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 } },
                          [
                            _c("span", { staticClass: "fontTitle" }, [
                              _vm._v(_vm._s(_vm.$t("Total suppliers")) + " :")
                            ]),
                            _c("span", { staticClass: "fontSumCont" }, [
                              _vm._v(
                                " " + _vm._s(_vm.bottomInfo.TotalSuppliers)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 } },
                          [
                            _c("span", { staticClass: "fontTitle" }, [
                              _vm._v(_vm._s(_vm.$t("Total Po")) + " :")
                            ]),
                            _c("span", { staticClass: "fontSumCont" }, [
                              _vm._v(_vm._s(_vm.bottomInfo.TotalPo) + " ")
                            ])
                          ]
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 } },
                          [
                            _c("span", { staticClass: "fontTitle" }, [
                              _vm._v(
                                _vm._s(_vm.$t("Total submission times")) + " :"
                              )
                            ]),
                            _c("span", { staticClass: "fontSumCont" }, [
                              _vm._v(
                                _vm._s(_vm.bottomInfo.TotalSubmissionTimes) +
                                  " "
                              )
                            ])
                          ]
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 } },
                          [
                            _c("span", { staticClass: "fontTitle" }, [
                              _vm._v(_vm._s(_vm.$t("Total documents")) + " :")
                            ]),
                            _c("span", { staticClass: "fontSumCont" }, [
                              _vm._v(_vm._s(_vm.bottomInfo.TotalDocuments))
                            ])
                          ]
                        ),
                        _c("el-col", {
                          attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "upTit" }, [
                  _vm._v(
                    _vm._s(_vm.$t("LoadingComplete")) +
                      _vm._s(_vm.$t("STIME")) +
                      ":" +
                      _vm._s(this.currentTime)
                  )
                ])
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }