import { pvhApiBase } from "@/config/env";
import request from '@/router/axios';


//�ļ���ʾ�б� ��ѯ
export const List = (page, pageSize, id) => {
    return request({
        url: pvhApiBase + "Tracing/GetSaleComponents",
        method: "get",
        params: {
            "id": id,
            "page": page,
            "pageSize": pageSize
        }
    })
}


//�����ļ���Ϣ�б�
export const add = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PostSaleComponent",
        method: "post",
        data: {
            ...row
        }
    })
}


//�ļ���Ϣ����
export const edit = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PutSaleComponent",
        method: "put",
        data: {
            ...row
        }
    })
}


//ɾ���ļ���Ϣ
export const del = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeleteSaleComponent",
        method: "delete",
        params: {
            id
        }
    })
}