<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{$t('DeclarationFile')}}</div>
            </div>
            <template>
                <avue-crud :option="setData.tableOpt" :data="tableData" :row-style="rowStyle">
                    <template slot="expand" slot-scope="props">
                        <el-table :data="props.row.details" style="width: 100%" :header-cell-style="tableHeaderColor">
                            <el-table-column type="index"></el-table-column>
                            <el-table-column prop="name" :label="$t('SUPPORT FILE NAME')"></el-table-column>
                            <el-table-column prop="code" :label="$t('SUPPORT FILE Code')"></el-table-column>
                        </el-table>
                    </template>
                </avue-crud>
            </template>
        </basic-container>
    </div>

</template>
<script>
    import { supportFileList } from "@/api/declareList";

    export default {
        data() {
            return {
                tableData: [],
            }
        },
        computed: {
            setData() {
                return {
                    tableOpt: {
                        page: false,
                        refreshBtn: false,
                        editBtn: false,
                        delBtn: false,
                        emptyText: this.$t('No Data'),
                        menu: false,
                        menuWidth: 140,
                        align: 'center',
                        columnBtn: false,
                        simplePage: true,
                        addBtn: false,
                        header: true,
                        expand: true,
                        rowKey: 'sectionProcessId',
                        expandRowKeys: [],
                        column: [     
                            {
                                label: this.$t('PROCESS NAME'),
                                prop: 'sectionProcessName',
                            }, {
                                label: this.$t('PROCESS CODE'),
                                prop: 'sectionProcessCode',
                            },
                            {
                                label: this.$t('PROCESS VERSION'),
                                prop: 'sectionProcessVersion',
                            }
                        ]
                    }
                }
            }
        },
        created() {
            this.getsupportFileList();
        },
        methods: {
            //���ñ�ͷ�е���ʽ
            tableHeaderColor() {
                return 'background-color:#838bb3!important;padding: 0!important;'

            },
            //��ʾ��ѯ�걨�ϴ��ļ������ֶ������б�
            async getsupportFileList() {
                await supportFileList().then(res => {
                    console.log(res.data);
                    this.tableData = res.data;
                }).catch(erro => {
                    console.log(erro);
                })
            },
            rowStyle({ rowIndex }) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
            },
        }
    }
</script>