<template>
  <div>
    <basic-container>
      <avue-crud :option="tableOption"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :page="page"
                 ref="crud"
                 :title='$t("Add")'
                 @current-change="currentChange"
                 @size-change="sizeChange"
                 @selection-change="selectionChange"
                 @refresh-change="refreshChange"
                 @row-save="handleSave"
                 @row-update="handleUpdate"
                 @row-del="handleDel">
        <template slot="menuLeft">
          <importBtn
              :outApi="this.proApis.CORPUSERSEXPORT"
              :innerAPI="this.proApis.CORPUSERSIMPORT"
              :API="this.proApis.CORPUSERSIMPORTTEMPLATE"
              @refresh="refreshData"
          ></importBtn>
        </template>
        <template slot="menuRight">
          <el-select clearable v-model="departmentId" :placeholder="$t('BELONGING DEPARTMENT')" size="small" @change="getID" style="width:100px !important;marginRight: 5px;">
            <el-option v-for="value in depmentList" :key="value.id" :label="value.name" :value="value.id"></el-option>
          </el-select>
          <el-select clearable v-model="postId" :placeholder="$t('JOB')" size="small" style="width:100px !important;marginRight: 5px;">
            <el-option v-for="value in postList" :key="value.id" :label="value.name" :value="value.id"></el-option>
          </el-select>
          <el-select clearable v-model="model" :placeholder="$t('STATUS')" size="small" style="width:100px !important;marginRight: 5px;">
            <el-option :label="$t('ON THE JOB')"  value="0">{{$t('ON THE JOB')}}</el-option>
            <el-option :label="$t('QUIT')"  value="1">{{$t('QUIT')}}</el-option>
            <el-option :label="$t('TO BE CONFIRMED')"  value="-1">{{$t('TO BE CONFIRMED')}}</el-option>
          </el-select>
          <el-input v-model="nameOrCod" :placeholder="$t('NAME OR NUMBER OF PERSONNEL')" style="display:inlie-block;width:200px;marginRight: 5px;" size="small" clearable></el-input>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="search">{{$t('SEARCH')}}</el-button>
        </template>
        <template slot="menu" slot-scope="{row,index,size,type}">
          <el-button icon="el-icon-data-analysis" :size="size" :type="type" @click="goReport(row)">{{$t("Check the report")}}</el-button>
        </template>
        <template slot-scope="{row,index,type}" slot="menuForm">
          <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" v-if="type=='add'" @click="$refs.crud.rowSave()">{{$t("Hold")}}</el-button>
          <el-button type="primary" icon="el-icon-circle-check" size="small" v-if="type=='edit'" @click="$refs.crud.rowUpdate()">{{$t("Hold")}}</el-button>
          <el-button icon="el-icon-circle-close" size="small" @click="$refs.crud.closeDialog()">{{$t("Cancel")}}</el-button>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {list,add,edit,remove,audit,exportExcl} from "@/api/pepoleOrder"
import importBtn from "@/components/importExport/importBtn";
import {calssList,refrechList,workList,getworkAll} from "@/api/config";

export default {
  components:{
    importBtn
  },
  data() {
    return {
      tableLoading: false,
      model: "",
      departmentId: "",
      postId: "",
      depmentList: [],
      postList: [],
      selectionList: [],
      nameOrCod: "",
      status: "0",
      page: {
        pageSize: 20,
        pagerCount: 5,
        total: '',
        pageCurrent: 1
      },
      disabled: true,
      tableData: [],
      tableOption: {
        delBtn: true,
        menuWidth: 200,
        refreshBtn: false,
        selection: true,
        tip: false,
        columnBtn: false,
        dialogFullscreen: true,
        addBtnText: this.$t('ADD'),
        delBtnText: this.$t('DELETE'),
        editBtnText: this.$t('Edit'),
        emptyText: this.$t('No Data'),
        menuTitle: this.$t('OPERATION'),
        editTitle: this.$t('Edit'),
        addTitle: this.$t('Add'),
        saveBtn: false,
        updateBtn: false,
        cancelBtn: false,
        column: [
          {
            label: this.$t('USER CODE'),
            prop: "code",
            width:80,
            placeholder: this.$t('PLEASE ENTER THE USER CODE'),
            rules: [{
              required: true,
              message: this.$t('PLEASE ENTER THE USER CODE'),
              trigger: "blur"
            }]
          },
          {
            label: this.$t('USER NAME'),
            prop: "name",
            width:100,
            placeholder: this.$t('PLEASE ENTER A USER NAME'),
            rules: [{
              required: true,
              message: this.$t('PLEASE ENTER A USER NAME'),
              trigger: "blur"
            }]
          },
          {
            label: this.$t('TELEPHONE'),
            prop: "phoneNumber",
            width:110,
            placeholder: this.$t('PLEASE ENTER PHONE NUMBER'),
            rules: [{
              required: true,
              message: this.$t('PLEASE ENTER PHONE NUMBER'),
              trigger: "blur"
            }]
          },
          {
            label: this.$t('GENDER'),
            value: 2,
            type: "select",
            prop: "gender",
            width:100,
            dicData: [{
              label: this.$t('MALE'),
              value: 1
            }, {
              label: this.$t('FEMALE'),
              value: 2
            }, {
              label: this.$t('UNKNOWN'),
              value: 0
            }, {
              label: this.$t('OTHER'),
              value: 3
            }],
            rules: [{
              required: true,
              message: this.$t('PLEASE SELECT GENDER'),
              trigger: "blur"
            }]
          },{
            label: this.$t('EMAIL'),
            prop: "email",
            width:200,
            placeholder: this.$t('PLEASE ENTER EMAIL ADDRESS'),
            rules: [{
              required: true,
              message: this.$t('PLEASE ENTER EMAIL ADDRESS'),
              trigger: "blur"
            }]
          },
          {
            label: this.$t('WORKSHOP'),
            type: "select",
            placeholder: this.$t('PLEASE SELECT WORKSHOP'),
            //hide: true,
            dicData: [],
            props: {
              label: "name",
              value: "id"
            },
            prop: "workShopId",
            change: function (value) {
              this.$set(this.tableOption.column[6], "dicData", []);
              console.log(value);
              value.value ? refrechList(value.value).then(res => {
                this.$set(this.tableOption.column[6], "dicData", res.data.data);
              }) : "";
            }.bind(this),
            formatter: function (row) {
              let name = row.workShopName;
              return name;
            }
          }, {
            label: this.$t('TEAM'),
            dicData: [],
            placeholder: this.$t('PLEASE SELECT A TEAM'),
            //hide: true,
            type: "select",
            props: {
              label: "name",
              value: "id"
            },
            prop: "groupId",
            formatter: function (row) {
              let name = row.groupName;
              return name;
            }
          },
          {
            label: this.$t('BELONGING DEPARTMENT'),
            placeholder: this.$t('PLEASE SELECT THE DEPARTMENT'),
            //hide: true,
            dicData: [],
            props: {
              label: "name",
              value: "id"
            },
            type: "select",
            prop: "departmentId",
            rules: [{
              required: true,
              message: this.$t('PLEASE ENTER YOUR DEPARTMENT'),
              trigger: "blur"
            }],
            formatter: function (row) {
              let name = row.departmentName;
              return name;
            }
          },
          {
            label: this.$t('JOB'),
            placeholder: this.$t('PLEASE SELECT A POSITION'),
            //hide: true,
            dicData: [],
            props: {
              label: "name",
              value: "id"
            },
            type: "select",
            prop: "postId",
            rules: [{
              required: true,
              message: this.$t('PLEASE ENTER POSITION'),
              trigger: "blur"
            }],
            formatter: function (row) {
              let name = row.postName;
              return name;
            }
          },
          {
            label: this.$t('STATUS'),
            //value: 0,
            type: "select",
            addDisplay: false,
            dicData: [
              {
                label: this.$t('QUIT'),
                value: 1
              },
              {
                label: this.$t('TO BE CONFIRMED'),
                value: -1
              },
              {
                label: this.$t('ON THE JOB'),
                value: 0
              }, {
                label: this.$t('REFUSE'),
                value: -9
              }
            ],
            prop: "status",
          }]
      }
    }
  },
  async created() {
    let res = await this.request.get(this.businessUrl + "department/selectWorkShopName?type=1");
    this.wkshopList = res.data.data;
    let res1 = await this.request.get(this.businessUrl + "department/selectWorkShopName?type=0");
    let res2 = await this.request.post(this.businessUrl + "post/selectAllPost");
    console.log(res1);
    if (this.$route.query.data){
      this.departmentId = this.$route.query.data.id;
    }
    console.log(this.departmentId);
    console.log(this.$route.query.data);
    this.depmentList = res1.data.data;
    this.postList = res2.data.data;

    //车间
    calssList().then(res => {
      this.tableOption.column[5].dicData = res.data.data;
    });
    //部门
    getworkAll().then(res => {
      this.tableOption.column[7].dicData = res.data.data;
    });
    //岗位
    workList().then(res => {
      this.tableOption.column[8].dicData = res.data.data;
    })
    this.list();
  },
  methods: {
    //查看报表
    goReport(item) {
      this.$router.push({ path: '/searchDashboard/reportStyle', query: { type: 'user', groupId: item.groupId, userId: item.userId} })
    },
    getID(val) {
      this.departmentId = val
    },
    currentChange(pageCurrent) {
      this.page.pageCurrent = pageCurrent;
      this.list();
    },
    sizeChange(pageSize) {
      this.page.pageCurrent = 1;
      this.page.pageSize = pageSize;
      this.list();
    },
    search() {
      this.list();
    },
    refreshData() {
      this.list();
    },
    exportexcl() {
      exportExcl().then(res => {
        this.downloadExecl(res.data,"公司员工档案");
      });
    },
    // 下载execl文件
    downloadExecl(execlBuffer, fileName) {
      const blob = new Blob([execlBuffer], {type: 'application/vnd.ms-excel'})
      if ('download' in document.createElement('a')) {
        const link = document.createElement('a')//创建a标签
        link.download = fileName//a标签添加属性
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click()//执行下载
        URL.revokeObjectURL(link.href) //释放url
      }
    },
    returnAudit() {
      audit(this.selectionList[0].id,0).then(() => {
        this.$message({
          type: "success",
          message: this.$t('DE APPROVAL SUCCEEDED')
        });
        this.list();
      })
    },
    audit() {
      audit(this.selectionList[0].id,1).then(() => {
        this.$message({
          type: "success",
          message: this.$t('AUDIT SUCCEEDED')
        });
        this.list();
      });
    },
    handleDel(row,done) {
      this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        remove(row.id).then((res) => {
          if(res.data.code == "0000"){
            this.$message({
              type: "success",
              message: "删除成功",
            });
          }
          this.list();
        })
      })
    },
    handleUpdate(row,index,done) {
      edit(row).then((res) => {
        if(res.data.code == "0000"){
          this.$message({
            type: "success",
            message: "编辑成功",
          });
        }
        this.list();
        done();
      })
    },
    handleSave(row,done) {
      add(row).then((res) => {
        if(res.data.code == "0000"){
          this.$message({
            type: "success",
            message: "新增成功",
          });
        }
        this.list();
        done();
      })
    },
    change(row) {
      changeStatus(row)
    },
    selectionChange(list) {
      this.selectionList = list;
      console.log(list);
      list != "" ? this.disabled = false : this.disabled = true;
    },
    refreshChange() {
      this.list();
    },
    list(done) {
      list(this.page.pageCurrent,this.page.pageSize,this.departmentId,this.postId,this.model,this.nameOrCod).then(res => {
        if (res.data.data) {
          this.tableData = res.data.data.items;
          this.page.total = res.data.data.total
          this.tableData.forEach(v => {
            v.status = v.status;
          })
        }else {
          this.tableData = [];
          this.page.total = 0+"";
        }
      })
    },
    envText: function() {
      return this.env
    }
  }
}
</script>
<style scoped>
.el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  padding-left: 10px;
}
</style>