var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c("avue-crud", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            ref: "crud",
            attrs: {
              option: _vm.tableOption,
              data: _vm.tableData,
              page: _vm.page
            },
            on: {
              "current-change": _vm.currentChange,
              "size-change": _vm.sizeChange
            },
            scopedSlots: _vm._u([
              {
                key: "menu",
                fn: function(ref) {
                  var row = ref.row
                  var index = ref.index
                  var size = ref.size
                  var type = ref.type
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-data-analysis",
                          size: size,
                          type: type
                        },
                        on: {
                          click: function($event) {
                            return _vm.goPermission(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Permissions to")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-data-analysis",
                          size: size,
                          type: type
                        },
                        on: {
                          click: function($event) {
                            return _vm.goUser(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Users to")))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }