import request from '@/router/axios';
import { pvhApiBase } from "@/config/env";

//��ѯ��ʾ֧�����ļ��б�
export const subjectList = (corporationId, orderId, processId, page, pageSize) => {
    return request({
        url: pvhApiBase + "Tracing/GetSubjects",
        method: "get",
        params: {
            "corporationId": corporationId,
            "orderId": orderId,
            "processId": processId,
            "page": page,
            "pageSize": pageSize
        }
    })
}


//��������֧�����ļ���Ϣ
export const editSubject = (params) => {
    return Request({
        url: pvhApiBase + "Tracing/PutSubject",
        method: "put",
        data: {
            ...params
        }
    })
}

//ɾ��ĳ��֧�����ļ���Ϣ
export const delSubject = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeleteSubject",
        method: "delete",
        params: {
            id
        }
    })
}

