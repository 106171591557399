var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "20px" }
              },
              [
                _vm._v(
                  _vm._s(
                    this.formData.sign == "add"
                      ? _vm.$t("SupplierSectionAdd")
                      : _vm.$t("SupplierSectionModify")
                  )
                )
              ]
            )
          ]),
          [
            _c(
              "div",
              { staticClass: "Info" },
              [
                _c(
                  "el-form",
                  {
                    ref: "from",
                    attrs: {
                      model: _vm.formData,
                      "label-position": "right",
                      "show-message": _vm.notip
                    }
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Code"),
                                  prop: "codeVal",
                                  "label-width": "140px",
                                  required: true,
                                  rules: _vm.formData.fromaDataRules.codeVal
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: { maxlength: "64", clearable: "true" },
                                  model: {
                                    value: _vm.formData.codeVal,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "codeVal", $$v)
                                    },
                                    expression: "formData.codeVal"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Name"),
                                  prop: "nameVal",
                                  "label-width": "140px",
                                  required: true,
                                  rules: _vm.formData.fromaDataRules.nameVal
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: { maxlength: "64", clearable: "true" },
                                  model: {
                                    value: _vm.formData.nameVal,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "nameVal", $$v)
                                    },
                                    expression: "formData.nameVal"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("LOSSRATEMIN"),
                                  prop: "lossRateMinVal",
                                  "label-width": "140px",
                                  required: true,
                                  rules:
                                    _vm.formData.fromaDataRules.lossRateMinVal
                                }
                              },
                              [
                                _c("el-input-number", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: {
                                    controls: false,
                                    clearable: "",
                                    min: 0
                                  },
                                  model: {
                                    value: _vm.formData.lossRateMinVal,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "lossRateMinVal",
                                        $$v
                                      )
                                    },
                                    expression: "formData.lossRateMinVal"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("LOSSRATEMAX"),
                                  prop: "lossRateMaxVal",
                                  "label-width": "140px",
                                  required: true,
                                  rules:
                                    _vm.formData.fromaDataRules.lossRateMaxVal
                                }
                              },
                              [
                                _c("el-input-number", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: {
                                    controls: false,
                                    clearable: "",
                                    min: 0
                                  },
                                  model: {
                                    value: _vm.formData.lossRateMaxVal,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "lossRateMaxVal",
                                        $$v
                                      )
                                    },
                                    expression: "formData.lossRateMaxVal"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("UNITGROUPS"),
                                  prop: "unitGroupsVal",
                                  "label-width": "140px",
                                  required: true,
                                  rules:
                                    _vm.formData.fromaDataRules.unitGroupsVal
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: {
                                      width: "calc(100% - 115px)"
                                    },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "",
                                      clearable: "true"
                                    },
                                    model: {
                                      value: _vm.formData.unitGroupsVal,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "unitGroupsVal",
                                          $$v
                                        )
                                      },
                                      expression: "formData.unitGroupsVal"
                                    }
                                  },
                                  _vm._l(this.groupList, function(item) {
                                    return _c("el-option", {
                                      key: item,
                                      attrs: { label: item, value: item }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("SORT"),
                                  prop: "sortVal",
                                  "label-width": "140px"
                                }
                              },
                              [
                                _c("el-input-number", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  model: {
                                    value: _vm.formData.sortVal,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "sortVal", $$v)
                                    },
                                    expression: "formData.sortVal"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("isLastStep"),
                                  prop: "isLastStepVal",
                                  "label-width": "140px",
                                  rules:
                                    _vm.formData.fromaDataRules.isLastStepVal
                                }
                              },
                              [
                                _c("el-switch", {
                                  model: {
                                    value: _vm.formData.isLastStepVal,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "isLastStepVal",
                                        $$v
                                      )
                                    },
                                    expression: "formData.isLastStepVal"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Status"),
                                  prop: "statusVal",
                                  "label-width": "140px"
                                }
                              },
                              [
                                _c("el-switch", {
                                  model: {
                                    value: _vm.formData.statusVal,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "statusVal", $$v)
                                    },
                                    expression: "formData.statusVal"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("VERSION NUMBER"),
                                  "label-width": "140px",
                                  required: true,
                                  rules: _vm.formData.fromaDataRules.versionVal
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: { maxlength: "36", clearable: "true" },
                                  model: {
                                    value: _vm.formData.versionVal,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "versionVal", $$v)
                                    },
                                    expression: "formData.versionVal"
                                  }
                                }),
                                _c("el-button", {
                                  attrs: {
                                    title: _vm.$t("Random Generation"),
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-sunrise",
                                    circle: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.randomGeneration()
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t(
                                    "DEFAULT FIBER CLASSIFICATION"
                                  ),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "DEFAULT FIBER CLASSIFICATION"
                                      ),
                                      "label-width": "140px"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        maxlength: "255",
                                        clearable: "true"
                                      },
                                      model: {
                                        value:
                                          _vm.formData.classMainRawMaterialsVal,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "classMainRawMaterialsVal",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData.classMainRawMaterialsVal"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "smallTit" }, [
                      _vm._v(_vm._s(_vm.$t("Process")))
                    ]),
                    _c(
                      "el-table",
                      {
                        attrs: {
                          data: _vm.formData.tableData,
                          "row-class-name": _vm.tableRowClassName,
                          "header-cell-class-name": _vm.starAdd,
                          "row-key": "getRowKey() "
                        },
                        on: {
                          "current-change": _vm.handleCurrentChange,
                          "row-click": _vm.rowClick
                        }
                      },
                      [
                        _vm.formData.show
                          ? _c("el-table-column", [
                              _c("span", [_vm._v(_vm._s(_vm.scope.row.Id))]),
                              _c("span", [_vm._v(_vm._s(_vm.scope.row.Sort))])
                            ])
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("Index"),
                            type: "index",
                            width: "50px"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("SORT"), align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-button", {
                                    attrs: {
                                      icon: "el-icon-top",
                                      type: "text",
                                      size: "medium",
                                      title: _vm.$t("Moveup")
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.Moveup(scope.$index)
                                      }
                                    }
                                  }),
                                  _c("el-button", {
                                    attrs: {
                                      icon: "el-icon-bottom",
                                      type: "text",
                                      size: "medium",
                                      title: _vm.$t("Movedown")
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.Movedown(scope.$index)
                                      }
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("CODE"), align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.Code))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." + scope.$index + ".Code",
                                        rules: _vm.formData.fromaDataRules.Code
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-input", {
                                            attrs: {
                                              maxlength: "64",
                                              clearable: "true"
                                            },
                                            model: {
                                              value: scope.row.Code,
                                              callback: function($$v) {
                                                _vm.$set(scope.row, "Code", $$v)
                                              },
                                              expression: "scope.row.Code"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("NAME"), align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.Name))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." + scope.$index + ".Name",
                                        rules: _vm.formData.fromaDataRules.Name
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              maxlength: "64",
                                              clearable: "true"
                                            },
                                            model: {
                                              value: scope.row.Name,
                                              callback: function($$v) {
                                                _vm.$set(scope.row, "Name", $$v)
                                              },
                                              expression: "scope.row.Name"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("DOCUMENT"), align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.Files.length))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." + scope.$index + ".Files"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(scope.row.Files.length)
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("NUMBER"), align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.Fields.length))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".Fields"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(scope.row.Fields.length)
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("FIBER TYPE"),
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.RawFiberTypeName)
                                        )
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".RawFiberTypeId"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                "value-key": "id",
                                                filterable: "",
                                                placeholder: " "
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.rawFiberchange(
                                                    scope.row
                                                  )
                                                }
                                              },
                                              model: {
                                                value: scope.row.RawFiberTypeId,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "RawFiberTypeId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.RawFiberTypeId"
                                              }
                                            },
                                            _vm._l(_vm.rawFiberData, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("DECLARE TYPE"),
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("el-switch", {
                                        model: {
                                          value: scope.row.DeclareType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "DeclareType",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.DeclareType"
                                        }
                                      })
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".DeclareType"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-switch", {
                                            model: {
                                              value: scope.row.DeclareType,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "DeclareType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.DeclareType"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("DECLARE NOT REQUIRED"),
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("el-switch", {
                                        model: {
                                          value: scope.row.NotRequiredDeclare,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "NotRequiredDeclare",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scope.row.NotRequiredDeclare"
                                        }
                                      })
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".NotRequiredDeclare"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-switch", {
                                            model: {
                                              value:
                                                scope.row.NotRequiredDeclare,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "NotRequiredDeclare",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.NotRequiredDeclare"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("CAN INSIDE CITE"),
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("el-switch", {
                                        model: {
                                          value: scope.row.InsideCanCite,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "InsideCanCite",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.InsideCanCite"
                                        }
                                      })
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".InsideCanCite"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-switch", {
                                            model: {
                                              value: scope.row.InsideCanCite,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "InsideCanCite",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.InsideCanCite"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("IS OUTBOUND"),
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("el-switch", {
                                        model: {
                                          value: scope.row.IsCrossBorder,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "IsCrossBorder",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.IsCrossBorder"
                                        }
                                      })
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".IsCrossBorder"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-switch", {
                                            model: {
                                              value: scope.row.IsCrossBorder,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "IsCrossBorder",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.IsCrossBorder"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("Enable"), align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("el-switch", {
                                        model: {
                                          value: scope.row.Status,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "Status", $$v)
                                          },
                                          expression: "scope.row.Status"
                                        }
                                      })
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".Status"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-switch", {
                                            model: {
                                              value: scope.row.Status,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "Status",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.Status"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("ALLOW MULTIPLE"),
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("el-switch", {
                                        model: {
                                          value: scope.row.Allowmultiple,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "Allowmultiple",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.Allowmultiple"
                                        }
                                      })
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".Allowmultiple"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-switch", {
                                            model: {
                                              value: scope.row.Allowmultiple,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "Allowmultiple",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.Allowmultiple"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("OPERATION"),
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-button", {
                                    attrs: {
                                      title: _vm.$t("Set Data"),
                                      size: "small",
                                      icon: "el-icon-setting",
                                      circle: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.setIpu(scope.row)
                                      }
                                    }
                                  }),
                                  _c("el-button", {
                                    attrs: {
                                      title: _vm.$t("Delete"),
                                      type: "danger",
                                      size: "small",
                                      icon: "el-icon-delete",
                                      circle: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteItem(
                                          scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "right",
                          padding: "50px 0"
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.submitForm }
                          },
                          [_vm._v(_vm._s(_vm.$t("Save")))]
                        ),
                        _c("el-button", { on: { click: _vm.resetForm } }, [
                          _vm._v(_vm._s(_vm.$t("CANCEL")))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          _c(
            "el-dialog",
            {
              staticClass: "addDia",
              attrs: {
                title: _vm.$t("Define process declaration data"),
                visible: _vm.centerDialogVisible,
                height: "60%",
                width: "50%",
                "font-size": "20px",
                "show-close": false,
                center: ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.centerDialogVisible = $event
                }
              }
            },
            [
              _c("el-divider"),
              _c(
                "div",
                { staticClass: "formD upDiv" },
                [
                  _c("div", { staticClass: "smallTit" }, [
                    _vm._v(_vm._s(_vm.$t("Input Fields")))
                  ]),
                  _c(
                    "el-checkbox-group",
                    {
                      staticStyle: {
                        margin: "0",
                        display: "flex",
                        "justify-content": "flex-start",
                        "flex-wrap": "wrap"
                      },
                      model: {
                        value: _vm.FieldList,
                        callback: function($$v) {
                          _vm.FieldList = $$v
                        },
                        expression: "FieldList"
                      }
                    },
                    _vm._l(this.SupportFieldData, function(item) {
                      return _c(
                        "el-checkbox",
                        {
                          key: item.value,
                          staticStyle: {
                            width: "50%",
                            margin: "0",
                            display: "flex",
                            "align-items": "center"
                          },
                          attrs: { label: item.value }
                        },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c("el-divider"),
              _c(
                "div",
                { staticClass: "formD upDiv" },
                [
                  _c("div", { staticClass: "smallTit" }, [
                    _vm._v(_vm._s(_vm.$t("Upload Files")))
                  ]),
                  _c(
                    "el-checkbox-group",
                    {
                      staticStyle: {
                        margin: "0",
                        display: "flex",
                        "justify-content": "flex-start",
                        "flex-wrap": "wrap"
                      },
                      model: {
                        value: _vm.FileList,
                        callback: function($$v) {
                          _vm.FileList = $$v
                        },
                        expression: "FileList"
                      }
                    },
                    _vm._l(this.SupportFileData, function(item) {
                      return _c(
                        "el-checkbox",
                        {
                          key: item.value,
                          staticStyle: {
                            width: "50%",
                            margin: "0",
                            display: "flex",
                            "align-items": "center"
                          },
                          attrs: { label: item.value }
                        },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.savaVal($event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Save")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "" },
                      on: {
                        click: function($event) {
                          _vm.centerDialogVisible = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }