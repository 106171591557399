<template>
  <div>
    <basic-container>
		<div>
			<span>{{$t('UserName')}}：</span>
			<el-input v-model="$route.query.userName" readonly placeholder="" style="width: 200px;height:50px;"></el-input>
			<el-input v-model="userId" placeholder="" style="width: 200px;height:50px;display:none"></el-input>
		</div>
			
      <!-- 树结构 -->
		<div style="marginTop: 20px;display: flex;">
			{{$t('PERMISSION')}}：
			<el-tree :data="data"
					 show-checkbox
					 node-key="id"
					 ref="tree"
					 highlight-current
					 @check="clickCheck"
					 @setCheckedKeys="setCheckedKeys"
					 @node-collapse="nodeCollapse"
					 slot="reference"
					 :props="defaultProps">
			</el-tree>
			<div style="marginLeft: 500px">{{check.label}}</div>
			<el-popover style="marginTop:40px;marginLeft:-120px"
						v-model="visity"
						placement="right"
						width="100"
						:title="$t('DATA PERMISSIONS')">				
				<el-checkbox-group v-model="checkedCities" @change="checkRow">
					<el-checkbox v-for="city in cities" :label="city.id" :key="city.id">{{city.name}}</el-checkbox>
				</el-checkbox-group>
				<!-- <el-radio-group v-model="radio" style="height: 200px"> -->
				<!-- <el-checkbox v-model="checked1" @change="checked=>checkRow(checked,checked1)" label="1">个人</el-checkbox>
	<el-checkbox v-model="checked2" label="2">部门</el-checkbox>
	<el-checkbox v-model="checked3" checked label="4">公司</el-checkbox>
	<el-checkbox v-model="checked4" label="8">集团/品牌</el-checkbox> -->
				<!-- </el-radio-group> -->
				<!-- <el-button slot="reference">click 激活</el-button> -->
			</el-popover>
			<el-popover style="marginTop: 180px"
						v-model="visity"
						placement="right"
						width="100"
						:title="$t('PERMISSION LEVEL')">
				<el-checkbox-group v-model="ckList.checkList" @change="e => changeCheckbox(e, ckList.radios)">
					<el-checkbox v-for="value in ckList.radios" :value="value.id" :label="value.id" :key="value.id" :disabled="ckList.checkList.includes(ckList.radios[ckList.radios.length - 1].id) && value.id !== ckList.radios[ckList.radios.length - 1].id">{{value.name}}</el-checkbox>
					<el-button @click="save" size="small" style="" type="primary">{{this.$t('SAVE')}}</el-button>
				</el-checkbox-group>
				<!-- <el-radio v-model="radios" :label="3">授予</el-radio>
		<el-radio v-model="radios" :label="6">拒绝</el-radio>
		<el-radio v-model="radios" :label="9">拥有</el-radio> -->
			</el-popover>

		</div>
	  <el-button type="info" @click="updateUserPermission" style="marginTop: 20px;marginLeft:50px">{{$t('save')}}</el-button>

    </basic-container>
  </div>
</template>
<script>
    import { addUserPermission,userJurisdiction} from '@/api/userPermission'
  export default {
		data() {
			return {
                userName: '',
                userId: '',
				tableData: [], //表结构
				radio:'1',
				radios:'',
				visity: true,
				check: {},
                checkedKeys: [],
				checkedCities: [4],
				radioCities:[1],
                cities: [{ id: 1, name: this.$t('Individual') }
                    , { id: 2, name: this.$t('Department') }
                    , { id: 4, name: this.$t('Company') }
                    , { id: 8, name: this.$t('Group/Brand') }],
                ckList: {
                    checkList: [],
                    radios: [{ id: 1, name: this.$t('Own') }
                        , { id: 2, name: this.$t('Grant') }
                        , { id: 4, name: this.$t('Grantable') }
                        , { id: 8, name: this.$t('Reject') }],
                },
				checked1:1,
				checked2:2,
				checked3:4,
				checked4:8,
				strArray: '',
				str:'',
				checkId:'4',
				radioId:'1',
				saveList: [],
         // 树结构数据
        data: [],
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        // 表结构数据
      };
      },
    created() {
			//权限
		this.getUserJurisdiction();
        //this.userName = this.$route.query.userName;
        this.userId = this.$route.query.userId;
      },
    methods: {
			save() {
					this.strArray = this.str + ";" + this.checkId + ';' + this.radioId
					this.saveList.push(this.strArray)
		},
		//保存用户权限
        updateUserPermission() {
            this.strArray = this.str + ";" + this.checkId + ';' + this.radioId
			addUserPermission(this.userId, "", this.checkedKeys, this.saveList).then(res => {
				if (res.data != null && res.data != "") {
                    this.$message({
                        message: this.$t('OperationSuccess'),
                        type: "success"
                    });
                }
                //this.tokenId = res.data.result
                //this.list()
            })
        },
			// 获取权限
			getUserJurisdiction() {
				userJurisdiction("").then(res => {
					this.data= res.data
				})
			},
			// 选中的节点
			clickCheck(currentObj, treeStatus) {
				this.str = currentObj.id
				this.check = currentObj
				this.checkedKeys = treeStatus.checkedKeys
			},
			checkRow(checked,item) {
				this.checkId = eval(checked.join("+"))
			},
			changeCheckbox(e, item) {
				this.radioId = eval(e.join('+'))
				if (this.radioId == undefined) {
					return ''
				}
				const val = item[item.length - 1].id;
				if (e[e.length - 1] === val) {
					e.splice(0, e.length - 1)
				}
			},
			setCheckedKeys(keys, leafOnly) {
			},
			nodeCollapse(key,value) {
			}
  }
  };
</script>
 