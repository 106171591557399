import request from '@/router/axios';
import {businessUrl} from '@/config/env'
export const list = (id = "05b4b2a3d01a470eb47eae52fd7395bf") => {
    return request({
        url : businessUrl + "dict/selectDictByCategoryId",
        method: "get",
        params: {
            categoryId: id
        }
    })
}
export const add = (id,params) => {
    return request({
        url: businessUrl + "dict/insert",
        method: "post",
        data: {
            categoryId: id,
            ...params
        }
    })
}
export const edit = (id,params) => {
    return request({
        url: businessUrl + "dict/updateByPrimaryKeySelective",
        method: "put",
        data: {
            id,
            ...params
        }
    })
}
export const remove = (id) => {
    return request({
        url: businessUrl + "dict/delete",
        method: "delete",
        params: {
            id
        }
    })
}
export const treeList = () => {
    return request({
        url: businessUrl + "dictCategory/selectAll"
    })
}