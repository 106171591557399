import request from '@/router/axios';
import { pvhApiBase } from '@/config/env';
// 查询枚举主列表
export const downList = (remark, param1) => {
	return request({
			url: pvhApiBase + "Tracing/GetUnifiedDropDowns",
			method: "get",
			params: {
					"remark": remark,
					"param1": param1
			}
	})

}
// 查询枚举子列表
export const downSonList = (name, value, parentKey) => {
	return request({
			url: pvhApiBase + "Tracing/GetEnumItemlist",
			method: "get",
			params: {
					"name": name,
					"value": value,
					"parentKey": parentKey
			}
	})
}
// 子列表新增
export const addSonList = (parmas,parentKey) => {
	return request({
			url: pvhApiBase + "Tracing/PostEnumItem",
			method: "post",
			data: {
				...parmas,
				"parentKey":parentKey
			}
	})
}
// 子列表编辑
export const editSonList = (parmas) => {
	return request({
			url: pvhApiBase + "Tracing/PutEnumItem",
			method: "put",
			data: {
				...parmas
			}
	})
}
// 子列表删除
export const delSonList = (id) => {
	return request({
			url: pvhApiBase + "Tracing/DeleteEnumItem",
			method: "DELETE",
			params: {
				id
			}
	})
}
//判断枚举item是否一致
export const  itemIsAgreement= (remark,id,param1,param2) => {
	return request({
			url: pvhApiBase + "Tracing/UnifiedReturnBool",
			method: "get",
			params: {
					"remark": remark,
					"id": id,
					"param1": param1,
					"param2": param2
			}
	})
}
//判断枚举value是否一致
export const  valueIsAgreement= (remark,id,param1,param2) => {
	return request({
			url: pvhApiBase + "Tracing/UnifiedReturnBool",
			method: "get",
			params: {
					"remark": remark,
					"id": id,
					"param1": param1,
					"param2": param2
			}
	})
}