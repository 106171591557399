<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:10px;">{{$t('DeclarationField')}}</div>
            </div>
            <template>
                <avue-crud :option="setData.tableOpt" :data="tableData" :row-style="rowStyle" >

                    <template slot="expand" slot-scope="props">
                        <el-table :data="props.row.details" style="width: 100%" :header-cell-style="tableHeaderColor">
                            <el-table-column type="index"></el-table-column>
                            <el-table-column prop="name" :label="$t('SUPPORT FIELD NAME')"></el-table-column>
                            <el-table-column prop="code" :label="$t('SUPPORT FIELD Code')"></el-table-column>
                        </el-table>
                    </template>
                </avue-crud>
            </template>
        </basic-container>

    </div>
</template>
<style>
    .othChe .el-checkbox__label {
        color: #409eff !important;
    }

    .dataicon i {
        display: none;
    }
</style>
<script>
    import { supportFieldList } from "@/api/declareList";

    export default {
        data() {
            return {
                tableData: [],
            }
        },
        created() {
            this.getsupportFieldList();
        },
        computed: {
            setData() {
                return {
                    tableOpt: {
                        page: false,
                        refreshBtn: false,
                        addBtn: false,
                        editBtn: false,
                        delBtn: false,
                        menu: false,
                        emptyText: this.$t('No Data'),
                        delBtnText: this.$t('Delete'),
                        align: 'center',
                        columnBtn: false,
                        simplePage: true,
                        expand: true,
                        rowKey: 'sectionProcessId',
                        expandRowKeys: [],
                        column: [
                            {
                                label: this.$t('PROCESS NAME'),
                                prop: 'sectionProcessName',
                            }, {
                                label: this.$t('PROCESS CODE'),
                                prop: 'sectionProcessCode',
                            },
                            {
                                label: this.$t('PROCESS VERSION'),
                                prop: 'sectionProcessVersion',
                            }
                        ]
                    }
                }
            }
        },
        methods: {
            //���ñ�ͷ�е���ʽ
            tableHeaderColor({ row, column, rowIndex, columnIndex }) {
                return 'background-color:#838bb3!important;padding: 0!important;'

            }, //����������
            rowStyle({ row, column, rowIndex }) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
            },
            //��ʾ��ѯ�걨�ֶ������б�
            async getsupportFieldList() {
                await supportFieldList().then(res => {
                    console.log(res.data);
                    this.tableData = res.data;
                }).catch(erro => {
                    console.log(erro);
                })
            },
        }
    }
</script>