<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:10px;">{{$t('PURCHASE ORDER')}}</div>
            </div>
            <template>
                <el-row :gutter="20" class="topFromSearch">
                    <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('PO')}}:</span>
                            <el-input v-model="poVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('BUSINESS SERIAL NUMBER INTERNAL')}}:</span>
                            <el-input v-model="businessnoinVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('BUSINESS SERIAL NUMBER EXTERNAL')}}:</span>
                            <el-input v-model="businessnooutVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('ProductCode')}}:</span>
                            <el-input v-model="codeVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('BATCH')}}:</span>
                            <el-input v-model="batchVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span id="venSpan">{{$t('VENDOR')}}:</span>
                            <el-select v-model="vendorVal.ids" @focus="focusDiv($event)" @blur="blurDiv($event)" multiple collapse-tags placeholder="" style="width:100%;" @change="venchang" clearable="true">
                                <el-option v-for="item in this.vendorData"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('BEINGTIME')}}:</span>
                            <el-date-picker class="dataicon" v-model="beginTimeVal"
                                            type="date" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                            format="yyyy-MM-dd" value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                            </el-date-picker>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('ENDTIME')}}:</span>
                            <el-date-picker class="dataicon" v-model="endTimeVal"
                                            type="date" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                            format="yyyy-MM-dd" value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                            </el-date-picker>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('COLOR')}}:</span>
                            <el-input v-model="colorVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6" v-if="!isBrandBusiness">
                            <span id="otherStatusSpan">{{$t('STATE')}}:</span>
                            <el-select v-model="otherStatusVal" @focus="focusDiv($event)" @blur="blurDiv($event)" collapse-tags placeholder="" style="width:100%;" @change="otherStatuschang" clearable="true">
                                <el-option v-for="item in this.otherStatusData"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6" v-if="isBrandBusiness">
                            <span id="traingStatusSpan">{{$t('STATE')}}:</span>
                            <el-select v-model="traingStatusVal" @focus="focusDiv($event)" @blur="blurDiv($event)" collapse-tags placeholder="" style="width:100%;" @change="traingStatuschang" clearable="true">
                                <el-option v-for="item in this.traingStatusData"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('UPDATE START DATE')}}:</span>
                            <el-date-picker class="dataicon" v-model.trim="UTbeginTimeVal"
                                            type="date" @focus="focusDiv($event)" @blur="blurDiv($event)" prefix-icon="" style="width:100%;">
                            </el-date-picker>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('UPDATE END DATE')}}:</span>
                            <el-date-picker class="dataicon" v-model.trim="UTendTimeVal"
                                            type="date" @focus="focusDiv($event)" @blur="blurDiv($event)" prefix-icon="" style="width:100%;">
                            </el-date-picker>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6" style="justify-content:flex-start;height:38px;align-items:center;display:flex;">
                            <el-checkbox v-model="StatusVal" class="othChe">{{$t('DeletedData')}}</el-checkbox>
                        </el-col>
                    </el-col>
                    <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top:10px;">
                        <el-button class="moreBtn" size="small" icon="iconfont icon-24px" circle @click="moreFrom" style="margin-right:0;"></el-button>
                        <el-button type="primary" size="small"  style="margin-left: 0px !important" plain @click="searchFrom">{{$t('SEARCH')}}</el-button>
                        <el-button type="primary" size="small" icon="el-icon-plus" style="margin-left:10px;margin-right:0px!important" @click="addPurOrd">{{$t('ADD')}}</el-button>
                    </el-col>
                </el-row>
                <avue-crud :option="setData.tableOpt" :data="tableData" :row-style="rowStyle" :page.sync="page" @current-change="currentChange" v-loading="loading">
                    <template slot-scope="{row,index,size,type}" slot="Speed">
                        <el-tooltip class="item" effect="light" placement="bottom-start">
                            <div slot="content" v-for="(item,index) in row.SpeedContent" v-bind:key="index">{{formatterColumn(item)}}</div>
                            <el-link type="warning">{{row.Speed}}</el-link>
                        </el-tooltip>
                    </template>
                    <template slot-scope="{row,index,size,type}" slot="menu">
                        <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')" @click="editPur(row)" v-if="row.Status>=0"></el-button>
                        <el-button icon="el-icon-delete-solid" type="text" size="medium" :title="$t('Delete')" @click="delPur(row)" v-if="row.Status==0&&row.ApproveStatus!=100&&row.LockedStatus!=1"></el-button>
                        <el-button icon="el-icon-refresh-left" type="text" size="medium" :title="$t('RecoverData')" v-if="row.Status==1" @click="recover(row)"></el-button>
                    </template>
                    <template slot="expand" slot-scope="props">
                        <el-table :data="props.row.Details" :header-cell-style="tableHeaderColor">
                            <el-table-column type="index"></el-table-column>
                            <el-table-column prop="Speed" align="center" :label="$t('DEGREE OF COMPLETION')">
                                <template slot-scope="{row,index,size,type}">
                                    <el-tooltip class="item" effect="light" placement="bottom-start">
                                        <div slot="content" v-for="(item,index) in row.SpeedContent" v-bind:key="index">{{formatterColumn(item)}}</div>
                                        <el-link type="warning">{{row.Speed}}</el-link>
                                    </el-tooltip>
                                </template>
                            </el-table-column>
                            <el-table-column prop="BusinessNoIn" :label="$t('BUSINESS SERIAL NUMBER INTERNAL')"></el-table-column>
                            <el-table-column v-if="showOut" prop="BusinessNoOut" :label="$t('BUSINESS SERIAL NUMBER EXTERNAL')"></el-table-column>
                            <el-table-column prop="Code" :label="$t('ProductCode')"></el-table-column>
                            <el-table-column prop="ProductName" :label="$t('Product Name')"></el-table-column>
                            <el-table-column prop="Batch" :label="$t('BATCH')"></el-table-column>
                            <el-table-column prop="Color" :label="$t('COLOR')"></el-table-column>
                            <el-table-column align="center" prop="Status" :label="$t('STATUS')" :formatter="formatterStatus" v-if="!isBrandBusiness"></el-table-column>
                            <el-table-column align="center" prop="TrackingApproveStatus" :label="$t('STATUS')" :formatter="formatterTrackingStatus" v-if="isBrandBusiness"></el-table-column>
                            <el-table-column align="center" :label="$t('UPDATE TIME')">
                                <template slot-scope="scope">
                                    <el-tooltip class="item" effect="dark" placement="bottom" v-if="scope.row.IsApproveAfChange">
                                        <div slot="content">
                                            {{ $t('Modify again after approval') }}
                                        </div>
                                        <i class="el-icon-warning-outline"
                                           style="margin-left: 3px; font-size: 14px"></i>
                                    </el-tooltip>
                                    <span style="margin-left: 10px">{{transformDate(scope.row.LinkageUpdateDateTime) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column width="200px;" align="center" :label="$t('OPERATION')">
                                <template slot-scope="{row,index,size,type}">
                                    <el-button icon="icon-dingwei1" type="text" size="medium" :title="$t('Tracing')" v-if="LastSectionlist.indexOf(row.VendorSectionId)==-1" @click="tracingPur(row)"></el-button>
                                    <el-button icon="icon-shangchuan" type="text" size="medium" :title="$t('Upload')" v-if="!isBrandBusiness" @click="upload(row,index,size,type)"></el-button>
                                    <el-button icon="el-icon-thumb" type="text" size="medium" :title="$t('VIEW AUDIT INFO')" v-if="row.ApproveStatus<0" @click="ViewAuditInfo(row,index,size,type)"></el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </avue-crud>
                <el-dialog :title="$t('ChooseUploadBrand')" :visible.sync="BranddialogTableVisible" :modal-append-to-body="false" width="30%" top="1vh">
                    <el-form-item :label="$t('Brand')" label-width="120px">
                        <el-select v-model="brandChoose" filterable placeholder="" style="width: calc(100% - 115px);">
                            <el-option v-for="item in brandList"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <div style="text-align:center;padding:50px 0;">
                        <el-button type="primary" @click="submitBrandChoose();BranddialogTableVisible = false;">{{$t('Yes')}}</el-button>
                        <el-button @click="BranddialogTableVisible = false">{{$t('Cancel')}}</el-button>
                    </div>
                    <el-form-item>

                    </el-form-item>
                </el-dialog>
                <el-dialog :title="$t('REJECT')" :visible.sync="RejectdialogTableVisible" :modal-append-to-body="false" width="70%" top="1vh">
                    <el-form :model="RejectEdit" :rules="Rejectrules" ref="Rejectrules" label-position="top" label-width="150px" v-if="!isViewAudit">
                        <el-row>
                            <el-col :span="24" :sm="24" :md="24">
                                <el-form-item :label="$t('RESUBMIT DESCRIPTION')" :required="true" prop="Remarks" :rules="Rejectrules.Remarks">
                                    <el-input v-model="RejectEdit.Remarks" style="width: calc(100% - 115px);" type="textarea" maxlength="300" show-word-limit :autosize="{ minRows: 4, maxRows:6}"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div style="text-align:center;padding:20px 0;">
                            <el-button type="primary" @click="submitReject('Rejectrules');">{{$t('Yes')}}</el-button>
                            <el-button @click="RejectdialogTableVisible = false">{{$t('Cancel')}}</el-button>
                        </div>
                    </el-form>
                    <avue-crud :option="tableOptRejectList" :data="tableDataRejectList" :row-style="rowStyle" :page.sync="pageRejectList" @current-change="currentRejectListChange">
                    </avue-crud>
                </el-dialog>
            </template>
        </basic-container>
    </div>
</template>
<style>
    .el-dialog__wrapper {
        transition-duration: 0.3s;
    }

    .notifyStyle {
        width: 30% !important;
        background: #FFE4E1;
    }

    .dataicon i {
        display: none;
    }

    .othChe .el-checkbox__label {
        color: #409eff !important;
    }

    .el-table tr .el-table__expanded-cell {
        padding: 0 0 0 60px;
    }

    .el-icon-arrow-right:before {
        content: "\E6E0";
        font-weight: bold;
        color: Tomato;
        font-size: 15px;
    }
</style>
<script>
    import { purOrderList, delHead, recoverHead, SoListByPoId, DecCompleted, UnDecCompleted } from "@/api/purchaseOrd";
    import { ProcessDropDownList, TracingDropDownList, TracingUnifiedIsBool, TracingUnifiedHelpReturn, TracingStringList } from "@/api/unified";
    import { ListApproves, addResubmitsPo } from "@/api/orderApprove";
    export default {
        data() {
            return {
                showOut : false,
                loading: true,
                notiplist: [], notificationPromise: Promise.resolve(),
                isBrandBusiness: false,
                BranddialogTableVisible: false, PoDetailId: "", vendorSectionId: "", brandList: [], brandChoose:"",
                codeVal: "",
                businessnoinVal: "",
                businessnooutVal:"",
                batchVal: "",
                colorVal: "",
                poVal: "",
                beginTimeVal: "",
                endTimeVal: "",
                UTbeginTimeVal: "",
                UTendTimeVal: "",
                vendorVal: {
                    ids: [],
                },
                otherStatusVal: "",
                traingStatusVal: "",
                RejectdialogTableVisible: false, tableDataRejectList: [], RejectCategoryData: [], isViewAudit: false,
                RejectEdit: {
                    DetailOrderId: '',
                    Remarks: '',
                },
                Rejectrules: {
                    Remarks: [{ required: true, message: this.$t('Required'), trigger: 'blur' }],
                },
                StatusVal: false,
                vendorData: [],
                otherStatusData: [
                    {
                        label: this.$t('declaration reject'),
                        value: "14"
                    },
                    {
                        label: this.$t('declaration resubmit'),
                        value: "18"
                    },
                    {
                        label: this.$t('Locked'),
                        value: "80"
                    },
                    {
                        label: this.$t('declaration completed'),
                        value: "90"
                    }],
                traingStatusData: [
                    {
                        label: this.$t('declaration reject'),
                        value: "-4"
                    },
                    {
                        label: this.$t('Pending'),
                        value: "1"
                    },
                    {
                        label: this.$t('declaration resubmit'),
                        value: "-1"
                    },
                    {
                        label: this.$t('declaration completed'),
                        value: "100"
                    }],
                dictData: [],
                sectionData:[],
                tableData: [],
                LastSectionlist: {},
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background: true,
                    pageSize: 10
                },
                stateData: [
                    {
                        label: this.$t('Delete'),
                        value: 1
                    },
                    {
                        label: this.$t('initial'),
                        value: 0
                    },
                    {
                        label: this.$t('declaration reject'),
                        value: 14
                    },
                    {
                        label: this.$t('declaration resubmit'),
                        value: 18
                    },
                    {
                        label: this.$t('declaration locking'),
                        value: 20
                    },
                    {
                        label: this.$t('Locked'),
                        value: 80
                    },
                    {
                        label: this.$t('declaration completed'),
                        value: 90
                    }],
                traingData: [
                    {
                        label: this.$t('initial'),
                        value: 0
                    },
                    {
                        label: this.$t('Pending'),
                        value: 1
                    },
                    {
                        label: this.$t('declaration reject'),
                        value: -4
                    },
                    {
                        label: this.$t('declaration reject'),
                        value: -1
                    },
                    {
                        label: this.$t('declaration completed'),
                        value: 100
                    }],
                pageRejectList: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background: true,
                    pageSize: 10
                },
                tableOptRejectList: {
                    page: false,
                    refreshBtn: false,
                    columnBtn: false,
                    simplePage: true,
                    addBtn: false,
                    addRowBtn: false,
                    editBtn: false,
                    delBtn: false,
                    cellBtn: false,
                    index: true,
                    menu: false,
                    indexLabel: ' ',
                    border: true,
                    align: "center",
                    emptyText: this.$t('No Data'),
                    tip: false,
                    column: [
                        {
                            label: this.$t('DATE'),
                            prop: 'CreateTime',
                            type: "date",
                            format: 'yyyy-MM-dd',
                            valueFormat: 'yyyy-MM-dd',
                        },
                        {
                            label: this.$t('REJECTED COMPANY'),
                            prop: 'ApprovedObject',
                        },
                        {
                            label: this.$t('REJECTION CATEGORY'),
                            prop: 'RejectCategory',
                        },
                        {
                            label: this.$t('REJECTION DESCRIPTION'),
                            prop: 'RejectRemark',
                        },
                        {
                            label: this.$t('RESUBMISSION INSTRUCTIONS'),
                            prop: 'Remarks',
                        },
                    ]
                },
            }
        },
        computed: {
            setData() {
                return {
                    tableOpt: {
                        page: true,
                        refreshBtn: false,
                        addBtn: false,
                        editBtn: false,
                        delBtn: false,
                        emptyText: this.$t('No Data'),
                        menuTitle: this.$t('OPERATION'),
                        menuWidth: 200,
                        align: 'center',
                        columnBtn: false,
                        simplePage: true,
                        expand: true,
                        rowKey: 'Id',
                        expandRowKeys: [],
                        column: [

                            {
                                label: this.$t('DEGREE OF COMPLETION'),
                                prop: 'Speed',
                                slot: true
                            },
                            {
                                label: this.$t('VendorSection'),
                                prop: 'VendorSectionId',
                                type: "select",
                                filterable: true,
                                dicData: this.sectionData,
                            },
                            {
                                label: this.$t('VENDOR'),
                                prop: 'VendorCorporationId',
                                type: "select",
                                filterable: true,
                                dicData: this.vendorData,

                            },
                            {
                                label: this.$t('PO'),
                                prop: 'Po',
                            },
                            {
                                label: this.$t('Brand'),
                                prop: 'Brand',
                                type: "select",
                                filterable: true,
                                dicData: this.dictData,
                            },
                            {
                                label: this.$t('ORDER DATE'),
                                prop: 'OrderDate',
                                type: "date",
                                format: 'yyyy-MM-dd',
                                valueFormat: 'yyyy-MM-dd',
                            },
                            {
                                label: this.$t('STATE'),
                                prop: 'Status',
                                placeholder: ' ',
                                type: "select",
                                editDisplay: false,
                                hide: this.isBrandBusiness,
                                dicData: this.stateData,

                            },
                            {
                                label: this.$t('STATE'),
                                prop: 'TrackingApproveStatus',
                                placeholder: ' ',
                                type: "select",
                                hide: !this.isBrandBusiness,
                                editDisplay: false,
                                dicData: this.traingData,
                            },
                            {
                                label: this.$t('UPDATE TIME'),
                                prop: 'LinkageUpdateDateTime',
                                type: "date",
                                format: "yyyy-MM-dd HH:mm:ss",
                                valueFormat: "yyyy-MM-dd HH:mm:ss",
                            },
                            {
                                label: this.$t('systemBatchNo'),
                                prop: 'SystemBatchNo',
                                hide: true
                            }
                        ]
                    }
                }
            }
        },
        created() {
            this.init()
        },
        watch: {
            $route() {
                this.init()
            }
        },
        methods: {
            //设置表头行的样式
            tableHeaderColor() {
                return 'background-color:#838bb3!important;padding: 0!important;'

            },//表体颜色
            rowStyle(rowIndex) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
            },
            init() {
                this.getPurOrdList();
                TracingDropDownList('VendorList').then(res => {
                    this.vendorData = res.data;
                });
                TracingDropDownList('all_VendorSectionList').then(res => {
                    this.sectionData = res.data;
                });
                ProcessDropDownList('DictList', 'brand').then(res => {
                    this.dictData = res.data;
                });
                TracingUnifiedIsBool("IsBrandBusiness").then(res => {
                    this.isBrandBusiness = res.data;
                });
                TracingStringList("LastSection").then(res => {
                    this.LastSectionlist = res.data;
                })
            },
            focusDiv(obj) {//搜索框获取焦点
                if (obj.currentTarget === undefined) {
                    obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                } else {
                    if (obj.currentTarget.parentElement.getAttribute('class').indexOf('suffix') !== -1) {
                        obj.currentTarget.parentElement.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                    } else {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                    }

                }
            },//搜索框失去焦点
            blurDiv(obj) {
                if (obj.currentTarget === undefined) {
                    if (obj.value === "") {
                        obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                } else if (obj.currentTarget === null) {
                    if (this.vendorVal.ids.length === 0) {
                        document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                    if (this.otherStatusVal=== 0) {
                        document.querySelector("#otherStatusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                    if (this.traingStatusVal === 0) {
                        document.querySelector("#traingStatusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                } else {
                    if (obj.target.value == "") {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                }
            },
            venchang() {
                if (this.vendorVal.ids.length === 0) {
                    document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                } else {
                    document.querySelector("#venSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                }
            },//点击更多按钮
            otherStatuschang() {
                if (this.otherStatusVal === 0) {
                    document.querySelector("#otherStatusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                } else {
                    document.querySelector("#otherStatusSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                }
            },//点击更多按钮
            traingStatuschang() {
                if (this.traingStatusVal === 0) {
                    document.querySelector("#traingStatusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                } else {
                    document.querySelector("#traingStatusSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                }
            },//点击更多按钮
            moreFrom() {
                let fromHei = document.querySelector(".fromOutDiv").clientHeight;
                if (fromHei > 60) {
                    document.querySelector(".fromOutDiv").style.cssText = "height:60px;";
                } else {
                    document.querySelector(".fromOutDiv").style.cssText = "height:auto;";
                }
            },//切换分页
            currentChange(val) {
                this.page.currentPage = val
                this.getPurOrdList();
            },//搜索
            searchFrom() {
                this.getPurOrdList();
            },//新增
            addPurOrd() {
                this.$router.push({ path: '/pvh_factory/purchaseAdd', query: { sign: 'add', ID: '', corpid: '', systemBatchNo: '' } })
            },//删除数据
            delPur(row) {
                console.log("row0",row);
                //如果使用的数据不能删除
                SoListByPoId(row.Id).then(res => {
                    var list = res.data;
                    if (list.length > 0) { this.$message({ showClose: true, message: this.$t('NotCanDeletedPO'), type: "warning" }); return; }
                    this.$confirm(this.$t('IsDel') + row.Po, this.$t('Tips'), {
                        confirmButtonText: this.$t('Yes'),
                        cancelButtonText: this.$t('Cancel'),
                        type: "warning"
                    }).then(() => {
                        delHead(row.Id).then(() => {
                            this.getPurOrdList();
                            this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                        });
                    });
                })
            },//恢复数据
            recover(row) {
                this.$confirm(this.$t('IsRecover') + row.Po, this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                    recoverHead(row.Id).then(() => {
                        this.getPurOrdList();
                        this.$message({ showClose: true, message: this.$t('RecoverSuccess'), type: "success" });
                    });
                })
            },//修改
            editPur(row) {
                if (row.Status == 1) { this.$message({ showClose: true, message: this.$t('NotCanDeleted'), type: "warning" }); return; }
                this.$router.push({ path: '/pvh_factory/purchaseAdd', query: { sign: 'edit', ID: row.Id, corpid: row.CorporationId, systemBatchNo: row.SystemBatchNo } })
            },//追踪
            tracingPur(row) {
                this.$router.push({ path: '/pvh_operation/orderFracing', query: { ID: row.Id } })
            },//获取采购订单列表
            Declarationcompleted(row, e) {
                var isBodyType = e
                this.$confirm(this.$t('IsDeclarationcompleted') + this.$t('BATCH') + ':' + row.Batch, this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                    TracingUnifiedHelpReturn("IsCanDeclarationcompleted", row.Id, isBodyType).then(res => {
                        var errinfo = res.data;
                        if (errinfo.length > 0) {
                            this.notiplist = [];
                            for (var i = 0; i < errinfo.length; i++) {
                                if (errinfo[i].Param1 == "noLast") {
                                    this.notiplist.push(this.$t('BATCH') + '：' + (errinfo[i].Param2) + '  ' + this.$t('Process not completed') + '：' + errinfo[i].Param4);
                                }
                                else {
                                    this.notiplist.push(this.$t('BATCH') + '：' + (errinfo[i].Param2) + '  ' + errinfo[i].Param4 + '/' + errinfo[i].Param5 + ':' + this.$t('Attachment not uploaded'));
                                }
                            }
                            this.gettip();
                            console.log('error submit!!');
                            return false;
                        }
                        else {
                            //申报完成
                            DecCompleted(row.Id, isBodyType).then(() => {
                                this.getPurOrdList();
                                this.$message({ showClose: true, message: this.$t('OperationSuccess'), type: "success" });
                            });
                        }
                    }).catch((erro) => { console.log(erro) });

                })
            },
            gettip() {
                this.notificationPromise = this.notificationPromise.then(this.$nextTick).then(() => {
                    let newDatas = [];
                    const h = this.$createElement;
                    for (let i in this.notiplist) {
                        newDatas.push(h('p', null, this.notiplist[i]));
                    }
                    this.$notify.error({
                        title: this.$t('OperationFailed'),
                        offset: 100,
                        showClose: true,
                        duration: 0,
                        message: h('div', null, newDatas),
                        customClass: 'notifyStyle',
                    });
                });
            },
            UnDeclarationcompleted(row, e) {
                var isBodyType = e
                this.$confirm(this.$t('IsNoDeclarationcompleted') + row.Batch, this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                    //取消申报完成
                    UnDecCompleted(row.Id, isBodyType).then(() => {
                        this.getPurOrdList();
                        this.$message({ showClose: true, message: this.$t('OperationSuccess'), type: "success" });
                    });
                })
            },
            async getPurOrdList() {
                await purOrderList(this.page.currentPage, this.page.pageSize, this.vendorVal.ids.toString(), this.businessnoinVal, this.businessnooutVal, this.codeVal, this.batchVal, this.colorVal, this.poVal, this.beginTimeVal, this.endTimeVal, '', this.StatusVal, this.otherStatusVal, this.traingStatusVal, this.UTbeginTimeVal, this.UTendTimeVal).then(res => {
                    this.loading = false;
                    this.tableData = res.data.PurchaseOrders;
                    console.log(33,this.tableData );
                    this.page.total = res.data.TotalCount;
                    this.page.pageSize = res.data.PageSize;
                }).catch(erro => {
                    console.log(erro)
                })
            },
            formatterStatus(row) {
                var s_Status = "";
                switch (row.Status) {
                    case -1:
                        s_Status = this.$t('Delete');
                        break;
                    case 0:
                        s_Status = this.$t('initial');
                        break;
                    case 14:
                        s_Status = this.$t('declaration reject');
                        break;
                    case 18:
                        s_Status = this.$t('declaration resubmit');
                        break;
                    case 20:
                        s_Status = this.$t('declaration locking');
                        break;
                    case 80:
                        s_Status = this.$t('Locked');
                        break;
                    case 90:
                        s_Status = this.$t('declaration completed');
                        break;
                    default:
                        s_Status = this.$t('unknown');
                }
                return s_Status;
            },
            formatterTrackingStatus(row) {
                var s_Status = "";
                switch (row.TrackingApproveStatus) {
                    case -1:
                        s_Status = this.$t('declaration reject');
                        break;
                    case 0:
                        s_Status = this.$t('initial');
                        break;
                    case -4:
                        s_Status = this.$t('declaration reject');
                        break;
                    case 1:
                        s_Status = this.$t('Pending');
                        break;
                    case 100:
                        s_Status = this.$t('declaration completed');
                        break;
                    default:
                        s_Status = this.$t('unknown');
                }
                return s_Status;
            }
            , formatterColumn(e) {
                var helpReturn = e;
                if (helpReturn.Param1 == "Completed") {
                    return "√" + this.$t('No.') + helpReturn.Param3 + " : " + this.$t('Completed');
                }
                else if (helpReturn.Param1 == "IsLast") {
                    return "√" + this.$t('No.') + helpReturn.Param3 + " : " + this.$t('IsLast');
                }
                else if (helpReturn.Param1 == "HaveLast") {
                    return "√" +this.$t('HaveLast');
                }
                else if (helpReturn.Param1 == "MissedOrders") {
                    return "×" + this.$t('No.') + helpReturn.Param3 + " : " + helpReturn.Param2 +"-"+helpReturn.Param4 + " : " + this.$t('MissedOrders');
                }
                else if (helpReturn.Param1 == "HaveUpinfo") {
                    return "√" + this.$t('No.') + helpReturn.Param3 + " : " + helpReturn.Param2 + "-" + helpReturn.Param4 + " [" + helpReturn.Param5 + "] " +  this.$t('HaveUpinfo');
                }
                else if (helpReturn.Param1 == "NoUpinfo") {
                    return "×" + this.$t('No.') + helpReturn.Param3 + " : " + helpReturn.Param2 + "-" + helpReturn.Param4 + " [" + helpReturn.Param5 + "] " +  this.$t('NoUpinfo');
                }
                else if (helpReturn.Param1 == "HavePartUpinfo") {
                    return "×" + this.$t('No.') + helpReturn.Param3 + " : " + helpReturn.Param2 + "-" + helpReturn.Param4 + " [" + helpReturn.Param5 + "] " +  this.$t('HavePartUpinfo');
                }
                else if (helpReturn.Param1 == "NoWriteSO") {
                    return "×" + this.$t('No.') + helpReturn.Param3 + " : " + helpReturn.Param2 + "-" + helpReturn.Param4 + " [" + helpReturn.Param5 + "] " + this.$t('NoWriteSO');
                }
                else if (helpReturn.Param1 == "HaveWriteSO") {
                    return "√" + this.$t('No.') + helpReturn.Param3 + " : " + helpReturn.Param2 + "-" + helpReturn.Param4 + " [" + helpReturn.Param5 + "] " + this.$t('HaveWriteSO');
                }
                else if (helpReturn.Param1 == "NoWriteComponent") {
                    return "×" + this.$t('No.') + helpReturn.Param3 + " : " + helpReturn.Param2 + "-" + helpReturn.Param4 + " [" + helpReturn.Param5 + "] " + this.$t('NoWriteComponent');
                }
                else if (helpReturn.Param1 == "HaveWriteComponent") {
                    return "√" + this.$t('No.') + helpReturn.Param3 + " : " + helpReturn.Param2 + "-" + helpReturn.Param4 + " [" + helpReturn.Param5 + "] " + this.$t('HaveWriteComponent');
                }
                else if (helpReturn.Param1 == "NoRelation") {
                    return "×" + this.$t('No.') + helpReturn.Param3 + " : " + this.$t('NoRelation');
                }
                else if (helpReturn.Param1 == "HaveRelation") {
                    return "√" + this.$t('No.') + helpReturn.Param3 + " : " + this.$t('HaveRelation');
                }
                else if (helpReturn.Param1 == "NoNeedUpinfo") {
                    return "√" + this.$t('No.') + helpReturn.Param3 + " : " + helpReturn.Param2 + "-" + helpReturn.Param4 + " [" + helpReturn.Param5 + "] " + this.$t('NoNeedUpinfo');
                }
                else if (helpReturn.Param1 == "NoLast") {
                    return "×" + this.$t('No.') + helpReturn.Param3 + " : " + this.$t('NoLast');
                }
                else {
                    return "";
                }
            }
            , upload(row) {
                this.vendorSectionId = row.VendorSectionId;
                //上传前判断是否有品牌商信息
                TracingDropDownList('OrderBrandTrackList', row.Id).then(res => {
                    this.brandList = res.data;
                    if (this.brandList.length > 0) {
                        if (this.brandList.length == 1) {
                            this.$router.push({ path: '/pvh_factory/declarationEntry', query: { orderId: row.Id, brandBusinessId: this.brandList[0].value, vendorSectionId: this.vendorSectionId, isPO:'true' } })
                        }
                        else {
                            this.PoDetailId = row.Id;
                            this.BranddialogTableVisible = true;
                        }
                    }
                    else {
                        this.$message({
                            message: this.$t('NoBrandInfo'),
                            type: "warning"
                        });
                        return;
                    }
                });
                //如果没有弹窗提示不能上传
                //如果只有一个上传跳转上传界面
                //如果多个弹出选择框
            },
            submitBrandChoose() {
                if (this.brandChoose == "") {
                    this.$message({
                        message: this.$t('PleaseChooseBrand'),
                        type: "warning"
                    });
                    return;
                }
                else {
                    this.$router.push({ path: '/pvh_factory/declarationEntry', query: { orderId: this.PoDetailId, brandBusinessId: this.brandChoose, vendorSectionId: this.vendorSectionId, isPO: 'true'} })
                }

            },
            ViewAuditInfo(row) {
                var info = row;
                this.RejectdialogTableVisible = true;
                this.RejectEdit.DetailOrderId = info.Id;
                this.RejectEdit.Remarks = '';
                this.getRejectList();
                if (info.ApproveStatus < 0) {
                    this.isViewAudit = false;
                }
                else {
                    this.isViewAudit = true;
                }
            },
            submitReject(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let paramrow = {
                            detailOrderId: this.RejectEdit.DetailOrderId,
                            remarks: this.RejectEdit.Remarks,
                            status: 0,
                        };
                        addResubmitsPo(paramrow).then(() => {
                            this.$message({
                                message: this.$t('OperationSuccess'),
                                type: "success"
                            });
                            this.getPurOrdList();
                            this.RejectdialogTableVisible = false;
                        }).catch((erro) => { console.log(erro) });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async getRejectList() {
                await ListApproves(this.RejectEdit.DetailOrderId, this.pageRejectList.currentPage, this.pageRejectList.pageSize).then(res => {
                    var RejectInfo = res.data.OrderApproves;
                    this.tableDataRejectList = RejectInfo;
                    this.pageRejectList.total = res.data.TotalCount;
                    this.pageRejectList.pageSize = res.data.PageSize;
                }).catch(erro => {
                    console.log(erro)
                })
            },
            currentRejectListChange(val) {
                this.pageRejectList.currentPage = val
                this.getRejectList();
            },
            //替换表格时间格式
            formatDate(row, column) {
                // 获取单元格数据
                let date = row[column.property];
                if (date) {
                    return this.transformDate(date)
                } else {
                    return ''
                }
            },
            //标准时间格式转yyyy-MM-dd HH:mm:ss
            transformDate(date) {
                if (date) {
                    let dt = new Date(date);
                    return dt.getFullYear() + '-' +
                        ((dt.getMonth() + 1) < 10 ? ('0' + (dt.getMonth() + 1)) : (dt.getMonth() + 1)) + '-' +
                        (dt.getDate() < 10 ? ('0' + dt.getDate()) : dt.getDate()) + ' ' +
                        (dt.getHours() < 10 ? ('0' + dt.getHours()) : dt.getHours()) + ':' +
                        (dt.getMinutes() < 10 ? ('0' + dt.getMinutes()) : dt.getMinutes()) + ':' +
                        (dt.getSeconds() < 10 ? ('0' + dt.getSeconds()) : dt.getSeconds())
                } else {
                    return ''
                }
            },
        }
    }
</script>