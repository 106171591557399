import request from '@/router/axios';
import {businessUrl} from "@/config/env"
export const list = (machineId) => {
    return request({
        url : businessUrl + "corpUsers/selectUserMachines",
        method: "post",
        data: {
            machineId:machineId,
            workShopId: 4        }
    })
}
export const getMachineList = (deid,prid) => {
    return request({
        url : businessUrl + "machine/queryAllMachine",
        method: "post",
        data: {
            departmentId:deid,
            processId:prid
         }
    })
}
export const MachineList = (codes) => {
    return request({
        url : businessUrl + "machine/queryAllMachine",
        method: "post",
        data: {
            code: codes,
         }
    })
}
export const addMachine = (codes) => {
    return request({
        url : businessUrl + "machine/checkMachineCode",
        method: "get",
        params: {
            code: codes,
         }
    })
}