var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "avue-crud",
            {
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                "table-loading": _vm.tableLoading,
                page: _vm.page
              },
              on: {
                "update:page": function($event) {
                  _vm.page = $event
                },
                "size-change": _vm.sizeChange,
                "current-change": _vm.currentChange
              }
            },
            [
              _c(
                "template",
                { attrs: { "slot-scop": "scope" }, slot: "menuRight" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "110px !important",
                        "margin-right": "5px"
                      },
                      attrs: {
                        placeholder: "报表类型",
                        size: "small",
                        clearable: ""
                      },
                      on: { change: _vm.selType },
                      model: {
                        value: _vm.type,
                        callback: function($$v) {
                          _vm.type = $$v
                        },
                        expression: "type"
                      }
                    },
                    _vm._l(_vm.typeList, function(item) {
                      return _c("el-option", {
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  ),
                  _vm.timeType
                    ? _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "110px !important",
                            "margin-right": "5px"
                          },
                          attrs: {
                            placeholder: "事件类型",
                            size: "small",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchVal.time,
                            callback: function($$v) {
                              _vm.$set(_vm.searchVal, "time", $$v)
                            },
                            expression: "searchVal.time"
                          }
                        },
                        _vm._l(_vm.timeList, function(item) {
                          return _c("el-option", {
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _c("el-date-picker", {
                    staticStyle: {
                      width: "150px !important",
                      "margin-right": "5px"
                    },
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      size: "small",
                      placeholder: "选择开始时间"
                    },
                    model: {
                      value: _vm.searchVal.strTime,
                      callback: function($$v) {
                        _vm.$set(_vm.searchVal, "strTime", $$v)
                      },
                      expression: "searchVal.strTime"
                    }
                  }),
                  _c("el-date-picker", {
                    staticStyle: {
                      width: "150px !important",
                      "margin-right": "5px"
                    },
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      size: "small",
                      placeholder: "选择结束时间"
                    },
                    model: {
                      value: _vm.searchVal.endTime,
                      callback: function($$v) {
                        _vm.$set(_vm.searchVal, "endTime", $$v)
                      },
                      expression: "searchVal.endTime"
                    }
                  }),
                  _vm.userType || _vm.timpType
                    ? _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "110px !important",
                            "margin-right": "5px"
                          },
                          attrs: {
                            placeholder: "请选择班组",
                            size: "small",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchVal.groupId,
                            callback: function($$v) {
                              _vm.$set(_vm.searchVal, "groupId", $$v)
                            },
                            expression: "searchVal.groupId"
                          }
                        },
                        _vm._l(_vm.groupList, function(item) {
                          return _c("el-option", {
                            key: item.name,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.userType
                    ? _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "110px !important",
                            "margin-right": "5px"
                          },
                          attrs: {
                            placeholder: "请选择人员",
                            size: "small",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchVal.userId,
                            callback: function($$v) {
                              _vm.$set(_vm.searchVal, "userId", $$v)
                            },
                            expression: "searchVal.userId"
                          }
                        },
                        _vm._l(_vm.userList, function(item) {
                          return _c("el-option", {
                            key: item.name,
                            attrs: { label: item.name, value: item.userId }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.timeType || _vm.timpType
                    ? _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "110px !important",
                            "margin-right": "5px"
                          },
                          attrs: {
                            placeholder: "请选择车间",
                            size: "small",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchVal.workShopId,
                            callback: function($$v) {
                              _vm.$set(_vm.searchVal, "workShopId", $$v)
                            },
                            expression: "searchVal.workShopId"
                          }
                        },
                        _vm._l(_vm.cjlist, function(item) {
                          return _c("el-option", {
                            key: item.name,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.timeType
                    ? _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "110px !important",
                            "margin-right": "5px"
                          },
                          attrs: {
                            placeholder: "请选择设备",
                            size: "small",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchVal.machineId,
                            callback: function($$v) {
                              _vm.$set(_vm.searchVal, "machineId", $$v)
                            },
                            expression: "searchVal.machineId"
                          }
                        },
                        _vm._l(_vm.mechinaList, function(item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.code, value: item.id }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.timpType
                    ? _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "110px !important",
                            "margin-right": "5px"
                          },
                          attrs: {
                            placeholder: "请选择班制",
                            size: "small",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchVal.shiftId,
                            callback: function($$v) {
                              _vm.$set(_vm.searchVal, "shiftId", $$v)
                            },
                            expression: "searchVal.shiftId"
                          }
                        },
                        _vm._l(_vm.bzList, function(item) {
                          return _c("el-option", {
                            key: item.name,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "small"
                      },
                      on: { click: _vm.search }
                    },
                    [_vm._v("搜 索")]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }