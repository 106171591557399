<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{$t('QR Code Access Record')}}</div>
            </div>
            <template>
                <avue-crud ref="crud" :option="setData.tableOpt" :data="tableData" :row-style="rowStyle" :page.sync="page" @current-change="currentChange">
                    <template slot="menuRight" style="width: calc(100% - 80px);">
                        <el-row :gutter="20" class="topFromSearch">
                            <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('QR CodeID')}}:</span>
                                    <el-input v-model="qrCodeIdVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('Visitor IP')}}:</span>
                                    <el-input v-model="iPVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>

                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('BEINGTIME')}}:</span>
                                    <el-date-picker class="dataicon" v-model="beginTimeVal"
                                                    type="date" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                                    format="yyyy-MM-dd" value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                                    </el-date-picker>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('ENDTIME')}}:</span>
                                    <el-date-picker class="dataicon" v-model="endTimeVal"
                                                    type="date" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                                    format="yyyy-MM-dd" value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                                    </el-date-picker>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <!--<span>{{$t('Visitor Equipment')}}:</span>
        <el-input v-model="deviceVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>-->
                                </el-col>
                            </el-col>
                            <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top:10px;">
                                <el-button class="moreBtn" size="small" icon="iconfont icon-24px" circle @click="moreFrom" style="margin-right:0;"></el-button>
                                <el-button type="primary" size="small" plain @click="searchFrom">{{$t('SEARCH')}}</el-button>
                            </el-col>
                        </el-row>
                    </template>
              
                </avue-crud>
            </template>
        </basic-container>
    </div>
</template>
<style scoped>
    .dataicon i {
        display: none;
    }
</style>
<script>
    import { recodeList} from "@/api/QRcode";
    export default {
        data() {
            return {
                qrCodeIdVal: "",
                iPVal: "",
                deviceVal: "",
                beginTimeVal: "",
                endTimeVal: "",
                tableData: [],
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev,next",
                    background: true,
                    pageSize: 10
                }
            }
        },
        computed: {
            setData() {
                return {
                    tableOpt: {
                        page: true,
                        refreshBtn: false,
                        menuBtn: false,
                        menu: false,
                        addBtn: false,
                        delBtn: false,
                        emptyText: this.$t('No Data'),
                        align: 'center',
                        columnBtn: false,
                        simplePage: false,
                        header: true,
                        column: [
                            {
                                label: this.$t('QR CodeID'),
                                prop: 'QRCodeId',
                            },
                            {
                                label: this.$t('Visitor IP'),
                                prop: 'IP',
                            },
                            //{
                            //    label: this.$t('Visitor Equipment'),
                            //    prop: 'Device',
                            //},
                            {
                                label: this.$t('Record Generation Time'),
                                prop: 'RecodeTime',
                                type: "date",
                                editDisplay: false,//ҳ�� ����
                                addDisplay: false,
                                format: "yyyy-MM-dd hh:mm:ss",
                                valueFormat: "yyyy-MM-dd hh:mm:ss"
                            }
                        ]
                    }
                }
            }
        },
        created() {
            this.getList();
        },
        methods: {
            moreFrom() {
                let fromHei = document.querySelector(".fromOutDiv").clientHeight;
                if (fromHei > 60) {
                    document.querySelector(".fromOutDiv").style.cssText = "height:60px;";
                } else {
                    document.querySelector(".fromOutDiv").style.cssText = "height:auto;";
                }
            },
            focusDiv(obj) {//�������ȡ����
                if (obj.currentTarget === undefined) {
                    obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                } else {
                    obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                }
            },//������ʧȥ����
            blurDiv(obj) {
                if (obj.currentTarget === undefined) {
                    if (obj.value === "") {
                        obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                } else {
                    if (obj.target.value == "") {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                }

            },//��������ʽ
            rowStyle({rowIndex }) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
            },//�л���ҳ
            currentChange(val) {
                this.page.currentPage = val;
                this.getList();
            },//����
            searchFrom() {
                this.getList();
            },
            //��ʾ����
            getList() {
                recodeList(this.qrCodeIdVal, this.iPVal, this.deviceVal, this.beginTimeVal, this.endTimeVal, this.page.currentPage, this.page.pageSize).then(res => {
                    this.tableData = res.data.QRCodeRecodes;
                    this.page.total = res.data.TotalCount;
                    this.page.pageSize = res.data.PageSize;
                }).catch(erro => {
                    console.log(erro);
                })
            },

        }
    }
</script>