import request from '@/router/axios';
import {businessUrl} from "@/config/env"
export const list = (pageNo,pageSize,nameOrCode,status) => {
    return request({
        url : businessUrl + "unit/selectPage",
        method: "post",
        data: {
            pageNo,
            pageSize,
            nameOrCode,
            status
        }
    })
}
export const remove = (id) => {
    return request({
        url: businessUrl + "unit/delete",
        method: "delete",
        params: {
            id
        }
    })
}
export const add = (params) => {
    return request({
        url: businessUrl + "unit/insert",
        method: "post",
        data: {
            ...params
        }
    })
}
export const edit = (params) => {
    return request({
        url: businessUrl + "unit/updateByPrimaryKeySelective",
        method: "put",
        data: {
            ...params
        }
    })
}
export const audit = (id,auditStatus) => {
    return request({
        url: businessUrl + "unit/audit",
        method: "put",
        data: {
            id,auditStatus
        }
    })
}
export const returnAudit = (id,status) => {
    return request({
        url: businessUrl + "unit/audit",
        method: "put",
        data: {
            id,status
        }
    })
}

//��ѯ������չʾ��λ�ӿ�
export const ShowUnit = () => {
    return request({
        url: businessUrl + "unit/selectShowUnit",
        method: "get"
    })
}

//code �Ƿ����
export const CodeIsExist = (id, code) => {
    return request({
        url: businessUrl + "unit/selectCodeByCorpId",
        method: "get",
        params: {
            "id": id,
            "code": code
        }
    })

}

//���ݷ���id��ѯ�����Ƿ��Ѿ��л�׼��λ�ӿ�
export const StandardIsExist = (groupId, id) => {
    return request({
        url: businessUrl + "unit/selectIsBaseByGroup",
        method: "get",
        params: {
            "groupId": groupId,
            "id": id
        }
    })

}

//�������ƽӿ�
export const ShowGroupName = () => {
    return request({
        url: businessUrl + "unit/selectGroupNameByCorpId",
        method: "get"
    })
}

//�����¼ǲ˵�����
export const GroupDown = (groupId) => {
    return request({
        url: businessUrl + "unit/selectUnitByGroup",
        method: "get",
        params: {
            "groupId": groupId
        }
    })

}

//��ȡ���з���ӿ�
export const AllGroup = (pageNo, pageSize) => {
    return request({
        url: businessUrl + "unit/selectGroupByCorporationId",
        method: "get",
        params: {
            "pageNo": pageNo,
            "pageSize": pageSize
        }
    })

}