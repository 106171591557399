import request from '@/router/axios';
import {businessUrl} from '@/config/env'
export const list = (pageNo,pageSize,startTime,endTime,mechineId,productid,batchid) => {
    return request({
        url : businessUrl + "productMachineData/selectProductMachineData",
        method: "post",
        data: {
					pageNo,
					pageSize,
					startTime,
					endTime,
					mechineId,
					productid,
					batchid
        }
    })
}
// 查询所有品种
export const searchVarieties = () => {
	return request({
			url : businessUrl + "product/selectPage",
			method: "post",
			data: {

			}
	})
}
// 查询批号
export const searchNumber = (id,code) => {
	return request({
			url : businessUrl + "productBatch/selectBatch",
			method: "post",
			data: {
				productId: id,
				batchCode:code
			}
	})
}
// 查询设备
export const searcheQuipment = (id) => {
	return request({
			url : businessUrl + "machine/queryAllMachine",
			method: "post",
			data: {
				departmentId: id
			}
	})
}
// 查询车间
export const searchWorkshop = (num) => {
	return request({
			url : businessUrl + "department/selectWorkShopName",
			method: "get",
			params: {
				type: num
			}
	})
}
export const getCommmit = (row,cutDataList) => {
	return request({
			url : businessUrl + "productMachineData/updateProductMachineData",
			method: "post",
			data: {
			  ...row,
				cutDataList
			}
	})
}
export const getvarietiesById = (id) => {
	return request({
			url : businessUrl + "product/selectById",
			method: "get",
			params: {
				id:id
			}
	})
}
export const getBatchById = (id) => {
	return request({
			url : businessUrl + "productBatch/selectBatchById",
			method: "get",
			params: {
				batchCodeId:id
			}
	})
}
export const getIdByCode = (code) => {
	return request({
			url : businessUrl + "productBatch/selectBatchByCode",
			method: "get",
			params: {
				batchCodeId:code
			}
	})
}